import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { ICity } from 'app/shared/interfaces/icity';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { ICompany } from '../../../../shared/interfaces/icompany.interface';
import { OverlayBusyService } from '../../../../shared/overlay-busy/overlay-busy.service';
import { AdminPanelService } from '../admin-panel.service';
import { CitiesService } from '../cities/cities.service';
import { MunicipalitiesService } from '../municipalities/municipalities.service';
import { RegionsService } from '../regions/regions.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import { IFindUsersFilter } from 'app/shared/interfaces/ifindusersfilter.interface';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'prf-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  @ViewChild('regionDrop') public readonly regionDrop: ElementRef;
  @ViewChild('regionField') public readonly regionField: ElementRef;
  @ViewChild('municipalityDrop') public readonly municipalityDrop: ElementRef;
  @ViewChild('municipalityField') public readonly municipalityField: ElementRef;
  @ViewChild('cityDrop') public readonly cityDrop: ElementRef;
  @ViewChild('cityField') public readonly cityField: ElementRef;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  allSchools: any[] = [];
  schools: any[] = [];
  schoolsByIds: Map<string, string> = new Map();
  institutions: any = [];
  schoolAdmins: any = [];
  schoolAdminsView: any = [];
  directors: any = [];
  directorsView: any = [];
  adminDOUsers: any = [];
  adminDOUsersView: any = [];
  admins: any = [];
  adminsView: any = [];
  employers: any = [];
  employersView: any = [];
  tutors: any[] = [];
  tutorsView: any[] = [];
  error: boolean = false;
  userRegionId: string = '';
  userMunicipalityId: string = '';
  defaultGuid: string = '00000000-0000-0000-0000-000000000000';
  adminLevel: any;
  adminAccessLevel: any = AdminAccessLevel;
  filterRegionId: string = '';
  filterMunicipalityId: string = '';
  selectedRole: string = 'schooladmin';

  dataFetched: boolean = false;
  editUserFlag: boolean = false;
  regionDisabled: boolean = false;
  municipalityDisabled: boolean = false;

  public allRegions: IRegion[] = [];
  public municipalitiesByRegion: IMunicipality[] = [];
  public allCities: ICity[];
  public citiesByMunicipality: ICity[] = [];
  public schoolsFiltered: ISchool[] = [];

  private ngUnsubscribe$: Subject<any> = new Subject();
  public allCompanies: ICompany[] = [];
  public showDeletePopUp: boolean = false;
  public showResetPasswordPopUp: boolean = false;
  public selectedUser: any;
  public deletedUser: any;
  public currentUserEmail: string = '';

  public form: UntypedFormGroup;

  public showInfo: boolean = false;

  public submitted: boolean = false;
  public users: any[];
  public userRoles: any = [
    { name: 'Ученик', value: 'pupil' },
    { name: 'Родитель', value: 'parent' },
    { name: 'Школьный админ', value: 'schooladmin' },
    { name: 'Директор', value: 'director' },
    { name: 'Админ', value: 'admin' },
    { name: 'Тьютор', value: 'tutor' },
  ];

  constructor(
    private meta: Meta,
    private adminPanelService: AdminPanelService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private citiesService: CitiesService,
    private overlayService: OverlayBusyService,
    private fb: UntypedFormBuilder,
    public utilsService: UtilsService,
    private translateService: TranslateService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Пользователи' });
  }

  ngOnInit() {
    this.dataFetched = false;
    this.adminLevel = localStorage.getItem('adminLevel');
    this.userRegionId = localStorage.getItem('regionId');
    this.userMunicipalityId = localStorage.getItem('municipalityId');
    this.overlayService.show();

    this.form = this.fb.group({
      region: new UntypedFormControl(null, []),
      municipality: new UntypedFormControl(null, []),
      city: new UntypedFormControl(null, []),
      school: new UntypedFormControl(null, []),
      role: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, []),
      firstName: new UntypedFormControl(null, []),
      email: new UntypedFormControl(null, []),
      code: new UntypedFormControl(null, []),
    });

    this.adminPanelService
      .getAllCities()
      .pipe(
        switchMap(cities => {
          this.allCities = cities;

          return this.adminPanelService.getCatalogSchools().pipe(
            takeUntil(this.ngUnsubscribe$),
            tap(allSchools => {
              this.allSchools = allSchools;
              this.allSchools.forEach(school => {
                this.schools.push({ value: school.number, viewValue: school.number, id: school.id });
                this.schoolsByIds.set(school.id, school.number);
              });

              return this.loadTerritory().pipe().subscribe();
              // return this.loadTerritory().pipe(
              //   switchMap(r => {
              //     return observableForkJoin(
              //       this.adminPanelService.getInstitutionsAll(),
              //       this.adminPanelService.getSchoolAdmins(
              //         this.userRegionId,
              //         this.userMunicipalityId
              //       )
              //     ).pipe(
              //       tap(([institutions, schoolAdmins]) => {
              //         this.institutions = institutions;
              //         this.schoolAdmins = schoolAdmins;
              //         this.schoolAdminsView = this.schoolAdmins;
              //       })
              //     );
              //   })
              // );
            }),
          );
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        this.overlayService.hide();
        this.dataFetched = true;
      });
  }

  loadTerritory(): Observable<any> {
    this.adminLevel = localStorage.getItem('adminLevel');
    let userRegion: any;
    let userMunicipality: any;

    switch (this.adminLevel) {
      case AdminAccessLevel.GLOBAL: {
        return this.regionsService.getAllRegions().pipe(
          tap(r => {
            this.allRegions = r.filter(r => r.id != this.defaultGuid).sort((a, b) => (a.name > b.name ? 1 : -1));
          }),
        );
      }
      case AdminAccessLevel.REGION: {
        return this.regionsService.getAllRegions().pipe(
          switchMap(r => {
            this.allRegions = r.filter(r => r.id != this.defaultGuid);
            userRegion = this.allRegions.find(r => r.id == this.userRegionId);

            return this.setRegion(userRegion).pipe(
              tap(r => {
                this.regionDisabled = true;
                // return this.getMunicipalities(this.userRegionId);
              }),
            );
          }),
        );
      }
      case AdminAccessLevel.MUNICIPALITY: {
        return this.regionsService.getAllRegions().pipe(
          switchMap(r => {
            this.allRegions = r.filter(r => r.id != this.defaultGuid);
            userRegion = this.allRegions.find(r => r.id == this.userRegionId);
            if (userRegion) {
              return this.setRegion(userRegion).pipe(
                switchMap(r => {
                  this.regionDisabled = true;
                  userMunicipality = this.municipalitiesByRegion.find(m => m.id == this.userMunicipalityId);
                  if (userMunicipality) {
                    return this.setMunicipality(userMunicipality).pipe(
                      tap(r => {
                        this.municipalityDisabled = true;
                      }),
                    );
                  } else {
                    return of(null);
                  }
                }),
              );
            } else {
              return of(null);
            }
          }),
        );
      }
      default:
        return of(null);
    }
  }

  findUsers() {
    this.submitted = true;
    if (this.form.valid) {
      let filters: IFindUsersFilter = {
        role: this.f.role.value.value,
        lastName: this.f.lastName.value ? this.f.lastName.value : null,
        firstName: this.f.firstName.value ? this.f.firstName.value : null,
        code: this.f.code.value ? this.f.code.value : null,
        email: this.f.email.value ? this.f.email.value : null,
        regionId: this.f.region.value ? this.f.region.value.id : null,
        municipalityId: this.f.municipality.value ? this.f.municipality.value.id : null,
        city: this.f.city.value ? this.f.city.value.name : null,
        schoolId: this.f.school.value ? this.f.school.value.id : null,
      };

      this.users = null;

      this.adminPanelService
        .findUsersByFilters(filters)
        .pipe(
          takeUntil(this.ngUnsubscribe$),
          tap(usersResponse => {
            if (usersResponse.status == 'Success') {
              this.users = usersResponse.users;
            } else if (usersResponse.status == 'Failed') {
              if (usersResponse.comment == 'Not enough filters' || 'Not supported filters') {
                this.utilsService.openSnackBar('Недостаточно фильтров для данной роли', 'error');
              } else {
                this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
              }
            }
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe();
    } else {
      this.f.role.markAsTouched();
    }
  }

  resetFilters() {
    this.form.reset();
    this.users = null;
  }

  getCompanies(): Observable<ICompany[]> {
    return this.adminPanelService.getAllCompanies();
  }

  loadUsers(regionId, municipalityId): Observable<any> {
    //todo: старый метод, удалить позже
    return of(null);

    let currentObs$: Observable<any>;
    switch (this.selectedRole) {
      case 'adminDO':
        {
          this.adminDOUsers = [];
          currentObs$ = this.adminPanelService.getAdminDOUsers(regionId, municipalityId).pipe(
            tap(users => {
              this.adminDOUsers = users;
              if (this.adminDOUsers) {
                this.adminDOUsersView = this.adminDOUsers;
              }
            }),
          );
        }
        break;
      case 'schooladmin':
        {
          this.schoolAdmins = [];
          currentObs$ = this.adminPanelService.getSchoolAdmins(regionId, municipalityId).pipe(
            tap(admins => {
              this.schoolAdmins = admins;
              if (this.schoolAdmins) {
                this.schoolAdminsView = this.schoolAdmins;
              }
            }),
          );
        }
        break;
      case 'director':
        {
          this.directors = [];
          currentObs$ = this.adminPanelService.getDirectors(regionId, municipalityId).pipe(
            tap(directors => {
              this.directors = directors;
              if (this.directors) {
                this.directorsView = this.directors;
              }
            }),
          );
        }
        break;
      case 'admin':
        {
          this.admins = [];
          currentObs$ = this.adminPanelService.getAdminUsers(regionId, municipalityId).pipe(
            tap(admins => {
              this.admins = admins;
              if (this.admins) {
                this.adminsView = this.admins;
              }
            }),
          );
        }
        break;
      case 'employers':
        {
          this.employers = [];

          currentObs$ = this.adminPanelService.getEmployerUsers().pipe(
            switchMap(users => {
              this.employers = users.employers;
              if (this.employers) {
                return this.getCompanies().pipe(
                  tap(companies => {
                    const getCompany = companyId => {
                      const company = companies.find(c => c.id == companyId);
                      return (company && company.name) || '';
                    };
                    this.employersView = [...this.employers.map(d => ({ ...d, companyName: getCompany(d.companyId) }))];
                  }),
                );
              } else {
                return of(null);
              }
            }),
          );
        }
        break;
      case 'tutors': {
        this.tutorsView = [];
        currentObs$ = this.adminPanelService.getTutorUsers().pipe(
          tap(users => {
            this.tutors = users;
            if (this.tutors) {
              this.tutorsView = this.tutors;
            }
          }),
        );
      }
    }

    return currentObs$;
  }

  get f() {
    return this.form.controls;
  }

  selectRegion(region) {
    this.setRegion(region).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setRegion(region): Observable<any> {
    this.f.municipality.setValue(null);
    this.f.city.setValue(null);
    this.f.school.setValue(null);

    this.f.region.setValue(region);
    return this.getUsersByRegion(region).pipe(
      switchMap(r => {
        return this.getMunicipalities(region);
      }),
    );
  }

  selectMunicipality(municipality) {
    this.setMunicipality(municipality).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setMunicipality(municipality): Observable<any> {
    this.f.city.setValue(null);
    this.f.school.setValue(null);

    this.f.municipality.setValue(municipality);

    return observableForkJoin([
      this.getUsersByMunicipality(municipality),
      this.citiesService.getAllCitiesByMunicipality(municipality.id),
    ]).pipe(
      tap(([users, cities]) => {
        this.citiesByMunicipality = cities;
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  setCity(city) {
    if (city) {
      this.f.city.setValue(city);
      this.getSchoolsByCity(city);
    }
    return;
  }

  private getSchoolsByCity(city) {
    this.schoolsFiltered = [];
    if (city) {
      let citiesByMunicipality = this.allCities.filter(el => el.id === city.id);
      this.schoolsFiltered = this.allSchools
        .filter(school => {
          let schoolCity = citiesByMunicipality.find(el => el.id === school.cityId);
          return citiesByMunicipality.indexOf(schoolCity) > -1;
        })
        .sort((a, b) => (a.number > b.number ? 1 : -1));
    }
  }

  public getUsersByRegion(region): Observable<any> {
    if (region) {
      let defaultMunicipalityId = this.defaultGuid;
      return this.loadUsers(region.id, defaultMunicipalityId);
    } else {
      return of(null);
    }
  }

  getUsersByMunicipality(municipality): Observable<any> {
    if (municipality) {
      let regionId = this.f.region.value.id;
      return this.loadUsers(regionId, municipality.id);
    } else {
      return of(null);
    }
  }

  processUserAdd(role: any) {
    let regionId: string = this.f.region.value ? this.f.region.value.id : null;
    let municipalityId: string = this.f.municipality.value ? this.f.municipality.value.id : null;
    this.loadUsers(regionId, municipalityId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  processUserEdit(role: any) {
    let regionId: string = this.f.region.value ? this.f.region.value.id : null;
    let municipalityId: string = this.f.municipality.value ? this.f.municipality.value.id : null;
    this.loadUsers(regionId, municipalityId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  processUserDelete(role: any) {
    let regionId: string = this.f.region.value ? this.f.region.value.id : null;
    let municipalityId: string = this.f.municipality.value ? this.f.municipality.value.id : null;
    this.loadUsers(regionId, municipalityId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  selectRole(role: any) {
    this.selectedRole = role;
    let regionId = this.f.region.value ? this.f.region.value.id : this.defaultGuid;
    let municipalityId = this.f.municipality.value ? this.f.municipality.value.id : this.defaultGuid;
    this.loadUsers(regionId, municipalityId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  getMunicipalities(region): Observable<any> {
    return this.municipalitiesService.getMunicipalitiesByRegion(region.id).pipe(
      tap(r => {
        this.municipalitiesByRegion = r.filter(r => r.id != this.defaultGuid);
      }),
    );
  }

  getDefaultUsers(): Observable<any> {
    return this.loadUsers(this.defaultGuid, this.defaultGuid);
  }

  resetData() {
    this.getDefaultUsers()
      .pipe(
        tap(() => {
          this.f.region.setValue(null);
          this.f.municipality.setValue(null);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  resetMunicipality() {
    if (this.f.region.value) {
      this.getUsersByRegion(this.f.region.value)
        .pipe(
          tap(() => {
            this.f.municipality.setValue(null);
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  @HostListener('click', ['$event'])
  checkClick(event: Event) {
    if (this.regionDrop?.nativeElement.classList.contains('w--open')) {
      if (!this.regionDrop.nativeElement.contains(event.target) && !this.regionField.nativeElement.contains(event.target)) {
        this.regionDrop.nativeElement.classList.remove('w--open');
      }
    }

    if (this.municipalityDrop?.nativeElement.classList.contains('w--open')) {
      if (!this.municipalityDrop.nativeElement.contains(event.target) && !this.municipalityField.nativeElement.contains(event.target)) {
        this.municipalityDrop.nativeElement.classList.remove('w--open');
      }
    }

    if (this.cityDrop?.nativeElement.classList.contains('w--open')) {
      if (!this.cityDrop.nativeElement.contains(event.target) && !this.cityField.nativeElement.contains(event.target)) {
        this.cityDrop.nativeElement.classList.remove('w--open');
      }
    }
  }

  editFlag(data) {
    this.editUserFlag = !this.editUserFlag;
  }

  showEditUser(user, role) {
    this.editUserFlag = !this.editUserFlag;
    this.selectedUser = user;
    this.selectedRole = role;
  }

  editSchoolAdmin(index: any) {
    const schoolAdmin = this.schoolAdminsView[index];
    return schoolAdmin;
  }

  selectDeletedUser(user, role) {
    this.deletedUser = user;
    this.selectedRole = role;
    this.showDeletePopUp = true;
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  totalDeleteUser(userId) {
    this.adminPanelService.totalDeleteUser(userId);
    this.adminPanelService
      .totalDeleteUser(userId)
      .pipe(
        switchMap((response: any) => {
          if (response.status == 'Success') {
            return this.getTranslations(['SHARED.USER_DELETE_MSG']).pipe(
              tap(translations => {
                this.utilsService.openSnackBar(translations['SHARED.USER_DELETE_MSG'], 'success');
                this.processUserDelete(this.selectedRole);
                this.showDeletePopUp = false;
              }),
            );
          } else {
            return this.getTranslations(['SHARED.ERROR_DELETE_MSG']).pipe(
              tap(translations => this.utilsService.openSnackBar(translations['SHARED.ERROR_DELETE_MSG'], 'error')),
            );
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  closeDeletePopUp() {
    this.showDeletePopUp = false;
  }

  isAdminGlobal() {
    return this.adminLevel == AdminAccessLevel.GLOBAL ? true : false;
  }

  public openResetPopUp(email: string) {
    this.currentUserEmail = email;
    this.showResetPasswordPopUp = true;
  }

  public resetPasswordToCode() {
    this.adminPanelService
      .resetPasswordToRegCode(this.currentUserEmail)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.showResetPasswordPopUp = false;
        this.currentUserEmail = null;
      });
  }

  public closeResetPasswordPopUp() {
    this.showResetPasswordPopUp = false;
    this.currentUserEmail = null;
  }
}
