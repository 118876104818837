import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { IScreeningTestsAccessesResponse, ISessionListResponse, ITutorPupilInterface } from 'app/shared/interfaces/itutorpupil.interface';
import { BehaviorSubject, throwError, TimeoutError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IPupilData } from './tutor-student-page/tutor-student-page.interface';

@Injectable({
  providedIn: 'root',
})
export class TutorPanelService {
  public showResults$ = new BehaviorSubject(false);
  private pupilsList = [];

  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  setPupilsList(pupils: any) {
    return (this.pupilsList = pupils);
  }

  getPupilsList() {
    return this.pupilsList;
  }

  public getPupils(): Observable<IPupilData[]> {
    return this.http.get('/b2c/v1.0/tutors/getpupilsbytutor').pipe(
      map(r => r.pupils),
      catchError(err => this.handleError(err)),
    );
  }

  getPupilByCode(code) {
    return this.http.get('/b2c/v1.0/tutors/getuserinfobycode?registrationCode=' + code).pipe(
      map(r => r),
      catchError(err => this.handleError(err)),
    );
  }

  getTutorUserInfoByCode(code: string): Observable<ITutorPupilInterface> {
    return this.http.get('/b2c/v1.0/tutors/getuserinfobycode?registrationCode=' + code).pipe(
      map(r => r),
      catchError(err => this.handleError(err)),
    );
  }

  public getSessionList(userId: string): Observable<ISessionListResponse> {
    return this.http.get(`/api/v1.0/player/sessions/list?userId=${userId}`).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  checkUser(userId, screeningTestId) {
    let object = {
      userId: userId,
      screeningTestId: screeningTestId,
    };
    return this.http.get('/b2c/v1.0/tutors/getscreeningtestaccessbyuserid', object).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getTestsAccessesByUser(userId: string): Observable<IScreeningTestsAccessesResponse> {
    return this.http.get(`/b2c/v1.0/tutors/getscreeningtestsaccessesbyuserid?userId=${userId}`).pipe(
      map(r => r),
      catchError(err => this.handleError(err)),
    );
  }

  openTestAccess(userId, screeningTestId) {
    let data = {
      userId: userId,
      screeningTestId: screeningTestId,
      isTestPassing: true,
      isTestResult: false,
    };
    return this.http.put('/b2c/v1.0/tutors/changescreeningtestaccess', data).pipe(
      map(r => r),
      catchError(err => this.handleError(err)),
    );
  }
  openResultsAccess(userId, screeningTestId) {
    let data = {
      userId: userId,
      screeningTestId: screeningTestId,
      isTestPassing: true,
      isTestResult: true,
    };
    return this.http.put('/b2c/v1.0/tutors/changescreeningtestaccess', data).pipe(
      map(r => r),
      catchError(err => this.handleError(err)),
    );
  }

  showResults() {
    this.showResults$.next(true);
  }
  closeResults() {
    this.showResults$.next(false);
  }

  getPupilPage(): Observable<any> {
    return this.showResults$.asObservable();
  }
}
