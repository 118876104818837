<section class="add-class">
  <div class="profile-section">
    <div class="profile-row">
      <div class="profile-column _1">
        <form id="email-form" [formGroup]="form">
          <h1>{{ 'SHARED.CREATING_NEW_CLASS' | translate }}</h1>
          <div class="choice">
            <div class="subtitle-class">Класс</div>
            <div class="select-class">
              <div class="dropdown-inline cat" #numberFilter>
                <div class="dropdown-inline-container w-dropdown">
                  <div class="dd-input-field">
                    <input
                      tabindex="-1"
                      autocomplete="new-number"
                      type="text"
                      class="class-field w-input field-small"
                      name="number"
                      maxlength="25"
                      placeholder="Цифра"
                      formControlName="number"
                      [class.error-field]="submitted && f.number.errors"
                      [class.active]="selectedNumber"
                      (click)="numberDrop.classList.toggle('w--open')"
                      (focus)="fieldFocusNumber($event)"
                      (blur)="fieldFocusOutNumber($event)"
                    />
                    <div class="dd-icon-inline w-icon-dropdown-toggle"></div>
                  </div>
                  <nav class="dd-prof-filter-list w-dropdown-list" #numberDrop>
                    <div class="dd-prof-filter-div">
                      <div class="filter-list-row">
                        <div class="filter-list-heading">Цифра</div>
                      </div>
                      <div class="prof-categories-links child-dropdown-column">
                        <a
                          class="prof-categories-link w-inline-block"
                          *ngFor="let number of numbersClass"
                          (click)="setNumber(number); numberDrop.classList.remove('w--open')"
                          [class.dd-inline-current]="numbersClass.indexOf(number.value) !== -1"
                        >
                          <div class="category-text-link">{{ number.value }}</div>
                        </a>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div>
                <div class="dropdown-inline cat" #letterFilter>
                  <div class="dropdown-inline-container w-dropdown">
                    <div class="dd-input-field">
                      <input
                        *ngIf="!missLetterToggle"
                        tabindex="-1"
                        autocomplete="new-letter"
                        type="text"
                        class="class-field w-input field-small"
                        name="letter"
                        maxlength="25"
                        placeholder="Буква"
                        formControlName="letter"
                        [class.error-field]="submitted && f.letter.errors"
                        [class.active]="selectedLetter"
                        (click)="letterDrop.classList.toggle('w--open')"
                        (focus)="fieldFocusLetter($event)"
                        (blur)="fieldFocusOutLetter($event)"
                      />
                      <input
                        *ngIf="missLetterToggle"
                        tabindex="-1"
                        autocomplete="new-letter"
                        type="text"
                        class="class-field w-input field-small"
                        name="letter"
                        maxlength="25"
                        placeholder="Название"
                        formControlName="letter"
                        [class.error-field]="submitted && f.letter.errors"
                        [class.active]="selectedLetter"
                      />
                      <div *ngIf="!missLetterToggle" class="dd-icon-inline w-icon-dropdown-toggle"></div>
                    </div>
                    <nav class="dd-prof-filter-list w-dropdown-list" #letterDrop>
                      <div class="dd-prof-filter-div">
                        <div class="filter-list-row">
                          <div class="filter-list-heading">Буква</div>
                        </div>
                        <div class="prof-categories-links child-dropdown-column letters-dd-column">
                          <a
                            class="prof-categories-link w-inline-block"
                            *ngFor="let letter of lettersClass"
                            (click)="setLetter(letter); letterDrop.classList.remove('w--open')"
                            [class.dd-inline-current]="lettersClass.indexOf(letter.value) !== -1"
                          >
                            <div class="category-text-link">{{ letter.value }}</div>
                          </a>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
                <a (click)="missingLetterToggle()">{{ !missLetterToggle ? 'Ввести вручную' : 'Выбрать букву из списка' }}</a>
              </div>
            </div>
            <div class="class-teacher">
              <div class="subtitle-class">Классный руководитель</div>

              <div class="class-teacher-form">
                <div class="class-name-column">
                  <input
                    class="class-field w-input"
                    type="text"
                    [ngClass]="submitted && f.lastName.errors"
                    formControlName="lastName"
                    maxlength="25"
                    data-name="{{ 'SHARED.FORM.FAMILY' | translate }}"
                    placeholder="{{ 'SHARED.FORM.FAMILY' | translate }}"
                  />
                </div>
                <div class="class-name-column">
                  <input
                    class="class-field w-input"
                    type="text"
                    [ngClass]="submitted && f.firstName.errors"
                    formControlName="firstName"
                    maxlength="25"
                    data-name="{{ 'SHARED.FORM.NAME' | translate }}"
                    placeholder="{{ 'SHARED.FORM.NAME' | translate }}"
                  />
                </div>
                <div class="class-name-column">
                  <input
                    class="class-field w-input"
                    type="text"
                    [ngClass]="submitted && f.middleName.errors"
                    formControlName="middleName"
                    maxlength="25"
                    data-name="{{ 'SHARED.FORM.MIDDLENAME' | translate }}"
                    placeholder="{{ 'SHARED.FORM.MIDDLENAME' | translate }}"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="class-buttons-row left">
            <a
              (click)="animateSubmit()"
              [ngClass]="{ disabled: !isAccessAllowed() && !saveInProcess }"
              [class.btn-waiting]="buttonWaiting"
              [class.btn-activated]="buttonActivated"
              [class.btn-activate]="!buttonActivate"
              class="button-animate btn-activate"
              id="btnActivation"
            >
              <span class="btn-icon"></span>
              <span class="btn-text" data-wait="Подождите" data-after="Сохранено">Сохранить</span>
            </a>
            <a routerLink="/schooladmin/classes" class="button white-button class-edit-button w-button">Отменить</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
