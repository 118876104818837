import { Component, ElementRef, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/shared/shared.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { REG_EXP } from '../../../../shared/global-constants/reg-exp';

import { SCHOOL_NUMBERS } from '../../../../shared/global-constants/school-numbers';
import { ISchool } from '../../../../shared/interfaces/ischool.interface';
import { ISchoolClass } from '../../../../shared/interfaces/ischoolclass.interface';
import { AppSettingsService } from '../../../../shared/services/appsettings.service';

import { SchoolAdminPanelService } from '../schooladmin-panel.service';
import { IAddUserInterface } from '../../../../shared/interfaces/iregistration.interface';

@Component({
  selector: 'prf-schooladmin-add-class',
  templateUrl: './schooladmin-add-class.component.html',
  styleUrls: ['./schooladmin-add-class.component.scss'],
})
export class SchooladminAddClassComponent implements OnInit {
  public readonly emailRegExp: RegExp = REG_EXP.emailRegExp;
  public readonly numbersClass: any = SCHOOL_NUMBERS;
  private appSettingsService = inject(AppSettingsService);
  public lettersClass: any = this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS');

  public form: UntypedFormGroup;

  submitted: boolean;
  schoolclass: ISchoolClass;
  errors: number[] = [];
  school: ISchool;
  saveInProcess: boolean = false;

  public selectedNumber: any;
  public selectedLetter: any;
  public missLetterToggle: boolean = false;

  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;

  @ViewChild('numberFilter') public readonly numberFilter: ElementRef;
  @ViewChild('numberDrop') public readonly numberDrop: ElementRef;

  @ViewChild('letterFilter') public readonly letterFilter: ElementRef;
  @ViewChild('letterDrop') public readonly letterDrop: ElementRef;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private schoolAdminPanelService: SchoolAdminPanelService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private utilsService: UtilsService,
  ) {
    this.getTranslation('SHARED.CREATING_NEW_CLASS')
      .pipe(take(1))
      .subscribe(translation =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation,
        }),
      );
  }

  ngOnInit() {
    this.setCreateClassControls();
  }

  private setCreateClassControls() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl('', [Validators.required]),
      firstName: new UntypedFormControl('', [Validators.required]),
      middleName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.pattern(this.emailRegExp)]),
      number: new UntypedFormControl('', [Validators.required]),
      letter: new UntypedFormControl('', [Validators.required]),
      phoneNumber: new UntypedFormControl('', []),
    });
  }

  removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }

  failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }

  animateSubmit() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.submit();
  }

  submit() {
    this.submitted = true;
    if (this.form.valid && !this.saveInProcess) {
      this.saveInProcess = true;
      this.sharedService
        .getSchool()
        .pipe(
          switchMap(school => {
            this.school = school;
            // Создание нового класса
            return this.createNewSchoolClassInfo();
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe(() => {
          this.saveInProcess = false;
          // нужно, чтобы выполнился запрос инфо по классам
          this.sharedService.needCall(true);
        });
    } else {
      this.failWaiting();
      return;
    }
  }

  fieldFocusNumber(e) {
    if (this.form.value.number) {
      this.f.number.setValue('');
    }
  }

  fieldFocusOutNumber(e) {
    const schoolNumber = this.numbersClass ? this.numbersClass.filter(t => t.value === this.form.value.number) : [];
    if (schoolNumber.length == 0) {
      this.f.number.setValue('');
    }
  }

  setNumber(number: any = {}) {
    this.f.number.setValue(number.value);
  }

  fieldFocusLetter(e) {
    this.lettersClass = this.lettersClass ? this.lettersClass : this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS');
    if (this.form.value.letter) {
      this.f.letter.setValue('');
    }
  }

  fieldFocusOutLetter(e) {
    const schoolLetter = this.lettersClass ? this.lettersClass.filter(t => t.value === this.form.value.letter) : [];
    if (schoolLetter.length == 0) {
      this.f.letter.setValue('');
    }
  }

  setLetter(letter: any = {}) {
    this.f.letter.setValue(letter.value);
  }

  get f() {
    return this.form.controls;
  }

  private createNewSchoolClassInfo(): Observable<any> {
    let createNewClass = {
      letter: this.form.value.letter,
      number: this.form.value.number,
      schoolId: this.school.id,
      teacherId: '00000000-0000-0000-0000-000000000000',
    };
    return this.schoolAdminPanelService.addNewSchoolClass(createNewClass).pipe(
      switchMap(response => {
        if (response === 'ALREADY_EXIST') {
          this.utilsService.openSnackBar('👎 Такой класс уже занят', 'error');
          this.f.number.setValue('');
          this.f.letter.setValue('');
          this.failWaiting();
          return of(null);
        } else {
          return this.createNewTeacher(response).pipe(
            tap(r => {
              this.router.navigate(['/schooladmin/class/' + response]);
              this.removeWaiting();
            }),
          );
        }
      }),
      catchError(err => {
        this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
        return throwError(err);
      }),
    );
  }

  private createNewTeacher(schoolClassId): Observable<any> {
    let createNewTeacher: IAddUserInterface = {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      middleName: this.form.value.middleName,
      schoolClassIds: schoolClassId ? [schoolClassId] : [],
      role: 'teacher',
    };
    return this.schoolAdminPanelService.addTeacher(createNewTeacher).pipe(
      tap(response => {
        if (!response) {
          return this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
        }
      }),
    );
  }

  public isAccessAllowed() {
    return (
      this.form.value.lastName &&
      this.form.value.firstName &&
      this.form.value.middleName &&
      this.form.value.number &&
      this.form.value.letter
    );
  }

  public missingLetterToggle() {
    this.missLetterToggle = !this.missLetterToggle;
    this.f.letter.setValue('');
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (!this.numberFilter?.nativeElement.contains(target)) {
      this.numberDrop.nativeElement.classList.remove('w--open');
    }
    if (!this.letterFilter?.nativeElement.contains(target)) {
      this.letterDrop.nativeElement.classList.remove('w--open');
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
