import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'app/core/services/http.service';
import { UserControlService } from 'app/shared/dashboard/backend-services/user-control.service';
import { ICompletedTestsCount } from 'app/shared/interfaces/icompletedtestscount.interface';
import { ISchoolClassExtended } from 'app/shared/interfaces/ischoolclassextended.interface';
import { CookieService } from 'ngx-cookie-service';
import { of, BehaviorSubject, Observable, throwError, TimeoutError, Subject } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { IResultsAccess } from '../interfaces/iresults.access';
import { SharedService } from '../shared.service';

@Injectable()
export class UserAccessService {
  userAccesses$ = new BehaviorSubject<IResultsAccess[]>(null);
  constructor(private http: HttpService) {}

  requestUserAccesses(userRole): Observable<any> {
    return this.getResultsAccess(userRole)
      .pipe(
        take(1),
        tap(r => {
          if (r.status === 'Success') {
            this.setAccesses(r.screeningTestsAccesses);
          } else {
            this.setAccesses([]);
          }
        }),
        catchError(err => {
          this.setAccesses([]);
          throw err;
        }),
      )
      .pipe(take(1));
  }

  getResultsAccess(userRole): Observable<any> {
    let domen: string;
    if (userRole === 'pupil') {
      domen = 'pupils';
    } else if (userRole === 'parent') {
      domen = 'parents';
    }
    return this.http.get('/b2c/v1.0/' + domen + '/getscreeningtestsaccesses').pipe(map(r => r));
  }

  setAccesses(accesses: IResultsAccess[]) {
    this.userAccesses$.next(accesses);
  }

  getUserAccesses(): Observable<any> {
    let userRole = localStorage.getItem('userRole');
    return this.userAccesses$.asObservable().pipe(
      switchMap(userAccesses => {
        if (userAccesses) {
          return of(userAccesses);
        } else {
          return this.requestUserAccesses(userRole).pipe(switchMap(r => this.userAccesses$.asObservable()));
        }
      }),
    );
  }
}
