<div *ngIf="slide">
  <h3 class="welcome-h3-stud">{{ 'SHARED.SILENCE_TEST_IN_PROGRESS' | translate }}</h3>
  <div class="progress-block">
    <div class="progress-text">{{ slide.slide.orderNumber }} из {{ countSteps }}</div>
    <div class="progress-bar">
      <div class="progress-bar-color" [ngStyle]="{ width: ((slide.slide.orderNumber - 1) * 100) / (countSteps - 1) + '%' }"></div>
    </div>
  </div>
  <p class="welcome-text-stud">Ты начал тест, не бросай это дело и результаты обрадуют тебя.</p>
  <div class="main-screen-buttons-row">
    <a routerLink="/test-na-professiyu/{{ sessionId }}" class="button welcome-button-stud testing w-button">Продолжить тест</a>
    <a routerLink="/test-na-professiyu" class="button welcome-button-stud white-button w-button">Начать тест заново</a>
  </div>
</div>
