import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { ProfessionCardsComponent } from 'app/pages/catalog/profession-page/profession-cards/profession-cards.component';
import { ChangePasswordComponent } from 'app/pages/catalog/settings-page/change-password/change-password.component';
import { RestorePasswordComponent } from 'app/pages/catalog/settings-page/restore-password/restore-password.component';
import { CourseDetailsComponent } from './pages/catalog/courses-page-without-filters/course-details/course-details.component';
import { CoursesPageComponent } from './pages/catalog/courses-page-without-filters/courses-page.component';
import { RecommendedCoursesComponent } from './shared/dashboard/courses/recommended-courses/recommended-courses.component';
import { InternshipCardsComponent } from './pages/catalog/internship-page/internship-cards.component';
import { InternshipCatalogComponent } from './pages/catalog/internship-page/internship-catalog/internship-catalog.component';
import { InternshipDetailsComponent } from './pages/catalog/internship-page/internship-details/internship-details.component';
import { ProfessionDetailsComponent } from './pages/catalog/profession-page/profession-details/profession-details.component';
import { UserProfileComponent } from './pages/catalog/user-profile-page/user-profile.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { PageNewsComponent } from './pages/news-page/page-news.component';
import { Page404Component } from './pages/page-404/page-404.component';
import { Page500Component } from './pages/page-500/page-500.component';
import { PageResultComponent } from './pages/results/result-page/page-result.component';
import { PortfolioPageComponent } from './shared/dashboard/teacher-student-page/portfolio-page/portfolio-page.component';
import { CoursesCatalogComponent } from './shared/dashboard/courses/courses-catalog/courses-catalog.component';
import { RestorePasswordKazahstanComponent } from './pages/catalog/settings-page/restore-password/restore-password-kazahstan/restore-password-kazahstan.component';
import { ChangePasswordKazahstanComponent } from './pages/catalog/settings-page/change-password/change-password-kazahstan/change-password-kazahstan.component';
import { PupilTestsHistoryPageComponent } from './pages/control-panel/pupil/pupil-tests-history-page/pupil-tests-history-page.component';
import { FavoritesComponent } from './pages/catalog/favorites/favorites.component';
import { LogoutComponent } from './shared/dashboard/logout/logout.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { PlayerV2Component } from '@profilum-components/prf-tests/player/default/player-v2.component';

export const routeConfig: Routes = [
  { path: 'test', component: PlayerV2Component },
  { path: 'results', component: PageResultComponent, canActivate: [AuthGuard] },
  {
    path: 'pupil-tests-history',
    component: PupilTestsHistoryPageComponent,
    canActivate: [AuthGuard],
  },
  { path: 'courses/:hrid', component: CourseDetailsComponent },

  {
    path: 'courses',
    component: CoursesPageComponent,
    children: [
      {
        path: '',
        component: RecommendedCoursesComponent,
        outlet: 'recommended-courses',
        data: { isCourses: true },
      },
      {
        path: '',
        component: CoursesCatalogComponent,
        outlet: 'courses-catalog',
        data: { isCourses: true },
      },
    ],
  },
  { path: 'events/:hrid', component: CourseDetailsComponent },
  { path: 'events', component: EventsPageComponent },
  { path: 'change_password', component: ChangePasswordComponent },
  { path: 'change_password_kz', component: ChangePasswordKazahstanComponent },
  { path: 'recovery_password', component: RestorePasswordComponent },
  { path: 'recovery_password_kz', component: RestorePasswordKazahstanComponent },
  { path: 'professions', component: ProfessionCardsComponent },
  { path: 'professions/:hrid', component: ProfessionDetailsComponent },
  { path: 'internships', component: InternshipCardsComponent },
  { path: 'internships/:hrid', component: InternshipDetailsComponent },
  {
    path: 'internships',
    component: InternshipCardsComponent,
    children: [
      {
        path: '',
        component: InternshipCatalogComponent,
        outlet: 'internship-catalog',
      },
    ],
  },
  {
    path: 'portfolio',
    component: PortfolioPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-favorites',
    component: FavoritesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'news',
    component: PageNewsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'news/:id',
    component: PageNewsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'path',
    component: WelcomePageComponent,
  },
  { path: 'logout', component: LogoutComponent },
  { path: '500', component: Page500Component },
  { path: '404', component: Page404Component },
  { path: '**', redirectTo: '/404' },
];
