import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RegionsService } from '../regions.service';

@Component({
  selector: 'prf-delete-region',
  templateUrl: './delete-region.component.html',
  styleUrls: ['./delete-region.component.scss'],
})
export class DeleteRegionComponent implements OnInit {
  dialogOpen: boolean = false;
  @Input() regionId: string;
  @Output() regionDeleted = new EventEmitter<any>();

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private regionsService: RegionsService, private utilsService: UtilsService) {}

  ngOnInit() {}

  openDialog() {
    this.dialogOpen = !this.dialogOpen;
  }

  delete() {
    this.regionsService
      .deleteRegion(this.regionId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        r => {
          this.utilsService.openSnackBar('👌 Регион удален', 'success');
          return setTimeout(_ => {
            this.regionDeleted.emit(this.regionId);
            this.openDialog();
          }, 1000);
        },
        err => {
          return this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте позже', 'error');
        },
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
