import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { SharedBaseModule } from '../../../../../shared-base.module';
import { IRecommendedProfession } from '../model';

@Component({
  selector: 'prf-recommended-profession-card',
  standalone: true,
  imports: [CommonModule, TranslateModule, SharedBaseModule],
  templateUrl: './recommended-profession-card.component.html',
  styleUrls: ['./recommended-profession-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendedProfessionCardComponent {
  @Input({ required: true }) public profession: any;
  @Input({ required: true }) public userRole: string;
  @Input() public transformedDescription: string;
  @Input() public isSalaryShown: boolean = true;
  @Input() public isProfessionChecked: boolean = false;
  @Input() public isGoal: boolean = false;

  @Output() public professionClickOutput = new EventEmitter<any>();
  @Output() public removeFavoriteClickOutput = new EventEmitter<any>();
  @Output() public addFavoriteClickOutput = new EventEmitter<any>();

  public professionClick(profession: IRecommendedProfession): void {
    this.professionClickOutput.emit(profession);
  }

  public removeFavoriteClick(profession: IRecommendedProfession): void {
    this.removeFavoriteClickOutput.emit(profession);
  }

  public addFavoriteClick(profession: IRecommendedProfession): void {
    this.addFavoriteClickOutput.emit(profession);
  }
}
