import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from 'app/pages/catalog/user-profile-page/user-profile.service';
import { InternshipsService } from 'app/shared/dashboard/internships/internships.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { AGE_DATA, COMPLEXITYS, COURSES_TYPES } from 'app/shared/global-constants/constants';
import { IApplyInternship } from 'app/shared/interfaces/iapplyinternship.interface';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import * as moment from 'moment';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InternshipService } from 'app/shared/dashboard/internships/internship.service';
import { InternshipDetailsService } from './internship-details.service';
import { Internship } from 'app/shared/classes/internship.class';
import { InternshipFormat } from 'app/shared/enums/internship-format.enum';
import { SharedService } from 'app/shared/shared.service';
import { Observable, of, Subject } from 'rxjs';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';

export const INTERNSHIP_FORMAT = [
  { value: InternshipFormat.OFFLINE, viewValue: 'Дистанционный' },
  { value: InternshipFormat.ONLINE, viewValue: 'Очный' },
  { value: InternshipFormat.MIXED, viewValue: 'Смешанный' },
];

@Component({
  selector: 'prf-internship-details',
  templateUrl: './internship-details.component.html',
  styleUrls: ['./internship-details.component.scss'],
})
export class InternshipDetailsComponent implements OnInit {
  subscription: any;
  internship: Internship;
  types: any = COURSES_TYPES;

  ageData: any = AGE_DATA;
  isFavorite: boolean = false;
  otherInternships: any = [];
  similarInternships: any = [];
  favoritesInternships: any;
  internshipEnrolls: any;
  userId: string;

  hrid: string;
  title: string;
  mapHeader: string;
  userRole: string;
  adminLevel: string = '';
  color: string;
  createDate: string;
  complexity: string = '';
  format: string = '';

  isEnrolled: boolean = false;
  dataFetched: boolean = false;
  popUpConfirming: boolean = false;
  addtoArchive: boolean = false;
  mapsLoaded: boolean = false;

  moment: any;
  testCompleted: boolean = false;
  applyInternship: IApplyInternship = {
    userId: '',
    internship: '',
    regionId: '',
    municnipalityId: '',
    schoolId: '',
    schoolClass: '',
  };
  school: ISchool;
  userClass: any;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private internshipDetailsService: InternshipDetailsService,
    private profileService: UserProfileService,
    private internshipService: InternshipService,
    private route: ActivatedRoute,
    private internshipsService: InternshipsService,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private sharedService: SharedService,
    private favoritesService: FavoritesService,
    private router: Router,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Стажировка' });
    this.userRole = localStorage.getItem('userRole');
    if (this.userRole && this.userRole.includes('admin')) {
      this.adminLevel = localStorage.getItem('adminLevel');
    }
    this.userId = localStorage.getItem('userId');
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.overlayService.show();
    //  todo check it!
    // this.setTitles();
    const sessionStatus = localStorage.getItem('sessionStatus');
    this.testCompleted = sessionStatus === 'Success';
    this.subscription = this.route.params.subscribe(params => {
      this.hrid = params['hrid'];
      window.scrollTo(0, 0);
      this.loadCurrentInternship();
    });
    this.overlayService.hide();
  }

  backButtonUrl() {
    return '/' + this.userRole + '/internships';
    // switch (this.userRole) {
    //   case 'admin':
    //     return '/admin/internships';
    //   case 'schooladmin':
    //     return '/schooladmin/internships';
    //   default:
    //     return '/internships';
    // }
  }

  loadCurrentInternship() {
    this.internshipService
      .getInternshipByHRID(this.hrid)
      .pipe(take(1))
      .subscribe(async data => {
        this.internship = data;
        const format = INTERNSHIP_FORMAT.find(c => c.value === this.internship.format);
        this.format = format ? format.viewValue : '';
        this.dataFetched = true;
      });
  }

  //TODO internships not existed in FavoritesTypes enum
  loadFavorites(): Observable<any> {
    return this.favoritesService.getUserFavorites(this.userId).pipe(
      tap(r => {
        this.favoritesInternships = r;
        this.internshipDetailsService.addFavoriteInternships(this.favoritesInternships);
        if (this.favoritesInternships) {
          let productIds = this.favoritesInternships.filter(el => el.productId === this.internship.id);
          this.isFavorite = productIds.length > 0;
        }
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  loadSchoolClass() {
    this.sharedService
      .getSchool()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(school => {
        this.school = school;
      });
    this.profileService
      .getSchoolClass()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(schcl => {
        this.userClass = schcl[0];
      });
  }
  // async addToGoals() {
  //   await this.loadSchoolClass();
  //   this.applyInternship = {
  //     userId: this.userId,
  //     internship: this.internship.name,
  //     regionId: this.internship.regionId,
  //     municnipalityId: this.internship.municipalityId,
  //     schoolId: localStorage.getItem('schoolId'),
  //     schoolClass: this.userClass.className,
  //   };
  //   await this.internshipDetailsService.sendAplyForInternship(this.applyInternship);
  //   await this.goToInternship();
  // }

  // async removeGoal() {
  // if (this.currentFavoriteIds) {
  //   this.currentFavoriteIds.forEach(favoriteId => this.goalsService.deleteGoal(favoriteId));
  //   setTimeout(() => this.router.navigate(['/' + this.userRole]), 1000);
  // }
  // }

  // async archive(internship) {
  //   internship.isArchived = true;
  //   await this.internshipsService.changeInternshipsDO(internship.id, internship);
  //   this.utilsService.openSnackBar('👌 Стажировка добавлена в архив', 'success');
  //   return setTimeout(_ => {
  //     this.router.navigate(['/admin/internships']);
  //   }, 2000);
  // }

  // loadOtherCourses() {
  //   if (
  //     this.internship.institutionId &&
  //     this.internship.institutionId !== '00000000-0000-0000-0000-000000000000'
  //   ) {
  //     let subscriptionOtherCourses = this.internshipService
  //       .getOtherInternships(this.internship.institutionId)
  //       .pipe(take(1))
  //       .subscribe(data => {
  //         this.otherInternships = data.filter(d => d.id !== this.internship.id);
  //       });

  //     let subscriptionSimilarCourses = this.internshipService
  //       .getSimilarInternship(this.internship.internships)
  //       .pipe(take(1))
  //       .subscribe(data => {
  //         this.similarInternships = data.filter(d => d.id !== this.internship.id);
  //       });
  //   }
  // }

  addToFavorite() {
    return this.internshipDetailsService
      .addToFavorites(this.internship.id, this.userId)
      .pipe(
        switchMap(() => this.loadFavorites()),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  removeFavorite() {
    let filteredFavorites = this.favoritesInternships.filter(el => el.productId === this.internship.id);
    let internshipFavoriteIds = filteredFavorites.map(el => el.id);
    if (internshipFavoriteIds) {
      internshipFavoriteIds.forEach(favoriteId => this.internshipDetailsService.deleteInternshipFromFavorites(favoriteId));
      this.isFavorite = false;
    }
  }

  goToInternship(): Observable<any> {
    if (!this.isEnrolled) {
      return this.internshipDetailsService.addClassEnroll(this.internship.id).pipe(tap(() => (this.isEnrolled = true)));
    } else {
      return of(null);
    }
  }

  cancelClassEnroll(): Observable<any> {
    if (this.isEnrolled) {
      return this.internshipDetailsService.removeClassEnroll(this.internship.id, this.userId).pipe(tap(() => (this.isEnrolled = true)));
    } else {
      return of(null);
    }
  }

  // getAges() {
  //   // let agesArray = [];
  //   // this.course.audience.forEach(age => agesArray.push(this.ageData.find(el => el.value === age).label));
  //   // let string = agesArray;
  //   // if (agesArray.length > 1) {
  //   //   let startSympols = agesArray[0].slice(0, agesArray[0].indexOf('-'));
  //   //   string = startSympols + agesArray[agesArray.length - 1].slice(agesArray[agesArray.length - 1].indexOf('-'))
  //   // }
  //   // return string
  //   if (this.internship.maxAgeAudience != 100 && this.internship.minAgeAudience != 0) {
  //     return (
  //       'от ' + this.internship.minAgeAudience + ' до ' + this.internship.maxAgeAudience + ' лет'
  //     );
  //   } else if (this.internship.maxAgeAudience == 100) {
  //     return this.internship.minAgeAudience + '+ лет';
  //   } else if (this.internship.minAgeAudience == 0) {
  //     return 'до ' + this.internship.maxAgeAudience + ' лет';
  //   }
  // }

  setTitles() {
    let path = this.router.routerState.snapshot.url;
    if (path.includes('/internships/')) {
      this.title = 'Стажировки';
      this.mapHeader = 'Стажировки на карте';
    }
  }

  deleteInternship(internshipID) {
    this.internshipsService
      .removeCurrentInternship(internshipID)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.popUpConfirming = !this.popUpConfirming;
        return this.router.navigate(['/' + this.userRole + '/internships']);
      });
  }

  checkMenuRole() {
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
        return true;
      default:
        return false;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
