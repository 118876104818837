import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { MaterialModule } from './modules/material.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PipesModule } from './pipes/pipes.module';
import { QuillModule } from 'ngx-quill';
import { YandexMapsComponent } from './yandex-maps/yandex-maps.component';
import { PageHeaderModule } from './dashboard/page-header/page-header.module';
import { PageFooterModule } from './dashboard/page-footer/page-footer.module';
import { TextMaskModule } from '@matheo/text-mask';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

@NgModule({
  declarations: [YandexMapsComponent],
  providers: [provideCharts(withDefaultRegisterables())],
  imports: [
    CommonModule,
    QuillModule,
    TranslateModule,
    MaterialModule,
    ClipboardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    ContentLoaderModule,
    NgCircleProgressModule,
    PipesModule,
    PageHeaderModule,
    PageFooterModule,
    TextMaskModule,
  ],
  exports: [
    CommonModule,
    QuillModule,
    TranslateModule,
    MaterialModule,
    TranslateModule,
    ClipboardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    ContentLoaderModule,
    NgCircleProgressModule,
    PipesModule,
    YandexMapsComponent,
    PageHeaderModule,
    PageFooterModule,
    TextMaskModule,
  ],
})
export class SharedBaseModule {}
