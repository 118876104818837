import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Gender } from '../../../../shared/enums/gender.enums';
import { SchoolAdminPanelService } from '../schooladmin-panel.service';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { IAddUserInterface } from '../../../../shared/interfaces/iregistration.interface';

@Component({
  selector: 'prf-schooladmin-add-students',
  templateUrl: './schooladmin-add-students.component.html',
  styleUrls: ['./schooladmin-add-students.component.scss'],
})
export class SchooladminAddStudentsComponent implements OnInit {
  // @Output() showAddPupils = new EventEmitter();
  @Output() showEditPupils = new EventEmitter();
  @Input() pupils: any;
  @Input() teacherClass: any;
  @Input() schoolId: any;
  pupilsForm: UntypedFormGroup;
  users: any = [];
  submitted: boolean;
  newPupils: any = [];
  errors: number[] = [];
  saveInProcess: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private formBuilder: UntypedFormBuilder, private schoolAdminService: SchoolAdminPanelService) {}

  ngOnInit() {
    let array = [];
    if (this.pupils.length > 0) {
      array = this.pupils.map(user => this.createPupil(user));
    } else {
      let user = this.newUser();
      this.newPupils.push(user);
      array.push(this.createPupil(user));
    }

    this.pupilsForm = this.formBuilder.group({
      users: this.formBuilder.array(array),
    });
  }

  createPupil(user: any): UntypedFormGroup {
    return this.formBuilder.group({
      firstName: new UntypedFormControl(user.firstName, [Validators.required]),
      lastName: new UntypedFormControl(user.lastName, [Validators.required]),
      middleName: new UntypedFormControl(user.middleName, [Validators.required]),
      registrationCode: user.registrationCode,
      gender: user.gender,
      isActivated: user.isActivated,
    });
  }

  addPupil() {
    let user = this.newUser();
    this.newPupils.push(user);
    this.users = this.pupilsForm.get('users') as UntypedFormArray;
    this.users.push(this.createPupil(user));
    this.submitted = false;
  }

  submit() {
    this.submitted = true;
    if (this.pupilsForm.valid && !this.saveInProcess) {
      this.saveInProcess = true;
      // Обновление только измененных учеников
      if (this.pupilsForm.get('users').touched) {
        for (let i = 0; i < this.pupils.length; i++) {
          if (!this.pupils[i].isActivated) {
            // можно редактировать данные только для неавторизованных пользователей
            this.updatePupil(i, this.pupils[i], this.pupilsForm.value.users[i]);
          }
        }
      }

      let currentObservable$: Observable<any>;
      // Создание новых учеников
      if (this.pupilsForm.value.users.length > this.pupils.length) {
        let diff = this.pupils.length - this.pupilsForm.value.users.length;
        let dataForNewPupil = this.pupilsForm.value.users.slice(diff);
        currentObservable$ = this.createNewPupil(dataForNewPupil).pipe(
          tap(_newErrors => {
            this.errors.concat(_newErrors);
          }),
        );
      } else {
        currentObservable$ = of(null);
      }

      currentObservable$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(r => {
        this.saveInProcess = false;
        if (this.errors.length == 0) {
          this.hideEdit(true);
        }
      });
    } else {
      return;
    }
  }

  private updatePupil(pupilIndex, currentPupil, updatedPupil) {
    if (
      currentPupil.firstName !== updatedPupil.firstName ||
      currentPupil.lastName !== updatedPupil.lastName ||
      currentPupil.middleName !== updatedPupil.middleName ||
      currentPupil.gender !== updatedPupil.gender
    ) {
      currentPupil.firstName = updatedPupil.firstName;
      currentPupil.lastName = updatedPupil.lastName;
      currentPupil.middleName = updatedPupil.middleName;
      currentPupil.gender = updatedPupil.gender;
      this.schoolAdminService
        .updateRegistrationCode(this.createUpdateRegistrationCode(currentPupil))
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(resp => {
          if (!resp) {
            this.errors.push(pupilIndex);
          }
        });
    }
  }

  createUpdateRegistrationCode(currentPupil: any) {
    return {
      userId: currentPupil.userId,
      schoolId: this.schoolId,
      firstName: currentPupil.firstName,
      lastName: currentPupil.lastName,
      middleName: currentPupil.middleName,
      gender: currentPupil.gender,
    };
  }

  newUser() {
    return {
      firstName: '',
      lastName: '',
      middleName: '',
      registrationCode: '',
      gender: Gender.Male,
      schoolClassId: this.teacherClass.id,
      schoolId: this.teacherClass.schoolId,
      role: 'pupil',
    };
  }

  hideEdit(isUpdated) {
    // this.showAddPupils.emit(false);
    this.showEditPupils.emit(isUpdated);
  }

  public isMale(gender: string) {
    return gender === Gender.Male;
  }

  public setMale(pupil) {
    pupil.value.gender = Gender.Male;
  }

  public setFemale(pupil) {
    pupil.value.gender = Gender.Female;
  }

  createNewPupil(data: any): Observable<any> {
    let newErrors = [];

    let newPupilsArray$: Observable<any>[] = [];
    newPupilsArray$ = data.map((el, index) => {
      let pupil: IAddUserInterface = {
        firstName: data[index].firstName,
        lastName: data[index].lastName,
        middleName: data[index].middleName,
        gender: data[index].gender,
        role: 'pupil',
        schoolClassIds: this.teacherClass.id ? [this.teacherClass.id] : [],
        schoolClassNumber: this.teacherClass.number,
        schoolClassLetter: this.teacherClass.letter,
      };
      return this.schoolAdminService.createPupil(pupil);
    });

    return observableForkJoin(newPupilsArray$).pipe(
      switchMap((newPupilsArray: Array<any>) => {
        let newErrors = [];
        newPupilsArray.forEach((newPupil, index) => {
          if (!newPupil) {
            newErrors.push(index);
          }
        });

        return of(newErrors);
      }),
    );
  }

  removePupil(index: any) {
    const pupil = this.pupils[index];
    let control = <UntypedFormArray>this.pupilsForm.controls['users'];
    // Удаление control для данного ученика
    control.removeAt(index);

    if (pupil && pupil.registrationCode !== '') {
      this.schoolAdminService
        .removePupil(pupil.userId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(res => {
          if (res.status == 'Success') {
            this.pupils.splice(index, 1);
            this.newPupils.pop();
          }
        });
    } else {
      this.pupils.splice(index, 1);
      this.newPupils.pop();
    }
  }

  trackByFn(index, item) {
    return index;
  }

  get formDataArray() {
    return <UntypedFormArray>this.pupilsForm.get('users');
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
