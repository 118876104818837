import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { IProfessionFilter } from 'app/shared/interfaces/iprofessionfilter.interface';
import { BehaviorSubject, Observable, of, Subject, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class PupilPanelService {
  private colorMap = {
    'Административная работа': 1,
    'Продажи и торговое дело': 1,
    Управление: 1,
    'Экономика, бизнес и финансы': 1,
    'Инженерия, архитектура и проектирование': 6,
    'Естественные науки': 3,
    'Медицина и здравоохранение': 3,
    'Сельское и лесное хозяйство, рыболовство': 3,
    'Образование и педагогика, управление знаниями': 7,
    'Реклама, PR, развлечения и СМИ': 7,
    'Социальные и гуманитарные науки': 7,
    Юриспруденция: 7,
    'Производство и технологии': 2,
    'Строительные и технические работы, добыча': 2,
    'Транспорт и перевозки': 2,
    'Установка, обслуживание и ремонт': 2,
    'Информационные технологии и математика': 5,
    'Безопасность и обеспечение порядка': 4,
    'Военное дело': 4,
    Спорт: 4,
    'Искусство, творчество, дизайн': 8,
    'Сервис, питание и услуги': 1,
  };

  private goalProfession$ = new BehaviorSubject<any>(null);

  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  // получить список тестовых сессии ученика, упорядоченный по дате
  getTestingSessions(userId): Observable<any> {
    return this.http.get('/api/v1.0/player/sessions/list?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getResults(sessionId): Observable<any> {
    return this.http.get('/api/v1.0/results/page?sessionIds=' + sessionId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getColorSchemeNumber(fieldName) {
    return this.colorMap[fieldName] || 1;
  }

  getLastSession(userId: string): Observable<any> {
    return this.http.get('/b2c/v1.0/saas/getlastsession?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUserGoals(userId): Observable<any> {
    let params = {
      userId: userId,
      productType: 'Profession',
    };
    return this.http.get('/api/v1.0/profiles/favorites/getfavorites', params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUserProfessionsInGoalsByIds(ids: any): Observable<any> {
    let credentials: IProfessionFilter = {};

    credentials.isVisible = true;

    if (ids.length) {
      credentials.ids = [];
      ids.forEach(id => {
        credentials.ids.push(id);
      });
    } else return of([]);

    return this.http.post('/api/v1.0/catalog/professions/elasticsearch/search/filters', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUserSession(sessionId): Observable<any> {
    return this.http.get('/api/v1.0/player/sessions?sessionId=' + sessionId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUserTestsAccesses() {
    return this.http.get('/b2c/v1.0/pupils/getscreeningtestsaccesses').pipe(
      map(r => r),
      catchError(err => this.handleError(err)),
    );
  }

  getSessionList(userId: string): Observable<any> {
    return this.http.get('/api/v1.0/player/sessions/list?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  setGoalProfession(profession) {
    this.goalProfession$.next(profession);
  }
  getGoalProfession() {
    return this.goalProfession$.asObservable();
  }
}
