<div class="w-tab-pane w--tab-active">
  <div class="tab-pane adm">
    <a *ngIf="catalogType === 1" class="admin-add-event w-button" (click)="addNewProfessionFlag = !addNewProfessionFlag">{{
      'SHARED.ADD' | translate
    }}</a>
    <a *ngIf="catalogType === 1" class="download-catalog admin-add-event w-button" (click)="downloadProfessionsCatalog()">{{
      'SHARED.DOWNLOAD_CATALOG' | translate
    }}</a>
    <prf-add-profession
      *ngIf="addNewProfessionFlag"
      (closeAddProfession)="addNewProfessionFlag = !addNewProfessionFlag"
      [fields]="fields"
      [courses]="courses"
      [profilClasses]="profilClasses"
    >
    </prf-add-profession>
    <h2>Список профессий</h2>

    <div class="territory-objects">
      <div class="regions-row">
        <div>
          <label for="region" class="region-label">{{ 'SHARED.REGION' | translate }}:</label>
          <div class="div-space"></div>
        </div>
        <div class="dropdown-inline cat" #regionFilter>
          <div class="dropdown-inline-container w-dropdown" [ngClass]="{ disabled: isRegion }">
            <div
              class="dd-input-field"
              #regionField
              (click)="regionDrop.classList.toggle('w--open'); regionField.classList.toggle('opened')"
            >
              <div class="login-field w-input" [class.active]="selectedRegion">
                {{ selectedRegion.name }}
              </div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #regionDrop>
              <div class="dd-prof-filter-div">
                <div class="filter-list-row">
                  <div class="filter-list-heading">{{ 'SHARED.REGION' | translate }}</div>
                </div>
                <div class="prof-categories-links">
                  <a
                    class="prof-categories-link w-inline-block"
                    *ngFor="let region of regions"
                    (click)="selectRegion(region); regionDrop.classList.remove('w--open'); regionField.classList.remove('opened')"
                  >
                    <div class="category-text-link">{{ region.name }}</div>
                  </a>
                </div>
                <div
                  class="filter-list-reset"
                  (click)="resetData(); regionDrop.classList.remove('w--open'); regionField.classList.remove('opened')"
                >
                  Сбросить
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div class="municipalities-row" *ngIf="selectedRegion.id && !isPilotRegion && !isDefaultRegion">
        <div>
          <label for="municipality" class="region-label">Муниципалитет</label>
          <div class="div-space"></div>
        </div>
        <div class="dropdown-inline cat" #municipalityFilter>
          <div class="dropdown-inline-container w-dropdown" [ngClass]="{ disabled: isMunicipality }">
            <div
              class="dd-input-field"
              #municipalityField
              (click)="municipalityDrop.classList.toggle('w--open'); municipalityField.classList.toggle('opened')"
            >
              <div class="login-field w-input" [class.active]="selectedMunicipality">
                {{ selectedMunicipality.name }}
              </div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #municipalityDrop>
              <div class="dd-prof-filter-div">
                <div class="filter-list-row">
                  <div class="filter-list-heading">Муниципалитет</div>
                </div>
                <div class="prof-categories-links">
                  <a
                    class="prof-categories-link w-inline-block"
                    *ngFor="let municipality of municipalities | slice : 1"
                    (click)="
                      selectMunicipality(municipality);
                      municipalityDrop.classList.remove('w--open');
                      municipalityField.classList.remove('opened')
                    "
                  >
                    <div class="category-text-link">{{ municipality.name }}</div>
                  </a>
                </div>
                <div
                  class="filter-list-reset"
                  (click)="
                    resetMunicipalityData(); municipalityDrop.classList.remove('w--open'); municipalityField.classList.remove('opened')
                  "
                >
                  Сбросить
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <mat-form-field class="full-width">
      <input matInput type="search" class="form-control" placeholder="Введите поисковый запрос" [formControl]="searchProfessionField" />
      <a (click)="clearSearch()" class="search-clear show w-inline-block"></a>
    </mat-form-field>

    <br />
    <prf-profession-filter [categoryList]="categoryList" (selectedCategory)="getProfessionsByCategory($event)"> </prf-profession-filter>
    <br />

    <div class="professions-count-row">
      <div class="courses-number">Количество профессий: {{ professionsCount }}</div>
    </div>

    <div class="wrapper">
      <div class="table">
        <div class="row table-header">
          <div class="cell">№</div>
          <div class="cell">Название</div>
          <!-- <div class="cell">Муниципалитет</div> -->
          <div class="cell">HRID</div>
          <div class="cell">средняя зарплата</div>
          <div class="cell">макс зарплата</div>
          <div class="cell">мин зарплата</div>
          <div class="cell">ссылка</div>
          <div class="cell" *ngIf="userRole != 'schooladmin'">
            {{ 'SHARED.EDIT_BUTTON' | translate }}
          </div>
        </div>
        <div class="row search-row" *ngFor="let search of searches; let count = index">
          <div class="cell" data-title="number">{{ count + 1 }}</div>
          <div class="cell" data-title="name">{{ search.name }}</div>
          <!-- <div class="cell" data-title="municipiality">{{getMunicipalityName(search.municipalityId)}}</div> -->
          <div class="cell" data-title="hrid">{{ search.hrid }}</div>
          <div class="cell" data-title="averageSalary">{{ search.averageSalary }}</div>
          <div class="cell" data-title="maxSalary">{{ search.maxSalary }}</div>
          <div class="cell" data-title="minSalary">{{ search.minSalary }}</div>
          <div class="cell" data-title="LINK">
            <a mat-button routerLink="/professions/{{ search.hrid }}" target="_self">Перейти</a>
          </div>
          <div class="cell" data-title="SETTINGS" *ngIf="userRole != 'schooladmin'">
            <a (click)="setCurrentProfessionFromSearches(search)" class="event-edit-btn w-inline-block"></a>
            <a class="delete-event w-inline-block" (click)="showDeleteProfession(search.id)"></a>
          </div>
        </div>
        <ng-container *ngIf="(professions && professions.length > 0) || (searches && searches.length > 0); else emptyProfessions">
          <div class="row" *ngFor="let profession of professions; let count = index">
            <div class="cell" data-title="number">{{ count + 1 }}</div>
            <div class="cell" data-title="name">{{ profession.name }}</div>
            <!-- <div class="cell" data-title="municipiality">{{getMunicipalityName(profession.municipalityId)}}</div> -->
            <div class="cell" data-title="hrid">{{ profession.hrid }}</div>
            <div class="cell" data-title="averageSalary">{{ profession.averageSalary }}</div>
            <div class="cell" data-title="maxSalary">{{ profession.maxSalary }}</div>
            <div class="cell" data-title="minSalary">{{ profession.minSalary }}</div>
            <div class="cell" data-title="LINK">
              <a mat-button target="_blank" routerLink="/professions/{{ profession.hrid }}" target="_self">Перейти</a>
            </div>
            <div class="cell" data-title="SETTINGS" *ngIf="userRole != 'schooladmin'">
              <a
                (click)="setCurrentProfession(profession)"
                class="event-edit-btn w-inline-block"
                [ngClass]="{ disabled: isPilotRegion }"
              ></a>
              <a
                class="delete-event w-inline-block"
                (click)="showDeleteProfession(profession.id)"
                [ngClass]="{ disabled: isPilotRegion }"
              ></a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="setting">
      <div class="perform-task-lb lb show">
        <div class="lb-bg"></div>
        <div class="lb-block task-lb">
          <a
            [routerLink]="['/admin/copy-profession', currentProfession.hrid]"
            *ngIf="!isCopy"
            class="copy-profession copy-event-button w-inline-block"
          >
            <div class="edit-event-button-row">
              <div class="edit-icon-div">
                <img src="/assets/images/icons/files-copy-interface-symbol.svg" class="icon-initial-state" />
              </div>
              <div>{{ 'SHARED.CREATE_COPY' | translate }}</div>
            </div>
          </a>
          <a class="close-lb w-inline-block" (click)="closeEditWindow()">
            <img src="/assets/images/icons/close-lb.svg" />
          </a>
          <div class="tasl-lb-row">
            <div class="task-lb-column">
              <h3 class="task-lb-heading">{{ editingTitle }} «{{ currentProfession.name }}»</h3>
              <prf-edit-profession
                (updateValue)="closeEditWindow($event)"
                [profession]="currentProfession"
                [fields]="fields"
                [courses]="courses"
                [profilClasses]="profilClasses"
                [isCopy]="isCopy"
              >
              </prf-edit-profession>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="popUpConfirming">
      <div class="perform-task-lb lb show">
        <div class="lb-bg"></div>
        <div class="lb-block task-lb sm">
          <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
            <img src="/assets/images/icons/close-lb.svg" />
          </a>
          <div class="tasl-lb-row">
            <div class="task-lb-column">
              <h4 class="task-lb-heading-result">Удаление профессии</h4>
              <div class="task-result-text">Вы уверены, что хотите удалить профессию?</div>
              <a class="button attach-file-btn w-button" (click)="deleteProfession(removeID)">{{ 'BUTTONS.DELETE' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #emptyProfessions>
  <div class="event-empty-block">
    <div>
      <img src="/assets/images/menu/grey/Professions.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">
      {{ 'SHARED.FOR_YOU_REQUEST_NOT_PROFESSIONS' | translate }}
    </div>
  </div>
</ng-template>
