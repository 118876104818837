<div class="login-section">
  <div *ngIf="!isMobile" class="logo-link w-inline-block">
    <img class="new-logo" src="/assets/images/dashboard/profilum-new-logo.svg" alt="" />
  </div>

  <div *ngIf="isMobile" class="login-title _mobile">
    {{ 'REGISTRATION.PARENT_REGISTRATION' | translate }}
  </div>
  <div *ngIf="isMobile" class="login-text _mobile">
    {{ 'REGISTRATION.FILL_PARENT' | translate }}
  </div>
  <div *ngIf="loaded" class="login-container">
    <div class="tabs login w-tabs">
      <div class="w-tab-pane w--tab-active">
        <div class="tab-pane login">
          <div *ngIf="!isMobile" class="login-title fullscreen-title">
            {{ 'REGISTRATION.PARENT_REGISTRATION' | translate }}
          </div>
          <div *ngIf="!isMobile" class="login-text w-center">
            {{ 'REGISTRATION.FILL_PARENT' | translate }}
          </div>
          <div class="user-data-form-block w-form">
            <form [formGroup]="form" name="wf-form-Login" autocomplete="off">
              <div class="login-form-header"></div>
              <div class="login-form-row">
                <label for="lastName" class="login-label">Фамилия*</label>
                <div class="login-field-column">
                  <input
                    type="text"
                    autocomplete="off"
                    class="login-field w-input"
                    [class.error-field]="submitted && f.lastName.errors"
                    maxlength="25"
                    name="lastName"
                    data-name="lastName"
                    id="lastName"
                    formControlName="lastName"
                  />
                  <ng-container *ngIf="submitted && f.lastName.errors">
                    <mat-error *ngIf="f.lastName.errors.required"> Заполните поле </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="login-form-row">
                <label for="firstName" class="login-label">Имя*</label>
                <div class="login-field-column">
                  <input
                    type="text"
                    autocomplete="off"
                    class="login-field w-input"
                    [class.error-field]="submitted && f.firstName.errors"
                    maxlength="25"
                    name="firstName"
                    data-name="firstName"
                    id="firstName"
                    formControlName="firstName"
                  />
                  <ng-container *ngIf="submitted && f.firstName.errors">
                    <mat-error *ngIf="f.firstName.errors.required"> Заполните поле </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="login-form-row">
                <label for="email" class="login-label">Email*</label>
                <div class="login-field-column">
                  <input
                    type="email"
                    autocomplete="off"
                    class="login-field w-input"
                    [class.error-field]="emailChecked && f.email.errors"
                    maxlength="70"
                    name="email"
                    data-name="email"
                    id="email"
                    formControlName="email"
                    (focusout)="checkFormatEmail($event)"
                  />
                  <ng-container>
                    <mat-error *ngIf="!checkEmailPattern"> Проверьте правильность email </mat-error>
                    <mat-error *ngIf="f.email.errors?.duplicateUserName"> Такая электронная почта уже зарегистрирована </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="login-form-row">
                <label for="phone" class="login-label">Телефон*</label>

                <div class="login-field-column">
                  <div class="flex-row">
                    <input
                      type="text"
                      class="login-field w-input countryCode-input"
                      [class.error-field]="submitted && f.countryCode.errors"
                      [ngClass]="{ 'text-colored': f.phone.value }"
                      name="countryCode"
                      maxlength="25"
                      data-name="countryCode"
                      id="countryCode"
                      placeholder="+ 7"
                      [textMask]="{ mask: countryCodeMask, guide: false }"
                      formControlName="countryCode"
                      (focusout)="f.phone.value ? checkPhone() : null"
                    />
                    <div class="phone-wrapper">
                      <input
                        type="tel"
                        autocomplete="off"
                        class="login-field w-input phone-input"
                        [class.error-field]="phoneChecked && f.phone.errors"
                        name="phone"
                        maxlength="25"
                        data-name="phone"
                        id="phone"
                        [textMask]="{ mask: mask }"
                        formControlName="phone"
                        (input)="checkPhone()"
                        (focusin)="phoneChecked = false"
                        (paste)="onPhonePaste($event, userTypes.parent)"
                      />
                      <ng-container *ngIf="phoneChecked && f.phone.errors">
                        <mat-error *ngIf="f.phone.errors.maxLength"> Номер телефона слишком длинный </mat-error>
                        <mat-error *ngIf="f.phone.errors.required || f.phone.errors.pattern || f.phone.errors.notValid">
                          Проверьте правильность номера телефона
                        </mat-error>
                        <mat-error *ngIf="f.phone.errors.isUsed"> Данный телефон уже используется </mat-error>
                        <mat-error *ngIf="f.phone.errors.invalidNumber"> Неверный формат номера </mat-error>
                        <mat-error *ngIf="f.phone.errors.failed"> Произошла ошибка. Повторите попытку </mat-error>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="f.password" class="login-form-row">
                <label for="password" class="login-label">Код из смс*</label>
                <div class="form-full-width">
                  <div class="login-field-column" (focusout)="focusOutErrorChecking()" (focusin)="focusOutPasswordErrors = false">
                    <div class="login-input-container">
                      <input
                        *ngIf="isMaskedPassword"
                        type="password"
                        autocomplete="new-password"
                        class="login-field w-input short-right"
                        [class.error-field]="submitted && f.password.errors"
                        maxlength="25"
                        name="password"
                        data-name="password"
                        id="password"
                        formControlName="password"
                        (ngModelChange)="isValidPassword($event)"
                      />

                      <input
                        *ngIf="!isMaskedPassword"
                        type="text"
                        id="password"
                        autocomplete="new-password"
                        class="login-field w-input short-right"
                        [class.error-field]="submitted && f.password.errors"
                        maxlength="25"
                        name="password"
                        data-name="password"
                        formControlName="password"
                        (ngModelChange)="isValidPassword($event)"
                      />
                      <ng-container *ngIf="form.get('password').errors">
                        <div *ngIf="form.get('password').errors?.isOutdated" class="alert-message">
                          {{ 'REGISTRATION.CODE_IS_OUTDATED' | translate }}
                        </div>
                        <div *ngIf="form.get('password').errors?.notCorrect" class="alert-message">
                          {{ 'REGISTRATION.PASS_NOT_SUITS' | translate }}
                        </div>
                      </ng-container>
                      <div [ngClass]="{ eye: !isMaskedPassword, eye__closed: isMaskedPassword }" (click)="toggleMask()"></div>
                    </div>
                    <div class="promt-text">
                      <span *ngIf="activeTimer" class="timer-value">{{ timer$ | async }}</span
                      ><span>
                        <a *ngIf="smsAllowed" (click)="sendPasswordTwice()" [class.inactive]="activeTimer" class="promt-link">
                          {{ 'REGISTRATION.SEND_TWICE' | translate }}
                        </a>
                        <div *ngIf="!smsAllowed" class="promt-link-wrapper">
                          <a (click)="supportRequest()" [class.inactive]="activeTimer" class="promt-link">
                            {{ 'REGISTRATION.WRITE_TO_SUPPORT' | translate }}
                          </a>
                          <div [style.display]="supportAlert ? 'block' : 'none'" class="info-popup" #infopopup>
                            <p>Вы можете связаться с поддержкой через чат</p>
                            <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ADD CHILD -->
              <div class="add-child-section">
                <div class="profile-data-wrapper" [class.opened]="parentRegStep === 3">
                  <div class="add-child-text">Заполните данные о ребенке</div>
                  <form [formGroup]="formChild" class="child-add-form">
                    <div class="profile-data-row">
                      <div>
                        <label for="lastNameChild" class="login-label">{{ 'SHARED.FORM.FAMILY' | translate }}*</label>
                        <div class="div-space"></div>
                      </div>
                      <div class="profile-field-div">
                        <input
                          type="text"
                          autocomplete="off"
                          class="login-field w-input"
                          [class.error-field]="submitted && f.lastName.errors"
                          name="lastName"
                          maxlength="25"
                          data-name="lastName"
                          id="lastNameChild"
                          formControlName="lastName"
                        />
                      </div>
                    </div>

                    <div class="profile-data-row">
                      <div>
                        <label for="firstNameChild" class="login-label">{{ 'SHARED.FORM.NAME' | translate }}*</label>
                        <div class="div-space"></div>
                      </div>
                      <div class="profile-field-div">
                        <input
                          type="text"
                          autocomplete="off"
                          class="login-field w-input"
                          [class.error-field]="submitted && formChild.get('firstName').errors"
                          name="firstName"
                          maxlength="25"
                          data-name="firstName"
                          id="firstNameChild"
                          formControlName="firstName"
                        />
                      </div>
                    </div>

                    <div class="profile-data-row">
                      <div>
                        <label for="dateChild" class="login-label">Дата рождения*</label>
                        <div class="div-space"></div>
                      </div>
                      <div class="profile-field-div">
                        <input
                          type="text"
                          autocomplete="off"
                          class="login-field w-input"
                          [class.error-field]="submitted && formChild.get('date').errors"
                          [textMask]="{ mask: dateMask }"
                          name="date"
                          maxlength="25"
                          data-name="date"
                          id="dateChild"
                          formControlName="date"
                          (focusout)="checkFormatDate($event)"
                        />
                        <ng-container *ngIf="checkBirthday">
                          <mat-error *ngIf="formChild.get('date').errors || invalidDate">
                            {{ 'REGISTRATION.FORM_ALERT.CHECK_DATA' | translate }}
                          </mat-error>
                        </ng-container>
                      </div>
                    </div>

                    <div class="login-form-row">
                      <label for="phoneChild" class="login-label">Телефон*</label>

                      <div class="login-field-column">
                        <div class="flex-row">
                          <input
                            type="text"
                            class="login-field w-input countryCode-input"
                            [class.error-field]="submitted && fChild.countryCode.errors"
                            [ngClass]="{ 'text-colored': fChild.phone.value }"
                            name="countryCode"
                            maxlength="25"
                            data-name="countryCode"
                            id="countryCodeChild"
                            placeholder="+ 7"
                            [textMask]="{ mask: countryCodeMask, guide: false }"
                            formControlName="countryCode"
                          />
                          <div>
                            <input
                              type="tel"
                              autocomplete="off"
                              class="login-field w-input phone-input"
                              [class.error-field]="phoneChildChecked && fChild.phone.errors"
                              name="phone"
                              maxlength="25"
                              data-name="phone"
                              id="phoneChild"
                              [textMask]="{ mask: mask }"
                              formControlName="phone"
                              (focusout)="checkChildPhone()"
                              (focusin)="phoneChildChecked = false"
                              (paste)="onPhonePaste($event, userTypes.pupil)"
                            />

                            <ng-container *ngIf="phoneChildChecked && fChild.phone.errors">
                              <mat-error *ngIf="fChild.phone.errors.maxLength"> Номер телефона слишком длинный </mat-error>
                              <mat-error *ngIf="fChild.phone.errors.required || fChild.phone.errors.pattern">
                                Проверьте правильность номера телефона
                              </mat-error>
                              <mat-error *ngIf="fChild.phone.errors?.isUsed"> Данный телефон уже используется </mat-error>
                              <mat-error *ngIf="fChild.phone.errors?.isEqual"> Номер ребенка совпадает с номером родителя </mat-error>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="profile-data-row inline-row">
                      <div>
                        <label class="login-label">Пол</label>
                        <div class="div-space"></div>
                      </div>
                      <div class="gender-choice">
                        <div class="gender-choice-row">
                          <a class="gender-button active" (click)="setMale()" [class.active]="isMale(genderChoice)" #male> М </a>
                          <a class="gender-button right" (click)="setFemale()" [class.active]="!isMale(genderChoice)" #female> Ж </a>
                        </div>
                      </div>
                    </div>

                    <div class="terms-block">
                      <div class="terms _privacy">
                        <prf-custom-check-box [formControl]="fChild.privacyAccess">
                          Даю согласие на обработку моих
                          <a href="{{ getDocsRoute() + '/PrivacyPolicy.pdf' }}" target="_blank">{{
                            'SHARED.AGREEMENT.MY_PRIVATE_DATA' | translate
                          }}</a>
                        </prf-custom-check-box>
                      </div>
                      <div class="terms _agreement">
                        <prf-custom-check-box [formControl]="fChild.personalTerms">
                          Принимаю условия
                          <a href="/assets/docs/talent/Agreement.pdf" target="_blank">пользовательского соглашения</a>
                        </prf-custom-check-box>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="w-center-content">
                <button *ngIf="parentRegStep === 1" (click)="checkParent()" class="button-submit" [class.disabled]="!checkParentForm">
                  <span class="btn-text">Далее</span>
                </button>
                <button *ngIf="parentRegStep === 2" (click)="submitParent()" class="button-submit" [class.disabled]="!checkParentForm">
                  <span class="btn-text">Продолжить</span>
                </button>
                <button
                  *ngIf="parentRegStep === 3"
                  (click)="submit()"
                  class="button-submit"
                  [class.disabled]="!isAccessAllowed || !isAccessChildAllowed"
                >
                  <span class="btn-text">Зарегистрироваться</span>
                </button>
              </div>
            </form>
            <div class="login-form-footer">
              <div class="prosvet-registration">
                <a *ngIf="!isMobile" (click)="prosvetReg()" class="prosvet-link">Зарегистрироваться через Просвещение</a>
                <a *ngIf="isMobile" (click)="prosvetReg()" class="prosvet-link">Войти через ProsvID</a>
              </div>
              <div class="footer_separator">ИЛИ</div>
              <div class="footer_sign-in">Уже есть аккаунт?<a href="/login" class="sign-in-link">Войти</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <prf-page-footer> </prf-page-footer>
</div>
