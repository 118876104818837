import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PagesModule } from '../../pages/pages.module';
import { MaterialModule } from '../../shared/modules/material.module';
import { FilterByNamePipe } from '../../shared/pipes/filter-by-name.pipe';
import { SharedModule } from '../../shared/shared.module';
import { LandingB2cRoutingModule } from './landing-b2c-routing.module';
import { LoginB2cComponent } from './login-b2c/login-b2c.component';
import { LoginService } from './login-b2c/login.service';
import { CodeRegistrationB2cComponent } from './registration-b2c/code-registration-b2c/code-registration-b2c.component';
import { OpenRegistrationB2cComponent } from './registration-b2c/open-registration-b2c/open-registration-b2c.component';
import { RegistrationB2cComponent } from './registration-b2c/registration-b2c.component';
import { RegistrationPupilB2cComponent } from './registration-b2c/registration-pupil-b2c/registration-pupil-b2c.component';
import { RegistrationService } from './registration-b2c/registration.service';
import { TokenauthComponent } from './tokenauth/tokenauth.component';

@NgModule({
  imports: [MaterialModule, ReactiveFormsModule, PagesModule, SharedModule, LandingB2cRoutingModule],
  declarations: [
    LoginB2cComponent,
    RegistrationB2cComponent,
    CodeRegistrationB2cComponent,
    OpenRegistrationB2cComponent,
    RegistrationPupilB2cComponent,
    TokenauthComponent,
  ],
  exports: [RegistrationPupilB2cComponent],
  providers: [FilterByNamePipe, LoginService, RegistrationService],
})
export class LandingB2cModule {}
