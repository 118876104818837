import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

import { ICity } from 'app/shared/interfaces/icity';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { RegionsService } from '../../regions/regions.service';
import { CitiesService } from '../cities.service';

@Component({
  selector: 'prf-edit-city',
  templateUrl: './edit-city.component.html',
  styleUrls: ['./edit-city.component.scss'],
})
export class EditCityComponent implements OnInit {
  @Input() currentCity: ICity;
  @Input() regions: IRegion[];
  @Output() cityUpdated = new EventEmitter<ICity>();

  public allRegions: IRegion[];

  public currentRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };

  public municipalitiesByRegion: IMunicipality[];
  public currentMunicipality: IMunicipality = {
    id: '',
    hrid: '',
    name: '',
    regionId: '',
  };

  dialogOpen: boolean = false;
  buttonClicked: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private citiesService: CitiesService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit() {}

  // async getMunicipalitiesByRegion(regionId) {
  //   this.municipalitiesByRegion = (
  //     await this.municipalitiesService.getMunicipalitiesByRegion(regionId)
  //   ).sort((r1, r2) => r1.name.localeCompare(r2.name));
  // }

  edit() {
    this.dialogOpen = !this.dialogOpen;
    this.currentRegion = this.regions.find(r => r.id == this.currentCity.regionId);
    this.municipalitiesService
      .getMunicipalitiesByRegion(this.currentCity.regionId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(municipalities => {
        this.municipalitiesByRegion = municipalities.sort((r1, r2) => r1.name.localeCompare(r2.name));
        this.currentMunicipality = this.municipalitiesByRegion.find(m => m.id == this.currentCity.municipalityId);
        this.buttonClicked = true;
      });
  }

  onSubmit(valid: boolean) {
    if (valid && this.currentCity.hrid && this.currentCity.name && this.currentRegion.id && this.currentMunicipality.id) {
      const cityData: ICity = {
        id: this.currentCity.id,
        hrid: this.currentCity.hrid,
        name: this.currentCity.name,
        region: this.currentRegion.name,
        regionId: this.currentRegion.id,
        municipality: this.currentMunicipality.name,
        municipalityId: this.currentMunicipality.id,
      };

      this.citiesService
        .updateCity(cityData)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          response => {
            this.utilsService.openSnackBar('👌 Город обновлен', 'success');
            return setTimeout(_ => {
              this.dialogOpen = !this.dialogOpen;
              this.cityUpdated.emit(cityData);
            }, 300);
          },
          err => {
            return this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте позже', 'error');
          },
        );
    }
  }

  setMunicipalities(regionId: string) {
    if (regionId) {
      this.municipalitiesService
        .getMunicipalitiesByRegion(regionId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(municipalities => {
          this.municipalitiesByRegion = municipalities;
        });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
