import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MenuComponent } from 'app/shared/dashboard/menus/menu/menu.component';
import { MenuService } from 'app/shared/dashboard/menus/menu/menu.service';

import { RouterModule } from '@angular/router';
import { routeConfig } from 'app/pages/pages.router.config';
import {
  RecommendedProfessionsComponent,
} from './professions/welcome-choise-profession/recommended-professions/recommended-professions.component';
import {
  RecommendedClassesComponent,
} from './professions/welcome-choise-profession/recommended-classes/recommended-classes.component';
import { StepsTrajectoryComponent } from './professions/steps-trajectory/steps-trajectory.component';
import {
  NotSelectedProfessionComponent,
} from './professions/not-selected-profession/not-selected-profession.component';
import {
  WelcomeChoiseProfessionComponent,
} from './professions/welcome-choise-profession/welcome-choise-profession.component';
import {
  AddFirstProfessionModalComponent,
} from './professions/welcome-choise-profession/add-first-profession-modal/add-first-profession-modal.component';
import { ProfessionsService } from './professions/professions.service';
import { ParentTestPanelComponent } from './parent-test-panel/parent-test-panel.component';
import { QuestionBarComponent } from './question-bar/question-bar.component';

import { ReportsService } from './reports/reports.service';
import { ReportsComponent } from './reports/reports.component';
import { ClassResultsComponent } from './reports/class-results/class-results.component';
import {
  InterestProgressBarComponent,
} from './reports/class-results/interest-progress-bar/interest-progress-bar.component';
import { RadarChartComponent } from './reports/class-results/radar-chart/radar-chart.component';
import { TalentsComponent } from './reports/talents/talents.component';
import { SkillsComponent } from './reports/skills/skills.component';
import { InterestsComponent } from './reports/interests/interests.component';
import { StaticTaskModalComponent } from './static-task-modal/static-task-modal.component';
import { PageToastComponent } from './page-toast/page-toast.component';
import { NotPassedTestComponent } from './not-passed-test/not-passed-test.component';
import { RecommendationMenuComponent } from './recommendation-menu/recommendation-menu.component';

import { CoursesSearchComponent } from './courses/courses-catalog/courses-search/courses-search.component';
import { CourseCardComponent } from './courses/courses-catalog/course-card/course-card.component';
import { CoursesFilterComponent } from './courses/courses-catalog/courses-filter/courses-filter.component';
import {
  CoursesTypesFilterComponent,
} from './courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import {
  CoursesGroupFilterComponent,
} from './courses/courses-catalog/courses-filter/courses-group-filter/courses-group-filter.component';
import {
  CoursesFormatFilterComponent,
} from './courses/courses-catalog/courses-filter/courses-format-filter/courses-format-filter.component';
import { CoursesCatalogComponent } from './courses/courses-catalog/courses-catalog.component';
import { RecommendedCoursesComponent } from './courses/recommended-courses/recommended-courses.component';
import { CoursesFiltersService } from './courses/courses-catalog/courses-filter/courses-filters.service';
import { PaymentFormComponent } from './order/payment-form/payment-form.component';
import { GoalRecommendationComponent } from './goals/goal-recommendation/goal-recommendation.component';
import { SettingsComponent } from './settings/settings.component';
import { PasswordChangeComponent } from './settings/password-change/password-change.component';
import { EmailChangeComponent } from './settings/email-change/email-change.component';
import { PhotoChangeComponent } from './settings/photo-change/photo-change.component';
import { PhoneChangeComponent } from './settings/phone-change/phone-change.component';
import { SettingsService } from './settings/settings.service';
import { NewsComponent } from './news/news.component';
import { AddNewsComponent } from './news/add-news/add-news.component';
import { TeacherStudentPageComponent } from './teacher-student-page/teacher-student-page.component';
import {
  StudentTestResultsComponent,
} from './teacher-student-page/student-test-results/student-test-results.component';
import {
  DetailedProfessionCardComponent,
} from './professions/detailed-profession-card/detailed-profession-card.component';
import { GoalsTaskCardComponent } from 'app/shared/dashboard/goals/goals-task-card/goals-task-card.component';
import { ResultsComponent } from 'app/pages/results/results.component';
import {
  InterestsProgressBarComponent,
} from 'app/shared/dashboard/results/interests-progress-bar/interests-progress-bar.component';
import { CompareModalComponent } from 'app/shared/dashboard/results/compare-modal/compare-modal.component';
import {
  DiagramCompareModalComponent,
} from 'app/shared/dashboard/results/diagram-compare-modal/diagram-compare-modal.component';
import { TestSwitchComponent } from 'app/shared/dashboard/results/test-switch/test-switch.component';
import { SimilarProfessionsComponent } from './professions/similar-professions/similar-professions.component';
import { ResultsService } from 'app/pages/results/results.service';
import { GoalRecommendationService } from './goals/goal-recommendation/goal-recommendation.service';
import { PupilTasksComponent } from './goals/pupil-tasks/pupil-tasks.component';
import { SharedBaseModule } from '../shared-base.module';
import { InternshipsModule } from './internships/internships.module';
import { SharedService } from 'app/shared/shared.service';
import { UtilsService } from './backend-services/utils.service';
import { HistoryService } from './backend-services/history.service';
import { ProgrammsService } from './backend-services/programms.service';
import { PortfolioPageComponent } from './teacher-student-page/portfolio-page/portfolio-page.component';
import { PortfolioCardComponent } from './teacher-student-page/portfolio-page/portfolio-card/portfolio-card.component';
import {
  PortfolioAddModalComponent,
} from './teacher-student-page/portfolio-page/portfolio-add-modal/portfolio-add-modal.component';
import { AdminMenuComponent } from './menus/admin-menu/admin-menu.component';
import { SchooladminMenuComponent } from './menus/schooladmin-menu/schooladmin-menu.component';
import { TestResultComponent } from './goals/test-widget/test-result.component';
import { UserFavoritesComponent } from './teacher-student-page/user-favorites/user-favorites.component';
import { UserProfileSharedComponent } from './teacher-student-page/user-profile-shared/user-profile-shared.component';
import { PortfolioService } from './teacher-student-page/portfolio-page/portfolio.service';
import {
  PortfolioAddModalService,
} from './teacher-student-page/portfolio-page/portfolio-add-modal/portfolio-add-modal.service';
import { AdminClassesComponent } from './admin-classes/admin-classes.component';
import { AdminClassComponent } from './admin-classes/admin-class/admin-class.component';
import { ContentLoaderComponent } from './content-loader/content-loader.component';
import { SchooladminWelcomeComponent } from './admin-classes/schooladmin-welcome/schooladmin-welcome.component';
import { UserControlService } from './backend-services/user-control.service';
import { AdminClassService } from './admin-classes/admin-class/admin-class.service';
import { TutorMenuComponent } from './menus/tutor-menu/tutor-menu.component';
import { LogoutComponent } from './logout/logout.component';
import {
  PromotestCourseCardComponent,
} from './courses/promotest-courses/promotest-course-card/promotest-course-card.component';
import { AllEventsPromotestComponent } from './all-events-promotest/all-events-promotest.component';
import {
  FiltersTalentsComponent,
} from './all-events-promotest/filters-courses/filters-talents/filters-talents.component';
import { FiltersPriceComponent } from './all-events-promotest/filters-courses/filters-price/filters-price.component';
import { ProfessionFilterComponent } from './professions/profession-filter/profession-filter.component';
import { FiltersGroupComponent } from './all-events-promotest/filters-courses/filters-group/filters-group.component';
import { FiltersFormatComponent } from './all-events-promotest/filters-courses/filters-format/filters-format.component';
import { FiltersAgeComponent } from './all-events-promotest/filters-courses/filters-age/filters-age.component';
import { PromoActionPageComponent } from './promo-action-page/promo-action-page.component';
import { FiltersDateComponent } from './all-events-promotest/filters-courses/filters-date/filters-date.component';
import {
  FiltersInstitutionsComponent,
} from './all-events-promotest/filters-courses/filters-institutions/filters-institutions.component';
import { PupilTestsHistoryComponent } from './teacher-student-page/pupil-tests-history/pupil-tests-history.component';
import {
  MotivationResultsComponent,
} from 'app/pages/results/motivation-results-page/motivation-results/motivation-results.component';
import {
  PromotestCourseDetailsComponent,
} from './courses/promotest-courses/promotest-course-details/promotest-course-details.component';
import { PageSwitcherComponent } from './page-switcher/page-switcher.component';
import { ProfessionCardNewComponent } from './professions/profession-card-new/profession-card-new.component';
import { ValuesResultsComponent } from 'app/pages/results/values-results-page/values-result/values-results.component';
import { PupilTalantCardComponent } from './cards/pupil-talant-card/pupil-talant-card.component';
import { NgOptimizedImage } from '@angular/common';
import {
  RecommendedProfessionCardComponent,
} from './professions/welcome-choise-profession/recommended-professions/recommended-profession-card/recommended-profession-card.component';
import { SwiperDirective } from '@profilum-directives/swiper/swiper.directive';
import { BaseChartDirective } from 'ng2-charts';
import { SpiderChartCustomComponent } from '@profilum-components/spider-chart-custom/spider-chart-custom.component';

@NgModule({
  declarations: [
    //professions
    RecommendedProfessionsComponent,
    RecommendedClassesComponent,
    StepsTrajectoryComponent,
    NotSelectedProfessionComponent,
    WelcomeChoiseProfessionComponent,
    AddFirstProfessionModalComponent,
    DetailedProfessionCardComponent,
    SimilarProfessionsComponent,
    ProfessionFilterComponent,
    ProfessionCardNewComponent,

    //reports
    ReportsComponent,
    ClassResultsComponent,
    InterestProgressBarComponent,
    RadarChartComponent,
    TalentsComponent,
    SkillsComponent,
    InterestsComponent,

    //courses
    CourseCardComponent,
    CoursesFilterComponent,
    CoursesSearchComponent,
    CoursesTypesFilterComponent,
    CoursesGroupFilterComponent,
    CoursesFormatFilterComponent,
    CoursesCatalogComponent,
    RecommendedCoursesComponent,
    PromotestCourseCardComponent,
    PromotestCourseDetailsComponent,

    //settings
    SettingsComponent,
    PasswordChangeComponent,
    EmailChangeComponent,
    PhotoChangeComponent,
    PhoneChangeComponent,

    //portfolio
    PortfolioPageComponent,
    PortfolioCardComponent,
    PortfolioAddModalComponent,

    //results
    ResultsComponent,
    CompareModalComponent,
    DiagramCompareModalComponent,
    InterestsProgressBarComponent,
    TestSwitchComponent,
    MotivationResultsComponent,
    ValuesResultsComponent,
    PupilTalantCardComponent,

    //goals
    PupilTasksComponent,
    GoalsTaskCardComponent,
    GoalRecommendationComponent,

    //others
    AdminMenuComponent,
    MenuComponent,
    SchooladminMenuComponent,
    TutorMenuComponent,

    ParentTestPanelComponent,
    QuestionBarComponent,
    TestResultComponent,
    StaticTaskModalComponent,
    PageToastComponent,
    NotPassedTestComponent,
    RecommendationMenuComponent,
    UserFavoritesComponent,
    PaymentFormComponent,
    UserProfileSharedComponent,

    NewsComponent,
    AddNewsComponent,

    AdminClassesComponent,
    AdminClassComponent,
    ContentLoaderComponent,
    SchooladminWelcomeComponent,
    TeacherStudentPageComponent,
    PupilTestsHistoryComponent,
    StudentTestResultsComponent,
    LogoutComponent,
    AllEventsPromotestComponent,
    FiltersTalentsComponent,
    FiltersPriceComponent,
    FiltersFormatComponent,
    FiltersGroupComponent,
    FiltersAgeComponent,
    PromoActionPageComponent,
    FiltersDateComponent,
    FiltersInstitutionsComponent,
    PageSwitcherComponent,
  ],
  imports: [
    SharedBaseModule,
    RouterModule.forChild(routeConfig),
    InternshipsModule,
    NgOptimizedImage,
    SwiperDirective,
    RecommendedProfessionCardComponent,
    BaseChartDirective,
    SpiderChartCustomComponent,
  ],
  exports: [
    InternshipsModule,

    //professions
    RecommendedProfessionsComponent,
    RecommendedClassesComponent,
    StepsTrajectoryComponent,
    NotSelectedProfessionComponent,
    WelcomeChoiseProfessionComponent,
    AddFirstProfessionModalComponent,
    DetailedProfessionCardComponent,
    SimilarProfessionsComponent,
    ProfessionFilterComponent,
    ProfessionCardNewComponent,

    //reports
    ReportsComponent,
    ClassResultsComponent,
    InterestProgressBarComponent,
    RadarChartComponent,
    TalentsComponent,
    SkillsComponent,
    InterestsComponent,

    //courses
    CourseCardComponent,
    RecommendedCoursesComponent,
    CoursesCatalogComponent,
    PromotestCourseCardComponent,
    PromotestCourseDetailsComponent,

    //settings
    SettingsComponent,
    PasswordChangeComponent,
    EmailChangeComponent,
    PhotoChangeComponent,
    PhoneChangeComponent,

    //results
    ResultsComponent,
    CompareModalComponent,
    DiagramCompareModalComponent,
    InterestsProgressBarComponent,
    TestSwitchComponent,
    MotivationResultsComponent,
    ValuesResultsComponent,
    PupilTalantCardComponent,

    //goals
    PupilTasksComponent,
    GoalsTaskCardComponent,
    GoalRecommendationComponent,

    //others
    AdminMenuComponent,
    MenuComponent,
    SchooladminMenuComponent,
    TutorMenuComponent,
    PageSwitcherComponent,

    ParentTestPanelComponent,
    QuestionBarComponent,
    TestResultComponent,
    StaticTaskModalComponent,
    PageToastComponent,
    NotPassedTestComponent,
    RecommendationMenuComponent,
    UserFavoritesComponent,
    PaymentFormComponent,

    UserProfileSharedComponent,
    PortfolioPageComponent,

    NewsComponent,
    AddNewsComponent,

    AdminClassesComponent,
    AdminClassComponent,
    ContentLoaderComponent,
    SchooladminWelcomeComponent,
    TeacherStudentPageComponent,
    PupilTestsHistoryComponent,
    StudentTestResultsComponent,
    LogoutComponent,
  ],
  providers: [
    SharedService,
    UtilsService,
    HistoryService,
    ProgrammsService,
    MenuService,
    ProfessionsService,
    ReportsService,
    CoursesFiltersService,
    SettingsService,
    PortfolioService,
    PortfolioAddModalService,
    ResultsService,
    GoalRecommendationService,
    UserControlService,
    AdminClassService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule {}
