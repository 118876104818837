import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AppSettingsService } from 'app/shared/services/appsettings.service';

@Component({
  selector: 'prf-results-profession-prestige',
  templateUrl: './results-profession-prestige.component.html',
  styleUrls: ['./results-profession-prestige.component.scss'],
})
export class ResultsProfessionPrestigeComponent implements OnInit, OnDestroy {
  public userRole: string = '';

  public profilumLogoUrl: string = AppSettingsService.settings.profilumLogoUrl;

  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'og:title', content: 'Результаты престижа профессий' });
  }

  ngOnInit() {
    this.userRole = localStorage.getItem('userRole');
    localStorage.removeItem('testType');
  }

  ngOnDestroy() {}
}
