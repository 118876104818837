import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { PortfolioAddModalService } from './portfolio-add-modal/portfolio-add-modal.service';

import { PortfolioService } from './portfolio.service';
import { Gender } from 'app/shared/enums/gender.enums';

@Component({
  selector: 'prf-portfolio-page',
  templateUrl: './portfolio-page.component.html',
  styleUrls: ['./portfolio-page.component.scss'],
})
export class PortfolioPageComponent implements OnInit, OnDestroy {
  @Input()
  set child(val: any) {
    this.userId = val.userId;
    this.userGender = val.gender;
    this.isChild = true;
  }

  @Input()
  set childPortfolios(val: any) {
    this.portfolios = val;
  }

  @Output() _childPortfolios = new EventEmitter();

  public loaded: boolean = false;
  public isChild: boolean = false;
  public userId: string = '';
  public portfolios: any;
  public userGender: string = '';
  public userRole: string = '';

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private meta: Meta, private portfolioService: PortfolioService, private portfolioAddModalService: PortfolioAddModalService) {
    this.meta.updateTag({ name: 'og:title', content: 'Портфолио' });
  }

  ngOnInit() {
    // Если портфоли вызвано не из админки/учителя
    // То берем userID, userGender из стора

    if (!this.isChild) {
      this.userId = localStorage.getItem('userId');
      this.userGender = localStorage.getItem('userGender');
    }
    this.userRole = localStorage.getItem('userRole');

    this.portfolioAddModalService.added$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(file => {
      if (file && file.id) {
        this.loadData();
      }
    });

    this.loadData();
    //  this.loaded = true;
  }

  public showModal() {
    this.portfolioAddModalService.show();
  }

  public isMale(gender: string) {
    return gender === Gender.Male;
  }

  private loadData() {
    this.portfolioService
      .getPortfolio(this.userId)
      .pipe(take(1))
      .subscribe(r => {
        this.portfolios = r;
        if (this.isChild) {
          this._childPortfolios.emit(this.portfolios);
        }
        this.portfolioAddModalService.portfolioId = this.portfolios.id;

        this.loaded = true;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
