<section class="login-section">
  <div *ngIf="!isMobile" class="logo-link w-inline-block">
    <img class="new-logo" src="/assets/images/dashboard/profilum-new-logo.svg" alt="" />
  </div>
  <div class="login-container">
    <article class="tabs login w-tabs">
      <div class="tabs-content w-tab-content">
        <div class="w-tab-pane w--tab-active">
          <div class="tab-pane login">
            <header>
              <h1>{{ 'PASSWORD.RESTORE' | translate }}</h1>
            </header>

            <div class="user-data-form-block w-form">
              <form [formGroup]="form" autocomplete="false">
                <section class="login-form-row" *ngIf="firstStep">
                  <label for="email" class="login-label">{{ 'PASSWORD.PLEASE_INDICATE_YOUR_EMAIL_PHONE' | translate }}</label>
                  <div class="restore-switcher">
                    <button class="restore-switcher-btn" (click)="isEmailRestore = true; clearForm()">
                      <span [class.active]="isEmailRestore" class="restore-swither-text">{{ 'PASSWORD.EMAIL' | translate }}</span>
                    </button>
                    <button class="restore-switcher-btn" (click)="isEmailRestore = false; clearForm()">
                      <span [class.active]="!isEmailRestore" class="restore-swither-text">{{ 'PASSWORD.PHONE' | translate }}</span>
                    </button>
                  </div>

                  <div class="login-field-column" *ngIf="isEmailRestore">
                    <input
                      type="email"
                      autocomplete="false"
                      class="login-field w-input"
                      maxlength="70"
                      name="email"
                      data-name="email"
                      id="email"
                      formControlName="email"
                    />
                    <mat-error *ngIf="userNotFound || errorResetToken">
                      <span *ngIf="userNotFound">{{ 'PASSWORD.USER_BY_EMAIL_NOT_FOUND' | translate : { email: form.value.email } }}</span>
                      <span *ngIf="errorResetToken">
                        {{ 'PASSWORD.USER_BY_EMAIL_NOT_FOUND' | translate : { email: form.value.email } }}</span
                      >
                    </mat-error>
                  </div>
                  <div class="login-field-column" *ngIf="!isEmailRestore">
                    <input
                      type="tel"
                      autocomplete="phone"
                      class="login-field w-input"
                      name="phone"
                      data-name="phone"
                      id="phone"
                      [textMask]="{ mask: phoneMask }"
                      formControlName="phone"
                      placeholder="+7 (___) ___-__-__"
                    />
                    <mat-error *ngIf="userNotFound || errorResetToken">
                      <span *ngIf="userNotFound"> {{ 'PASSWORD.USER_BY_PHONE_NOT_FOUND' | translate : { email: form.value.email } }}</span>
                      <span *ngIf="errorResetToken">{{
                        'PASSWORD.USER_BY_PHONE_NOT_FOUND' | translate : { email: form.value.email }
                      }}</span>
                    </mat-error>
                  </div>
                </section>
                <section class="recovery-second" *ngIf="secondStep && isEmailRestore">
                  <small class="annotation">{{ 'PASSWORD.SEND_MSG_ON_EMAIL' | translate : { email: form.value.email } }}</small>
                </section>
                <section class="recovery-second" *ngIf="secondStep && !isEmailRestore">
                  <small class="annotation">{{ 'PASSWORD.SEND_SMS_ON_PHONE' | translate : { email: form.value.email } }}</small>
                </section>
                <div class="navigation">
                  <a
                    *ngIf="firstStep"
                    class="nav-recovery-button next w-button"
                    [ngClass]="{ 'recovery-active': isAccessAllowed() }"
                    (click)="restorePassword()"
                  >
                    {{ 'PASSWORD.RESTORE' | translate }}
                  </a>
                  <a (click)="onMain()" class="nav-recovery-link">
                    <span>
                      {{ 'PASSWORD.ON_MAIN' | translate }}
                    </span>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</section>
