import { Injectable } from '@angular/core';

@Injectable()
export class BackendService {
  constructor() {}

  sendToServer(playerId, slide) {
    return;
  }
}
