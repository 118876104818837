import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationService } from 'app/landing/b2c/registration-b2c/registration.service';
import { ICity } from 'app/shared/interfaces/icity';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { SchoolClassesClass } from 'app/shared/classes/school-classes.class';
import { Gender } from 'app/shared/enums/gender.enums';
import { SCHOOL_NUMBERS } from 'app/shared/global-constants/school-numbers';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { DateValidator } from 'app/shared/instruments/validators/dateValidator';
import { SharedService } from 'app/shared/shared.service';
import { CitiesService } from 'app/pages/control-panel/admin/cities/cities.service';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { ChildrenAddService } from '../../../children-add/children-add.service';
import { IAddUserInterface } from '../../../../../../shared/interfaces/iregistration.interface';

export enum ERegTypes {
  B2C,
  PROSVETID,
  DEFAULT,
}
@Component({
  selector: 'prf-child-add-form',
  templateUrl: './child-add-form.component.html',
  styleUrls: ['./child-add-form.component.scss'],
})
export class ChildAddFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted: boolean;
  public dateMask = [/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public cities: ICity[] = [];
  public citiesByMunicipality: ICity[] = [];
  public genderChoice: string = Gender.Male;
  public role: string = 'pupil';
  public numbersClass: any = SCHOOL_NUMBERS;
  private appSettingsService = inject(AppSettingsService);
  public lettersClass: any = this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS');
  public selectedSchools: any;
  public selectedNumber: any;
  public selectedLetter: any;
  public allSchools: any[] = [];
  public response: any = {};
  public isShow: boolean = false;
  showInfoPopup: boolean = false;

  parentRegionId: string = '';
  parentMunicipalityId: string = '';

  public selectedCity: ICity;
  public schoolsFiltered: any[] = [];
  public schoolName: string;
  public searchCity: string;
  public searchSchool: string;
  public classes: SchoolClassesClass[] = [];
  public classesFetched: boolean = false;
  // выбор буквы и цифры класса
  public isClasses: boolean = false;
  public schoolID: string = '';
  public selectedSchoolClass: any;
  public checkBirthday: boolean = false;
  public schoolClass: string;
  public personalTerms: boolean = false;
  public invalidDate: boolean = false;

  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;

  public registrationFailed: boolean = false;
  public passFailed: boolean = false;
  public date: string;
  public newDate: Date = new Date();
  public moment: any = moment;
  public activeLocation = 'base';

  private _citiesView: any[] = [];
  private _schoolView: any[] = [];

  public dataFetched: boolean = false;

  public regionIdQuery: string = '';
  public cityEnabled: boolean = true;
  public schoolEnabled: boolean = true;
  public classEnabled: boolean = true;

  public code: string = '';
  public child: any = null;
  public codeLength: number = 6;
  public loading: boolean = false;
  inputsArray: any;

  userTag: string;
  registrationType: ERegTypes;

  @Output() addedChild = new EventEmitter<any>();

  private ngUnsubscribe$: Subject<any> = new Subject();

  isProsvetIdUser: boolean = false;

  constructor(
    private meta: Meta,
    private registrationService: RegistrationService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private citiesService: CitiesService,
    private childrenAddService: ChildrenAddService,
    private translateService: TranslateService,
    private sharedService: SharedService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Регистрация ребенка' });
    this.moment = moment;
    this.moment.locale('ru');
    this.parentRegionId = localStorage.getItem('regionId');
    this.parentMunicipalityId = localStorage.getItem('municipalityId');
  }

  ngOnInit() {
    if (localStorage.getItem('tag') === EUserTags[EUserTags.Prosv]) {
      this.isProsvetIdUser = true;
    }
    this.getCurrentLocation();
    this.getData()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.citiesView = this.parentMunicipalityId;
        this.initForm();
        this.dataFetched = true;
      });
  }

  initForm() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      middleName: new UntypedFormControl(null, [Validators.required]),
      date: new UntypedFormControl(null, [Validators.required, DateValidator()]),
      role: new UntypedFormControl(this.role, [Validators.required]),
      city: new UntypedFormControl(null, [Validators.required]),
      school: new UntypedFormControl(null, [Validators.required]),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      schoolClass: new UntypedFormControl(null, []),
    });
    this.form.removeControl('middleName');
    this.checkUrlRegistrationParams();
  }

  checkUrlRegistrationParams() {
    this.regionIdQuery = localStorage.getItem('regionIdParam');
    this.userTag = localStorage.getItem('tag');
    this.registrationType =
      this.userTag === EUserTags[EUserTags.Prosv]
        ? ERegTypes.PROSVETID
        : this.userTag === EUserTags[EUserTags.b2c]
        ? ERegTypes.B2C
        : ERegTypes.DEFAULT;
    let cityIdParam = localStorage.getItem('cityIdParam');
    if (this.registrationType === ERegTypes.PROSVETID) {
      let regionId = localStorage.getItem('regionIdParam');
      let cityId = localStorage.getItem('cityIdParam');

      let city = this.cities.find(city => city.id === cityId);
      if (city) {
        this.form.get('city').setValue({ name: city.name, data: city });
        this.onCityChange();
        this.cityEnabled = false;
        let schoolId = localStorage.getItem('schoolIdParam');
        let school = this.schoolView.find(school => school.data.id === schoolId);
        if (school) {
          this.form.get('school').setValue(school);
          this.schoolEnabled = false;
          this.getSchoolClassBySchool(school.data.id)
            .pipe(
              takeUntil(this.ngUnsubscribe$),
              tap(response => {
                let classId = localStorage.getItem('classIdParam');
                if (classId) {
                  let schoolClass = this.schoolClassView.find(schoolClass => schoolClass.data.id === classId);
                  if (schoolClass) {
                    this.form.get('schoolClass').setValue(schoolClass);
                    this.isClasses = true;
                    this.classEnabled = false;
                  }
                }
              }),
            )
            .subscribe();
        }
      }
    } else if (this.registrationType === ERegTypes.B2C || this.registrationType === ERegTypes.DEFAULT) {
      // todo все пользователи будут b2с пользователи, в будущем доработать условие и регу
      let regionId = AppSettingsService.settings.b2c.regionId;
      let cityId = AppSettingsService.settings.b2c.cityId;

      let city = this.cities.find(city => city.id === cityId);
      if (city) {
        this.form.get('city').setValue({ name: city.name, data: city });
        this.onCityChange();
        this.cityEnabled = false;
        let schoolId = AppSettingsService.settings.b2c.schoolId;
        let school = this.schoolView.find(school => school.data.id === schoolId);
        if (school) {
          this.form.get('school').setValue(school);
          this.schoolEnabled = false;
          this.getSchoolClassBySchool(school.data.id)
            .pipe(
              takeUntil(this.ngUnsubscribe$),
              tap(response => {
                let classId = AppSettingsService.settings.b2c.classId;

                if (classId) {
                  let schoolClass = this.schoolClassView.find(schoolClass => schoolClass.data.id === classId);
                  if (schoolClass) {
                    this.form.get('schoolClass').setValue(schoolClass);
                    this.isClasses = true;
                    this.classEnabled = false;
                  }
                }
              }),
            )
            .subscribe();
        }
      }
    } else {
      if (cityIdParam) {
        let city = this.cities.find(city => city.id === cityIdParam);

        if (city) {
          this.form.get('city').setValue({ name: city.name, data: city });
          this.onCityChange();
          this.cityEnabled = false;

          let schoolIdParam = localStorage.getItem('schoolIdParam');
          if (schoolIdParam) {
            let school = this.schoolView.find(school => school.data.id === schoolIdParam);
            if (school) {
              this.form.get('school').setValue(school);
              this.schoolEnabled = false;

              this.getSchoolClassBySchool(school.data.id)
                .pipe(
                  takeUntil(this.ngUnsubscribe$),
                  tap(response => {
                    let classIdParam = localStorage.getItem('classIdParam');

                    if (classIdParam) {
                      let schoolClass = this.schoolClassView.find(schoolClass => schoolClass.data.id === classIdParam);
                      if (schoolClass) {
                        this.form.get('schoolClass').setValue(schoolClass);
                        this.isClasses = true;
                        this.classEnabled = false;
                      }
                    }
                  }),
                )
                .subscribe();
            }
          }
        }
      }
    }
  }

  getData(): Observable<any> {
    return this.citiesService.getAllCities().pipe(
      switchMap(r => {
        this.cities = r;
        this.cities.forEach(city => {
          if (city.regionId === this.parentRegionId && city.municipalityId === this.parentMunicipalityId) {
            this.citiesByMunicipality.push(city);
          }
        });
        // this.citiesView = this.citiesByMunicipality.map(city => ({name: city.name, data: city}));
        return this.getSchools(this.citiesByMunicipality);
      }),
    );
  }

  getSchools(cities): Observable<any> {
    return this.childrenAddService.getCatalogSchools().pipe(
      tap(schools => {
        schools.forEach(school => {
          this.allSchools.push({
            city: school.city,
            cityId: school.cityId,
            value: school.number,
            viewValue: school.number,
            id: school.id,
          });
        });
      }),
    );
  }

  getCurrentLocation() {
    this.activeLocation = this.appSettingsService.currentLocation;
    this.getDocsRoute();
  }

  getDocsRoute() {
    switch (this.activeLocation) {
      case 'mo':
        return AppSettingsService.settings.docsPath.docsPathDefault;
      case 'kz':
        if (this.getKzRegionLanguage() === 'kz') {
          return AppSettingsService.settings.docsPath.docsPathRegion;
        } else if (this.getKzRegionLanguage() === 'ru') {
          return AppSettingsService.settings.docsPath.docsPathDefault;
        } else {
          return AppSettingsService.settings.docsPath.docsPathDefault;
        }
      default:
        return AppSettingsService.settings.docsPath.docsPathDefault;
    }
  }

  public getKzRegionLanguage(): string {
    let language = localStorage.getItem('selectedLanguage');
    if (language && ['ru', 'kz'].indexOf(language) > -1) {
      return language;
    } else {
      return 'kz';
    }
  }

  get f() {
    return this.form.controls;
  }

  setCity(obj: any = {}) {
    this.selectedCity = obj;
    if (obj != this.selectedCity) {
      this.f.school.setValue('');
      this.f.schoolClassNumber.setValue('');
      this.f.schoolClassLetter.setValue('');
    }
    this.f.city.setValue(obj.name);
  }

  onCityChange() {
    this.f.school.reset();
    if (this.f.city.value && this.f.city.value.data.name) {
      this.schoolView = this.f.city.value.data.name;
    }
  }
  onSchoolChange() {
    if (this.f.school.value && this.f.school.value.data.id) {
      this.getSchoolClassBySchool(this.f.school.value.data.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassNumber.reset();
        });
    }
  }

  getSchoolClassBySchool(id): Observable<any> {
    return this.registrationService.getSchoolClassesBySchool(id).pipe(
      tap((classes: SchoolClassesClass[]) => {
        this.classes = classes;
        this.classes && this.classes.length ? (this.isClasses = true) : null;
        this.classesFetched = true;
      }),
    );
  }

  public tooggleSelectClassType() {
    this.isClasses = !this.isClasses;
    this.f.schoolClass.reset();
    this.f.schoolClassNumber.reset();
    this.f.schoolClassLetter.reset();
  }

  set citiesView(municipalityId: any) {
    this._citiesView = municipalityId
      ? [...this.cities].filter(city => city.municipalityId == municipalityId).map(city => ({ name: city.name, data: city }))
      : [];
  }
  get citiesView() {
    return this._citiesView;
  }

  set schoolView(cityName) {
    this._schoolView = cityName
      ? [...this.allSchools].filter(school => school.city === cityName).map(school => ({ name: school.viewValue, data: school }))
      : [];
  }
  get schoolView() {
    return this._schoolView;
  }

  get schoolClassView() {
    return [...this.classes].map(schoolClass => ({
      name: schoolClass.number + schoolClass.letter,
      data: schoolClass,
    }));
  }

  get schoolClassNumber() {
    return [...SCHOOL_NUMBERS].map(number => ({ name: number.value, data: number }));
  }

  get schoolClassLetter() {
    return [...this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS')].map(letter => ({
      name: letter.value,
      data: letter,
    }));
  }

  get currentClassLetter() {
    return this.form.value.schoolClassLetter ? this.form.value.schoolClassLetter.name : this.form.value.schoolClass.data.letter;
  }

  get currentClassNumber() {
    return this.form.value.schoolClassNumber ? this.form.value.schoolClassNumber.name : this.form.value.schoolClass.data.number;
  }

  get isClassSelected(): boolean {
    return (this.f.schoolClassLetter.value && this.f.schoolClassNumber.value) || this.f.schoolClass.value;
  }

  public closeModal() {
    this.isShow = false;
  }

  public submit() {
    this.submitted = true;
    if (this.form.valid && this.validateAge()) {
      let credentials: IAddUserInterface;
      let birthday = moment.utc(this.f.date.value, 'DD/MM/YYYY', true).format();

      if (this.registrationType === ERegTypes.PROSVETID) {
        credentials = {
          firstName: this.form.value.firstName,
          lastName: this.form.value.lastName,
          middleName: this.form.value.middleName,
          schoolId: this.form.value.school.data.id,
          role: this.form.value.role,
          schoolClassIds: this.form.value.schoolClass.data.id ? [this.form.value.schoolClass.data.id] : [],
          schoolClassNumber: this.currentClassNumber,
          schoolClassLetter: this.currentClassLetter,
          birthday: birthday,
          gender: this.genderChoice,
          city: this.form.value.city.name,
          tag: localStorage.getItem('tag'),
        };
      } else {
        credentials = {
          firstName: this.form.value.firstName,
          lastName: this.form.value.lastName,
          middleName: this.form.value.middleName,
          schoolId: this.form.value.school.data.id,
          role: this.form.value.role,
          schoolClassNumber: this.currentClassNumber,
          schoolClassLetter: this.currentClassLetter,
          birthday: birthday,
          gender: this.genderChoice,
          city: this.form.value.city.name,
          tag: localStorage.getItem('tag'),
        };
      }
      this.regionIdQuery ? Object.assign(credentials, { regionId: this.regionIdQuery }) : null;
      if (credentials) {
        this.registrationFailed = false;
        this.passFailed = false;
        this.registrationService
          .addUser(credentials)
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(registrationResult => {
            this.response = registrationResult;
            // this.isShow = true;
            if (registrationResult) {
              if (registrationResult.userId == undefined) {
                if (registrationResult.status == 'Registration failed') {
                  // такой статус возвращается при неверном пароле
                  this.passFailed = true;
                } else {
                  this.registrationFailed = true;
                }
              } else {
                // Pupil succesfuly added
                localStorage.setItem('skipCaching', 'true');
                this.addedChild.emit(registrationResult);
                this.clearUrlStorageParams();
              }
            }

            // this.isShow = true; показать модальное окно с кодом, было решено пока отправлять в лк родителя
          });
      }
    }
  }

  clearUrlStorageParams() {
    localStorage.removeItem('regionIdParam');
    localStorage.removeItem('cityIdParam');
    localStorage.removeItem('schoolIdParam');
    localStorage.removeItem('classIdParam');
  }

  public isMale(gender: string) {
    return gender === Gender.Male;
  }

  public setMale() {
    this.genderChoice = Gender.Male;
  }

  public setFemale() {
    this.genderChoice = Gender.Female;
  }

  public validateAge(): boolean {
    let date = this.form.get('date').status === 'VALID' ? moment(this.form.value.date, 'DD-MM-YYYY').toDate() : null;
    this.invalidDate = false;

    //ребенок не старше maximumChildAge лет
    if (date && date?.getTime() / 1000 + AppSettingsService.settings.maximumChildAge * 365 * 24 * 60 * 60 > this.newDate.getTime() / 1000) {
      return true;
    }
    this.invalidDate = true;
    return false;
  }

  public checkFormatDate(event) {
    if (event) {
      return (this.checkBirthday = true);
    }
  }

  get isAccessAllowed() {
    if (this.isProsvetIdUser) {
      return this.form.value.lastName && this.form.value.firstName && this.form.value.city && this.personalTerms && this.validateAge();
    } else {
      return (
        this.form.value.lastName &&
        this.form.value.firstName &&
        this.form.value.city &&
        this.form.value.school &&
        (this.form.value.schoolClass || (this.form.value.schoolClassNumber && this.form.value.schoolClassLetter)) &&
        this.personalTerms &&
        this.validateAge()
      );
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
