import { Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { UserProfileOutletComponent } from '../catalog/user-profile-page/user-profile-outlet/user-profile-outlet.component';
import { AddCourseComponent } from '../catalog/courses-page/all-courses/active-courses/add-course/add-course.component';
import { MineCoursesComponent } from '../catalog/courses-page/mine-courses/mine-courses.component';
import { ActiveInternshipsComponent } from '../../shared/dashboard/internships/all-internships/active-internships/active-internships.component';
import { AddInternshipComponent } from '../../shared/dashboard/internships/all-internships/active-internships/add-internship/add-internship.component';
import { EditInternshipComponent } from '../../shared/dashboard/internships/all-internships/active-internships/edit-internship/edit-internship.component';
import { AllInternshipsComponent } from '../../shared/dashboard/internships/all-internships/all-internships.component';
import { ArchiveInternshipsComponent } from '../../shared/dashboard/internships/all-internships/archive-internships/archive-internships.component';
import { InternshipsComponent } from '../../shared/dashboard/internships/internships.component';
import { MineInternshipsComponent } from '../../shared/dashboard/internships/mine-internships/mine-internships.component';
import { NewsComponent } from '../../shared/dashboard/news/news.component';
import { SettingsComponent } from '../../shared/dashboard/settings/settings.component';
import { AdminPanelComponent } from './admin/admin-panel.component';
import { CentersComponent } from './admin/centers/centers.component';
import { CitiesComponent } from './admin/cities/cities.component';
import { MunicipalitiesComponent } from './admin/municipalities/municipalities.component';
import { RegionsComponent } from './admin/regions/regions.component';
import { SchoolsComponent } from './admin/schools/schools.component';
import { UsersComponent } from './admin/users/users.component';
import { ChildRegistrationComponent } from './parent/children-add/child-registration/child-registration.component';
import { ParentPanelComponent } from './parent/parent-panel.component';
import { PupilPanelComponent } from './pupil/pupil-panel.component';
import { SchooladminAddClassComponent } from './schooladmin/schooladmin-add-class/schooladmin-add-class.component';
import { SchoolAdminPanelComponent } from './schooladmin/schooladmin-panel.component';
import { ArchiveCoursesComponent } from '../catalog/courses-page/all-courses/archive-courses/archive-courses.component';
import { AllCoursesComponent } from '../catalog/courses-page/all-courses/all-courses.component';
import { ActiveCoursesComponent } from '../catalog/courses-page/all-courses/active-courses/active-courses.component';
import { EditCourseComponent } from '../catalog/courses-page/all-courses/active-courses/edit-course/edit-course.component';
import { CoursesComponent } from '../catalog/courses-page/courses.component';
import { InternshipDetailsComponent } from 'app/shared/dashboard/internships/all-internships/active-internships/internship/internship-details/internship-details.component';
import { SystemComponent } from './admin/system/system.component';
import { SystemReportsComponent } from './admin/system/reports/system-reports.component';
import { SystemStatisticsComponent } from './admin/system/statistics/system-statistics.component';

import { AdminClassesComponent } from 'app/shared/dashboard/admin-classes/admin-classes.component';
import { SchooladminClassTemplateComponent } from './schooladmin/schooladmin-class-template/schooladmin-class-template.component';
import { AdminClassTemplateComponent } from './admin/schools/admin-class-template/admin-class-template.component';
import { TutorPanelComponent } from './tutor/tutor-panel.component';
import { UniversitiesComponent } from '../catalog/universities-page/universities.component';
import { AddUniversityComponent } from '../catalog/universities-page/add-university/add-university.component';
import { EditUniversityComponent } from '../catalog/universities-page/edit-university/edit-university.component';
import { AllUniversitiesComponent } from '../catalog/universities-page/all-universities/all-universities.component';
import { UniversityDetailsComponent } from '../catalog/universities-page/all-universities/university-details/university-details.component';
import { SpecialtiesComponent } from '../catalog/specialties-page/specialties.component';
import { AllSpecialtiesComponent } from '../catalog/specialties-page/all-specialties/all-specialties.component';
import { SpecialtyDetailsComponent } from '../catalog/specialties-page/all-specialties/specialty-details/specialty-details.component';
import { AddSpecialtyComponent } from '../catalog/specialties-page/add-specialty/add-specialty.component';
import { EditSpecialtyComponent } from '../catalog/specialties-page/edit-specialty/edit-specialty.component';
import { SystemDashboardComponent } from './admin/system/dashboard/system-dashboard.component';
import { UpdateUserInfoComponent } from './pupil/update-user-info/update-user-info.component';
import { PromoTestComponent } from './parent/promo-test/promo-test.component';
import { ElasticCoursesComponent } from '../catalog/courses-page/elastic-courses/elastic-courses.component';
import { ElasticActiveCoursesComponent } from '../catalog/courses-page/elastic-courses/elastic-active-courses/elastic-active-courses.component';
import { TutorPupilsComponent } from './tutor/tutor-pupils/tutor-pupils.component';
import { ProfessionsComponent } from './admin/professions/professions/professions.component';
import { ProfessionsPanelComponent } from './admin/professions/professions-panel.component';
import { RecommendationsComponent } from '../catalog/recommendations/recommendations.component';
import { TutorStudentPageComponent } from 'app/pages/control-panel/tutor/tutor-shared/tutor-student-page/tutor-student-page.component';
import { ParentChildRegistrationComponent } from './parent/parent-child-container/parent-child-registration/parent-child-registration.component';

const InternshipRoutes: Routes = [
  {
    path: '',
    redirectTo: '/admin/internships/all-internships',
    pathMatch: 'full',
  },
  {
    path: 'all-internships',
    component: AllInternshipsComponent,
    children: [
      { path: '', component: ActiveInternshipsComponent },
      { path: 'active-internships', component: ActiveInternshipsComponent },
      { path: 'archive-internships', component: ArchiveInternshipsComponent },
    ],
  },
  {
    path: 'mine-internships',
    component: MineInternshipsComponent,
    children: [
      { path: '', component: ActiveInternshipsComponent },
      { path: 'active-internships', component: ActiveInternshipsComponent },
      { path: 'archive-internships', component: ArchiveInternshipsComponent },
    ],
  },
];

const SystemRoutes: Routes = [
  {
    path: '',
    redirectTo: '/admin/system/reports',
    pathMatch: 'full',
  },
  {
    path: 'reports',
    component: SystemReportsComponent,
  },
  {
    path: 'statistics',
    component: SystemStatisticsComponent,
  },
  { path: 'dashboard', component: SystemDashboardComponent },
];

const adminCoursesRoutes: Routes = [
  { path: '', redirectTo: '/admin/courses/all-courses', pathMatch: 'full' },
  {
    path: 'all-courses',
    component: AllCoursesComponent,
    children: [
      { path: '', component: ActiveCoursesComponent },
      { path: 'active-courses', component: ActiveCoursesComponent },
      { path: 'archive-courses', component: ArchiveCoursesComponent },
    ],
  },
  {
    path: 'mine-courses',
    component: MineCoursesComponent,
    children: [
      { path: '', component: ActiveCoursesComponent },
      { path: 'active-courses', component: ActiveCoursesComponent },
      { path: 'archive-courses', component: ArchiveCoursesComponent },
    ],
  },
  {
    path: 'search-index-courses',
    component: ElasticCoursesComponent,
    children: [
      { path: '', component: ElasticActiveCoursesComponent },
      { path: 'active-courses', component: ElasticActiveCoursesComponent },
    ],
  },
];

const adminEventsRoutes: Routes = [
  { path: '', redirectTo: '/admin/events/all-events', pathMatch: 'full' },
  {
    path: 'all-events',
    component: AllCoursesComponent,
    children: [
      { path: '', component: ActiveCoursesComponent },
      { path: 'active-events', component: ActiveCoursesComponent },
      { path: 'archive-events', component: ArchiveCoursesComponent },
    ],
  },
  {
    path: 'mine-events',
    component: MineCoursesComponent,
    children: [
      { path: '', component: ActiveCoursesComponent },
      { path: 'active-events', component: ActiveCoursesComponent },
      { path: 'archive-events', component: ArchiveCoursesComponent },
    ],
  },
  {
    path: 'search-index-events',
    component: ElasticCoursesComponent,
    children: [
      { path: '', component: ElasticActiveCoursesComponent },
      { path: 'active-events', component: ElasticActiveCoursesComponent },
    ],
  },
];

export const ControlPanelRoutes: Routes = [
  {
    path: 'pupil',
    //redirectTo: '/recommendations',
    component: PupilPanelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'recommendations',
    component: RecommendationsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'pupil/:profession', component: PupilPanelComponent, canActivate: [AuthGuard] },
  {
    path: 'schooladmin',
    component: SchoolAdminPanelComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'classes', pathMatch: 'full' },
      { path: 'classes', component: AdminClassesComponent },
      { path: 'class/:classID', component: SchooladminClassTemplateComponent },
      { path: 'new-class', component: SchooladminAddClassComponent },
      {
        path: 'courses',
        component: CoursesComponent,
        children: [
          { path: '', redirectTo: '/schooladmin/courses/all-courses', pathMatch: 'full' },
          {
            path: 'all-courses',
            component: AllCoursesComponent,
            children: [
              { path: '', component: ActiveCoursesComponent },
              { path: 'active-courses', component: ActiveCoursesComponent },
              { path: 'archive-courses', component: ArchiveCoursesComponent },
            ],
          },
          {
            path: 'mine-courses',
            component: MineCoursesComponent,
            children: [
              { path: '', component: ActiveCoursesComponent },
              { path: 'active-courses', component: ActiveCoursesComponent },
              { path: 'archive-courses', component: ArchiveCoursesComponent },
            ],
          },
        ],
      },
      { path: 'create-course', component: AddCourseComponent },
      { path: 'edit-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
      { path: 'copy-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
      { path: 'profile', component: UserProfileOutletComponent },
      { path: 'internships', component: InternshipsComponent, children: InternshipRoutes },
      { path: 'internships/:hrid', component: InternshipDetailsComponent },
    ],
  },
  {
    path: 'admin',
    component: AdminPanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        children: [
          { path: 'schools', component: SchoolsComponent },
          { path: 'schools/:schoolId', component: AdminClassesComponent },
          { path: 'schools/class/:classID', component: AdminClassTemplateComponent },
          { path: 'professions', component: ProfessionsPanelComponent },
          { path: 'copy-profession/:hrid', component: ProfessionsComponent, pathMatch: 'full' },
          { path: 'news', component: NewsComponent },
          { path: 'centers', component: CentersComponent },
          {
            path: 'courses',
            component: CoursesComponent,
            children: adminCoursesRoutes,
          },
          {
            path: 'events',
            component: CoursesComponent,
            children: adminEventsRoutes,
          },
          { path: 'create-course', component: AddCourseComponent },
          { path: 'edit-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
          { path: 'copy-course/:hrid', component: EditCourseComponent, pathMatch: 'full' },
          {
            path: 'universities',
            component: UniversitiesComponent,
            children: [
              { path: '', redirectTo: '/admin/universities/all-universities', pathMatch: 'full' },
              {
                path: 'all-universities',
                component: AllUniversitiesComponent,
              },
            ],
          },
          {
            path: 'universities/:id',
            component: UniversityDetailsComponent,
            pathMatch: 'full',
          },
          { path: 'create-university', component: AddUniversityComponent },
          { path: 'edit-university/:id', component: EditUniversityComponent, pathMatch: 'full' },

          {
            path: 'specialties',
            component: SpecialtiesComponent,
            children: [
              { path: '', redirectTo: '/admin/specialties/all-specialties', pathMatch: 'full' },
              {
                path: 'all-specialties',
                component: AllSpecialtiesComponent,
              },
            ],
          },
          {
            path: 'specialties/:id',
            component: SpecialtyDetailsComponent,
            pathMatch: 'full',
          },
          { path: 'create-specialty', component: AddSpecialtyComponent },
          { path: 'edit-specialty/:id', component: EditSpecialtyComponent, pathMatch: 'full' },

          { path: 'regions', component: RegionsComponent },
          { path: 'municipalities', component: MunicipalitiesComponent },
          { path: 'cities', component: CitiesComponent },
          { path: 'users', component: UsersComponent },
          { path: 'profile', component: UserProfileOutletComponent },
          { path: 'internships', component: InternshipsComponent, children: InternshipRoutes },
          { path: 'internships/:hrid', component: InternshipDetailsComponent },
          { path: 'create-internship', component: AddInternshipComponent },
          { path: 'edit-internship/:hrid', component: EditInternshipComponent, pathMatch: 'full' },
          { path: 'copy-internship/:hrid', component: EditInternshipComponent, pathMatch: 'full' },
          { path: 'system', component: SystemComponent, children: SystemRoutes },
        ],
      },
    ],
  },
  {
    path: 'tutor',
    component: TutorPanelComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: TutorPupilsComponent },
      // {path: '/:uid', component: TutorStudentPageComponent},
      { path: 'profile', component: UserProfileOutletComponent },
    ],
  },
  { path: 'tutor/:uid', component: TutorStudentPageComponent, canActivate: [AuthGuard] },
  {
    path: 'parent',
    component: ParentPanelComponent,
    canActivate: [AuthGuard],
    children: [],
  },
  { path: 'parent/child-registration', component: ParentChildRegistrationComponent },
  { path: 'vorobievi-gori/mosrupromo', component: PromoTestComponent },
  {
    path: 'parent/:uid',
    component: ParentPanelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'parent/:uid/:menu',
    component: ParentPanelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'child-registration',
    component: ChildRegistrationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'update-user-info', component: UpdateUserInfoComponent },
];
