<a class="btn-prf-outline" (click)="toggleAddUserForm()">Добавить пользователя</a>
<div class="add-new-director__form" *ngIf="addUserFlag">
  <div class="overlay">
    <div class="modal">
      <p (click)="addUserFlag = !addUserFlag">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35">
          <g fill-rule="evenodd" stroke-linecap="square">
            <path d="M9.192 9.192l18.385 18.385M27.577 9.192L9.192 27.577"></path>
          </g>
        </svg>
      </p>
      <form novalidate [formGroup]="formUser" (submit)="onSubmit()" [class.long-modal]="userRole === 'employer'">
        <p>{{ 'SHARED.ADDING' | translate }} {{ roleView.genitiveValue }}</p>
        <mat-form-field>
          <mat-select placeholder="Выберите роль" formControlName="role" (selectionChange)="updateRole()">
            <div *ngIf="isAdmin">
              <mat-option *ngFor="let role of roles" [value]="role.value">
                {{ role.viewValue }}
              </mat-option>
            </div>
            <div *ngIf="!isAdmin">
              <mat-option *ngFor="let role of roles | slice : 0 : roles.length - 1" [value]="role.value">
                {{ role.viewValue }}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="userRole === 'employer'">
          <mat-form-field>
            <input matInput type="email" maxlength="70" class="w-input" name="email" placeholder="Email" formControlName="email" />
            <mat-error *ngIf="formUser.get('email').errors && formUser.get('email').errors.email">
              E-mail не соответствует шаблону: name&#64;domain.ru
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" class="w-input" name="password" placeholder="Пароль" formControlName="password" />
            <mat-error *ngIf="formUser.get('password').errors && formUser.get('password').errors.pattern">
              Пароль должен состоять минимум из 6 символов латинского алфавита и цифр.
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              type="tel"
              class="w-input"
              name="phoneNumber"
              placeholder="Номер телефона"
              formControlName="phoneNumber"
              [textMask]="{ mask: textMasks.phoneMask }"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              type="text"
              autocomplete="off"
              prfNoAutocomplete
              class="login-field w-input"
              placeholder="Дата рождения: дд/мм/гггг"
              [textMask]="{ mask: textMasks.dateMask }"
              formControlName="birthday"
              name="date"
              data-name="date"
              id="date"
            />
            <mat-error *ngIf="formUser.get('birthday').errors && formUser.get('birthday').errors.pattern">
              Введена не корректная дата.
            </mat-error>
          </mat-form-field>

          <div>
            <div>
              <label class="login-label">Пол:</label>
              <div class="div-space"></div>
            </div>
            <prf-gender-toggle formControlName="gender"></prf-gender-toggle>
          </div>

          <mat-form-field>
            <mat-select placeholder="Компания" formControlName="companyId" name="company">
              <mat-option *ngFor="let company of companies$ | async" [value]="company.id">
                {{ company.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input matInput type="text" class="w-input" name="position" placeholder="Должность" formControlName="position" />
          </mat-form-field>
        </ng-container>

        <mat-form-field>
          <input matInput type="text" class="w-input" name="lastName" placeholder="Фамилия" formControlName="lastName" />
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" class="w-input" name="firstName" placeholder="Имя" formControlName="firstName" />
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" class="w-input" name="middleName" placeholder="Отчество" formControlName="middleName" />
        </mat-form-field>
        <ng-container [ngSwitch]="roleView.value">
          <div *ngSwitchCase="'adminDO'">
            <mat-form-field>
              <mat-select placeholder="Название центра ДО" formControlName="schoolId" name="institutions">
                <mat-option *ngFor="let institution of institutions" [value]="institution.id">
                  {{ institution.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'tutor'"></div>
          <div *ngSwitchDefault>
            <mat-form-field>
              <mat-select placeholder="Регион" formControlName="regionId" name="allRegions">
                <mat-option *ngFor="let region of allRegions" [value]="region.id" (click)="selectRegion(region)">
                  {{ region.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="formUser.get('regionId').value">
              <mat-form-field>
                <mat-select placeholder="Муниципалитет" formControlName="municipalityId" name="municipality">
                  <mat-option
                    *ngFor="let municipality of municipalitiesByRegion"
                    [value]="municipality.id"
                    (click)="selectMunicipality(municipality)"
                  >
                    {{ municipality.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="isSelectCityAvailable">
                <mat-select placeholder="Город" formControlName="city" [disabled]="cityDisabled" name="cities">
                  <mat-option *ngFor="let city of citiesByMunicipality" [value]="city.name" (click)="setCity(city)">
                    {{ city.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="isSelectSchoolAvailable">
                <mat-select placeholder="Школа" formControlName="schoolId" name="schools">
                  <mat-option *ngFor="let school of schoolsFiltered" [value]="school.id">
                    {{ school.number }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </ng-container>

        <button type="submit" class="button green-btn w-button">Добавить</button>
      </form>
    </div>
  </div>
</div>
