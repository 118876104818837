export const TESTS_VARIANTS_IDS = {
  passportOfTalents: 'ced03d50-b59b-4670-af5c-20fff930c512',
  passportOfTalentsMO: '1f1edeee-4655-41b9-ab15-145e977abf66',
  test360: '',
  motivationtest: '8cc4237d-166f-4a89-80d6-70e3e7616716',
  promoTestMosRu: '0708668d-1f28-4cef-8492-cb1a6adf7556',
  valuesTest: '48751c29-0918-4fab-be65-76c75f0806c6',
  careerTypes: 'cc254c34-7e94-42a8-a8da-82474b75dd53',

  GetPassportOfTalentsByLocation(activeLocation: string) {
    switch (activeLocation) {
      case 'mo':
        return TESTS_VARIANTS_IDS.passportOfTalentsMO;
      default:
        return TESTS_VARIANTS_IDS.passportOfTalents;
    }
  },
};
