import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HttpService } from 'app/core/services/http.service';
import { merge as observableMerge } from 'rxjs';

import { map } from 'rxjs/operators';

@Injectable()
export class AnketaService {
  private anketa = {
    firstName: undefined,
    lastName: undefined,
    classNumber: undefined,
    classLetter: undefined,
    age: undefined,
    isFemale: false,
    testType: undefined,
    userStatus: undefined,
    childAge: undefined,
    schoolNumberId: undefined,
    language: undefined,
  };
  private userAnketa: any;
  private formControls: UntypedFormGroup[];
  public getForms: () => UntypedFormGroup[] = () => this.formControls;
  public getAnketaData = () => this.anketa;
  public getSavedAnketa = () => (this.userAnketa != null ? this.userAnketa : JSON.parse(localStorage.getItem('anketa') || '{}'));

  constructor(private http: HttpService) {
    // const tellAbout = new FormGroup({
    //   firstName: new FormControl(this.anketa.firstName, [Validators.required]),
    //   lastName: new FormControl(this.anketa.lastName, [Validators.required]),
    //   isFemale: new FormControl(this.anketa.isFemale),
    //   age: new FormControl(this.anketa.age, [Validators.required]),
    //   schoolNumberId: new FormControl(this.anketa.schoolNumberId, [Validators.required]),
    //   classNumber: new FormControl(this.anketa.classNumber, [Validators.required]),
    //   classLetter: new FormControl(this.anketa.classLetter, [Validators.required])
    // });
    // const testType = new FormGroup({
    //   testType: new FormControl(this.anketa.testType, [Validators.required])
    // });
    // const userStatus = new FormGroup({
    //   userStatus: new FormControl(this.anketa.userStatus, [Validators.required])
    // });
    const aboutTest = new UntypedFormGroup({});
    /*
      const tellAboutChild = new FormGroup({
      childName: new FormControl(this.anketa.childName, [Validators.required]),
      childIsFemale: new FormControl(this.anketa.childIsFemale),
      childAge: new FormControl(this.anketa.childAge, [Validators.required])
    });
     */
    /*
      const tellAboutMyself = new FormGroup({
      name: new FormControl(this.anketa.firstName, [Validators.required]),
      isFemale: new FormControl(this.anketa.isFemale),
      age: new FormControl(this.anketa.age, [Validators.required])
    });
     */
    /* this.formControls = [tellAbout, testType, userStatus, tellAboutChild, tellAboutMyself];*/
    this.formControls = [aboutTest];
    observableMerge(...this.formControls.map(f => f.valueChanges)).subscribe(d => {
      Object.assign(this.anketa, d);
      this.userAnketa = this.anketa;
      localStorage.setItem('anketa', JSON.stringify(this.userAnketa));
    });
  }

  public getSchools() {
    return this.http.get('/api/v1.0/catalog/schools').pipe(map(response => response.schools));
  }
}
