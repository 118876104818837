<div class="scheme-prof">
  <div *ngIf="isOpen === true" class="sheme-prof-div">
    <div class="scheme-info-block">
      <p>
        Эта схема показывает типичные варианты развития внутри профессии. Нажав на тот или иной элемент траектории, ты можешь увидеть
        подробную информацию про каждый из них.
      </p>
    </div>
    <div *ngIf="schemeType === 'twoBranches'" class="scheme">
      <a
        (click)="currentStep = 'averageSchool'"
        [class.active]="currentStep === 'averageSchool'"
        class="scheme-link-3 active w-inline-block"
      >
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'averageSchool'">
            <img src="/assets/images/icons/scheme-icon-1.svg" />
          </div>
          <div>{{ 'SHARED.HIGH_SCHOOL' | translate }}</div>
        </div>
      </a>
      <div class="scheme-center-block">
        <div class="scheme-center-div">
          <div class="scheme-line-column">
            <div class="scheme-line _2"></div>
            <div class="scheme-line _3"></div>
            <div class="scheme-line">
              <img src="/assets/images/icons/scheme-triangle.svg" class="scheme-triangle" />
            </div>
          </div>
          <a (click)="currentStep = 'college'" [class.active]="currentStep === 'college'" class="scheme-link-3 w-inline-block">
            <div class="scheme-link-div">
              <div class="scheme-icon-3" [class.active]="currentStep === 'college'">
                <img src="/assets/images/icons/scheme-icon-3.svg" />
              </div>
              <div>Колледж</div>
            </div>
          </a>
          <div class="scheme-line-column">
            <div class="scheme-line r2"></div>
            <div class="scheme-line _3 r"></div>
            <div class="scheme-line _2 r">
              <img src="/assets/images/icons/scheme-triangle.svg" class="scheme-triangle _2" />
            </div>
            <div class="line-down">
              <img src="/assets/images/icons/scheme-triangle.svg" class="scheme-triangle _3" />
            </div>
          </div>
        </div>
        <div class="scheme-center-div">
          <div class="scheme-line-column">
            <div class="scheme-line _2 d"></div>
            <div class="scheme-line _3 d"></div>
            <div class="scheme-line">
              <img src="/assets/images/icons/scheme-triangle.svg" class="scheme-triangle" />
            </div>
          </div>
          <a (click)="currentStep = 'highSchool'" [class.active]="currentStep === 'highSchool'" class="scheme-link-3 w-inline-block">
            <div class="scheme-link-div">
              <div class="scheme-icon-3" [class.active]="currentStep === 'highSchool'">
                <img src="/assets/images/icons/scheme-icon-2.svg" class="high-school-icon" />
              </div>
              <div>Старшая школа</div>
            </div>
          </a>
          <div class="scheme-line-column">
            <div class="scheme-line">
              <img src="/assets/images/icons/scheme-triangle.svg" class="scheme-triangle" />
            </div>
          </div>
          <a (click)="currentStep = 'university'" [class.active]="currentStep === 'university'" class="scheme-link-3 w-inline-block">
            <div class="scheme-link-div">
              <div class="scheme-icon-3" [class.active]="currentStep === 'university'">
                <img src="/assets/images/icons/scheme-icon-4.svg" />
              </div>
              <div>Вуз</div>
            </div>
          </a>
          <div class="scheme-line-column">
            <div class="scheme-line r2"></div>
            <div class="scheme-line _3 r d"></div>
            <div class="scheme-line _2 r d"></div>
          </div>
        </div>
      </div>
      <a (click)="currentStep = 'job'" [class.active]="currentStep === 'job'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'job'">
            <img src="/assets/images/icons/scheme-icon-5.svg" />
          </div>
          <div>Работа</div>
        </div>
      </a>
    </div>

    <div *ngIf="schemeType === 'withoutCollege'" class="scheme">
      <a (click)="currentStep = 'averageSchool'" [class.active]="currentStep === 'averageSchool'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'averageSchool'">
            <img src="/assets/images/icons/scheme-icon-1.svg" />
          </div>
          <div>{{ 'SHARED.HIGH_SCHOOL' | translate }}</div>
        </div>
      </a>
      <div class="scheme-line s">
        <img src="/assets/images/icons/scheme-triangle.svg" class="scheme-triangle" />
      </div>
      <a (click)="currentStep = 'highSchool'" [class.active]="currentStep === 'highSchool'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'highSchool'">
            <img src="/assets/images/icons/scheme-icon-2.svg" class="high-school-icon" />
          </div>
          <div>Старшая школа</div>
        </div>
      </a>
      <div class="scheme-line s">
        <img src="/assets/images/icons/scheme-triangle.svg" class="scheme-triangle" />
      </div>
      <a (click)="currentStep = 'university'" [class.active]="currentStep === 'university'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'university'">
            <img src="/assets/images/icons/scheme-icon-4.svg" />
          </div>
          <div>Вуз</div>
        </div>
      </a>
      <div class="scheme-line s">
        <img src="/assets/images/icons/scheme-triangle.svg" class="scheme-triangle" />
      </div>
      <a (click)="currentStep = 'job'" [class.active]="currentStep === 'job'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'job'">
            <img src="/assets/images/icons/scheme-icon-5.svg" />
          </div>
          <div>Работа</div>
        </div>
      </a>
    </div>

    <div *ngIf="schemeType === 'withoutUniversity'" class="scheme">
      <a (click)="currentStep = 'averageSchool'" [class.active]="currentStep === 'averageSchool'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'averageSchool'">
            <img src="/assets/images/icons/scheme-icon-1.svg" />
          </div>
          <div>{{ 'SHARED.HIGH_SCHOOL' | translate }}</div>
        </div>
      </a>
      <div class="scheme-line s">
        <img src="/assets/images/icons/scheme-triangle.svg" class="scheme-triangle" />
      </div>
      <a (click)="currentStep = 'college'" [class.active]="currentStep === 'college'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'college'">
            <img src="/assets/images/icons/scheme-icon-3.svg" />
          </div>
          <div>Колледж</div>
        </div>
      </a>
      <div class="scheme-line s">
        <img src="/assets/images/icons/scheme-triangle.svg" class="scheme-triangle" />
      </div>
      <a (click)="currentStep = 'job'" [class.active]="currentStep === 'job'" class="scheme-link-3 w-inline-block">
        <div class="scheme-link-div">
          <div class="scheme-icon-3" [class.active]="currentStep === 'job'">
            <img src="/assets/images/icons/scheme-icon-5.svg" />
          </div>
          <div>Работа</div>
        </div>
      </a>
    </div>

    <div [class.active]="currentStep === 'averageSchool'" class="scheme-info">
      <div class="scheme-info-block">
        <p>
          Осознать свой интерес к этой профессии тебе помогут программы дополнительного образования. Ознакомься со своей подборкой курсов и
          начни дорогу к делу жизни:
        </p>
        <ul>
          <li *ngFor="let course of profession.courses">{{ course.name }}</li>
        </ul>
      </div>
      <div class="scheme-info-block" hidden="hidden">
        <p>
          Осилить дорогу к этой профессии и поступить в профильное учебное заведение после 9–ого класса тебе помогут следующие школьные
          предметы:
        </p>
        <ul>
          <li><span>Математика</span></li>
          <li><span>Физика</span></li>
          <li><span>Прототипирование и моделирование</span></li>
        </ul>
      </div>
    </div>

    <div [class.active]="currentStep === 'highSchool'" class="scheme-info active">
      <div class="scheme-info-block">
        <p>
          Дорога к любимой профессии начинается с интереса. Осваивай программы дополнительного образования. Ниже представлена подборка
          профильных курсов
        </p>
        <ul>
          <li *ngFor="let course of profession.courses">{{ course.name }}</li>
        </ul>
      </div>
      <div class="scheme-info-block" hidden="hidden">
        <p>Впереди главные испытания этого маршрута: ЕГЭ и поступление в вуз. Чтобы пройти их достойно, налегай на следующие предметы:</p>
        <ul>
          <li><span>Математика</span></li>
          <li><span>Физика</span></li>
          <li><span>Прототипирование и моделирование</span></li>
        </ul>
      </div>
    </div>

    <div [class.active]="currentStep === 'college'" class="scheme-info">
      <div class="scheme-info-block">
        <p>
          Самый важный этап пути к делу жизни — обучение в заведении среднего или высшего профессионального образования. Получить
          представление о профильных специальностях тебе поможет наш список:
        </p>
        <ul>
          <li *ngFor="let special of specialsCollage">
            <span>{{ special.name }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div [class.active]="currentStep === 'university'" class="scheme-info">
      <div class="scheme-info-block">
        <p>
          Самый важный этап пути к делу жизни — обучение в заведении среднего или высшего профессионального образования. Получить
          представление о профильных специальностях тебе поможет наш список:
        </p>
        <ul>
          <li *ngFor="let special of specialsUniversity">
            <span>{{ special.name }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div [class.active]="currentStep === 'job'" class="scheme-info">
      <div class="scheme-info-block">
        <p>
          Сегодня существует множество способов для трудоустройства. Ты можешь строить карьеру в компании или создать свой бизнес, причем
          иногда уже даже на этапе обучения в школе, колледже или вузе. Мы собрали для тебя перечень ведущих компаний в этой сфере, и тебе
          будет полезно поближе изучить их опыт.
        </p>
        <ul>
          <li *ngFor="let company of profession.companies">
            <span>{{ company.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <a *ngIf="hideButton && isOpen === true" (click)="isOpen = false" class="sheme-prof-button-hide"> Скрыть траекторию </a>
  <a *ngIf="hideButton && isOpen === false" (click)="isOpen = true" class="sheme-prof-button-show"> Показать траекторию </a>
</div>
