import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/shared/shared.service';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ResultsService } from '../results.service';

@Component({
  selector: 'prf-career-results',
  templateUrl: './career-results.component.html',
  styleUrls: ['./career-results.component.scss'],
})
export class CareerResultsComponent implements OnInit {
  @Input() pageLoader: boolean = false;

  public results: any;

  public userRole: string = '';
  public urlSessionId: string = '';
  public userProfile: any;

  resultsFetched: boolean = false;
  public nullResults: boolean = false;
  public isMobile: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private resultService: ResultsService,
    private overlayService: OverlayBusyService,
    protected translateService: TranslateService,
  ) {
    this.userRole = localStorage.getItem('userRole');
    this.activatedRoute.params
      .pipe(
        switchMap((params: Params) => {
          if (params && params['ssid']) {
            this.urlSessionId = params['ssid'];
            return this.getSessionResults(this.urlSessionId);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(() => {
        this.resultsFetched = true;
        this.overlayService.hide();
      });
  }

  getSessionResults(sessionId): Observable<any> {
    if (this.pageLoader) {
      this.overlayService.show();
    }
    let getResultsObject = {
      sessionIds: [sessionId],
    };

    return this.resultService.getResultPages(getResultsObject).pipe(
      tap(results => {
        if (results) {
          this.results = results;
          //this.parseResults(this.results);
        }
      }),
    );
  }

  ngOnInit(): void {}
}
