import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { consultsMotivationData } from 'app/shared/global-constants/consults-data';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { tap } from 'rxjs/operators';

import { IResult, IResultsData } from '../../results.interface';
import { ResultsService } from '../../results.service';
import { TranslateService } from '@ngx-translate/core';
import {
  AmotivationRecommendations,
  InnerMotivationFactors,
  MotivationsTextData,
  OuterMotivationFactors,
  RecommendationsTextData,
} from './motivation-results-const';

export enum EMotivationTypes {
  AMOTIVATION,
  OUTER_MOTIVATION,
  INNER_MOTIVATION,
}

export enum motivationRatio {
  LOW,
  MEDIUM,
  HIGH,
}

@Component({
  selector: 'prf-motivation-results',
  templateUrl: './motivation-results.component.html',
  styleUrls: ['./motivation-results.component.scss'],
})
export class MotivationResultsComponent implements OnInit {
  @Input() userRole: string = 'pupil';
  @Input() pageLoader: boolean = false;
  @Input() set sessionId(val: string) {
    if (val) {
      this.resultsFetched = false;
      this.getSessionResults(val);
      this._sessionId = val;
    } else {
      this.resultsFetched = true;
    }
  }
  _sessionId;
  get sessionId() {
    return this._sessionId;
  }

  motivationTypes = EMotivationTypes;
  public selectedMotivation: EMotivationTypes = EMotivationTypes.AMOTIVATION;
  motivationRatio = motivationRatio;
  public results: any;

  consultsMotivationData = consultsMotivationData;

  // some logic constants
  lowFactorRatio = 27;
  highFactorRatio = 73;
  maximumSymbolsShortRecommends = 700;

  motivationObjects: IResult[] = [];
  factorsObjects: IResult[] = [];

  motivationData: any[] = [];
  factorsData: any[] = [];

  public innerMotivationFactors = InnerMotivationFactors;
  public outerMotivationFactors = OuterMotivationFactors;
  public motivationsTextData = MotivationsTextData;
  public recommendationsTextData = RecommendationsTextData;
  public amotivationRecommendations = AmotivationRecommendations;
  @ViewChild('swiperMotivComponent') swiperMotivComponent?: ElementRef;
  public swiperMotiv?: Swiper;
  public swiperMotivationsConfig: SwiperOptions = {
    spaceBetween: 200,
    allowTouchMove: false,
    speed: 600,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
  };

  @ViewChild('swiperDescriptionInMotElement') swiperDescriptionInMotElement?: ElementRef;
  public swiperDescriptionIn?: Swiper;
  public swiperDescriptionInMotConfig: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-descrIn-next',
      prevEl: '.swiper-descrIn-prev',
    },
    spaceBetween: 200,
    allowTouchMove: false,
    speed: 600,
  };
  @ViewChild('swiperRecommendationsInMotElement')
  swiperRecommendationsInMotElement?: ElementRef;
  public swiperRecommendationIn?: Swiper;
  public swiperRecommendationsInMotConfig: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-recomIn-next',
      prevEl: '.swiper-recomIn-prev',
    },
    spaceBetween: 200,
    allowTouchMove: false,
    speed: 600,
  };

  @ViewChild('swiperDescriptionOutMotElement') swiperDescriptionOutMotElement?: ElementRef;
  public swiperDescriptionOut?: Swiper;
  public swiperDescriptionOutMotConfig: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-descrOut-next',
      prevEl: '.swiper-descrOut-prev',
    },
    spaceBetween: 200,
    allowTouchMove: false,
    speed: 600,
  };
  @ViewChild('swiperRecommendationsOutMotElement')
  swiperRecommendationsOutMotElement?: ElementRef;
  public swiperRecommendationOut?: Swiper;
  public swiperRecommendationsOutMotConfig: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-recomOut-next',
      prevEl: '.swiper-recomOut-prev',
    },
    spaceBetween: 200,
    allowTouchMove: false,
    speed: 600,
  };

  resultsFetched: boolean = false;
  public nullResults: boolean = false;
  public isMobile: boolean = false;
  mobileSlideNumber: number = null;
  mobileOpened: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private resultService: ResultsService,
    public element: ElementRef,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private overlayService: OverlayBusyService,
    protected translateService: TranslateService,
  ) {
    this.isMobile = window.innerWidth <= 450;
    localStorage.removeItem('testType');

    matIconRegistry.addSvgIcon(
      'next',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/results/motivation-results/swiper-next.svg'),
    );
    matIconRegistry.addSvgIcon(
      'prev',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/results/motivation-results/swiper-prev.svg'),
    );
  }

  ngOnInit() {}
  public ngAfterViewInit(): void {
    this.swiperMotiv = this.swiperMotivComponent?.nativeElement.swiper;
    this.swiperDescriptionIn = this.swiperDescriptionInMotElement?.nativeElement.swiper;
    this.swiperDescriptionOut = this.swiperDescriptionOutMotElement?.nativeElement.swiper;
    this.swiperRecommendationOut = this.swiperRecommendationsOutMotElement?.nativeElement.swiper;
    this.swiperRecommendationIn = this.swiperRecommendationsInMotElement?.nativeElement.swiper;
  }
  getSessionResults(sessionId) {
    if (this.pageLoader) {
      this.overlayService.show();
    }
    let getResultsObject = {
      sessionIds: [sessionId],
    };

    return this.resultService
      .getResultPages(getResultsObject)
      .pipe(
        tap(results => {
          if (results) {
            this.results = results;
            this.parseResults(this.results);
          }
        }),
      )
      .subscribe(() => {
        this.resultsFetched = true;
        this.overlayService.hide();
        if (!this.isMobile) {
          setTimeout(() => {
            this.changeMotivSection(0);
          }, 50);
        }
      });
  }

  private parseResults(results: IResultsData) {
    let resultsData;
    if (!results || !results.results || results.results.length === 0) {
      this.nullResults = true;
      return;
    }

    resultsData = JSON.parse(JSON.stringify(results));

    if (resultsData.results[0].results.length === 0) {
      return;
    }

    this.motivationObjects = resultsData.results.filter(d => d.objectType === 'Motivation');
    this.factorsObjects = resultsData.results.filter(d => d.objectType === 'MotivationSevenScales');

    this.motivationData = this.motivationObjects.map(i => {
      return {
        title: i.name,
        value: i.results.length ? Math.round(i.results[0].transformedValue) : 0,
      };
    });

    // меняем местами 2-ой и 3-ий элемента массива
    // this.motivationData = this.swapElements(this.motivationData, 1, 2);

    this.factorsData = this.factorsObjects.map(i => {
      return {
        title: i.name,
        value: i.results.length ? Math.round(i.results[0].transformedValue) : 0,
      };
    });
    // матчинг факторов по названию
    this.innerMotivationFactors = this.innerMotivationFactors.map((factor, i) => {
      return {
        name: this.innerMotivationFactors[i].name,
        value: this.factorsData.find(fd => fd.title == this.translateService.instant(this.innerMotivationFactors[i].name))?.value,
        descriptions: this.innerMotivationFactors[i].descriptions,
      };
    });
    this.outerMotivationFactors = this.outerMotivationFactors.map((factor, j) => {
      return {
        name: this.outerMotivationFactors[j].name,
        value: this.factorsData.find(fd => fd.title == this.translateService.instant(this.outerMotivationFactors[j].name))?.value,
        descriptions: this.outerMotivationFactors[j].descriptions,
      };
    });
  }

  changeMotivSection(index: number) {
    if (this.isMobile) {
      this.mobileOpened = !this.mobileOpened;
      this.mobileSlideNumber = index;
      window.scrollTo(0, 0);
    } else {
      this.selectedMotivation = EMotivationTypes[this.motivationTypes[index]];
    }
    setTimeout(() => {
      this.swiperDescriptionIn = this.swiperDescriptionInMotElement?.nativeElement.swiper;
      this.swiperDescriptionOut = this.swiperDescriptionOutMotElement?.nativeElement.swiper;
    }, 0);
  }
  closeMobileSlide() {
    this.mobileOpened = !this.mobileOpened;
  }

  changeInMotDescriptionText(index: number) {
    this.swiperDescriptionIn.slideTo(index);
  }

  changeOutMotDescriptionText(index: number) {
    this.swiperDescriptionOut.slideTo(index);
  }

  checkFactorRatio(factorValue: number) {
    switch (true) {
      case factorValue < this.lowFactorRatio:
        return 'low';
      case factorValue > this.highFactorRatio:
        return 'high';
      default:
        return 'medium';
    }
  }

  isSliderForRecom(motivationype: EMotivationTypes) {
    if (this.isMobile) {
      return false;
    } else {
      if (motivationype === EMotivationTypes.INNER_MOTIVATION) {
        let summaryInSymbols =
          this.translateService.instant(
            this.recommendationsTextData.curiosity[this.checkFactorRatio(parseInt(this.innerMotivationFactors[0].value))],
          ).length +
          this.translateService.instant(
            this.recommendationsTextData.skillsDevelopment[this.checkFactorRatio(parseInt(this.innerMotivationFactors[1].value))],
          ).length +
          this.translateService.instant(
            this.recommendationsTextData.productivity[this.checkFactorRatio(parseInt(this.innerMotivationFactors[2].value))],
          ).length;
        return summaryInSymbols > this.maximumSymbolsShortRecommends;
      } else if (motivationype === EMotivationTypes.OUTER_MOTIVATION) {
        let summaryOutSymbols =
          this.translateService.instant(
            this.recommendationsTextData.awards[this.checkFactorRatio(parseInt(this.outerMotivationFactors[0].value))],
          ).length +
          this.translateService.instant(
            this.recommendationsTextData.rules[this.checkFactorRatio(parseInt(this.outerMotivationFactors[1].value))],
          ).length +
          this.translateService.instant(
            this.recommendationsTextData.beliefs[this.checkFactorRatio(parseInt(this.outerMotivationFactors[2].value))],
          ).length;
        return summaryOutSymbols > this.maximumSymbolsShortRecommends;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.isMobile = event.target.innerWidth <= 450;
  }
}
