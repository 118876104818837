import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { IRegion } from 'app/shared/interfaces/iregion';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RegionsService } from './regions.service';

@Component({
  selector: 'prf-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss'],
})
export class RegionsComponent implements OnInit {
  public allRegions: IRegion[];

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private meta: Meta, private regionsService: RegionsService, private overlayService: OverlayBusyService) {
    this.meta.updateTag({ name: 'og:title', content: 'Города' });
  }

  ngOnInit() {
    this.overlayService.show();
    this.regionsService
      .getAllRegions()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.allRegions = r;
        this.overlayService.hide();
      });
  }

  regionUpdate(regionId) {
    let changedRegion = this.allRegions.find(i => i.id === regionId);
    let index = this.allRegions.indexOf(changedRegion);
    this.regionsService
      .getRegion(regionId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(region => {
        this.allRegions[index] = region;
      });
  }

  regionAdded(region) {
    if (region) {
      this.allRegions.push(region);
    }
    return;
  }

  regionDeleted(regionId) {
    return (this.allRegions = this.allRegions.filter(i => i.id != regionId));
  }

  // async getRegionInfo(regionId) {
  //   return await this.regionsService.getRegion(regionId);
  // }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
