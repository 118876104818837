<ng-container *ngIf="staticTask; else notStatic">
  <div class="event-card">
    <a class="event-gradient-bg w-inline-block static-block-sm lg-blue">
      <div class="event-header">
        <h3 class="event-heading static">{{ course.name }}</h3>
      </div>
      <div class="event-image-bg static-image-{{ index }}"></div>
    </a>
  </div>
</ng-container>

<ng-template #notStatic>
  <div class="event-card" (click)="checkAction($event)">
    <a class="event-gradient-bg w-inline-block" [ngClass]="currentType.color">
      <div class="event-header">
        <div class="event-type" [ngClass]="currentType.color">{{ currentType.viewValue }}</div>
        <h3 class="event-heading">{{ course.name }}</h3>
      </div>
      <div
        class="event-image-bg"
        [ngStyle]="{
          'background-image': course.mainImagePath ? 'url(' + course.mainImagePath + ')' : 'url(/assets/images/background/event-bg-2.png)'
        }"
      ></div>
    </a>
    <div class="event-info-div">
      <a
        class="event-heart w-inline-block"
        *ngIf="!isFavorite; else isFavoriteCard"
        [style.background-image]="'url(/assets/images/icons/Heart-line-violet.svg)'"
        (mouseover)="heart.style.backgroundImage = 'url(/assets/images/icons/Heart-violet.svg)'"
        (mouseleave)="heart.style.backgroundImage = 'url(/assets/images/icons/Heart-line-violet.svg)'"
        #heart
      >
      </a>
      <div class="event-date" *ngIf="course.isRegular">{{ 'SHARED.REGULARLY' | translate }}</div>
      <div class="event-date" *ngIf="!course.isRegular">
        {{ course.startDate ? moment(course.startDate).format('D MMM в hh:mm') : 'Нет даты' }}
      </div>
      <p class="event-description">{{ course.shortDescription }}</p>
      <div class="event-metro-row" *ngIf="course.metroStations.length > 0; else emptyMetroStation">
        <div class="metro-color" [ngClass]="metroColors.getStationByLine(course.metroStations[0])"></div>
        <div class="event-metro">{{ course.metroStations[0].name }}</div>
      </div>
      <div class="event-price">
        <div class="final-price">
          {{
            calculateRealPrice() > 0
              ? calculateRealPrice() + ' ' + ('COMMON.MONEY_SYMBOL' | translateByDefault)
              : ('COURCES.PAGE.FREE' | translate)
          }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emptyDiscont>
  <div class="event-price-row">
    <div class="final-price" *ngIf="isCourse">
      {{
        calculateRealPrice() > 0
          ? calculateRealPrice() + ' ' + ('COMMON.MONEY_SYMBOL' | translateByDefault)
          : ('COURCES.PAGE.FREE' | translate)
      }}
    </div>
    <div class="starting-price"></div>
    <div class="discount"></div>
  </div>
</ng-template>

<ng-template #emptyMetroStation>
  <div class="event-metro-row" style="opacity: 0">
    <div class="metro-color white-border" *ngIf="isCourse"></div>
    <div class="event-metro"></div>
  </div>
</ng-template>

<ng-template #isFavoriteCard>
  <a
    class="event-heart w-inline-block"
    [style.background-image]="isCourse ? 'url(/assets/images/icons/heart-white.svg)' : 'url(/assets/images/icons/Heart-violet.svg)'"
    (mouseover)="
      heartActive.style.backgroundImage = isCourse
        ? 'url(/assets/images/icons/heart-line-white.svg)'
        : 'url(/assets/images/icons/Heart-line-violet.svg)'
    "
    (mouseleave)="
      heartActive.style.backgroundImage = isCourse
        ? 'url(/assets/images/icons/heart-white.svg)'
        : 'url(/assets/images/icons/Heart-violet.svg)'
    "
    #heartActive
  ></a>
</ng-template>
