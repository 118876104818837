import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProfessionsService } from 'app/shared/dashboard/professions/professions.service';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { saveAs } from 'file-saver';
import { ProfessionFilter } from 'app/shared/dashboard/professions/profession-filter/profession-filter.component';
import { IProfessionFilter } from 'app/shared/interfaces/iprofessionfilter.interface';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { AdminPanelService } from '../../admin-panel.service';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { ProfessionsCatalogTypes } from '../professions-panel.component';
import { RegionsService } from '../../regions/regions.service';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';

@Component({
  selector: 'prf-professions',
  templateUrl: './professions.component.html',
  styleUrls: ['./professions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfessionsComponent implements OnInit, OnDestroy {
  @Input() set catalogType(val: ProfessionsCatalogTypes) {
    this._catalogType = val;
    if (val) {
      this.showMore(val);
    }
  }
  get catalogType() {
    return this._catalogType;
  }
  _catalogType: ProfessionsCatalogTypes;
  @ViewChild('regionDrop') public readonly regionDrop: ElementRef;
  @ViewChild('regionField') public readonly regionField: ElementRef;
  @ViewChild('municipalityDrop') public readonly municipalityDrop: ElementRef;
  @ViewChild('municipalityField') public readonly municipalityField: ElementRef;

  isCopy: boolean = false;
  hrid: string;
  error: boolean = false;
  addNewProfessionFlag: boolean = false;
  setting: boolean = false;
  filters: IProfessionFilter = {};
  professions: IProfession[] = [];
  currentProfession: IProfession;
  searchProfessionField: UntypedFormControl;
  searches: IProfession[] = [];
  size: number = 0;
  itemsOnPage: number = 12;
  loading: boolean = false;
  loadedAll: boolean = false;
  popUpConfirming: boolean = false;
  fields: any[];
  courses: any[];
  profilClasses: any[];
  removeID: string = '';
  schoolID: string = '';
  userRole: string = '';
  userRegionId: string = '';
  userMunicipalityId: string = '';
  editingTitle: string = 'Изменение профессии';

  public categoryList: Array<ProfessionFilter>;

  professionsCount: number;

  regions: IRegion[];
  selectedRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };

  municipalities: any[] = [
    // маппинг для отображения названий муниципалитетов по Id
    {
      id: '00000000-0000-0000-0000-000000000000',
      name: '',
    },
  ];
  selectedMunicipality: IMunicipality = {
    id: '',
    hrid: '',
    name: '',
    regionId: '',
  };

  defaultRegionId: string = '00000000-0000-0000-0000-000000000000';
  adminLevel: any;
  regionTests: any[];
  isPilotRegion: boolean = true; // по умолчанию открывается пилотный регион, профессии нельзя редактировать
  isDefaultRegion: boolean = false;
  isMunicipality: boolean = false;
  isRegion: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    public route: ActivatedRoute,
    private adminPanelService: AdminPanelService,
    private overlayService: OverlayBusyService,
    private regionsService: RegionsService,
    private professionsService: ProfessionsService,
    private municipalitiesService: MunicipalitiesService,
    private router: Router,
    private translateService: TranslateService,
  ) {
    this.getTranslation('HEADER.PROF')
      .pipe(take(1))
      .subscribe(translation => this.meta.updateTag({ name: 'og:title', content: translation }));

    if (route.snapshot.routeConfig.path == 'copy-profession/:hrid') {
      this.isCopy = true;
      this.editingTitle = 'Копия профессии';
    }
  }

  ngOnInit() {
    this.schoolID = localStorage.getItem('schoolId');
    this.userRole = localStorage.getItem('userRole');
    let regionId = localStorage.getItem('regionId');
    this.adminLevel = localStorage.getItem('adminLevel');
    this.userRegionId = regionId && regionId != 'null' ? regionId : this.defaultRegionId;
    this.userMunicipalityId = localStorage.getItem('municipalityId');

    // this.overlayService.show();
    this.getCategoryList();

    this.getProfessionsData().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();

    if (this.isCopy) {
      this.route.params
        .pipe(
          switchMap(params => {
            this.hrid = params['hrid'];
            return this.professionsService.getProfessionByHRID(this.hrid).pipe(
              tap(data => {
                this.currentProfession = data;
                this.setting = !this.setting;
              }),
            );
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe();
    }

    // Search
    this.searchProfessionField = new UntypedFormControl();
    this.searchProfessionField.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(term => {
          let currentObs$: Observable<any>;
          if (term && term.length != 0) {
            let searchRegionId: string = '';
            searchRegionId = this.selectedRegion && this.selectedRegion.id ? this.selectedRegion.id : this.defaultRegionId;
            if (this.catalogType === ProfessionsCatalogTypes.MONGO) {
              currentObs$ = this.adminPanelService.getProfessionsQuery(term, searchRegionId);
            } else if (this.catalogType === ProfessionsCatalogTypes.ELASTIC) {
              currentObs$ = this.adminPanelService.getElasticProfessionsQuery(term, searchRegionId);
            }
          } else {
            // this.searches = [];
            currentObs$ = of(null);
          }
          return currentObs$.pipe(
            tap(
              (search: any[]) => {
                this.searches = [];
                if (search) {
                  search.forEach(item => {
                    this.searches.push(item);
                  });
                  this.searches.sort((a, b) => a.name.localeCompare(b.name));
                }
              },
              error => {
                this.error = true;
              },
            ),
          );
        }),
      )
      .subscribe();

    // get professions
    // this.showMore();
  }

  getProfessionsData(): Observable<any> {
    // get all data objects
    return this.getObjectLists().pipe(
      switchMap(_ => {
        return observableForkJoin([this.regionsService.getAllRegions(), this.adminPanelService.getRegionTestInfo()]).pipe(
          switchMap(([regions, regionTestInfo]: [any[], any]) => {
            this.regions = regions.sort((a, b) => (a.name > b.name ? 1 : -1));
            this.regionTests = regionTestInfo;
            let currentObservable$: Observable<any> = of(null);
            // this.professions = defaultProfessions;
            if (this.userRole == 'schooladmin') {
              currentObservable$ = this.adminPanelService.getAllElasticProfessionsByRegion(this.defaultRegionId).pipe(
                tap((defaultProfessions: any[]) => {
                  this.professions = defaultProfessions.find(p => p.id === this.schoolID).sort((a, b) => a.name.localeCompare(b.name));
                }),
              );
            }

            return currentObservable$.pipe(
              switchMap(() => {
                return this.loadTerritory();
              }),
            );
          }),
        );
      }),
    );
  }

  showMore(catalogType: ProfessionsCatalogTypes) {
    this.loading = true;
    this.overlayService.show();
    this.filters.regionId = this.userRegionId ? this.userRegionId : this.defaultRegionId;

    // TODO: вернуть передачу размера и onScroll() в случае надобности пагинации
    // this.size = this.size + this.itemsOnPage;
    this.acquireProfessionsByFilters(this.filters, catalogType)
      .pipe(
        take(1),
        tap(([count, professions]) => {
          this.professionsCount = count;
          this.professions = professions.sort((a, b) => a.name.localeCompare(b.name));
          if (this.userRole == 'schooladmin') {
            this.professions.find(p => p.id === this.schoolID);
          }
          this.loadedAll = professions.length < this.itemsOnPage;
          this.loading = false;
          this.overlayService.hide();
        }),
      )
      .subscribe();
  }

  acquireProfessionsByFilters(filters: IProfessionFilter, catalogType: ProfessionsCatalogTypes): Observable<any> {
    // check and reject null/undefined values
    let filtersVerified: IFilterClasses = {};
    Object.keys(filters).forEach(key => {
      filters[key] != null && filters[key] != undefined ? (filtersVerified[key] = filters[key]) : null;
    });
    this.filters = filtersVerified;
    if (catalogType === ProfessionsCatalogTypes.MONGO) {
      return forkJoin([
        this.adminPanelService.getProfessionCount(this.filters),
        this.adminPanelService.getProfessionsByFilters(this.filters),
      ]);
    } else if (catalogType === ProfessionsCatalogTypes.ELASTIC) {
      return forkJoin([
        this.adminPanelService.getElasticProfessionCount(this.filters),
        this.adminPanelService.getElasticProfessionsByFilters(this.filters),
      ]);
    }
    return of(null);
  }

  getMunicipalityName(municipalityId: string): string {
    const municipality = this.municipalities.find(m => m.id === municipalityId);
    return municipality ? municipality.name : '';
  }

  selectRegion(region) {
    this.setRegion(region).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setRegion(region: IRegion): Observable<any> {
    if (region) {
      this.clearSelectedMunicipality();
      this.selectedRegion = region;
      this.filters.regionId = region.id;
      let selectedRegionTest = this.regionTests.find(x => x.regionId == this.selectedRegion.id);
      // если регион пилотный и не дефолтный, запрещаем редактирование профессий
      if (selectedRegionTest && selectedRegionTest.isPilot && selectedRegionTest.regionId != this.defaultRegionId) {
        this.isPilotRegion = true;
      }
      if (selectedRegionTest && selectedRegionTest.regionId == this.defaultRegionId) {
        this.isDefaultRegion = true;
      }

      return observableForkJoin([
        this.acquireProfessionsByFilters(this.filters, this.catalogType),
        this.municipalitiesService.getMunicipalitiesByRegion(region.id),
      ]).pipe(
        tap(([acquireProfResponse, municipalities]) => {
          this.professionsCount = acquireProfResponse[0];
          this.professions = acquireProfResponse[1].sort((a, b) => a.name.localeCompare(b.name));
          this.loadedAll = true;
          let municipalitiesForCurrentRegion = municipalities
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(item => {
              return {
                id: item.id,
                name: item.name,
              };
            });
          this.municipalities = [this.municipalities[0], ...municipalitiesForCurrentRegion]; // дефолтный элемент и элементы по региону
        }),
      );
    } else {
      return of(null);
    }
  }

  selectMunicipality(municipality) {
    this.setMunicipality(municipality).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setMunicipality(municipality): Observable<any> {
    if (municipality) {
      this.resetPageNumber();
      this.selectedMunicipality = municipality;
      this.filters.municipalityId = municipality.id;
      return this.acquireProfessionsByFilters(this.filters, this.catalogType).pipe(
        tap(([count, professions]) => {
          this.professionsCount = count;
          this.professions = professions.sort((a, b) => a.name.localeCompare(b.name));
          this.loadedAll = true;
        }),
      );
    }
  }

  clearSelectedMunicipality() {
    this.resetPageNumber();
    this.isPilotRegion = false;
    this.isDefaultRegion = false;
    this.selectedMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    this.filters.municipalityId = null;
    return;
  }

  clearTerritoryData() {
    this.resetPageNumber();
    this.isPilotRegion = true;
    this.isDefaultRegion = false;
    this.selectedRegion = {
      id: '',
      hrid: '',
      name: '',
    };
    this.municipalities = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        name: '',
      },
    ];
    this.selectedMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    this.filters.regionId = null;
    this.filters.municipalityId = null;
    return;
  }

  loadTerritory(): Observable<any> {
    let userRegionId = localStorage.getItem('regionId');

    let regionsObservable$: Observable<any>;
    switch (this.adminLevel) {
      case AdminAccessLevel.GLOBAL:
        {
          regionsObservable$ = this.regionsService.getAllRegions().pipe(
            tap(r => {
              this.regions = r;
            }),
          );
        }
        break;
      case AdminAccessLevel.REGION:
        {
          regionsObservable$ = this.regionsService.getAllRegions().pipe(
            switchMap(r => {
              this.regions = r;
              let region = this.regions.find(r => r.id == userRegionId);
              return this.setRegion(region).pipe(
                switchMap(_ => {
                  this.isRegion = true;
                  return this.municipalitiesService.getMunicipalitiesByRegion(userRegionId).pipe(
                    tap(municipalities => {
                      this.municipalities = municipalities
                        .filter(item => item.id != this.defaultRegionId)
                        .sort((a, b) => (a.name > b.name ? 1 : -1));
                    }),
                  );
                }),
              );
            }),
          );
        }
        break;
      case AdminAccessLevel.MUNICIPALITY:
        {
          regionsObservable$ = this.regionsService.getAllRegions().pipe(
            switchMap(r => {
              this.regions = r;
              let region = this.regions.find(r => r.id == userRegionId);
              return this.setRegion(region).pipe(
                switchMap(_ => {
                  this.isRegion = true;
                  return this.municipalitiesService.getMunicipalitiesByRegion(userRegionId).pipe(
                    switchMap(municipalities => {
                      this.municipalities = municipalities
                        .filter(item => item.id != this.defaultRegionId)
                        .sort((a, b) => (a.name > b.name ? 1 : -1));
                      let municipality = this.municipalities.find(r => r.id == this.userMunicipalityId);
                      return this.setMunicipality(municipality).pipe(
                        tap(_ => {
                          this.isMunicipality = true;
                        }),
                      );
                    }),
                  );
                }),
              );
            }),
          );
        }
        break;
      default:
        regionsObservable$ = of(null);
        break;
    }
    return regionsObservable$;
  }

  getObjectLists(): Observable<any> {
    return observableForkJoin([
      this.adminPanelService.getFields(),
      this.adminPanelService.getCourses(),
      this.adminPanelService.getProfilClasses(),
    ]).pipe(
      tap(([fields, courses, classes]) => {
        this.fields = fields;
        this.fields.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.courses = courses;
        this.courses.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.profilClasses = classes;
        this.profilClasses.sort((a, b) => (a.name > b.name ? 1 : -1));
      }),
    );
  }

  public setCurrentProfession(data) {
    this.setting = !this.setting;
    this.currentProfession = this.professions.find(p => p.id === data.id);
  }

  public setCurrentProfessionFromSearches(data) {
    this.setting = !this.setting;
    this.currentProfession = this.searches.find(p => p.id === data.id);
  }

  public closeEditWindow(data = null) {
    this.setting = !this.setting;
    if (this.isCopy) {
      this.isCopy = false;
      this.editingTitle = 'Изменение профессии';
      this.router.navigate(['/admin/professions']);
    }
  }

  showDeleteProfession(professionID) {
    this.popUpConfirming = !this.popUpConfirming;
    this.removeID = professionID;
  }

  deleteProfession(professionID) {
    this.adminPanelService.removeProfession(professionID).subscribe(() => {
      this.popUpConfirming = !this.popUpConfirming;

      // обнуляем поиск
      this.searches = [];
      this.searchProfessionField.reset();

      // get professions
      this.showMore(this.catalogType);
    });
  }

  clearSearch() {
    this.searchProfessionField.reset();
  }

  resetData() {
    this.clearTerritoryData();
    this.showMore(this.catalogType);
  }

  resetMunicipalityData() {
    this.adminPanelService
      .getAllProfessionsByRegion(this.selectedRegion.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(professions => {
        this.professions = professions.sort((a, b) => a.name.localeCompare(b.name));
        this.clearSelectedMunicipality();
      });
  }

  private resetPageNumber() {
    this.size = 0;
    this.loading = false;
    this.loadedAll = false;
  }

  downloadProfessionsCatalog() {
    this.adminPanelService
      .downloadCatalogExcel(this.selectedRegion.id, 'Professions')
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(response => {
        const blob = new Blob([response.body], { type: response.headers.get('content-type') });
        const fileName = this.parseFilenameFromContentDisposition(response.headers.get('content-disposition'));
        const file = new File([blob], fileName, { type: response.headers.get('content-type') });
        saveAs(file);
      });
  }

  private parseFilenameFromContentDisposition(contentDisposition) {
    if (!contentDisposition) {
      return null;
    }
    let matches = /filename="(.*?)"/g.exec(contentDisposition);
    return matches && matches.length > 1 ? matches[1] : 'untitled';
  }

  public getProfessionsByCategory(categoryItem: ProfessionFilter) {
    if (categoryItem && categoryItem.name) {
      this.filters.fields = [categoryItem.name];
    } else {
      delete this.filters.fields;
    }

    this.acquireProfessionsByFilters(this.filters, this.catalogType)
      .pipe(
        take(1),
        tap(([count, professions]) => {
          this.professionsCount = count;
          this.professions = professions.sort((a, b) => a.name.localeCompare(b.name));
          if (this.userRole == 'schooladmin') {
            this.professions.find(p => p.id === this.schoolID);
          }
          this.loadedAll = professions.length < this.itemsOnPage;
        }),
      )
      .subscribe();
  }

  private getCategoryList() {
    this.professionsService
      .getFields()
      .pipe(take(1))
      .subscribe(fields => {
        this.categoryList = [];
        fields.forEach(field => {
          this.categoryList.push({
            name: field.name,
            count: 0,
            selected: false,
          });
        });
        //  this.loadedCategory = true;
      });
  }

  @HostListener('click', ['$event'])
  checkClick(event: Event) {
    if (this.regionDrop && this.regionDrop?.nativeElement.classList.contains('w--open')) {
      if (!this.regionDrop.nativeElement.contains(event.target) && !this.regionField.nativeElement.contains(event.target)) {
        this.regionDrop.nativeElement.classList.remove('w--open');
      }
    }
    if (this.municipalityDrop && this.municipalityDrop?.nativeElement.classList.contains('w--open')) {
      if (!this.municipalityDrop.nativeElement.contains(event.target) && !this.municipalityField.nativeElement.contains(event.target)) {
        this.municipalityDrop.nativeElement.classList.remove('w--open');
      }
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  // Пагинация работает только для первой дефолтной загрузки, при выборе региона или муниципалитета грузятся сразу все профессии
  // @HostListener('window:scroll', [])
  // onScroll(): void {
  //   if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
  //     if (this.loading === false && this.loadedAll == false) {
  //       this.showMore();
  //     }
  //   }
  // }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
