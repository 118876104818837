import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { duration } from 'moment';
import { empty, from, Observable, of, Subject, timer } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

export enum EOnboardingSteps {
  START = 0,
  STEP_1,
  STEP_2,
  STEP_3,
}
@Component({
  selector: 'prf-onboarding-steps',
  templateUrl: './onboarding-steps.component.html',
  styleUrls: ['./onboarding-steps.component.scss'],
})
export class OnboardingStepsComponent implements OnInit {
  @Output() onboardingFinished = new EventEmitter<any>();
  public onboardingSteps = EOnboardingSteps;
  public onboardingStep: EOnboardingSteps = EOnboardingSteps.START;

  onboardingStepsCount = 3;
  stepTimeConst = 3000;
  timerUnsubscribe$ = new Subject<any>();
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.startOnboarding();
  }

  startOnboarding() {
    let fullOnboardingTime = this.onboardingStepsCount * this.stepTimeConst;
    let barElement: HTMLElement = document.getElementsByClassName('step-bar')[0] as HTMLElement;
    timer(0, fullOnboardingTime)
      .pipe(
        takeUntil(this.timerUnsubscribe$),
        switchMap(fulltimerEvent => {
          if (fulltimerEvent >= 1) {
            this.timerUnsubscribe$.next(true);
            this.onboardingFinished.emit(true);
            return of(null);
          }

          barElement.animate({ flex: '100px' }, fullOnboardingTime);
          return timer(0, this.stepTimeConst).pipe(
            takeUntil(this.timerUnsubscribe$),
            tap(r => {
              this.onboardingStep++;
              this.cd.markForCheck();
            }),
          );
        }),
      )

      .subscribe();
  }
}
