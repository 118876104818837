<div class="child-profile-block">
  <div
    class="child-profile-photo"
    [ngStyle]="{
      'background-image': user.imagePath ? 'url(' + user.imagePath + ')' : 'url(/assets/images/dashboard/no-photo-child.svg)'
    }"
  ></div>
  <div class="child-profile-column">
    <div class="user-name-profile child-prof">
      {{ user.firstName }}
      {{ user.middleName && user.middleName.length > 0 ? ' ' + user.middleName : '' }}
      {{ ' ' + user.lastName }}
    </div>
    <div class="child-profile-code-row">
      <div class="child-profile-code-text">{{ 'SHARED.REGISTRATION_CODE' | translate }} —</div>
      <div class="child-profile-code-column">
        <a class="child-profile-code">{{ user.registrationCode ? user.registrationCode : ('SHARED.NOT_SPECIFIED' | translate) }}</a>
        <div class="info-div child-prof">
          <div class="info-icon" (mouseenter)="popup.style.display = 'block'" (mouseleave)="popup.style.display = 'none'"></div>
          <div class="info-popup" #popup>
            <p>{{ 'SHARED.CODE_FOR_REGISTER' | translate }}</p>
            <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
          </div>
        </div>
      </div>
    </div>

    <div class="child-profile-data">
      <div class="child-profile-data-row" *ngIf="parents">
        <div class="child-profile-data-text">{{ 'SHARED.PARENTS' | translate }}:</div>
        <div class="child-profile-contact" *ngFor="let parent of parents">
          {{ parent.firstName }}
          {{ parent.middleName && parent.middleName.length > 0 ? ' ' + parent.middleName : '' }}
          {{ ' ' + parent.lastName }}
        </div>
      </div>
      <div class="child-profile-data-row">
        <div class="child-profile-data-text">{{ 'PLAYER.ANKETA.TELL_ABOUT.PHONE' | translate }}:</div>
        <div class="child-profile-contact">
          {{ user.phoneNumber ? user.phoneNumber : ('SHARED.NOT_SPECIFIED' | translate) }}
        </div>
      </div>
      <div class="child-profile-data-row">
        <div class="child-profile-data-text">{{ 'PLAYER.ANKETA.TELL_ABOUT.EMAIL' | translate }}:</div>
        <div class="child-profile-contact">
          {{ user.email ? user.email : ('SHARED.NOT_SPECIFIED' | translate) }}
        </div>
      </div>
    </div>
  </div>
</div>
