import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { filter } from 'rxjs/operators';
import { routeAnimations } from '../core';

@Component({
  selector: 'prf-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  animations: [routeAnimations],
})
export class PagesComponent implements OnInit {
  public hideHeader = false;

  public activeLocation = 'base';
  userRole: string = '';
  bannerLink: string = 'https://grani.moscow/';

  isProfessionTestPage: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private appSettings: AppSettingsService) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.isProfessionTestPage = e.url.includes('test-na-professiyu');
      }
    });
    this.activeLocation = this.appSettings.currentLocation;
    router.events.pipe(filter(e => e instanceof NavigationEnd)).forEach(() => {
      this.hideHeader = route.root.firstChild.snapshot.data['hideHeader'];
      this.userRole = localStorage.getItem('userRole');
    });
  }

  ngOnInit() {}
}
