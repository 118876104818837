import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdminModule } from './admin/admin.module';
import { ControlPanelComponent } from './control-panel.component';
import { ParentModule } from './parent/parent.module';
import { PupilModule } from './pupil/pupil.module';
import { SchoolAdminModule } from './schooladmin/school-admin.module';
import { TutorModule } from './tutor/tutor.module';

@NgModule({
  declarations: [ControlPanelComponent],
  imports: [CommonModule, AdminModule, SchoolAdminModule, ParentModule, PupilModule, TutorModule],
  exports: [AdminModule, SchoolAdminModule, ParentModule, PupilModule],
})
export class ControlPanelModule {}
