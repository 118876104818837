import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectVariables } from 'app/core/project.variables';
import { PlayerService } from 'app/pages/player/player.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { SlideService } from 'app/shared/services/slide.service';
import { of, Subject, timer, throwError, Observable } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Gender } from 'app/shared/enums/gender.enums';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { ETestTypes } from 'app/shared/enums/testtypes.enum';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { RegistrationService } from 'app/landing/b2c/registration-b2c/registration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ParentPanelService } from '../parent-panel.service';
import { SharedService } from 'app/shared/shared.service';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { IAddUserInterface } from '../../../../shared/interfaces/iregistration.interface';

export enum EPromoTestTypes {
  TYPE_1,
  TYPE_2,
  TYPE_3,
}

export enum loginCases {
  LOGIN,
  REGISTER,
  UPDATE,
}

export const promoTestData = [
  {
    testType: EPromoTestTypes.TYPE_1,
    screeningTestId: '0708668d-1f28-4cef-8492-cb1a6adf7556',
    variantString: '',
    minAge: 15,
    maxAge: 18,
  },
  {
    testType: EPromoTestTypes.TYPE_2,
    screeningTestId: '0708668d-1f28-4cef-8492-cb1a6adf7556',
    variantString: 'variant 2',
    minAge: 11,
    maxAge: 14,
  },
  {
    testType: EPromoTestTypes.TYPE_3,
    screeningTestId: '0708668d-1f28-4cef-8492-cb1a6adf7556',
    variantString: 'variant 3',
    minAge: 7,
    maxAge: 10,
  },
];

@Component({
  selector: 'prf-promo-test',
  templateUrl: './promo-test.component.html',
  styleUrls: ['./promo-test.component.scss'],
})
export class PromoTestComponent implements OnInit {
  public promoTestTypes = EPromoTestTypes;

  userId: string = '';
  userGender: string = '';
  public credentials;
  public personalTerms: boolean = false;
  userRole: string = '';

  testDataArray = promoTestData;

  form: UntypedFormGroup;
  ageChecked: boolean = false;
  public ageValid: boolean = true;
  public submitted: boolean = false;
  sessionWaiting: boolean = false;
  public createSessionObject = { userId: '', screeningTestId: '', data: {} };

  // проверка языка для регионов
  public selectedLanguage: string =
    AppSettingsService.settings.regionLanguages && AppSettingsService.settings.regionLanguages.isAccessible
      ? AppSettingsService.settings.regionLanguages.defaultLanguage
      : null;
  public regionLanguages = {
    default: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.defaultLanguage : null,
    native: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.regionLanguage : null,
  };

  isVGuser: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(
    private router: Router,
    private playerService: PlayerService,
    private slideService: SlideService,
    private overlayService: OverlayBusyService,
    private projectVariables: ProjectVariables,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private fb: UntypedFormBuilder,
    private registrationService: RegistrationService,
    private parentService: ParentPanelService,
    private sharedService: SharedService,
  ) {
    this.userId = localStorage.getItem('userId');
    this.userRole = localStorage.getItem('userRole');
    this.userGender = localStorage.getItem('userGender');
    this.isVGuser = localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString();

    this.route.url.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(url => {
      url.map(segment => segment.path).includes('mosrupromo') ? localStorage.setItem('testType', ETestTypes.PROMO_TEST.toString()) : null;
    });
  }

  ngOnInit() {
    this.setSessionData();
    this.initForm();
  }

  setSessionData() {
    //selectedLanguage
    if (this.selectedLanguage) {
      Object.assign(this.createSessionObject, { language: this.selectedLanguage });
    }
    //userId
    this.createSessionObject.userId = this.userId ? this.userId : null;
  }

  initForm() {
    this.form = this.fb.group({
      name: new UntypedFormControl(null, [Validators.required]),
      age: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^\d+$/)]),
      personalTerms: new UntypedFormControl((this.personalTerms = false), [Validators.requiredTrue]),
    });
  }

  get f() {
    return this.form.controls;
  }

  checkChildAge() {
    this.ageValid = this.checkInterval(this.f.age.value, 7, 18) && !this.f.age.errors;
    !this.ageValid ? this.f.age.setErrors({ incorrect: true }) : null;
    this.ageChecked = true;
  }

  createSession() {
    let variantString;
    this.submitted = true;

    let childAge = this.f.age.value;

    let testType = this.checkInterval(childAge, 7, 10)
      ? this.promoTestTypes.TYPE_3
      : this.checkInterval(childAge, 11, 14)
      ? this.promoTestTypes.TYPE_2
      : this.checkInterval(childAge, 15, 18)
      ? this.promoTestTypes.TYPE_1
      : null;

    switch (testType) {
      case this.promoTestTypes.TYPE_1:
        //screeningTestId
        this.createSessionObject.screeningTestId = this.testDataArray.find(
          test => test.testType === this.promoTestTypes.TYPE_1,
        ).screeningTestId;
        //variant
        variantString = this.testDataArray.find(test => test.testType === this.promoTestTypes.TYPE_1).variantString;
        variantString ? Object.assign(this.createSessionObject, { data: { ScreeningTestType: variantString } }) : null;
        break;
      case this.promoTestTypes.TYPE_2:
        //screeningTestId
        this.createSessionObject.screeningTestId = this.testDataArray.find(
          test => test.testType === this.promoTestTypes.TYPE_2,
        ).screeningTestId;
        //variant
        variantString = this.testDataArray.find(test => test.testType === this.promoTestTypes.TYPE_2).variantString;
        variantString ? Object.assign(this.createSessionObject, { data: { ScreeningTestType: variantString } }) : null;
        break;
      case this.promoTestTypes.TYPE_3:
        //screeningTestId
        this.createSessionObject.screeningTestId = this.testDataArray.find(
          test => test.testType === this.promoTestTypes.TYPE_3,
        ).screeningTestId;
        //variant
        variantString = this.testDataArray.find(test => test.testType === this.promoTestTypes.TYPE_3).variantString;
        variantString ? Object.assign(this.createSessionObject, { data: { ScreeningTestType: variantString } }) : null;
        break;
      default:
        this.f.age.setErrors({ incorrect: true });
        break;
    }

    if (this.form.valid && AppSettingsService.settings.promotestSchool) {
      this.sessionWaiting = true;
      let nowDateYear = new Date().getFullYear();
      let childBirthDay = new Date(nowDateYear - this.f.age.value, 1, 1);

      this.credentials = {
        firstName: this.f.name.value,
        lastName: '',
        middleName: '',
        role: 'pupil',
        birthday: childBirthDay,
        gender: '',
        regionId:
          localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString()
            ? AppSettingsService.settings.vorobioviGori.regionId
            : localStorage.getItem('regionId')
            ? localStorage.getItem('regionId')
            : '',
        municipalityId:
          localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString()
            ? AppSettingsService.settings.vorobioviGori.municipalityId
            : localStorage.getItem('municipalityId')
            ? localStorage.getItem('municipalityId')
            : null,
        city: localStorage.getItem('city') ? localStorage.getItem('city') : 'Москва',
        schoolId:
          localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString()
            ? AppSettingsService.settings.vorobioviGori.schoolId
            : AppSettingsService.settings.promotestSchool.schoolId,
        //todo возможно, нужно будет поменять класс для школы ВГ
        schoolClassNumber: parseInt(AppSettingsService.settings.promotestSchool.schoolClassNumber, 10),
        schoolClassLetter: AppSettingsService.settings.promotestSchool.schoolClassLetter,
      };
      this.overlayService.show();
      this.registrationService
        .addUser(this.credentials)
        .pipe(
          switchMap(registrationResult => {
            if (registrationResult && registrationResult.userId) {
              if (registrationResult['userId'] == undefined) {
                this.utilsService.openSnackBar('Произошла ошибка', 'error');
                return of(null);
              } else {
                Object.assign(this.createSessionObject, {
                  ReferralUserId: registrationResult.userId,
                });
                // create session
                return this.playerService.createPormoTestSession(this.createSessionObject).pipe(
                  switchMap(sessionResult => {
                    if (!sessionResult || !sessionResult.status || sessionResult.status === 'Failed to create testing session') {
                      this.handleSessionError();
                      return of(null);
                    } else {
                      // localStorage.setItem('promoTestingChildId', registrationResult.userId);
                      return timer(1500).pipe(
                        switchMap(r => {
                          return this.playerService.getNextSlide(sessionResult.sessionId).pipe(
                            tap(value => {
                              this.slideService.setCurrentSlide(sessionResult.sessionId, value);
                              if (localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString()) {
                                return this.router.navigate(['/vorobievi-gori/mosrupromo-test', sessionResult.sessionId]).then(() => {
                                  this.overlayService.hide();
                                });
                              } else {
                                this.router.navigate(['/mosrupromo-test', sessionResult.sessionId]).then(() => {
                                  this.overlayService.hide();
                                });
                              }
                            }),
                            catchError((err: HttpErrorResponse) => {
                              this.utilsService.openSnackBar('Произошла ошибка', 'error');
                              return of(null);
                            }),
                          );
                        }),
                      );
                    }
                  }),
                  catchError((err: HttpErrorResponse) => {
                    this.utilsService.openSnackBar('Произошла ошибка', 'error');
                    return of(null);
                  }),
                );
              }
            } else {
              this.utilsService.openSnackBar('Произошла ошибка', 'error');
              return of(null);
            }
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe(_ => {
          this.sessionWaiting = false;
          this.overlayService.show();
        });
    } else {
    }
  }

  checkInterval(value, min, max) {
    return value >= min && value <= max;
  }

  public getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }

  get isAccessDenied() {
    return this.form.invalid || !this.ageChecked;
  }

  handleSessionError() {
    this.utilsService.openSnackBar('Неполадки на сервере', 'error');
    setTimeout(() => {
      this.overlayService.hide();
      this.utilsService.closeSnackBar();
      return this.router.navigate(['/']);
    }, 2000);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  // const getOutFromError = async function recoverFromError() {
  //   if (getState().value === 'error') {
  //     return probe().catch(throw new Error('can\t recover. try later'));
  //   }
  //}
}
