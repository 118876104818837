import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';
import { take, takeUntil, tap } from 'rxjs/operators';
import { IExtendedPupil } from '../../../../shared/interfaces/iextendedpupil.interface';
import { ISchoolClass } from '../../../../shared/interfaces/ischoolclass.interface';
import { ITeacher } from '../../../../shared/interfaces/iteacher.interface';
import { Subject } from 'rxjs';
import { AdminClassService } from 'app/shared/dashboard/admin-classes/admin-class/admin-class.service';

enum ClassPageViews {
  CLASS_VIEW,
  EDIT_CLASS_VIEW,
  EDIT_PUPILS_VIEW,
}

@Component({
  selector: 'prf-schooladmin-class-template',
  templateUrl: './schooladmin-class-template.component.html',
  styleUrls: ['./schooladmin-class-template.component.scss'],
})
export class SchooladminClassTemplateComponent implements OnInit, OnDestroy {
  schoolClass: ISchoolClass;
  teacher: ITeacher;
  pupils: IExtendedPupil[] = [];
  userProfile: any;
  showEditPupilsComponent: boolean = false;
  showEditClassComponent: boolean = false;

  classViewSelected: any = ClassPageViews.CLASS_VIEW;
  classPageViews = ClassPageViews;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta,
    private router: Router,
    private sharedService: SharedService,
    private adminClassService: AdminClassService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Кабинет Школьного администратора' });
  }

  ngOnInit() {
    this.sharedService
      .getUserInfoData()
      .pipe(
        tap(userProfile => {
          this.userProfile = userProfile;
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  showEditPupils() {
    this.showEditPupilsComponent = true;
    this.classViewSelected = ClassPageViews.EDIT_PUPILS_VIEW;
  }

  hideEditPupils(pupilsUpdated) {
    this.showEditPupilsComponent = false;
    this.classViewSelected = ClassPageViews.CLASS_VIEW;
  }

  showEditClass() {
    this.showEditClassComponent = true;
    this.classViewSelected = ClassPageViews.EDIT_CLASS_VIEW;
  }

  hideEditClass(classUpdated) {
    this.showEditClassComponent = false;
    this.classViewSelected = ClassPageViews.CLASS_VIEW;
  }

  setTeacher(event) {
    this.teacher = event;
  }
  setSchoolClass(event) {
    this.schoolClass = event;
  }
  setPupils(event) {
    this.pupils = event;
  }

  routingBack() {
    this.adminClassService
      .getPupilPage()
      .pipe(take(1))
      .subscribe(pupilPage => {
        if (pupilPage.showPupilPage) {
          this.adminClassService.closePupilPage();
        } else {
          this.router.navigate(['/schooladmin/classes']);
        }
      });
  }

  showElement(event: any) {
    event.target.nextSibling.style.display = 'block';
  }

  hideElement(event: any) {
    event.target.nextSibling.style.display = 'none';
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
