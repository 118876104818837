import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'prf-email-change',
  templateUrl: './email-change.component.html',
  styleUrls: ['./email-change.component.scss'],
})
export class EmailChangeComponent implements OnInit {
  _email: any = {
    newEmail: null,
  };

  newEmailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {}

  // Update email
  onSubmit(valid: boolean) {
    if (valid) {
      this.settingsService.changeEmail(this._email);
    }
  }
}
