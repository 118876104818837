import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Subject, pipe, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TutorPanelService } from '../tutor-shared/tutor.service';

@Component({
  selector: 'prf-tutor-pupils',
  templateUrl: './tutor-pupils.component.html',
  styleUrls: ['./tutor-pupils.component.scss'],
})
export class TutorPupilsComponent implements OnInit {
  public loadedClass: boolean = false;

  public pupils: any[] = [];
  public selectedPupil: any;
  selectedPupilId: string = null;

  public showResults: boolean;

  // showPupilPage: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(
    private overlayService: OverlayBusyService,
    private tutorService: TutorPanelService,
    private sharedService: SharedService,
    private router: Router,
    private utilsService: UtilsService,
  ) {
    this.selectedPupilId = this.router.getCurrentNavigation()?.extras.state?.pupilId;
  }

  ngOnInit() {
    // todo доработать лоадер при возварте к списку детей

    this.pupils = this.tutorService.getPupilsList();

    if (!this.pupils.length) {
      this.getPupils().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
    }

    if (this.selectedPupilId) {
      setTimeout(() => this.scrollToLastSelected(), 0);
    }
  }

  getPupils(): Observable<any> {
    this.overlayService.show();
    return this.tutorService.getPupils().pipe(
      tap(pupils => {
        if (pupils && pupils.length) {
          this.pupils = pupils;
          this.tutorService.setPupilsList(pupils);
          this.overlayService.hide();
        }
      }),
    );
  }

  getImageUrl(user: any) {
    return user.imagePath ? user.imagePath : '/assets/images/dashboard/no-photo.svg';
  }
  getImagePath(job: any) {
    if (job.mainImagePath) {
      job.mainImagePath = job.mainImagePath.replace('/content/images/professions/default_icons/', '/assets/images/profession/');
    } else {
      job.mainImagePath = '/assets/images/dashboard/petard.png';
    }
    return job.mainImagePath;
  }

  showElement(event: any) {
    event.target.nextSibling.style.display = 'block';
  }

  hideElement(event: any) {
    event.target.nextSibling.style.display = 'none';
  }

  showSelectedPupil(pupil: any) {
    this.selectedPupil = pupil;
    this.router.navigate([`tutor/${this.selectedPupil.userId}`]);
    // this.tutorService.showPupilPage();
  }

  scrollToLastSelected() {
    let lastSelectedPupil = document.getElementsByClassName('last-selected')[0] as HTMLElement;
    lastSelectedPupil?.scrollIntoView({ behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
