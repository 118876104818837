import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/shared/shared.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { of, Subject, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { SCHOOL_NUMBERS } from '../../../../shared/global-constants/school-numbers';
import { ISchool } from '../../../../shared/interfaces/ischool.interface';
import { ISchoolClass } from '../../../../shared/interfaces/ischoolclass.interface';
import { ITeacher } from '../../../../shared/interfaces/iteacher.interface';
import { AppSettingsService } from '../../../../shared/services/appsettings.service';

import { SchoolAdminPanelService } from '../schooladmin-panel.service';

@Component({
  selector: 'prf-schooladmin-edit-class',
  templateUrl: './schooladmin-edit-class.component.html',
  styleUrls: ['./schooladmin-edit-class.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SchooladminEditClassComponent implements OnInit {
  @Output() showEditClass = new EventEmitter();
  @Input() teacher: ITeacher;
  @Input() teacherClass: any;

  public form: UntypedFormGroup;

  popUpConfirming: boolean = false;

  public readonly numbersClass: any = SCHOOL_NUMBERS;
  private appSettingsService = inject(AppSettingsService);
  public readonly lettersClass: any = this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS');

  submitted: boolean;
  schoolclass: ISchoolClass;
  errors: number[] = [];
  school: ISchool;
  saveInProcess: boolean = false;

  public selectedNumber: any;
  public selectedLetter: any;

  public missLetterToggle: boolean = false;
  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;

  @ViewChild('numberFilter') public readonly numberFilter: ElementRef;
  @ViewChild('numberDrop') public readonly numberDrop: ElementRef;

  @ViewChild('letterFilter') public readonly letterFilter: ElementRef;
  @ViewChild('letterDrop') public readonly letterDrop: ElementRef;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private schoolAdminPanelService: SchoolAdminPanelService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private utilsService: UtilsService,
  ) {
    this.getTranslation('SHARED.EDITING_CLASS')
      .pipe(take(1))
      .subscribe(translation =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation,
        }),
      );
  }

  ngOnInit() {
    this.setUpdateClassControls();
  }

  private setUpdateClassControls() {
    this.form = this.fb.group({
      number: new UntypedFormControl(this.teacherClass.number.toString()),
      letter: new UntypedFormControl(this.teacherClass.letter),
      firstName: new UntypedFormControl(this.teacher && this.teacher.firstName ? this.teacher.firstName : null),
      lastName: new UntypedFormControl(this.teacher && this.teacher.lastName ? this.teacher.lastName : null),
      middleName: new UntypedFormControl(this.teacher && this.teacher.middleName ? this.teacher.middleName : null),
    });
    if (this.teacher && this.teacher.isActivated) {
      this.form.get('firstName').disable();
      this.form.get('lastName').disable();
      this.form.get('middleName').disable();
    }
  }

  removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }

  failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }

  hideEdit(isUpdated) {
    this.showEditClass.emit(isUpdated);
  }

  animateSubmit() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.submit();
  }

  submit() {
    this.submitted = true;
    if (this.form.valid && !this.saveInProcess) {
      this.saveInProcess = true;
      this.sharedService
        .getSchool()
        .pipe(
          switchMap(school => {
            this.school = school;
            // Обновляем информацию о школе
            return this.updateClassInfo();
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe(() => {
          this.saveInProcess = false;
          this.sharedService.needCall(true);
          this.removeWaiting();

          if (this.errors.length == 0) {
            this.hideEdit(false);
          }
        });
    } else {
      this.failWaiting();
      return;
    }
  }

  fieldFocusNumber(e) {
    if (this.form.value.number) {
      this.f.number.setValue('');
    }
  }

  fieldFocusOutNumber(e) {
    const schoolNumber = this.numbersClass.filter(t => t.value === this.form.value.number);
    if (schoolNumber.length == 0) {
      this.f.number.setValue('');
    }
  }

  setNumber(number: any = {}) {
    this.f.number.setValue(number.value);
  }

  fieldFocusLetter(e) {
    if (this.form.value.letter) {
      this.f.letter.setValue('');
    }
  }

  fieldFocusOutLetter(e) {
    const schoolLetter = this.lettersClass.filter(t => t.value === this.form.value.letter);
    if (schoolLetter.length == 0) {
      this.f.letter.setValue('');
    }
  }

  setLetter(letter: any = {}) {
    this.f.letter.setValue(letter.value);
  }

  get f() {
    return this.form.controls;
  }

  private fieldsNotEmpty(): boolean {
    return this.form.value.firstName != null && this.form.value.lastName != null && this.form.value.middleName != null;
  }

  private updateClassInfo(): Observable<any> {
    let updateClass = {
      id: this.teacherClass.id,
      teacherId: this.teacher && this.teacher.teacherId ? this.teacher.teacherId : '00000000-0000-0000-0000-000000000000',
      letter: this.form.value.letter,
      number: this.form.value.number,
      schoolId: this.school.id,
      pupilsCount: this.teacherClass.pupilsCount,
    };

    let currentObservable$: Observable<any> = of(null);
    if (this.teacher) {
      if (
        this.f.firstName.value != this.teacher.firstName ||
        this.f.lastName.value != this.teacher.lastName ||
        this.f.middleName.value != this.teacher.middleName
      ) {
        // меняем учителя
        let newTeacherCredentials = {
          firstName: this.f.firstName.value,
          lastName: this.f.lastName.value,
          middleName: this.f.middleName.value,
          schoolClassIds: this.teacherClass.id ? [this.teacherClass.id] : [],
          role: 'teacher',
        };
        currentObservable$ = this.schoolAdminPanelService.removeTeacher(this.teacher.userId).pipe(
          switchMap(r =>
            this.schoolAdminPanelService.addTeacher(newTeacherCredentials).pipe(
              tap(r => {
                updateClass.teacherId = r.userId;
              }),
            ),
          ),
        );
      }
    } else if (this.fieldsNotEmpty()) {
      // если в классе нет учителя и поля для него заполнены, создаем нового
      currentObservable$ = this.createNewTeacher(this.teacherClass.id);
    }
    return currentObservable$.pipe(
      switchMap(r =>
        this.schoolAdminPanelService.updateSchoolClass(updateClass).pipe(
          catchError(err => {
            this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
            return throwError(err);
          }),
        ),
      ),
    );
  }

  private createNewTeacher(schoolClassId): Observable<any> {
    let createNewTeacher = {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      middleName: this.form.value.middleName,
      schoolClassIds: schoolClassId ? [schoolClassId] : [],
      role: 'teacher',
    };
    return this.schoolAdminPanelService.addTeacher(createNewTeacher).pipe(
      catchError(err => {
        this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
        return throwError(err);
      }),
    );
  }

  deleteTeacher() {
    this.schoolAdminPanelService
      .removeTeacher(this.teacher.userId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        r => {
          this.utilsService.openSnackBar('👌 Учитель удален', 'success');
          this.hideEdit(false);
          this.popUpConfirming = false;
        },
        catchError(err => {
          this.utilsService.openSnackBar('👎 Произошла ошибка', 'error');
          this.popUpConfirming = false;
          return throwError(err);
        }),
      );
  }

  public isAccessAllowed() {
    return this.f.number.value && this.f.letter.value;
  }

  public missingLetterToggle() {
    this.missLetterToggle = !this.missLetterToggle;
    this.f.letter.setValue('');
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (!this.numberFilter?.nativeElement.contains(target)) {
      this.numberDrop.nativeElement.classList.remove('w--open');
    }
    if (!this.letterFilter?.nativeElement.contains(target)) {
      this.letterDrop.nativeElement.classList.remove('w--open');
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
