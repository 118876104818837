<div class="class-section">
  <div class="w-form">
    <form id="email-form" [formGroup]="pupilsForm">
      <div class="class-div">
        <div class="class-header">
          <div class="class-row class-header-row-2">
            <div class="class-column _1">
              <div class="class-header-number">№</div>
            </div>
            <div class="class-column _2">
              <div class="class-photo-column"></div>
              <div class="class-name-row">
                <div class="class-name-column w-hidden-medium w-hidden-small w-hidden-tiny">
                  <div class="class-name">{{ 'SHARED.FORM.FAMILY' | translate }}</div>
                </div>
                <div class="class-name-column w-hidden-medium w-hidden-small w-hidden-tiny">
                  <div class="class-name">{{ 'SHARED.FORM.NAME' | translate }}</div>
                </div>
                <div class="class-name-column w-hidden-medium w-hidden-small w-hidden-tiny">
                  <div class="class-name">{{ 'SHARED.FORM.MIDDLENAME' | translate }}</div>
                </div>
                <div class="class-name-column w-hidden-main">
                  <div class="class-name">ФИО</div>
                </div>
              </div>
            </div>
            <div class="class-buttons-row">
              <a (click)="submit()" class="button class-edit-button w-button">Сохранить</a>
              <a (click)="hideEdit(false)" class="button white-button class-edit-button w-button">Отменить</a>
            </div>
          </div>
        </div>
        <div class="class-list-block">
          <div class="students-list">
            <div
              class="class-list-item"
              formArrayName="users"
              *ngFor="let pupil of formDataArray.controls; let i = index; trackBy: trackByFn"
              [class.not-activated]="pupil.value.isActivated != null"
            >
              <div class="class-li-wrapper">
                <div class="class-row">
                  <div class="class-column _1">
                    <div class="class-header-number">{{ i + 1 }}</div>
                  </div>

                  <div class="class-column _2">
                    <div class="class-photo-column">
                      <div class="class-photo"></div>
                    </div>
                    <div *ngIf="pupil.value.isActivated == null">
                      <div class="class-name-row" [formGroupName]="i">
                        <div class="class-name-column">
                          <ng-container *ngFor="let error of errors">
                            <div class="error-text-field" *ngIf="error == i">
                              Произошла ошибка при сохранении данного ученика. Обратитесь к администратору
                            </div>
                          </ng-container>
                          <input
                            type="text"
                            formControlName="lastName"
                            class="class-field w-input"
                            [ngClass]="submitted && pupilsForm.controls.users.controls[i].controls.lastName.errors ? 'error' : ''"
                            maxlength="256"
                            data-name="{{ 'SHARED.FORM.FAMILY' | translate }}"
                            placeholder="{{ 'SHARED.FORM.FAMILY' | translate }}"
                          />

                          <div class="error-text-field" *ngIf="submitted && pupilsForm.controls.users.controls[i].controls.lastName.errors">
                            {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                          </div>
                        </div>

                        <div class="class-name-column">
                          <input
                            type="text"
                            formControlName="firstName"
                            class="class-field w-input"
                            [ngClass]="submitted && pupilsForm.controls.users.controls[i].controls.firstName.errors ? 'error' : ''"
                            maxlength="256"
                            data-name="{{ 'SHARED.FORM.NAME' | translate }}"
                            placeholder="{{ 'SHARED.FORM.NAME' | translate }}"
                          />

                          <div
                            class="error-text-field"
                            *ngIf="submitted && pupilsForm.controls.users.controls[i].controls.firstName.errors"
                          >
                            {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                          </div>
                        </div>

                        <div class="class-name-column">
                          <input
                            type="text"
                            formControlName="middleName"
                            class="class-field w-input"
                            [ngClass]="submitted && pupilsForm.controls.users.controls[i].controls.middleName.errors ? 'error' : ''"
                            maxlength="256"
                            data-name="{{ 'SHARED.FORM.MIDDLENAME' | translate }}"
                            placeholder="{{ 'SHARED.FORM.MIDDLENAME' | translate }}"
                          />
                          <div
                            class="error-text-field"
                            *ngIf="submitted && pupilsForm.controls.users.controls[i].controls.middleName.errors"
                          >
                            {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="pupil.value.isActivated != null">
                      <div class="class-name-row">
                        <div class="class-name-column">
                          {{ pupil.value.lastName }}
                        </div>

                        <div class="class-name-column">
                          {{ pupil.value.firstName }}
                        </div>

                        <div class="class-name-column">
                          {{ pupil.value.middleName }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="class-column _3">
                    <div class="gender-choice">
                      <div class="gender-choice-row">
                        <a
                          class="gender-button"
                          [class.active]="isMale(pupil.value.gender)"
                          (click)="setMale(pupil); pupilsForm.controls.users.touched = true"
                        >
                          М
                        </a>
                        <a
                          class="gender-button right"
                          [class.active]="!isMale(pupil.value.gender)"
                          (click)="setFemale(pupil); pupilsForm.controls.users.touched = true"
                        >
                          Ж
                        </a>
                      </div>
                    </div>
                    <a class="delete-student w-inline-block" (click)="removePupil(i)"> </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a (click)="addPupil()" class="button add-student w-button">Добавить еще ученика</a>
        </div>
      </div>
    </form>
    <div class="w-form-done">
      <div>Thank you! Your submission has been received!</div>
    </div>
    <div class="w-form-fail">
      <div>Oops! Something went wrong while submitting the form.</div>
    </div>
  </div>
</div>
