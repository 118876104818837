<div class="promo-course-card">
  <div class="promo-course-pic">
    <div class="course-discount-info" *ngIf="course.unitDiscount">
      <span class="_dash">&ndash;</span>{{ course.unitDiscount }}
      <span *ngIf="course.isPercent">%</span>
      <span *ngIf="!course.isPercent">&nbsp;&#8381;</span>
    </div>
    <div
      class="event-image-bg"
      [ngStyle]="{
        'background-image': course.mainImagePath
          ? 'url(' + course.mainImagePath + ')'
          : 'url(/assets/images/results/promo-results/promo-course-pic.png)'
      }"
    >
      <div class="event-image-overlay"></div>
    </div>
    <div class="promo-course-type">
      {{ checkCourseFormat(course.classesFormat) }}
    </div>
  </div>
  <div class="promo-course-main">
    <div class="course-main-heading">
      <h3
        class="course-name"
        (mouseleave)="popup.style.display = 'none'"
        (mouseover)="course.name.length >= 35 ? (popup.style.display = 'block') : null"
      >
        {{ cutText(course.name, 35) }}
        <div class="info-popup recommendation course w-hidden-small w-hidden-tiny" #popup style="display: none">
          <p>{{ course.name }}</p>
          <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
        </div>
      </h3>
      <div class="course-age" *ngIf="course.minAgeAudience">{{ course.minAgeAudience }}+</div>
    </div>
    <div class="course-text" [innerHTML]="course.institution"></div>
    <div class="promo-course-buttons">
      <div class="buttons-row">
        <a class="_button button-more-info" [class.no-link]="!course.url && !isVGuser" (click)="navigateCourse(course.url)" target="_blank">
          Узнать больше
        </a>
      </div>
      <div class="buttons-row">
        <a class="_button button-price no-link">
          <ng-container *ngIf="course.unitDiscount">
            <div class="button-price-discounted" *ngIf="calculateRealPrice(course) > 0">{{ calculateRealPrice(course) }}&nbsp;&#8381;</div>
            <div class="button-price-discounted" *ngIf="calculateRealPrice(course) <= 0" [style.font-size]="'13px'">бесплатно</div>
            <div class="und-wrapper">
              <div class="button-price-undiscounted" id="id_button_undiscounted">
                {{ course.unitPrice }}&nbsp;&#8381;
                <div
                  class="_crossing-line"
                  [style.transform]="'rotate(-' + getCrossingLineAngle() + 'deg)'"
                  [style.width]="getCrossingLineLength() + 'px'"
                ></div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!course.unitDiscount">
            <div class="button-price-full" *ngIf="course.unitPrice > 0">{{ course.unitPrice }}&nbsp;&#8381;</div>
            <div class="button-price-full" *ngIf="course.unitPrice <= 0">бесплатно</div>
          </ng-container>
        </a>
        <a class="_button button-promocode" (click)="copyPromocode(course.promocode)" *ngIf="course.promocode"
          ><span class="promocode-text"> промокод </span><br /><span class="promocode-value">{{ course.promocode }} </span>
        </a>
      </div>
    </div>
  </div>
</div>
