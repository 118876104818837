import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { EditUserProfileComponent } from './edit-user-profile/edit-user-profile.component';
import {
  EditUserProfileOutletComponent,
} from './user-profile-outlet/edit-user-profile-outlet/edit-user-profile-outlet.component';
import { UserProfileOutletComponent } from './user-profile-outlet/user-profile-outlet.component';
import { UserProfileOutletService } from './user-profile-outlet/user-profile-outlet.service';
import { UserProfileComponent } from './user-profile.component';
import { UserProfileService } from './user-profile.service';

@NgModule({
  imports: [BrowserModule, RouterModule, SharedModule],
  declarations: [UserProfileComponent, EditUserProfileComponent, UserProfileOutletComponent, EditUserProfileOutletComponent],
  providers: [UserProfileService, UserProfileOutletService],
  bootstrap: [],
})
export class UserProfileModule {}
