<ng-container *ngIf="!urlSessionId && userRole === 'pupil'">
  <prf-menu [root]="userRole"></prf-menu>

  <div class="content-section">
    <prf-page-header [title]="headerTitle" [backButtonUrl]="'/' + userRole" [backButtonName]="'На главную'"></prf-page-header>
    <div class="container">
      <ng-container *ngIf="loaded">
        <div class="professions-section">
          <prf-pupil-results-shortened *ngIf="!showFullResults" [sessionId]="sessionId"></prf-pupil-results-shortened>

          <ng-container *ngIf="showFullResults">
            <prf-results *ngIf="sessionStatus === 'Success'" [child]="profile" [showSwitch]="true" [showResultMenu]="false"> </prf-results>

            <prf-parent-test-panel
              *ngIf="sessionStatus !== 'Success'"
              [pupilUserId]="userId"
              [isPupil]="true"
              [sessionIDParent]="testSessionId"
            >
            </prf-parent-test-panel>
          </ng-container>
        </div>
      </ng-container>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</ng-container>

<div *ngIf="urlSessionId" class="result-container">
  <div class="testing-progress-bar-block">
    <div class="testing-progress-bar full"></div>
  </div>

  <div class="header-testing">
    <a routerLink="/{{ userRole }}" class="testing-logo-link w-inline-block">
      <img src="{{ profilumLogoUrl }}" />
    </a>
    <div class="header-testing-div">
      <div class="test-title">{{ 'PLAYER.ANKETA.ABOUT_TEST.HEADER_PARENT' | translate }}</div>
    </div>
  </div>

  <div class="content-section testing-content-2">
    <!-- SITE-2839 -->
    <!-- <a (click)="printPage()" class="button white-button print-btn-2 w-button">Распечатать</a> -->
    <div class="container container-900">
      <prf-results></prf-results>
    </div>
  </div>
</div>
