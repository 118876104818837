import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CourseDetailsService } from 'app/pages/catalog/courses-page-without-filters/course-details/course-details.service';
import { CourseService } from 'app/pages/catalog/courses-page-without-filters/course.service';
import { COMPLEXITYS } from 'app/pages/catalog/courses-page/all-courses/active-courses/add-course/add-course.component';
import { MetroColors } from 'app/pages/catalog/courses-page/all-courses/active-courses/add-course/select-metro/metro-colors';
import { CoursesService } from 'app/pages/catalog/courses-page/courses.service';
import { SharedService } from 'app/shared/shared.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { AGE_DATA, COURSES_TYPES } from 'app/shared/global-constants/constants';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import * as moment from 'moment';
import { forkJoin, of, Observable, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';

@Component({
  selector: 'prf-promotest-course-details',
  templateUrl: './promotest-course-details.component.html',
  styleUrls: ['./promotest-course-details.component.scss'],
})
export class PromotestCourseDetailsComponent implements OnInit {
  course: any;
  types: any = COURSES_TYPES;
  classesFormat: string;
  ageData: any = AGE_DATA;
  isFavorite: boolean = false;
  otherCourses: any = [];
  similarCourses: any = [];
  favoritesCourses: any;
  courseEnrolls: any;
  userId: string;

  hrid: string;
  title: string;
  mapHeader: string;
  userRole: string;
  adminLevel: string = '';
  color: string;
  isCourse: boolean;
  startDate: string;
  complexity: string = '';

  // isEnrolled: boolean = false;
  dataFetched: boolean = false;
  // popUpConfirming: boolean = false;
  // addtoArchive: boolean = false;
  mapsLoaded: boolean = false;

  moment: any;
  metroColors: MetroColors = new MetroColors();

  fromString = '';
  beforeString = '';
  yearsString = '';
  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private courseDetailsService: CourseDetailsService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private sharedService: SharedService,
    private favoritesService: FavoritesService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    this.getTranslations(['SHARED.COURSE', 'SHARED.FROM', 'SHARED.BEFORE', 'COURCES.CATALOG.YEARS'])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.meta.updateTag({ name: 'og:title', content: translations['SHARED.COURSE'] });
        this.fromString = translations['SHARED.FROM'];
        this.beforeString = translations['SHARED.BEFORE'];
        this.yearsString = translations['COURCES.CATALOG.YEARS'];
      });
    this.userRole = localStorage.getItem('userRole');
    if (this.userRole.includes('admin')) {
      this.adminLevel = localStorage.getItem('adminLevel');
    }
    this.userId = localStorage.getItem('userId');
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.overlayService.show();
    this.setTitles();
    this.route.params
      .pipe(
        switchMap(params => {
          this.hrid = params['hrid'];
          window.scrollTo(0, 0);
          return this.loadCurrentCourse();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.overlayService.hide());
  }

  loadCurrentCourse(): Observable<any> {
    return this.courseService.getClassByHRId(this.hrid).pipe(
      tap(data => {
        if (data.status == 'Not Found') {
          this.utilsService.openSnackBar(`Не удалось найти курс с hrid = ${this.hrid}`, 'error');
          this.router.navigate([this.backButtonUrl()]);
        }
        this.course = data.class;
        let type = this.types.find(el => el.value === this.course.classesFormat);
        this.classesFormat = type.viewValue;
        const complexity = COMPLEXITYS.find(c => c.value === this.course.complexity);
        this.complexity = complexity ? complexity.viewValue : '';
        this.color = type.color.length > 0 ? type.color : '';
        let startTime = this.moment(this.course.startDate).format('HH:mm');
        if (startTime == '00:00') {
          this.startDate = this.moment(this.course.startDate).format('D MMMM YYYY');
        } else {
          this.startDate = this.moment(this.course.startDate).format('D MMMM YYYY в HH:mm');
        }

        // let classEnrollObservable$: Observable<any>;
        // if (this.userRole === 'pupil') {
        //   classEnrollObservable$ = this.loadFavorites().pipe(
        //     switchMap(r =>
        //       this.courseDetailsService.getClassEnroll(this.userId, this.course.id).pipe(
        //         tap(classEnroll => {
        //           this.isEnrolled = classEnroll && classEnroll.isDeleted === false;
        //         })
        //       )
        //     )
        //   );
        // } else {
        //   classEnrollObservable$ = of(null);
        // }
        // return classEnrollObservable$;
      }),
    );
  }

  loadOtherCourses(): Observable<any> {
    if (this.course.institutionId && this.course.institutionId !== '00000000-0000-0000-0000-000000000000') {
      return forkJoin([
        this.courseService.getOtherEventsOrCourses(this.course.institutionId, this.isCourse),
        this.courseService.getSimilarEventsOrCourses(this.course.courses, this.isCourse),
      ]).pipe(
        tap(([otherCourses, similarCourses]) => {
          this.otherCourses = otherCourses.filter(d => d.id !== this.course.id);
          this.similarCourses = similarCourses.filter(d => d.id !== this.course.id);
        }),
      );
    } else {
      return of(null);
    }
  }

  loadFavorites(): Observable<any> {
    return this.favoritesService.getUserFavorites(this.userId, FavoritesTypes.Class).pipe(
      tap(r => {
        this.favoritesCourses = r;
        this.favoritesService.setFavoriteCourses(r);
        if (this.favoritesCourses) {
          let productIds = this.favoritesCourses.filter(el => el.productId === this.course.id);
          this.isFavorite = productIds.length > 0;
        }
      }),
    );
  }

  // archive(course) {
  //   course.isArchived = true;
  //   let observables: Observable<any>[] = [];
  //   //mongo
  //   if (this.userRole == 'schooladmin') {
  //     observables.push(this.schoolAdminPanelService.replaceClassDO(course));
  //   }  else {
  //     //admin
  //     observables.push(this.coursesService.changeClassesDO(course));
  //   }
  //   forkJoin(observables)
  //     .pipe(
  //       takeUntil(this.ngUnsubscribe$),
  //       switchMap(r =>
  //         this.getTranslations(['SHARED.COURSE_ADDED_TO_ARCHIVE']).pipe(
  //           tap(translations => {
  //             this.utilsService.openSnackBar(
  //               translations['SHARED.COURSE_ADDED_TO_ARCHIVE'],
  //               'success'
  //             );
  //             this.addtoArchive = !this.addtoArchive;
  //           })
  //         )
  //       )
  //     )
  //     .subscribe();
  // }

  // addToFavorite() {
  //   this.courseDetailsService
  //     .addToFavorites(this.course.id, this.userId)
  //     .pipe(
  //       takeUntil(this.ngUnsubscribe$),
  //       switchMap(r => this.loadFavorites())
  //     )
  //     .subscribe();
  // }

  // removeFavorite() {
  //   let filteredFavorites = this.favoritesCourses.filter(el => el.productId === this.course.id);
  //   let courseFavoriteIds = filteredFavorites.map(el => el.id);
  //   if (courseFavoriteIds) {
  //     courseFavoriteIds.forEach(favoriteId =>
  //       this.courseDetailsService
  //         .deleteCourseFromFavorites(favoriteId)
  //         .pipe(takeUntil(this.ngUnsubscribe$))
  //         .subscribe()
  //     );
  //     this.isFavorite = false;
  //   }
  // }

  // goToCourse() {
  //   if (!this.isEnrolled) {
  //     let currentObservable$: Observable<any> = of(null);
  //     if (this.userRole === 'parent') {
  //       currentObservable$ = this.courseDetailsService.addClassEnrollParent(this.course.id);
  //     }
  //     if (this.userRole === 'pupil') {
  //       currentObservable$ = this.courseDetailsService.addClassEnrollPupil(this.course.id);
  //     }
  //     currentObservable$
  //       .pipe(takeUntil(this.ngUnsubscribe$))
  //       .subscribe(() => (this.isEnrolled = true));
  //   }
  // }

  goToCourse() {
    const url = 'www.mos.ru/pgu/ru/application/dogm/077060701/#step_1';
    return this.router.navigate([]).then(result => {
      window.open('https://' + url, '_blank');
    });
    // return this.router.navigate([`${this.course.url}`]);
  }

  // cancelClassEnroll() {
  //   if (this.isEnrolled) {
  //     this.courseDetailsService
  //       .removeClassEnroll(this.course.id, this.userId)
  //       .pipe(take(1))
  //       .subscribe(r => (this.isEnrolled = false));
  //   }
  // }

  getAges() {
    if (this.course.maxAgeAudience != 100 && this.course.minAgeAudience != 0) {
      return (
        this.fromString +
        ' ' +
        this.course.minAgeAudience +
        ' ' +
        this.beforeString +
        ' ' +
        this.course.maxAgeAudience +
        ' ' +
        this.yearsString
      );
    } else if (this.course.maxAgeAudience == 100) {
      return this.course.minAgeAudience + '+ ' + this.yearsString;
    } else if (this.course.minAgeAudience == 0) {
      return this.fromString + ' ' + this.course.maxAgeAudience + ' ' + this.yearsString;
    }
  }

  calculateRealPrice() {
    return this.courseDetailsService.calculateRealPrice(this.course);
  }

  setTitles() {
    this.getTranslations(['COURCES.CATALOG.COURCES', 'SHARED.EVENT_ON_MAP'])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.title = translations['COURCES.CATALOG.COURCES'];
        this.mapHeader = translations['SHARED.COURSE_ON_MAP'];
        this.isCourse = true;
      });
  }

  backButtonUrl() {
    return '/vorobievi-gori/partners-courses-all';
  }

  // deleteCourse(courseID) {
  //   let observables: Observable<any>[] = [];
  //   //mongo
  //   if (this.userRole == 'schooladmin') {
  //     observables.push(this.schoolAdminPanelService.deleteClassDO(courseID));
  //   }  else {
  //     //admin
  //     observables.push(this.coursesService.removeCurrentClass(courseID));
  //   }
  //   //elastic
  //   observables.push(this.courseService.removeCurrentClass(courseID));
  //   observableForkJoin(observables)
  //     .pipe(
  //       takeUntil(this.ngUnsubscribe$),
  //       catchError(err => {
  //         this.popUpConfirming = !this.popUpConfirming;
  //         this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
  //         return throwError(err);
  //       })
  //     )
  //     .subscribe(([delMongo, delElastic]) => {
  //       if (delMongo.status == 'Success') {
  //         this.utilsService.openSnackBar(`👌 Курс удален`, 'success');
  //       }
  //       this.popUpConfirming = !this.popUpConfirming;
  //       return this.router.navigate([this.backButtonUrl()]);
  //     });
  // }

  checkMenuRole() {
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
        return true;
      default:
        return false;
    }
  }

  // checkFavourite(): boolean {
  //   switch (this.userRole) {
  //     case 'pupil':
  //     case 'parent':
  //       return true;
  //     default:
  //       return false;
  //   }
  // }

  checkCourseEdit(course) {
    let isEditable = false;
    isEditable =
      this.userRole == 'admin' ||
      (this.userRole == 'schooladmin' &&
        course.regionId === localStorage.getItem('regionId') &&
        course.municipalityId === localStorage.getItem('municipalityId') &&
        course.institutionId === localStorage.getItem('schoolId'));

    return isEditable;
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
