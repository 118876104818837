import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlayerService } from 'app/pages/player/player.service';
import { IResult } from 'app/pages/results/results.interface';
import { ResultsService } from 'app/pages/results/results.service';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import {
  SpiderChartFiguresEnum,
  SpiderChartThemeEnum,
} from '@profilum-components/spider-chart-custom/spider-chart-custom.model';

@Component({
  selector: 'prf-pupil-results-shortened',
  templateUrl: './pupil-results-shortened.component.html',
  styleUrls: ['./pupil-results-shortened.component.scss'],
})
export class PupilResultsShortenedComponent implements OnInit {
  @Input()
  set sessionId(val: string) {
    //загружаем результаты по sessionId
    this._sessionId = val;
    if (val) {
      this.playerService
        .getResults(this._sessionId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(results => {
          this.parseResults(results);
        });
    }
  }
  _sessionId: string = null;
  get sessionId() {
    return this._sessionId;
  }

  @Output() loaded = new EventEmitter<boolean>();

  public interestsTopCategories: any[] = [];
  public skillsTopCategories: any[] = [];

  public skillsAttainmentData = [];
  public interestsData = [];
  public talentsData = [];

  public fieldsData = [];
  public selectedField: any;
  public activeProfessionList: IProfession[] = [];
  public userRole: string = '';
  public isCompleteTestParent: boolean = false;

  public skillsAttainment: IResult[] = [];
  public interests: IResult[] = [];
  public talents: IResult[] = [];
  public professionList: IProfession[] = [];

  public additionalText: Array<string> = [];
  public additionalTextName: string = null;
  public additionalTextShortDescription: string = null;
  public userId: string = '';
  public parent: any = {};
  public results: any;
  public chartSize: number = 560; // [px]

  private talentDescriptionByRole: Object = {};

  nullResults: boolean = false;
  resultsFetched: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(
    private playerService: PlayerService,
    private overlayService: OverlayBusyService,
    private resultService: ResultsService,
    private translate: TranslateService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.setChartSize(window.innerWidth);
  }

  parseResults(results) {
    let resultsData;
    resultsData = JSON.parse(JSON.stringify(results));

    resultsData.results = resultsData.results.map(item => {
      item.results = item.results.filter(s => s.sessionId === this._sessionId);
      return item;
    });

    if (!this.checkResultsCategories(resultsData.results, ['SkillAttainment', 'TalentAttainment'])) {
      this.nullResults = true;
      setTimeout(() => this.overlayService.hide(), 500);
      // данные загружены
      this.resultsFetched = true;
      this.loaded.emit(true);
      return;
    }

    this.skillsAttainment = resultsData.results.filter(d => d.objectType === 'SkillAttainment');
    this.talents = resultsData.results.filter(d => d.objectType === 'Talent');
    this.skillsAttainmentData = this.resultService.transformSkills(this.skillsAttainment, this.sessionId);

    this.skillsTopCategories = [];

    for (let i = 0; i < this.skillsAttainmentData[0].length; i++) {
      this.skillsTopCategories.push({
        childValue: this.skillsAttainmentData[0][i] ? Math.round(this.skillsAttainmentData[0][i]['value'] * 100) : null,
        parentValue: this.skillsAttainmentData[1][i] ? Math.round(this.skillsAttainmentData[1][i]['value'] * 100) : null,
        name: this.skillsAttainmentData[0][i]['axis'],
      });
    }
    if (this.sessionId) {
      this.skillsTopCategories = this.skillsTopCategories.sort((a, b) => b.childValue - a.childValue).slice(0, 3);
    } else {
      this.skillsTopCategories = this.skillsTopCategories.sort((a, b) => b.parentValue - a.parentValue).slice(0, 3);
    }

    // получаем урл картинок для талантов
    this.resultService
      .getTalentsUrls()
      .pipe(
        take(1),
        tap(data => {
          // преобразовываем массив с урлами талантов объект
          data.forEach(item => {
            this.talentsData = this.preTransformTalents(this.talents);
          });
          setTimeout(() => this.overlayService.hide(), 500);
          // данные загружены
          this.resultsFetched = true;
          this.loaded.emit(true);
        }),
      )
      .subscribe();
  }

  private checkResultsCategories(resultsArray, categoriesNames: string[]): boolean {
    let resultsAvailable: boolean;
    categoriesNames.forEach(categoryName => {
      let categoryResultsArray = resultsArray.filter(r => r.objectType === categoryName);
      resultsAvailable = categoryResultsArray.every(resultItem => resultItem.results?.length > 0);
    });
    return resultsAvailable;
  }

  private preTransformTalents(talents) {
    const shortDescriptionLen: number = 40;
    return talents
      .map(t => {
        return {
          name: t.name,
          description: this.talentDescriptionByRole[t.name],
          shortDescription:
            t.description.length < shortDescriptionLen ? t.description : t.description.slice(0, shortDescriptionLen - 2) + '..',
          value: t.results.length ? t.results[0].transformedValue : 0,
          imageUrl: document.location.origin + this.location.prepareExternalUrl(t.imageUrl),
          title: this.isEn() ? t.name : t.data.DativeCase,
        };
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, this.resultService.TALENTS_TO_CHOOSE);
  }

  private setChartSize(size: number) {
    if (size > 890 && size <= 960) {
      this.chartSize = 500;
    } else if (size > 460 && size <= 890) {
      this.chartSize = 450;
    } else if (size <= 460 && size > 370) {
      this.chartSize = 400;
    } else if (size <= 370) {
      this.chartSize = 350;
    } else {
      this.chartSize = 560;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setChartSize(event.target.innerWidth);
  }

  private isEn = () => this.translate.currentLang === 'en';
  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  protected readonly SpiderChartThemeEnum = SpiderChartThemeEnum;
  protected readonly SpiderChartFiguresEnum = SpiderChartFiguresEnum;
}
