<ng-container [ngSwitch]="userRole">
  <ng-container *ngSwitchCase="'pupil'">
    <prf-menu></prf-menu>
  </ng-container>

  <ng-container *ngSwitchCase="'tutor'">
    <prf-tutor-menu [innerRoute]="true"></prf-tutor-menu>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <prf-menu></prf-menu>
  </ng-container>
</ng-container>

<div class="content-section">
  <prf-page-header [title]="'HEADER.PROF' | translate"></prf-page-header>
  <div class="container">
    <div class="tabs" *ngIf="loaded">
      <div class="tabs-professions">
        <div *ngIf="userRole === 'pupil'" class="tabs-menu-div-2">
          <div class="tabs-menu-prof">
            <a (click)="tabName = 'catalog'" [class.current]="tabName === 'catalog'" class="tab-link-prof w-inline-block">
              <div>{{ 'SHARED.CATALOG' | translate }}</div>
              <img src="/assets/images/icons/angle.png" width="20" alt="" class="angle" />
            </a>
            <a (click)="tabName = 'recommendations'" [class.current]="tabName === 'recommendations'" class="tab-link-prof w-inline-block">
              <div>Рекомендованные</div>
              <img src="/assets/images/icons/angle.png" width="20" alt="" class="angle" [class.hide]="tabName !== 'recommendations'" />
              <img src="/assets/images/icons/angle2.png" width="20" alt="" class="angle _2" />
            </a>
          </div>
        </div>

        <div [class.no-display]="tabName !== 'catalog'" class="tabs-content">
          <div class="tab-pane left">
            <prf-profession-catalog [tabName]="tabName"></prf-profession-catalog>
          </div>
        </div>

        <div *ngIf="userRole === 'pupil'" [class.no-display]="tabName !== 'recommendations'" class="tabs-content">
          <div class="tab-pane left">
            <prf-recommended-professions [sessionId]="testSessionId"></prf-recommended-professions>
          </div>
        </div>
      </div>
    </div>

    <prf-page-footer></prf-page-footer>
  </div>
</div>
