import { NgModule } from '@angular/core';
import { AnketaService } from 'app/pages/player/anketa/anketa.service';
import { PlayerService } from 'app/pages/player/player.service';
import { HyphensService } from 'app/shared/services/hyphens.service';
import { WindowRef } from 'app/shared/services/windowRef.service';
import { SharedModule } from 'app/shared/shared.module';
import { HttpService } from '../../core/services/http.service';
import { BackendService } from '../../shared/services/backend.service';
import { CheckConnectionService } from '../../shared/services/check-connection.service';
import { D3ToolsService } from '../../shared/services/d3tools.service';
import { SlideService } from '../../shared/services/slide.service';
import { StorageService } from '../../shared/services/storage.service';
import { AboutTestComponent } from './about-test/about-test.component';
import { AgileBubbleBoxContainerComponent } from './agile-bubble-box-container/agile-bubble-box-container.component';
import { AgileBubbleBoxComponent } from './agile-bubble-box/agile-bubble-box.component';
import { AnketaComponent } from './anketa/anketa.component';
import { BubbleBoxContainerComponent } from './bubble-box-container/bubble-box-container.component';
import { ChildInfoComponent } from './child-info/child-info.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SelectTestTypeIntComponent } from './select-test-type/select-test-type-int/select-test-type-int.component';
import { SelectTestTypeComponent } from './select-test-type/select-test-type.component';
import {
  SelectUserStatusIntComponent,
} from './select-user-status/select-user-status-int/select-user-status-int.component';
import { SelectUserStatusComponent } from './select-user-status/select-user-status.component';
import { TellAboutComponent } from './tell-about/tell-about.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [SharedModule, RouterModule],

  declarations: [
    TellAboutComponent,
    SelectTestTypeComponent,
    SelectTestTypeIntComponent,
    SelectUserStatusComponent,
    SelectUserStatusIntComponent,
    ChildInfoComponent,
    AnketaComponent,
    NavigationComponent,
    BubbleBoxContainerComponent,
    AboutTestComponent,
    AgileBubbleBoxComponent,
    AgileBubbleBoxContainerComponent,
  ],
  providers: [
    CheckConnectionService,
    SlideService,
    StorageService,
    BackendService,
    D3ToolsService,
    HyphensService,
    HttpService,
    PlayerService,
    AnketaService,
    WindowRef,
  ],
})
export class PlayerModule {}
