import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { GoalsService } from '../../../../../shared/dashboard/goals/goals.service';
import { Observable, of, Subject } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';

@Component({
  selector: 'prf-pupil-plan',
  templateUrl: './pupil-plan.component.html',
  styleUrls: ['./pupil-plan.component.scss'],
})
export class PupilPlanComponent implements OnInit {
  @ViewChild('swiperComponentFirst') public readonly swiperComponentFirst?: ElementRef;
  @ViewChild('swiperComponentSecond') public readonly swiperComponentSecond?: ElementRef;
  @ViewChild('swiperComponentThird') public readonly swiperComponentThird?: ElementRef;
  @ViewChild('swiperComponentFourth') public readonly swiperComponentFourth?: ElementRef;
  @ViewChild('swiperComponentStatic') public readonly swiperComponentStatic?: ElementRef;
  public swiperStatic?: Swiper;
  public swiperFirst?: Swiper;
  public swiperSecond?: Swiper;
  public swiperThird?: Swiper;
  public swiperFourth?: Swiper;

  @Input()
  set profession(val: IProfession) {
    this.loaded = false;
    this._profession = val;
  }

  get profession(): IProfession {
    return this._profession;
  }

  private _profession: any;
  public swiperConfig: SwiperOptions  = {
    slidesPerView: 3,
    slidesPerGroup: 3,
    speed: 500,
    allowTouchMove: true,
  };
  programsAmount: number = 3;
  isDoneData: Array<any> = [];
  userTrajectories: Array<any> = [];
  userStaticTrajectories: Array<any> = [];

  public loaded: boolean = false;
  public tasksLevel: number = 1;
  public currentIndexCarouselFirst: number = 0;
  public currentIndexCarouselSecond: number = 0;
  public currentIndexCarouselThird: number = 0;
  public currentIndexCarouselFourth: number = 0;
  public currentIndexCarouselStatic: number = 0;

  public first_block: Array<any> = [];
  public second_block: Array<any> = [];
  public third_block: Array<any> = [];
  public fourth_block: Array<any> = [];
  public classesInTrajectory: Array<any> = [];
  public staticTasksInTrajectory: Array<any> = [];

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private goalsService: GoalsService) {}

  ngOnInit() {
    this.getUserTrajectories();
    this.goalsService
      .checkTrajectories()
      .pipe(
        tap(updateTrajectories => {
          if (updateTrajectories) {
            this.getUserTrajectories();
          } else return of(null);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }
  public ngAfterViewInit(): void {
    this.swiperStatic = this.swiperComponentStatic?.nativeElement.swiper;
    this.swiperFirst = this.swiperComponentFirst?.nativeElement.swiper;
    this.swiperSecond = this.swiperComponentSecond?.nativeElement.swiper;
    this.swiperThird = this.swiperComponentThird?.nativeElement.swiper;
    this.swiperFourth = this.swiperComponentFourth?.nativeElement.swiper;
  }
  getUserTrajectories() {
    this.goalsService
      .getUserTrajectories(this._profession.id)
      .pipe(
        switchMap(userTrajectories => {
          this.isDoneData = userTrajectories.filter(d => d.isDone == true);
          this.userTrajectories = userTrajectories;
          // получаем модели статических Tasks заданий из траектории пользователя
          return this.getStaticTasksInTrajectory(userTrajectories).pipe(
            switchMap(r => {
              // получаем модели Classes из траектории пользователя
              return this.getClassesInTrajectory(userTrajectories).pipe(
                tap(classes => {
                  if (classes) {
                    this.classesInTrajectory = classes;
                    // фильтруем курсы по нужным группам
                    this.filterClasses(this.tasksLevel, this.classesInTrajectory);
                  }
                }),
              );
            }),
          );
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        this.loaded = true;
      });
  }

  public changeTaskLevel(lvl) {
    if (this.tasksLevel != lvl) {
      this.tasksLevel = lvl;
      this.filterClasses(this.tasksLevel, this.classesInTrajectory);
    }
  }

  private getClassesInTrajectory(userTrajectories): Observable<any> {
    let trajectoriesCatalogIds: string[] = [];
    userTrajectories.forEach(trajectory => {
      if (trajectory.trajectoryType != 'CatalogTask') {
        trajectoriesCatalogIds.push(trajectory.productId);
      }
    });
    if (trajectoriesCatalogIds.length != 0) {
      return this.goalsService.getClassesByIds(trajectoriesCatalogIds).pipe(
        tap(r => {
          this.classesInTrajectory = r;
        }),
      );
    } else {
      this.classesInTrajectory = [];
      return of(null);
    }
  }

  private getStaticTasksInTrajectory(userTrajectories): Observable<any> {
    let trajectoriesStaticTasksIds: string[] = [];
    let staticTasksInTrajectory: Array<any> = [];

    let trajectoriesStaticTasksRequests$: Observable<any>[] = [];

    userTrajectories.forEach(trajectory => {
      if (trajectory.trajectoryType == 'CatalogTask') {
        trajectoriesStaticTasksRequests$.push(
          this.goalsService.getStaticTaskById(trajectory.productId).pipe(
            tap(data => {
              if (data) {
                //id - уникальный идентификатор добавленного в тректорию объекта
                //productId - идентификатор курса
                data['id'] = trajectory.id;
                data['productId'] = trajectory.productId;
                staticTasksInTrajectory.push(data);
                this.staticTasksInTrajectory = this.transformForCarousel(staticTasksInTrajectory);
              }
            }),
          ),
        );
      }
    });
    if (userTrajectories.length != 0) {
      return forkJoin(trajectoriesStaticTasksRequests$);
    } else {
      this.staticTasksInTrajectory = [];
      return of(null);
    }
  }

  private filterClasses(taskLevel, classesInTrajectory) {
    let filter: any[] = [];
    if (taskLevel === 1) {
      filter[0] = this.setFilter(['Introductory', 'Base', 'ShortCourse', 'LongCourse']);
      filter[1] = this.setFilter(['Introductory', 'Base', 'MasterClass', 'Profproba']);
      filter[2] = this.setFilter(['Introductory', 'Base', 'Excursion', 'Meeting']);
      filter[3] = this.setFilter(['Introductory', 'Base', 'Action', 'Festival']);
    } else if (taskLevel === 2) {
      filter[0] = this.setFilter(['InDepth', 'PreProfessional', 'ShortCourse', 'LongCourse']);
      filter[1] = this.setFilter(['InDepth', 'PreProfessional', 'MasterClass', 'Profproba']);
      filter[2] = this.setFilter(['InDepth', 'PreProfessional', 'Excursion', 'Meeting']);
      filter[3] = this.setFilter(['InDepth', 'PreProfessional', 'Action', 'Festival']);
    }

    this.first_block = [];
    let first_block = classesInTrajectory.filter(item => {
      return this.filterFunction(item, filter[0]);
    });
    this.first_block = this.transformForCarousel(first_block);

    this.second_block = [];
    let second_block = classesInTrajectory.filter(item => {
      return this.filterFunction(item, filter[1]);
    });
    this.second_block = this.transformForCarousel(second_block);

    this.third_block = [];
    let third_block = classesInTrajectory.filter(item => {
      return this.filterFunction(item, filter[2]);
    });
    this.third_block = this.transformForCarousel(third_block);

    this.fourth_block = [];
    let fourth_block = classesInTrajectory.filter(item => {
      return this.filterFunction(item, filter[3]);
    });
    this.fourth_block = this.transformForCarousel(fourth_block);
  }

  private setFilter(params: string[]): any {
    return {
      complexity: [params[0], params[1]],
      classesFormat: [params[2], params[3]],
    };
  }

  private filterFunction(item: any, filter: any): boolean {
    return (
      (item.complexity == filter.complexity[0] || item.complexity == filter.complexity[1]) &&
      (item.classesFormat == filter.classesFormat[0] || item.classesFormat == filter.classesFormat[1])
    );
  }

  public clickNextFirst() {
    this.swiperFirst.slideNext();
    this.currentIndexCarouselFirst++;
  }

  public clickPrevFirst() {
    this.swiperFirst.slidePrev();
    this.currentIndexCarouselFirst--;
  }

  public clickNextSecond() {
    this.swiperSecond.slideNext();
    this.currentIndexCarouselSecond++;
  }

  public clickPrevSecond() {
    this.swiperSecond.slidePrev();
    this.currentIndexCarouselSecond--;
  }

  public clickNextThird() {
    this.swiperThird.slideNext();
    this.currentIndexCarouselThird++;
  }

  public clickPrevThird() {
    this.swiperThird.slidePrev();
    this.currentIndexCarouselThird--;
  }

  public clickNextFourth() {
    this.swiperFourth.slideNext();
    this.currentIndexCarouselFourth++;
  }

  public clickPrevFourth() {
    this.swiperFourth.slidePrev();
    this.currentIndexCarouselFourth--;
  }

  public clickNextStatic() {
    this.swiperStatic.slideNext();
    this.currentIndexCarouselStatic++;
  }

  public clickPrevStatic() {
    this.swiperStatic.slidePrev();
    this.currentIndexCarouselStatic--;
  }

  public StaticTaskModal(staticTask) {
    if (staticTask) {
      let staticTaskData = {
        staticTask: staticTask,
        available: true,
      };
      this.goalsService.showStaticTaskModal(staticTaskData);
    }
  }

  private transformForCarousel(data): Array<any> {
    let recommendedCourses = [{ programms: [] }];
    let index = 0;
    data.forEach(c => {
      if (recommendedCourses[index].programms.length < this.programsAmount) {
        recommendedCourses[index].programms.push(c);
      } else {
        recommendedCourses.push({ programms: [] });
        recommendedCourses[index + 1].programms.push(c);
      }
    });
    return recommendedCourses;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
