import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ResultsService } from '../../results/results.service';

import { GoalsService } from '../../../shared/dashboard/goals/goals.service';
import { PupilPanelService } from '../pupil/pupil-panel.service';
import { SharedService } from 'app/shared/shared.service';
import { Observable, of, pipe, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { ParentPanelService } from './parent-panel.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';
import { Router } from '@angular/router';
import { ITestInfo } from 'app/shared/interfaces/itestinfo.interface';

@Component({
  selector: 'prf-parent-panel',
  templateUrl: './parent-panel.component.html',
  styleUrls: ['./parent-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ParentPanelComponent implements OnInit, OnDestroy {
  public userId: string = '';
  public profileLoaded: boolean = false;
  public userProfile: any;
  public favoriteProfessions: Array<any> = [];
  public showProfile: boolean = false;
  public isShowAddChild: boolean = false;
  public childrenRegister: Array<any> = [];
  public isShowChildReg: boolean = false;
  public pageTitle: string = null;
  public isActiveReg: boolean = false;

  public sessionIDParent: string = '';
  public sessionIDChild: string = '';
  public sessionIDLoaded: boolean = false;

  private _selectedChild: any;

  isTabletScreen: boolean = false;
  menuAllowed: boolean = true;

  public testsVariantsIds = TESTS_VARIANTS_IDS;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private goalsService: GoalsService,
    private sharedService: SharedService,
    private parentPanelService: ParentPanelService,
    private favoritesService: FavoritesService,
    private resultService: ResultsService,
    private appSettingsService: AppSettingsService,
    private router: Router,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Страница родителя' });
    this.isTabletScreen = window.innerWidth <= 768;
  }

  set selectedChild(child: any) {
    this._selectedChild = child;
    this.favoriteProfessions = [];
    this.loadFavoritesProfessions(this._selectedChild.userId);
    // this.sharedResults();
  }

  get selectedChild(): any {
    return this._selectedChild;
  }

  ngOnInit() {
    const testSessionId = localStorage.getItem('testSessionId');

    if (testSessionId === '00000000-0000-0000-0000-000000000000') {
      return this.router.navigate(['/test-na-professiyu']);
    }

    if (localStorage.getItem('sessionStatus') !== 'Success') {
      return this.router.navigate([`/test-na-professiyu/${testSessionId}`]);
    }

    this.userId = localStorage.getItem('userId');
    this.loadProfile();
    this.sharedResults();
  }

  loadFavoritesProfessions(userId: string = null) {
    this.favoritesService
      .getUserFavorites(userId, FavoritesTypes.Class)
      .pipe(
        switchMap(favorites => {
          if (favorites.length) {
            const favoritesProfessionsIds = favorites.filter(el => el.productType === 3).map(el => el.productId);
            return this.favoritesService.getUserFavoritesProfessions(favoritesProfessionsIds).pipe(
              tap(favoriteProfessions => {
                this.favoriteProfessions = favoriteProfessions;
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  public sharedResults() {
    this.sessionIDLoaded = false;
    this.resultService
      .getSharedResults(this.userId)
      .pipe(
        switchMap(sharedResults => {
          if (sharedResults.length > 0) {
            return this.sharedService.getRegionTestInfo(localStorage.getItem('regionId')).pipe(
              tap((testInfo: ITestInfo) => {
                let sessionID = sharedResults.filter(result => result.screeningTestId === testInfo.screeningTestId);
                if (sessionID.length > 0) {
                  this.sessionIDParent = sessionID[0].sessionId;
                  this.sessionIDChild = sessionID[0].refferalSessionId;
                } else {
                  this.sessionIDParent = null;
                  //если нет сессии, редирект на начало теста
                  this.routingTest();
                }
              }),
            );
          } else {
            //если нет sharedResults, редирект на начало теста
            this.routingTest();
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        this.sessionIDLoaded = true;
      });
  }
  routingTest() {
    return this.router.navigate(['/test-na-professiyu']);
  }

  public bindingChild(event) {
    if (event) {
      //copy without profileLoaded flag
      this.sharedService
        .getUserInfoData()
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(data => {
          this.userProfile = data;
          let children = this.userProfile.children;
          //todo при добавлении свитчера между детьми, поменять
          if (children && children.length) {
            this.selectedChild = children[children.length - 1];
          }
          this.menuAllowed = !!(this.selectedChild && this.selectedChild.id !== '00000000-0000-0000-0000-000000000000');
        });
    }
  }

  get dataLoaded(): boolean {
    return this.sessionIDLoaded && this.profileLoaded;
  }

  public loadProfile() {
    this.profileLoaded = false;
    this.sharedService
      .getUserInfoData()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        this.userProfile = data;
        let children = this.userProfile.children;
        //todo при добавлении свитчера между детьми, поменять
        if (children && children.length) {
          this.selectedChild = children[children.length - 1];
        } else {
          this.sessionIDLoaded = true;
        }
        this.menuAllowed = !!(this.selectedChild && this.selectedChild.id !== '00000000-0000-0000-0000-000000000000');
        this.profileLoaded = true;
      });
  }

  setTitle(val: string) {
    this.pageTitle = val;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isTabletScreen = event.target.innerWidth <= 768;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
