import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { ICity } from 'app/shared/interfaces/icity';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseGuid, TEXT_MASK } from '../../../../../shared/global-constants/constants';
import { REG_EXP } from '../../../../../shared/global-constants/reg-exp';
import { ICompany } from '../../../../../shared/interfaces/icompany.interface';
import { AdminPanelService } from '../../admin-panel.service';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { RegionsService } from '../../regions/regions.service';

export interface IEditUser {
  userId: string;
  email: string;
  newEmail?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
}
@Component({
  selector: 'prf-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  @Output() updateValue = new EventEmitter<any>();

  @Output() editUserFlag = new EventEmitter();

  @Input() set inputInstitutions(val: Array<any>) {
    this.institutions = val;
  }
  @Input() set inputOldUserData(val: any) {
    this.oldUserData = val;
  }
  @Input() set inputUserRole(val: any) {
    this.userRole = val;
  }

  public adminLevel: any;
  public userRegionId: string = '';
  public userMunicipalityId: string = '';
  public isRegion: boolean = false;
  public isMunicipality: boolean = false;
  public textMasks = TEXT_MASK;
  private guidEmpty: string = BaseGuid;
  private dateRegExp: RegExp = REG_EXP.dateRegExp;
  private passwordRegExp: RegExp = REG_EXP.passwordRegExp;

  public allSchools: ISchool[];
  public allCities: ICity[];

  public municipalitiesByRegion: IMunicipality[] = [];
  public citiesByMunicipality: ICity[] = [];
  public schoolsFiltered: ISchool[] = [];

  public institutions: Array<any> = [];
  public oldUserData: any;
  public userRole: string;
  public allRegions: Array<any> = [];
  public error: boolean = false;
  public dataChanged = false;
  public formUser: UntypedFormGroup = new UntypedFormGroup({});
  private adminPanelService: AdminPanelService = inject(AdminPanelService);
  public companies$: Observable<ICompany[]> = this.adminPanelService.getAllCompanies();

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private translateService: TranslateService,
    public utilsService: UtilsService,
  ) {
    this.getTranslations(['SHARED.SCHOOL_ADMIN', 'SHARED.SCHOOL_GO_ADMIN'])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {});
  }

  ngOnInit() {
    this.buildFormByRole();
  }

  public toggleEditUserForm() {
    this.editUserFlag.emit(!this.editUserFlag);
  }

  public onSubmit() {
    if (!this.isFormInvalid) {
      this.editUser()
        .pipe(
          switchMap(response => {
            if (response.status == 'Success') {
              return this.getTranslations(['SHARED.USER_EDIT_MSG']).pipe(
                tap(translations => {
                  this.utilsService.openSnackBar(translations['SHARED.USER_EDIT_MSG'], 'success');
                  this.updateValue.emit(this.userRole);
                  this.editUserFlag.emit(!this.editUserFlag);
                }),
              );
            } else {
              return this.getTranslations(['SHARED.ERROR_EDIT_MSG']).pipe(
                tap(translations => this.utilsService.openSnackBar(translations['SHARED.ERROR_EDIT_MSG'], 'error')),
              );
            }
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe();
    } else {
      if (!this.formUser.pristine) {
        this.getTranslations(['SHARED.ERROR_EDIT_MSG'])
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(translations => this.utilsService.openSnackBar(translations['SHARED.ERROR_EDIT_MSG'], 'error'));
      } else {
        this.updateValue.emit(this.userRole);
        this.editUserFlag.emit(!this.editUserFlag);
      }
    }
  }

  private editUser(): Observable<any> {
    let userData: IEditUser = this.formUser.value;
    userData.lastName = this.formUser.get('lastName').value;
    userData.firstName = this.formUser.get('firstName').value;
    userData.middleName = this.formUser.get('middleName').value;
    userData.userId = this.oldUserData.userId;
    userData.email = this.oldUserData.isActivated ? this.oldUserData.email : '';
    userData.newEmail = this.oldUserData.isActivated ? this.formUser.get('email').value : userData.email;
    this.dataChanged = true;
    return this.adminPanelService.changeUserInfo(userData);
  }

  public buildFormByRole() {
    this.formUser.reset();
    this.formUser = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, Validators.required),
      middleName: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl({
        value: !this.oldUserData.isActivated ? this.oldUserData.email : '',
        disabled: !this.oldUserData.isActivated ? true : false,
      }),
    });

    this.formUser.get('firstName').setValue(this.oldUserData.firstName);
    this.formUser.get('lastName').setValue(this.oldUserData.lastName);
    this.formUser.get('middleName').setValue(this.oldUserData.middleName == null ? '' : this.oldUserData.middleName);
    this.formUser.get('email').setValue(this.oldUserData.isActivated ? this.oldUserData.email : '');

    this.formUser.updateValueAndValidity();
  }

  public get isAdmin() {
    this.adminLevel = localStorage.getItem('adminLevel');
    return this.adminLevel === AdminAccessLevel.GLOBAL;
  }

  public get isFormInvalid() {
    return this.formUser.invalid || this.formUser.pristine || this.formUser.errors;
  }

  private trimNulledProperty() {
    let result = {};
    let sourceData = this.formUser.value;
    Object.keys(sourceData).forEach(property => {
      if (sourceData[property] != null && sourceData[property] != undefined) {
        result[property] = sourceData[property];
      }
    });

    return result;
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }
}
