<ng-container [ngSwitch]="userRole">
  <ng-container *ngSwitchCase="'schooladmin'">
    <prf-schooladmin-menu [innerRoute]="true"></prf-schooladmin-menu>
  </ng-container>

  <ng-container *ngSwitchCase="'tutor'">
    <prf-tutor-menu></prf-tutor-menu>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <prf-menu></prf-menu>
  </ng-container>
</ng-container>

<section class="content-section">
  <prf-page-header [title]="'Курсы'"></prf-page-header>
  <article class="container">
    <router-outlet></router-outlet>

    <div class="tabs">
      <div class="tabs-professions">
        <div *ngIf="userRole === 'pupil'" class="tabs-menu-div-2">
          <div class="tabs-menu-prof">
            <a (click)="tabName = 'catalog'" [class.current]="tabName === 'catalog'" class="tab-link-prof w-inline-block">
              <div>{{ 'SHARED.CATALOG' | translate }}</div>
              <img src="/assets/images/icons/angle.png" width="20" alt="" class="angle" />
            </a>
            <a (click)="tabName = 'recommendations'" [class.current]="tabName === 'recommendations'" class="tab-link-prof w-inline-block">
              <div>Рекомендованные</div>
              <img src="/assets/images/icons/angle.png" width="20" alt="" class="angle" [class.hide]="tabName !== 'recommendations'" />
              <img src="/assets/images/icons/angle2.png" width="20" alt="" class="angle _2" />
            </a>
          </div>
        </div>

        <div [class.no-display]="tabName !== 'catalog'" class="tabs-content">
          <div class="tab-pane left">
            <router-outlet name="courses-catalog"></router-outlet>
          </div>
        </div>

        <div [class.no-display]="tabName !== 'recommendations'" class="tabs-content">
          <div class="tab-pane left">
            <router-outlet name="recommended-courses" *ngIf="userRole === 'pupil'"></router-outlet>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="professions-section">-->
    <!--<router-outlet></router-outlet>-->
    <!--<router-outlet name="recommended-courses" *ngIf="userRole === 'pupil'"></router-outlet>-->
    <!--<router-outlet name="courses-catalog"></router-outlet>-->
    <!--</div>-->

    <prf-page-footer></prf-page-footer>
  </article>
</section>
