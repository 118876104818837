<div *ngIf="testType == testTypes.DEFAULT_STANDART || testType == testTypes.DEFAULT_360" class="about-test-wrapper">
  <div class="about-test-section">
    <div *ngIf="role === 'pupil'" class="about-test-container" [formGroup]="form" novalidate>
      <h1>{{ 'PLAYER.ANKETA.ABOUT_TEST.HEADER_CHILDREN' | translate }}</h1>
      <p>{{ 'PLAYER.ANKETA.ABOUT_TEST.TEXT_1_CHILDREN' | translate }}</p>
      <p>{{ 'PLAYER.ANKETA.ABOUT_TEST.TEXT_2_CHILDREN' | translate }}</p>
      <p>{{ 'PLAYER.ANKETA.ABOUT_TEST.TEXT_3_CHILDREN' | translate }}</p>
    </div>
    <div *ngIf="role === 'parent'" class="about-test-container _parent" [formGroup]="form" novalidate>
      <h1>{{ 'PLAYER.ANKETA.ABOUT_TEST.HEADER_PARENT' | translate }}</h1>
      <p>{{ 'PLAYER.ANKETA.ABOUT_TEST.TEXT_PARENT' | translate }}</p>
    </div>
    <div class="about-test-container">
      <div class="test-info" *ngIf="testDuration">
        <div class="test-duration">
          <div class="duration-value">{{ testDuration }}</div>
          <div class="duration-units">минут</div>
        </div>
        <div class="questions-quantity" *ngIf="testSteps">
          <div class="quantity-value">{{ testSteps }}</div>
          <div class="quantity-text">вопросов</div>
        </div>
      </div>
    </div>
    <div class="w-center" *ngIf="!isBaseNavigation">
      <button class="start-test-button button-new" (click)="nextToTest()">Пройти диагностику</button>
    </div>
  </div>
</div>

<div *ngIf="testType == testTypes.PROFESSIONPRESTIGE.toString()">
  <h1>Опрос о профессиях</h1>
  <p>
    Приглашаем тебя пройти небольшой опрос про современный рынок труда, который содержит несколько вопросов на тему знания и отношения к
    разным профессиям. Опрос займет у тебя около 5 минут, и поможет собрать ценную информацию для исследовательских целей.
  </p>
  <img src="/assets/images/dashboard/start-page-prej.svg" class="welcome-image-stud" />
</div>
<div class="about-test-section" *ngIf="testType === testTypes.MOTIVATION_TEST.toString()">
  <h1>{{ 'PLAYER.ANKETA.ABOUT_MOTIV_TEST.HEADER' | translate }}</h1>
  <p>{{ 'PLAYER.ANKETA.ABOUT_MOTIV_TEST.TEXT_1' | translate }}</p>
  <p>{{ 'PLAYER.ANKETA.ABOUT_MOTIV_TEST.TEXT_2' | translate }}</p>
  <p>{{ 'PLAYER.ANKETA.ABOUT_MOTIV_TEST.TEXT_3' | translate }}</p>
</div>
<div class="about-test-section" *ngIf="testType === testTypes.VALUES_TEST.toString()">
  <h1>Какая у тебя система ценностей?</h1>
  <p>
    У каждого человека есть своя система ценностей — определенный набор убеждений: часть из них очень важны, другие нейтральны, а некоторые
    — совсем не важны. Ценности определяют, что мы выбираем и какие поступки совершаем. По результатам тестирования наша система покажет,
    что важно именно для тебя, и даст практические рекомендации, как сделать свою жизнь комфортнее.<br />
    Успешного старта!
  </p>
</div>
<div class="about-test-section" *ngIf="testType === testTypes.CAREER_TYPES.toString()">
  <h1>заголовок</h1>
  <p>текст...описание теста</p>
</div>
