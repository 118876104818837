import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { ChildRegistrationComponent } from './children-add/child-registration/child-registration.component';
import { ChildrenAddComponent } from './children-add/children-add.component';
import { ChildrenTabsComponent } from './children-tabs/children-tabs.component';
import { ParentPanelComponent } from './parent-panel.component';
import { ParentRoutingModule } from './parent-routing.module';
import { PromoTestComponent } from './promo-test/promo-test.component';
import { ChildrenFavoritesComponent } from './children-favorites/children-favorites.component';
import { ParentChildContainerComponent } from './parent-child-container/parent-child-container.component';
import { ParentResultsShortenedComponent } from './parent-child-container/parent-results-shortened/parent-results-shortened.component';
import { ParentChildInviteComponent } from './parent-child-container/parent-child-invite/parent-child-invite.component';
import { ProfessionMenuComponent } from './parent-child-container/profession-menu/profession-menu.component';
import { ParentPupilProfileComponent } from './parent-child-container/parent-pupil-profile/parent-pupil-profile.component';
import { CodesChildrenComponent } from './parent-child-container/codes-children/codes-children.component';
import { ParentChildRegistrationComponent } from './parent-child-container/parent-child-registration/parent-child-registration.component';
import { ChildAddFormComponent } from './parent-child-container/parent-child-registration/child-add-form/child-add-form.component';
import { SpiderChartCustomComponent } from '@profilum-components/spider-chart-custom/spider-chart-custom.component';

@NgModule({
  declarations: [
    ParentPanelComponent,
    ChildrenTabsComponent,
    ParentChildContainerComponent,
    ProfessionMenuComponent,
    ParentPupilProfileComponent,
    ChildrenAddComponent,
    ChildrenAddComponent,
    ChildRegistrationComponent,
    CodesChildrenComponent,
    PromoTestComponent,
    ParentChildRegistrationComponent,
    ChildAddFormComponent,
    ChildrenFavoritesComponent,
    ParentResultsShortenedComponent,
    ParentChildInviteComponent,
  ],
  imports: [CommonModule, ParentRoutingModule, SharedModule, SpiderChartCustomComponent],
})
export class ParentModule {}
