import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { SharedService } from 'app/shared/shared.service';
import { ClipboardService } from 'ngx-clipboard';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ParentPanelService } from '../../parent-panel.service';

@Component({
  selector: 'prf-parent-child-registration',
  templateUrl: './parent-child-registration.component.html',
  styleUrls: ['./parent-child-registration.component.scss'],
})
export class ParentChildRegistrationComponent implements OnInit {
  child: any;
  children: any[];
  childNotRegistered: boolean = false;
  public showToolTip: boolean = false;
  private isTabletScreen: boolean = false;

  registrationPupilLink: string;
  linkIsCopied: boolean = false;
  userProfile: UserInfoClass;

  userTag: string;
  userId: string;

  public registrationSteps = [
    {
      name: 'Добавьте ребенка',
      value: 'addChild',
      status: 'active',
    },
    {
      name: 'Отправьте ссылку',
      value: 'shareLink',
      status: null,
    },
  ];
  menuAllowed: boolean;

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(private clipboardService: ClipboardService, private sharedService: SharedService) {
    this.userTag = localStorage.getItem('tag');
    this.userId = localStorage.getItem('userId');
    this.isTabletScreen = window.innerWidth <= 768;
  }

  ngOnInit() {
    this.getParentInfo();
  }

  getParentInfo() {
    this.sharedService
      .getUserInfoData()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(userInfo => {
        this.userProfile = userInfo;
        let children = userInfo.children;
        //todo изменить, пока берем последнего ребенка
        this.child = children[children.length - 1];
        if (this.child) {
          this.registrationSteps[0].status = 'done';
          this.registrationSteps[1].status = 'active';
        }
        this.childNotRegistered = this.child?.id === '00000000-0000-0000-0000-000000000000';

        this.menuAllowed = !!(this.child && this.child.id !== '00000000-0000-0000-0000-000000000000');
        this.generateRegLink();
      });
  }
  updateChild(child) {
    if (child) {
      this.child = child;
      this.generateRegLink();

      this.registrationSteps[0].status = 'done';
      this.registrationSteps[1].status = 'active';
    }
  }
  generateRegLink() {
    if (this.child?.registrationCode) {
      this.registrationPupilLink =
        this.userTag === EUserTags[EUserTags.Default]
          ? location.origin + '/code-registration?code=' + this.child.registrationCode
          : this.userTag === EUserTags[EUserTags.b2c] || this.userTag === EUserTags[EUserTags.Prosv]
          ? location.origin + '/code-registration?code=' + this.child.registrationCode
          : null;
    }
  }
  copyRegLink() {
    this.clipboardService.copy(this.registrationPupilLink);
    setTimeout(() => {
      this.linkIsCopied = true;
      let popupHintEl = document.getElementsByClassName('info-popup')[0] as HTMLElement;

      popupHintEl?.classList.add('opened');
      setTimeout(() => {
        popupHintEl?.classList.remove('opened');
      }, 3000);
    }, 50);
  }

  openSharing() {
    if (this.isTabletScreen && navigator.share) {
      navigator
        .share({
          url: this.registrationPupilLink,
        })
        .then();
    } else {
      const shareButtonField = document.querySelector('.button-share-container');
      shareButtonField.classList.toggle('open');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isTabletScreen = event.target.innerWidth <= 768;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
