import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SlideService } from '../../../shared/services/slide.service';

@Component({
  selector: 'prf-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @Input() buttonEvent: Subject<boolean>;
  @Input() enableForward: boolean;
  @Input() enableBack: boolean;
  @Input() disabled: boolean;
  @Input() showAgreements: boolean;
  @Input() isFinish: boolean = false;

  currentSlideNumber: number;

  public userRole: string = '';

  constructor(private location: Location, private slideService: SlideService) {
    this.userRole = localStorage.getItem('userRole');
  }

  ngOnInit() {
    this.slideService.getSlideNumber().subscribe(slide => {
      this.currentSlideNumber = slide;
    });
  }

  back() {
    this.buttonEvent.next(false);
    this.slideService.changeSlideNumber(this.currentSlideNumber - 1);
  }

  forward() {
    this.buttonEvent.next(true);
    this.slideService.changeSlideNumber(this.currentSlideNumber + 1);
  }

  public getLink(url) {
    return document.location.origin + this.location.prepareExternalUrl(url);
  }
}
