<header>
  <div>
    <div class="logo">
      <a routerLink="/"
        ><img alt="Профилум" src="{{ '/assets/images/dashboard/' + (isEn() ? 'Profilum_logo.png' : 'profilum-logo.svg') }}"
      /></a>
    </div>

    <nav>
      <ul class="navigation">
        <li>
          <a routerLink="/courses">{{ 'HEADER.COURSES' | translate }}</a>
        </li>
        <li>
          <a routerLink="/professions">{{ 'HEADER.PROF' | translate }}</a>
        </li>
        <li>
          <a href="https://profilum.ru/blog/" target="_blank">{{ 'SHARED.BLOG' | translate }}</a>
        </li>
      </ul>
    </nav>

    <div class="top-contact">
      <span><a href="tel:+78005501246">8 800 550-12-46</a></span>
    </div>

    <div class="top-contact" aria-hidden="true">
      <a routerLink="/login">{{ 'SHARED.ENTRANCE' | translate }}</a>
      <a routerLink="/registration">{{ 'SHARED.REGISTRATION' | translate }}</a>
    </div>
  </div>
</header>
