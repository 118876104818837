<div class="welcome-page">
  <img class="logo_cornered" src="/assets/images/dashboard/profilum-new-logo.svg" alt="Profilum logo" />
  <div class="container welcome-container">
    <div class="welcome-title">Какой у вас Профилум?</div>
    <div class="welcome-content">
      <div class="content-container">
        <a class="content-tab tab-school" href="https://class.profilum.ru/login">
          <div class="tab-title">Школьный</div>
          <div class="tab-text">Это часть учебной программы</div>

          <button class="button-blue">Войти</button>
        </a>
        <a class="content-tab tab-family" href="/">
          <div class="tab-title">Семейный</div>
          <div class="tab-text">Родитель приобрёл программу</div>

          <button class="button-white">Войти</button>
        </a>
      </div>
    </div>
    <prf-page-footer></prf-page-footer>
  </div>
</div>
