<section class="professions">
  <div class="admin-section">
    <div class="tabs w-tabs">
      <div class="tabs-menu w-tab-menu">
        <a
          (click)="tabLevel = professionsMenu.MONGO"
          [class.w--current]="tabLevel === professionsMenu.MONGO"
          class="tab-link adm w-inline-block w-tab-link"
        >
          <div>Монго</div>
          <img src="/assets/images/courses/angle.png" width="20" class="angle" />
        </a>
        <a
          (click)="tabLevel = professionsMenu.ELASTIC"
          [class.w--current]="tabLevel === professionsMenu.ELASTIC"
          class="tab-link adm w-inline-block w-tab-link"
        >
          <div>Поисковый индекс</div>
          <img src="/assets/images/courses/angle2.png" width="20" class="angle _2" />
          <img *ngIf="adminLevel === 'GLOBAL'" src="/assets/images/courses/angle.png" width="20" class="angle" />
        </a>
      </div>
      <div class="tabs-content w-tab-content">
        <prf-professions [catalogType]="tabLevel"></prf-professions>
      </div>
    </div>
  </div>
</section>
