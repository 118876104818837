import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { AdminPanelService } from 'app/pages/control-panel/admin/admin-panel.service';
import { RegionsService } from 'app/pages/control-panel/admin/regions/regions.service';
import { ChildrenAddService } from 'app/pages/control-panel/parent/children-add/children-add.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { RegistrationService } from '../registration.service';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { DateValidator } from 'app/shared/instruments/validators/dateValidator';
import { Gender } from 'app/shared/enums/gender.enums';
import * as moment from 'moment';
import { SCHOOL_NUMBERS } from 'app/shared/global-constants/school-numbers';
import { ICity } from 'app/shared/interfaces/icity';
import { SchoolClassesClass } from 'app/shared/classes/school-classes.class';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { LoginService } from 'app/landing/b2c/login-b2c/login.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IAddUserInterface } from '../../../../shared/interfaces/iregistration.interface';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

export enum EParentRegSteps {
  SUBMIT_PHONE = 1,
  SEND_CODE,
  SUBMIT_PUPIL,
}

export enum EUserTypes {
  pupil = 'pupil',
  parent = 'parent',
}

@Component({
  selector: 'prf-open-registration-b2c',
  templateUrl: './open-registration-b2c.component.html',
  styleUrls: ['./open-registration-b2c.component.scss'],
})
export class OpenRegistrationB2cComponent extends UnsubscribeComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted: boolean;
  public readonly dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public readonly emailRegExp: RegExp = REG_EXP.emailRegExp;
  public readonly dateRegExp: RegExp = REG_EXP.dateRegExp;
  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  public readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  public readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  public readonly phoneRegExp: RegExp = REG_EXP.phoneRegExp;

  public isCharactersError: boolean = true;
  public isLetterError: boolean = true;
  public isNumberError: boolean = true;
  public isRusLettersError: boolean = false;
  public isWhiteSpaceError: boolean = false;
  public isNotValidPassword: boolean = false;
  public focusOutPasswordErrors: boolean = false;

  public addition: boolean = false;
  public regions = [];
  public cities: ICity[] = [];
  public classes: SchoolClassesClass[] = [];
  public classesFetched: boolean = false;
  public schools: any[] = [];
  public numbersClass: any = [];
  public lettersClass: any = [];

  public registrationFailed: boolean = false;
  public passFailed: boolean = false;
  public loginFailed: boolean = false;

  public moment: any = moment;
  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;
  public isMaskedPassword: boolean = true;
  public personalTerms: boolean = false;
  public duplicateUserName: boolean = false;
  public emailChecked: boolean = false;
  public checkEmailPattern: boolean = true;
  public phoneChecked: boolean = false;
  public phoneChildChecked: boolean = false;
  public checkBirthday: boolean = false;
  public isClasses: boolean = false;

  public timer$ = new Subject<number>();
  public activeTimer: boolean = false;
  public smsAllowed: boolean = true;
  public supportAlert: boolean = false;
  public parentRegStep: EParentRegSteps = EParentRegSteps.SUBMIT_PHONE;

  public SCHOOL_LETTERS: { value: string }[] = [];
  public defaultRegion: string = null;

  public formChild: UntypedFormGroup;

  public advertisingTerms: boolean = false;
  public countryCodeMask = ['+', ' ', /[0-9]/, /[0-9]/, /[0-9]/];
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];

  public isMobile: boolean = false;
  public genderChoice: string = Gender.Male;
  public invalidDate: boolean = false;

  public schoolName: string;
  public schoolID: string = '';
  public schoolClass: string;

  public loaded: boolean = false;
  public userTypes: typeof EUserTypes = EUserTypes;

  private role = 'parent';
  private tag: string;
  private _citiesView;
  private _schoolView: any[] = [];
  private isProsvetIdUser: boolean = false;
  private parentUserId: string = null;
  private newDate: Date = new Date();

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private regionsService: RegionsService,
    private childrenAddService: ChildrenAddService,
    private adminPanelService: AdminPanelService,
    private appSettingsService: AppSettingsService,
    private sharedService: SharedService,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private cd: ChangeDetectorRef,
    private utilsService: UtilsService,
  ) {
    super();
    const componentName: MetaDefinition = {
      name: 'profliner:title',
      content: 'Open Registration',
    };
    this.meta.addTag(componentName);
    this.moment = moment;
    this.moment.locale('ru');

    this.isMobile = window.innerWidth <= 450;
  }

  ngOnInit(): void {
    this.SCHOOL_LETTERS = this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS');
    this.initFormGroup();

    this.getData()
      .pipe(
        switchMap(() => {
          return this.activatedRoute.queryParams;
        }),
        tap(params => {
          if (params?.tag === EUserTags[EUserTags.Prosv]) {
            this.tag = EUserTags[EUserTags.Prosv];
            this.isProsvetIdUser = true;
            // this.form.removeControl('middleName');
          } else {
            this.tag = EUserTags[EUserTags.b2c];
          }
          if (params.regionId) {
            //registrationn by params
            localStorage.setItem('regionIdParam', params.regionId);
            let region = this.regionsView.find(region => region.data.id === params.regionId);
            if (region) {
              this.defaultRegion = region.data.regionId;
              this.form.get('region').setValue(region);
              this.onRegionChange();
              if (params.cityId) {
                localStorage.setItem('cityIdParam', params.cityId);
                let city = this.citiesView.find(city => city.data.id === params.cityId);
                if (city) {
                  this.form.get('city').setValue(city);
                  this.onCityChange();
                  this.formChild.get('municipalityId').setValue(city.data.municipalityId);
                  this.formChild.get('cityId').setValue(city.data.id);

                  if (params.schoolId) {
                    let school = this.schoolView.find(school => school.data.id === params.schoolId);
                    if (school) {
                      this.formChild.get('schoolId').setValue(school.data.id);
                      return this.onSchoolChange().pipe(
                        tap(() => {
                          if (params.classId) {
                            let pupilschoolClass = this.schoolClassView.find(schoolClass => schoolClass.data.id === params.classId);
                            this.formChild.get('schoolClassId').setValue(pupilschoolClass.data.id);
                          }
                        }),
                      );
                    } else {
                      return of(null);
                    }
                  } else {
                    return of(null);
                  }
                } else {
                  return of(null);
                }
              } else {
                return of(null);
              }
            } else {
              this.defaultRegion = AppSettingsService.settings.b2c.regionId;
              this.regions = this.regions.filter(r => r.id === this.defaultRegion);
              this.f.region.setValue(this.regionsView.find(r => r.data.id === this.defaultRegion));
              this.onRegionChange();
              return of(null);
            }
          } else {
            this.defaultRegion = AppSettingsService.settings.b2c.regionId;
            this.regions = this.regions.filter(r => r.id === this.defaultRegion);
            this.f.region.setValue(this.regionsView.find(r => r.data.id === this.defaultRegion));
            this.onRegionChange();
            this.f.city.setValue(this.citiesView.find(r => r.data.id === AppSettingsService.settings.b2c.cityId));

            //child form settings
            let municipalityId = AppSettingsService.settings.b2c.municipalityId;
            this.formChild.get('municipalityId').setValue(municipalityId);
            let cityId = AppSettingsService.settings.b2c.cityId;
            this.formChild.get('cityId').setValue(cityId);
            let schoolId = AppSettingsService.settings.b2c.schoolId;
            this.formChild.get('schoolId').setValue(schoolId);
            let classId = AppSettingsService.settings.b2c.classId;
            this.formChild.get('schoolClassId').setValue(classId);
          }

          params.schoolId ? localStorage.setItem('schoolIdParam', params.schoolId) : null;
          params.classId ? localStorage.setItem('classIdParam', params.classId) : null;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(_ => (this.loaded = true));
  }

  private initFormGroup(): void {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      countryCode: new UntypedFormControl(null),
      phone: new UntypedFormControl(null, [Validators.required, Validators.maxLength(18), Validators.pattern(this.phoneRegExp)]),
      region: new UntypedFormControl(null, [Validators.required]),
      city: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegExp)]),
      //  password: new FormControl(null, [Validators.required]),
      role: new UntypedFormControl('parent', [Validators.required]),
    });
    this.formChild = this.fb.group({
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      date: new UntypedFormControl(null, [Validators.required, DateValidator()]),
      countryCode: new UntypedFormControl(null),
      phone: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.phoneRegExp)]),
      role: new UntypedFormControl('pupil', [Validators.required]),
      municipalityId: new UntypedFormControl(null, [Validators.required]),
      cityId: new UntypedFormControl(null, [Validators.required]),
      schoolId: new UntypedFormControl(null, [Validators.required]),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      schoolClassId: new UntypedFormControl(null),
      privacyAccess: new UntypedFormControl(false, [Validators.requiredTrue]),
      personalTerms: new UntypedFormControl(false, [Validators.requiredTrue]),
    });
  }

  get f() {
    return this.form.controls;
  }

  get fChild() {
    return this.formChild.controls;
  }

  private getData(): Observable<any> {
    return this.getRegions().pipe(
      switchMap(() => {
        return this.getCities();
      }),
      switchMap(() => {
        return this.getSchools();
      }),
    );
  }

  public submit(): void {
    this.submitted = true;
    if (this.form.valid) {
      let phone = this.f.countryCode.value
        ? this.f.countryCode.value.replace(/\D/g, '') + this.f.phone.value.replace(/\D/g, '')
        : '7' + this.f.phone.value.replace(/\D/g, '');

      let credentials = {
        email: this.f.email.value,
        lastName: this.f.lastName.value,
        firstName: this.f.firstName.value,
        password: this.f.password.value,
        phoneNumber: phone,
        userName: phone,
        regionId: this.f.city.value.data.regionId,
        municipalityId: this.f.city.value.data.municipalityId,
        city: this.f.city.value.data.name,
        role: this.f.role.value,
        tag: this.tag,
        isConsentToMailing: this.advertisingTerms,
      };

      if (!credentials.phoneNumber) {
        delete credentials.phoneNumber;
      }

      localStorage.clear();

      this.registrationFailed = false;
      this.passFailed = false;

      this.registrationService
        .openregistration(credentials)
        .pipe(
          switchMap((response: any) => {
            if (!response || response.userId == undefined) {
              if (response.status === 'User with current phone number already exists') {
                this.f.phone.setErrors({ isUsed: true });
                return of(null);
              }
              if (response.comment === 'Sms code is outdated') {
                this.f.password.setErrors({ isOutdated: true });
                return of(null);
              }
              //  this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
              this.registrationFailed = true; // ошибка регистрации
              this.failWaiting();
              return of(null);
            } else {
              return this.registrationService.login(response.userName, credentials.password, false).pipe(
                switchMap((loginResult: any) => {
                  if (!loginResult || loginResult?.succeeded === false) {
                    this.loginFailed = true;
                    this.passFailed = true;
                    this.removeWaiting();
                    return of(null);
                  } else {
                    localStorage.setItem('userRole', loginResult.role);
                    localStorage.setItem('userId', loginResult.userId);
                    localStorage.setItem('tag', loginResult.tag);
                    localStorage.setItem('isAuthorized', 'true');
                    this.parentUserId = loginResult.userId;

                    return this.registrationChild();
                  }
                }),
                switchMap((response: boolean) => {
                  if (response) {
                    return this.sharedService.getUserInfoData().pipe(
                      tap((ui: UserInfoClass) => {
                        if (ui) {
                          this.setUserInfoInLS(ui);
                        }

                        if (localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString()) {
                          this.router.navigate(['/vorobievi-gori/mosrupromo']);
                          return this.removeWaiting();
                        }

                        this.router.navigate(['/test-na-professiyu']);
                        return this.removeWaiting();
                      }),
                    );
                  } else {
                    return of(null);
                  }
                }),
              );
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    }
  }

  private getRegions(): Observable<any> {
    return this.regionsService.getAllRegions().pipe(
      tap(regions => {
        this.regions = regions.filter(region => region.hrid != 'defaultRegion');
      }),
    );
  }

  private getCities(): Observable<any> {
    return this.adminPanelService.getAllCities().pipe(tap(cities => (this.cities = cities)));
  }

  private getSchools(): Observable<any> {
    return this.childrenAddService.getCatalogSchools().pipe(
      tap((schools: any[]) => {
        this.schools = [...schools].map(school => ({
          city: school.city,
          value: school.number,
          viewValue: school.number,
          id: school.id,
        }));
      }),
    );
  }

  private getSchoolClassBySchool(id): Observable<any> {
    return this.registrationService.getSchoolClassesBySchool(id).pipe(
      tap((classes: SchoolClassesClass[]) => {
        this.classes = classes;
        this.classes && this.classes.length ? (this.isClasses = true) : null;
        this.classesFetched = true;
      }),
    );
  }

  private onRegionChange(): void {
    this.f.city.reset();
    if (this.f.region.value && this.f.region.value.data.id) {
      this.citiesView = this.f.region.value.data.id;
    }
  }

  private onCityChange(): void {
    this.formChild.get('schoolId').reset();
    if (this.f.city.value && this.f.city.value.data.name) {
      this.schoolView = this.f.city.value.data.name;
    }
  }

  private onSchoolChange(): Observable<any> {
    if (this.formChild.get('schoolId').value) {
      return this.getSchoolClassBySchool(this.formChild.get('schoolId').value).pipe(
        tap(() => {
          this.formChild.get('schoolClassId').reset();
          this.formChild.get('schoolClassNumber').reset();
          this.formChild.get('schoolClassNumber').reset();
        }),
      );
    } else {
      return of(null);
    }
  }

  get regionsView() {
    return [...this.regions].map(region => ({ name: region.name, data: region }));
  }

  set citiesView(regionId) {
    this._citiesView = regionId
      ? [...this.cities].filter(city => city.regionId === regionId).map(city => ({ name: city.name, data: city }))
      : [];
  }

  get citiesView() {
    return this._citiesView;
  }

  get schoolClassNumber() {
    return [...SCHOOL_NUMBERS].map(number => ({ name: number.value, data: number }));
  }

  get schoolClassLetter() {
    return [...this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS')].map(letter => ({
      name: letter.value,
      data: letter,
    }));
  }

  set schoolView(cityName) {
    this._schoolView = cityName
      ? [...this.schools].filter(school => school.city === cityName).map(school => ({ name: school.viewValue, data: school }))
      : [];
  }

  get schoolView() {
    return this._schoolView;
  }

  get schoolClassView() {
    return [...this.classes].map(schoolClass => ({
      name: schoolClass.number + schoolClass.letter,
      data: schoolClass,
    }));
  }

  private setDefaultErrorFlagValues(): void {
    this.isWhiteSpaceError = false;
    this.isCharactersError = true;
    this.isLetterError = true;
    this.isNumberError = true;
    this.isRusLettersError = false;
  }

  private updateErrorFlagValues(password: string): void {
    this.isWhiteSpaceError = !this.testWhiteSpace.test(password);
    this.isCharactersError = !this.testSixCharter.test(password);
    this.isLetterError = !this.testOneLetter.test(password);
    this.isNumberError = !this.testOneDigit.test(password);
    this.isRusLettersError = this.testRusLetters.test(password);
  }

  private isAnyPasswordError(): boolean {
    return this.isCharactersError || this.isRusLettersError || this.isWhiteSpaceError;
  }

  public getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }

  public animateLogin(): void {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.submit();
  }

  public removeWaiting(): void {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }

  public failWaiting(): void {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }

  public isValidPassword(password: string): boolean {
    if (password && password.length > 0) {
      this.updateErrorFlagValues(password);
      if (this.isAnyPasswordError()) {
        this.isNotValidPassword = true; // isWhiteSpaceError был без этой записи
        return false;
      } else {
        this.isNotValidPassword = false;
        return true;
      }
    } else {
      this.setDefaultErrorFlagValues();
      return false;
    }
  }

  public focusOutErrorChecking(): void {
    this.focusOutPasswordErrors = this.isAnyPasswordError();
  }

  public checkFormatEmail(event): void {
    if (event) {
      this.emailChecked = true;
      this.checkEmailPattern = this.emailRegExp.test(this.f.email.value);
      if (this.checkEmailPattern) {
        this.f.email.setErrors(null);
        this.registrationService
          .checkEmail(this.f.email.value)
          .pipe(
            take(1),
            catchError(err => {
              this.utilsService.openSnackBar('Неисправности с базой данных, уже чиним.', 'error');
              return throwError(err);
            }),
            tap(r => {
              if (r.status === 'Success') {
                if (!r.isFree) {
                  this.f.email.setErrors({ duplicateUserName: true });
                }
              }
            }),
          )
          .subscribe();
      }
    }
  }

  public checkPhone(): void {
    this.phoneChecked = true;
    this.f.phone.setErrors(null);

    let phone = this.f.countryCode.value
      ? this.f.countryCode.value.replace(/\D/g, '') + this.f.phone.value.replace(/\D/g, '')
      : '7' + this.f.phone.value.replace(/\D/g, '');

    if (phone.length < 11) return;

    !this.phoneRegExp.test(this.f.phone.value.replace(/\D/g, '')) ? this.f.phone.setErrors({ notValid: true }) : null;

    this.registrationService
      .checkPhone(phone)
      .pipe(
        take(1),
        catchError(err => {
          this.utilsService.openSnackBar('Неисправности с базой данных, уже чиним.', 'error');
          return throwError(err);
        }),
        tap(r => {
          if (r.status === 'Success') {
            if (!r.isFree) {
              this.f.phone.setErrors({ isUsed: true });
            }
          }
        }),
      )
      .subscribe();
  }

  public checkChildPhone(): void {
    this.phoneChildChecked = true;
    this.fChild.phone.setErrors(null);

    let phone = this.fChild.countryCode.value
      ? this.fChild.countryCode.value.replace(/\D/g, '') + this.fChild.phone.value.replace(/\D/g, '')
      : '7' + this.fChild.phone.value.replace(/\D/g, '');

    !this.phoneRegExp.test(this.fChild.phone.value.replace(/\D/g, '')) ? this.fChild.phone.setErrors({ notValid: true }) : null;

    if (this.phoneNumbersEqual) {
      return this.fChild.phone.setErrors({ isEqual: true });
    }

    this.registrationService
      .checkPhone(phone)
      .pipe(
        take(1),
        catchError(err => {
          this.utilsService.openSnackBar('Неисправности с базой данных, уже чиним.', 'error');
          return throwError(err);
        }),
        tap(r => {
          if (r.status === 'Success') {
            if (!r.isFree) {
              this.fChild.phone.setErrors({ isUsed: true });
            }
          }
        }),
      )
      .subscribe();
  }

  private setUserInfoInLS(ui: UserInfoClass): void {
    if (!ui) {
      throw 'User info is not defined';
    }

    localStorage.setItem('imagePath', ui.imagePath);
    localStorage.setItem('firstName', ui.firstName);
    localStorage.setItem('lastName', ui.lastName);
    localStorage.setItem('schoolId', ui.schoolId);
    localStorage.setItem('userGender', ui.gender);
    localStorage.setItem('companyId', ui.companyId);
    localStorage.setItem('position', ui.position);
    localStorage.setItem('regionId', ui.regionId);
    localStorage.setItem('municipalityId', ui.municipalityId);
    ui.parents[0]?.userId ? localStorage.setItem('parentUserId', ui.parents[0]?.userId) : null;
  }

  public checkParent(): void {
    if (this.form.valid) {
      this.parentRegStep = EParentRegSteps.SEND_CODE;

      let phone = this.f.countryCode.value
        ? this.f.countryCode.value.replace(/\D/g, '') + this.f.phone.value.replace(/\D/g, '')
        : '7' + this.f.phone.value.replace(/\D/g, '');

      this.sendPhone(phone).pipe(take(1)).subscribe();
    }
  }

  public submitParent(): void {
    if (this.form.valid && this.isValidPassword(this.f.password?.value)) {
      this.parentRegStep = EParentRegSteps.SUBMIT_PUPIL;
    }
  }

  private sendPhone(phone): Observable<any> {
    return this.loginService.sendPhone(phone).pipe(
      take(1),
      tap(response => {
        switch (response.status) {
          case 'Not Found': {
            this.form.get('phone').setErrors({ notFound: true });
            break;
          }
          case 'Failed': {
            if (response.comment.includes('invalid number')) {
              this.f.phone.setErrors({ invalidNumber: true });
              break;
            } else {
              this.f.phone.setErrors({ failed: true });
              break;
            }
          }
          case 'Success': {
            this.form.addControl('password', new UntypedFormControl(null, [Validators.required]));
            this.cd.detectChanges();
            let codeInputElement = document.getElementById('password');
            codeInputElement.focus();

            //temp fix
            if (!environment.production) {
              this.form.get('password').setValue(response.code);
              this.form.get('password').updateValueAndValidity();
              this.isMaskedPassword = false;
            }
            this.startTimer(60);
            break;
          }
          default: {
            this.f.phone.setErrors({ failed: true });
            break;
          }
        }
      }),
    );
  }

  public checkFormatDate(event): void {
    if (event) {
      this.validateAge();
      this.checkBirthday = true;
    }
  }

  get isAccessAllowed() {
    return this.form.valid;
  }

  get isAccessChildAllowed() {
    return (
      this.formChild.valid &&
      (this.formChild.value.schoolClassId || (this.formChild.value.schoolClassNumber && this.formChild.value.schoolClassLetter)) &&
      this.validateAge() &&
      !this.phoneNumbersEqual
    );
  }

  get checkParentForm() {
    return this.form.valid;
  }

  get phoneNumbersEqual() {
    return this.formChild.get('phone').value.replace(/\D/g, '') === this.form.get('phone').value.replace(/\D/g, '');
  }

  public toggleMask(): void {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  public prosvetReg(): void {
    this.sharedService
      .getProsvetRoute()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(prosvetRoute => {
        if (prosvetRoute) {
          location.href = prosvetRoute.url;
          return;
        }
      });
  }

  public registrationChild(): Observable<any> {
    if (this.formChild.valid) {
      let child: IAddUserInterface = {
        firstName: this.formChild.get('firstName').value,
        lastName: this.formChild.get('lastName').value,
        role: 'pupil',
        birthday: moment(this.formChild.get('date').value, 'DD/MM/YYYY', true).format('YYYY-MM-DD'),
        phoneNumber: this.fChild.countryCode.value
          ? this.fChild.countryCode.value.replace(/\D/g, '') + this.fChild.phone.value.replace(/\D/g, '')
          : '7' + this.fChild.phone.value.replace(/\D/g, ''),
        gender: this.genderChoice,
        city: this.formChild.get('cityId').value,
        regionId: this.defaultRegion,
        municipalityId: this.formChild.get('municipalityId').value,
        schoolClassIds: this.formChild.get('schoolClassId').value ? [this.formChild.get('schoolClassId').value] : [],
        schoolId: this.formChild.get('schoolId').value,
        tag: EUserTags[EUserTags.b2c],
      };
      return this.registrationService.addUser(child).pipe(
        switchMap((response: any) => {
          if (response?.status === 'Success') {
            localStorage.setItem('RefferalUserId', response.userId);
            localStorage.setItem('RefferalUserGender', response.gender);
            return of(true);
          } else {
            return of(false);
          }
        }),
      );
    } else {
      this.failWaiting();
      return of(false);
    }
  }

  public isMale(gender: string) {
    return gender === Gender.Male;
  }

  public setMale() {
    this.genderChoice = Gender.Male;
  }

  public setFemale() {
    this.genderChoice = Gender.Female;
  }

  public validateAge(): boolean {
    let date = this.formChild.get('date').status === 'VALID' ? moment(this.formChild.value.date, 'DD-MM-YYYY').toDate() : null;
    this.invalidDate = false;

    //ребенок не старше maximumChildAge лет
    if (date && date?.getTime() / 1000 + AppSettingsService.settings.maximumChildAge * 365 * 24 * 60 * 60 > this.newDate.getTime() / 1000) {
      return true;
    }
    this.invalidDate = true;
    return false;
  }

  public sendPasswordTwice(): void {
    let phone = this.f.countryCode.value
      ? this.f.countryCode.value.replace(/\D/g, '') + this.f.phone.value.replace(/\D/g, '')
      : '7' + this.f.phone.value.replace(/\D/g, '');
    this.sendPhone(phone)
      .pipe(
        tap(() => (this.smsAllowed = false)),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  public supportRequest(): void {
    this.supportAlert = true;
    this.timer = setTimeout(() => {
      this.supportAlert = false;
    }, 2000);
  }

  private startTimer(seconds: number): void {
    let count = seconds;
    this.activeTimer = true;
    timer(0, 1000)
      .pipe(
        take(count),
        tap(() => {
          count > 0 ? --count : 0;
          this.timer$.next(count);
          if (!count) {
            this.activeTimer = false;
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isMobile = event.target.innerWidth <= 450;
  }

  public onPhonePaste($event: ClipboardEvent, userType: EUserTypes): void {
    let copied: string = $event.clipboardData.getData('text/plain').replace(/\D/g, '');

    if (copied.length === 11) {
      copied = copied.slice(1);

      $event.preventDefault();
      this.setUserPhone(copied, userType);
    } else if (copied.length === 10) {
      $event.preventDefault();
      this.setUserPhone(copied, userType);
    }
  }

  private setUserPhone(phone: string, userType: EUserTypes): void {
    if (userType === this.userTypes.parent) {
      this.form.get('phone').setValue(phone);
    } else {
      this.formChild.get('phone').setValue(phone);
    }
    this.checkPhone();
  }
}
