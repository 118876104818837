import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'app/shared/shared.service';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { IProfessionFilter } from 'app/shared/interfaces/iprofessionfilter.interface';
import { forkJoin, of, Subject, throwError, TimeoutError, BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { HttpService } from '../../../core/services/http.service';
import { UtilsService } from '../backend-services/utils.service';

@Injectable()
export class GoalsService {
  public trajectoryShowCount: number = 0;
  defaultGuid: string = '00000000-0000-0000-0000-000000000000';
  public updateTrajectories$ = new Subject<boolean>();

  private staticTask: any = new BehaviorSubject(null);

  constructor(private http: HttpService, private utilsService: UtilsService, private sharedService: SharedService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  // addToFavorites(productId, userId): Observable<any> {
  //   let params = {
  //     productId: productId,
  //     productType: 'Profession',
  //     userId: userId,
  //   };
  //   return this.http.post('/api/v1.0/profiles/favorites/addtofavorites', params).pipe(
  //     map(r => r),
  //     catchError((err: HttpErrorResponse) => {
  //       return this.handleError(err);
  //     })
  //   );
  // }

  // deleteGoal(productId: string): Observable<any> {
  //   return this.http.delete('/api/v1.0/profiles/favorites/delete?favoriteId=' + productId).pipe(
  //     map(r => r),
  //     catchError((err: HttpErrorResponse) => {
  //       return this.handleError(err);
  //     })
  //   );
  // }

  // getUserFavoritesProfessions(ids: string[]): Observable<any> {
  //   let url = '/api/v1.0/catalog/professions/elasticsearch/search/filters';
  //   let credentials: IProfessionFilter = {};
  //   if (ids.length) {
  //     credentials.ids = [];
  //     ids.forEach(id => {
  //       credentials.ids.push(id);
  //     });
  //   } else {
  //     return of([]);
  //   }

  //   return this.http.post(url, credentials).pipe(
  //     map(r => r),
  //     catchError((err: HttpErrorResponse) => {
  //       this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
  //       return this.handleError(err);
  //     })
  //   );
  // }

  getClassesByCourses(courses: Array<string>): Observable<any> {
    let filtersRegion: IFilterClasses = {};
    let filtersDefault: IFilterClasses = {};
    filtersRegion.courses = courses;
    filtersDefault.courses = courses;

    let regionId = localStorage.getItem('regionId');
    let municipalityId = localStorage.getItem('municipalityId');
    let currentObservable$: Observable<any> = of(null);

    // если в localstorage нет региона и муниципалитета, то запросить через userinfo
    if (!regionId || !municipalityId) {
      currentObservable$ = this.sharedService.getUserInfoData().pipe(
        take(1),
        tap(r => {
          regionId = r.regionId ? r.regionId : null;
          municipalityId = r.municipalityId ? r.municipalityId : null;
        }),
      );
    }

    return currentObservable$.pipe(
      switchMap(_ => {
        filtersRegion.regionId = regionId;
        filtersRegion.municipalityId = municipalityId;
        filtersDefault.regionId = this.defaultGuid;
        filtersDefault.municipalityId = this.defaultGuid;
        return forkJoin([
          this.http.post('/api/v1.0/catalog/classes/elasticsearch/search/filters', filtersRegion),
          this.http.post('/api/v1.0/catalog/classes/elasticsearch/search/filters', filtersDefault),
        ]).pipe(
          map(
            ([res, resDefault]) => {
              return resDefault.concat(res);
            },
            catchError((err: HttpErrorResponse) => {
              return this.handleError(err);
            }),
          ),
        );
      }),
    );
  }

  getClassesByIds(ids: Array<string>): Observable<any> {
    let filters: IFilterClasses = {};
    filters.ids = ids;
    let credentials = {
      filters: filters,
    };

    return this.http.post('/api/v1.0/catalog/classes/mongo/search/filters', credentials).pipe(
      map(r => r.classes),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getTasks(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/tasks/all').pipe(
      map(r => r.tasks),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getStaticTaskById(id: string) {
    const url = '/api/v1.0/catalog/tasks/onebyid?id=' + id;
    return this.http.get(url).pipe(
      map(r => r.task),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUserTrajectories(professionID: string): Observable<any> {
    return this.http.get('/b2c/v1.0/saas/getusertrajectories?professionId=' + professionID).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  addUserTrajectory(userId: string, productId: string, trajectoryType: string, professionID: string): Observable<any> {
    const params = 'UserId=' + userId + '&ProductId=' + productId + '&ProfessionId=' + professionID + '&TrajectoryType=' + trajectoryType;
    return this.http.post('/b2c/v1.0/pupils/addusertrajectory?' + params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
  updateUserTrajectory(
    id: string,
    userId: string,
    productId: string,
    trajectoryType: string,
    professionID: string,
    completed?: boolean,
  ): Observable<any> {
    let params =
      'Id=' + id + '&UserId=' + userId + '&ProductId=' + productId + '&ProfessionId=' + professionID + '&TrajectoryType=' + trajectoryType;

    params = completed === true ? params.concat('&IsDone=true') : completed === false ? params.concat('&IsDone=false') : params;

    return this.http.post('/b2c/v1.0/pupils/updateusertrajectory?' + params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  deleteUserTrajectory(userId: string, productId: string): Observable<any> {
    const params = 'UserId=' + userId + '&ProductId=' + productId;
    return this.http.delete('/b2c/v1.0/saas/deleteusertrajectory?' + params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  addFileToTrajectory(file: File, id: string): Observable<any> {
    let formData: FormData = new FormData();
    formData.append(file.name, file);
    return this.http.postImage('/b2c/v1.0/utils/upload/' + id, formData).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getFilesToTrajectory(id: string): Observable<any> {
    return this.http.get('/b2c/v1.0/utils/getfilesforobject?id=' + id).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  deleteFileFromTrajectory(id: string): Observable<any> {
    return this.http.delete('/b2c/v1.0/utils/deletefile?id=' + id).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  showStaticTaskModal(staticTaskData: any) {
    this.staticTask.next(staticTaskData);
  }

  closeStaticTaskModal() {
    this.staticTask.next(null);
  }

  getStaticTaskModal(): Observable<any> {
    return this.staticTask.asObservable();
  }

  toUpdateTrajectories(toUpdate: boolean) {
    this.updateTrajectories$.next(toUpdate);
  }
  checkTrajectories() {
    return this.updateTrajectories$.asObservable();
  }

  getStaticTaskByComplexity(complexity: string): Observable<any> {
    return this.http.post('/api/v1.0/catalog/tasks/search/complexity?complexity=' + complexity).pipe(
      map(r => r.tasks),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
}
