<div class="results-shortened">
  <h2 class="test-result-h1 non-mobile left-aligned">🎉 Ура, диагностика пройдена!</h2>
  <p class="test-result-text left-aligned">
    А ты знаешь, что успешны те, кто выбрал профессию, исходя из своих талантов? Таланты находятся на пересечении твоих «могу» и «хочу» —
    того, что ты умеешь и любишь делать. Выбирай то, что тебе нравится и к чему есть способности.
  </p>

  <section class="talants">
    <div class="talant-cards">
      <div class="card-wrapper" *ngFor="let talant of talents">
        <prf-pupil-talant-card [talant]="talant"></prf-pupil-talant-card>
      </div>
    </div>
  </section>

  <section class="test-result-section" #skills>
    <h2 class="test-result-h2 left-aligned">Твои навыки и способности</h2>
    <p class="test-result-text left-aligned">
      Эта схема демонстрирует твои сильные стороны и определяет круг задач, которые ты можешь успешно решать.
    </p>
    <div class="test-result-diagram">
      <prf-spider-chart-custom [inputSpiderChartData]="skillsAttainmentData"
                               [theme]="SpiderChartThemeEnum.GREEN"
                               [figureType]="SpiderChartFiguresEnum.HEXAGON"
      > </prf-spider-chart-custom>
    </div>
  </section>

  <div class="overlay-cap">
    <div class="overlay-cap-content">
      <div class="cap-data-container">
        <div class="cap-title">Самое интересное — впереди</div>
        <p class="cap-text">
          С подробными результатами поможет разобраться эксперт Профилума. Передай родителям, что завершил диагностику — они договорятся о
          встрече.
        </p>
      </div>
    </div>
  </div>
</div>
