import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/shared/shared.service';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ETestTypes, ETestTypesSteps } from '../../../shared/enums/testtypes.enum';

@Component({
  selector: 'prf-about-test',
  templateUrl: './about-test.component.html',
  styleUrls: ['./about-test.component.scss'],
})
export class AboutTestComponent implements OnInit {
  @Input() form;
  @Input() buttonEvent: Subject<boolean>;
  @Input() set testType(val: any) {
    if (val) {
      this._testType = val;
    } else {
      if (this.role == 'pupil') {
        this._testType = ETestTypes.DEFAULT_STANDART.toString();
      }
      if (this.role == 'parent') {
        this._testType = ETestTypes.DEFAULT_360.toString();
      } else {
        this._testType = ETestTypes.DEFAULT_STANDART.toString();
      }
    }
    this.getTestInfo();
    if (this._testType === ETestTypes.DEFAULT_STANDART.toString() || this._testType === ETestTypes.DEFAULT_360.toString()) {
      this.checkTested();
    } else {
      this.pageIsReady = true;
    }
  }
  @Input() isBaseNavigation: boolean = false;
  get testType() {
    return this._testType;
  }
  _testType;

  @Output() languageSelected = new EventEmitter<any>();

  pageIsReady: boolean = false;
  role: string = '';
  testTypes: any = ETestTypes;
  public selectedLanguage: string =
    AppSettingsService.settings.regionLanguages && AppSettingsService.settings.regionLanguages.isAccessible
      ? AppSettingsService.settings.regionLanguages.defaultLanguage
      : null;
  public regionLanguages = {
    default: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.defaultLanguage : null,
    native: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.regionLanguage : null,
  };

  public testSteps;
  public testDuration;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private translateService: TranslateService, private router: Router, private sharedService: SharedService) {
    this.role = localStorage.getItem('userRole');
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.languageSelected.emit(this.selectedLanguage);
    if (this.selectedLanguage) {
      this.toggleLanguage(this.regionLanguages.default);
    }
  }

  checkTested() {
    //tested users rejected from test
    this.sharedService
      .getSessionList(localStorage.getItem('userId'))
      .pipe(
        tap(sessionList => {
          let talatsSessions = sessionList
            .filter(session => {
              return session.screeningTestId === TESTS_VARIANTS_IDS.passportOfTalents && session.completed;
            })
            .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));
          let lastSession = talatsSessions[0];

          let isLocalhost = location.origin.includes('localhost');
          if (lastSession && !isLocalhost) {
            if (this.role == 'pupil') {
              return this.router.navigate(['/recommendations']);
            } else if (this.role == 'parent') {
              return this.router.navigate(['/parent']);
            }
          }
        }),
      )
      .subscribe();
  }

  private getTestInfo() {
    switch (this.testType) {
      case ETestTypes.DEFAULT_STANDART.toString():
        this.testSteps = ETestTypesSteps.DEFAULT_STANDART;
        this.testDuration = 25;
        break;
      case ETestTypes.DEFAULT_360.toString():
        this.testSteps = ETestTypesSteps.DEFAULT_360;
        this.testDuration = 15;
        break;
      default:
        break;
    }
  }

  nextToTest() {
    this.buttonEvent.next(true);
  }

  public toggleLanguage(language: string) {
    if (language) {
      this.selectedLanguage = language;
      localStorage.setItem('testLang', language);
      this.languageSelected.emit(language);
    }
    return;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
