import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { SharedService } from 'app/shared/shared.service';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { ICity } from 'app/shared/interfaces/icity';
import { SchoolClassesClass } from 'app/shared/classes/school-classes.class';
import { ICheckActivationCode } from 'app/shared/interfaces/icheckactivationcode.interface';
import { LoginService } from '../../login-b2c/login.service';
import { RegistrationService } from '../registration.service';

export enum ERegForms {
  PHONE_INPUT = 1,
  CODE_INPUT,
}
@Component({
  selector: 'prf-code-registration-b2c',
  templateUrl: './code-registration-b2c.component.html',
  styleUrls: ['./code-registration-b2c.component.scss'],
})
export class CodeRegistrationB2cComponent implements OnInit {
  public registrationPupil: boolean;
  public registrationOther: boolean;
  public tglRegistration: boolean = false;
  public advertisingTerms: boolean = false;
  public registrationOpen: boolean = false;

  public isCodeQueryParam: boolean = false;
  public phoneMask = ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  public phoneMaskWithoutCode = ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];

  tag: string;
  public form: UntypedFormGroup;
  public submitted: boolean;
  public readonly dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public readonly emailRegExp: RegExp = REG_EXP.emailRegExp;
  public readonly dateRegExp: RegExp = REG_EXP.dateRegExp;
  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  public readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  public readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  public readonly phoneRegExp: RegExp = REG_EXP.fullPhoneRegExp;

  public isCharactersError: boolean = true;
  public isLetterError: boolean = true;
  public isNumberError: boolean = true;
  public isRusLettersError: boolean = false;
  public isWhiteSpaceError: boolean = false;
  public isNotValidPassword: boolean = false;
  public focusOutPasswordErrors: boolean = false;

  public addition: boolean = false;
  public regions = [];
  public cities: ICity[] = [];
  private _citiesView;
  public classes: SchoolClassesClass[] = [];
  public classesFetched: boolean = false;
  public schools: any[] = [];
  private _schoolView: any[] = [];
  public numbersClass: any = [];
  public lettersClass: any = [];

  public registrationFailed: boolean = false;
  public passFailed: boolean = false;
  public loginFailed: boolean = false;

  public moment: any = moment;
  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;
  public isMaskedPassword: boolean = true;
  public personalTerms: boolean = false;
  public duplicateUserName: boolean = false;
  public codeIsUsed: boolean = false;
  public checkBirthday: boolean = false;
  public isClasses: boolean = false;

  public isNotEnoughYears: boolean = false; // признак для определения разрешенного возраста
  private minAge: number = 14; // минимальный разрешенный возраст для регистрации ученика
  public SCHOOL_LETTERS: { value: string }[] = [];
  public defaultRegion: string = null;

  public codeFailed: boolean = false;
  public phoneLabel = false;
  public codeLabel = false;
  public emailLabel = false;
  public phoneChecked: boolean = false;
  public checkEmail: boolean = true;
  timer$ = new Subject<number>();
  activeTimer: boolean = false;
  smsAllowed: boolean = true;
  supportAlert: boolean = false;
  incorrectLoginData: boolean = false;
  public queryParams: boolean = false;

  public regFormVariant: ERegForms = ERegForms.PHONE_INPUT;
  public regForms = ERegForms;
  public translations: string[] = [];

  public role = 'pupil';

  public isProcessing: boolean = false;

  private routeParams: Params;

  private ngUnsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private sharedService: SharedService,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Регистрация по коду' });
    this.moment = moment;
    this.moment.locale('ru');
    this.getTranslations();

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        switch (event.url) {
          case '/registration-pupil': {
            this.personalTerms = true;
            return (this.registrationPupil = true);
          }
          case '/registration-other': {
            return (this.registrationOther = true);
          }
          default: {
            this.personalTerms = true;
            return (this.registrationPupil = true);
          }
        }
      }
    });
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      phone: new UntypedFormControl(null, [Validators.pattern(REG_EXP.phoneRegExp)]),
      password: new UntypedFormControl(null),
    });

    this.activatedRoute.queryParams
      .pipe(
        switchMap((params: Params) => {
          this.routeParams = params;
          if (this.routeParams?.Code) {
            return this.registrationService.checkActivationCode(this.routeParams?.Code);
          } else return of(null);
        }),
        tap((codeStatus: ICheckActivationCode) => {
          let phoneWithoutCode: string = this.routeParams.Phone.length === 11 ? this.routeParams.Phone.slice(1) : this.routeParams.Phone;
          phoneWithoutCode = '+7' + phoneWithoutCode;

          if (!codeStatus?.isActive) {
            this.router.navigate(['/']);
          } else if (this.routeParams?.Code && this.routeParams?.Phone) {
            this.queryParams = true;

            this.f.phone.setValue(phoneWithoutCode);
            this.f.phone.disable();
            this.f.phone.updateValueAndValidity();
            this.phoneLabel = true;
            this.f.password.setValue(this.routeParams.Code);
            this.f.password.disable();
            this.f.password.updateValueAndValidity();
            this.codeLabel = true;
          } else if (this.routeParams?.Phone) {
            this.f.phone.setValue(phoneWithoutCode);
            this.f.phone.disable();
            this.f.phone.updateValueAndValidity();
            this.codeLabel = true;
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  public get phoneValue(): string {
    return this.f.phone.value.replace(/\D/g, '');
  }

  submit() {
    this.isProcessing = true;
    switch (this.regFormVariant) {
      case ERegForms.PHONE_INPUT:
        if (this.regFormVariant === 1) {
          this.regFormVariant = ERegForms.CODE_INPUT;
        }
        this.isProcessing = false;
        break;
      case ERegForms.CODE_INPUT:
        this.registrationByPhone();
        break;
    }
  }

  registrationByPhone() {
    let phone = this.phoneValue;
    let password = this.f.password.value;

    this.registrationService
      .registration(phone, password)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap(response => {
          if (response.status != 'Success') {
            this.codeFailed = true;
            if (response.status === 'Cannot find registration code' || response.status === 'Сode has already been used') {
              this.router.navigate(['/'], { state: { userPhone: phone } });
            }
            if (response.status === 'Sms code is outdated') {
              this.f.password.setErrors({ isOutdated: true });
            }
            if (response.comment === 'User already exists') {
              this.regFormVariant = ERegForms.PHONE_INPUT;
              this.f.password.setValue(null);
              this.f.password.updateValueAndValidity();
              this.cd.detectChanges();
              this.f.phone.setErrors({ isUsed: true });
            }
            return of(null);
          } else {
            // Сразу попадаем в ЛК
            return this.loginService.login(this.phoneValue, this.f.password.value, false).pipe(
              switchMap((loginResult: any) => {
                if (!loginResult || (loginResult && loginResult.succeeded === false)) {
                  this.loginFailed = true;
                  this.passFailed = true;
                  return of(null);
                } else {
                  localStorage.setItem('userRole', loginResult.role);
                  localStorage.setItem('userId', loginResult.userId);
                  localStorage.setItem('tag', loginResult.tag);
                  localStorage.setItem('isAuthorized', 'true');

                  return this.sharedService.getUserInfoData().pipe(
                    tap((ui: UserInfoClass) => {
                      if (ui) {
                        this.setUserInfoInLS(ui);
                      }

                      switch (loginResult.role) {
                        case 'admin': {
                          // Пока нет пользователя с ролью админ, переходим просто /admin
                          this.router.navigate(['/admin/schools']);
                          return;
                        }
                        case 'schooladmin': {
                          this.router.navigate(['/schooladmin/classes']);
                          return;
                        }
                        case 'pupil': {
                          this.router.navigate(['/pupil'], { state: { needOnboarding: true } });
                          return;
                        }
                      }
                    }),
                  );
                }
              }),
            );
          }
        }),
      )
      .subscribe(() => (this.isProcessing = false));
  }

  setUserInfoInLS(ui) {
    if (!ui) {
      throw 'User info is not defined';
    }
    localStorage.setItem('imagePath', ui.imagePath);
    localStorage.setItem('firstName', ui.firstName);
    localStorage.setItem('lastName', ui.lastName);
    localStorage.setItem('schoolId', ui.schoolId);
    localStorage.setItem('userGender', ui.gender);
    localStorage.setItem('companyId', ui.companyId);
    localStorage.setItem('position', ui.position);
    localStorage.setItem('regionId', ui.regionId);
    localStorage.setItem('municipalityId', ui.municipalityId);
    ui.parents[0]?.userId ? localStorage.setItem('parentUserId', ui.parents[0]?.userId) : null;
  }

  public toggleRegistration() {
    this.tglRegistration = !this.tglRegistration;
    this.registrationOpen = !this.registrationOpen;
  }

  get isAccessAllowed() {
    return this.form.value && this.personalTerms;
  }
  get accessFormRole(): boolean {
    if (this.registrationOther) {
      return true;
    } else {
      return this.registrationPupil && this.tglRegistration;
    }
  }

  public getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }

  get f() {
    return this.form.controls;
  }

  clearInput(control) {
    if (control) {
      this.form.get(control).setValue(null);
      this.form.get(control).updateValueAndValidity();
    }
  }

  submitAllowed(): boolean {
    switch (this.regFormVariant) {
      case ERegForms.PHONE_INPUT:
        return this.submitPhoneAllowed;
      case ERegForms.CODE_INPUT:
        return this.submitCodeAllowed;
      default:
        return false;
    }
  }
  get submitPhoneAllowed() {
    return (this.f.phone.value && this.f.phone.valid) || this.queryParams;
  }
  get submitCodeAllowed() {
    return (this.f.password.value && this.f.password.valid) || this.queryParams;
  }

  checkPhone() {
    this.phoneChecked = false;
    let a = this.phoneRegExp.test(this.form.get('phone').value?.replace(/\D/g, ''));
    if (a) {
      this.form.get('phone').setErrors(null);
    }
  }

  public toggleMask() {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  get loginTitleText(): string {
    switch (this.regFormVariant) {
      case ERegForms.PHONE_INPUT:
        return 'REGISTRATION.HELLO_PROFILUM';
      case ERegForms.CODE_INPUT:
        return 'REGISTRATION.GET_PASSWORD_BY_SMS';
      default:
        return 'REGISTRATION.HELLO_PROFILUM';
    }
  }
  get loginFormText() {
    switch (this.regFormVariant) {
      case ERegForms.PHONE_INPUT:
        return 'REGISTRATION.CONFIRM_YOUR_NUMBER';
      case ERegForms.CODE_INPUT:
        return '';
      default:
        return '';
    }
  }

  get promptText() {
    switch (this.regFormVariant) {
      case ERegForms.PHONE_INPUT:
        return 'LOGIN.ENTER_BY_EMAIL';
      case ERegForms.CODE_INPUT:
        return 'LOGIN.SEND_TWICE';
      default:
        return '';
    }
  }

  getTranslations() {
    this.translateService
      .get([
        'REGISTRATION.HELLO_PROFILUM',
        'REGISTRATION.CONFIRM_YOUR_NUMBER',
        'REGISTRATION.PHONE',
        'REGISTRATION.SUBMIT',
        'REGISTRATION.GET_PASSWORD_BY_SMS',
        'REGISTRATION.EVERY_TIME_NEW',
        'REGISTRATION.PASSWORD',
      ])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.translations = translations;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
