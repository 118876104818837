import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { IExtendedPupil } from 'app/shared/interfaces/iextendedpupil.interface';
import { ISchoolClass } from 'app/shared/interfaces/ischoolclass.interface';
import { BehaviorSubject, Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ICompletedTestsCount } from '../../../shared/interfaces/icompletedtestscount.interface';
import { ISchoolClassExtended } from '../../../shared/interfaces/ischoolclassextended.interface';
import { IUpdateRegistrationCode } from '../../../shared/interfaces/iupdateregistrationcode.interface';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import { IClass } from 'app/shared/interfaces/iclass.interface';
import { IAddUserInterface } from '../../../shared/interfaces/iregistration.interface';

@Injectable()
export class SchoolAdminPanelService {
  public callRequest$ = new BehaviorSubject<boolean>(false);
  private editPupils = new BehaviorSubject({ editPupils: false });
  private showPupil = new BehaviorSubject({ showPupilPage: false });

  public responseCache = new Map();

  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  needCall(call: boolean) {
    this.callRequest$.next(call);
  }

  getSchool(): Observable<ISchool> {
    return this.http.get('/b2c/v1.0/schooladmins/getschool').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        console.error(`%c Error: ${err.status}`, 'color: #8A2BE2;');
        return this.handleError(err);
      }),
    );
  }

  addNewSchoolClass(schoolClassData: ISchoolClass): Observable<any> {
    return this.http.post('/b2c/v1.0/schooladmins/addschoolclass', schoolClassData).pipe(
      map(r => r),
      catchError((e: HttpErrorResponse) => {
        const ALREADY_EXIST = 'already exists';
        let include = e.error.Error.Message.includes(ALREADY_EXIST);
        if (include) {
          return of('ALREADY_EXIST');
        } else {
          return throwError(e);
        }
      }),
    );
  }

  removeSchoolClass(schoolClassId: any): Observable<any> {
    return this.http.delete('/b2c/v1.0/schooladmins/deleteschoolclass?schoolClassId=' + schoolClassId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  addTeacher(teacherData: IAddUserInterface): Observable<any> {
    return this.http.post('/b2c/v1.0/saas/adduser', teacherData).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getSaasTestingSessions(schoolClassId: any): Observable<any> {
    return this.http.get('/b2c/v1.0/schooladmins/getlastsessionsbyclass?schoolClassId=' + schoolClassId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  uploadImage(image: File, id: string): Observable<any> {
    let formData: FormData = new FormData();
    formData.append(image[0].name, image[0]);
    return this.http.postImage('/b2c/v1.0/utils/upload/' + id, formData).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateSchoolClass(schoolClass): Observable<any> {
    return this.http.put('/b2c/v1.0/schooladmins/updateschoolclass', schoolClass).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateRegistrationCode(regCode: IUpdateRegistrationCode): Observable<any> {
    return this.http.post('/b2c/v1.0/schooladmins/updateregistrationcode', regCode).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  createPupil(dataUser: IAddUserInterface): Observable<any> {
    return this.http.post('/b2c/v1.0/saas/adduser', dataUser).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  removePupil(userId: any): Observable<any> {
    return this.http.delete('/b2c/v1.0/schooladmins/removefromschoolclass?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  removeTeacher(userId): Observable<any> {
    return this.http.delete('/b2c/v1.0/schooladmins/deleteteacher?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  removeUnregisteredPupil(userId): Observable<any> {
    return this.http.delete('/b2c/v1.0/schooladmins/deleteregistrationcode?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  resetPasswordToCode(email): Observable<any> {
    return this.http.get('/b2c/v1.0/schooladmins/resetpassword?email=' + email).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  createClassDO(classesDto: IClass): Observable<any> {
    return this.http.post('/b2c/v1.0/schooladmins/createclass', classesDto).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  replaceClassDO(classesDto: IClass): Observable<any> {
    return this.http.put('/b2c/v1.0/schooladmins/replaceclass', classesDto).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  deleteClassDO(classID: any): Observable<any> {
    return this.http.delete('/b2c/v1.0/schooladmins/deleteclass?classId=' + classID).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  showAddPupils() {
    this.editPupils.next({ editPupils: true });
  }

  closeAddPupils() {
    this.editPupils.next({ editPupils: false });
  }

  getEditPupils(): Observable<any> {
    return this.editPupils.asObservable();
  }

  showPupilPage() {
    this.showPupil.next({ showPupilPage: true });
  }

  closePupilPage() {
    this.showPupil.next({ showPupilPage: false });
  }

  getPupilPage(): Observable<any> {
    return this.showPupil.asObservable();
  }
}
