import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { AdminPanelService } from '../../../admin-panel.service';
import { RegionsService } from '../../../regions/regions.service';
import { MunicipalitiesService } from '../../../municipalities/municipalities.service';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';

@Component({
  selector: 'prf-edit-profession',
  templateUrl: './edit-profession.component.html',
  styleUrls: ['./edit-profession.component.scss'],
})
export class EditProfessionComponent implements OnInit {
  @Input() profession: IProfession;
  @Input() courses: any[];
  @Input() profilClasses: any[];
  @Input() fields: any[];
  @Input() isCopy: boolean;
  @Output() updateValue = new EventEmitter<any>();
  error: boolean = false;
  educationList = [];
  demandRatings = [];
  booleanVariants = [];
  selectedOptions = [];

  addedEducation = {
    level: 1,
    isPreferred: false,
    perentage: 0,
  };

  addedPerson = {
    imagePath: '',
    name: '',
    description: '',
  };

  _selectedFile: File;

  // _personImagePath;

  professionFieldName: string;

  public allRegions: IRegion[] = [];
  public currentRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };

  public municipalitiesByRegion: IMunicipality[] = [];
  public currentMunicipality: IMunicipality = {
    id: '',
    hrid: '',
    name: '',
    regionId: '',
  };

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private adminPanelService: AdminPanelService,
    private router: Router,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
  ) {
    this.getTranslations([
      'SHARED.EDU.SECONDARY',
      'SHARED.EDU.VOCATIONAL',
      'SHARED.EDU.HIGHER',
      'SHARED.EDUCATION.LOW',
      'SHARED.EDUCATION.MEDIUM',
      'SHARED.EDUCATION.HIGH',
      'BUTTONS.YES',
      'BUTTONS.NO',
    ])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.educationList = [
          { level: 1, viewValue: translations['SHARED.EDU.SECONDARY'] },
          { level: 2, viewValue: translations['SHARED.EDU.VOCATIONAL'] },
          { level: 3, viewValue: translations['SHARED.EDU.HIGHER'] },
        ];
        this.demandRatings = [
          { level: 1, viewValue: translations['SHARED.EDUCATION.LOW'] },
          { level: 2, viewValue: translations['SHARED.EDUCATION.MEDIUM'] },
          { level: 3, viewValue: translations['SHARED.EDUCATION.HIGH'] },
        ];
        this.booleanVariants = [
          { value: true, viewValue: translations['BUTTONS.YES'] },
          { value: false, viewValue: translations['BUTTONS.NO'] },
        ];
      });
  }

  ngOnInit() {
    if (this.isCopy) {
      this.resetFieldsForCopy();
    }
    this.getRegions();

    if (this.profession.demandRating == 0) {
      this.profession.demandRating = null;
    }
  }

  resetFieldsForCopy() {
    this.profession.id = this.utilsService.newGuid();
    this.profession.hrid = null;
    this.profession.regionId = null;
    this.currentRegion = null;
    this.profession.municipalityId = '00000000-0000-0000-0000-000000000000';
  }

  getRegions() {
    this.regionsService
      .getAllRegions()
      .pipe(
        switchMap(r => {
          this.allRegions = r;
          let currentObs$: Observable<any>;
          if (this.profession.regionId && this.profession.regionId != '00000000-0000-0000-0000-000000000000') {
            this.currentRegion = this.allRegions.find(x => x.id == this.profession.regionId);
            currentObs$ = this.municipalitiesService.getMunicipalitiesByRegion(this.currentRegion.id).pipe(
              tap(municipalities => {
                this.municipalitiesByRegion = municipalities;
                this.currentMunicipality = this.municipalitiesByRegion.find(
                  x => x.regionId == this.profession.regionId && x.id == this.profession.municipalityId,
                );
              }),
            );
          } else {
            currentObs$ = of(null);
          }
          return currentObs$;
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  selectRegion(region) {
    this.setRegion(region).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setRegion(region): Observable<any> {
    this.currentRegion = region;
    this.profession.regionId = region.id;
    this.clearCurrentMunicipality();
    return this.municipalitiesService.getMunicipalitiesByRegion(region.id).pipe(
      tap(r => {
        this.municipalitiesByRegion = r;
      }),
    );
  }

  setMunicipality(municipality) {
    if (municipality) {
      this.currentMunicipality = municipality;
      this.profession.municipalityId = municipality.id;
    }
    return;
  }

  resetMunicipality() {
    this.profession.municipalityId = '00000000-0000-0000-0000-000000000000';
  }

  clearCurrentMunicipality() {
    this.currentMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    return;
  }

  clearTerritoryData() {
    this.currentRegion = {
      id: '',
      hrid: '',
      name: '',
    };
    this.municipalitiesByRegion = [];
    this.currentMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    return;
  }

  // async isProfessionHridBusy(hrid: string) {
  //   let profession = await this.adminPanelService.getProfessionByHrid(hrid);
  //   if (profession) {
  //     return true;
  //   }
  //   return false;
  // }

  /* Update profession */
  onSubmit(form: NgForm) {
    if (form.status == 'VALID') {
      if (this.professionFieldName !== undefined) {
        this.profession.fieldNames = [this.professionFieldName];
        this.profession.fieldIds = this.fields.filter(el => el.name === this.professionFieldName).map(el => el.id);
      } else {
        this.profession.fieldIds = this.fields.filter(el => this.profession.fieldNames.includes(el.name)).map(el => el.id);
      }

      if (this.profession.courses && this.profession.courses.length) {
        this.profession.coursesIds = this.courses.filter(el => this.profession.courses.includes(el.name)).map(el => el.id);
      }

      if (this.isCopy) {
        this.adminPanelService
          .getProfessionByHrid(this.profession.hrid)
          .pipe(
            switchMap(profession => {
              let currentObs$: Observable<any>;
              if (profession) {
                this.utilsService.openSnackBar('👎 Профессия с таким Hrid уже существует', 'error');
                currentObs$ = of(null);
              } else {
                currentObs$ = this.adminPanelService.createProfessions(this.profession).pipe(
                  tap(r => {
                    if (r && r.status === 'Success') {
                      this.utilsService.openSnackBar('👌 Профессия добавлена', 'success');
                      setTimeout(_ => {
                        this.router.navigate(['/admin/professions']);
                      }, 2000);
                    } else {
                      this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте снова', 'error');
                      setTimeout(_ => {
                        this.router.navigate(['/admin/professions']);
                      }, 1000);
                    }
                  }),
                );
              }
              return currentObs$;
            }),
          )
          .subscribe();
      } else {
        this.adminPanelService
          .changeProfessions(this.profession)
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(r => {
            if (r) {
              this.utilsService.openSnackBar('👌 Профессия обновлена', 'success');
              form.reset();
              this.clearTerritoryData();
              return this.updateValue.emit(true);
            }
          });
      }
    } else {
      this.getTranslations(['SHARED.ERROR_MSG'])
        .pipe(take(1))
        .subscribe(translations => this.utilsService.openSnackBar(translations['SHARED.ERROR_MSG'], 'error'));
    }
  }

  // установка imagePath для профессии по сфере
  onModelChangeFieldNames(name) {
    let imagePath = null;
    if (name) {
      imagePath = this.fields.find(field => field.name === name).icon || null;
    }
    this.profession.mainImagePath = imagePath;
  }

  addMovie(movie: string) {
    this.utilsService.addStringValueToCollection(this.profession.movies, movie);
  }

  addSkill(skill: string) {
    this.utilsService.addStringValueToCollection(this.profession.skills, skill);
  }

  addResponsibility(responsibility: string) {
    this.utilsService.addStringValueToCollection(this.profession.resposibilities, responsibility);
  }

  addProductGroup(productGroup: string) {
    this.utilsService.addStringValueToCollection(this.profession.productGroups, productGroup);
  }

  addKnowledge(knowledge: string) {
    this.utilsService.addStringValueToCollection(this.profession.knowledge, knowledge);
  }

  addAbility(ability: string) {
    this.utilsService.addStringValueToCollection(this.profession.abilities, ability);
  }

  addFact(fact: string) {
    this.utilsService.addStringValueToCollection(this.profession.facts, fact);
  }

  addAdditionalEducation(additionalEducation: string) {
    this.utilsService.addStringValueToCollection(this.profession.additionalEducations, additionalEducation);
  }

  addEducation() {
    if (!this.profession.education) {
      this.profession.education = [
        {
          level: this.addedEducation.level,
          isPreferred: this.addedEducation.isPreferred,
          perentage: this.addedEducation.perentage,
        },
      ];
    } else {
      let indexEducation: number = this.profession.education.findIndex(d => d.level === this.addedEducation.level);
      if (indexEducation === -1) {
        let education = {
          level: this.addedEducation.level,
          isPreferred: this.addedEducation.isPreferred,
          perentage: this.addedEducation.perentage,
        };
        this.profession.education.push(education);
      }
    }
  }

  addVideo(videoName: string, videoUrl: string) {
    if (videoName && videoUrl) {
      if (!this.profession.videos) {
        this.profession.videos = [
          {
            name: videoName,
            url: videoUrl,
          },
        ];
      } else {
        let indexVideo: number = this.profession.videos.findIndex(d => d.name === videoName);
        if (indexVideo === -1) {
          let video = {
            name: videoName,
            url: videoUrl,
          };
          this.profession.videos.push(video);
        }
      }
    }
  }

  addCompany(companyName: string, companyDescription: string, companyUrl: string, companyImageId: string) {
    if (companyName && companyDescription) {
      if (!this.profession.companies) {
        this.profession.companies = [
          {
            name: companyName,
            description: companyDescription,
            url: companyUrl,
            imageId: companyImageId,
          },
        ];
      } else {
        let indexCompany: number = this.profession.companies.findIndex(d => d.name === companyName);
        if (indexCompany === -1) {
          let company = {
            name: companyName,
            description: companyDescription,
            url: companyUrl,
            imageId: companyImageId,
          };
          this.profession.companies.push(company);
        }
      }
    }
  }

  addArticle(articleName: string, articleUrl: string) {
    if (articleName && articleUrl) {
      if (!this.profession.articles) {
        this.profession.articles = [
          {
            name: articleName,
            url: articleUrl,
          },
        ];
      } else {
        let indexArticle: number = this.profession.articles.findIndex(d => d.name === articleName);
        if (indexArticle === -1) {
          let article = {
            name: articleName,
            url: articleUrl,
          };
          this.profession.articles.push(article);
        }
      }
    }
  }

  addPerson() {
    if (this.addedPerson.name && this.addedPerson.description && this.addedPerson.imagePath) {
      if (!this.profession.persons) {
        this.profession.persons = [
          {
            name: this.addedPerson.name,
            description: this.addedPerson.description,
            imagePath: this.addedPerson.imagePath,
          },
        ];
      } else {
        let indexPerson: number = this.profession.persons.findIndex(d => d.name === this.addedPerson.name);
        if (indexPerson === -1) {
          let person = {
            name: this.addedPerson.name,
            description: this.addedPerson.description,
            imagePath: this.addedPerson.imagePath,
          };
          this.profession.persons.push(person);
          this.addedPerson.imagePath = '';
          this.addedPerson.description = '';
          this.addedPerson.name = '';
        }
      }
    } else if (!this.addedPerson.imagePath) {
      this.utilsService.openSnackBar('👎 Фото персоны не загружено', 'error');
    } else if (!this.addedPerson.name || !this.addedPerson.description) {
      this.utilsService.openSnackBar('👎 Необходимо заполнить все поля о персоне', 'error');
    }
  }

  addSpecial(specialName: string, specialEducationLevel: string) {
    if (specialName && specialEducationLevel) {
      if (!this.profession.specials) {
        this.profession.specials = [
          {
            name: specialName,
            education: specialEducationLevel,
          },
        ];
      } else {
        let indexSpecials: number = this.profession.specials.findIndex(
          d => d.name === specialName && d.education === specialEducationLevel,
        );
        if (indexSpecials === -1) {
          let specials = {
            name: specialName,
            education: specialEducationLevel,
          };
          this.profession.specials.push(specials);
        }
      }
    }
  }

  removeMovies() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.movies, item);
    });
    this.selectedOptions = [];
  }

  removeSkills() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.skills, item);
    });
    this.selectedOptions = [];
  }

  removeResponsibilities() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.resposibilities, item);
    });
    this.selectedOptions = [];
  }

  removeProductGroups() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.productGroups, item);
    });
    this.selectedOptions = [];
  }

  removeKnowledges() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.knowledge, item);
    });
    this.selectedOptions = [];
  }

  removeAbilities() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.abilities, item);
    });
    this.selectedOptions = [];
  }

  removeAdditionalEducations() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.additionalEducations, item);
    });
    this.selectedOptions = [];
  }

  removeFacts() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromStringArray(this.profession.facts, item);
    });
    this.selectedOptions = [];
  }

  removeArticles() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.articles, item);
    });
    this.selectedOptions = [];
  }

  removeVideos() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.videos, item);
    });
    this.selectedOptions = [];
  }

  removeCompanies() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.companies, item);
    });
    this.selectedOptions = [];
  }

  removePersons() {
    this.selectedOptions.forEach(item => {
      this.utilsService.deleteItemFromCollectionByName(this.profession.persons, item);
    });
    this.selectedOptions = [];
  }

  removeEducations() {
    this.selectedOptions.forEach(item => {
      let indexEducation: number = this.profession.education.findIndex(d => d.level === item.level);
      if (indexEducation > -1) {
        this.profession.education.splice(indexEducation, 1);
      }
    });
    this.selectedOptions = [];
  }

  removeSpecials() {
    this.selectedOptions.forEach(item => {
      let indexSpecials: number = this.profession.specials.findIndex(d => d.name === item.name && d.education === item.education);
      if (indexSpecials > -1) {
        this.profession.specials.splice(indexSpecials, 1);
      }
    });
    this.selectedOptions = [];
  }

  selectFile(event) {
    if (event.target.files.item(0).type.match('image.*')) {
      this._selectedFile = event.target.files;
    } else {
      alert('invalid format!');
    }
  }

  upload() {
    if (!this._selectedFile) {
      this.utilsService.openSnackBar('👎 Сначала выберите файл', 'error');
      return;
    }

    this.adminPanelService
      .uploadImage(this._selectedFile, this.profession.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.addedPerson.imagePath = r;
      });
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
