import { Injectable } from '@angular/core';

@Injectable()
export class CheckConnectionService {
  constructor() {}

  checkConnect(): boolean {
    return false;
  }
}
