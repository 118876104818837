<div class="prof-municipality" style="position: absolute" *ngIf="isProfessionChecked">
  <img
    src="./assets/images/fire-profession.svg"
    class="prof-municipality-image"
    (mouseleave)="popup.style.display = 'none'"
    (mouseover)="popup.style.display = 'block'"
  />
  <div class="info-popup recommendation w-hidden-small w-hidden-tiny" #popup style="display: none">
    <p>
      {{ 'SHARED.PROFESSION_NARRATIVE' | translate }}
    </p>
    <img src="./assets/images/info-triangle.png" width="12" class="info-triangle" />
  </div>
</div>
<a (click)="professionClick(profession)" class="professions-card w-inline-block">
  <div class="professions-image"><img src="{{ profession.mainImagePath }}" alt="" /></div>
  <div class="prof-column">
    <div class="professions-card-vertical-column">
      <h3 class="professions-h3">{{ profession.name }}</h3>
      <p class="professions-description">{{ transformedDescription }}</p>
    </div>
    <div class="professions-wage" *ngIf="isSalaryShown">
      {{ profession.minSalary | number }}–{{ profession.maxSalary | number }}
      <span class="rub">{{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</span>
    </div>
    <ng-container *ngIf="userRole === 'pupil'">
      <div *ngIf="isGoal" (click)="removeFavoriteClick(profession)" class="add-goal-button delete-goal">
        <strong>Убрать</strong>
      </div>
      <div *ngIf="!isGoal" (click)="addFavoriteClick(profession)" class="add-goal-button">
        <strong>{{ 'SHARED.ADD' | translate }}</strong>
      </div>
    </ng-container>
  </div>
</a>
