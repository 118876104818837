import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'prf-values-short-results',
  templateUrl: './values-short-results.component.html',
  styleUrls: ['./values-short-results.component.scss'],
})
export class ValuesShortResultsComponent implements OnInit {
  @Input() userRole: string = 'pupil';

  @Input() set sessionId(val: string) {
    if (val) {
      this.resultsFetched = false;
      // some requests
      this._sessionId = val;
    } else {
      this.resultsFetched = true;
    }
  }
  _sessionId;
  get sessionId() {
    return this._sessionId;
  }
  public resultsFetched: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
