<prf-banner *ngIf="showBanner"></prf-banner>

<div class="test-progress-block" *ngIf="stepNumber !== 4 && loaded">
  <div class="test-progress-row">
    <div class="test-progress-col">
      <div [class.active]="stepNumber === 1" [class.done]="stepNumber > 1" class="test-progress-stage-num">1</div>
      <div [class.active]="stepNumber === 1" class="test-progress-stage-title">
        {{ 'PROFESSIONS.LAUNCH_TEST' | translate }}
      </div>
      <img [class.active]="stepNumber === 1" src="/assets/images/icons/Rectangle-2.png" width="19" alt="" class="test-progress-triangle" />
    </div>

    <div class="test-progress-line" [class.done]="stepNumber > 1"></div>

    <div class="test-progress-col">
      <div [class.active]="stepNumber === 2" [class.done]="stepNumber > 2" class="test-progress-stage-num">2</div>
      <div [class.active]="stepNumber === 2" class="test-progress-stage-title">Узнать о своих навыках</div>
      <img [class.active]="stepNumber === 2" src="/assets/images/icons/Rectangle-2.png" width="19" alt="" class="test-progress-triangle" />
    </div>

    <div class="test-progress-line" [class.done]="stepNumber > 2"></div>

    <div class="test-progress-col">
      <div [class.active]="stepNumber === 3" [class.done]="stepNumber > 3" class="test-progress-stage-num">3</div>
      <div [class.active]="stepNumber === 3" class="test-progress-stage-title">
        {{ 'SHARED.CHOOSE_PROFESSION' | translate }}
      </div>
      <img [class.active]="stepNumber === 3" src="/assets/images/icons/Rectangle-2.png" width="19" alt="" class="test-progress-triangle" />
    </div>

    <div class="test-progress-line" [class.done]="stepNumber > 3"></div>

    <div class="test-progress-col">
      <div [class.active]="stepNumber === 4" [class.done]="stepNumber > 4" class="test-progress-stage-num">4</div>
      <div [class.active]="stepNumber === 4" class="test-progress-stage-title">
        {{ 'SHARED.EXPLORE_MORE' | translate }}
      </div>
      <img [class.active]="stepNumber === 4" src="/assets/images/icons/Rectangle-2.png" width="19" alt="" class="test-progress-triangle" />
    </div>
  </div>

  <div *ngIf="stepNumber === 1" class="test-progress-white-block">
    <div class="welcome-row-3">
      <div class="welcome-column-stud-2">
        <h3 class="welcome-h3-stud-2">
          <span class="welcome-stage">Шаг 1:</span> Пройти тест, который поможет понять твои интересы и способности.
        </h3>

        <div *ngIf="uncompletedTest" class="progress-block-2">
          <div class="progress-text">{{ currentSlide }} из {{ countSlide }}</div>
          <div class="progress-bar">
            <div class="progress-bar-color" [ngStyle]="{ width: ((currentSlide - 1) * 100) / (countSlide - 1) + '%' }"></div>
          </div>
        </div>

        <div *ngIf="!uncompletedTest" class="main-screen-buttons-row">
          <a (click)="navigateStartTest()" class="button welcome-button-stud-2 w-button">{{ 'SHARED.START_TEST' | translate }}</a>
        </div>

        <div *ngIf="uncompletedTest" class="main-screen-buttons-row">
          <a (click)="navigateContinueTest()" class="button welcome-button-stud-2 testing w-button">Продолжить тест</a>
          <a (click)="navigateStartTest()" class="button welcome-button-stud-2 white-button w-button">Начать тест заново</a>
        </div>
      </div>

      <img src="/assets/images/dashboard/welc-1.svg" alt="" class="welcome-image-stud" />
    </div>
  </div>

  <div *ngIf="stepNumber === 2" class="test-progress-white-block stage-2">
    <div class="welcome-row-3">
      <div class="welcome-column-stud-2">
        <h3 class="welcome-h3-stud-2"><span class="welcome-stage">Шаг 2:</span> Ознакомиться с твоими способностями</h3>
        <div class="main-screen-buttons-row">
          <a (click)="navigateResults()" class="button welcome-button-stud-2 st2 w-button">Ознакомиться с навыками</a>
        </div>
      </div>
      <img src="/assets/images/dashboard/welc-2.svg" alt="" class="welcome-image-stud" />
    </div>
  </div>

  <div *ngIf="stepNumber === 3" class="test-progress-white-block stage-3">
    <div class="welcome-row-3">
      <div class="welcome-column-stud-2 st3">
        <h3 class="welcome-h3-stud-2"><span class="welcome-stage">Шаг 3:</span> Выбрать профессию и посмотреть куда развиваться.</h3>
        <div class="main-screen-buttons-row">
          <a (click)="navigateProfessions()" class="button welcome-button-stud-2 st2 w-button">{{
            'SHARED.CHOOSE_PROFESSION' | translate
          }}</a>
        </div>
      </div>
      <img src="/assets/images/dashboard/welc-3.svg" alt="" class="welcome-image-stud" />
    </div>
  </div>

  <div *ngIf="stepNumber === 40" class="test-progress-white-block stage-4">
    <!--Пока отключено-->
    <div class="welcome-row-3">
      <div class="welcome-column-stud-2 st4">
        <h3 class="welcome-h3-stud-2"><span class="welcome-stage">Шаг 4:</span> Выполни любое задание в твоей профессии.</h3>
        <div class="main-screen-buttons-row">
          <a class="button welcome-button-stud-2 st2 w-button">Перейти к заданиям</a>
        </div>
      </div>
      <img src="/assets/images/dashboard/welc-4.svg" alt="" class="welcome-image-stud" />
    </div>
  </div>

  <div *ngIf="stepNumber === 4" class="test-progress-white-block stage-end">
    <!-- Пока отключено -->
    <div class="end-stage-container">
      <div class="test-stage-heading-div">
        <h3 class="welcome-h3-stud-2 stage-end">
          <span class="welcome-stage end">Поздравляем, цель достигнута!</span>
        </h3>
      </div>
      <p class="end-stage-text">Теперь ты готов заниматься самостоятельно. Продолжай свой путь и у тебя все будет хорошо.</p>

      <a (click)="hideThisBlock()" class="button welcome-button-stud-2 st2 w-button">Продолжить заниматься</a>
    </div>
  </div>
</div>
