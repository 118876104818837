import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResultsService } from 'app/pages/results/results.service';
import { SharedService } from 'app/shared/shared.service';
import { ClassesFormatTypes } from 'app/shared/enums/courses-types.enum';
import { Stands } from 'app/shared/enums/stands.enums';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Observable, Subject } from 'rxjs';
import { mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MenuService } from '../menus/menu/menu.service';
import {
  FiltersPromotestCoursesService,
  IDateFilterFormat,
  IPriceFilterFormat,
  PricesFilterTypes,
} from './filters-courses/filters-promotest-courses.service';

@Component({
  selector: 'prf-all-events-promotest',
  templateUrl: './all-events-promotest.component.html',
  styleUrls: ['./all-events-promotest.component.scss'],
})
export class AllEventsPromotestComponent implements OnInit {
  userRole: string;

  resultsSSid: string;
  resultsRoutingUrl: string;
  recommendedTalents: any[] = [];

  testRecommends: any[] = [];
  public allPartnersCourses: Array<any> = [];
  public allPartnersCoursesView: Array<any> = [];

  dataLoaded: boolean = false;
  isVGuser: boolean = false;

  filters: IFilterClasses = {};

  isMobile: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private overlayService: OverlayBusyService,
    private filtersService: FiltersPromotestCoursesService,
    private sharedService: SharedService,
    private resultsService: ResultsService,
    private menuService: MenuService,
  ) {
    this.userRole = localStorage.getItem('userRole');
    this.isVGuser = localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString();
    this.menuService.isMobileScreen$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit() {
    this.overlayService.show();
    this.checkPromotestResults();
    this.filtersService
      .getCoursesFilters()
      .pipe(
        mergeMap(filters => {
          this.filters = filters;
          //check and reject null/undefined values
          let filtersVerified: IFilterClasses = {};
          Object.keys(filters).forEach(key => {
            filters[key] != null && filters[key] != undefined ? (filtersVerified[key] = filters[key]) : null;
          });
          return this.processLoadCourses(filtersVerified);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(_ => {
        this.dataLoaded = true;
        this.overlayService.hide();
      });
  }

  processLoadCourses(filtersVerified): Observable<any> {
    return this.loadCourses(filtersVerified).pipe(
      mergeMap(courses => {
        //по умолчанию сортировка по возрастанию цены
        this.allPartnersCourses = this.createRecommendedCoursesArray(courses, this.recommendedTalents).sort((a, b) =>
          this.calculateRealPrice(a) > this.calculateRealPrice(b) ? 1 : -1,
        );

        return this.filtersService.getPriceFilter().pipe(
          mergeMap((priceFilter: IPriceFilterFormat) => {
            if (priceFilter) {
              switch (priceFilter.value) {
                case PricesFilterTypes.BY_ASCENDING:
                  this.allPartnersCourses = this.allPartnersCourses.sort((a, b) =>
                    this.calculateRealPrice(a) > this.calculateRealPrice(b) ? 1 : -1,
                  );
                  break;
                case PricesFilterTypes.BY_DESCENDING:
                  this.allPartnersCourses = this.allPartnersCourses.sort((a, b) =>
                    this.calculateRealPrice(a) < this.calculateRealPrice(b) ? 1 : -1,
                  );
                  break;
              }
            }
            this.allPartnersCoursesView = this.allPartnersCourses;
            return this.filtersService.getDateFilter().pipe(
              tap((dateFilter: IDateFilterFormat) => {
                if (dateFilter) {
                  this.allPartnersCoursesView = this.allPartnersCourses.filter(course => {
                    if (course.startDate) {
                      let courseStartDate = new Date(course.startDate);
                      return courseStartDate.getMonth() != dateFilter.value - 1 ? false : true;
                    }
                    return true;
                  });
                } else {
                  this.allPartnersCoursesView = this.allPartnersCourses;
                }
              }),
            );
          }),
        );
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  loadCourses(filters: IFilterClasses): Observable<any[]> {
    Object.assign(filters, {
      stand: Stands.Talent,
      regionId: localStorage.getItem('regionId'),
      municipalityId: localStorage.getItem('municipalityId'),
    });

    return this.filtersService.getFilteredClassesMongo(filters).pipe(takeUntil(this.ngUnsubscribe$));
  }

  createRecommendedCoursesArray(coursesByFilters: any[], talents?: any[]): any[] {
    let coursesArray = [];
    let coursesThreeCoincidence = [];
    let coursesTwoCoincidence = [];
    let coursesOneCoincidence = [];

    if (talents && talents.length) {
      //сравниваем таланты по name, т.к. id не совпадает; вопрос, почему??
      //courses with 3 talents coincidence
      coursesThreeCoincidence = coursesByFilters.filter(course => {
        return talents.every(talent => course.talents.includes(talent.name));
      });
      coursesArray = coursesArray.concat(coursesThreeCoincidence);
      //courses with 2 talents coincidence
      coursesTwoCoincidence = coursesByFilters.filter(course => {
        if (course.talents.filter(c => talents.some(t => t.name === c)).length === 2) {
          return true;
        } else {
          return false;
        }
      });
      coursesArray = coursesArray.concat(coursesTwoCoincidence);
      //courses with 1 talents coincidence
      coursesOneCoincidence = coursesByFilters.filter(course => {
        if (course.talents.filter(c => talents.some(t => t.name === c)).length === 1) {
          return true;
        } else {
          return false;
        }
      });
      coursesArray = coursesArray.concat(coursesOneCoincidence);
      return coursesArray;
    } else {
      return coursesByFilters;
    }
  }

  navigateNewTest() {
    return this.router.navigate(['/parent/mosrupromo']);
  }

  calculateRealPrice(course: any) {
    let discount = +course.unitDiscount;
    let price = +course.unitPrice;
    if (course.isPercent) {
      return discount ? price - price * discount * 0.01 : price;
    } else {
      return discount ? price - discount : price;
    }
  }

  returnToResults() {
    if (this.isVGuser) {
      return this.resultsSSid
        ? this.router.navigate(['/vorobievi-gori/promotest-results/' + this.resultsSSid])
        : this.resultsRoutingUrl
        ? this.router.navigate([this.resultsRoutingUrl])
        : this.router.navigate(['/vorobievi-gori/mosrupromo']);
    } else {
      return this.resultsSSid
        ? this.router.navigate(['/promotest-results/' + this.resultsSSid])
        : this.resultsRoutingUrl
        ? this.router.navigate([this.resultsRoutingUrl])
        : this.router.navigate(['/parent/mosrupromo']);
    }
  }

  checkPromotestResults() {
    this.resultsService
      .getSharedResults(localStorage.getItem('userId'))
      .pipe(
        tap(sharedResults => {
          let promotestResults: any[] = [];
          if (sharedResults.length > 0) {
            promotestResults = sharedResults.filter(r => r.screeningTestId === TESTS_VARIANTS_IDS.promoTestMosRu);
            this.resultsRoutingUrl = promotestResults.length > 0 ? '/promotest-results' : '/parent/mosrupromo';
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  showMobileMenu() {
    this.menuService.showMobileMenu(true);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
