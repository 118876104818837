<div *ngIf="talant">
  <div class="pupil-talant-card" [style.background]="'center bottom / cover no-repeat url(' + talant.imageUrl + ')'">
    <div class="card-container">
      <div class="talant-name align-left">
        {{ talant.name }}
      </div>
      <div class="talant-description align-left">
        {{ talant.shortDescription | translate }}
      </div>
    </div>
  </div>
</div>
