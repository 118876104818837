import { Component, OnInit } from '@angular/core';

export enum ProfessionsCatalogTypes {
  MONGO = 1,
  ELASTIC,
}

@Component({
  selector: 'prf-professions-panel',
  templateUrl: './professions-panel.component.html',
  styleUrls: ['./professions-panel.component.scss'],
})
export class ProfessionsPanelComponent implements OnInit {
  public professionsMenu = ProfessionsCatalogTypes;
  public tabLevel: ProfessionsCatalogTypes = ProfessionsCatalogTypes.MONGO;
  public adminLevel;

  constructor() {
    this.adminLevel = localStorage.getItem('adminLevel');
  }

  ngOnInit() {}
}
