import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'prf-tutor-student-test-results',
  templateUrl: './tutor-student-test-results.component.html',
  styleUrls: ['./tutor-student-test-results.component.scss'],
})
export class TutorStudentTestResultsComponent implements OnInit {
  @Input() pupil: any;
  sessionId: string = '';
  showTestResults: boolean = false;
  pupilSessionComplete: boolean;
  resultLoaded: boolean = false;

  constructor() {}

  ngOnInit() {}
}
