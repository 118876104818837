<prf-menu></prf-menu>

<div class="content-section">
  <prf-page-header [title]="'История тестов'" [buttonToTest]="isCompletedTest"></prf-page-header>
  <div class="container">
    <prf-pupil-tests-history
      [pupil]="user"
      (testsResultsViewEmitter)="getCompletedTest($event)"
      [sessionList]="sessionList"
    ></prf-pupil-tests-history>
    <prf-page-footer></prf-page-footer>
  </div>
</div>
