<div *ngFor="let group of groups; let i = index">
  <p>{{ group.questionName }}</p>
  <canvas
    baseChart
    [data]="group.data[0].data"
    [labels]="group.data[1].data"
    [chartType]="barChartType"
    [legend]="barChartLegend"
    [options]="barChartOptions"
    [colors]="chartColors"
  >
  </canvas>
</div>
