import { Injectable } from '@angular/core';
import { PlayerStatus, SlideStatus, SlideType } from '../enums/slides-routing.enums';

const slides = [
  {
    number: 0,
    type: SlideType.CHECKBOXGROUP,
    id: 0,
    dependsOnPrevious: true,
    data: [
      { value: '', id: 'name' },
      { value: false, id: 'gender' },
      { value: '', id: 'age' },
    ],
    status: SlideStatus.SENT,
  },
  {
    number: 1,
    type: SlideType.CHECKBOXGROUP,
    id: 111,
    dependsOnPrevious: true,
    data: [
      { value: true, id: 1, displayName: 'путешествия' },
      { value: false, id: 2, displayName: 'музыка' },
      { value: false, id: 3, displayName: 'рукоделие' },
      { value: false, id: 4, displayName: 'интеллектуаль-ные игры' },
      { value: false, id: 5, displayName: 'чтение' },
      { value: false, id: 6, displayName: 'кулинария' },
      { value: false, id: 7, displayName: 'компьютерные игры' },
      { value: false, id: 8, displayName: 'рисование и живопись' },
      { value: false, id: 9, displayName: 'коллекционирование' },
      { value: false, id: 10, displayName: 'кино' },
      { value: false, id: 11, displayName: 'театр' },
      { value: false, id: 12, displayName: 'написание статей, ведение бловов' },
      { value: false, id: 13, displayName: 'моделирование' },
      { value: false, id: 14, displayName: 'уход за животными' },
      { value: false, id: 15, displayName: 'танцы' },
      { value: false, id: 16, displayName: 'спорт' },
    ],
    status: SlideStatus.SENT,
  },
  {
    number: 2,
    type: SlideType.CHECKBOXGROUP,
    id: 222,
    dependsOnPrevious: true,
    data: [
      { value: false, id: 1, displayName: 'путешествия' },
      { value: false, id: 2, displayName: 'музыка' },
      { value: false, id: 3, displayName: 'рукоделие' },
      { value: false, id: 4, displayName: 'интеллектуальные игры' },
      { value: false, id: 5, displayName: 'чтение' },
      { value: false, id: 6, displayName: 'кулинария' },
      { value: false, id: 7, displayName: 'компьютерные игры' },
      { value: false, id: 8, displayName: 'рисование и живопись' },
      { value: false, id: 9, displayName: 'коллекционирование' },
      { value: false, id: 10, displayName: 'кино' },
      { value: false, id: 11, displayName: 'театр' },
      { value: false, id: 12, displayName: 'написание статей, ведение блогов' },
      { value: false, id: 13, displayName: 'моделирование' },
      { value: false, id: 14, displayName: 'уход за животными' },
      { value: false, id: 15, displayName: 'танцы' },
      { value: false, id: 16, displayName: 'спорт' },
    ],
    status: SlideStatus.SENT,
  },
  {
    number: 3,
    type: SlideType.CHECKBOXGROUP,
    id: 333,
    dependsOnPrevious: false,
    data: [
      { value: 4, id: 1, displayName: 'Быстро считает в уме' },
      { value: 1, id: 2, displayName: 'Хорошо понимает математические формулы' },
      { value: 2, id: 3, displayName: 'Легко ориентируется в пространстве и местности' },
      { value: 3, id: 4, displayName: 'Хорошо делает работу руками, собирает, ремонтирует' },
      {
        value: 0,
        id: 5,
        displayName: 'Легко осваивает спортивные упражнения, хорошо владее своим телом',
      },
    ],
    status: SlideStatus.SENT,
  },
  {},
  {
    number: 5,
    type: SlideType.CHECKBOXGROUP,
    id: 555,
    dependsOnPrevious: false,
    data: [{ value: 0, id: 'testType' }],
    status: SlideStatus.SENT,
  },
  {
    number: 6,
    type: SlideType.CHECKBOXGROUP,
    id: 666,
    dependsOnPrevious: false,
    data: [{ value: 0, id: 'userStatus' }],
    status: SlideStatus.SENT,
  },
  {},
  {
    number: 8,
    type: SlideType.CHECKBOXGROUP,
    id: 888,
    dependsOnPrevious: false,
    data: [{ value: '', id: 'email' }],
    status: SlideStatus.SENT,
  },
];

@Injectable()
export class StorageService {
  public slidesCount = slides.length;

  constructor() {}

  updateLocalStorage(playerId, slideNumber, slide) {
    // 1. получить весь массив данных
    let lsQuesets = this.getPlayers();
    let playerIndex;
    let slideIndex;
    // 2. найти интекс искомого опроса
    lsQuesets.forEach((q, i) => {
      if (q.id == playerId) {
        playerIndex = i;
      }
    });
    // 2. поиск индекса шага

    lsQuesets[playerIndex].slides.forEach((s, i) => {
      if (s.number == slideNumber) {
        slideIndex = i;
      }
    });

    // 4. заменить данные шага
    lsQuesets[playerIndex].slides[slideIndex] = slide;

    setTimeout(() => {
      localStorage.setItem('players', JSON.stringify(lsQuesets));
    });
  }

  getPlayers() {
    return JSON.parse(window.localStorage.players);
  }

  getData(playerId) {
    let players = JSON.parse(window.localStorage.players);
    let player;
    players.forEach((q, i) => {
      if (q.id == playerId) {
        player = { i, player: q };
      }
    });
    return player;
  }

  updateData() {
    // window.localStorage.players77 = JSON.stringify([
    //   /* Object<IPlayerionaire> */
    //   {
    //     id: 'some-sort-of-hash-id-55666',
    //     slides: [
    //       {
    //         number: 3,
    //         type: slideType.CHECKBOXGROUP,
    //         id: 131314131,
    //         dependsOnPrevious: false,
    //         data: [
    //           { value: false, id: 13414141, displayName: 'Music' },
    //           {
    //             value: false, id: 414141, displayName: 'Films'
    //           }/* ...ICheckBox */
    //         ],
    //         status: slideStatus.SENT
    //       }
    //     ],
    //     expires: 1499784825548,
    //     status: PlayerStatus.INPROGRESS
    //   }
    // ]);
  }

  dataExists() {
    return window.localStorage.players !== undefined;
  }

  /**
   * Установка id сессии теста
   */

  setTestSessionId(sessionId) {
    window.sessionStorage.playerSessionId = sessionId;
  }

  /**
   * Получение id сессии теста
   */

  getTestSessionId() {
    return window.sessionStorage.playerSessionId || false; // Вернуть id сессии или false если не установлено
  }

  startDataInit() {
    // window.localStorage.players = JSON.stringify([
    //   /* Object<IPlayerionaire> */
    //   {
    //     id: 'test-hash',
    //     slides: [
    //       {
    //         number: 1,
    //         type: slideType.CHECKBOXGROUP,
    //         id: 76553,
    //         dependsOnPrevious: true,
    //         data: [
    //           { value: true, id: 466, displayName: 'Music' },
    //           {
    //             value: false, id: 467, displayName: 'Films'
    //           }/* ...ICheckBox */
    //         ],
    //         status: slideStatus.SENT
    //       }
    //     ],
    //     expires: 1499784825548,
    //     status: PlayerStatus.INPROGRESS
    //   }
    // ]);
  }

  public getCurrentSlide() {
    return window.sessionStorage.currentSlide || false; // Вернуть номер текущего слайда прохождения или false если не установлено
  }

  public setCurrentSlide(currentSlide) {
    window.sessionStorage.currentSlide = currentSlide; // установить номер текущего слайда
  }

  testDataInit() {
    window.localStorage.players = JSON.stringify([
      /* Object<IPlayerionaire> */
      {
        id: 'some-sort-of-hash-id-55666',
        slides: [
          {
            number: 1,
            type: SlideType.CHECKBOXGROUP,
            id: 111,
            dependsOnPrevious: true,
            data: [
              { value: true, id: 1, displayName: 'путешествия' },
              { value: false, id: 2, displayName: 'музыка' },
              { value: false, id: 3, displayName: 'рукоделие' },
              { value: false, id: 4, displayName: 'интеллектуаль-ные игры' },
              { value: false, id: 5, displayName: 'чтение' },
              { value: false, id: 6, displayName: 'кулинария' },
              { value: false, id: 7, displayName: 'компьютерные игры' },
              { value: false, id: 8, displayName: 'рисование и живопись' },
              { value: false, id: 9, displayName: 'коллекционирование' },
              { value: false, id: 10, displayName: 'кино' },
              { value: false, id: 11, displayName: 'театр' },
              { value: false, id: 12, displayName: 'написание статей, ведение бловов' },
              { value: false, id: 13, displayName: 'моделирование' },
              { value: false, id: 14, displayName: 'уход за животными' },
              { value: false, id: 15, displayName: 'танцы' },
              { value: false, id: 16, displayName: 'спорт' },
            ],
            status: SlideStatus.SENT,
          },
          {
            number: 2,
            type: SlideType.CHECKBOXGROUP,
            id: 222,
            dependsOnPrevious: true,
            data: [
              { value: false, id: 1, displayName: 'путешествия' },
              { value: false, id: 2, displayName: 'музыка' },
              { value: false, id: 3, displayName: 'рукоделие' },
              { value: false, id: 4, displayName: 'интеллектуальные игры' },
              { value: false, id: 5, displayName: 'чтение' },
              { value: false, id: 6, displayName: 'кулинария' },
              { value: false, id: 7, displayName: 'компьютерные игры' },
              { value: false, id: 8, displayName: 'рисование и живопись' },
              { value: false, id: 9, displayName: 'коллекционирование' },
              { value: false, id: 10, displayName: 'кино' },
              { value: false, id: 11, displayName: 'театр' },
              { value: false, id: 12, displayName: 'написание статей, ведение блогов' },
              { value: false, id: 13, displayName: 'моделирование' },
              { value: false, id: 14, displayName: 'уход за животными' },
              { value: false, id: 15, displayName: 'танцы' },
              { value: false, id: 16, displayName: 'спорт' },
            ],
            status: SlideStatus.SENT,
          },
          {
            number: 3,
            type: SlideType.CHECKBOXGROUP,
            id: 333,
            dependsOnPrevious: false,
            data: [
              { value: 4, id: 1, displayName: 'Быстро считает в уме' },
              { value: 1, id: 2, displayName: 'Хорошо понимает математические формулы' },
              { value: 2, id: 3, displayName: 'Легко ориентируется в пространстве и местности' },
              { value: 3, id: 4, displayName: 'Хорошо делает работу руками, собирает, ремонтирует' },
              {
                value: 0,
                id: 5,
                displayName: 'Легко осваивает спортивные упражнения, хорошо владее своим телом',
              },
            ],
            status: SlideStatus.SENT,
          },
        ],
        expires: 1499784825548,
        status: PlayerStatus.INPROGRESS,
      },
    ]);
  }
}
