import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IResult, IResultsData } from 'app/pages/results/results.interface';
import { ResultsService } from 'app/pages/results/results.service';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PupilPanelService } from 'app/pages/control-panel/pupil/pupil-panel.service';
import { PlayerService } from 'app/pages/player/player.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { ParentPanelService } from 'app/pages/control-panel/parent/parent-panel.service';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { TutorPanelService } from 'app/pages/control-panel/tutor/tutor-shared/tutor.service';
import { SharedService } from 'app/shared/shared.service';
import { ITestInfo } from 'app/shared/interfaces/itestinfo.interface';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import {
  SpiderChartFiguresEnum,
  SpiderChartThemeEnum,
} from '@profilum-components/spider-chart-custom/spider-chart-custom.model';

@Component({
  selector: 'prf-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit, OnDestroy {
  @Input()
  set sessionId(val: string) {
    //загружаем результаты по sessionId
    this._sessionId = val;
    if (val) {
      this.playerService
        .getResults(this._sessionId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(params => {
          this.parseResults(params);
        });
    }
  }

  @Input() set child(val: any) {
    this._child = val;
  }
  _child;
  get child() {
    return this._child;
  }

  public interestsTopCategories: any[] = [];
  public skillsTopCategories: any[] = [];

  @Input() showSwitch: boolean = false;
  @Input() showResultMenu: boolean = false;
  @Input() isRecommendedProfessionsTabFilterShown: boolean = true;

  @Output() loaded = new EventEmitter<boolean>();
  @Output() pupilSessionComplete = new EventEmitter<boolean>();
  @Output() parentSessionComplete = new EventEmitter<boolean>();

  public resultMenu: string = 'results';
  public skillsAttainmentData = [];
  public interestsData = [];
  public talentsData = [];
  public localization: any;
  public fieldsData = [];
  public selectedField: any;
  public activeProfessionList: IProfession[] = [];
  public userRole: string = '';
  public isPupil: boolean = false;
  public showParent: boolean = false;
  public showPupil: boolean = false;
  public parentSessionId: string = '';
  public pupilSessionId: string = '';
  public isCompleteTestPupil: boolean = false;
  public isCompleteTestParent: boolean = false;

  public parentLink: string;
  public resultsFetched: boolean = false;
  public nullResults: boolean = false;
  public skillsAttainment: IResult[] = [];
  public interests: IResult[] = [];
  public talents: IResult[] = [];
  public professionList: IProfession[] = [];
  public course: IResult[] = [];
  public additionalText: Array<string> = [];
  public additionalTextName: string = null;
  public additionalTextShortDescription: string = null;
  public userId: string = '';
  public parent: any = {};
  public sharedResults: any;
  public chartSize: number = 560; // [px]

  private refferalSessionId: string = null;
  private programsAmount: number = 1;
  private _sessionId: string = null;
  private ngUnsubscribe$ = new Subject<any>();
  private showAfterTestingText: boolean = false;
  private talentDescriptionByRole: Object = {};
  private initialSessionId: string = '00000000-0000-0000-0000-000000000000';

  public regionId: string = '';
  public municipalityId: string = '';
  public selectLanguage: boolean = false;

  public parentResults: IResultsData;
  public pupilResults: IResultsData;
  public currentResults: IResultsData;

  isMobile: boolean = false;

  @ViewChild('infopopup') infopopup: ElementRef;
  @ViewChildren('closepopup') closepopup: QueryList<ElementRef>;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resultService: ResultsService,
    private pupilPanelService: PupilPanelService,
    private parentPanelService: ParentPanelService,
    private tutorPanelService: TutorPanelService,
    private location: Location,
    private overlayService: OverlayBusyService,
    private translate: TranslateService,
    private playerService: PlayerService,
    private appSettingsService: AppSettingsService,
    private sharedService: SharedService,
    private utilsService: UtilsService,
  ) {
    // если выполняется совпадение regionId из appsettings с выбранным регионом пользователя и для данного региона разрешена смена языка (isAccessible)
    this.selectLanguage =
      AppSettingsService.settings.regionLanguages &&
      AppSettingsService.settings.regionLanguages.isAccessible &&
      AppSettingsService.settings.regionId == localStorage.getItem('regionId');
    this.programsAmount = 3;
    this.userRole = localStorage.getItem('userRole');
    this.isPupil = this.userRole === 'pupil';
    this.userId = localStorage.getItem('userId');
    this.regionId = localStorage.getItem('regionId');
    this.municipalityId = localStorage.getItem('municipalityId');
    this.isMobile = window.innerWidth <= 1024;
  }

  ngOnInit() {
    if (this.userRole !== 'tutor') {
      this.overlayService.show();
    }

    this.setChartSize(window.innerWidth);
    this.getLocalizationData();
    this.checkUserRole();

    this.getSessionData();
  }

  public getSessionData() {
    // Отображение теста по сессии из URL
    this.route.params
      .pipe(
        switchMap(params => {
          // смотрим sessionId в url
          if (params['ssid']) {
            this._sessionId = params['ssid'];
            localStorage.setItem('testSessionId', this._sessionId);
            localStorage.setItem('sessionStatus', 'Success');

            const sessionIds = [];
            sessionIds.push(this._sessionId);
            const getResultsObject = {
              regionId: this.regionId,
              municipalityId: this.municipalityId,
              sessionIds: sessionIds,
              role: this.userRole,
            };

            if (this.selectLanguage) {
              return this.pupilPanelService.getUserSession(this._sessionId).pipe(
                switchMap(session => {
                  Object.assign(getResultsObject, { language: session.language });
                  return this.resultService.getResultPages(getResultsObject).pipe(
                    tap((results: IResultsData) => {
                      if (results) {
                        this.sharedResults = results;
                        this.parseResults(this.sharedResults);
                      }
                    }),
                  );
                }),
              );
            } else {
              return this.resultService.getResultPages(getResultsObject).pipe(
                tap(results => {
                  if (results) {
                    this.sharedResults = results;
                    this.parseResults(this.sharedResults);
                  }
                }),
              );
            }
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(() => {
        //запрашиваем пройденные сессии теста
        if (!this._sessionId) {
          this.showBySharedResults();
        }
      });
  }

  public showBySharedResults() {
    let pupilUserId = '';
    let parentUserId = '';
    //в sharedResults заносятся только сессии теста на таланты родителя и детей, как завершенные, так и не завершенные
    let currentObservable$: Observable<any> = of(null);

    if (this.userRole === 'parent') {
      pupilUserId = this.child?.userId;
      parentUserId = this.userId;
      currentObservable$ = this.sharedService.getUserInfoData().pipe(
        switchMap(userInfo => {
          this.parent = userInfo;
          return this.resultService.getSharedResults(parentUserId).pipe(
            switchMap(sharedResults => {
              return this.sharedService.getRegionTestInfo(this.regionId).pipe(
                map((testInfo: ITestInfo) => {
                  return sharedResults.filter(ru => ru.userId === parentUserId && ru.screeningTestId === testInfo.screeningTestId);
                }),
              );
            }),
          );
        }),
      );
    } else if (this.userRole === 'pupil') {
      pupilUserId = this.userId;
      currentObservable$ = this.sharedService.getUserInfoData().pipe(
        switchMap(userInfo => {
          this.parent = userInfo?.parents ? userInfo.parents[0] : {};
          return this.resultService.getSharedResults(pupilUserId).pipe(tap(([results, userInfo]) => {}));
        }),
      );
    } else if (this.userRole === 'schooladmin') {
      //todo запрос userInfo ребенка и родителя, нет методов для всех пользователей
      pupilUserId = this.child?.userId;
      currentObservable$ = this.resultService.getSharedResults(pupilUserId);
    } else if (this.userRole === 'tutor') {
      pupilUserId = this.child.userId;
      currentObservable$ = this.tutorPanelService.getTutorUserInfoByCode(this.child?.registrationCode).pipe(
        switchMap(childInfo => {
          this.parent = childInfo?.parents ? childInfo.parents[0] : {};

          if (childInfo.comment.includes('not found')) {
            this.utilsService.openSnackBar('Код не найден', 'error');

            return of(null);
          }

          return this.resultService.getSharedResults(pupilUserId);
        }),
      );
    }

    // -----------------------------------------------------------------

    currentObservable$
      .pipe(
        switchMap(sharedResults => {
          //sharedResults не возвращаются, пока не добавлен ребенок
          let sharedResultPageObservable$: Observable<any>;
          let parentResultPageObservable$: Observable<any>;
          let childResultPageObservable$: Observable<any>;
          if (sharedResults && sharedResults.length) {
            //ненулевая сессия - значит, тест начат
            this.pupilSessionId = sharedResults[0].refferalSessionId;
            // первая сесси в списке должна быть последней начатой сессией
            this.parentSessionId = sharedResults[0].sessionId;

            if (this.userRole === 'pupil' || this.userRole === 'schooladmin' || this.userRole === 'tutor') {
              parentUserId = sharedResults[0].userId;
            }

            const sessionIds = this.pupilSessionId ? [this.parentSessionId, this.pupilSessionId] : [this.parentSessionId];
            const getResultsObject = {
              regionId: localStorage.getItem('regionId'),
              municipalityId: localStorage.getItem('municipalityId'),
              sessionIds: sessionIds,
              role: localStorage.getItem('userRole'),
            };

            const getResultsParentObject = {
              regionId: localStorage.getItem('regionId'),
              municipalityId: localStorage.getItem('municipalityId'),
              sessionIds: [this.parentSessionId],
              role: localStorage.getItem('userRole'),
            };

            const getResultsPupilObject = {
              regionId: localStorage.getItem('regionId'),
              municipalityId: localStorage.getItem('municipalityId'),
              sessionIds: [this.pupilSessionId],
              role: localStorage.getItem('userRole'),
            };

            if (this.selectLanguage) {
              // берем сессию родителя
              sharedResultPageObservable$ = this.pupilPanelService.getUserSession(this.parentSessionId).pipe(
                mergeMap(session => {
                  Object.assign(getResultsObject, { language: session.language });
                  return this.resultService.getResultPages(getResultsObject);
                }),
              );
            } else {
              parentResultPageObservable$ = this.resultService.getResultPages(getResultsParentObject);
              childResultPageObservable$ = this.resultService.getResultPages(getResultsPupilObject);
              sharedResultPageObservable$ = this.resultService.getResultPages(getResultsObject);
            }
          } else {
            //пробуем загрузить сессию ребенка, если нет sharedResults
            sharedResultPageObservable$ = this.resultService.getLastSession(pupilUserId).pipe(
              switchMap(response => {
                const lastSessionId = response.sessionId;
                if (lastSessionId && lastSessionId !== this.initialSessionId) {
                  return this.parentPanelService.getUserSession(lastSessionId).pipe(
                    mergeMap(lastSession => {
                      let userResultsObservable$: Observable<any> = of(null);
                      this.pupilSessionId = lastSession.sessionId !== this.initialSessionId ? lastSession.sessionId : '';
                      if (lastSession && lastSession.completed) {
                        const sessionIds = [];
                        sessionIds.push(lastSession.sessionId);
                        const regionId = localStorage.getItem('regionId');
                        const muniId = localStorage.getItem('municipalityId');
                        const getResultsObject = {
                          regionId: regionId === 'null' ? null : regionId,
                          municipalityId: muniId === 'null' ? null : muniId,
                          sessionIds: sessionIds,
                          role: localStorage.getItem('userRole'),
                        };

                        if (this.selectLanguage) {
                          userResultsObservable$ = this.pupilPanelService.getUserSession(lastSession.sessionId).pipe(
                            mergeMap(session => {
                              Object.assign(getResultsObject, { language: session.language });
                              return this.resultService.getResultPages(getResultsObject);
                            }),
                          );
                        } else {
                          userResultsObservable$ = this.resultService.getResultPages(getResultsObject);
                        }
                      }
                      return userResultsObservable$;
                    }),
                  );
                } else {
                  return of(null);
                }
              }),
            );
          }

          return forkJoin([parentResultPageObservable$, childResultPageObservable$, sharedResultPageObservable$]).pipe(
            switchMap(([parentResults, pupilResults, results]) => {
              if (!results || !results.results || results.results.length === 0) {
                this.nullResults = true;
                setTimeout(() => this.overlayService.hide(), 500);
                // данные загружены
                this.resultsFetched = true;
                this.loaded.emit(true);
                return of(null);
              }

              this.parentResults = parentResults;
              this.pupilResults = pupilResults;
              this.sharedResults = results;

              const sessionsRequestArray$: Observable<any>[] = [];

              if (this.pupilSessionId && this.pupilSessionId !== this.initialSessionId) {
                sessionsRequestArray$.push(this.pupilPanelService.getUserSession(this.pupilSessionId));
              }

              if (this.parentSessionId) {
                sessionsRequestArray$.push(this.pupilPanelService.getUserSession(this.parentSessionId));
              }

              return forkJoin(sessionsRequestArray$).pipe(
                tap((sessions: any[]) => {
                  const pupilSession = sessions.find(session => session.userId === pupilUserId);
                  const parentSession = sessions.find(session => session.userId === parentUserId);
                  if (pupilSession) {
                    this.isCompleteTestPupil = !!pupilSession.completed;
                  }
                  if (parentSession) {
                    this.isCompleteTestParent = !!parentSession.completed;
                  }
                  //нужно для передачи в test-switch
                  if (this.child) {
                    this.child = pupilSession
                      ? Object.assign(this.child, { lastSession: pupilSession })
                      : Object.assign(this.child, { lastSession: undefined });
                  } else {
                    this.child = null;
                  }
                  this.parent = parentSession ? Object.assign(this.parent, { lastSession: parentSession }) : null;

                  this.showPupil = this.isCompleteTestPupil;
                  this.showParent = this.isCompleteTestParent;

                  this.pupilSessionComplete.emit(this.isCompleteTestPupil);
                  this.parentSessionComplete.emit(this.isCompleteTestParent);

                  if (this.isCompleteTestPupil && !this.isCompleteTestParent) {
                    this._sessionId = this.pupilSessionId;
                  }

                  if (!this.isCompleteTestPupil && this.isCompleteTestParent) {
                    this._sessionId = this.parentSessionId;
                  }

                  if (this.isCompleteTestPupil && this.isCompleteTestParent) {
                    this._sessionId = this.parentSessionId;
                  }

                  if (results && (this.isCompleteTestPupil || this.isCompleteTestParent)) {
                    this.parseResults(results);
                  } else {
                    this.overlayService.hide();
                    this.loaded.emit(true);
                  }
                }),
              );
            }),
          );
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  private parseResults(results: IResultsData) {
    let resultsData;
    resultsData = JSON.parse(JSON.stringify(results));
    if ((!this.showParent && this.showPupil) || (this.showParent && !this.showPupil)) {
      resultsData.results = resultsData.results.map(item => {
        item.results = item.results.filter(s => s.sessionId === this._sessionId);
        if (item.results.length) return item;
      });
    }
    if (!this.checkResultsCategories(resultsData.results, ['SkillAttainment', 'TalentAttainment'])) {
      this.nullResults = true;
      setTimeout(() => this.overlayService.hide(), 500);
      // данные загружены
      this.resultsFetched = true;
      this.loaded.emit(true);
      return;
    }

    this.currentResults = resultsData;
    this.getAdditionalText();
    this.skillsAttainment = resultsData.results.filter(d => d.objectType === 'SkillAttainment');
    this.interests = resultsData.results.filter(d => d.objectType === 'TalentAttainment');
    this.talents = resultsData.results.filter(d => d.objectType === 'Talent');
    const fields = resultsData.results.filter(d => d.objectType === 'Field');
    this.fieldsData = fields.sort((a, b) => {
      if (a.results[0] && b.results[0]) {
        return a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1;
      } else {
        return -1;
      }
    });
    this.selectedField = this.fieldsData[0];

    this.activeProfessionList = this.professionList.filter(p => {
      return this.FilterFields(p, this.selectedField.name);
    });
    this.interestsData = this.resultService.transformInterests(this.interests, this.pupilSessionId, this.parentSessionId);
    this.interestsTopCategories = this.interestsData.map(item => {
      return {
        name: item.title,
        childValue: item.childEstimate || 0,
        parentValue: item.parentEstimate || 0,
      };
    });
    if (this.interestsTopCategories['childValue']) {
      this.interestsTopCategories = this.interestsTopCategories.sort((a, b) => b.childValue - a.childValue).slice(0, 3);
    } else {
      this.interestsTopCategories = this.interestsTopCategories.sort((a, b) => b.parentValue - a.parentValue).slice(0, 3);
    }
    this.skillsAttainmentData = this.resultService.transformSkills(this.skillsAttainment, this.pupilSessionId);

    this.skillsTopCategories = [];

    for (let i = 0; i < this.skillsAttainmentData[0].length; i++) {
      this.skillsTopCategories.push({
        childValue: this.skillsAttainmentData[0][i] ? Math.round(this.skillsAttainmentData[0][i]['value'] * 100) : null,
        parentValue: this.skillsAttainmentData[1][i] ? Math.round(this.skillsAttainmentData[1][i]['value'] * 100) : null,
        name: this.skillsAttainmentData[0][i]['axis'],
      });
    }
    if (this.pupilSessionId) {
      this.skillsTopCategories = this.skillsTopCategories.sort((a, b) => b.childValue - a.childValue).slice(0, 3);
    } else {
      this.skillsTopCategories = this.skillsTopCategories.sort((a, b) => b.parentValue - a.parentValue).slice(0, 3);
    }

    // получаем урл картинок для талантов
    this.resultService
      .getTalentsUrls()
      .pipe(
        take(1),
        tap(data => {
          // преобразовываем массив с урлами талантов объект
          data.forEach(item => {
            // переопределение описание таланта для родителя и тьютора
            if (!this.isPupil) {
              switch (this.userRole) {
                case 'parent':
                  this.talentDescriptionByRole[item.name] = item.descriptionForParent;
                  break;
                default:
                  this.talentDescriptionByRole[item.name] = item.descriptionForTutor;
              }
            }

            this.talentsData = this.preTransformTalents(this.talents);
          });
          setTimeout(() => this.overlayService.hide(), 500);
          // данные загружены
          this.resultsFetched = true;
          this.loaded.emit(true);
        }),
      )
      .subscribe();
  }

  checkResults() {
    setTimeout(_ => {
      if (!this.showParent && this.showPupil) {
        this._sessionId = this.pupilSessionId;
        this.parseResults(this.pupilResults);
      }

      if (this.showParent && !this.showPupil) {
        this._sessionId = this.parentSessionId;
        this.parseResults(this.parentResults);
      }

      if (this.showParent && this.showPupil) {
        this._sessionId = this.parentSessionId;
        this.parseResults(this.sharedResults);
      }
    }, 50);
  }

  private checkUserRole() {
    if (this.userRole === 'pupil') {
      return (this.showAfterTestingText = true);
    }
    return (this.showAfterTestingText = false);
  }

  private setChartSize(size: number) {
    if (size > 890 && size <= 960) {
      this.chartSize = 500;
    } else if (size > 460 && size <= 890) {
      this.chartSize = 450;
    } else if (size <= 460 && size > 370) {
      this.chartSize = 400;
    } else if (size <= 370) {
      this.chartSize = 350;
    } else {
      this.chartSize = 560;
    }
  }

  private prepareAdditionalText(text: string): Array<string> {
    const result: Array<string> = [];
    if (text && text.length) {
      const textList = text.replace(/[ ]?<p>[ ]?/gi, '').split('</p>');

      textList.forEach((t, i) => {
        if (i < 4) {
          result.push(t);
        }
      });
    }
    return result;
  }

  private getLocalizationData(): void {
    const translateSubscribe = this.translate
      .getTranslation(this.translate.currentLang)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.localization = response;
      });
  }

  private checkResultsCategories(resultsArray, categoriesNames: string[]): boolean {
    let resultsAvailable: boolean;
    categoriesNames.forEach(categoryName => {
      const categoryResultsArray = resultsArray.filter(r => r.objectType === categoryName);
      resultsAvailable = categoryResultsArray.every(resultItem => resultItem.results?.length > 0);
    });
    return resultsAvailable;
  }

  private FilterFields(dataFields, fieldName) {
    for (const i in dataFields.fieldNames) {
      if (dataFields.fieldNames[i] === fieldName) {
        return true;
      }
    }
    return false;
  }

  public routeHome(userRole) {
    switch (userRole) {
      case 'schooladmin':
        return `/${userRole}/classes`;
      default:
        return `/${userRole}`;
    }
  }

  public fieldsListClick(event, newValue) {
    this.selectedField = newValue;
    this.activeProfessionList = this.professionList.filter(p => {
      return this.FilterFields(p, newValue.name);
    });
  }

  private isEn = () => this.translate.currentLang === 'en';

  private createProfessions(data) {
    if (
      this.professionList.filter(p => {
        return p.hrid === data.hrid;
      }).length === 0
    ) {
      const result: IProfession = {
        _id: 0,
        hrid: data.hrid,
        name: data.name,
        fieldNames: data.fieldNames,
        shortDescription: data.shortDescription,
        mainImagePath: data.mainImagePath,
        salaryString: '',
        minSalary: data.minSalary,
        maxSalary: data.maxSalary,
        regionId: data.regionId,
        municipalityId: data.municipalityId,
      };
      this.professionList.push(result);
    }
  }

  private preTransformTalents(talents) {
    const shortDescriptionLen: number = 40;
    return talents
      .map(t => {
        return {
          name: t.name,
          description: this.isPupil ? t.description : this.talentDescriptionByRole[t.name],
          shortDescription:
            t.description.length < shortDescriptionLen ? t.description : t.description.slice(0, shortDescriptionLen - 2) + '..',
          value: t.results.length ? t.results[0].transformedValue : 0,
          imageUrl: document.location.origin + this.location.prepareExternalUrl(t.imageUrl),
          title: this.isEn() ? t.name : t.data.DativeCase,
        };
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, this.resultService.TALENTS_TO_CHOOSE);
  }

  private getTestingSessions(userId: string, sessionId?: string): Observable<any> {
    //if (!userId || !sessionId) {
    if (!userId) {
      return of([]);
    }
    return this.pupilPanelService.getTestingSessions(userId).pipe(
      map(userSessionList => {
        // возвращает либо список сессий, либо фильтруем нужную сессию
        if (userSessionList && userSessionList.length && sessionId) {
          userSessionList = userSessionList.filter(usl => usl.sessionId === sessionId);
          return userSessionList;
          // if (userSessionList.length) {
          //   return userSessionList[0].completed;
          // } else {
          //   return false;
          // }
        } else {
          return userSessionList;
        }
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  private getAdditionalText() {
    if (this.sharedResults && this.sharedResults.additionalText) {
      this.additionalTextName = this.sharedResults.additionalText.name;
      this.additionalTextShortDescription = this.sharedResults.additionalText.shortDescription;
      this.additionalText = this.prepareAdditionalText(this.sharedResults.additionalText.text);
    }
  }

  selectDetails(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setChartSize(event.target.innerWidth);
    event.target?.innerWidth;
    this.isMobile = event.target.innerWidth <= 1024;
  }
  @HostListener('window:click', ['$event.target'])
  onClick(target) {
    if (!this.infopopup?.nativeElement.contains(target) && !this.closepopup.some(el => el.nativeElement.contains(target))) {
      this.talentsData.forEach(talent => (talent.showDescription = false));
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  protected readonly SpiderChartThemeEnum = SpiderChartThemeEnum;
  protected readonly SpiderChartFiguresEnum = SpiderChartFiguresEnum;
}
