<div class="tab-pane-parent">
  <div class="empty-block">
    <div>
      <img src="/assets/images/icons/no-prof-icon.png" width="80" class="no-profession-icon" />
    </div>
    <div class="empty-block-text">
      {{ 'SHARED.RESULTS_TESTING_NARRATIVE' | translate }}
    </div>
  </div>
</div>
