<div class="similar-prof-block" *ngIf="dataFetched">
  <h4 class="similar-prof-heading">Похожие профессии</h4>
  <div>
    <div class="professions-row">
      <div class="professions-column-2" *ngFor="let job of similarJobs | slice : 0 : 3">
        <a (click)="showProfession(job)" class="professions-card w-inline-block">
          <div class="professions-image">
            <img src="{{ baseUrl + (job.mainImagePath ? job.mainImagePath : '') }}" />
          </div>
          <div class="prof-column">
            <div class="professions-card-vertical-column">
              <h3 class="professions-h3">{{ job.name }}</h3>
              <p class="professions-description" innerHtml="{{ job.shortDescription }}"></p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
