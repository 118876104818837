import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IResult, IResultsData } from 'app/pages/results/results.interface';
import { Gender } from 'app/shared/enums/gender.enums';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { HistoryService } from 'app/shared/dashboard/backend-services/history.service';
import { IHistory } from '../../../interfaces/ihistory.interface';
import { mergeMap, switchMap, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { from, Observable, of, Subject } from 'rxjs';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { SharedService } from 'app/shared/shared.service';
import { ITestInfo } from 'app/shared/interfaces/itestinfo.interface';
import { UtilsService } from '../../backend-services/utils.service';

export enum UserRoles {
  pupil = 'pupil',
  parent = 'parent',
  tutor = 'tutor',
}

@Component({
  selector: 'prf-pupil-tests-history',
  templateUrl: './pupil-tests-history.component.html',
  styleUrls: ['./pupil-tests-history.component.scss'],
})
export class PupilTestsHistoryComponent implements OnInit {
  @Input() pupil: any;
  @Input() set sessionList(val: IHistory[]) {
    if (val) {
      this._sessionList = val;
      this.getTestInfo()
        .pipe(
          take(1),
          switchMap(r => {
            return this.getTestsData(val).pipe(takeUntil(this.ngUnsubscribe$));
          }),
        )
        .subscribe(_ => {
          this.testsResultsViewEmitter.emit(this.testsResultsView);
          this.isLoaded = true;
        });
    }
  }

  get sessionList() {
    return this._sessionList;
  }
  public pupilInfo: {
    userId: string;
    userRole: string;
    firstName: string;
    regionId: string;
    municipalityId: string;
  };

  public userId: string = '';
  public userRole: string = '';
  public regionId: string = '';
  public _sessionList: IHistory[];
  public sessionIdsList: string[] = [];
  public sessionListView: any[] = [];
  testInfo: ITestInfo = null;

  public testsResults: any[] = [];
  public testsResultsView: { sessionListView: any; testsResults: any; resultsOpened: boolean }[] = [];
  @Output() testsResultsViewEmitter = new EventEmitter<any[]>();

  public skillsAttainment: IResult[] = [];
  public interests: IResult[] = [];
  public talents: IResult[] = [];
  public isPupilLoaded: boolean = false;
  public isLoaded: boolean = false;
  public isPupilPage: boolean = true;

  testVariantsIds = TESTS_VARIANTS_IDS;
  fullResultsShow: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private historyService: HistoryService,
    private appSettingsService: AppSettingsService,
    private sharedService: SharedService,
    private utilsService: UtilsService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'История тестов' });
    this.userRole = localStorage.getItem('userRole');
    // todo изменить, если будет доступно другим пользователям
    this.isPupilPage = this.userRole == 'pupil' || this.userRole == 'tutor' ? true : false;
  }

  ngOnInit() {
    if (this.userRole === 'tutor') {
      this.getTutorPupilInfo(this.pupil)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(pupil => {
          if (pupil?.status === 'Success') {
            this.isPupilLoaded = true;
            this.pupilInfo = {
              userId: pupil.userId,
              userRole: 'pupil',
              firstName: pupil.firstName,
              regionId: pupil.regionId,
              municipalityId: pupil.municipalityId,
            };
          } else if (pupil?.comment.includes('not found')) {
            this.utilsService.openSnackBar('Код не найден', 'error');
          }
        });
    }
    // this.activatedRoute.params
    //   .pipe(
    //     switchMap(r => {
    //       return this.loadHistoryTests();
    //     }), takeUntil(this.ngUnsubscribe$),
    //   )
    //   .subscribe(() => {

    //     this.testsResultsViewEmitter.emit(this.testsResultsView);
    //     this.isLoaded = true;
    //   });
  }

  private getTestsData(sessionList: IHistory[]): Observable<any> {
    if (sessionList) {
      let currentObservable$: Observable<any> = of(null);
      if (this.pupil && this.pupil.regionId) {
        // для тьютора взяли все пройденные сессии
        switch (this.userRole) {
          case UserRoles.tutor:
            this.sessionListView = this.sessionList.filter(el => el.completed);
            break;
          case UserRoles.pupil:
            this.sessionListView = this.sessionList.filter(el => el.variantId == this.testInfo['pupilVariantId'] && el.completed);
            break;
          case UserRoles.parent:
            this.sessionListView = this.sessionList.filter(el => el.variantId == this.testInfo['parentVariantId'] && el.completed);
            break;
          default:
            break;
        }
      } else {
        // для тьютора regionid ученика нет
        this.sessionListView = sessionList.filter(el => el.completed);
      }
      return currentObservable$.pipe(
        switchMap(() => {
          this.sessionIdsList = this.sessionListView.map(el => el.sessionId);
          this.sessionListView.forEach(session => {
            this.testsResultsView.push({
              sessionListView: session,
              testsResults: null,
              resultsOpened: false,
            });
          });
          // запрашиваем результаты для shortResults по сессиям основного теста

          return this.sharedService.getRegionTestInfo(localStorage.getItem('regionId')).pipe(
            tap((testInfo: ITestInfo) => {
              this.testInfo = testInfo;
              let passportOfTalentsSessions = this.sessionListView.filter(session => session.screeningTestId === testInfo.screeningTestId);
              return from(passportOfTalentsSessions).pipe(
                mergeMap(session => {
                  return this.historyService.getTestResults(session.sessionId).pipe(
                    tap(results => {
                      this.testsResultsView.map(tsv => {
                        if (tsv.sessionListView.sessionId === session.sessionId) {
                          tsv.testsResults = this.parseResults(results);
                        }
                      });
                    }),
                  );
                }),
                toArray(),
              );
            }),
          );
        }),
      );
    } else {
      return of(null);
    }
  }

  getTestInfo(): Observable<any> {
    return this.sharedService.getRegionTestInfo(localStorage.getItem('regionId')).pipe(
      tap((testInfo: ITestInfo) => {
        this.testInfo = testInfo;
      }),
    );
  }

  public parseResults(oneTestResults: IResultsData) {
    return {
      skillsAttainment: oneTestResults.results.filter(d => d.objectType === 'SkillAttainment').sort((a, b) => this.sortTestResults(a, b)),
      interests: oneTestResults.results.filter(d => d.objectType === 'TalentAttainment').sort((a, b) => this.sortTestResults(a, b)),
      talents: oneTestResults.results.filter(d => d.objectType === 'Talent').sort((a, b) => this.sortTestResults(a, b)),
      fields: oneTestResults.results.filter(d => d.objectType === 'Field').sort((a, b) => this.sortTestResults(a, b)),
      skill: oneTestResults.results.filter(d => d.objectType === 'Skill').sort((a, b) => this.sortTestResults(a, b)),
      fieldDO: oneTestResults.results.filter(d => d.objectType === 'FieldDO').sort((a, b) => this.sortTestResults(a, b)),
    };
  }

  // сортировка по максимальным баллам
  public sortTestResults(a, b) {
    if (a.results[0] && b.results[0]) {
      return a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1;
    } else {
      return -1;
    }
  }

  private getResult(test: { sessionListView: any; testsResults: any; resultsOpened: boolean }): void {
    //this.router.navigate(['/results/' + sessionId]);
    test.resultsOpened = !test.resultsOpened;
  }

  public isMale(gender: string) {
    return gender === Gender.Male;
  }

  checkShortResults(test: { sessionListView: any; testsResults: any; resultsOpened: boolean }): boolean {
    return (
      test.sessionListView.screeningTestId === this.testInfo.screeningTestId
      // test.sessionListView.variantId === this.testInfo['pupilVariantId'] ||
      // test.sessionListView.variantId === this.testInfo['parentVariantId']
    );
  }
  showTestName(test: { sessionListView: any; testsResults: any; resultsOpened: boolean }): boolean {
    return this.userRole != 'pupil';
  }

  getTutorPupilInfo(pupil): Observable<any> {
    return this.historyService.getTutorPupilInfoByCode(pupil.registrationCode);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
