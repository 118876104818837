import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'prf-children-tabs',
  templateUrl: './children-tabs.component.html',
  styleUrls: ['./children-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChildrenTabsComponent {
  @Input()
  set children(val: any) {
    this._children = val;

    if (this._children.length > 0) {
      if (this._childId) {
        const children = this._children.filter(c => c.userId === this._childId);

        if (children && children.length) {
          this.onSelectChild(children[0]);
        } else {
          this.onSelectChild(this._children[0]);
        }
      } else {
        this.onSelectChild(this._children[0]);
      }
    }
  }

  get children(): any {
    return this._children.slice(0, 3);
  }

  get childrenMore(): any {
    return this._children.slice(3);
  }

  @Output() addChild = new EventEmitter<boolean>();
  @Output() selectChild = new EventEmitter<any>();

  @ViewChild('tabDropdown') tabDropdown: ElementRef;

  public selectedChild: any = {};
  public buttonOpacity: number = 0;
  public isTabDropdown: boolean = false;

  private _children: Array<any> = [];
  private _childId: string = '';

  addChildrenAllowed: boolean = false;

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params && params['uid']) {
        this._childId = params['uid'];
      }
    });
  }

  public onSelectChild(child: any) {
    this.selectedChild = child;
    this.selectChild.emit(this.selectedChild);
    this.isTabDropdown = false;
  }

  public clickAddChild() {
    this.addChild.emit(true);
    this.isTabDropdown = false;
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.tabDropdown && !this.tabDropdown?.nativeElement.contains(target)) {
      this.isTabDropdown = false;
    }
  }
}
