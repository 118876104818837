<div class="welcome-section teacher-welc">
  <div class="welcome-row teacher-welc">
    <div class="welcome-column teacher-welc">
      <h2 class="welcome-h2">{{ 'SHARED.WELCOME' | translate }}!</h2>
      <p class="welcome-text teacher-welc">Вы можете добавить учеников самостоятельно или это может сделать классный руководитель.</p>
      <div class="main-screen-buttons-row">
        <a class="button welcome-button teacher-welc w-button" routerLink="/schooladmin/new-class">Создать класс</a>
      </div>
    </div>

    <img
      src="/assets/images/dashboard/teacher-welcome.png"
      width="497"
      srcset="
        /assets/images/dashboard/teacher-welcome-p-500.png 500w,
        /assets/images/dashboard/teacher-welcome-p-800.png 800w,
        /assets/images/dashboard/teacher-welcome.png       994w
      "
      sizes="(max-width: 450px) 100vw, (max-width: 767px) 69vw, (max-width: 991px) 59vw, 497px"
      class="welcome-image teacher-welc"
    />
  </div>
</div>
