import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { UserAccessService } from 'app/shared/services/useraccess.service';
import { SharedService } from 'app/shared/shared.service';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'prf-values-results-page',
  templateUrl: './values-results-page.component.html',
  styleUrls: ['./values-results-page.component.scss'],
})
export class ValuesResultsPageComponent implements OnInit {
  public userRole: string = '';
  public urlSessionId: string = '';
  public userProfile: any;
  public showFullResults: boolean = false;
  loaded: boolean = false;

  mobileOpened: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private activatedRoute: ActivatedRoute, private sharedService: SharedService, private userAccessService: UserAccessService) {
    this.userRole = localStorage.getItem('userRole');
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params && params['ssid']) {
        this.urlSessionId = params['ssid'];
      }
    });
  }

  ngOnInit() {
    window.scroll(0, 0);
    if (this.userRole === 'parent') {
      this.sharedService
        .getUserInfoData()
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(data => {
          this.userProfile = data;
        });
    }

    this.userAccessService
      .getUserAccesses()
      .pipe(
        take(1),
        tap(userAccesses => {
          if (userAccesses) {
            let valuesTestId = TESTS_VARIANTS_IDS.valuesTest;
            let resultsAccess = userAccesses.find(el => el.screeningTestId === valuesTestId);
            this.showFullResults = resultsAccess.isTestResult === true;

            this.loaded = true;
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
