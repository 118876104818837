export enum EUserTags {
  /// Пользователь, самостоятельно попавший в систему
  Default = 0,
  /// Пользователь, зарегистрированный из дневника Московской области
  MoDairy = 1,
  /// Пользователь, попавший в систему из рассылки
  Emailing = 2,
  /// Пользователь TUMO
  Tumo = 3,
  /// Пользователь B2C
  b2c = 4,
  /// Пользователь Mos.ru
  MosRu = 5,
  /// Пользователь Просвещения
  Prosv = 6,
  /// Пользователь промо Воробьевы Горы
  VorobieviGori = 7,
}
