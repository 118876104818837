<div class="results-check-row">
  <!-- <div class="results-check-text">{{ 'SHARED.RESULT.S' | translate }}:</div> -->
  <div class="results-check-column">
    <div class="results-check-column-2">
      <div class="results-checkbox">
        <div
          (click)="onClickPupil()"
          [class.checked]="checkPupil && isCompleteTestPupil && !checkParent"
          class="results-checkbox-icon"
        ></div>
        <div>
          <div class="results-checkbox-name" [class.checked]="checkPupil && isCompleteTestPupil && !checkParent">
            <ng-container *ngIf="child && child.firstName; else childNotRegistered">
              <span>
                {{ child.firstName }}
              </span>
            </ng-container>
            <ng-template #childNotRegistered>
              <span>Ребенок</span>
            </ng-template>
          </div>
          <div class="results-checkbox-text">
            <span>{{ getStatusOnTesting(child, isCompleteTestPupil) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="results-check-column-2">
      <div class="results-checkbox">
        <div
          (click)="onClickParent()"
          [class.checked]="checkParent && isCompleteTestParent && !checkPupil"
          class="results-checkbox-icon"
        ></div>
        <div>
          <div class="results-checkbox-name" [class.checked]="checkParent && isCompleteTestParent && !checkPupil">
            <ng-container *ngIf="parent && parent.firstName; else parentNotRegistered">
              <span>{{ parent.firstName }}</span>
            </ng-container>
            <ng-template #parentNotRegistered>
              <span>Родитель</span>
            </ng-template>
          </div>
          <div class="results-checkbox-text">
            <span>{{ getStatusOnTesting(parent, isCompleteTestParent) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="results-check-column-2">
      <div class="results-checkbox">
        <div
          (click)="onClickAll()"
          [class.checked]="checkParent && isCompleteTestParent && checkPupil && isCompleteTestPupil"
          class="results-checkbox-icon"
        ></div>
        <div>
          <div class="results-checkbox-name" [class.checked]="checkParent && isCompleteTestParent && checkPupil && isCompleteTestPupil">
            <span>{{ 'SHARED.JOINT' | translate }}</span>
          </div>
          <div class="results-checkbox-text" [class.not-done]="!isCompleteTestParent">
            <span>{{ getJointDaysPassed() }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
