import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { ProfessionsService } from '../../../../shared/dashboard/professions/professions.service';
import { ResultsService } from '../../../results/results.service';
import { PupilPanelService } from '../pupil-panel.service';

@Component({
  selector: 'prf-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss'],
})
export class GoalsComponent implements OnInit {
  @Input() goalsProfessions: any;
  @Input() lastTestResults: any;

  sessionId: any;
  userId: any;
  weAreParent: boolean = false;
  skillsAttainment: any;
  skillsAttainmentData: any;
  subscription: any;
  favorites: any = [];
  profession: any;
  professionIdByUrl: string = '';
  showProfessions: boolean = false;
  dataLoaded: boolean = true;
  planLevel: number = 1;
  isActive: boolean;

  @ViewChild('dropProfession') drop: ElementRef;

  constructor(
    private resultService: ResultsService,
    private pupilPanelService: PupilPanelService,
    private router: Router,
    public route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      if (params['profession']) {
        this.professionIdByUrl = params['profession'];
      }
    });
  }

  ngOnInit() {
    this.sessionId = localStorage.getItem('testSessionId');
    this.userId = localStorage.getItem('userId');

    if (this.goalsProfessions) {
      if (this.professionIdByUrl) {
        const professions = this.goalsProfessions.filter(gp => gp.id === this.professionIdByUrl);
        if (professions && professions.length) {
          this.selectFavorite(professions[0]);
        }
      } else {
        this.profession = this.goalsProfessions[0];
        this.pupilPanelService.setGoalProfession(this.profession);
      }
    }
    if (this.lastTestResults) {
      this.parseResultData(this.lastTestResults);
    }
  }

  selectFavorite(favorite) {
    if (this.profession !== favorite) {
      this.selectMenu(1);
    }
    this.profession = favorite;
    this.pupilPanelService.setGoalProfession(this.profession);
  }

  openProfessions() {
    this.showProfessions = !this.showProfessions;
  }

  showProfession(job: IProfession) {
    this.router.navigate(['/professions', job.hrid]);
  }

  private parseResultData(results: any) {
    this.skillsAttainment = results.filter(d => d.objectType === 'SkillAttainment');
    this.skillsAttainmentData = this.resultService.transformSkills(this.skillsAttainment, this.sessionId);
    this.setColorsSkills(this.skillsAttainmentData);
  }

  public setColorsSkills(data) {
    data[0][0].color = '#f743de';
    data[0][1].color = '#fdcf00';
    data[0][2].color = '#36acff';
    data[0][3].color = '#13e5db';
    data[0][4].color = '#9375fb';
    data[0][5].color = '#fd651a';
    return data;
  }

  checkActive(event): boolean {
    return (this.isActive = event > 0);
  }

  public selectMenu(planLevel: number) {
    if (this.isActive) {
      this.planLevel = planLevel;
    }
  }

  @HostListener('document:click', ['$event'])
  checkClick(event) {
    if (this.drop && !this.drop?.nativeElement.contains(event.target)) {
      this.showProfessions = false;
    }
  }
}
