export interface ITutorDiagnosticInterface {
  name: string;
  description: string;
  screeningTestId: string;
  pupil: {
    testForPupil: boolean;
    isPupilStarted?: boolean;
    isPupilTested?: boolean;
  };
  parent: {
    testForParent: boolean;
    isParentStarted?: boolean;
    isParentTested?: boolean;
  };
  diagnosticOpened: boolean;
  diagnosticState: EDiagnStateEnum;
  statusText: string;
  isComplexDiagnostic: boolean;
}

export interface IPupilDiagnosticInterface {
  name: string;
  description: string;
  screeningTestId: string;
  diagnosticState: EDiagnStateEnum;
  route: string;
  results: string;
  createdDate?: string;
}

export enum EDiagnStateEnum {
  NOT_OPENED = 1,
  TEST_OPENED,
  IS_TESTED,
  RESULTS_OPENED,
}
