import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AnketaService } from 'app/pages/player/anketa/anketa.service';

@Component({
  selector: 'prf-tell-about',
  templateUrl: './tell-about.component.html',
  styleUrls: ['./tell-about.component.scss'],
})
export class TellAboutComponent {
  @Input() form: UntypedFormGroup;
  public age: number = 1;
  public isMaleGender: boolean = true;
  public myModel = '';
  public mask = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  public ageRange = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
  public classRange = [5, 6, 7, 8, 9, 10, 11];
  public classLetterRange = [
    'А',
    'Б',
    'В',
    'Г',
    'Д',
    'Е',
    'Ё',
    'Ж',
    'З',
    'И',
    'К',
    'Л',
    'М',
    'Н',
    'О',
    'П',
    'Р',
    'С',
    'Т',
    'У',
    'Ф',
    'Х',
    'Ц',
    'Ч',
    'Ш',
    'Э',
    'Ю',
    'Я',
  ];
  public cities = [];
  public schools = [];
  public school: string = null;

  constructor(private translate: TranslateService, private anketaService: AnketaService) {
    this.anketaService.getSchools().subscribe(schools => {
      this.schools = schools;
    });
  }

  public onAgeChanged(event): void {
    this.form.get('age').setValue(event.value);
  }

  public onClassChanged(event): void {
    this.form.get('classNumber').setValue(event.value);
  }

  public onClassLetterChanged(event): void {
    this.form.get('classLetter').setValue(event.value);
  }

  // public onAgeChanged(event): void {
  //   this.childAge = event.value;
  // }

  public changeGender() {
    if (setTimeout) {
      setTimeout(() => {
        this.isMaleGender = !this.isMaleGender;
      }, 300);
    } else {
      this.isMaleGender = !this.isMaleGender;
    }
  }

  public isRu() {
    return this.translate.currentLang === 'ru';
  }

  public onSchoolChanged(event) {
    this.school = event.value;
    let schoolNumberId = this.schools.find(s => s.number == this.school).id;
    this.form.get('schoolNumberId').setValue(schoolNumberId);
  }
}
