export const InnerMotivationFactors = [
  {
    name: 'MOTIVATION.INNER_MOTIVATION_FACTORS.CURIOSITY.NAME',
    value: null,
    descriptions: {
      low: 'MOTIVATION.INNER_MOTIVATION_FACTORS.CURIOSITY.DESCRIPTIONS.LOW',
      medium: 'MOTIVATION.INNER_MOTIVATION_FACTORS.CURIOSITY.DESCRIPTIONS.MEDIUM',
      high: 'MOTIVATION.INNER_MOTIVATION_FACTORS.CURIOSITY.DESCRIPTIONS.HIGH',
    },
  },
  {
    name: 'MOTIVATION.INNER_MOTIVATION_FACTORS.SKILLS_DEVELOPMENT.NAME',
    value: null,
    descriptions: {
      low: 'MOTIVATION.INNER_MOTIVATION_FACTORS.SKILLS_DEVELOPMENT.DESCRIPTIONS.LOW',
      medium: 'MOTIVATION.INNER_MOTIVATION_FACTORS.SKILLS_DEVELOPMENT.DESCRIPTIONS.MEDIUM',
      high: 'MOTIVATION.INNER_MOTIVATION_FACTORS.SKILLS_DEVELOPMENT.DESCRIPTIONS.HIGH',
    },
  },
  {
    name: 'MOTIVATION.INNER_MOTIVATION_FACTORS.PRODUCTIVITY.NAME',
    value: null,
    descriptions: {
      low: 'MOTIVATION.INNER_MOTIVATION_FACTORS.PRODUCTIVITY.DESCRIPTIONS.LOW',
      medium: 'MOTIVATION.INNER_MOTIVATION_FACTORS.PRODUCTIVITY.DESCRIPTIONS.MEDIUM',
      high: 'MOTIVATION.INNER_MOTIVATION_FACTORS.PRODUCTIVITY.DESCRIPTIONS.HIGH',
    },
  },
];

export const OuterMotivationFactors = [
  {
    name: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.AWARDS.NAME',
    value: null,
    descriptions: {
      low: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.AWARDS.DESCRIPTIONS.LOW',
      medium: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.AWARDS.DESCRIPTIONS.MEDIUM',
      high: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.AWARDS.DESCRIPTIONS.HIGH',
    },
  },
  {
    name: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.RULES.NAME',
    value: null,
    descriptions: {
      low: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.RULES.DESCRIPTIONS.LOW',
      medium: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.RULES.DESCRIPTIONS.MEDIUM',
      high: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.RULES.DESCRIPTIONS.HIGH',
    },
  },
  {
    name: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.BELIEFS.NAME',
    value: null,
    descriptions: {
      low: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.BELIEFS.DESCRIPTIONS.LOW',
      medium: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.BELIEFS.DESCRIPTIONS.MEDIUM',
      high: 'MOTIVATION.OUTER_MOTIVATION_FACTORS.BELIEFS.DESCRIPTIONS.HIGH',
    },
  },
];

export const MotivationsTextData = {
  amotivation: {
    low: 'MOTIVATION.MOTIVATIONS_TEXT_DATA.AMOTIVATION.LOW',
    medium: 'MOTIVATION.MOTIVATIONS_TEXT_DATA.AMOTIVATION.MEDIUM',
    high: 'MOTIVATION.MOTIVATIONS_TEXT_DATA.AMOTIVATION.HIGH',
  },
  outerMotivation: {
    low: 'MOTIVATION.MOTIVATIONS_TEXT_DATA.OUTER_MOTIVATION.LOW',
    medium: 'MOTIVATION.MOTIVATIONS_TEXT_DATA.OUTER_MOTIVATION.MEDIUM',
    high: 'MOTIVATION.MOTIVATIONS_TEXT_DATA.OUTER_MOTIVATION.HIGH',
  },
  innerMotivation: {
    low: 'MOTIVATION.MOTIVATIONS_TEXT_DATA.INNER_MOTIVATION.LOW',
    medium: 'MOTIVATION.MOTIVATIONS_TEXT_DATA.INNER_MOTIVATION.MEDIUM',
    high: 'MOTIVATION.MOTIVATIONS_TEXT_DATA.INNER_MOTIVATION.HIGH',
  },
};

export const RecommendationsTextData = {
  curiosity: {
    low: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.CURIOSITY.LOW',
    medium: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.CURIOSITY.MEDIUM',
    high: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.CURIOSITY.HIGH',
  },
  skillsDevelopment: {
    low: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.SKILLS_DEVELOPMENT.LOW',
    medium: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.SKILLS_DEVELOPMENT.MEDIUM',
    high: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.SKILLS_DEVELOPMENT.HIGH',
  },
  productivity: {
    low: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.PRODUCTIVITY.LOW',
    medium: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.PRODUCTIVITY.MEDIUM',
    high: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.PRODUCTIVITY.HIGH',
  },
  awards: {
    low: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.AWARDS.LOW',
    medium: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.AWARDS.MEDIUM',
    high: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.AWARDS.HIGH',
  },
  rules: {
    low: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.RULES.LOW',
    medium: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.RULES.MEDIUM',
    high: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.RULES.HIGH',
  },
  beliefs: {
    low: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.BELIEFS.LOW',
    medium: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.BELIEFS.MEDIUM',
    high: 'MOTIVATION.RECOMMENDATIONS_TEXT_DATA.BELIEFS.HIGH',
  },
};

export const AmotivationRecommendations = {
  low: [
    'MOTIVATION.AMOTIVATION_RECOMMENDATIONS.LOW.0',
    'MOTIVATION.AMOTIVATION_RECOMMENDATIONS.LOW.1',
    'MOTIVATION.AMOTIVATION_RECOMMENDATIONS.LOW.2',
  ],
  medium: [
    'MOTIVATION.AMOTIVATION_RECOMMENDATIONS.MEDIUM.0',
    'MOTIVATION.AMOTIVATION_RECOMMENDATIONS.MEDIUM.1',
    'MOTIVATION.AMOTIVATION_RECOMMENDATIONS.MEDIUM.2',
  ],
  high: ['MOTIVATION.AMOTIVATION_RECOMMENDATIONS.HIGH.0'],
};
