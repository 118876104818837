<div *ngIf="!isLoading" class="pupil-page">
  <!-- <prf-menu [root]="'pupil'"></prf-menu> -->
  <img class="logo_cornered" src="/assets/images/dashboard/profilum-new-logo.svg" alt="Profilum logo" />

  <ng-container *ngIf="isOnboarding; else dashboardPage">
    <prf-onboarding-steps (onboardingFinished)="finishOnboarding()"></prf-onboarding-steps>
  </ng-container>

  <ng-template #dashboardPage>
    <div class="navbar"></div>
    <div class="content-section">
      <prf-page-header [title]="title" [showMobile]="false"></prf-page-header>
      <div class="container vertical-container">
        <section class="diagnostics-tabs-section">
          <div class="diagnostics-tabs-wrapper">
            <div class="diagnostics-tabs-container">
              <div class="diagnostic-tab-wrapper" *ngFor="let diagnostic of diagnosticsList">
                <div class="diagnostic-tab" [style.background]="diagnostic.diagnosticState === 3 ? '#FAFAFA' : '#FFFFFF'">
                  <div *ngIf="diagnostic.diagnosticState >= 3" class="diagnostic-status">
                    <div class="status-container" #statusContainer>
                      <a class="status-icon" (click)="isMobile ? showHintMobile(statusContainer, popup) : none">
                        <img *ngIf="diagnostic.diagnosticState <= 3" src="/assets/images/icons/clock.svg" alt="Is waiting" />
                        <img *ngIf="diagnostic.diagnosticState > 3" src="/assets/images/icons/diag-completed.svg" alt="" />
                      </a>

                      <div class="status-hint info-popup" #popup>
                        <div class="popup-container">
                          <p *ngIf="diagnostic.diagnosticState <= 3">
                            {{ diagnosticsHints.awaitMeeting }}
                          </p>
                          <p *ngIf="diagnostic.diagnosticState > 3">
                            {{ diagnosticsHints.resultsAvailable }}
                          </p>
                          <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="diagnostic-header">
                    <div class="diagnostic-name">{{ diagnostic.name }}</div>
                    <div class="diagnostic-description">{{ diagnostic.description }}</div>
                  </div>

                  <div class="diagnostic-buttons">
                    <ng-container [ngSwitch]="true">
                      <div *ngSwitchCase="diagnostic.diagnosticState === 2">
                        <button class="diagnostic-button button-blue" (click)="routingTest(diagnostic)">
                          {{ 'PUPIL.PASS_DIAGNOSTIC' | translate }}
                        </button>
                      </div>
                      <div *ngSwitchCase="diagnostic.diagnosticState === 3">
                        <button
                          *ngIf="diagnostic.screeningTestId === diagnosticsIds.passportOfTalents; else noResults"
                          class="diagnostic-button button-simple"
                          (click)="openResultsPage(diagnostic)"
                        >
                          {{ 'PUPIL.VIEW_SHORT_RESULTS' | translate }}
                        </button>
                        <ng-template #noResults>
                          <div class="disabled-text">
                            {{ 'PUPIL.TUTOR_SHOW_RESULTS' | translate }}
                          </div>
                        </ng-template>
                      </div>

                      <div *ngSwitchCase="diagnostic.diagnosticState === 4">
                        <button class="diagnostic-button button-simple" (click)="openResultsPage(diagnostic)">
                          {{ 'PUPIL.VIEW_RESULTS' | translate }}
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </section>

        <section class="status-section">
          <div
            class="status-bar-container"
            [class.vertical-align]="window.innerWidth <= 991"
            [style.background]="
              window.innerWidth > 991
                ? dashboardStatus === dashboardStatuses.action
                  ? 'right bottom / cover no-repeat url(/assets/images/pupil/status_action.svg) #EEF1FE '
                  : 'right bottom / cover no-repeat url(/assets/images/pupil/status_studing.svg) #FFEFCF '
                : dashboardStatus === dashboardStatuses.action
                ? 'calc(50% - 250px) bottom / auto no-repeat url(/assets/images/pupil/status_action.svg)  #EEF1FE'
                : 'calc(50% - 250px) bottom / auto no-repeat url(/assets/images/pupil/status_studing.svg) #FFEFCF '
            "
          >
            <div class="status-title" [innerHTML]="dashboardStatus.title"></div>

            <div class="status-text" [innerHTML]="dashboardStatus.text"></div>

            <!-- <div class="picture-container">
              <img src="/assets/images/pupil/status_studing.svg" alt="Process studing" />
              <img src="/assets/images/pupil/status_action.svg" alt="Process action" />
            </div> -->
          </div>
        </section>

        <!-- </div> -->

        <prf-page-footer></prf-page-footer>
      </div>

      <!-- <div class="content-section">
    <div class="container" >
      <prf-page-header
        [title]="'Путь к профессии твоей мечты'"
        [isWelcome]="true"
      ></prf-page-header> 

    <prf-welcome-steps></prf-welcome-steps> 
     

      <ng-container *ngIf="!isWelcome">
        <prf-goals
          [lastTestResults]="lastTestResults"
          [goalsProfessions]="goalsProfessions"
        ></prf-goals>
      </ng-container> 
    </div>
  </div> -->
    </div>
  </ng-template>
</div>
