import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'prf-test-switch',
  templateUrl: './test-switch.component.html',
  styleUrls: ['./test-switch.component.scss'],
})
export class TestSwitchComponent {
  @Input() set child(val: any) {
    if (val) {
      this._child = val;
      this._child['daysPassed'] = this._child.lastSession?.createdDate ? this.calculateDays(this._child.lastSession.createdDate) : null;
    }
  }
  get child() {
    return this._child;
  }
  @Input() set parent(val: any) {
    if (val) {
      this._parent = val;
      this._parent['daysPassed'] = this.parent.lastSession?.createdDate ? this.calculateDays(this._parent.lastSession.createdDate) : null;
    }
  }
  get parent() {
    return this._parent;
  }
  @Input() checkPupil: boolean = false;
  @Input() checkParent: boolean = false;
  @Input() isCompleteTestPupil: boolean = false;
  @Input() isCompleteTestParent: boolean = false;

  @Output() clickPupil = new EventEmitter<boolean>();
  @Output() clickParent = new EventEmitter<boolean>();

  private _child: any;
  private _parent: any;
  public userRole: string = '';
  translations: string[];

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private translateService: TranslateService) {
    this.userRole = localStorage.getItem('userRole');
    this.getTranslations([
      'SHARED.TEST_NOT_PASSED',
      'SHARED.PARENT',
      'SHARED.NOT_TESTING',
      'SHARED.TEST_PASSED',
      'SHARED.TEST_NOT_PASSED_PARENT',
    ])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.translations = translations;
      });
  }

  public onClickPupil() {
    if (this.isCompleteTestPupil) {
      this.checkPupil = true;
      this.checkParent = false;

      this.clickPupil.emit(this.checkPupil);
      this.clickParent.emit(this.checkParent);
    }
  }

  public onClickParent() {
    if (this.isCompleteTestParent) {
      this.checkParent = true;
      this.checkPupil = false;

      this.clickParent.emit(this.checkParent);
      this.clickPupil.emit(this.checkPupil);
    }
  }

  public onClickAll() {
    if (this.isCompleteTestPupil && this.isCompleteTestParent) {
      this.checkPupil = true;
      this.checkParent = true;

      this.clickPupil.emit(this.checkPupil);
      this.clickParent.emit(this.checkParent);
    }
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  public getCompletedStatusDescription() {
    return !this.isCompleteTestParent && !this.isCompleteTestPupil
      ? this.translations['SHARED.TEST_NOT_PASSED'].toLowerCase()
      : !this.isCompleteTestParent && this.isCompleteTestPupil
      ? this.translations['SHARED.TEST_NOT_PASSED_PARENT'].toLowerCase()
      : this.isCompleteTestParent && !this.isCompleteTestPupil
      ? this.child?.firstName + ' ' + (this.child?.gender == 'F' ? 'не прошла тест' : 'не прошел тест')
      : this.translations['SHARED.TEST_PASSED'];
  }

  calculateDays(date: string) {
    if (date) {
      let daysPassed = Math.round((new Date().getTime() - Date.parse(date)) / 1000 / 60 / 60 / 24);
      return daysPassed;
    }
  }

  getJointDaysPassed() {
    if (this.isCompleteTestParent && this.isCompleteTestPupil) {
      return Math.min(this.child?.daysPassed, this.parent?.daysPassed) + ' дней назад';
    } else {
      return 'диагностика не пройдена';
    }
  }

  getStatusOnTesting(user: any, testCompleted: boolean) {
    if (testCompleted) {
      return user.daysPassed === 0 ? 'пройдена сегодня' : user.daysPassed + ' дней назад';
    }
    return 'диагностика не пройдена';
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
