<ng-container *ngIf="loadedCategory">
  <prf-profession-search (searchesData)="dataSearch($event)"></prf-profession-search>

  <div class="professions-filters">
    <prf-profession-filter [categoryList]="categoryList" (selectedCategory)="getProfessionsByCategory($event)"> </prf-profession-filter>
    <prf-profession-order (selectedOrder)="getProfessionsByOrder($event)"> </prf-profession-order>
  </div>

  <ng-container *ngIf="(!professionListFiltered || professionListFiltered.length == 0) && loading; else professionsList">
    <prf-profession-content-loader></prf-profession-content-loader>
  </ng-container>

  <ng-template #professionsList>
    <div class="catalog-professions-row" *ngIf="professionListFilteredView && professionListFilteredView.length > 0; else emptyProfessions">
      <prf-profession-card-new *ngFor="let profession of professionListFilteredView" [profession]="profession"> </prf-profession-card-new>
    </div>
  </ng-template>
</ng-container>

<ng-template #emptyProfessions>
  <div class="event-empty-block">
    <div>
      <img src="/assets/images/menu/grey/Professions.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">
      {{ 'SHARED.FOR_YOU_REQUEST_NOT_PROFESSIONS' | translate }}
    </div>
  </div>
</ng-template>
