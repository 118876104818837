<prf-menu *ngIf="userRole === 'pupil'"></prf-menu>

<div class="content-section" *ngIf="userRole === 'pupil'; else portfolioSection">
  <prf-page-header *ngIf="userRole === 'pupil'" [title]="'Моё портфолио'"></prf-page-header>
  <div class="container prof-page-cont">
    <ng-container *ngIf="loaded">
      <div class="portfolio-section">
        <ng-container *ngTemplateOutlet="portfolioSection"></ng-container>
      </div>
    </ng-container>

    <prf-page-footer></prf-page-footer>
  </div>
</div>

<ng-template #welcomePortfolio>
  <div class="welcome-section-portfolio" *ngIf="!isChild; else welcomeRowPotfolio">
    <ng-container *ngTemplateOutlet="welcomeRowPotfolio"></ng-container>
  </div>
</ng-template>

<ng-template #welcomeRowPotfolio>
  <div class="welcome-row-portfolio">
    <div class="welcome-column-portfolio">
      <h2 class="welcome-h2">
        {{ userRole === 'pupil' ? 'Добавь свои достижения' : 'Добавьте достижения ученика' }}
      </h2>
      <p class="welcome-text-portfolio">
        {{
          userRole === 'pupil'
            ? 'Здесь будут храниться все твои достижения по различным направлениям.\n' +
              '          Участвуй в различных конкурсах, олимпиадах, стажировках и других мероприятиях, а результаты добавляй сюда.\n' +
              '          Чем активнее ты участвуешь в мероприятиях, тем лучше будет для твоей будущей профессии.'
            : 'Здесь будут храниться все добавленные достижения ученика по различным направлениям. Список документов будет доступен и в личном кабинете ученика. Все добавленные документы можно в любой момент скачать или отредактировать.'
        }}
      </p>
      <div class="main-screen-buttons-row">
        <a (click)="showModal()" class="button welcome-button-portfolio w-button">{{ 'SHARED.ADD_ACHIEVEMENT' | translate }}</a>
      </div>
    </div>
    <img *ngIf="isMale(userGender)" src="/assets/images/dashboard/Portfolio-m.svg" width="380" class="welcome-image-portfolio" />
    <img *ngIf="!isMale(userGender)" src="/assets/images/dashboard/Portfolio-w.svg" width="380" class="welcome-image-portfolio" />
  </div>
</ng-template>

<ng-template #portfolioSection>
  <ng-container *ngIf="portfolios && portfolios.files.length > 0; else welcomePortfolio">
    <h2 class="portfolio-h2">
      {{ userRole === 'pupil' ? 'Добавь свои достижения' : 'Достижения ученика' }}
    </h2>
    <p class="portfolio-text">
      {{
        userRole === 'pupil'
          ? 'Участвуй в различных конкурсах, олимпиадах, стажировках и других мероприятиях, а результаты добавляй сюда. Чем активнее ты участвуешь в мероприятиях, тем лучше будет для твоей будущей профессии.'
          : 'Добавляйте результаты ученика в различных конкурсах, олимпиадах, стажировках и других мероприятиях. Чем активнее ученик участвует в мероприятиях, тем лучше будет для его будущей профессии.'
      }}
    </p>
    <div class="portfolio-row">
      <div *ngFor="let file of portfolios.files" class="portfolio-column">
        <prf-portfolio-card [file]="file"></prf-portfolio-card>
      </div>

      <div class="portfolio-column">
        <a (click)="showModal()" class="add-achievement w-inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
            <rect id="svg_1" fill-opacity="0" fill="black" height="40" width="40" />
            <path
              id="svg_2"
              fill="#9375FB"
              d="m0,20c0,11.0457 8.9543,20 20,20c11.0457,0 20,-8.9543 20,-20c0,-11.04568 -8.9543,-20 -20,-20c-11.0457,0 -20,8.95432 -20,20zm38,0c0,9.9411 -8.0589,18 -18,18c-9.9411,0 -18,-8.0589 -18,-18c0,-9.9411 8.0589,-18 18,-18c9.9411,0 18,8.0589 18,18z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            />
            <path
              id="svg_3"
              fill="#9375FB"
              d="m21,19l6,0c0.5523,0 1,0.4477 1,1c0,0.5523 -0.4477,1 -1,1l-6,0l0,6c0,0.5523 -0.4477,1 -1,1c-0.5523,0 -1,-0.4477 -1,-1l0,-6l-6,0c-0.5523,0 -1,-0.4477 -1,-1c0,-0.5523 0.4477,-1 1,-1l6,0l0,-6c0,-0.5523 0.4477,-1 1,-1c0.5523,0 1,0.4477 1,1l0,6z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            />
          </svg>
          <!--              <img src="/assets/images/icons/add-achievement-icon.svg" class="icon-initial-state">-->
          <div>{{ 'SHARED.ADD_ACHIEVEMENT' | translate }}</div>
        </a>
      </div>
    </div>
  </ng-container>
</ng-template>

<prf-portfolio-add-modal></prf-portfolio-add-modal>
