import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MetroColors } from 'app/pages/catalog/courses-page/all-courses/active-courses/add-course/select-metro/metro-colors';
import { SharedService } from 'app/shared/shared.service';
import { COURSES_TYPES } from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CourseDetailsService } from '../../../../../catalog/courses-page-without-filters/course-details/course-details.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';

@Component({
  selector: 'prf-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
})
export class PlanCardComponent implements OnInit, OnDestroy {
  @Input() course: any;
  @Input() staticTask: boolean = false;
  @Input() index: number = 0;
  types = COURSES_TYPES;
  currentType: any = { color: '' };
  isFavorite: boolean = false;
  isCourse: boolean;
  favoritesCourses: any;
  userId: string;
  metroColors: MetroColors = new MetroColors();
  moment: any = moment;

  @ViewChild('heart') heart: ElementRef;
  @ViewChild('heartActive') heartActive: ElementRef;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private courseDetailsService: CourseDetailsService,
    private router: Router,
    private sharedService: SharedService,
    private favoritesService: FavoritesService,
  ) {
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.isCourse = this.course.classesFormat === 'LongCourse' || this.course.classesFormat === 'ShortCourse';
    this.currentType = this.getTypeCourse(this.course.classesFormat);
    this.userId = localStorage.getItem('userId');
    this.favoritesService
      .getFavoriteCourses()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        this.favoritesCourses = data;
        if (this.favoritesCourses) {
          let productIds = this.favoritesCourses.filter(el => el.productId === this.course.id);
          this.isFavorite = productIds.length > 0;
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  calculateRealPrice() {
    return this.courseDetailsService.calculateRealPrice(this.course);
  }

  private getTypeCourse(value: string) {
    if (value == undefined) {
      value = 'OpenDay';
    }
    let type = this.types.filter(el => el.value === value);
    return type[0];
  }

  loadFavorites(): Observable<any> {
    return this.favoritesService.getUserFavorites(this.userId, FavoritesTypes.Class).pipe(
      tap(r => {
        this.favoritesCourses = r;
        this.favoritesService.setFavoriteCourses(r);
      }),
    );
  }

  addToFavorite() {
    this.favoritesService
      .addToFavorites(this.course.id, this.userId, FavoritesTypes.Class)
      .pipe(
        switchMap(r => {
          return this.loadFavorites();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  removeFavorite() {
    if (this.favoritesCourses) {
      let filteredFavorites = this.favoritesCourses.filter(el => el.productId === this.course.id);
      let courseFavoriteIds = filteredFavorites.map(el => el.id);
      if (courseFavoriteIds) {
        courseFavoriteIds.forEach(favoriteId =>
          this.favoritesService
            .deleteFavorite(favoriteId)
            .pipe(
              switchMap(r => {
                return this.loadFavorites();
              }),
              takeUntil(this.ngUnsubscribe$),
            )
            .subscribe(),
        );
        this.isFavorite = false;
      }
    }
  }

  checkAction(event: any) {
    switch (true) {
      case this.heart && this.heart.nativeElement.contains(event.target):
        this.addToFavorite();
        break;
      case this.heartActive && this.heartActive.nativeElement.contains(event.target):
        this.removeFavorite();
        break;
      default:
        this.isCourse ? this.router.navigate(['/courses', this.course.hrid]) : this.router.navigate(['/events', this.course.hrid]);
        break;
    }
  }
}
