<ng-container>
  <ng-container *ngIf="userRole === 'pupil' || userRole === 'parent'">
    <prf-parent-test-panel
      *ngIf="sessionStatus !== 'Success' && userRole !== 'schooladmin' && userRole !== 'parent'"
      [pupilUserId]="userId"
      [isPupil]="true"
    >
    </prf-parent-test-panel>
  </ng-container>
  <h2 *ngIf="userRole === 'parent'" class="professions-h2">
    {{ 'RECOMMENDATIONS.PROFESSIONS_FIT_CHILD' | translate }}
  </h2>
  <h2 *ngIf="userRole === 'pupil'" class="professions-h2">
    {{ 'RECOMMENDATIONS.FIT_PROFESSIONS_CHILD' | translate }}
  </h2>
  <ng-container *ngIf="recommendedProfessions">
    <ng-container *ngFor="let recommendedProfessionIndustry of recommendedProfessionsArray">
      <h2 class="professions-h2 rec">{{ recommendedProfessionIndustry.industry }}</h2>
      <p class="recommendations-text" [innerHTML]="recommendedProfessionIndustry.professions[0].fieldDescription"></p>
      <div class="professions-row recom">
        <ng-container
          *ngFor="let profession of recommendedProfessionIndustry.professions"
          [ngTemplateOutlet]="recommendedProfessionCardTemplate"
          [ngTemplateOutletContext]="{ $implicit: profession }"
        ></ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #recommendedProfessionCardTemplate let-profession>
  <prf-recommended-profession-card
    class="professions-column-2"
    [profession]="profession"
    [isGoal]="profession.isGoal"
    [userRole]="userRole"
    [transformedDescription]="removeTags(profession?.shortDescription)"
    [isSalaryShown]="showSalary"
    [isProfessionChecked]="checkProfession(profession)"
    (professionClickOutput)="navigateProfessions($event)"
    (removeFavoriteClickOutput)="removeFavorite($event)"
    (addFavoriteClickOutput)="addToFavorite($event)"
  ></prf-recommended-profession-card>
</ng-template>
