<prf-content-loader *ngIf="!pageLoader && !resultsFetched"></prf-content-loader>

<ng-container *ngIf="resultsFetched">
  <div *ngIf="resultsFetched" class="results-section">
    <ng-container
      *ngIf="!nullResults && sessionId && (userRole === 'pupil' || userRole === 'parent' || userRole === 'tutor'); else emptyResults"
    >
      <div class="test-result-block">
        <div class="test-results-row-container">
          <div class="test-results-heading-text">
            <h1 class="test-result-h1">Учебная мотивация</h1>
            <p class="test-result-text _w-700">
              Хорошая мотивация – один из ключевых залогов успеха человека в любом деле. В международной практике существует семь основных
              видов мотивации – от амотивации до наград. Познакомься с результатами своего теста и нашими рекомендациями. Помни, что важную
              роль в долгосрочной мотивации играет внутренняя – именно она поможет тебе любить то, чем ты занимаешься, и развиваться в
              выбранном занятии.
            </p>
          </div>
        </div>
      </div>

      <div class="test-result-block motivation-tabs" *ngIf="motivationData.length === 3 && !mobileOpened">
        <button
          class="tab-item"
          [class.mobile]="isMobile"
          [class.active]="selectedMotivation === t"
          [style.background-position-y.px]="isMobile ? 210 - (210 * motivation.value) / 100 : 260 - (260 * motivation.value) / 100"
          *ngFor="let motivation of motivationData; let t = index"
          (click)="changeMotivSection(t)"
        >
          <div class="tab-item-value">{{ motivation.value }}%</div>
          <div class="tab-item-name">
            {{ motivation.title }}
          </div>
        </button>
      </div>

      <ng-container *ngIf="isMobile && !mobileOpened">
        <div class="mobile-recommendations">
          <div class="motiv-section-recommendations">
            <h1 class="recommendations-title">Как повысить уровень мотивации</h1>
            <div class="recommendations-main">
              <div class="recommendations-picture">
                <img src="/assets/images/results/motivation-results/recommendations-pic-mobile.svg" alt="Recommendations picture" />
              </div>
              <div class="recommendations-info-wrapper">
                <ul class="recommendations-list">
                  <li
                    class="recommendation-item"
                    *ngFor="let recommendationAdvice of amotivationRecommendations[checkFactorRatio(motivationData[0].value)]"
                  >
                    {{ recommendationAdvice | translate }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="motivation-swiper-wrapper" [ngClass]="{ hidden: isMobile }">
          <span [ngSwitch]="selectedMotivation">
          <div *ngSwitchCase="motivationTypes.AMOTIVATION">
            <ng-container *ngTemplateOutlet="amotivationSection"></ng-container>
          </div>
          <div *ngSwitchCase="motivationTypes.INNER_MOTIVATION">
            <ng-container *ngTemplateOutlet="innerMotivationSection"></ng-container>
          </div>
          <div *ngSwitchCase="motivationTypes.OUTER_MOTIVATION">
            <ng-container *ngTemplateOutlet="outerMotivationSection"></ng-container>
          </div>
        </span>
      </div>
    </ng-container>
  </div>
</ng-container>

<div class="mobile-overlay" [ngClass]="{ opened: mobileOpened }">
  <div class="motivation-swiper-wrapper">
    <a class="close" (click)="closeMobileSlide()">
      <img src="/assets/images/results/motivation-results/close-cross.svg" alt="" />
    </a>
    <ng-container [ngSwitch]="mobileSlideNumber">
      <div *ngSwitchCase="0">
        <div
          class="motivation-bar-mobile motivation-bar-mobile_first"
          [style.background-position-y.px]="145 - (145 * motivationData[0].value) / 100"
        >
          <div class="tab-item-value">{{ motivationData[0].value }}%</div>
          <div class="tab-item-name">
            {{ motivationData[0].title }}
          </div>
        </div>
        <ng-container *ngTemplateOutlet="amotivationSection"> </ng-container>
      </div>
      <div *ngSwitchCase="1">
        <div
          class="motivation-bar-mobile motivation-bar-mobile_second"
          [style.background-position-y.px]="145 - (145 * motivationData[1].value) / 100"
        >
          <div class="tab-item-value">{{ motivationData[1].value }}%</div>
          <div class="tab-item-name">
            {{ motivationData[1].title }}
          </div>
        </div>
        <ng-container *ngTemplateOutlet="innerMotivationSection"></ng-container>
      </div>
      <div *ngSwitchCase="2">
        <div
          class="motivation-bar-mobile motivation-bar-mobile_third"
          [style.background-position-y.px]="145 - (145 * motivationData[2].value) / 100"
        >
          <div class="tab-item-value">{{ motivationData[2].value }}%</div>
          <div class="tab-item-name">
            {{ motivationData[2].title }}
          </div>
        </div>
        <ng-container *ngTemplateOutlet="outerMotivationSection"></ng-container>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #amotivationSection>
  <div class="motiv-section-header">
    <h1 class="motiv-section-title" *ngIf="!isMobile">Амотивация</h1>
    <p class="motiv-section-text">
      {{ motivationsTextData.amotivation[checkFactorRatio(motivationData[0].value)] | translate }}
    </p>
  </div>
  <div class="motiv-section-recommendations">
    <h1 class="recommendations-title">Как повысить уровень мотивации</h1>
    <div class="recommendations-main">
      <div class="recommendations-picture" [class.mobile]="isMobile">
        <img
          *ngIf="!isMobile"
          class=""
          src="/assets/images/results/motivation-results/recommendations-pic.svg"
          alt="Recommendations picture"
        />
        <img
          *ngIf="isMobile"
          class=""
          src="/assets/images/results/motivation-results/recommendations-pic-mobile.svg"
          alt="Recommendations picture"
        />
      </div>
      <div class="recommendations-info-wrapper">
        <ul class="recommendations-list">
          <li
            class="recommendation-item"
            *ngFor="let recommendationAdvice of amotivationRecommendations[checkFactorRatio(motivationData[0].value)]"
          >
            {{ recommendationAdvice | translate }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #innerMotivationSection>
  <div class="motiv-section-header">
    <h1 *ngIf="!isMobile" class="motiv-section-title">Внутренняя мотивация</h1>
    <p class="motiv-section-text">
      {{ motivationsTextData.innerMotivation[checkFactorRatio(motivationData[1].value)] | translate }}
    </p>
  </div>
  <div class="motiv-section-sliders">
    <div *ngIf="!isMobile" class="motiv-section-prompt">Для просмотра одного из показателей ниже нажмите на него</div>
    <div *ngIf="isMobile" class="motiv-section-prompt">Показатели</div>
    <div class="motiv-sliders-list">
      <div class="motiv-slider-item" *ngFor="let factor of innerMotivationFactors; let i = index" (click)="changeInMotDescriptionText(i)">
        <div class="factor-name">
          {{ factor.name | translate }}
        </div>
        <div class="factor-bar">
          <div class="factor-bar-line" #factorBarLineEl_inner>
            <div class="factor-bar-sprite-indent" [style.width]="factor.value + '%'"></div>
          </div>
          <div class="factor-bar-value">{{ factor.value }}</div>
        </div>
        <div *ngIf="isMobile" class="factor-descr-mobile">
          {{ factor.descriptions[checkFactorRatio(innerMotivationFactors[i].value)] | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="!isMobile" class="motiv-sliders-description">
      <div class="swiper-descrIn-prev">
        <mat-icon svgIcon="prev" class="mat-icon" vertical-align="middle" placement="center"></mat-icon>
      </div>
      <div class="swiper-descrIn-next">
        <mat-icon svgIcon="next" class="mat-icon" vertical-align="middle" placement="center"></mat-icon>
      </div>
      <swiper-container
        prfSwiperElement
        init="false"
        class="description-swiper"
        #swiperDescriptionInMotElement
        [config]="swiperDescriptionInMotConfig"
      >
        <swiper-slide class="swiper-item swiper-slide"  *ngFor="let factor of innerMotivationFactors; let k = index">
          <div class="motiv-factor-name">
            {{ factor.name | translate}}
          </div>
          <div class="motiv-factor-description">
            {{ factor.descriptions[checkFactorRatio(innerMotivationFactors[k].value)] | translate}}
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
  <div class="motiv-section-recommendations">
    <h1 class="recommendations-title">Как повысить уровень мотивации</h1>
    <div class="recommendations-main">
      <div class="recommendations-picture" [class.mobile]="isMobile">
        <img
          *ngIf="!isMobile"
          class=""
          src="/assets/images/results/motivation-results/recommendations-pic.svg"
          alt="Recommendations picture"
        />
        <img
          *ngIf="isMobile"
          class=""
          src="/assets/images/results/motivation-results/recommendations-pic-mobile.svg"
          alt="Recommendations picture"
        />
      </div>
      <div class="recommendations-info-wrapper">
        <ng-container [ngSwitch]="isSliderForRecom(motivationTypes.INNER_MOTIVATION)">
          <ng-container *ngSwitchCase="true">
            <swiper-container
              prfSwiperElement
              init="false"
              class="recommendations-swiper"
              #swiperRecommendationsInMotElement
              [config]="swiperRecommendationsInMotConfig"
            >
              <swiper-slide class="swiper-item recommendation-item swiper-slide">
                {{ recommendationsTextData.curiosity[checkFactorRatio(innerMotivationFactors[0].value)] | translate}}
              </swiper-slide>
              <swiper-slide class="swiper-item recommendation-item swiper-slide">
                {{ recommendationsTextData.skillsDevelopment[checkFactorRatio(innerMotivationFactors[1].value)] | translate}}
              </swiper-slide>
              <swiper-slide class="swiper-item recommendation-item swiper-slide">
                {{ recommendationsTextData.productivity[checkFactorRatio(innerMotivationFactors[2].value)] | translate}}
              </swiper-slide>
            </swiper-container>
            <ng-template #recomInNavigation>
              <div class="swiper-navigation">
                <div class="swiper-recomIn-prev">
                  <mat-icon svgIcon="prev" class="mat-icon" vertical-align="middle" placement="center"></mat-icon>
                </div>
                <div class="navigation-text">Другие рекомендации</div>
                <div class="swiper-recomIn-next">
                  <mat-icon svgIcon="next" class="mat-icon" vertical-align="middle" placement="center"></mat-icon>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <ul class="recommendations-list">
              <li class="recommendation-item">
                {{ recommendationsTextData.curiosity[checkFactorRatio(innerMotivationFactors[0].value)] | translate }}
              </li>
              <li class="recommendation-item">
                {{ recommendationsTextData.skillsDevelopment[checkFactorRatio(innerMotivationFactors[1].value)] | translate }}
              </li>
              <li class="recommendation-item">
                {{ recommendationsTextData.productivity[checkFactorRatio(innerMotivationFactors[2].value)] | translate }}
              </li>
            </ul>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #outerMotivationSection>
  <div class="motiv-section-header">
    <h1 class="motiv-section-title" *ngIf="!isMobile">Внешняя мотивация</h1>
    <p class="motiv-section-text">
      {{ motivationsTextData.outerMotivation[checkFactorRatio(motivationData[0].value)] | translate }}
    </p>
  </div>
  <div class="motiv-section-sliders">
    <div *ngIf="!isMobile" class="motiv-section-prompt">Для просмотра одного из показателей ниже нажмите на него</div>
    <div *ngIf="isMobile" class="motiv-section-prompt">Показатели</div>
    <div class="motiv-sliders-list">
      <div class="motiv-slider-item" *ngFor="let factor of outerMotivationFactors; let j = index" (click)="changeOutMotDescriptionText(j)">
        <div class="factor-name">
          {{ factor.name | translate }}
        </div>
        <div class="factor-bar">
          <div class="factor-bar-line" #factorBarLineEl_outer>
            <div class="factor-bar-sprite-indent" [style.width]="factor.value + '%'"></div>
          </div>
          <div class="factor-bar-value">{{ factor.value }}</div>
        </div>
        <div *ngIf="isMobile" class="factor-descr-mobile">
          {{ factor.descriptions[checkFactorRatio(outerMotivationFactors[j].value)] | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="!isMobile" class="motiv-sliders-description">
      <div class="swiper-descrOut-prev">
        <mat-icon svgIcon="prev" class="mat-icon" vertical-align="middle" placement="center"></mat-icon>
      </div>
      <div class="swiper-descrOut-next">
        <mat-icon svgIcon="next" class="mat-icon" vertical-align="middle" placement="center"></mat-icon>
      </div>
      <swiper-container
        prfSwiperElement
        init="false"
        class="description-swiper"
        #swiperDescriptionOutMotElement
        [config]="swiperDescriptionOutMotConfig"
      >
        <swiper-slide class="swiper-item swiper-slide" *ngFor="let factor of outerMotivationFactors; let k = index">
          <div class="motiv-factor-name">
            {{ factor.name | translate }}
          </div>
          <div class="motiv-factor-description">
            {{ factor.descriptions[checkFactorRatio(outerMotivationFactors[k].value)] | translate }}
          </div>
        </swiper-slide>

      </swiper-container>

    </div>
  </div>
  <div class="motiv-section-recommendations">
    <h1 class="recommendations-title">Как повысить уровень мотивации</h1>
    <div class="recommendations-main">
      <div class="recommendations-picture" [class.mobile]="isMobile">
        <img
          *ngIf="!isMobile"
          class=""
          src="/assets/images/results/motivation-results/recommendations-pic.svg"
          alt="Recommendations picture"
        />
        <img
          *ngIf="isMobile"
          class=""
          src="/assets/images/results/motivation-results/recommendations-pic-mobile.svg"
          alt="Recommendations picture"
        />
      </div>
      <div class="recommendations-info-wrapper">
        <ng-container [ngSwitch]="isSliderForRecom(motivationTypes.OUTER_MOTIVATION)">
          <ng-container *ngSwitchCase="true">
            <swiper-container
              prfSwiperElement
              init="false"
              class="recommendations-swiper"
              #swiperRecommendationsOutMotElement
              [config]="swiperRecommendationsOutMotConfig"
            >
              <swiper-slide class="swiper-item recommendation-item swiper-slide">
                {{ recommendationsTextData.awards[checkFactorRatio(outerMotivationFactors[0].value)] | translate}}
                <ng-container *ngTemplateOutlet="recomOutNavigation"></ng-container>
              </swiper-slide>
              <swiper-slide class="swiper-item recommendation-item swiper-slide">
                {{ recommendationsTextData.rules[checkFactorRatio(outerMotivationFactors[1].value)] | translate}}
                <ng-container *ngTemplateOutlet="recomOutNavigation"></ng-container>
              </swiper-slide>
              <swiper-slide class="swiper-item recommendation-item swiper-slide">
                {{ recommendationsTextData.beliefs[checkFactorRatio(outerMotivationFactors[2].value)] | translate}}
                <ng-container *ngTemplateOutlet="recomOutNavigation"></ng-container>
              </swiper-slide>
            </swiper-container>
            <ng-template #recomOutNavigation>
              <div class="swiper-navigation">
                <div class="swiper-recomOut-prev">
                  <mat-icon svgIcon="prev" class="mat-icon" vertical-align="middle" placement="center"></mat-icon>
                </div>
                <div class="navigation-text">Другие рекомендации</div>
                <div class="swiper-recomOut-next">
                  <mat-icon svgIcon="next" class="mat-icon" vertical-align="middle" placement="center"></mat-icon>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <ul class="recommendations-list">
              <li class="recommendation-item">
                {{ recommendationsTextData.awards[checkFactorRatio(outerMotivationFactors[0].value)] | translate }}
              </li>
              <li class="recommendation-item">
                {{ recommendationsTextData.rules[checkFactorRatio(outerMotivationFactors[1].value)] | translate }}
              </li>
              <li class="recommendation-item">
                {{ recommendationsTextData.beliefs[checkFactorRatio(outerMotivationFactors[2].value)] | translate }}
              </li>
            </ul>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emptyResults>
  <div class="results-empty-block">
    <div>
      <img src="/assets/images/menu/grey/Test.svg" width="80" class="no-profession-icon" />
    </div>
    <div class="event-empty-block-text">Ваш ребенок еще не прошел тест</div>
  </div>
</ng-template>
