import { Component, HostListener, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'prf-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements OnInit {
  public internships: any[];
  public userRole: string;

  public isMobile: boolean = false;

  constructor(private meta: Meta, private router: Router) {
    this.isMobile = window.innerWidth <= 450;
  }

  ngOnInit() {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isMobile = event.target.innerWidth <= 450;
  }
}
