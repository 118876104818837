import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { take } from 'rxjs/operators';
import { OverlayBusyService } from '../../../overlay-busy/overlay-busy.service';
import { ProfessionsService } from '../professions.service';
import { AppSettingsService } from '../../../services/appsettings.service';

@Component({
  selector: 'prf-similar-professions',
  templateUrl: './similar-professions.component.html',
  styleUrls: ['./similar-professions.component.scss'],
})
export class SimilarProfessionsComponent implements OnInit {
  profession: IProfession;
  similarJobs: IProfession[];
  dataFetched: boolean = false;
  public userRole: string = '';

  baseUrl: string = AppSettingsService.settings.staticFiles.base;

  constructor(private professionService: ProfessionsService, private overlayService: OverlayBusyService, private router: Router) {
    this.userRole = localStorage.getItem('userRole');
  }

  ngOnInit() {
    this.professionService.getCurrentProfession().subscribe(job => {
      if (job) {
        this.profession = job;
        this.overlayService.show();
        let arr = this.createParams(this.profession.fieldNames);
        this.profession.fieldNames.forEach((field, i) => this.getJob(arr[i]));
      }
    });
  }

  private createParams(fields) {
    let result = [];

    switch (fields.length) {
      case 1:
        result = [{ field: fields[0], from: 0, to: 3 }];
        break;
      case 2:
        result = [
          { field: fields[0], from: 0, to: 2 },
          { field: fields[1], from: 0, to: 1 },
        ];
        break;
      case 3:
        result = [
          { field: fields[0], from: 0, to: 1 },
          { field: fields[1], from: 0, to: 1 },
          { field: fields[2], from: 0, to: 1 },
        ];
        break;
    }
    return result;
  }

  private getJob(params) {
    let filters = this.setFilters({ Fields: params.field });
    let sub = this.professionService
      .acquireProfessions(filters, params.to, params.from)
      .pipe(take(1))
      .subscribe(jobs => {
        if (this.profession && this.profession.id) {
          this.similarJobs = jobs.filter(j => j.id !== this.profession.id);
        } else {
          this.similarJobs = jobs;
        }
        this.overlayService.hide();
        this.dataFetched = true;
      });
  }

  setFilters(filtersObject) {
    let regionIdValue: string;
    let filtersResultObject: any = filtersObject;
    switch (this.userRole) {
      case 'tutor':
        regionIdValue = '00000000-0000-0000-0000-000000000000';
        filtersResultObject = Object.assign(filtersResultObject, { RegionId: regionIdValue });
        break;
      default:
        let isPilot = localStorage.getItem('isPilot') == 'true' ? true : false;
        let regionId = localStorage.getItem('regionId');
        regionIdValue = regionId && regionId != 'null' && !isPilot ? regionId : '00000000-0000-0000-0000-000000000000';
        filtersResultObject = Object.assign(filtersResultObject, { RegionId: regionIdValue });

        if (!isPilot) {
          let municipalityId = localStorage.getItem('municipalityId');
          let municipalityIdValue = municipalityId && municipalityId != 'null' ? municipalityId : null;
          filtersResultObject = Object.assign(filtersResultObject, {
            MunicipalityId: municipalityIdValue,
          });
        }
    }
    return filtersResultObject;
  }

  showProfession(job) {
    this.router.navigate(['/professions', job.hrid]);
  }
}
