<div class="login-section">
  <img class="logo_cornered" src="/assets/images/dashboard/profilum-new-logo.svg" alt="Profilum logo" />

  <div class="login-container">
    <div class="login-content">
      <div class="login-data">
        <div class="h1-login-text" [innerHTML]="loginTitleText | translate"></div>
        <div class="login-form-container">
          <form *ngIf="!showInfoBlock; else codeSent" [formGroup]="form" action="">
            <p class="login-text">{{ loginFormText | translate }}</p>
            <ng-container [ngSwitch]="true">
              <ng-container *ngSwitchCase="loginFormVariant === loginForms.BY_PHONE || loginFormVariant === loginForms.ADD_PHONE">
                <div class="login-form-block">
                  <div class="login-input-container">
                    <label [class.topped-out]="phoneLabel" for="phone" class="login-label">{{ 'LOGIN.PHONE' | translate }}</label>

                    <div class="input-wrapper">
                      <input
                        class="login-input w-input"
                        [class.error-field]="form.get('phone').errors && checkPhone"
                        id="phone"
                        maxlength="25"
                        type="text"
                        formControlName="phone"
                        [textMask]="{ mask: phoneMaskUsed }"
                        (focusin)="phoneLabel = true; checkPhone = false"
                        (focusout)="form.get('phone').value ? null : (phoneLabel = false); checkPhone = true"
                      />

                      <a *ngIf="form.get('phone').errors?.notFound" (click)="clearInput('phone')" class="input-clear">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L9 9" stroke="#FF5722" stroke-width="2" stroke-linecap="round" />
                          <path d="M9.00003 1L1.00003 9" stroke="#FF5722" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </a>
                    </div>

                    <ng-container *ngIf="form.get('phone').errors as errors">
                      <div *ngIf="errors.notFound" class="alert-message">
                        {{ 'LOGIN.NUMBER_NOT_REGISTERED' | translate }}
                      </div>
                      <div *ngIf="errors.noUserPhone" class="alert-message">
                        {{ 'LOGIN.NO_USER_PHONE' | translate }}
                      </div>

                      <div *ngIf="errors.notExist" class="alert-message">
                        {{ 'LOGIN.NUMBER_IS_NOT_EXIST' | translate }}
                      </div>

                      <div *ngIf="errors.isUsed" class="alert-message">
                        {{ 'LOGIN.NUMBER_IS_USED' | translate }}
                      </div>
                      <div *ngIf="errors.limitFailed" class="alert-message">
                        {{ 'LOGIN.LIMIT_IS_EXCEEDED' | translate }}
                      </div>
                      <div *ngIf="errors.failed" class="alert-message">
                        {{ 'LOGIN.FAILED' | translate }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="promt-text">
                    <a (click)="changeEmailReg()" class="promt-link">
                      {{ promptText | translate }}
                    </a>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="loginFormVariant === loginForms.CODE_INPUT || loginFormVariant === loginForms.ADD_CODE_INPUT">
                <div class="login-form-block">
                  <div class="login-input-container">
                    <label [class.topped-out]="codeLabel" for="codeInput" class="login-label">{{ 'LOGIN.PASSWORD' | translate }}</label>
                    <div class="input-wrapper">
                      <input
                        [type]="isMaskedPassword ? 'password' : 'text'"
                        class="login-input w-input"
                        [class.error-field]="form.get('code').errors"
                        id="codeInput"
                        maxlength="25"
                        formControlName="code"
                        (focusin)="codeLabel = true"
                        (focusout)="form.get('code').value ? null : (codeLabel = false)"
                      />
                      <div [ngClass]="{ eye: !isMaskedPassword, eye__closed: isMaskedPassword }" (click)="toggleMask()"></div>
                    </div>
                    <ng-container *ngIf="form.get('code').errors">
                      <div *ngIf="form.get('code').errors?.notCorrect" class="alert-message">
                        {{ 'LOGIN.PASS_NOT_SUITS' | translate }}
                      </div>
                      <div *ngIf="form.get('code').errors?.needSupport" class="alert-message">
                        {{ 'LOGIN.CONNECT_TO_SUPPORT' | translate }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="promt-text">
                    <span *ngIf="activeTimer" class="timer-value" [style.margin-right]="'12px'">{{ timer$ | async }}</span
                    ><span>
                      <a *ngIf="smsAllowed" (click)="sendPasswordTwice()" [class.inactive]="activeTimer" class="promt-link">
                        {{ promptText | translate }}
                      </a>
                      <div *ngIf="!smsAllowed" class="promt-link-wrapper">
                        <a (click)="supportRequest($event)" [class.inactive]="activeTimer" class="promt-link">
                          {{ 'LOGIN.WRITE_TO_SUPPORT' | translate }}
                        </a>
                        <div [style.display]="supportAlert ? 'block' : 'none'" class="info-popup" #infopopup>
                          <p>Вы можете связаться с поддержкой через чат</p>
                          <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="loginFormVariant === loginForms.BY_EMAIL">
                <div class="login-form-block">
                  <div class="login-input-container">
                    <label [class.topped-out]="emailLabel" for="emailInput" class="login-label">{{ 'LOGIN.EMAIL' | translate }}</label>
                    <div class="input-wrapper">
                      <input
                        class="login-input w-input"
                        [class.error-field]="!checkEmail || passFailed || anotherForm || unRegistered"
                        id="emailInput"
                        maxlength="70"
                        type="text"
                        formControlName="email"
                        (focusin)="emailLabel = true; checkEmail = true; passFailed = false; anotherForm = false; unRegistered = false"
                        (focusout)="form.get('email').value ? null : (emailLabel = false); checkFormatEmail($event)"
                      />
                      <!-- пока не используем на почте -->
                      <a *ngIf="false" (click)="clearInput('email')" class="input-clear">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L9 9" stroke="#FF5722" stroke-width="2" stroke-linecap="round" />
                          <path d="M9.00003 1L1.00003 9" stroke="#FF5722" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </a>
                    </div>
                    <ng-container *ngIf="!checkEmail">
                      <div class="alert-message">{{ 'LOGIN.CHECK_EMAIL' | translate }}</div>
                    </ng-container>
                    <ng-container *ngIf="passFailed">
                      <div class="alert-message">
                        {{ 'LOGIN.EMAIL_ENTRANCE_NOT_POSSIBLE' | translate }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="anotherForm">
                      <div class="alert-message" [innerHTML]="'LOGIN.ANOTHER_LOGIN_FORM' | translate : { link: pathToForm }"></div>
                    </ng-container>
                    <ng-container *ngIf="unRegistered">
                      <div class="alert-message" [innerHTML]="'LOGIN.UNREGISTERED' | translate : { link: pathToForm }"></div>
                    </ng-container>
                  </div>
                  <div class="promt-text" *ngIf="!unRegistered && !anotherForm">
                    <a (click)="changePhoneReg()" class="promt-link">
                      {{ 'LOGIN.ENTER_BY_PHONE' | translate }}
                    </a>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="loginFormVariant === loginForms.PASSWORD_INPUT">
                <div class="login-form-block">
                  <div class="login-input-container">
                    <label [class.topped-out]="passLabel" for="passInput" class="login-label">{{ 'LOGIN.PASSWORD' | translate }}</label>
                    <div class="input-wrapper">
                      <input
                        [type]="isMaskedPassword ? 'password' : 'text'"
                        class="login-input w-input"
                        [class.error-field]="form.get('password').errors"
                        id="passInput"
                        maxlength="25"
                        formControlName="password"
                        (ngModelChange)="isValidPassword($event)"
                        (focusin)="passLabel = true"
                        (focusout)="form.get('password').value ? null : (passLabel = false)"
                      />
                      <div [ngClass]="{ eye: !isMaskedPassword, eye__closed: isMaskedPassword }" (click)="toggleMask()"></div>
                      <!--<div
                        class="info-popup recommendation w-hidden-small w-hidden-tiny hide-popup"
                        #popupPassword
                        [style.display]="isNotValidPassword ? 'block' : 'none'"
                      >
                        <p>Требования к паролю (не менее)</p>
                        <ol class="info-exacting">
                          <li class="info-pswrd-icon info-popup-pswrd" [class.not-correct]="isCharactersError">6 знаков/символов</li>
                          <li class="info-pswrd-icon info-popup-letter" [class.not-correct]="isLetterError">1 строчная буква</li>
                          <li class="info-pswrd-icon info-popup-digit" [class.not-correct]="isNumberError">1 цифра</li>
                        </ol>
                        <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                      </div>-->
                    </div>
                    <ng-container *ngIf="form.get('password').errors">
                      <div class="alert-message">
                        {{ 'LOGIN.PASSWORD_INCORRECT' | translate }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="promt-text">
                    <a (click)="routingRePassword()" class="promt-link">
                      {{ 'LOGIN.NOT_REMEMBER_PASS' | translate }}
                    </a>
                  </div>
                </div>
              </ng-container>

              <button
                class="submit-button button-blue"
                (click)="submit()"
                [class.inactive]="!submitAllowed() || anotherForm || unRegistered"
                [class.loading]="isProcessing"
              >
                <ng-container *ngIf="isProcessing">
                  <prf-loader-button></prf-loader-button>
                </ng-container>
                <ng-container *ngIf="!isProcessing">{{ 'LOGIN.TO_CONFIRM' | translate }}</ng-container>
              </button>
            </ng-container>
          </form>
        </div>
      </div>

      <div class="login-picture">
        <img src="/assets/images/login_profilum.svg" alt="registration Profilum" />
      </div>
    </div>

    <div class="tabs login w-tabs"></div>
  </div>
  <prf-page-footer></prf-page-footer>
</div>

<ng-template #codeSent>
  <h4>Сообщение отправлено</h4>
  <p>Если ничего не пришло – обратитесь в поддержку</p>
  <div class="button-container">
    <button class="button-blue" type="button" (click)="toggleInfoBlock()">
      {{ 'BUTTONS.BACK_TO_LOGIN' | translate }}
    </button>
    <button class="button-simple" type="button" (click)="openOnlineConsultant($event)">
      {{ 'BUTTONS.CONTACT_THE_SUPPORT' | translate }}
    </button>
  </div>
</ng-template>
