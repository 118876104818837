<div class="invite-child-section">
  <div class="invite-child-container">
    <div *ngIf="!childRegistered && !childTested" class="invite-child-text">Отправьте вашему подростку ссылку на диагностику</div>
    <div *ngIf="childRegistered && !childTested" class="invite-child-text _reg">
      Ваш подросток не прошёл диагностику.<br />
      Отправить ссылку повторно?
    </div>
    <div *ngIf="childTested" class="invite-child-text _completed">
      Вы и ваш подросток прошли диагностику.<br />
      Запланируйте встречу с экспертом Профилума.
    </div>
    <div *ngIf="!childTested" class="button-container">
      <button type="button" class="invite-child-button button-blue" (click)="openPopup()">Отправить</button>
    </div>
    <div *ngIf="childTested" class="button-container">
      <a class="invite-child-button button-blue" href="https://{{ whatsAppLink }}" target="_blank"> Запланировать </a>
    </div>
  </div>
</div>

<ng-container *ngIf="showPopUp">
  <div class="popup-overlay-new overlay">
    <div class="popup-container">
      <a (click)="close()" class="popup-close"><img src="/assets/images/icons/close_popup.svg" alt="close" /></a>
      <ng-container *ngIf="childInviteStep !== 3">
        <div class="popup-title">Отправить на этот номер?</div>
        <div class="popup-content">
          <div class="content-text">
            <div class="popup-text _1">На указанный номер мы отправим ссылку для входа и прохождения диагностики</div>
            <form #f="ngForm" [formGroup]="phone">
              <div class="popup-number">
                <div class="input-wrapper">
                  <input
                    class="popup-input"
                    [class.error-field]="phone.errors && phoneChecked"
                    id="phone"
                    type="text"
                    [formControl]="phone"
                    [textMask]="{ mask: phoneMask }"
                    (focusout)="checkPhone()"
                    (focusin)="phoneChecked = false"
                  />
                </div>

                <ng-container *ngIf="phone.errors && phoneChecked">
                  <div *ngIf="phone.errors.isUsed" class="alert-message">
                    {{ 'REGISTRATION.NUMBER_IS_USED' | translate }}
                  </div>
                  <div *ngIf="phone.errors?.notValid" class="alert-message">Проверьте правильность номера телефона</div>
                </ng-container>
              </div>
            </form>
            <div *ngIf="childInviteStep === 1" class="popup-buttons">
              <a class="popup-button _white" (click)="sendToChild()"> Отправить </a>
              <a class="popup-button button-blue _blue" (click)="editPhone()"> Редактировать </a>
            </div>
            <div *ngIf="childInviteStep === 2" class="popup-buttons">
              <a class="popup-button _white" [class.disabled]="!phone.valid" (click)="sendToChild()"> Отправить </a>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="childInviteStep === 3">
        <!-- <div class="popup-title">
          <span class="text-symbol">👍</span><br />
          Проверьте, пришла <br />ли ссылка вашему подростку
        </div> -->
        <div class="popup-title">Ссылка скопирована 👍</div>
        <br />
        <div class="popup-text w-center">Передайте ее подростку</div>
        <div class="popup-content">
          <div class="content-text">
            <div class="popup-text _1">
              <!-- В течение пяти минут на указанный номер придёт сообщение. Если не пришло, свяжитесь с
              координатором -->
            </div>
            <div class="popup-buttons" [style.margin-top]="'48px'">
              <a class="popup-button button-blue _blue" (click)="close()"> Понятно </a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
