import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IFilterInternships } from 'app/pages/catalog/internship-page/internship-catalog/internship-filter/internships-filter.service';
import { AdminPanelService } from 'app/pages/control-panel/admin/admin-panel.service';
import { MunicipalitiesService } from 'app/pages/control-panel/admin/municipalities/municipalities.service';
import { RegionsService } from 'app/pages/control-panel/admin/regions/regions.service';
import { SchoolAdminPanelService } from 'app/pages/control-panel/schooladmin/schooladmin-panel.service';
import { SharedService } from 'app/shared/shared.service';
import { Internship } from 'app/shared/classes/internship.class';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { BaseGuid } from 'app/shared/global-constants/constants';

import { IInstitution } from 'app/shared/interfaces/IInstitution.interface';
import { IInternship } from 'app/shared/interfaces/iinternships.interface';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InternshipsService } from '../../../internships.service';
import { INTERNSHIP_FORMAT } from '../internship/internship-details/internship-details.component';

export const COMPLEXITYS = [
  { value: 'Introductory', viewValue: 'Начальный' },
  { value: 'Base', viewValue: 'Базовый' },
  { value: 'InDepth', viewValue: 'Продвинутый' },
  { value: 'PreProfessional', viewValue: 'Предпрофессиональный' },
];
export const DELIVERY = [
  { value: 'Offline', viewValue: 'Очный' },
  { value: 'Online', viewValue: 'Онлайн' },
  { value: 'Mixed', viewValue: 'Смешанный' },
];

@Component({
  selector: 'prf-add-internship',
  templateUrl: './add-internship.component.html',
  styleUrls: ['./add-internship.component.scss'],
})
export class AddInternshipComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted: boolean;
  public internship: Internship = new Internship();
  public school: ISchool;
  public showInfoPopup: boolean = false;
  public showAgeInfo: boolean = false;
  public filters: IFilterInternships;
  public timeMask = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  public selectedFormat: any;
  public complexitys = COMPLEXITYS;
  public selectedComplexity: any;
  public delivery = DELIVERY;
  public selectedDelivery: any;
  public selectedFiles: File;
  public image;
  public selectedThemes: any;
  public selectedTalents: any;
  public moment: any = moment;
  public userRole: string;
  public isRegular: boolean;
  public currentMinAge: number;
  public currentMaxAge: number;
  public img: string = '/assets/images/icons/add-achievement-icon.svg';
  public institution: IInstitution;
  public checkhrid: boolean;
  format: any;
  public internshipFormats = INTERNSHIP_FORMAT;
  public saveToActive = false;

  //объекты территориальной привязки
  public allRegions: IRegion[] = [];
  public selectedRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };
  public municipalitiesByRegion: IMunicipality[] = [];
  public selectedMunicipality: IMunicipality = {
    id: '00000000-0000-0000-0000-000000000000',
    hrid: '',
    name: '',
    regionId: '',
  };

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    public router: Router,
    public route: ActivatedRoute,
    private internshipsService: InternshipsService,
    private utilsService: UtilsService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private adminPanelService: AdminPanelService,
    private schoolAdminPanelService: SchoolAdminPanelService,
    private sharedService: SharedService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Добавление стажировки' });

    this.moment = moment;
    this.moment.locale('ru');
    this.internship.id = this.utilsService.newGuid();
    this.userRole = localStorage.getItem('userRole');
  }

  ngOnInit() {
    this.form = this.fb.group({
      id: new UntypedFormControl(this.internship.id, []),
      createdDate: new UntypedFormControl(this.internship.createdDate, []),
      updatedDate: new UntypedFormControl(this.internship.updatedDate, []),
      name: new UntypedFormControl(this.internship.name, [Validators.required]),
      departamentName: new UntypedFormControl(this.internship.departamentName, [Validators.required]),
      companyId: new UntypedFormControl(this.internship.companyId),
      description: new UntypedFormControl(this.internship.description, [Validators.required]),
      hrid: new UntypedFormControl(this.internship.hrid, [Validators.required]),
      candidates: new UntypedFormControl(this.internship.candidates, [Validators.required]),
      duties: new UntypedFormControl(this.internship.duties, [Validators.required]),
      totalPlaces: new UntypedFormControl(0, []),
      specialities: new UntypedFormControl(this.internship.specialities, []),
      talents: new UntypedFormControl(this.internship.talents, []),
      courses: new UntypedFormControl(this.internship.courses, []),
      startDate: new UntypedFormControl(this.internship.startDate, [Validators.required]),
      closeDate: new UntypedFormControl(this.internship.closeDate, [Validators.required]),
      address: new UntypedFormControl(this.internship.address, []),
      link: new UntypedFormControl(this.internship.link, []),
      deadline: new UntypedFormControl(this.internship.deadline, [Validators.required]),
      mainImagePath: new UntypedFormControl(this.internship.mainImagePath, []),
      format: new UntypedFormControl(this.internship.format, [Validators.required]),
      blockerExercises: new UntypedFormControl(this.internship.blockerExercises, []),
      signUpList: new UntypedFormControl(this.internship.signUpList),
      isActive: new UntypedFormControl(this.internship.isActive, []),
    });
    if (this.userRole == 'schooladmin') {
      this.sharedService
        .getSchool()
        .pipe(take(1))
        .subscribe(school => (this.school = school));
    }
    //this.getRegions();
  }

  get f() {
    return this.form.controls;
  }

  dataThemes(data) {
    this.form.value.courses = data;
    this.selectedThemes = data;
  }

  dataTalents(data) {
    this.form.value.talents = data;
    this.selectedTalents = data;
  }

  async uploadLogoInternship(event) {
    if (event.target.files.item(0).type.match('image.*')) {
      this.selectedFiles = event.target.files;
      this.image = await this.adminPanelService.uploadImage(this.selectedFiles, this.internship.id);
    } else {
      this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
    }
  }

  parseDate(dateString) {
    if (dateString) {
      return new Date(dateString);
    } else {
      return null;
    }
  }

  setInternshipFormat(obj) {
    if (obj) {
      this.form.value.format = obj.value;
      this.form.controls['format'].setValue(obj.value);
      this.format = obj;
    }
  }

  checkInternshipFormatHeader() {
    if (this.format) {
      let format = INTERNSHIP_FORMAT.filter(el => el.value === this.format.value);
      return format[0].viewValue;
    } else {
      return 'Формат стажировки';
    }
  }

  submit() {
    this.submitted = true;

    this.internshipsService.checkHrid(this.form.value.hrid).pipe(
      switchMap(r => {
        this.checkhrid = r;

        if (this.form.valid && this.checkhrid) {
          let companyId = localStorage.getItem('companyId');
          let date = moment(new Date()).format('YYYY-MM-DD');

          this.internship = {
            id: this.form.value.id,
            hrid: this.form.value.hrid,
            address: this.form.value.address,
            description: this.form.value.description,
            departamentName: this.form.value.departamentName,
            companyId: companyId,
            candidates: this.form.value.candidates,
            duties: this.form.value.duties,
            name: this.form.value.name,
            totalPlaces: this.form.value.totalPlaces,
            link: this.form.value.link,
            mainImagePath: this.form.value.mainImagePath,
            specialities: this.form.value.specialities,
            talents: this.selectedTalents,
            courses: this.selectedThemes,
            blockerExercises: this.form.value.blockerExercises,
            signUpList: BaseGuid,
            deadline: this.form.value.deadline,
            startDate: this.form.value.startDate,
            closeDate: this.form.value.closeDate,
            createdDate: date,
            updatedDate: date,
            format: this.form.value.format,
            isActive: this.saveToActive,
          };

          return this.internshipsService.createInternshipsDO(this.internship).pipe(
            tap(() => {
              this.utilsService.openSnackBar('👌 Стажировка добавлена, вы будете перенаправлены на страницу данной стажировки', 'success');
              return setTimeout(_ => {
                this.router.navigate(['/' + this.userRole + '/internships/' + this.form.value.hrid]);
              }, 2000);
            }),
          );
        } else {
          return this.getTranslation('SHARED.ERROR_MSG').pipe(
            take(1),
            tap(translation => this.utilsService.openSnackBar(translation, 'error')),
          );
        }
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  //todo: неиспользуемые методы - удалить потом

  getRegions() {
    this.regionsService
      .getAllRegions()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.allRegions = r.filter(region => region.id != '00000000-0000-0000-0000-000000000000');
      });
  }

  public clearMunicipality() {
    this.form.get('municipality').setValue(this.selectedMunicipality);
    return;
  }

  setFormat(obj) {
    if (obj) {
      this.selectedFormat = obj;
    }
  }

  setComplexity(obj) {
    if (obj) {
      this.selectedComplexity = obj;
    }
  }

  checkComplexityHeader() {
    if (this.selectedComplexity) {
      let town = this.complexitys.filter(el => el.value === this.selectedComplexity.value);
      return town[0].viewValue;
    } else {
      return 'Сложность стажировки';
    }
  }

  setDelivery(obj) {
    if (obj) {
      this.selectedDelivery = obj;
    }
  }

  checkDeliveryHeader() {
    if (this.selectedDelivery) {
      let town = this.delivery.filter(el => el.value === this.selectedDelivery.value);
      return town[0].viewValue;
    } else {
      return 'Формат стажировки';
    }
  }

  async selectRegion(region) {
    if (region) {
      if (region != this.selectedRegion) {
        this.clearMunicipality();
      }
      this.selectedRegion = region;
      this.municipalitiesService
        .getMunicipalitiesByRegion(region.id)
        .pipe(take(1))
        .subscribe(r => (this.municipalitiesByRegion = r));
    }
    return;
  }

  // diffAges() {
  //   if (this.currentMinAge > this.currentMaxAge) {
  //     return (this.currentMinAge = this.currentMaxAge);
  //   }
  // }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
