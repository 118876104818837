<div class="event-page-section create">
  <h1 class="event-h1">{{ 'SHARED.ADDING' | translate }}</h1>
  <div class="create-event-form w-form">
    <form [formGroup]="form">
      <a
        class="event-image w-inline-block"
        (mouseover)="img = '/assets/images/icons/add-prof.svg'"
        (mouseleave)="img = '/assets/images/icons/add-achievement-icon.svg'"
        (click)="upload.click()"
        [ngStyle]="{ 'background-image': image ? 'url(' + image + ')' : '' }"
      >
        <div class="add-event-image">
          <div class="profession-icon">
            <img [src]="img" src="/assets/images/icons/add-achievement-icon.svg" class="icon-initial-state" />
          </div>
          <div>Добавить фото</div>
        </div>
      </a>

      <input type="file" (change)="uploadLogoInternship($event)" #upload hidden />
      <div class="event-image-text">Рекомендуем загружать качественные фото. Его увидят тысячи учеников и родителей.</div>
      <h2 class="event-h2">Основная информация</h2>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Название*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="name"
            data-name="name"
            [class.error-field]="submitted && f.name.errors"
            placeholder="Название"
            id="name"
            formControlName="name"
          />
          <ng-container *ngIf="submitted && f.name.errors">
            <mat-error *ngIf="f.name.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="hrid">HRID стажировки*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            [class.error-field]="(submitted && f.hrid.errors) || (submitted && !checkhrid)"
            maxlength="256"
            name="hrid"
            placeholder="HRID стажировки"
            id="hrid"
            formControlName="hrid"
          />
          <ng-container *ngIf="submitted && f.hrid.errors">
            <mat-error *ngIf="f.hrid.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
          <ng-container *ngIf="submitted && !checkhrid">
            <mat-error *ngIf="!checkhrid">
              {{ 'SHARED.HRID_IS_BUSY' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="departamentName">Департамент*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="departamentName"
            data-name="departamentName"
            [class.error-field]="submitted && f.departamentName.errors"
            placeholder="Название департамента в предложном падеже"
            id="departamentName"
            formControlName="departamentName"
          />
          <ng-container *ngIf="submitted && f.departamentName.errors">
            <mat-error *ngIf="f.departamentName.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-event-row">
          <div class="create-form-column _1">
            <label class="create-form-label" for="startDate">Дата проведения*</label>
          </div>

          <input
            matInput
            [matDatepicker]="picker"
            formControlName="startDate"
            class="create-event-field date w-input"
            [class.error-field]="submitted && f.startDate.errors"
            maxlength="256"
            name="startDate"
            data-name="startDate"
            id="startDate"
            #myDate
            [value]="internship.startDate | date : 'yyyy-MM-dd'"
            (input)="internship.startDate = parseDate($event.target.value)"
            placeholder="Начало"
            autocomplete="off"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>

          <input
            matInput
            [matDatepicker]="picker2"
            formControlName="closeDate"
            class="create-event-field date w-input"
            [class.error-field]="submitted && f.closeDate.errors"
            maxlength="256"
            name="closeDate"
            data-name="closeDate"
            id="closeDate"
            #myDate
            [value]="internship.closeDate | date : 'yyyy-MM-dd'"
            (input)="internship.closeDate = parseDate($event.target.value)"
            placeholder="Конец"
            autocomplete="off"
          />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-event-row">
          <div class="create-form-column _1">
            <label class="create-form-label" for="deadline">Дедлайн подачи*</label>
          </div>
          <input
            matInput
            [matDatepicker]="picker3"
            formControlName="deadline"
            class="create-event-field date w-input"
            [class.error-field]="submitted && f.deadline.errors"
            maxlength="256"
            name="deadline"
            data-name="deadline"
            id="startDate"
            #myDate
            [value]="internship.deadline | date : 'yyyy-MM-dd'"
            (input)="internship.deadline = parseDate($event.target.value)"
            placeholder="Заявки до"
            autocomplete="off"
          />
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="candidates">Кого готовы взять*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="candidates"
            data-name="candidates"
            [class.error-field]="submitted && f.candidates.errors"
            placeholder="Описание кандидатов"
            id="candidates"
            formControlName="candidates"
          />
          <ng-container *ngIf="submitted && f.candidates.errors">
            <mat-error *ngIf="f.candidates.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="duties">Обязанности*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="duties"
            data-name="duties"
            [class.error-field]="submitted && f.duties.errors"
            placeholder="Кратное описание по пунктам"
            id="duties"
            formControlName="duties"
          />
          <ng-container *ngIf="submitted && f.duties.errors">
            <mat-error *ngIf="f.duties.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="description">Описание и план*</label>
        </div>
        <div class="create-form-column">
          <quill-editor
            id="description"
            height="250px"
            name="description"
            placeholder="Описание"
            formControlName="description"
            [class.error-field]="submitted && f.description.errors"
          >
          </quill-editor>
          <ng-container *ngIf="submitted && f.description.errors">
            <mat-error *ngIf="f.description.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Специальность*</label>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Тематики*</label>
        </div>
        <prf-select-themes [existingThemes]="internship.courses" (dataSelectThemes)="dataThemes($event)"> </prf-select-themes>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Таланты*</label>
        </div>
        <prf-select-talents [existingTalents]="internship.talents" (dataSelectTalents)="dataTalents($event)"> </prf-select-talents>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Количество мест*</label>
        </div>
        <input
          class="create-event-field time w-input"
          type="number"
          [class.error-field]="submitted && f.totalPlaces.errors"
          min="0"
          name="totalPlaces"
          data-name="totalPlaces"
          id="totalPlaces"
          formControlName="totalPlaces"
        />
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Формат*</label>
        </div>
        <div class="create-form-column" #ddFormat>
          <div class="professions-dropdown w-dropdown">
            <div class="professions-dropdown-toggle w-dropdown-toggle" (click)="typesFormats.classList.toggle('w--open')">
              <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
              <div>{{ checkInternshipFormatHeader() }}</div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #typesFormats>
              <div class="dd-prof-filter-div">
                <div class="scroll-block">
                  <div class="prof-categories-links">
                    <a class="prof-categories-link w-inline-block" *ngFor="let type of internshipFormats">
                      <div class="category-text-link" (click)="setInternshipFormat(type); typesFormats.classList.remove('w--open')">
                        {{ type.viewValue }}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </nav>
            <ng-container *ngIf="submitted && f.format.errors">
              <mat-error *ngIf="f.format.errors.required">
                {{ 'SHARED.FORM.FILL_INPUT' | translate }}
              </mat-error>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="address">Место проведения</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="address"
            data-name="address"
            id="address"
            formControlName="address"
            placeholder="Город и адрес"
          />
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="link">Ссылка</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="link"
            data-name="link"
            id="link"
            formControlName="link"
            placeholder="Ссылка на описание стажировки"
          />
        </div>
      </div>

      <input
        (click)="saveToActive = false; submit()"
        type="submit"
        value="Сохранить в неопубликованное"
        class="white-button edit-event w-button"
      />
      <input (click)="saveToActive = true; submit()" type="submit" value="Опубликовать" class="button green-btn w-button" />
    </form>
    <div class="w-form-done">
      <div>Thank you! Your submission has been received!</div>
    </div>
    <div class="w-form-fail">
      <div>Oops! Something went wrong while submitting the form.</div>
    </div>
  </div>
</div>
