import { Injectable } from '@angular/core';

@Injectable()
export class HyphensService {
  private voiceGroups = {
    vowel: 'аеёиоуыэюя',
    voiced: 'бвгджзлмнрхцчшщ',
    deaf: 'кпстф',
    brief: 'й',
    other: 'ьъ',
    cons: 'бвгджзйклмнпрстфхцчшщ',
  };

  private isNotLastSep = remainStr => {
    let is = false;
    for (let i = 0; i < remainStr.length; i++) {
      if (this.voiceGroups.vowel.indexOf(remainStr.substr(i, 1)) !== -1) {
        is = true;
        break;
      }
    }
    return is;
  };

  public getSeparatedString = s => {
    function addSep() {
      sepArr.push(tmpS);
      tmpS = '';
    }

    let tmpL = '';
    let tmpS = '';
    const sepArr = [];
    for (let i = 0; i < s.length; i++) {
      tmpL = s.substr(i, 1);
      tmpS += tmpL;
      if (
        i !== 0 &&
        i !== s.length - 1 &&
        this.voiceGroups.brief.indexOf(tmpL) !== -1 &&
        this.isNotLastSep(s.substr(i + 1, s.length - i + 1))
      ) {
        addSep();
        continue;
      }
      if (i < s.length - 1 && this.voiceGroups.vowel.indexOf(tmpL) !== -1 && this.voiceGroups.vowel.indexOf(s.substr(i + 1, 1)) !== -1) {
        addSep();
        continue;
      }
      if (
        i < s.length - 2 &&
        this.voiceGroups.vowel.indexOf(tmpL) !== -1 &&
        this.voiceGroups.cons.indexOf(s.substr(i + 1, 1)) !== -1 &&
        this.voiceGroups.vowel.indexOf(s.substr(i + 2, 1)) !== -1
      ) {
        addSep();
        continue;
      }
      if (
        i < s.length - 2 &&
        this.voiceGroups.vowel.indexOf(tmpL) !== -1 &&
        this.voiceGroups.deaf.indexOf(s.substr(i + 1, 1)) !== -1 &&
        this.voiceGroups.cons.indexOf(s.substr(i + 2, 1)) !== -1 &&
        this.isNotLastSep(s.substr(i + 1, s.length - i + 1))
      ) {
        addSep();
        continue;
      }
      if (
        i > 0 &&
        i < s.length - 1 &&
        this.voiceGroups.voiced.indexOf(tmpL) !== -1 &&
        this.voiceGroups.vowel.indexOf(s.substr(i - 1, 1)) !== -1 &&
        this.voiceGroups.vowel.indexOf(s.substr(i + 1, 1)) === -1 &&
        this.voiceGroups.other.indexOf(s.substr(i + 1, 1)) === -1 &&
        this.isNotLastSep(s.substr(i + 1, s.length - i + 1))
      ) {
        addSep();
        continue;
      }
      if (
        i < s.length - 1 &&
        this.voiceGroups.other.indexOf(tmpL) !== -1 &&
        (this.voiceGroups.vowel.indexOf(s.substr(i + 1, 1)) === -1 || this.isNotLastSep(s.substr(0, i)))
      ) {
        addSep();
        continue;
      }
    }
    sepArr.push(tmpS);
    return sepArr;
  };
}
