<div class="student-page-section">
  <div class="student-page-row">
    <div
      class="student-page-photo"
      [ngStyle]="{
        'background-image': pupil.imagePath ? 'url(' + pupil.imagePath + ')' : 'url(/assets/images/dashboard/no-photo-child.svg)'
      }"
    ></div>
    <div class="student-page-name">{{ pupil.firstName }} {{ pupil.lastName }}</div>
  </div>

  <div>
    <div class="parent-menu">
      <div class="parent-menu-row-2">
        <a
          *ngFor="let menuItem of detailsMenu"
          (click)="selectMenuItem(menuItem)"
          [class.w--current]="menuItem === selectedMenuItem"
          class="parent-menu-link"
          >{{ menuItem.name }}</a
        >
      </div>
    </div>

    <div class="tab-pane-parent">
      <ng-container *ngIf="selectedMenuItem.key === 'favoritesProfessions'">
        <div class="tabs-professions">
          <div class="tab-menu-professions" *ngIf="favoriteProfessions.length > 0">
            <a
              class="tabs-professions-link"
              *ngFor="let profHead of favoriteProfessions | slice : 0 : 2"
              [class.w--current]="activeProfession === profHead"
              (click)="setActiveProfession(profHead)"
              >{{ profHead.name }}</a
            >

            <div
              class="menu-professions-dropdown w-dropdown"
              *ngIf="favoriteProfessions && favoriteProfessions.length > 2"
              (click)="nav.classList.toggle('w--open')"
            >
              <div class="professions-dd-toggle w-dropdown-toggle">
                <div class="professions-dd-icon w-icon-dropdown-toggle"></div>
                <div>{{ 'SHARED.YET' | translate }}</div>
              </div>
              <nav class="dropdown-list w-dropdown-list" #nav>
                <div class="professions-dd-bg">
                  <a
                    class="professions-dd-link w-dropdown-link"
                    *ngFor="let profMenu of favoriteProfessions | slice : 2"
                    [class.w--current]="activeProfession === profMenu"
                    (click)="setActiveProfession(profMenu)"
                    >{{ profMenu.name }}</a
                  >
                </div>
              </nav>
            </div>
          </div>

          <prf-detailed-profession-card
            *ngIf="activeProfession; else noFavorites"
            [profession]="activeProfession"
          ></prf-detailed-profession-card>
          <ng-template #noFavorites>
            <prf-not-selected-profession [name]="pupil.firstName"></prf-not-selected-profession>
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedMenuItem.key === 'results'">
        <prf-student-test-results [pupil]="pupil"></prf-student-test-results>
      </ng-container>

      <ng-container *ngIf="selectedMenuItem.key === 'motivation'">
        <div class="test-history-block">
          <div *ngIf="motivationLastSessionId; else notPassed" class="test-results">
            <prf-motivation-results [userRole]="userRole" [sessionId]="motivationLastSessionId"></prf-motivation-results>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedMenuItem.key === 'values'">
        <div class="test-history-block">
          <div *ngIf="valuesLastSessionId; else notPassed" class="test-results">
            <prf-values-results [userRole]="userRole" [sessionId]="valuesLastSessionId"></prf-values-results>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedMenuItem.key === 'profile'">
        <prf-user-profile-shared [user]="pupil"></prf-user-profile-shared>
      </ng-container>

      <ng-template #notPassed>
        <div class="no-test-block">
          <div>
            <img src="/assets/images/icons/no-test-icon.png" width="80" class="no-profession-icon" />
          </div>
          <div class="empty-block-text">Ваш ребенок пока не прошел тест.</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
