import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { IAllSchoolsStatistic } from 'app/shared/interfaces/iallschoolsstatistic';
import { ICity } from 'app/shared/interfaces/icity';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { OverlayBusyService } from '../../../../shared/overlay-busy/overlay-busy.service';
import { AdminPanelService } from '../admin-panel.service';
import { CitiesService } from '../cities/cities.service';
import { MunicipalitiesService } from '../municipalities/municipalities.service';
import { RegionsService } from '../regions/regions.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

const PAGE_SIZE: number = 100;
const TRIGGER_SCROLL_BOTTOM: number = 350;

@Component({
  selector: 'prf-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss'],
})
export class SchoolsComponent implements OnInit {
  @ViewChild('regionDrop') public readonly regionDrop: ElementRef;
  @ViewChild('regionField') public readonly regionField: ElementRef;
  @ViewChild('municipalityDrop') public readonly municipalityDrop: ElementRef;
  @ViewChild('municipalityField') public readonly municipalityField: ElementRef;
  @ViewChild('cityDrop') public readonly cityDrop: ElementRef;
  @ViewChild('cityField') public readonly cityField: ElementRef;

  allSchoolsStatistic: IAllSchoolsStatistic[] = [];
  schoolsStatistic: IAllSchoolsStatistic[] = [];
  schoolsStatisticCounters: any;

  // флаги сортировки
  sortSelectedSchoolName: boolean = false;
  sortSelectedTotalPupils: boolean = false;
  sortSelectedTotalRegPupils: boolean = false;
  sortSelectedTotalRegParents: boolean = false;
  sortSelectedTestCompleted: boolean = false;
  sortSelectedTestCompletedRussian: boolean = false;
  sortSelectedTestCompletedForeign: boolean = false;
  sortSelectedTotalClasses: boolean = false;
  sortSelectedTotalTeachers: boolean = false;
  // активная сортировка
  selectedIndex: number = 1;

  // edit flag
  editing: boolean;

  // текущая школа
  currentSchool: IAllSchoolsStatistic;

  defaultGuid: string = '00000000-0000-0000-0000-000000000000';

  public allRegions: IRegion[] = [];
  public municipalitiesByRegion: IMunicipality[] = [];
  public citiesByMunicipality: ICity[] = [];
  adminLevel: any;
  adminAccessLevel: any = AdminAccessLevel;
  isRegion: boolean = false;
  isMunicipality: boolean = false;
  reportDate: Date;
  reportYear: number;
  reportYears: any[] = [
    { name: 'За все время', value: 0 },
    { name: '2020', value: 2020 },
    { name: '2021', value: 2021 },
    { name: '2022', value: 2022 },
  ];
  deleteConfirming: boolean = false;
  schoolToDeleteId: string = '';

  public regionLanguages = {
    default: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.defaultLanguage : null,
    native: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.regionLanguage : null,
  };

  public allCities: ICity[];

  private ngUnsubscribe$: Subject<any> = new Subject();
  public loading: boolean = false;
  public loadedAll: boolean = false;
  public pageNumber: number = 0; // номер загруженной страницы
  public currentGroupElementsCount: number = 0; // количество загруженных профессий в текущей группе

  public form: UntypedFormGroup;

  constructor(
    private meta: Meta,
    private adminPanelService: AdminPanelService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private citiesService: CitiesService,
    private overlayService: OverlayBusyService,
    private fb: UntypedFormBuilder,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Школы' });
  }

  ngOnInit() {
    this.form = this.fb.group({
      region: new UntypedFormControl(null, []),
      municipality: new UntypedFormControl(null, []),
      city: new UntypedFormControl(null, []),
      year: new UntypedFormControl(null, []),
    });

    // по дефолту отображаем статистику за последний год
    this.f.year.setValue(this.reportYears[this.reportYears.length - 1]);
    this.reportYear = this.reportYears[this.reportYears.length - 1].value;

    this.loadTerritory().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  loadTerritory(): Observable<any> {
    let userRegionId = localStorage.getItem('regionId');
    let userMunicipalityId = localStorage.getItem('municipalityId');
    this.adminLevel = localStorage.getItem('adminLevel');
    let currentObservable$: Observable<any> = of(null);

    switch (this.adminLevel) {
      case AdminAccessLevel.GLOBAL:
        {
          currentObservable$ = this.getRegions().pipe(
            switchMap(r => {
              let region = this.allRegions[0];
              return this.setRegion(region);
            }),
          );
        }
        break;
      case AdminAccessLevel.REGION:
        {
          currentObservable$ = this.getRegions().pipe(
            switchMap(r => {
              let region = this.allRegions.find(r => r.id == userRegionId);
              return this.setRegion(region);
            }),
          );
        }
        break;
      case AdminAccessLevel.MUNICIPALITY:
        {
          currentObservable$ = this.getRegions().pipe(
            switchMap(r => {
              let region = this.allRegions.find(r => r.id == userRegionId);
              return this.setRegion(region).pipe(
                switchMap(r => {
                  return this.getMunicipalities(userRegionId).pipe(
                    switchMap(r => {
                      let municipality = this.municipalitiesByRegion.find(r => r.regionId == userRegionId && r.id === userMunicipalityId);
                      return this.setMunicipality(municipality);
                    }),
                  );
                }),
              );
            }),
          );
        }
        break;
    }
    return currentObservable$;
  }

  get f() {
    return this.form.controls;
  }

  @HostListener('window:scroll', [])
  onScroll() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - TRIGGER_SCROLL_BOTTOM) {
      if (this.loading === false && this.loadedAll == false) {
        let region = this.f.region.value ? this.f.region.value.id : null;
        let municipality = this.f.municipality.value ? this.f.municipality.value.id : null;
        let city = this.f.city.value ? this.f.city.value.name : null;
        this.loadStatistic(region, municipality, city, this.reportDate, this.reportYear).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
      }
    }
  }

  loadStatisticWithLoader(regionId?, municipalityId?, cityName?, reportDate?, reportYear?): Observable<any> {
    this.overlayService.show();
    return this.loadStatistic(regionId, municipalityId, cityName, reportDate, reportYear).pipe(tap(() => this.overlayService.hide()));
  }

  loadStatistic(regionId?, municipalityId?, cityName?, reportDate?, reportYear?): Observable<any> {
    this.loading = true;
    return this.adminPanelService
      .getAllSchoolsStatistic(regionId, municipalityId, cityName, reportDate, this.currentGroupElementsCount, PAGE_SIZE, reportYear)
      .pipe(
        tap(statistics => {
          statistics.schoolStatistics.forEach(p => {
            this.allSchoolsStatistic.push(p);
          });
          this.schoolsStatistic = this.allSchoolsStatistic;
          this.schoolsStatisticCounters = statistics.counters;
          this.pageNumber++;
          this.currentGroupElementsCount = this.currentGroupElementsCount + PAGE_SIZE;
          this.loadedAll = statistics.schoolStatistics.length < PAGE_SIZE;
          this.loading = false;
        }),
      );
  }

  // sorting by param
  sortByParamName(paramName: string) {
    switch (paramName) {
      case 'school':
        {
          this.sortSchoolStatistic('schoolName', true, this.sortSelectedSchoolName);
          this.sortSelectedSchoolName = !this.sortSelectedSchoolName;
          this.selectedIndex = 1;
        }
        break;
      case 'pupils':
        {
          this.sortSchoolStatistic('totalPupilsCount', false, this.sortSelectedTotalPupils);
          this.sortSelectedTotalPupils = !this.sortSelectedTotalPupils;
          this.selectedIndex = 2;
        }
        break;
      case 'regPupils':
        {
          this.sortSchoolStatistic('registeredPupilsCount', false, this.sortSelectedTotalRegPupils);
          this.sortSelectedTotalRegPupils = !this.sortSelectedTotalRegPupils;
          this.selectedIndex = 3;
        }
        break;
      case 'parents':
        {
          this.sortSchoolStatistic('totalParentsCount', false, this.sortSelectedTotalRegParents);
          this.sortSelectedTotalRegParents = !this.sortSelectedTotalRegParents;
          this.selectedIndex = 8;
        }
        break;
      case 'testCompleted':
        {
          this.sortSchoolStatistic('completedTestsCount', false, this.sortSelectedTestCompleted);
          this.sortSelectedTestCompleted = !this.sortSelectedTestCompleted;
          this.selectedIndex = 4;
        }
        break;
      case 'testCompletedRussian':
        {
          this.sortSchoolStatistic('completedRussianTestsCount', false, this.sortSelectedTestCompletedRussian);
          this.sortSelectedTestCompletedRussian = !this.sortSelectedTestCompletedRussian;
          this.selectedIndex = 4;
        }
        break;
      case 'testCompletedForeign':
        {
          this.sortSchoolStatistic('completedForeignTestsCount', false, this.sortSelectedTestCompletedForeign);
          this.sortSelectedTestCompletedForeign = !this.sortSelectedTestCompletedForeign;
          this.selectedIndex = 7;
        }
        break;
      case 'classes':
        {
          this.sortSchoolStatistic('classesCount', false, this.sortSelectedTotalClasses);
          this.sortSelectedTotalClasses = !this.sortSelectedTotalClasses;
          this.selectedIndex = 5;
        }
        break;
      case 'teachers': {
        this.sortSchoolStatistic('teachersCount', false, this.sortSelectedTotalTeachers);
        this.sortSelectedTotalTeachers = !this.sortSelectedTotalTeachers;
        this.selectedIndex = 6;
      }
    }
  }

  private sortSchoolStatistic(fieldName: string, isStringField: boolean, orderByDesc: boolean) {
    this.schoolsStatistic.sort(function (a, b) {
      let fieldA = a[fieldName];
      let fieldB = b[fieldName];
      if (isStringField) {
        fieldA = fieldA.toUpperCase(); // to ignore upper and lowercase
        fieldB = fieldB.toUpperCase();
      }
      if (orderByDesc) {
        if (fieldA > fieldB) {
          return -1;
        }
        if (fieldA < fieldB) {
          return 1;
        }
      } else {
        if (fieldA < fieldB) {
          return -1;
        }
        if (fieldA > fieldB) {
          return 1;
        }
      }
      return 0;
    });
  }

  edit(schoolID) {
    this.editing = !this.editing;
    this.currentSchool = this.allSchoolsStatistic.find(school => school.schoolId === schoolID);
  }

  deleteSchool() {
    if (this.schoolToDeleteId) {
      this.deleteConfirming = true;
      this.adminPanelService
        .deleteSchool(this.schoolToDeleteId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => {
          this.deleteConfirming = false;
        });
    }
  }

  closeEditing(event) {
    this.editing = event;
  }

  // подгрузка и выбор объектов территориального деления: Регион, Муниципалитет, Город
  getRegions(): Observable<any> {
    return this.regionsService.getAllRegions().pipe(
      tap(r => {
        this.allRegions = r.filter(r => r.id != this.defaultGuid);
      }),
    );
  }

  selectRegion(region) {
    this.setRegion(region).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setRegion(region): Observable<any> {
    if (region) {
      if (region != this.f.region.value) {
        this.f.municipality.setValue(null);
        this.f.city.setValue(null);
      }
      this.resetPageNumber();
      this.f.region.setValue(region);
      return this.loadStatistic(region.id, null, null, this.reportDate, this.reportYear).pipe(
        switchMap(r => {
          if (this.adminLevel == AdminAccessLevel.GLOBAL || this.adminLevel == AdminAccessLevel.REGION) {
            return this.municipalitiesService.getMunicipalitiesByRegion(region.id).pipe(tap(r => (this.municipalitiesByRegion = r)));
          } else {
            return of(null);
          }
        }),
      );
    }
  }

  setSchoolsByRegion(region) {
    let citiesByRegion = this.allCities.filter(city => city.regionId === region.id);
    this.setSchoolsByCities(citiesByRegion);
  }

  public setSchoolsByCities(cities) {
    this.schoolsStatistic = [];
    cities.forEach(city => {
      this.schoolsStatistic = this.schoolsStatistic.concat(this.allSchoolsStatistic.filter(school => school.schoolCity === city.name));
    });
  }

  getMunicipalities(regionId): Observable<any> {
    return this.municipalitiesService.getMunicipalitiesByRegion(regionId).pipe(
      tap(r => {
        this.municipalitiesByRegion = r.filter(r => r.id != this.defaultGuid);
      }),
    );
  }

  selectMunicipality(municipality) {
    this.setMunicipality(municipality).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setMunicipality(municipality): Observable<any> {
    if (municipality) {
      this.resetPageNumber();
      this.f.city.setValue(null);
      this.f.municipality.setValue(municipality);
      return this.loadStatistic(this.f.region.value.id, this.f.municipality.value.id, null, this.reportDate, this.reportYear).pipe(
        switchMap(r => {
          return this.citiesService.getAllCitiesByMunicipality(municipality.id).pipe(tap(r => (this.citiesByMunicipality = r)));
        }),
      );
    } else {
      return of(null);
    }
  }

  public isSelectedRegion(): boolean {
    return this.f.region.value && this.f.region.value.id !== this.defaultGuid;
  }

  schoolAdded(schoolId) {
    if (schoolId) {
      this.adminPanelService
        .getSchoolByID(schoolId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => {
          this.allSchoolsStatistic.push(r);
        });
    }
  }

  selectCity(city) {
    if (city) {
      this.resetPageNumber();
      this.f.city.setValue(city);
      // загружаем статистику с указанием города
      this.loadStatistic(this.f.region.value.id, null, this.f.city.value.name, this.reportDate, this.reportYear)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe();
    }
    return;
  }

  setDate() {
    this.resetPageNumber();
    let region = this.f.region.value ? this.f.region.value.id : null;
    let municipality = this.f.municipality.value ? this.f.municipality.value.id : null;
    let city = this.f.city.value ? this.f.city.value.name : null;
    this.loadStatistic(region, municipality, city, this.reportDate).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setYear(year) {
    if (year) {
      this.f.year.setValue(year);
      this.reportYear = year.value;
      this.resetPageNumber();
      let region = this.f.region.value ? this.f.region.value.id : null;
      let municipality = this.f.municipality.value ? this.f.municipality.value.id : null;
      let city = this.f.city.value ? this.f.city.value.name : null;
      this.loadStatistic(region, municipality, city, null, this.reportYear).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
    }
  }

  parseDate(dateString) {
    if (dateString) {
      return new Date(dateString);
    } else {
      return null;
    }
  }

  // методы сброса фильтров
  deselectMunicipality() {
    this.setRegion(this.f.region.value)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.f.municipality.setValue(null);
        this.f.city.setValue(null);
      });
  }

  deselectCity() {
    this.setMunicipality(this.f.municipality.value)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.f.city.setValue(null));
  }

  resetData() {
    this.resetPageNumber();
    this.f.region.setValue(null);
    this.f.municipality.setValue(null);
    this.f.city.setValue(null);
    this.loadStatisticWithLoader(this.reportDate).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  private resetPageNumber() {
    this.allSchoolsStatistic = [];
    this.currentGroupElementsCount = 0;
    this.pageNumber = 0;
    this.loading = false;
    this.loadedAll = false;
    this.resetSorting();
  }

  private resetSorting() {
    this.sortSelectedSchoolName = false;
    this.sortSelectedTotalPupils = false;
    this.sortSelectedTotalRegPupils = false;
    this.sortSelectedTestCompleted = false;
    this.sortSelectedTestCompletedRussian = false;
    this.sortSelectedTestCompletedForeign = false;
    this.sortSelectedTotalClasses = false;
    this.sortSelectedTotalTeachers = false;
    this.selectedIndex = 1;
  }

  @HostListener('click', ['$event'])
  checkClick(event: Event) {
    if (this.regionDrop) {
      if (this.regionDrop.nativeElement.classList.contains('w--open')) {
        if (!this.regionDrop.nativeElement.contains(event.target) && !this.regionField.nativeElement.contains(event.target)) {
          this.regionDrop.nativeElement.classList.remove('w--open');
        }
      }
    }
    if (this.municipalityDrop) {
      if (this.municipalityDrop.nativeElement.classList.contains('w--open')) {
        if (!this.municipalityDrop.nativeElement.contains(event.target) && !this.municipalityField.nativeElement.contains(event.target)) {
          this.municipalityDrop.nativeElement.classList.remove('w--open');
        }
      }
    }
    if (this.cityDrop) {
      if (this.cityDrop.nativeElement.classList.contains('w--open')) {
        if (!this.cityDrop.nativeElement.contains(event.target) && !this.cityField.nativeElement.contains(event.target)) {
          this.cityDrop.nativeElement.classList.remove('w--open');
        }
      }
    }
  }
}
