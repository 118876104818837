import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Gender } from '../../../../../shared/enums/gender.enums';

import { AddFirstProfessionModalService } from './add-first-profession-modal.service';

@Component({
  selector: 'prf-add-first-profession-modal',
  templateUrl: './add-first-profession-modal.component.html',
  styleUrls: ['./add-first-profession-modal.component.scss'],
})
export class AddFirstProfessionModalComponent {
  @Input() enableClose: boolean = false;

  public show: boolean = false;
  public userGender: string = '';
  public url: string = '';

  constructor(private addFirstProfessionModalService: AddFirstProfessionModalService, private router: Router) {
    this.userGender = localStorage.getItem('userGender');

    this.addFirstProfessionModalService.show$.subscribe(show => {
      this.show = show;
      this.url = this.addFirstProfessionModalService.url;
    });
  }

  public close() {
    this.addFirstProfessionModalService.close();
  }

  public navigateTo() {
    if (this.url) {
      this.router.navigate([this.url]);
    } else {
      window.location.reload();
    }

    this.close();
  }

  public isMale(gender: string) {
    return gender === Gender.Male;
  }
}
