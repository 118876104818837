import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ETestTypesSteps } from '../../../../shared/enums/testtypes.enum';
import { OverlayBusyService } from '../../../../shared/overlay-busy/overlay-busy.service';
import { PlayerService } from '../../../player/player.service';

@Component({
  selector: 'prf-pupil-uncompleted-test',
  templateUrl: './pupil-uncompleted-test.component.html',
})
export class PupilUncompletedTestComponent implements OnInit {
  @Input() sessionId: any;

  public slide: any;
  public userRole: string = '';
  public countSteps: number = 0;

  constructor(private playerService: PlayerService, private overlayService: OverlayBusyService) {
    this.userRole = localStorage.getItem('userRole');
    this.countSteps = this.userRole === 'parent' ? ETestTypesSteps.DEFAULT_360 : ETestTypesSteps.DEFAULT_STANDART;
  }

  ngOnInit() {
    this.overlayService.show();
    this.playerService
      .getCurrentSlide(this.sessionId)
      .pipe(take(1))
      .subscribe(data => {
        this.slide = data;
        this.overlayService.hide();
      });
  }
}
