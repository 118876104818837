import { ElementRef, HostListener, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  DateFiltersTypes,
  datesFiltersArray,
  FiltersPromotestCoursesService,
  IDateFilterFormat,
} from '../filters-promotest-courses.service';

@Component({
  selector: 'prf-filters-date',
  templateUrl: './filters-date.component.html',
  styleUrls: ['./filters-date.component.scss'],
})
export class FiltersDateComponent implements OnInit {
  @ViewChild('dateFilter') dateFilter: ElementRef;

  filterOpen: boolean = false;
  datesFilters: IDateFilterFormat[] = datesFiltersArray;

  selectedFilter: IDateFilterFormat;

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(private filtersService: FiltersPromotestCoursesService) {}

  ngOnInit() {
    this.checkDatePeriod();
    this.filtersService
      .getDateFilter()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((dateFilter: IDateFilterFormat) => {
        this.selectedFilter = dateFilter;
      });
  }

  setDateFilter(dateFilter: IDateFilterFormat) {
    this.selectedFilter = dateFilter;
    this.filtersService.setDateFilter(dateFilter);
    this.filterOpen = false;
  }

  deselectFilter() {
    this.selectedFilter = null;
    this.filtersService.setDateFilter(this.selectedFilter);
    this.filterOpen = false;
  }

  checkFilterHeader(): string {
    if (this.selectedFilter) {
      return this.selectedFilter.viewValue;
    } else {
      return 'Дата';
    }
  }

  checkDatePeriod() {
    this.datesFilters = this.datesFilters.filter(dateObject => {
      let newDate = new Date();
      switch (true) {
        //лето
        case newDate.getMonth() <= DateFiltersTypes.AUGUST - 1 && newDate.getMonth() >= DateFiltersTypes.JUNE - 1:
          return dateObject.value <= DateFiltersTypes.AUGUST && dateObject.value >= DateFiltersTypes.JUNE;
        //осень
        case newDate.getMonth() <= DateFiltersTypes.NOVEMBER - 1 && newDate.getMonth() >= DateFiltersTypes.SEPTEMBER - 1:
          return dateObject.value <= DateFiltersTypes.NOVEMBER && dateObject.value >= DateFiltersTypes.SEPTEMBER;
        //зима
        case newDate.getMonth() === DateFiltersTypes.FEBRUARE - 1 ||
          newDate.getMonth() === DateFiltersTypes.DECEMBER - 1 ||
          newDate.getMonth() === DateFiltersTypes.JANUARY - 1:
          return (
            dateObject.value === DateFiltersTypes.FEBRUARE ||
            dateObject.value === DateFiltersTypes.DECEMBER ||
            dateObject.value === DateFiltersTypes.JANUARY
          );
        //весна
        case newDate.getMonth() <= DateFiltersTypes.MAY - 1 && newDate.getMonth() >= DateFiltersTypes.MARCH - 1:
          return dateObject.value <= DateFiltersTypes.MAY && dateObject.value >= DateFiltersTypes.MARCH;
        default:
          return false;
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.dateFilter && !this.dateFilter?.nativeElement.contains(target)) {
      this.filterOpen = false;
    }
  }
}
