<div class="payment">
  <h1>{{ 'CART.ORDER' | translate }}</h1>
  <div class="payment__steps">
    <div class="payment__step payment__step--active">
      <!-- <div *ngIf="!isClaim" class="payment__step-num">1. </div> -->
      <div class="payment__step-name">Личные данные</div>
    </div>
    <!--div *ngIf="!isClaim" class="payment__step">
			<div class="payment__step-num">2. </div>
			<div class="payment__step-name">Оплата</div>
		</div-->
  </div>
  <div class="payment__block">
    <div *ngIf="!isAuthorized" class="payment__notauthorization" [innerHTML]="'SHARED.SIGN_IN_OR_SUBMIT_FORM' | translate"></div>
    <div class="payment__item">
      <label class="payment__label">{{ 'SHARED.FORM.NAME' | translate }}</label>
      <input type="text" value="" class="payment__input payment__input--username" />
    </div>
    <div class="payment__item">
      <label class="payment__label">{{ 'SHARED.FORM.FAMILY' | translate }} </label>
      <input type="text" value="" class="payment__input payment__input--lastname" />
    </div>
    <div class="payment__item">
      <label class="payment__label">{{ 'SHARED.FORM.EMAIL' | translate }} </label>
      <input type="text" value="" class="payment__input payment__input--email" />
      <span class="payment__span">{{ 'SHARED.EMAIL_PLACEHOLDER' | translate }}</span>
    </div>
    <div class="payment__item">
      <label class="payment__label">{{ 'SHARED.FORM.PHONE' | translate }}</label>
      <input type="text" value="" placeholder="+7 (___) ______" class="payment__input payment__input--phone" />
    </div>
    <div class="payment__offers">
      <div class="payment__offers-switch">
        <label class="switch switch--offers">
          <input class="switch-input switch-input--offers" type="checkbox" (change)="onChangedRulesSwitch()" />
          <span class="switch-label switch-label--offers" data-on="" data-off=""></span>
          <span class="switch-handle switch-handle--offers"></span>
        </label>
      </div>
      <div
        class="payment__offers-text"
        [innerHTML]="
          'CART.PERSONALS.CONDITION' | translate : { offer: '/assets/docs/offers.pdf', confirm: 'assets/docs/privacypolicy.pdf' }
        "
      ></div>
    </div>
  </div>
  <div *ngIf="!isClaim && product && !!product.price" class="payment__discount">
    <div class="payment__discount--left">{{ 'CART.DISCOUNT' | translate }}</div>
    <div class="payment__discount--right">{{ product.discount }} {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</div>
  </div>
  <div *ngIf="!isClaim && product && !!product.price" class="payment__summ">
    <div class="payment__summ--left">{{ 'CART.PAY' | translate }}</div>
    <div class="payment__summ--right">{{ product.price }} {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</div>
  </div>
  <div class="payment__buttons">
    <a *ngIf="!isClaim" href="#" [attr.disabled]="!rulesAccepted ? true : null" class="payment__button">{{ 'CART.PAY' | translate }}</a>
    <a *ngIf="isClaim" href="#" [attr.disabled]="!rulesAccepted ? true : null" class="payment__button">{{
      'SHARED.SUBMIT_REQUEST' | translate
    }}</a>
  </div>
</div>
