import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsService } from 'app/shared/services/appsettings.service';

@Component({
  selector: 'prf-page-footer-kz',
  templateUrl: './page-footer-kz.component.html',
  styleUrls: ['./page-footer-kz.component.scss'],
})
export class PageFooterKzComponent implements OnInit {
  public yearNumber: number = null;
  public isKz: boolean = true;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.yearNumber = new Date().getFullYear();
    this.setLanguage();
  }

  public setLanguage() {
    let language = localStorage.getItem('selectedLanguage');
    if (language && ['ru', 'kz'].indexOf(language) > -1) {
      this.isKz = language === 'kz' ? true : false;
      this.translate.use(language);
    }
    return;
  }

  getDocsRoute(): string {
    if (this.isKz) {
      return AppSettingsService.settings.docsPath.docsPathRegion;
    } else {
      return AppSettingsService.settings.docsPath.docsPathDefault;
    }
  }
}
