import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppSettingsService } from '../../shared/services/appsettings.service';
import { GuardService } from '../services/guard.service';
import { HttpService } from '../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedService } from 'app/shared/shared.service';

@Injectable()
export class AuthGuard  {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private http: HttpService,
    private appSettings: AppSettingsService,
    public route: ActivatedRoute,
    private guardService: GuardService,
    private sharedService: SharedService,
  ) {}

  async canActivate(): Promise<boolean> {
    return this.sharedService
      .getUserInfoData()
      .toPromise()
      .then(ui => {
        let locationPrefix = this.appSettings.currentLocation + '-'; // Префикс роута
        locationPrefix = locationPrefix.replace('base-', ''); // Если базовый лендинг, то убираем префикс
        let loginPath = `/${locationPrefix}login`; // Формируем имя роута

        if (localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString()) {
          return false;
        }
        this.checkCookie().subscribe(
          response => {
            if (response?.status !== 'Success') {
              this.sharedService.logOut();
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status >= 500 && err.status < 600) {
              this.sharedService.logOut();
            }
          },
        );
        const token = localStorage.getItem('userRole');
        if (token == undefined) {
          localStorage.clear();
          this.router.navigate(['/']);
          return false;
        }
        this.router.events.subscribe(e => {
          if (e instanceof NavigationStart && e.url === '/parent' && localStorage.getItem('tag') === 'MosRu') {
            // для mosru пользователей домашняя страница - '/mosruabout'
            this.router.navigate(['/mosruabout']);
            return false;
          }
          if (e instanceof NavigationEnd) {
            switch (e.url) {
              case '/admin':
              case '/schooladmin':
              case '/tutor':
              case '/pupil': {
                this.router.navigate(['/' + token]);
                return false;
              }
              // case '/pupil':
              //   this.router.navigate(['/recommendations']);
              //   return false;
              case '/parent': {
                this.router.navigate(['/' + token]);
                return false;
              }
              default:
                break;
            }
          }
        });
        if (localStorage.getItem('isAuthorized') !== 'true') {
          localStorage.clear();
          this.router.navigate([loginPath]);
          return false;
        }
        // проверяем needToUpdate, если true, перенаправляем ученика на обновление профиля
        if (token === 'pupil' && ui.needToUpdate) {
          this.router.navigate(['/update-user-info']);
          return false;
        }

        return true;
      })
      .catch(err => {
        this.router.navigate(['/']);
        return false;
      });
  }

  public checkCookie() {
    return this.http.get('/b2c/v1.0/saas/checkcookie').pipe(map(r => r));
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate();
  }
}
