import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  getCatalogSchools(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/schools').pipe(
      map(r => r.schools),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateAllSchoolsStatistic(): Observable<any> {
    return this.http.post('/b2c/v1.0/admins/updateallschoolclassesextendedpupils').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateSchoolStatistic(schoolId): Observable<any> {
    return this.http.post(`/b2c/v1.0/admins/updateextendedpupils?schoolId=${schoolId}`).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateAllSchoolsAnalytics(regionId, dateFrom, dateTo): Observable<any> {
    let credentials = {
      regionId: regionId,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    return this.http.post('/b2c/v1.0/admins/calculateallpartialschoolireport', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
  updateSchoolAnalytics(schoolId, dateFrom, dateTo): Observable<any> {
    let credentials = {
      schoolId: schoolId,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    return this.http.post('/b2c/v1.0/admins/calculatepartialschoolireport', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  calculatePartialRegioniReport(regionId: string) {
    let credentials = {
      regionId: regionId,
      dateFrom: '',
      dateTo: '',
    };
    return this.http.post('/b2c/v1.0/admins/calculatepartialregionireport', credentials).pipe(
      map(r => r.id),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  calculatePartialMunicipalityiReport(regionId, municipalityId) {
    let credentials = {
      regionId: regionId,
      municipalityId: municipalityId,
      dateFrom: '',
      dateTo: '',
    };
    return this.http.post('/b2c/v1.0/admins/calculatepartialmunicipalityireport', credentials).pipe(
      map(r => r.id),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateRegionStatistic(regionId, language?) {
    let urlParam: string = `?regionId=${regionId}` + (language ? `&language=${language}` : '');
    return this.http.post('/b2c/v1.0/reports/updateregionstatisticexcel' + urlParam).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
  getRegionStatisticExcel(regionId, language?) {
    let urlParam: string = `?regionId=${regionId}` + (language ? `&language=${language}` : '');
    return this.http.getFile('/b2c/v1.0/reports/getregionstatisticexcel' + urlParam).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateRegionPupilsStatistic(regionId) {
    let urlParam: string = `?regionId=${regionId}`;
    return this.http.post('/b2c/v1.0/reports/updatepupilusersinfoforregion' + urlParam).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
  getRegionPupilsStatisticExcel(regionId) {
    let urlParam: string = `?regionId=${regionId}`;
    return this.http.getFile('/b2c/v1.0/reports/getpupilusersinfoforregionexcel' + urlParam).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateMunicipalityStatistic(regionId, municipalityId, language) {
    return this.http
      .post(`/b2c/v1.0/reports/updateregionstatisticexcel?regionId=${regionId}&municipalityId=${municipalityId}&language=${language}`)
      .pipe(
        map(r => r),
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err);
        }),
      );
  }
  getMunicipalityStatisticExcel(regionId, municipalityId, language): Observable<any> {
    return this.http
      .getFile(`/b2c/v1.0/reports/getregionstatisticexcel?regionId=${regionId}&municipalityId=${municipalityId}&language=${language}`)
      .pipe(
        map(r => r),
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err);
        }),
      );
  }

  updateProfessions() {
    return this.http.post('/api/v1.0/catalog/professions/reloadprofessionselasticindex').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
}
