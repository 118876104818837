<ng-container [ngSwitch]="testType">
  <ng-container *ngSwitchCase="testTypesEnum.PROFESSIONPRESTIGE.toString()">
    <div class="slide-number">
      {{ slideNum }} {{ 'COMMON.OF' | translate }}
      {{ testTypesSteps.PROFESSIONPRESTIGE }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="testTypesEnum.MOTIVATION_TEST.toString()">
    <div class="slide-number">
      {{ slideNum }} {{ 'COMMON.OF' | translate }}
      {{ testTypesSteps.MOTIVATION_TEST }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="testTypesEnum.CAREER_TYPES.toString()">
    <div class="slide-number">
      {{ slideNum }} {{ 'COMMON.OF' | translate }}
      {{ testTypesSteps.CAREER_TYPES }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="testTypesEnum.PROMO_TEST.toString()">
    <div class="slide-number">{{ slideNum }} {{ 'COMMON.OF' | translate }} {{ testTypesSteps.PROMO_TEST }}</div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div *ngIf="userRole == 'pupil'" class="slide-number">
      {{ slideNum }} {{ 'COMMON.OF' | translate }}
      {{ testTypesSteps.DEFAULT_STANDART }}
    </div>
    <div *ngIf="userRole == 'parent'" class="slide-number">
      {{ slideNum }} {{ 'COMMON.OF' | translate }} {{ testTypesSteps.DEFAULT_360 }}
    </div>
  </ng-container>
</ng-container>

<div class="text-container">
  <h1 class="title">{{ data.title }}</h1>
  <p class="descr">{{ data.description }}</p>
</div>

<div *ngIf="data.imageUrl" class="picture-container">
  <img [src]="data.imageUrl" alt="" class="slide-picture" />
</div>

<div *ngIf="maxAnswerCount" class="text-container count-container">
  <p class="descr">Выберите от 1 до {{ maxAnswerCount }} вариантов</p>
</div>

<div
  *ngIf="!showSlideWithSubsections"
  class="agile-bubble-box-container"
  [ngClass]="{
    'slide-positive': data.sectionType == 'Positive',
    'slide-negative': data.sectionType == 'Negative'
  }"
>
  <mat-selection-list
    #elements
    [(ngModel)]="selectedAnswerNames"
    (ngModelChange)="onModelChangeElements($event)"
    name="elements"
    class="agile-bubble-box-section"
  >
    <mat-list-option
      *ngFor="let element of visibleAnswers"
      class="grow"
      [class.checked]="element.active"
      [value]="element.text"
      [selected]="element.sectionId === data.sectionId"
      [disabled]="selectedAnswerCount === maxAnswerCount && !element.active"
    >
      {{ element.text }}
    </mat-list-option>
  </mat-selection-list>
</div>

<div
  *ngIf="showSlideWithSubsections"
  class="subsections-container bubbles"
  [ngClass]="{
    'slide-positive': data.sectionType == 'Positive',
    'slide-negative': data.sectionType == 'Negative'
  }"
>
  <mat-selection-list name="sectionElements">
    <div *ngFor="let section of screenSections">
      <div class="leaf section circle checkboxes" (click)="selectSection(section)">
        {{ section.name }}
      </div>
      <mat-list-option
        class="grow leaf subsection circle checkboxes"
        (click)="checkedSubsection(element)"
        *ngFor="let element of section.fields"
        [value]="element.text"
        [ngClass]="{
          hidden: section.isOpened == false,
          selected: element.sectionId == data.sectionId
        }"
      >
        {{ element.text }}
      </mat-list-option>
    </div>
  </mat-selection-list>
</div>
