<div class="event-card" *ngIf="isCourse" (click)="checkAction($event)">
  <div class="event-gradient-bg full-height w-inline-block" [ngClass]="currentType.color">
    <div class="event-header full-height">
      <div class="event-type" [ngClass]="currentType.color">{{ currentType.viewValue }}</div>
      <div class="event-heading-wrapper">
        <h3
          class="event-heading"
          (mouseleave)="popup2.style.display = 'none'"
          (mouseover)="course.name.length >= 40 ? (popup2.style.display = 'block') : null"
        >
          {{ cutText(course.name, 40) }}
        </h3>
        <div class="info-popup recommendation course w-hidden-small w-hidden-tiny" #popup2 style="display: none">
          <p>{{ course.name }}</p>
          <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
        </div>
      </div>

      <div class="event-info-row-2">
        <div class="event-date-2" *ngIf="course.startDate">{{ course.startDate }}</div>
      </div>
    </div>
    <div
      [ngStyle]="{
        'background-image': course.mainImagePath ? 'url(' + course.mainImagePath + ')' : 'url(/assets/images/background/event-bg-2.png)'
      }"
      class="event-image-bg adm"
    ></div>
    <div class="event-info-div">
      <div class="event-date white-text">
        <ng-container *ngIf="course.isRegular">{{ 'SHARED.REGULARLY' | translate }}</ng-container>
        <ng-container *ngIf="!course.isRegular">
          {{ course.startDate ? moment(course.startDate).format('D MMM в hh:mm') : ('SHARED.NOT_DATE' | translate) }}
        </ng-container>
      </div>
      <p class="event-description white-text">{{ course.shortDescription }}</p>
      <div class="event-address white-text">{{ course.address }}</div>
      <div class="metro-list">
        <div class="event-metro-row metro-li" *ngFor="let station of course.metroStations">
          <div class="metro-color white-border" [ngClass]="metroColors.getStationByLine(station)"></div>
          <div class="event-metro">{{ station.name }}</div>
        </div>
      </div>
      <div class="event-price">
        <div class="event-price-row">
          <div class="final-price adm">
            {{
              calculateRealPrice() > 0
                ? calculateRealPrice() + ' ' + ('COMMON.MONEY_SYMBOL' | translateByDefault)
                : ('COURCES.PAGE.FREE' | translate)
            }}
          </div>
          <div class="starting-price white-text" *ngIf="course.unitDiscount > 0">
            {{ course.unitPrice > 0 ? course.unitPrice : '' }}
          </div>
          <div class="discount white-text" *ngIf="course.unitDiscount > 0 && (course.isPercent === true || course.isPercent === null)">
            {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}%
          </div>
          <div class="discount white-text" *ngIf="course.unitDiscount > 0 && course.isPercent === false">
            {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}
            {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="favouritesAccess">
    <a
      (click)="toggleFavorite()"
      [style.background-image]="isFavorite ? 'url(/assets/images/icons/heart-white.svg)' : 'url(/assets/images/icons/heart-line-white.svg)'"
      class="event-heart white w-inline-block"
    >
    </a>
  </ng-container>
</div>

<div class="event-card" *ngIf="!isCourse" (click)="checkAction($event)">
  <a class="event-gradient-bg w-inline-block" [ngClass]="currentType.color">
    <div class="event-header">
      <div class="event-type" [ngClass]="currentType.color">{{ currentType.viewValue }}</div>

      <div class="event-heading-wrapper">
        <h3
          class="event-heading"
          (mouseleave)="popup.style.display = 'none'"
          (mouseover)="course.name.length >= 40 ? (popup.style.display = 'block') : null"
        >
          {{ cutText(course.name, 40) }}
        </h3>
        <div class="info-popup recommendation course w-hidden-small w-hidden-tiny" #popup style="display: none">
          <p>{{ course.name }}</p>
          <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
        </div>
      </div>
    </div>
    <div
      class="event-image-bg"
      [ngStyle]="{
        'background-image': course.mainImagePath ? 'url(' + course.mainImagePath + ')' : 'url(/assets/images/background/event-bg-2.png)'
      }"
    ></div>
  </a>
  <div class="event-info-div">
    <ng-container *ngIf="favouritesAccess">
      <a
        class="event-heart w-inline-block"
        *ngIf="!isFavorite; else isFavoriteCard"
        [style.background-image]="'url(/assets/images/icons/Heart-line-violet.svg)'"
        (mouseover)="heart.style.backgroundImage = 'url(/assets/images/icons/Heart-violet.svg)'"
        (mouseleave)="heart.style.backgroundImage = 'url(/assets/images/icons/Heart-line-violet.svg)'"
        #heart
      >
      </a>
    </ng-container>
    <div class="event-date" *ngIf="course.isRegular">{{ 'SHARED.REGULARLY' | translate }}</div>
    <div class="event-date" *ngIf="!course.isRegular">
      {{ course.startDate ? moment(course.startDate).format('D MMM в hh:mm') : ('SHARED.NOT_DATE' | translate) }}
    </div>
    <p class="event-description">{{ course.shortDescription }}</p>
    <div class="event-address">{{ course.address }}</div>
    <div class="event-metro-row" *ngIf="course.metroStations.length > 0; else emptyMetroStation">
      <div class="metro-color" [ngClass]="metroColors.getStationByLine(course.metroStations[0])"></div>
      <div class="event-metro">{{ course.metroStations[0].name }}</div>
    </div>

    <div class="event-price">
      <div class="event-price-row">
        <div class="final-price adm">
          {{
            calculateRealPrice() > 0
              ? calculateRealPrice() + ' ' + ('COMMON.MONEY_SYMBOL' | translateByDefault)
              : ('COURCES.PAGE.FREE' | translate)
          }}
        </div>
        <div class="starting-price" *ngIf="course.unitDiscount > 0">
          {{ course.unitPrice > 0 ? (course.unitPrice | eventCurrencyConvert) : '' }}
        </div>
        <div class="discount" *ngIf="course.unitDiscount > 0 && (course.isPercent === true || course.isPercent === null)">
          {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}%
        </div>
        <div class="discount" *ngIf="course.unitDiscount > 0 && course.isPercent === false">
          {{ 'CART.DISCOUNT' | translate }} {{ +course.unitDiscount }}
          {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyDiscont>
  <div class="event-price-row">
    <div class="final-price" *ngIf="isCourse">
      {{
        calculateRealPrice() > 0
          ? calculateRealPrice() + ' ' + ('COMMON.MONEY_SYMBOL' | translateByDefault)
          : ('COURCES.PAGE.FREE' | translate)
      }}
    </div>
    <div class="starting-price"></div>
    <div class="discount"></div>
  </div>
</ng-template>

<ng-template #emptyMetroStation>
  <div class="event-metro-row" style="opacity: 0">
    <div class="metro-color white-border" *ngIf="isCourse"></div>
    <div class="event-metro"></div>
  </div>
</ng-template>

<ng-template #isFavoriteCard>
  <a
    class="event-heart w-inline-block"
    [style.background-image]="isCourse ? 'url(/assets/images/icons/heart-white.svg)' : 'url(/assets/images/icons/Heart-violet.svg)'"
    (mouseover)="
      heartActive.style.backgroundImage = isCourse
        ? 'url(/assets/images/icons/heart-line-white.svg)'
        : 'url(/assets/images/icons/Heart-line-violet.svg)'
    "
    (mouseleave)="
      heartActive.style.backgroundImage = isCourse
        ? 'url(/assets/images/icons/heart-white.svg)'
        : 'url(/assets/images/icons/Heart-violet.svg)'
    "
    #heartActive
  ></a>
</ng-template>
