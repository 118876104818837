import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SharedService } from 'app/shared/shared.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'prf-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  @Output() showSettings = new EventEmitter();
  _userInfo: any;
  _changePhoto: boolean = false;
  _changePassword: boolean = false;
  _changeEmail: boolean = false;
  _changePhone: boolean = false;

  constructor(private meta: Meta, private sharedService: SharedService) {
    this.meta.updateTag({ name: 'og:title', content: 'Настройки' });
  }

  ngOnInit() {
    this.sharedService
      .getUserInfoData()
      .pipe(take(1))
      .subscribe(r => (this._userInfo = r));
  }

  closeSettings() {
    this.showSettings.emit(false);
  }

  ngOnDestroy() {}
}
