import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { TutorPanelService } from 'app/pages/control-panel/tutor/tutor-shared/tutor.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { EDiagnStateEnum, ITutorDiagnosticInterface } from 'app/shared/interfaces/idiagnostic.interface';
import { IHistory } from 'app/shared/interfaces/ihistory.interface';
import {
  IScreeningTestsAccesses,
  IScreeningTestsAccessesResponse,
  ITutorPupilInterface,
} from 'app/shared/interfaces/itutorpupil.interface';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { IParentData, IPupilData } from './tutor-student-page.interface';

@Component({
  selector: 'prf-tutor-student-page',
  templateUrl: './tutor-student-page.component.html',
  styleUrls: ['./tutor-student-page.component.scss'],
})
export class TutorStudentPageComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  public pupil: IPupilData;
  public parent: IParentData;
  public userRole: string;
  public selectedDiagnostic: ITutorDiagnosticInterface;
  public showResults: boolean;
  public title: string = '';
  public diagnosticsIds: {
    motivationtest: string;
    careerTypes: string;
    valuesTest: string;
    GetPassportOfTalentsByLocation(activeLocation?: string): string;
    passportOfTalentsMO: string;
    passportOfTalents: string;
    promoTestMosRu: string;
    test360: string;
  } = TESTS_VARIANTS_IDS;
  public sessionList: IHistory[];
  public motivationLastSessionId: string;
  public valuesLastSessionId: string;
  public popUpTestAccess: boolean = false;
  public popUpResultsAccess: boolean = false;
  public isLoaded: boolean = false;
  public hideDiagnostics: boolean = true;
  public diagnosticsList: ITutorDiagnosticInterface[] = [
    {
      name: 'Таланты',
      description:
        'Диагностика об интересах и способностях подростка. Помогает продуктивно провести встречу с экспертом и разобрать рекомендации по профессиям.',
      screeningTestId: TESTS_VARIANTS_IDS.passportOfTalents,
      pupil: {
        testForPupil: true,
        isPupilStarted: false,
        isPupilTested: false,
      },
      parent: {
        testForParent: true,
        isParentStarted: false,
        isParentTested: false,
      },
      diagnosticOpened: true,
      diagnosticState: EDiagnStateEnum.TEST_OPENED,
      statusText: null,
      isComplexDiagnostic: false,
    },
    {
      name: 'Мотивация',
      description:
        'По результатам тестирования система покажет, что мотивирует подростка, и даст рекомендации, как прокачать его мотивацию.',
      screeningTestId: TESTS_VARIANTS_IDS.motivationtest,
      pupil: {
        testForPupil: true,
        isPupilStarted: false,
        isPupilTested: false,
      },
      parent: {
        testForParent: false,
      },
      diagnosticOpened: false,
      diagnosticState: EDiagnStateEnum.NOT_OPENED,
      statusText: null,
      isComplexDiagnostic: false,
    },
    {
      name: 'Ценности',
      description: 'По результатам тестирования система покажет, что важно для подростка и что влияет на его выборы и поступки.',
      screeningTestId: TESTS_VARIANTS_IDS.valuesTest,
      pupil: {
        testForPupil: true,
        isPupilStarted: false,
        isPupilTested: false,
      },
      parent: {
        testForParent: false,
      },
      diagnosticOpened: false,
      diagnosticState: EDiagnStateEnum.NOT_OPENED,
      statusText: null,
      isComplexDiagnostic: false,
    },
  ];

  private pupils: IPupilData[] = [];
  private screeningTestsIdsList: string[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private tutorService: TutorPanelService,
    private utilsService: UtilsService,
    private router: Router,
    private translate: TranslateService,
  ) {
    super();
    this.userRole = localStorage.getItem('userRole');
    this.diagnosticsList.forEach((diagnostic: ITutorDiagnosticInterface) => {
      if (diagnostic.screeningTestId) {
        this.screeningTestsIdsList.push(diagnostic.screeningTestId);
      }
    });
  }

  public ngOnInit(): void {
    let pupilsList$: Observable<IPupilData[]>;

    this.pupils = this.tutorService.getPupilsList();
    if (this.pupils.length) {
      pupilsList$ = of(this.pupils);
    } else {
      pupilsList$ = this.getPupils().pipe(takeUntil(this.unsubscribe));
    }

    this.tutorService
      .getPupilPage()
      .pipe(
        tap((data: boolean) => {
          this.showResults = data;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();

    pupilsList$
      .pipe(
        switchMap(pupils => {
          return this.activatedRoute.params.pipe(
            switchMap(params => {
              if (params?.uid) {
                this.pupil = pupils.find(pupil => pupil.userId === params.uid);
                if (this.pupil?.registrationCode) {
                  return this.tutorService.getPupilByCode(this.pupil.registrationCode).pipe(
                    switchMap((pupilByCode: ITutorPupilInterface) => {
                      this.parent = pupilByCode?.parents[0];
                      if (pupilByCode.comment.includes('not found')) {
                        this.utilsService.openSnackBar('Код не найден', 'error');

                        return of(null);
                      }

                      return this.getData();
                    }),
                  );
                } else {
                  return of(null);
                }
              } else {
                return of(null);
              }
            }),
            takeUntil(this.unsubscribe),
          );
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(() => {
        this.isLoaded = true;
      });
  }

  public openDiagnosticAccess(diagnostic: ITutorDiagnosticInterface): void {
    if (diagnostic) {
      this.popUpTestAccess = !this.popUpTestAccess;
      this.tutorService
        .openTestAccess(this.pupil.userId, diagnostic.screeningTestId)
        .pipe(
          switchMap(r => {
            if (r.status === 'Success') {
              this.utilsService.openSnackBar(`Разрешение прохождения теста открыто`, 'success');
              return this.getData();
            } else {
              this.utilsService.openSnackBar(`Произошла ошибка ${r.comment}`, 'error');
              return of(null);
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    }
  }

  public openResultsPage(diagnostic?: ITutorDiagnosticInterface): void {
    if (diagnostic?.diagnosticState >= EDiagnStateEnum.IS_TESTED) {
      this.selectedDiagnostic = this.diagnosticsList.find(
        (diag: ITutorDiagnosticInterface) => diag.screeningTestId === diagnostic.screeningTestId,
      );
      if (this.selectedDiagnostic) {
        this.tutorService.showResults$.next(true);
      }
    }
  }

  public openResultsAccess(diagnostic: ITutorDiagnosticInterface): void {
    if (diagnostic) {
      this.popUpResultsAccess = !this.popUpResultsAccess;
      forkJoin([
        this.tutorService.openResultsAccess(this.pupil.userId, diagnostic.screeningTestId),
        this.tutorService.openResultsAccess(this.parent.userId, diagnostic.screeningTestId),
      ])
        .pipe(
          switchMap(([pupilResponse, parentResponse]) => {
            if (pupilResponse.status === 'Success' && parentResponse.status === 'Success') {
              this.utilsService.openSnackBar(`Разрешение просмотра результатов открыто`, 'success');
              return this.getData();
            } else {
              this.utilsService.openSnackBar(
                `Произошла ошибка pupilResponse = ${pupilResponse.comment}; parentResponse = ${parentResponse.comment}`,
                'error',
              );
              return of(null);
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    }
  }

  public routingBack(): void {
    if (this.showResults) {
      this.tutorService.closeResults();
    } else {
      this.router.navigate(['/tutor/'], { state: { pupilId: this.pupil.userId } });
    }
  }

  public trackByFn(index: number): number {
    return index;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.tutorService.closeResults();
  }

  private getPupils(): Observable<IPupilData[]> {
    return this.tutorService.getPupils().pipe(
      tap((pupils: IPupilData[]) => {
        if (pupils && pupils.length) {
          this.pupils = pupils;
          this.tutorService.setPupilsList(pupils);
        }
      }),
    );
  }

  private getData(): Observable<IScreeningTestsAccessesResponse> {
    return this.getSessionStatus(this.pupil.userId, this.parent.userId).pipe(
      switchMap(() =>
        this.getDiagnosticsStatus().pipe(
          tap(() => {
            this.hideDiagnostics = false;
            this.checkDiagnosticStatusText();
          }),
        ),
      ),
    );
  }

  private getDiagnosticsStatus(): Observable<IScreeningTestsAccessesResponse> {
    return this.tutorService.getTestsAccessesByUser(this.pupil.userId).pipe(
      tap((response: IScreeningTestsAccessesResponse) => {
        if (response.status === 'Success') {
          response.screeningTestsAccesses.forEach((testElement: IScreeningTestsAccesses) => {
            const index: number = this.diagnosticsList.findIndex(
              (diag: ITutorDiagnosticInterface) => diag.screeningTestId === testElement.screeningTestId,
            );

            if (index === -1) return;

            if (testElement.isTestPassing) {
              if (this.diagnosticsList[index].diagnosticState === EDiagnStateEnum.NOT_OPENED) {
                this.diagnosticsList[index].diagnosticState = EDiagnStateEnum.TEST_OPENED;
              }

              this.diagnosticsList[index].diagnosticOpened = true;
            }

            if (testElement.isTestResult && this.diagnosticsList[index].diagnosticState === EDiagnStateEnum.IS_TESTED) {
              this.diagnosticsList[index].diagnosticState = EDiagnStateEnum.RESULTS_OPENED;
            }
          });
        }
      }),
    );
  }

  private getSessionStatus(pupilId: string, parentId: string): Observable<any> {
    return forkJoin([this.tutorService.getSessionList(pupilId), this.tutorService.getSessionList(parentId)]).pipe(
      tap(([pupilSessions, parentSessions]) => {
        this.sessionList = pupilSessions;

        this.motivationLastSessionId = pupilSessions
          .filter(session => session.screeningTestId === this.diagnosticsIds.motivationtest && session.completed)
          .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1))[0]?.sessionId;

        this.valuesLastSessionId = pupilSessions
          .filter(session => session.screeningTestId === this.diagnosticsIds.valuesTest && session.completed)
          .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1))[0]?.sessionId;

        this.screeningTestsIdsList.forEach((testId: string) => {
          this.setPupilTestStatus(testId);

          const indexParentTest: number = this.diagnosticsList.findIndex(
            (diag: ITutorDiagnosticInterface) => diag.screeningTestId === testId,
          );

          if (indexParentTest === -1) return;

          if (!parentSessions.length) {
            this.setParentStatus(indexParentTest, false, false);
            return;
          }

          const parentSessionCompleted: IHistory = this.getLastSession(true, testId, parentSessions);

          if (parentSessionCompleted) {
            this.setParentStatus(indexParentTest, false, true, EDiagnStateEnum.IS_TESTED);
          } else {
            const parentSessionUncompleted: IHistory = this.getLastSession(false, testId, parentSessions);
            if (parentSessionUncompleted) {
              this.setParentStatus(indexParentTest, true);
            }
          }
        });
      }),
    );
  }

  private setPupilTestStatus(testId: string): void {
    const indexTest: number = this.diagnosticsList.findIndex((diag: ITutorDiagnosticInterface) => diag.screeningTestId === testId);

    if (indexTest === -1) return;

    if (!this.sessionList.length) {
      this.setPupilStatus(indexTest, false, false);
      return;
    }

    const lastSessionCompleted: IHistory = this.getLastSession(true, testId, this.sessionList);

    if (lastSessionCompleted) {
      this.setPupilStatus(indexTest, false, true, EDiagnStateEnum.IS_TESTED);
      return;
    }

    const lastSessionUncompleted: IHistory = this.getLastSession(false, testId, this.sessionList);

    if (lastSessionUncompleted) {
      this.setPupilStatus(indexTest, true);
      return;
    }
  }

  private getLastSession(completedStatus: boolean, testId: string, sessionList: IHistory[]): IHistory {
    return sessionList
      .filter((session: IHistory) => session.screeningTestId === testId && session.completed === completedStatus)
      .sort((a: IHistory, b: IHistory) => (new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1))[0];
  }

  private setPupilStatus(idx: number, started: boolean = false, tested: boolean = false, diagState?: EDiagnStateEnum): void {
    this.diagnosticsList[idx].pupil.isPupilStarted = started;
    this.diagnosticsList[idx].pupil.isPupilTested = tested;
    if (diagState) this.diagnosticsList[idx].diagnosticState = diagState;
  }

  private setParentStatus(idx: number, started: boolean = false, tested: boolean = false, diagState?: EDiagnStateEnum): void {
    this.diagnosticsList[idx].parent.isParentStarted = started;
    this.diagnosticsList[idx].parent.isParentTested = tested;
    if (diagState) this.diagnosticsList[idx].diagnosticState = diagState;
  }

  private checkDiagnosticStatusText(): void {
    this.diagnosticsList.forEach((diag: ITutorDiagnosticInterface) => {
      //совместный тест
      if (diag.pupil.testForPupil && diag.parent.testForParent) {
        switch (true) {
          case diag.diagnosticState === EDiagnStateEnum.RESULTS_OPENED && diag.parent.isParentStarted && diag.parent.isParentTested:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.RESULT_CONFIRMED');
            break;
          case diag.diagnosticState === EDiagnStateEnum.RESULTS_OPENED && diag.parent.isParentStarted && !diag.parent.isParentTested:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.RESULT_CONFIRMED_PARENT_INPROGRESS');
            break;
          case diag.diagnosticState === EDiagnStateEnum.RESULTS_OPENED && !diag.parent.isParentTested && !diag.parent.isParentStarted:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.RESULT_CONFIRMED_PARENT_NOTFINISHED');
            break;
          case diag.diagnosticState === EDiagnStateEnum.RESULTS_OPENED && diag.pupil.isPupilTested && diag.parent.isParentTested:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.RESULT_CONFIRMED');
            break;
          case diag.diagnosticState === EDiagnStateEnum.IS_TESTED && diag.pupil.isPupilTested && diag.parent.isParentTested:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PARENT_PUPIL_FINISHED');
            break;
          case diag.diagnosticState === EDiagnStateEnum.IS_TESTED && diag.pupil.isPupilTested && diag.parent.isParentStarted:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PUPIL_FINISHED_PARENT_STARTED');
            break;
          case diag.diagnosticState === EDiagnStateEnum.IS_TESTED && diag.pupil.isPupilStarted && diag.parent.isParentTested:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PARENT_FINISHED_PUPIL_STARTED');
            break;
          case diag.diagnosticState === EDiagnStateEnum.IS_TESTED && diag.pupil.isPupilStarted && diag.parent.isParentStarted:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PARENT_PUPIL_STARTED');
            break;
          case diag.diagnosticState === EDiagnStateEnum.IS_TESTED && !diag.pupil.isPupilStarted && diag.parent.isParentTested:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PARENT_FINISHED_PUPIL_NOTSTARTED');
            break;
          case diag.diagnosticState === EDiagnStateEnum.IS_TESTED && diag.pupil.isPupilTested && !diag.parent.isParentStarted:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PUPIL_FINISHED_PARENT_NOTSTARTED');
            break;
          case diag.diagnosticState === EDiagnStateEnum.TEST_OPENED && !diag.pupil.isPupilStarted && diag.parent.isParentStarted:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PARENT_STARTED_PUPIL_NOT');
            break;
          case diag.diagnosticState === EDiagnStateEnum.TEST_OPENED && diag.pupil.isPupilStarted && !diag.parent.isParentStarted:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PUPIL_STARTED_PARENT_NOT');
            break;

          default:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.DIAGNOSTIC_NOT_STARTED');
            break;
        }
      } else if (diag.pupil.testForPupil && !diag.parent.testForParent) {
        //тест ученика
        switch (true) {
          case diag.diagnosticState === EDiagnStateEnum.RESULTS_OPENED:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.RESULT_CONFIRMED');
            break;
          case diag.pupil.isPupilTested:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PUPIL_FINISHED');
            break;
          case diag.pupil.isPupilStarted:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PUPIL_STARTED');
            break;
          case !diag.pupil.isPupilStarted && diag.diagnosticState === EDiagnStateEnum.TEST_OPENED:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PUPIL_NOT_STARTED');
            break;
          default:
            diag.statusText = this.translate.instant('DIAGNOSTICS_PROGRESS.PROCESS_PROGRESS');
            break;
        }
      }
    });
  }
}
