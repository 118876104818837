import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

import { IRegion } from 'app/shared/interfaces/iregion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RegionsService } from '../regions.service';

@Component({
  selector: 'prf-add-region',
  templateUrl: './add-region.component.html',
  styleUrls: ['./add-region.component.scss'],
})
export class AddRegionComponent implements OnInit {
  @Output() regionAdded = new EventEmitter<any>();

  public region: IRegion = {
    id: '',
    name: '',
    hrid: '',
  };

  dialogOpen: boolean = false;
  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private regionsService: RegionsService, private utilsService: UtilsService) {}

  ngOnInit() {}

  onSubmit(valid: boolean) {
    if (valid) {
      let regionData = {
        hrid: this.region.hrid,
        name: this.region.name,
      };
      if (regionData.name && regionData.hrid) {
        this.regionsService
          .createRegion(regionData)
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(
            response => {
              this.utilsService.openSnackBar('👌 Регион добавлен', 'success');
              return setTimeout(() => {
                this.regionAdded.emit({
                  id: response,
                  hrid: regionData.hrid,
                  name: regionData.name,
                });
                this.addRegion();
              }, 300);
            },
            err => {
              return this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте позже', 'error');
            },
          );
      } else {
        return;
      }
    }
  }

  addRegion() {
    this.dialogOpen = !this.dialogOpen;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
