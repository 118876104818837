import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'prf-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit {
  _passwords: any = {
    currentPassword: null,
    newPassword: null,
    newPassword2: null,
  };
  currentPassword = new UntypedFormControl('', [Validators.required, Validators.email]);
  newPasswordFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  newPasswordFormControl2 = new UntypedFormControl('', [Validators.required, Validators.email]);

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {}

  // Update password
  onSubmit(valid: boolean) {
    if (valid) {
      this.settingsService.changePassword(this._passwords);
    }
  }
}
