<ng-container *ngIf="!showResults; else passedTest">
  <div class="welcome-section-2">
    <div class="welcome-row-2">
      <div class="welcome-column-stud">
        <div class="welc-num">1</div>
        <div *ngIf="isTestNew; else notFinishedTest">
          <h3 class="welcome-h3-stud">{{ 'PROFESSIONS.LAUNCH_TEST' | translate }}</h3>
          <p class="welcome-text-stud">
            {{ 'SHARED.SYSTEM_DIAGNOSTIC_NARRATIVE' | translate }}
          </p>
          <div class="main-screen-buttons-row">
            <a routerLink="/test-na-professiyu" (click)="setTypeTest()" class="button welcome-button-stud w-button">{{
              'SHARED.START_TEST' | translate
            }}</a>
          </div>
        </div>
        <div></div>
      </div>

      <img *ngIf="isMale(userGender)" src="/assets/images/dashboard/Pupil-m.svg" width="402" class="welcome-image-stud" />
      <img *ngIf="!isMale(userGender)" src="/assets/images/dashboard/Pupil-w.svg" width="402" class="welcome-image-stud" />
    </div>
  </div>
  <ng-template #notFinishedTest>
    <prf-pupil-uncompleted-test [sessionId]="testSessionId" *ngIf="testSessionId"></prf-pupil-uncompleted-test>
  </ng-template>
</ng-container>

<ng-template #passedTest>
  <div class="welcome-section-2 no-active completed-test">
    <div class="welcome-row-2 _2">
      <div class="welcome-column-stud no-active">
        <div class="welc-num complete">1</div>
        <div>
          <h3 class="welcome-h3-stud green">{{ 'SHARED.TEST_PASSED' | translate }}</h3>
        </div>
      </div>
      <a routerLink="/results/{{ testSessionId }}" class="welc-completed-test-link" *ngIf="testSessionId">Результаты теста</a>
    </div>
  </div>
</ng-template>
