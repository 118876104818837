import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AdminPanelService } from '../../admin-panel.service';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { RegionsService } from '../../regions/regions.service';
import { SystemService } from '../system.service';

interface IListedOption {
  name: string | number;
  data: any;
}

@Component({
  selector: 'prf-system-statistics',
  templateUrl: './system-statistics.component.html',
  styleUrls: ['./system-statistics.component.scss'],
})
export class SystemStatisticsComponent implements OnInit, OnDestroy {
  regions: IRegion[] = [];
  listedRegions: IListedOption[] = [];

  municipalitiesByRegion: IMunicipality[] = [];
  listedMunicipalities: IListedOption[] = [];

  allSchools: ISchool[] = [];
  listedSchools: IListedOption[] = [];
  listedSchoolsAnalytics: IListedOption[] = [];

  schoolRegionMunicipalities: IListedOption[] = [];

  allSchoolsUpdating: boolean = false;
  allSchoolsForYearUpdating: boolean = false;
  allSchoolsAnalyticsUpdating: boolean = false;

  reportYears: any[] = [
    { name: 'За все время', value: 0 },
    { name: '2020', value: 2020 },
    { name: '2021', value: 2021 },
    { name: '2022', value: 2022 },
  ];

  public dataFetched: boolean = false;
  public form: UntypedFormGroup;

  private ngUnsubscribe$: Subject<any> = new Subject();
  public clickEvent$: Subject<any> = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private meta: Meta,
    private adminService: AdminPanelService,
    private systemService: SystemService,
    private utilsService: UtilsService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private adminPanelService: AdminPanelService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Статистика' });
  }

  ngOnInit() {
    this.initForm();
    this.getData();

    this.f.schoolUpdate
      .get('schoolRegion')
      .valueChanges.pipe(
        mergeMap(regionSelected => {
          if (regionSelected && regionSelected.data) {
            let selectedRegion = this.regions.find(region => region.hrid === regionSelected.data);
            return this.loadSchoolsStatistic(selectedRegion.id).pipe(
              switchMap((schools: IListedOption[]) => {
                this.listedSchools = schools;
                this.f.schoolUpdate.get('schoolMunicipality').reset();
                this.f.schoolUpdate.get('school').reset();

                return this.municipalitiesService.getMunicipalitiesByRegion(selectedRegion.id).pipe(
                  mergeMap(municipalities => {
                    this.schoolRegionMunicipalities = [];
                    municipalities.forEach(municipality =>
                      this.schoolRegionMunicipalities.push({
                        name: municipality.name,
                        data: municipality.hrid,
                      }),
                    );
                    return this.f.schoolUpdate.get('schoolMunicipality').valueChanges.pipe(
                      mergeMap(municipalitySelected => {
                        if (municipalitySelected && municipalitySelected.data) {
                          this.f.schoolUpdate.get('school').reset();
                          let selectedMunicipality = municipalities.find(municipality => municipality.hrid === municipalitySelected.data);
                          if (selectedMunicipality && selectedMunicipality.id) {
                            return this.loadSchoolsStatistic(selectedRegion.id, selectedMunicipality.id).pipe(
                              tap((schools: IListedOption[]) => {
                                this.listedSchools = schools;
                              }),
                            );
                          } else {
                            return of(null);
                          }
                        } else {
                          return of(null);
                        }
                      }),
                    );
                  }),
                );
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();

    this.f.analyticsUpdate
      .get('oneSchool')
      .get('region')
      .valueChanges.pipe(
        mergeMap(regionSelected => {
          if (regionSelected && regionSelected.data) {
            let selectedRegion = this.regions.find(region => region.hrid === regionSelected.data);

            return this.loadSchoolsStatistic(selectedRegion.id).pipe(
              switchMap((schools: IListedOption[]) => {
                this.listedSchoolsAnalytics = schools;
                this.f.analyticsUpdate.get('oneSchool').get('municipality').reset();
                this.f.analyticsUpdate.get('oneSchool').get('school').reset();

                return this.municipalitiesService.getMunicipalitiesByRegion(selectedRegion.id).pipe(
                  mergeMap(municipalities => {
                    this.schoolRegionMunicipalities = [];
                    municipalities.forEach(municipality =>
                      this.schoolRegionMunicipalities.push({
                        name: municipality.name,
                        data: municipality.hrid,
                      }),
                    );
                    return this.f.analyticsUpdate
                      .get('oneSchool')
                      .get('municipality')
                      .valueChanges.pipe(
                        mergeMap(municipalitySelected => {
                          if (municipalitySelected && municipalitySelected.data) {
                            this.f.analyticsUpdate.get('oneSchool').get('school').reset();
                            let selectedMunicipality = municipalities.find(municipality => municipality.hrid === municipalitySelected.data);
                            if (selectedMunicipality && selectedMunicipality.id) {
                              return this.loadSchoolsStatistic(selectedRegion.id, selectedMunicipality.id).pipe(
                                tap((schools: IListedOption[]) => {
                                  this.listedSchoolsAnalytics = schools;
                                }),
                              );
                            } else {
                              return of(null);
                            }
                          } else {
                            return of(null);
                          }
                        }),
                      );
                  }),
                );
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  initForm() {
    this.form = this.fb.group({
      schoolUpdate: this.fb.group({
        schoolRegion: new UntypedFormControl(null),
        schoolMunicipality: new UntypedFormControl(null),
        school: new UntypedFormControl(),
      }),
      analyticsUpdate: this.fb.group({
        regionSchools: this.fb.group({
          region: new UntypedFormControl(null),
          startDate: new UntypedFormControl(null),
          endDate: new UntypedFormControl(null),
        }),
        oneSchool: this.fb.group({
          region: new UntypedFormControl(null),
          municipality: new UntypedFormControl(null),
          school: new UntypedFormControl(),
          startDate: new UntypedFormControl(null),
          endDate: new UntypedFormControl(null),
        }),
      }),
      updateWithYear: this.fb.group({
        region: new UntypedFormControl(null),
        year: new UntypedFormControl(null, []),
      }),
    });
  }

  get f() {
    return this.form.controls;
  }

  getData() {
    this.dataFetched = false;
    forkJoin([this.regionsService.getAllRegions(), this.adminService.getCatalogSchools()])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([regions, schools]) => {
        this.regions = regions;
        this.regions.forEach(region =>
          this.listedRegions.push({
            name: region.name,
            data: region.hrid,
          }),
        );
        this.allSchools = schools;
        this.allSchools.forEach(school => {
          this.listedSchools.push({
            name: school.number,
            data: school.id,
          });
          this.listedSchoolsAnalytics.push({
            name: school.number,
            data: school.id,
          });
        });

        this.dataFetched = true;
      });
  }

  loadSchoolsStatistic(regionId?, municipalityId?): Observable<IListedOption[]> {
    return this.adminService.getAllSchoolsStatistic(regionId, municipalityId).pipe(
      map(statistics => {
        let schools: IListedOption[] = [];
        statistics.schoolStatistics.forEach(p => {
          let oneSchool = this.allSchools.find(sc => sc.id == p.schoolId);
          if (oneSchool) {
            schools.push({ name: oneSchool.number, data: oneSchool.id });
          }
        });
        return schools;
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  updateAllSchools() {
    this.allSchoolsUpdating = true;
    this.systemService
      .updateAllSchoolsStatistic()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        res => {
          this.allSchoolsUpdating = false;
        },
        err => {
          this.allSchoolsUpdating = false;
        },
      );
  }

  updateAllSchoolsForYear() {
    this.allSchoolsForYearUpdating = true;
    const selectedYear = this.form.get('updateWithYear').get('year').value.value;
    const selectedRegion = this.form.get('updateWithYear').get('region').value.data;
    let queryRegion = this.regions.find(region => region.hrid === selectedRegion);

    this.adminPanelService
      .updateAllSchoolsStatistic(queryRegion.id, selectedYear)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        res => {
          this.allSchoolsForYearUpdating = false;
        },
        err => {
          this.allSchoolsForYearUpdating = false;
        },
      );
  }

  updateSchool() {
    let selectedSchool = this.allSchools.find(school => school.id === this.form.get('schoolUpdate').get('school').value.data);
    if (selectedSchool) {
      this.form.get('schoolUpdate').get('schoolRegion').disable();
      this.form.get('schoolUpdate').get('schoolMunicipality').disable();
      this.form.get('schoolUpdate').get('school').disable();
      this.systemService
        .updateSchoolStatistic(selectedSchool.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(res => {
          if (res.status === 'Success') {
            this.utilsService.openSnackBar('Данные по школе обновлены', 'success');
          } else {
            this.utilsService.openSnackBar(`Произошла ошибка: ${res.comment}`, 'error');
          }
          this.form.get('schoolUpdate').get('school').enable();
          this.form.get('schoolUpdate').get('school').setValue(null);
          this.form.get('schoolUpdate').get('schoolRegion').enable();
          this.form.get('schoolUpdate').get('schoolRegion').setValue(null);
          this.form.get('schoolUpdate').get('schoolMunicipality').enable();
          this.form.get('schoolUpdate').get('schoolMunicipality').setValue(null);
        });
    }
  }

  updateAllSchoolsAnalytics() {
    let selectedregion = this.regions.find(region => region.hrid === this.f.analyticsUpdate.get('regionSchools').get('region').value.data);
    let dateStart = new Date(this.f.analyticsUpdate.get('regionSchools').get('startDate').value);
    let dateEnd = this.f.analyticsUpdate.get('regionSchools').get('endDate').value
      ? new Date(this.f.analyticsUpdate.get('regionSchools').get('endDate').value)
      : new Date();

    if (selectedregion && dateEnd > dateStart) {
      this.allSchoolsAnalyticsUpdating = true;
      this.f.analyticsUpdate.get('regionSchools').get('region').disable();
      this.systemService
        .updateAllSchoolsAnalytics(selectedregion.id, dateStart, dateEnd)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(res => {
          if (res.status === 'Success') {
            res.comment
              ? this.utilsService.openSnackBar(`Аналитика по всем школам обновлена, comment: ${res.comment}`, 'success')
              : this.utilsService.openSnackBar('Аналитика по всем школам обновлена', 'success');
          } else {
            this.utilsService.openSnackBar(`Произошла ошибка: ${res.comment}`, 'error');
          }
          this.allSchoolsAnalyticsUpdating = false;
        });
    }
  }
  updateOneSchoolAnalytics() {
    let selectedSchool = this.allSchools.find(school => school.id === this.f.analyticsUpdate.get('oneSchool').get('school').value.data);
    let dateStart = new Date(this.f.analyticsUpdate.get('oneSchool').get('startDate').value);
    let dateEnd = this.f.analyticsUpdate.get('oneSchool').get('endDate').value
      ? new Date(this.f.analyticsUpdate.get('oneSchool').get('endDate').value)
      : new Date();

    if (selectedSchool && dateEnd > dateStart) {
      this.f.analyticsUpdate.get('oneSchool').get('region').disable();
      this.f.analyticsUpdate.get('oneSchool').get('municipality').disable();
      this.f.analyticsUpdate.get('oneSchool').get('school').disable();
      this.systemService
        .updateSchoolAnalytics(selectedSchool.id, dateStart, dateEnd)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(res => {
          if (res && res.length) {
            this.utilsService.openSnackBar('Аналитика по школе обновлена', 'success');
          } else {
            this.utilsService.openSnackBar(`Произошла ошибка: ${res.comment}`, 'error');
          }
          this.form.get('analyticsUpdate').get('oneSchool').get('school').enable();
          this.form.get('analyticsUpdate').get('oneSchool').get('school').setValue(null);
          this.form.get('analyticsUpdate').get('oneSchool').get('region').enable();
          this.form.get('analyticsUpdate').get('oneSchool').get('region').setValue(null);
          this.form.get('analyticsUpdate').get('oneSchool').get('municipality').enable();
          this.form.get('analyticsUpdate').get('oneSchool').get('municipality').setValue(null);
        });
    }
  }

  checkDate(date): boolean {
    return date && new Date(date) < new Date();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
