import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { ProfessionsService } from '../professions/professions.service';
import { ParentPanelService } from 'app/pages/control-panel/parent/parent-panel.service';
import { SharedService } from 'app/shared/shared.service';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';

@Component({
  selector: 'prf-recommendation-menu',
  templateUrl: './recommendation-menu.component.html',
  styleUrls: ['./recommendation-menu.component.scss'],
})
export class RecommendationMenuComponent {
  @Input()
  set courseId(val: string) {
    this._courseId = val;
    this.init().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  @Input() recommendationType: string = 'Profession'; // [Profession, Class]

  public isOpen: boolean = false;
  public userList: Array<any> = [];
  public userListFiltered: Array<any> = [];
  public userId: string = '';
  public userRole: string = '';
  public selectedAll: boolean = false;
  public searchText: string = '';
  public isRecommended: boolean = false;

  private _courseId: string = '';

  @ViewChild('recommendationMenu') recommendationMenu: ElementRef;

  get selectedAny(): boolean {
    return this.userList.some(u => u._recommended === true && u.recommended === false);
  }

  get recommendedAll(): boolean {
    if (this.userList && this.userList.length > 0) {
      return !this.userList.some(u => u.recommended === false);
    } else {
      return false;
    }
  }

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private sharedService: SharedService,
    private professionService: ProfessionsService,
    private parentPanelService: ParentPanelService,
  ) {
    this.userId = localStorage.getItem('userId');
    this.userRole = localStorage.getItem('userRole');
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.recommendationMenu && !this.recommendationMenu?.nativeElement.contains(target)) {
      this.isOpen = false;
    }
  }

  public init(): Observable<any> {
    let userIdList: any;

    let currentObservable$: Observable<any>;
    if (this.userRole === 'parent') {
      currentObservable$ = this.sharedService.getUserInfoData().pipe(
        tap(data => {
          if (data.children && data.children.length > 0) {
            this.userList = data.children.map(child => {
              child._recommended = false;
              child.recommended = false;
              child.imagePath = child.imagePath ? child.imagePath : '/assets/images/dashboard/no-photo.svg';
              return child;
            });

            this.userListFiltered = this.userList;
          }
        }),
      );
    } else {
      currentObservable$ = of(null);
    }

    return currentObservable$.pipe(
      switchMap(r => {
        return this.professionService.getUserIdsWithRecommendationByParent(this._courseId, this.recommendationType).pipe(
          tap(userIdList => {
            this.userList = this.userList.map(user => {
              const recommended = userIdList.some(id => id === user.userId);
              user.recommended = recommended;
              user._recommended = recommended;
              return user;
            });
          }),
        );
      }),
    );
  }

  public onInputSearchText(e: any) {
    this.searchText = e.target.value.toLowerCase();

    this.userListFiltered = this.userList.filter(user => {
      const name = (user.lastName + ' ' + user.firstName + ' ' + user.middleName).toLowerCase();
      return name.indexOf(this.searchText) > -1;
    });
  }

  public clearSearch(input: any) {
    input.value = '';
    this.searchText = '';
    this.userListFiltered = this.userList;
  }

  public onCheckAll() {
    this.selectedAll = !this.selectedAll;
    this.userList.forEach((child, index) => {
      if (this.userList[index].recommended === false) {
        this.userList[index]._recommended = this.selectedAll;
      }
    });
  }

  public onCheckChild(user: any) {
    let index = null;

    this.userList.forEach((u, i) => {
      if (u === user && u.recommended === false) {
        index = i;
      }
    });

    if (index !== null) {
      this.userList[index]._recommended = !this.userList[index]._recommended;
    }
  }

  public recommendProfession() {
    if (this.userList && this.userList.length > 0) {
      let userRecommendations$: Observable<any>[] = [];
      this.userList.forEach(child => {
        if (child._recommended === true) {
          if (this.userRole === 'parent') {
            userRecommendations$.push(
              this.professionService.addUserRecommendationByParent(child.userId, this._courseId, this.recommendationType, 'Parent'),
            );
          }
          child.recommended = true;
          this.isRecommended = true;
        }
      });

      observableForkJoin(userRecommendations$)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(() => (this.isOpen = false));
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
