import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CourseDetailsService } from '../catalog/courses-page-without-filters/course-details/course-details.service';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'prf-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss'],
})
export class EventsPageComponent implements OnInit, OnDestroy {
  public userRole: string = '';
  public tabName: string = 'catalog';

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private courseDetailsService: CourseDetailsService,
    private sharedService: SharedService,
    private favoritesService: FavoritesService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Мероприятия' });
  }

  ngOnInit() {
    this.userRole = localStorage.getItem('userRole');
    let userId = localStorage.getItem('userId');
    this.favoritesService
      .getUserFavorites(userId, FavoritesTypes.Class)
      .pipe(
        tap(favorites => {
          this.favoritesService.setFavoriteCourses(favorites);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
