<div class="content-column _2">
  <div class="test-block">
    <h3>{{ 'SHARED.TEST_PASSED' | translate }}!</h3>
    <div class="graphic">
      <prf-spider-chart-custom [inputSpiderChartData]="skillsAttainmentData"
                               [theme]="weAreParent ? SpiderChartThemeEnum.BLUE : SpiderChartThemeEnum.GREEN"
                               [figureType]="SpiderChartFiguresEnum.HEXAGON">

      </prf-spider-chart-custom>
    </div>
    <div class="parameters">
      <div class="parameter-block">
        <div>{{ skillsAttainmentData[0][3].axis }}</div>
        <div class="parameter-line">
          <div class="parameter-line-color" [ngStyle]="{ width: skillsAttainmentData[0][3].value * 100 + '%' }"></div>
          <div class="parameter-number">
            {{ skillsAttainmentData[0][3].value * 100 | number : '1.0-0' }}
          </div>
        </div>
      </div>
      <div class="parameter-block">
        <div>{{ skillsAttainmentData[0][4].axis }}</div>
        <div class="parameter-line">
          <div class="parameter-line-color purple" [ngStyle]="{ width: skillsAttainmentData[0][4].value * 100 + '%' }"></div>
          <div class="parameter-number">
            {{ skillsAttainmentData[0][4].value * 100 | number : '1.0-0' }}
          </div>
        </div>
      </div>
      <div class="parameter-block">
        <div>{{ skillsAttainmentData[0][2].axis }}</div>
        <div class="parameter-line">
          <div class="parameter-line-color blue" [ngStyle]="{ width: skillsAttainmentData[0][2].value * 100 + '%' }"></div>
          <div class="parameter-number">
            {{ skillsAttainmentData[0][2].value * 100 | number : '1.0-0' }}
          </div>
        </div>
      </div>
      <div class="parameter-block">
        <div>{{ skillsAttainmentData[0][5].axis }}</div>
        <div class="parameter-line">
          <div class="parameter-line-color orange" [ngStyle]="{ width: skillsAttainmentData[0][5].value * 100 + '%' }"></div>
          <div class="parameter-number">
            {{ skillsAttainmentData[0][5].value * 100 | number : '1.0-0' }}
          </div>
        </div>
      </div>
      <div class="parameter-block">
        <div>{{ skillsAttainmentData[0][0].axis }}</div>
        <div class="parameter-line">
          <div class="parameter-line-color pink" [ngStyle]="{ width: skillsAttainmentData[0][0].value * 100 + '%' }"></div>
          <div class="parameter-number">
            {{ skillsAttainmentData[0][0].value * 100 | number : '1.0-0' }}
          </div>
        </div>
      </div>
      <div class="parameter-block">
        <div>{{ skillsAttainmentData[0][1].axis }}</div>
        <div class="parameter-line">
          <div class="parameter-line-color yellow" [ngStyle]="{ width: skillsAttainmentData[0][1].value * 100 + '%' }"></div>
          <div class="parameter-number">
            {{ skillsAttainmentData[0][1].value * 100 | number : '1.0-0' }}
          </div>
        </div>
      </div>
    </div>
    <a *ngIf="userRole === 'pupil'" routerLink="/results" class="button achievements-button w-button">
      {{ 'SHARED.WATCH_RESULTS' | translate }}
    </a>
    <a *ngIf="userRole !== 'pupil'" routerLink="/results/{{ sessionId }}" class="button achievements-button w-button">
      {{ 'SHARED.WATCH_RESULTS' | translate }}
    </a>
  </div>
  <!-- Скрыл блок для прохождения теста Престиж Профессий -->
  <!-- <div class="test-block" *ngIf="professionTest">
    <a (click)="startProfessionPrestigeTest()" class="button achievements-button w-button green-btn">
      Пройти опрос о профессиях
    </a>
  </div> -->
</div>
