export const consultsMotivationData = {
  consultsUrl: 'https://career.profilum.ru/?utm_source=mosru&utm_medium=banner_mosru&utm_campaign=promo_mosru',
  consultantName: 'Карьерный наставник',
  consultantPhotoUrl: '/assets/images/consults/career_consultant.jpg',
  consultPrice: '3900',
  consultDiscount: null,
  consultCurrency: 'Рублей',
  consultsTime: '60',
  consultTimeUnits: 'Минут',
};

export const consultsPromoData = {
  consultsUrl: 'https://career.profilum.ru/mosru/?utm_source=mosru&utm_medium=banner_mosru&utm_campaign=promo_mosru',
  consultantName: 'Хотите выбрать профиль обучения и будущую профессию?',
  consultantPhotoUrl: '/assets/images/consults/promotest-subscribe.png',
  consultPrice: '3900',
  consultDiscount: '200',
  consultCurrency: 'Рублей',
  consultsTime: null,
  consultTimeUnits: null,
};
