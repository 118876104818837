import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoritesComponent } from './favorites.component';
import { FavoritesService } from './favorites.service';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [FavoritesComponent],
  imports: [CommonModule, BrowserModule, RouterModule, SharedModule],
  providers: [FavoritesService],
})
export class FavoritesModule {}
