import { Component, EventEmitter, Input, Output } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ClipboardService } from 'ngx-clipboard';
import { SharedService } from 'app/shared/shared.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { ParentPanelService } from '../../parent-panel.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

export enum EChildInviteSteps {
  CONFIRM_PHONE = 1,
  EDIT_PHONE,
  LINK_SENT,
}
@Component({
  selector: 'prf-parent-child-invite',
  templateUrl: './parent-child-invite.component.html',
  styleUrls: ['./parent-child-invite.component.scss'],
})
export class ParentChildInviteComponent extends UnsubscribeComponent {
  public readonly phoneRegExp: RegExp = REG_EXP.fullPhoneRegExp;
  public phone: UntypedFormControl = new UntypedFormControl(null, [Validators.pattern(this.phoneRegExp)]);
  public whatsAppLink: string;
  public showPopUp: boolean = false;
  public phoneMask = ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  public phoneChecked: boolean = false;
  @Input() childInviteStep: EChildInviteSteps = EChildInviteSteps.CONFIRM_PHONE;

  @Input() childPhoneNumber: any;
  @Input() childRegistered: boolean = false;
  @Input() childTested: boolean = false;

  @Output() changePhoneNumber: EventEmitter<string> = new EventEmitter<string>();
  @Output() openSnackBar: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private clipboardService: ClipboardService,
    private sharedService: SharedService,
    private parentService: ParentPanelService,
    private utilsService: UtilsService,
  ) {
    super();
  }

  public openPopup(): void {
    this.showPopUp = true;
    this.phone.setValue(this.childPhoneNumber);
    this.childInviteStep = EChildInviteSteps.CONFIRM_PHONE;
    this.phone.disable();
  }

  public editPhone(): void {
    this.phone.enable();
    document.getElementById('phone').focus();
    this.childInviteStep = EChildInviteSteps.EDIT_PHONE;
  }

  public sendToChild(): void {
    if (this.childInviteStep === EChildInviteSteps.CONFIRM_PHONE) {
      this.parentService
        .inviteChild()
        .pipe(
          take(1),
          tap(response => {
            if (response.status === 'Success') {
              this.childInviteStep = EChildInviteSteps.LINK_SENT;
              this.clipboardService.copy(response.registrationLink.replace('%26', '&'));
            } else {
              this.openSnackBar.emit(response);
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    } else if (this.childInviteStep === EChildInviteSteps.EDIT_PHONE) {
      if (!this.phoneChanged()) {
        this.parentService
          .inviteChild()
          .pipe(
            take(1),
            switchMap(response => {
              if (response.status === 'Success') {
                this.childInviteStep = EChildInviteSteps.LINK_SENT;
                return this.sharedService.requestUserInfo();
              } else {
                this.openSnackBar.emit(response);
                return of(null);
              }
            }),
            takeUntil(this.unsubscribe),
          )
          .subscribe();
      } else {
        this.changePhoneNumber.emit(this.phone.value);
      }
    }
  }

  private phoneChanged(): boolean {
    return this.phone.value.replace(/\D/g, '') != this.childPhoneNumber;
  }

  public checkPhone(): void {
    this.phoneChecked = true;
    this.phone.setErrors(null);

    const phone: string = this.phone.value.replace(/\D/g, '');

    !this.phoneRegExp.test(this.phone.value.replace(/\D/g, '')) ? this.phone.setErrors({ notValid: true }) : null;

    if (this.phoneChanged() && !this.phone.errors) {
      this.parentService
        .checkPhone(phone)
        .pipe(
          take(1),
          catchError(err => {
            this.utilsService.openSnackBar('Неисправности с базой данных, уже чиним.', 'error');
            return throwError(err);
          }),
          tap(response => {
            if (response.status === 'Success') {
              if (!response.isFree) {
                this.phone.setErrors({ isUsed: true });
              }
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    }
  }

  public close(): void {
    this.showPopUp = false;
  }
}
