import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectVariables } from '../../../../core/project.variables';
import { PupilPanelService } from '../../../../pages/control-panel/pupil/pupil-panel.service';
import {
  SpiderChartFiguresEnum,
  SpiderChartThemeEnum,
} from '@profilum-components/spider-chart-custom/spider-chart-custom.model';

@Component({
  selector: 'prf-test-result',
  templateUrl: './test-result.component.html',
  styleUrls: ['./test-result.component.scss'],
})
export class TestResultComponent implements OnInit {
  @Input() skillsAttainmentData;
  @Input() weAreParent;

  public userRole: string = '';
  public sessionId: string = '';
  public professionTest: boolean = false;

  constructor(private router: Router, private pupilService: PupilPanelService, private projectVar: ProjectVariables) {
    this.userRole = localStorage.getItem('userRole');
    this.sessionId = localStorage.getItem('testSessionId');
  }

  ngOnInit() {}

  // скрыл методы для прохождения Престижа Профессий
  // async ngOnInit() {
  //   let sessions = await this.pupilService.getTestingSessions(localStorage.getItem('userId'));
  //   sessions = sessions.filter(session => session.screeningTestId === this.projectVar.professionTestID);
  //   if (sessions.length === 0) {
  //     this.professionTest = true;
  //   }
  // }

  // async startProfessionPrestigeTest() {
  //   localStorage.setItem('testType', ETestTypes.PROFESSIONPRESTIGE.toString());
  //   this.router.navigate(['/test-na-professiyu']);
  // }
  protected readonly SpiderChartThemeEnum = SpiderChartThemeEnum;
  protected readonly SpiderChartFiguresEnum = SpiderChartFiguresEnum;
}
