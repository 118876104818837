import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'app/core/services/http.service';
import { PlayerService } from 'app/pages/player/player.service';
import { IProfessionFilter } from 'app/shared/interfaces/iprofessionfilter.interface';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ResultsService  {
  PROGRAMS_AMOUNT: number;
  TALENTS_TO_CHOOSE: number;
  programmsImages = {
    'Финансы и менеджмент': '/assets/images/talants/fin_i_man.png',
    'Спорт и военное дело': '/assets/images/talants/sport_i_voen.png',
    'Естественные науки': '/assets/images/talants/est_nauk.png',
    'Информационные технологии': '/assets/images/talants/inf_tec.png',
    'Языки и лингвистика': '/assets/images/talants/yaziki_i_lingv.png',
    'Гуманитарные науки и общество': '/assets/images/talants/gum_i_ob.png',
    'Культурология и туризм': '/assets/images/talants/kult_i_tour.png',
    'Техника и технологии': '/assets/images/talants/tech_i_technol.png',
    'Творчество и искусство': '/assets/images/talants/tvorch.png',
    'Физико-математические науки': '/assets/images/talants/fiz_mat.png',
    'Общее развитие': '/assets/images/talants/obsh_razv.png',
  };

  constructor(
    private playerService: PlayerService,
    private http: HttpService,
    private location: Location,
    private translate: TranslateService,
  ) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  resolve(route: ActivatedRouteSnapshot) {
    this.TALENTS_TO_CHOOSE = 3;
    this.PROGRAMS_AMOUNT = 6;
    const session = route.params.ssid;
    return this.playerService.getResults(session);
  }

  private isEn = () => this.translate.currentLang === 'en';

  public transformInterests(interests, pupilSessionId, parentSessionId) {
    return interests.map(i => {
      let pupilResult = i.results.find(results => results.sessionId === pupilSessionId);
      let parentResults = i.results.find(results => results.sessionId === parentSessionId);
      return {
        title: i.name,
        parentEstimate: Math.round(parentResults?.transformedValue) || null,
        childEstimate: Math.round(pupilResult?.transformedValue) || null,
      };
    });
  }

  public transformSkills(skills, pupilSessionId) {
    let part1 = skills.map(s => {
      let data = s.results.filter(r => r.sessionId === pupilSessionId)[0];
      return {
        axis: s.name,
        value: data ? Math.round(data.transformedValue) / 100 : null,
      };
    });

    let part2 = skills.map(s => {
      let data = s.results.filter(r => r.sessionId !== pupilSessionId)[0];
      return {
        axis: s.name,
        value: data ? Math.round(data.transformedValue) / 100 : null,
      };
    });

    return [part1, part2];
  }

  getTalentsUrls(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/talents/all').pipe(
      map(r => r.talents),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getSharedLink(sessionId: string, userId: string, isChild: boolean, status: string): Observable<any> {
    let postData: any = {
      userId,
      sessionId,
      isChild,
      status,
    };
    return this.http.post('/api/v1.0/profiles/sharedresults', postData).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public updateSharedSession(shareId: string, userId: string, sessionId: string): Observable<any> {
    const urlParams = '?sharedResultId=' + shareId + '&userId=' + userId + '&sessionId=' + sessionId;
    return this.http.put('/api/v1.0/profiles/sharedresults' + urlParams).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getJoinedResults(session1Id: string, session2Id: string): Observable<any> {
    const urlParams = '?' + 'sessionIds=' + session1Id + '&sessionIds=' + session2Id;
    return this.http.get('/api/v1.0/results/page' + urlParams).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getUserInfo(sessionId: string): Observable<any> {
    const urlParams = '?' + 'sessionId=' + sessionId;
    return this.http.get('/api/v1.0/profiles/sharedresults/getbysession' + urlParams).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getResultPages(getResultObject: any): Observable<any> {
    return this.http.post('/b2c/v1.0/saas/getresultpage', getResultObject).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getResultsForParent(sessionIdsList) {
    let urlParams = '?sessionIds=' + sessionIdsList;
    return this.http.get('/api/v1.0/results/page' + urlParams).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getSharedResults(userId?: string): Observable<any> {
    const params = userId ? '?userId=' + userId : '';
    return this.http.get('/api/v1.0/profiles/sharedresults/getbyuser' + params).pipe(
      map(r => r.sharedResults),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getSessionData(sessionId: string): Observable<any> {
    return this.http.get('/api/v1.0/player/sessions?sessionId=' + sessionId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getLastSession(userId: string): Observable<any> {
    return this.http.get('/b2c/v1.0/saas/getlastsession?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getProfessions(regionId, municipalityId): Observable<any> {
    let url = '/api/v1.0/catalog/professions/elasticsearch/search/filters';
    let credentials: IProfessionFilter = {};

    credentials.isVisible = true;
    credentials.regionId = regionId && regionId != 'null' ? regionId : '00000000-0000-0000-0000-000000000000';
    credentials.municipalityId = municipalityId && municipalityId != '00000000-0000-0000-0000-000000000000' ? municipalityId : null;

    return this.http.post(url, credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
}
