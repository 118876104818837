import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { IResult, IResultsData } from '../../results.interface';
import { ResultsService } from '../../results.service';
import { ChartConfiguration, TooltipItem } from 'chart.js';
import { ValuesResultsTexts } from '../values-results-const';
import { ETestTypes } from 'app/shared/enums/testtypes.enum';
import { MobileInfo } from 'app/shared/interfaces/imobiledata.interface';

interface ValueObject {
  name?: string;
  id?: string;
  value?: number;
}

@Component({
  selector: 'prf-values-results',
  templateUrl: './values-results.component.html',
  styleUrls: ['./values-results.component.scss'],
})
export class ValuesResultsComponent implements OnInit, OnDestroy {
  @Input() userRole: string = 'pupil';
  @Input() pageLoader: boolean = false;

  public userProfile: any = {};
  public children: any[] = [];
  public childrenTested: any[] = [];
  public currentSessionId: string = '';

  public results: any;
  public valuesResults: any[] = [];
  public highValuesResults: any[] = [];
  public middleValuesResults: any[] = [];
  public lowValuesResults: any[] = [];
  public chartData: ChartConfiguration;
  public resultsArray: Object[] = [];
  public valuesResultsData: ValueObject[] = [];

  nullResults: boolean = false;
  resultsFetched: boolean = false;
  isVGuser: boolean = false;
  isMobile: boolean = false;

  // переменные для мобильной отрисовки
  mobileOpened: boolean = false;
  mobileData: MobileInfo;
  mobileImages: string[];
  mobileDataColor: string;
  lastPosition: {
    x: number;
    y: number;
  };

  hightLevelRangeString = '70-100%';
  middleLevelRangeString = '40-70%';
  lowLevelRangeString = '0-39%';

  highRangeMinimum = 70;
  middleRangeMinimum = 40;

  colorGreen = '#5ed591';
  colorYellow = '#FFDD46';
  colorRed = '#FF4B32';

  // проверка языка для регионов
  public selectedLanguage: string =
    AppSettingsService.settings.regionLanguages && AppSettingsService.settings.regionLanguages.isAccessible
      ? AppSettingsService.settings.regionLanguages.defaultLanguage
      : null;
  public regionLanguages = {
    default: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.defaultLanguage : null,
    native: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.regionLanguage : null,
  };

  valueDescriptionConfig: any;
  @ViewChild('chartCanvas') chartCanvas: ElementRef;
  @ViewChild('valueCard') valueCard: ElementRef;
  @ViewChild('closeDescr') closeDescr: ElementRef;

  resultsContainer: HTMLElement;

  private ngUnsubscribe$ = new Subject<any>();

  @Input() set sessionId(val: string) {
    if (val) {
      this.resultsFetched = false;
      this.currentSessionId = val;
      this.getSessionResults(val);
    } else {
      this.resultsFetched = true;
    }
  }

  constructor(
    public element: ElementRef,
    private resultsService: ResultsService,
    private translate: TranslateService,
    private overlayService: OverlayBusyService,
    private cd: ChangeDetectorRef,
  ) {
    localStorage.removeItem('testType');
  }

  ngOnInit() {
    this.isMobile = window.screen.width <= 768;
  }

  getSessionResults(sessionId) {
    if (this.pageLoader) {
      this.overlayService.show();
    }
    const getResultsObject = {
      sessionIds: [sessionId],
    };
    return this.resultsService
      .getResultPages(getResultsObject)
      .pipe(
        tap((results: IResultsData) => {
          if (results && results.results) {
            this.results = results.results;
            this.parseResults(this.results);
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        this.resultsFetched = true;
        this.overlayService.hide();
        this.cd.markForCheck();
      });
  }

  parseResults(results: IResult[]) {
    if (results && results.length) {
      let resultsData: {
        name: string;
        value: number;
        id: string;
      }[];
      resultsData = results
        .map(result => {
          return {
            name: result.name,
            value: result.results[0].transformedValue,
            id: result.id,
          };
        })
        .sort((a, b) => b.value - a.value);
      const labels: string[] = [];
      const valuesDataSet: number[] = [];
      const backgroundColors: string[] = [];

      const getShortDescription = valueId => {
        const textDataBlock = ValuesResultsTexts.find(el => el.id === valueId);
        return textDataBlock ? textDataBlock.shortDescription : null;
      };
      const getAdvice = (valueId, value) => {
        const textDataBlock = ValuesResultsTexts.find(el => el.id === valueId);
        return value >= this.highRangeMinimum
          ? textDataBlock?.data.hightValue.tooltipText
          : value >= this.middleRangeMinimum
            ? textDataBlock?.data.middleValue.tooltipText
            : textDataBlock?.data.lowValue.tooltipText;
      };
      const getAboutText = (valueId, value) => {
        const textDataBlock = ValuesResultsTexts.find(el => el.id === valueId);
        return value >= this.highRangeMinimum
          ? textDataBlock?.data.hightValue.about
          : value >= this.middleRangeMinimum
            ? textDataBlock?.data.middleValue.about
            : textDataBlock?.data.lowValue.about;
      };
      const getAboutMyselfText = (valueId, value) => {
        const textDataBlock = ValuesResultsTexts.find(el => el.id === valueId);
        return value >= this.highRangeMinimum
          ? textDataBlock?.data.hightValue.aboutSelf
          : value >= this.middleRangeMinimum
            ? textDataBlock?.data.middleValue.aboutSelf
            : textDataBlock?.data.lowValue.aboutSelf;
      };
      const getPayAttentionText = (valueId, value) => {
        const textDataBlock = ValuesResultsTexts.find(el => el.id === valueId);
        return value >= this.highRangeMinimum
          ? textDataBlock?.data.hightValue.payAttention
          : value >= this.middleRangeMinimum
            ? textDataBlock?.data.middleValue.payAttention
            : textDataBlock?.data.lowValue.payAttention;
      };
      resultsData.forEach(valueResults => {
        this.valuesResultsData.push(valueResults);
        labels.push(valueResults.name);
        valuesDataSet.push(valueResults.value);
        if (valueResults.value >= this.highRangeMinimum) {
          backgroundColors.push(this.colorGreen);
          this.highValuesResults.push({
            name: valueResults.name,
            value: Math.round(valueResults.value),
            shortDescription: getShortDescription(valueResults.id),
            aboutText: getAboutText(valueResults.id, valueResults.value),
            aboutMyselfText: getAboutMyselfText(valueResults.id, valueResults.value),
            payAttentionText: getPayAttentionText(valueResults.id, valueResults.value),
            advice: getAdvice(valueResults.id, valueResults.value),
            descriptionOpened: null,
          });
        } else if (valueResults.value >= this.middleRangeMinimum) {
          backgroundColors.push(this.colorYellow);
          this.middleValuesResults.push({
            name: valueResults.name,
            value: Math.round(valueResults.value),
            shortDescription: getShortDescription(valueResults.id),
            aboutText: getAboutText(valueResults.id, valueResults.value),
            aboutMyselfText: getAboutMyselfText(valueResults.id, valueResults.value),
            payAttentionText: getPayAttentionText(valueResults.id, valueResults.value),
            advice: getAdvice(valueResults.id, valueResults.value),
            descriptionOpened: null,
          });
        } else {
          backgroundColors.push(this.colorRed);
          this.lowValuesResults.push({
            name: valueResults.name,
            value: Math.round(valueResults.value),
            shortDescription: getShortDescription(valueResults.id),
            aboutText: getAboutText(valueResults.id, valueResults.value),
            aboutMyselfText: getAboutMyselfText(valueResults.id, valueResults.value),
            payAttentionText: getPayAttentionText(valueResults.id, valueResults.value),
            advice: getAdvice(valueResults.id, valueResults.value),
            descriptionOpened: null,
          });
        }
      });

      if (this.isMobile) {
        this.setMobileChart(labels, valuesDataSet, backgroundColors);
      }

      this.setChart(valuesDataSet, labels, backgroundColors);
    }
  }

  setChart(valuesDataSet, labels, backgroundColors) {
    const self = this;
    this.chartData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            type: 'bar',
            backgroundColor: backgroundColors,
            data: valuesDataSet,
            // barThickness: 0.9,
            // borderWidth: 5,
            // borderColor: '#000000',
            // borderCapStyle: 'round',
            // categoryPercentage: 90,
            // maxBarThickness: 100,
            // barPercentage: 0.1,
            // tooltipText: 'тест текст',
            // radius: 20,
            // hitRadius: 20,
          },
        ],
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        aspectRatio: 0.5,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'point',
            enabled: false,
            external: function (tooltipModel) {
              // Tooltip Element
              let tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<div class="chartjs-tooltip"></div>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.tooltip.opacity === 0) {
                tooltipEl.style.opacity = '0';
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('top', 'bottom');
              if (tooltipModel.tooltip.yAlign) {
                tooltipEl.classList.add(tooltipModel.tooltip.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem;
              }

              // Set Text
              if (tooltipModel.tooltip.body) {
                const titleLines = tooltipModel.tooltip.title || [];
                const bodyLines = tooltipModel.tooltip.body.map(getBody);
                let titleBlock = '<div class="chartjs-tooltip__title">';
                titleLines.forEach(function (title) {
                  titleBlock += title;
                });
                titleBlock += '</div>';

                let descBlock = '<div class="chartjs-tooltip__body">';
                let footBlock = '<div class="chartjs-tooltip__footer">';
                bodyLines.forEach(function (body) {
                  descBlock += body.lines;
                  footBlock += body.after;
                });
                descBlock += '</div>';
                footBlock += '</div>';
                const tooltipRootBlock = tooltipEl.querySelector('.chartjs-tooltip');
                tooltipRootBlock.innerHTML = titleBlock + descBlock + footBlock;
              }
              // `this` will be the overall tooltip
              const position = tooltipModel.chart.canvas.getBoundingClientRect();
              // Display, position, and set styles for font
              tooltipEl.style.opacity = '1';
              tooltipEl.style.transition = 'all 0.3s ease-in';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + (tooltipModel.tooltip.caretX - 20) + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + (tooltipModel.tooltip.caretY + 20) + 'px';
              tooltipEl.style.pointerEvents = 'none';

              // if the position is more than the middle of the canvas, change the position of the tooltip
              if (tooltipModel.tooltip.caretY > position.height / 2) {
                tooltipEl.style.top =
                  position.top + window.pageYOffset + (tooltipModel.tooltip.caretY - tooltipEl.offsetHeight - 20) + 'px';
              }
            },
            callbacks: {
              title: function (tooltipItem: TooltipItem<'bar'>[]) {
                const highLevel = 'Высокий уровень';
                const middleLevel = 'Средний уровень';
                const lowLevel = 'Низкий уровень';
                return (tooltipItem[0].raw as number) >= self.highRangeMinimum
                  ? highLevel
                  : (tooltipItem[0].raw as number) >= self.middleRangeMinimum
                    ? middleLevel
                    : lowLevel;
              },
              label: function (tooltipItem: TooltipItem<'bar'>) {
                let tooltipText: string;
                const idDataBlock = self.valuesResultsData.find(el => el.name === tooltipItem.label);
                const textDataBlock = ValuesResultsTexts.find(el => el.id === idDataBlock.id);
                if (textDataBlock) {
                  tooltipText =
                    (tooltipItem.raw as number) >= self.highRangeMinimum
                      ? textDataBlock.data.hightValue.tooltipText
                      : (tooltipItem.raw as number) >= self.middleRangeMinimum
                        ? textDataBlock.data.middleValue.tooltipText
                        : textDataBlock.data.lowValue.tooltipText;
                  tooltipText = self.translate.instant(tooltipText);
                  return tooltipText;
                } else {
                  return '';
                }
              },
              afterLabel: function (tooltipItem: TooltipItem<'bar'>): string {
                const result = tooltipItem.raw.toString();
                return result ? result : '';
              },
            },
            backgroundColor: '#2F5787',
            bodyFont: {
              size: 16,
              family: 'Avenirnextcyr',
            },
            titleFont: {
              size: 20,
              family: 'Avenirnextcyr',
            },
            displayColors: false,
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            position: 'bottom',
            suggestedMax: 100,
            grid: {
              display: true,
            },
            ticks: {
              font: {
                family: 'Avenirnextcyr',
                size: 10,
              },
              color: '#353535',
              callback: function (tick) {
                tick = String(tick);
                return tick + '%';
              },
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                family: 'Avenirnextcyr',
                size: 14,
                weight: 'normal',
              },
              color: '#333333',
            },
            afterFit: function (scale) {
              scale.maxWidth = 150;
              scale.height = 120;
            },
          },
        },
        onHover() {},
      },
    };
  }

  // мобильный график
  setMobileChart(labels: string[], values: number[], backgroundColors: string[]): Object[] {
    let resultItem: {
      name: string;
      value: number;
      backgroundColor: string;
    };

    for (let i = 0; i < labels.length; i++) {
      resultItem = {
        name: labels[i],
        value: Math.round(values[i]),
        backgroundColor: backgroundColors[i],
      };
      this.resultsArray.push(resultItem);
    }
    return this.resultsArray;
  }

  openDescription(valuesResultsArray, index, el, event: MouseEvent) {
    if (this.closeDescr && this.closeDescr.nativeElement.contains(event.target)) {
      return this.closeDescriptions();
    }
    this.resultsContainer = document.getElementsByClassName('result-container')[0] as HTMLElement;
    if (!this.isMobile) {
      [this.highValuesResults, this.middleValuesResults, this.lowValuesResults].forEach(resultsArray => {
        if (resultsArray === valuesResultsArray) {
          resultsArray.forEach((el, i) => (el.descriptionOpened = i != index ? false : true));
        } else {
          resultsArray.forEach(el => (el.descriptionOpened = false));
        }
      });
      setTimeout(() => {
        const r = document.getElementsByClassName('card-desription-container display')[0];
        r?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    } else {
      // mobile
      this.lastPosition = {
        x: event.pageX,
        y: event.pageY,
      };

      switch (valuesResultsArray) {
        case this.highValuesResults:
          this.mobileDataColor = this.colorGreen;
          break;
        case this.middleValuesResults:
          this.mobileDataColor = this.colorYellow;
          break;
        case this.lowValuesResults:
          this.mobileDataColor = this.colorRed;
          break;
        default:
          break;
      }
      const containerElement = document.getElementsByClassName('content-section')[0] as HTMLElement;
      containerElement.style.overflow = 'visible';

      this.mobileOpened = true;
      window.scrollTo(0, 0);
      this.mobileData = valuesResultsArray[index];
    }
    this.setImages(valuesResultsArray, index, el);
  }

  openMobile(item: Object, type: string, event: MouseEvent) {
    this.lastPosition = {
      x: event.pageX,
      y: event.pageY,
    };

    switch (type) {
      case 'high':
        this.mobileDataColor = this.colorGreen;
        break;
      case 'middle':
        this.mobileDataColor = this.colorYellow;
        break;
      case 'low':
        this.mobileDataColor = this.colorRed;
        break;
      default:
        break;
    }

    this.mobileOpened = true;
    window.scrollTo(0, 0);

    return (this.mobileData = item);
  }

  setImages(valuesResultsArray, index, el) {
    const cardContainer = el.parentNode.firstChild.nextSibling;
    const idDataBlock = this.valuesResultsData.find(el => el.name === valuesResultsArray[index].name);
    const textDataBlock = ValuesResultsTexts.find(el => el.id === idDataBlock.id);
    this.mobileImages = textDataBlock.imageUrls;

    if (!this.isMobile) {
      cardContainer.children[0].children[0].children[0].children[0].children[0].children[0].setAttribute(
        'style',
        `background: #9375fb url('${textDataBlock.imageUrls[0]}') center bottom no-repeat; background-size: contain;`,
      );
      cardContainer.children[0].children[0].children[0].children[0].children[1].children[0].setAttribute(
        'style',
        `background: #496df6 url('${textDataBlock.imageUrls[1]}') center bottom no-repeat; background-size: contain;`,
      );
      cardContainer.children[0].children[0].children[0].children[0].children[2].children[0].setAttribute(
        'style',
        `background: #e48d6b url('${textDataBlock.imageUrls[2]}') center bottom no-repeat; background-size: contain;`,
      );
    } else {
    }
  }

  closeDescriptions() {
    [this.highValuesResults, this.middleValuesResults, this.lowValuesResults].forEach(resultsArray => {
      resultsArray.forEach(el => (el.descriptionOpened = null));
    });
  }

  closeMobile() {
    const containerElement = document.getElementsByClassName('content-section')[0] as HTMLElement;
    containerElement.removeAttribute('style');
    this.mobileOpened = false;
    this.scrollToPosition();
  }

  scrollToPosition() {
    const topPositon = this.lastPosition.y - 300;
    setTimeout(() => {
      window.scrollTo({ top: topPositon, behavior: 'smooth' });
    }, 0);
  }

  @HostListener('window:resize', [])
  onResize(): void {
    if (window.screen.width <= 768) {
      this.isMobile = true;
    }
    if (this.isMobile && window.screen.width > 768) {
      this.isMobile = false;
    }
  }

  checkLeftOffset(element): number {
    const resultsContainer = document.getElementsByClassName('result-container')[0] as HTMLElement;
    const resultsContainerRect = resultsContainer.getBoundingClientRect();
    const cardElement = element as HTMLElement;
    const cardElementRect = cardElement.getBoundingClientRect();

    return Math.abs(resultsContainerRect.left - cardElementRect.left);
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    localStorage.setItem('redirectTestType', ETestTypes.VALUES_TEST.toString());
  }

  downloadFullReport() {}

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
