<ng-container>
  <prf-menu [root]="userRole" [setUserProfile]="userProfile"></prf-menu>

  <div class="content-section">
    <prf-page-header [title]="'Рекомендации'"></prf-page-header>
    <div class="container vertical-container">
      <div class="professions-section">
        <div class="recommendations-pupil-block" *ngIf="userRole === 'parent' && childrenRegister">
          <ng-container *ngFor="let child of childrenRegister">
            <div
              class="recommendations-pupil-block__avatar"
              [style.background-image]="child.imagePath ? 'url(child.imagePath)' : 'url(/assets/images/dashboard/no-photo.svg)'"
            ></div>
            <div class="recommendations-pupil-block__name">
              {{ child.firstName }}
            </div>
          </ng-container>
        </div>

        <prf-page-switcher
          [valuesArray]="pupilRecommendationsMenu"
          [isDisabled]="sessionStatus !== 'Success'"
          (selectedMenuEmitter)="selectTab($event)"
        ></prf-page-switcher>
        <ng-container [ngSwitch]="sessionStatus">
          <ng-container *ngSwitchCase="'Uncompleted test'">
            <prf-not-passed-test *ngIf="userRole === 'parent'"></prf-not-passed-test>

            <div *ngIf="userRole === 'pupil'" class="test-progress-white-block untested-recommendations-block">
              <div class="welcome-row-3">
                <div class="welcome-column-stud-2">
                  <h3 class="welcome-h3-stud-2">Пройти тест, который поможет понять твои интересы и способности.</h3>

                  <div class="progress-block-2">
                    <div class="progress-text">{{ currentSlide }} из {{ countSlide }}</div>
                    <div class="progress-bar">
                      <div class="progress-bar-color" [ngStyle]="{ width: ((currentSlide - 1) * 100) / (countSlide - 1) + '%' }"></div>
                    </div>
                  </div>

                  <div class="main-screen-buttons-row">
                    <a (click)="navigateContinueTest()" class="button welcome-button-stud-2 testing w-button">Продолжить тест</a>
                    <a (click)="navigateStartTest()" class="button welcome-button-stud-2 white-button w-button">Начать тест заново</a>
                  </div>
                </div>

                <img
                  src="{{ isMale(gender) ? '/assets/images/dashboard/Pupil-m.svg' : '/assets/images/dashboard/Pupil-w.svg' }}"
                  alt=""
                  class="welcome-image-stud"
                />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'Success'">
            <div [class.display]="selectedTab === 'professions'" class="tabs-content">
              <div class="tab-pane left">
                <prf-recommended-professions
                  *ngIf="userRole === 'pupil' || isLastSessionLoaded"
                  [sessionId]="testSessionId || lastSessionCompleted?.sessionId"
                ></prf-recommended-professions>
              </div>
            </div>

            <div [class.display]="selectedTab === 'profileClasses'" class="tabs-content">
              <div class="tab-pane left">
                <ng-container>
                  <h3 class="tab-pane__title">Рекомендуемые классы обучения</h3>
                  <p class="tab-pane__description">Профильные классы и предметы</p>
                  <prf-recommended-classes [testResults]="testResults ? testResults : []"></prf-recommended-classes>
                  <div class="recommended-classes-container">
                    <div class="recommended-classes__block purple"></div>
                    <div class="recommended-classes__block blue"></div>
                    <div class="recommended-classes__block rose-white"></div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <div class="test-parent-div untested-recommendations-block">
              <div class="welcome-row">
                <div class="test-parent-column">
                  <ng-container>
                    <h3 class="test-parent-h3">{{ 'PROFESSIONS.LAUNCH_TEST' | translate }}</h3>
                    <p class="test-parent-text">
                      {{ 'SHARED.SYSTEM_DIAGNOSTIC_NARRATIVE' | translate }}
                    </p>
                  </ng-container>
                  <div class="main-screen-buttons-row">
                    <a routerLink="/test-na-professiyu" class="button test-parent-button w-button">{{ 'SHARED.START_TEST' | translate }}</a>
                  </div>
                </div>
                <img
                  src="{{ isMale(gender) ? '/assets/images/dashboard/Pupil-m.svg' : '/assets/images/dashboard/Pupil-w.svg' }}"
                  alt="gender"
                  width="365"
                  class="welcome-image-stud"
                />
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>
</ng-container>
