<div class="vertical-container">
  <div class="testing-progress-bar-block">
    <div class="testing-progress-bar" [style.width]="'0'"></div>
  </div>
  <div class="header-testing" *ngIf="!isMobile">
    <!-- <a routerLink="/{{ userRole }}" class="testing-logo-link w-inline-block">
    <img class="new-logo" src="/assets/images/dashboard/profilum-new-logo.svg" alt="" />
  </a> -->
    <div class="testing-logo-link w-inline-block">
      <img class="new-logo" src="/assets/images/dashboard/profilum-new-logo.svg" alt="" />
    </div>
    <!-- <div *ngIf="testType === '0' || testType === '1'" class="header-testing-div">
    <div class="test-title">{{ 'PLAYER.ANKETA.ABOUT_TEST.HEADER_PARENT' | translate }}</div>
    <div *ngIf="userRole == 'pupil'">
      {{ currentAnketaStep }} из {{ testTypesSteps.DEFAULT_STANDART }}
    </div>
    <div *ngIf="userRole == 'parent'">
      {{ currentAnketaStep }} из {{ testTypesSteps.DEFAULT_360 }}
    </div>
  </div> -->
    <div *ngIf="testType === '2'" class="header-testing-div">
      <div class="test-title">Мир профессий</div>
      <div>{{ currentAnketaStep }} из {{ testTypesSteps.PROFESSIONPRESTIGE }}</div>
    </div>
    <!-- <div *ngIf="testType === '4'" class="header-testing-div">
    <div class="test-title">Тест на ценности</div>
    <div>{{ currentAnketaStep }} из {{ testTypesSteps.VALUES_TEST }}</div>
  </div> -->
    <!-- <div *ngIf="testType === '3'" class="header-testing-div">
    <div class="test-title">Тест на мотивацию</div>
    <div>{{ currentAnketaStep }} из 4</div>
  </div> -->
  </div>
  <div class="content-section testing-content">
    <div class="container">
      <main [ngClass]="{ 'anketa-steps': true }">
        <!-- <prf-tell-about [form]="forms[steps.TellAbout-1]" *ngIf="currentAnketaStep == steps.TellAbout"></prf-tell-about> -->
        <!-- <prf-select-test-type [form]="forms[steps.TestType-1]"  *ngIf="currentAnketaStep == steps.TestType"></prf-select-test-type>
      <prf-select-user-status [form]="forms[steps.UserStatus-1]" [isFemale]="anketaService.getAnketaData().isFemale" *ngIf="currentAnketaStep == steps.UserStatus" class="anketa-step"></prf-select-user-status>
      <prf-child-info [form]="forms[steps.TellAboutChildren-1]" *ngIf="currentAnketaStep == steps.TellAboutChildren"></prf-child-info>
      <prf-tell-about [form]="forms[steps.TellAboutMyself-1]" *ngIf="currentAnketaStep == steps.TellAboutMyself"></prf-tell-about> -->
        <prf-about-test
          *ngIf="currentAnketaStep == steps.AboutTest"
          [isBaseNavigation]="baseNavigationAllow"
          [testType]="testType"
          [form]="forms[steps.AboutTest - 1]"
          [buttonEvent]="navigation"
        ></prf-about-test>
      </main>

      <prf-navigation
        *ngIf="baseNavigationAllow"
        [showAgreements]="true"
        [buttonEvent]="navigation"
        [enableBack]="false"
        [enableForward]="forms[currentAnketaStep - 1].valid"
      >
      </prf-navigation>
    </div>
  </div>

  <prf-page-footer> </prf-page-footer>
</div>
