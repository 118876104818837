import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { IUserInterface } from '../../../interfaces/iuser.interface';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'prf-phone-change',
  templateUrl: './phone-change.component.html',
  styleUrls: ['./phone-change.component.scss'],
})
export class PhoneChangeComponent implements OnInit {
  _user: IUserInterface = {
    phoneNumber: null,
  };
  phoneNumberFormControl = new UntypedFormControl('', [Validators.required]);

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {}

  // Update password
  onSubmit(valid: boolean) {
    if (valid) {
      this.settingsService.updateUserInfo(this._user);
    }
  }
}
