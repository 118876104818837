<div *ngIf="taskList && taskList.length > 0" class="task-card task-card-row">
  <div class="task-slider w-slider task-slider-row">
    <div class="mask-task w-slider-mask">
      <swiper-container
        #swiperComponent
        prfSwiperElement
        [config]="swiperConfig"
        init="false"
        class="swiper swiper-container"
        (indexChange)="onChangeSlide($event)"
      >
        <ng-container *ngIf="isStatic; else dynamicTaskList">
          <swiper-slide class="slide-task w-slide swiper-slide" *ngFor="let task of taskList; let i = index">
            <ng-container *ngIf="checkClassFormat(task.classesFormat)">
              <a
                class="event-gradient-bg-2 w-inline-block event-card-row lg-blue"
                (click)="StaticTaskModal(task)"
                (mouseleave)="popup.style.display = 'none'"
                (mouseover)="popup.style.display = 'block'"
              >
                <div class="event-header-2">
                  <h3 class="event-heading-2">{{ cutText(task.name, 40) }}</h3>
                </div>
                <div class="event-image-bg static-image-{{ i }}"></div>
              </a>
              <a *ngIf="userRole === 'pupil'" (click)="setPlan(task)" class="event-plan-block">
                <img class="add-to-plan" [class.hide]="task.active" src="./assets/images/tasks/plus.svg" alt="" />
                <img class="add-to-plan" *ngIf="task.active" src="./assets/images/tasks/check.svg" alt="" />
              </a>
            </ng-container>
          </swiper-slide>
        </ng-container>

        <ng-template #dynamicTaskList>
          <div class="slide-task w-slide" *ngFor="let task of taskList">
            <ng-container *ngIf="checkClassFormat(task.classesFormat)">
              <a
                (click)="linkToCourse(task)"
                (mouseleave)="popup.style.display = 'none'"
                (mouseover)="popup.style.display = 'block'"
                [class.orange]="bgColor === 'orange'"
                [class.yellow]="bgColor === 'yellow'"
                [class.blue]="bgColor === 'blue'"
                [class.green]="bgColor === 'green'"
                [class.is-enrolled]="task.isEnrolled"
                class="event-gradient-bg-2 w-inline-block event-card-row"
              >
                <div class="event-header-2">
                  <h3 class="event-heading-2">{{ cutText(task.name, 40) }}</h3>
                  <div class="event-info-row">
                    <div class="event-cost" *ngIf="task.unitPrice != null">
                      <span>{{ setUnitPrice(task.unitPrice) }}</span>
                    </div>
                    <div class="event-date-2">
                      <span *ngIf="task.isRegular">{{ 'SHARED.REGULARLY' | translate }}</span>
                      <span *ngIf="!task.isRegular && task.startDate">{{ dayjs(task.startDate).format('D MMM в hh:mm') }}</span>
                    </div>
                  </div>
                </div>
                <div class="event-image-bg"></div>
              </a>
              <a *ngIf="userRole === 'pupil'" (click)="setPlan(task)" class="event-plan-block">
                <img class="add-to-plan" [class.hide]="task.active" src="./assets/images/tasks/plus.svg" alt="" />
                <img class="add-to-plan" *ngIf="task.active" src="./assets/images/tasks/check.svg" alt="" />
              </a>
            </ng-container>

            <ng-container *ngIf="task.classesFormat === 'ShowMoreTasks'">
              <a (click)="showMoreCourses()" class="event-gradient-bg-2 all-events w-inline-block">
                <img src="./assets/images/all-events.svg" class="all-events-icon" />
                <div class="all-events-text">Посмотреть все {{ checkIsCourses() ? 'курсы' : 'мероприятия' }}</div>
              </a>
            </ng-container>
          </div>
        </ng-template>
      </swiper-container>
    </div>

    <div *ngIf="isEnablePrev" (click)="clickPrev()" class="task-slide-arrow w-hidden-small w-hidden-tiny w-slider-arrow-left">
      <img src="/assets/images/icons/arrow-1_1.svg" />
    </div>

    <div *ngIf="isEnableNext" (click)="clickNext()" class="task-slide-arrow w-hidden-small w-hidden-tiny w-slider-arrow-right">
      <img src="/assets/images/icons/arrow-2.svg" />
    </div>

    <div class="task-slide-nav w-slider-nav w-slider-nav-invert w-round">
      <ng-container *ngIf="taskList && taskList.length > 1">
        <div
          *ngFor="let task of taskList; let index = index"
          (click)="selectByIndex(index)"
          [class.w-active]="currentIndex === index"
          class="w-slider-dot"
          style="margin-left: 6px; margin-right: 6px"
        ></div>
      </ng-container>
    </div>
  </div>
  <article class="task-article-row">
    <p class="task-article-story">{{ articleCount }}</p>
    <p class="task-article-title">{{ text.title }}</p>
    <p class="task-article-selection">{{ text.selection }}</p>

    <div class="info-popup recommendation w-hidden-small w-hidden-tiny" #popup style="display: none">
      <p>Нажми на плюс, чтобы добавить в план.</p>
      <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
    </div>
  </article>
</div>

<div *ngIf="(taskList.length === 0 && staticTask) || (isStatic && staticTask)" class="task-card">
  <div *ngIf="!staticTask.files.length">
    <a (click)="StaticTaskModal()" class="tasks-event-image w-inline-block">
      <img src="/assets/images/tasks/astronaut-boy.svg" />
    </a>
    <p class="tasks-event-description">{{ cutText(staticTask.product.description, 50) }}</p>
    <div>
      <a (click)="StaticTaskModal()" class="perform-task">Подробнее</a>
    </div>
  </div>

  <div *ngIf="staticTask.files.length">
    <img (click)="StaticTaskModal()" src="/assets/images/icons/event-checked.svg" class="tasks-event-image test-complete" />

    <div class="event-attached-files">
      <img src="/assets/images/icons/Attachment.svg" class="attachment-icon" />

      <a (mouseover)="isShowInfoPopup = true" (mouseleave)="isShowInfoPopup = false" class="attachment-link">
        Прикрепленные файлы ({{ staticTask.files.length }})
      </a>

      <div [style.display]="isShowInfoPopup ? 'block' : 'none'" [style.opacity]="isShowInfoPopup ? 1 : 0" class="info-popup attach">
        <img src="/assets/images/icons/info-triangle.png" width="12" class="triangle-attachment" />
        <div *ngFor="let file of staticTask.files" class="attached-file-row">
          <img src="/assets/images/icons/attached-file-icon.svg" class="attach-icon" />
          <div class="attached-file-title">{{ file.name }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
