export const ValuesResultsTexts = [
  {
    name: 'VALUES.RESPONSIBILITY.NAME',
    id: 'b6cd1eb3-513e-46aa-ab0d-8556c26d315d',
    shortDescription: 'VALUES.RESPONSIBILITY.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/responsibility/responsibility01.svg',
      '/assets/images/results/values-results/cards-images/responsibility/responsibility02.svg',
      '/assets/images/results/values-results/cards-images/responsibility/responsibility03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.RESPONSIBILITY.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.RESPONSIBILITY.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.RESPONSIBILITY.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.RESPONSIBILITY.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.RESPONSIBILITY.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.RESPONSIBILITY.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.RESPONSIBILITY.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.RESPONSIBILITY.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.RESPONSIBILITY.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.RESPONSIBILITY.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.RESPONSIBILITY.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.RESPONSIBILITY.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.CAREER_GROWTH.NAME',
    id: '67af1315-a038-46ef-a2c8-66266b37b034',
    shortDescription: 'VALUES.RESPONSIBILITY.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/career/career01.svg',
      '/assets/images/results/values-results/cards-images/career/career02.svg',
      '/assets/images/results/values-results/cards-images/career/career03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.CAREER_GROWTH.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.CAREER_GROWTH.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.CAREER_GROWTH.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.CAREER_GROWTH.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.CAREER_GROWTH.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.CAREER_GROWTH.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.CAREER_GROWTH.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.CAREER_GROWTH.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.CAREER_GROWTH.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.CAREER_GROWTH.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.CAREER_GROWTH.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.CAREER_GROWTH.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.LEADERSHIP.NAME',
    id: '74078bed-8b6f-457e-810e-c3ae1c7ba9fc',
    shortDescription: 'VALUES.LEADERSHIP.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/leadership/leadership01.svg',
      '/assets/images/results/values-results/cards-images/leadership/leadership02.svg',
      '/assets/images/results/values-results/cards-images/leadership/leadership03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.LEADERSHIP.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.LEADERSHIP.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.LEADERSHIP.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.LEADERSHIP.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.LEADERSHIP.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.LEADERSHIP.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.LEADERSHIP.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.LEADERSHIP.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.LEADERSHIP.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.LEADERSHIP.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.LEADERSHIP.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.LEADERSHIP.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.SOCIAL_STATUS.NAME',
    id: 'b42f167d-1944-41a2-882b-4dfbe982ba2a',
    shortDescription: 'VALUES.SOCIAL_STATUS.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/social_status/social_status01.svg',
      '/assets/images/results/values-results/cards-images/social_status/social_status02.svg',
      '/assets/images/results/values-results/cards-images/social_status/social_status03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.SOCIAL_STATUS.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.SOCIAL_STATUS.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SOCIAL_STATUS.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SOCIAL_STATUS.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.SOCIAL_STATUS.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.SOCIAL_STATUS.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SOCIAL_STATUS.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SOCIAL_STATUS.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.SOCIAL_STATUS.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.SOCIAL_STATUS.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SOCIAL_STATUS.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SOCIAL_STATUS.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.MONEY.NAME',
    id: '53e02071-300b-470c-bf01-8302ab23f2f3',
    shortDescription: 'VALUES.MONEY.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/money/money01.svg',
      '/assets/images/results/values-results/cards-images/money/money02.svg',
      '/assets/images/results/values-results/cards-images/money/money03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.MONEY.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.MONEY.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.MONEY.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.MONEY.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.MONEY.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.MONEY.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.MONEY.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.MONEY.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.MONEY.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.MONEY.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.MONEY.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.MONEY.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.SELF_DEVELOPMENT.NAME',
    id: '2f031b16-4183-40ed-8d84-2481afdf5ab1',
    shortDescription: 'VALUES.SELF_DEVELOPMENT.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/self-development/self-development01.svg',
      '/assets/images/results/values-results/cards-images/self-development/self-development02.svg',
      '/assets/images/results/values-results/cards-images/self-development/self-development03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.SELF_DEVELOPMENT.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.SELF_DEVELOPMENT.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SELF_DEVELOPMENT.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SELF_DEVELOPMENT.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.SELF_DEVELOPMENT.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.SELF_DEVELOPMENT.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SELF_DEVELOPMENT.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SELF_DEVELOPMENT.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.SELF_DEVELOPMENT.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.SELF_DEVELOPMENT.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SELF_DEVELOPMENT.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SELF_DEVELOPMENT.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.JUSTICE.NAME',
    id: 'c7f16bbd-8ff7-4f49-bb2b-87d85c740634',
    shortDescription: 'VALUES.JUSTICE.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/justice/justice01.svg',
      '/assets/images/results/values-results/cards-images/justice/justice02.svg',
      '/assets/images/results/values-results/cards-images/justice/justice03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.JUSTICE.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.JUSTICE.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.JUSTICE.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.JUSTICE.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.JUSTICE.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.JUSTICE.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.JUSTICE.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.JUSTICE.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.JUSTICE.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.JUSTICE.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.JUSTICE.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.JUSTICE.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.INDEPENDENCE.NAME',
    id: '16609266-f787-489f-b5ac-a26197a6fc57',
    shortDescription: 'VALUES.INDEPENDENCE.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/independence/independence01.svg',
      '/assets/images/results/values-results/cards-images/independence/independence02.svg',
      '/assets/images/results/values-results/cards-images/independence/independence03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.INDEPENDENCE.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.INDEPENDENCE.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.INDEPENDENCE.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.INDEPENDENCE.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.INDEPENDENCE.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.INDEPENDENCE.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.INDEPENDENCE.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.INDEPENDENCE.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.INDEPENDENCE.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.INDEPENDENCE.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.INDEPENDENCE.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.INDEPENDENCE.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.SUCCESS.NAME',
    id: '1f7c1893-612d-4c94-845e-e3d226f7c94e',
    shortDescription: 'VALUES.SUCCESS.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/success/success01.svg',
      '/assets/images/results/values-results/cards-images/success/success02.svg',
      '/assets/images/results/values-results/cards-images/success/success03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.SUCCESS.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.SUCCESS.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SUCCESS.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SUCCESS.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.SUCCESS.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.SUCCESS.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SUCCESS.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SUCCESS.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.SUCCESS.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.SUCCESS.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SUCCESS.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SUCCESS.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.SAFETY.NAME',
    id: '8a41aeb3-6d9c-488e-a2c6-94b8ad21e354',
    shortDescription: 'VALUES.SAFETY.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/safety/safety01.svg',
      '/assets/images/results/values-results/cards-images/safety/safety02.svg',
      '/assets/images/results/values-results/cards-images/safety/safety03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.SAFETY.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.SAFETY.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SAFETY.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SAFETY.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.SAFETY.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.SAFETY.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SAFETY.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SAFETY.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.SAFETY.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.SAFETY.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SAFETY.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SAFETY.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.SENSE.NAME',
    id: 'cf575c73-b3be-4f07-a3ad-1e2979b8a060',
    shortDescription: 'VALUES.SENSE.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/sense/sense01.svg',
      '/assets/images/results/values-results/cards-images/sense/sense02.svg',
      '/assets/images/results/values-results/cards-images/sense/sense03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.SENSE.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.SENSE.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SENSE.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SENSE.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.SENSE.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.SENSE.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SENSE.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SENSE.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.SENSE.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.SENSE.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.SENSE.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.SENSE.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.ALTRUISM.NAME',
    id: 'c804bd42-bfc3-49d7-acde-3cbbe76bf29c',
    shortDescription: 'VALUES.ALTRUISM.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/altruism/altruism01.svg',
      '/assets/images/results/values-results/cards-images/altruism/altruism02.svg',
      '/assets/images/results/values-results/cards-images/altruism/altruism03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.ALTRUISM.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.ALTRUISM.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.ALTRUISM.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.ALTRUISM.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.ALTRUISM.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.ALTRUISM.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.ALTRUISM.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.ALTRUISM.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.ALTRUISM.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.ALTRUISM.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.ALTRUISM.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.ALTRUISM.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.CARE_FOR_NATURE.NAME',
    id: '03eeff6d-0056-4994-b061-aa4b82c53a29',
    shortDescription: 'VALUES.CARE_FOR_NATURE.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/caring_for_nature/caring_for_nature01.svg',
      '/assets/images/results/values-results/cards-images/caring_for_nature/caring_for_nature02.svg',
      '/assets/images/results/values-results/cards-images/caring_for_nature/caring_for_nature03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.CARE_FOR_NATURE.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.CARE_FOR_NATURE.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.CARE_FOR_NATURE.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.CARE_FOR_NATURE.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.CARE_FOR_NATURE.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.CARE_FOR_NATURE.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.CARE_FOR_NATURE.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.CARE_FOR_NATURE.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.CARE_FOR_NATURE.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.CARE_FOR_NATURE.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.CARE_FOR_NATURE.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.CARE_FOR_NATURE.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.PLEASURE.NAME',
    id: '2deebbe4-5ecd-4192-9a9b-f42bad99b66b',
    shortDescription: 'VALUES.PLEASURE.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/pleasure/pleasure01.svg',
      '/assets/images/results/values-results/cards-images/pleasure/pleasure02.svg',
      '/assets/images/results/values-results/cards-images/pleasure/pleasure03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.PLEASURE.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.PLEASURE.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.PLEASURE.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.PLEASURE.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.PLEASURE.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.PLEASURE.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.PLEASURE.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.PLEASURE.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.PLEASURE.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.PLEASURE.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.PLEASURE.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.PLEASURE.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
  {
    name: 'VALUES.TRADITIONS.NAME',
    id: '60f2c075-fad3-4121-83cd-20c1feee05e7',
    shortDescription: 'VALUES.TRADITIONS.SHORT_DESC',
    imageUrls: [
      '/assets/images/results/values-results/cards-images/traditions/traditions01.svg',
      '/assets/images/results/values-results/cards-images/traditions/traditions02.svg',
      '/assets/images/results/values-results/cards-images/traditions/traditions03.svg',
    ],
    data: {
      hightValue: {
        about: 'VALUES.TRADITIONS.HIGH_VALUE.ABOUT',
        aboutSelf: 'VALUES.TRADITIONS.HIGH_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.TRADITIONS.HIGH_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.TRADITIONS.HIGH_VALUE.TOOLTIP_TEXT',
      },
      middleValue: {
        about: 'VALUES.TRADITIONS.MIDDLE_VALUE.ABOUT',
        aboutSelf: 'VALUES.TRADITIONS.MIDDLE_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.TRADITIONS.MIDDLE_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.TRADITIONS.MIDDLE_VALUE.TOOLTIP_TEXT',
      },
      lowValue: {
        about: 'VALUES.TRADITIONS.LOW_VALUE.ABOUT',
        aboutSelf: 'VALUES.TRADITIONS.LOW_VALUE.ABOUT_SELF',
        payAttention: 'VALUES.TRADITIONS.LOW_VALUE.PAY_ATTENTION',
        tooltipText: 'VALUES.TRADITIONS.LOW_VALUE.TOOLTIP_TEXT',
      },
    },
  },
];
