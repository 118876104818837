import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AddFirstProfessionModalService,
} from 'app/shared/dashboard/professions/welcome-choise-profession/add-first-profession-modal/add-first-profession-modal.service';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { GoalsService } from 'app/shared/dashboard/goals/goals.service';
import { ProfessionsService } from 'app/shared/dashboard/professions/professions.service';
import { Observable, of, Subject } from 'rxjs';
import { SharedService } from 'app/shared/shared.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';

@Component({
  selector: 'prf-profession-details',
  templateUrl: './profession-details.component.html',
  styleUrls: ['./profession-details.component.scss'],
})
export class ProfessionDetailsComponent implements OnInit, OnDestroy {
  userId: string;
  userRole: string = '';
  adminLevel: string = '';
  profession: IProfession;
  contentMenu: string[] = [];
  currentContent: string;
  dataFetched: boolean = false;
  videoList: any;
  personsList: any;
  isGoal: boolean;
  currentFavoriteId: any = [];
  hrid: string;
  testCompleted: boolean = false;
  favorites: Array<any> = [];
  showTooltipAdd: boolean = false;
  showSalary: boolean = false;

  @ViewChild('profInfoMenu') infoMenu: ElementRef;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private overlayService: OverlayBusyService,
    private goalsService: GoalsService,
    private professionService: ProfessionsService,
    public route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private favoritesService: FavoritesService,
    private addFirstProfessionModalService: AddFirstProfessionModalService,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Детали профессии' });
  }

  ngOnInit() {
    this.overlayService.show();
    const sessionStatus = localStorage.getItem('sessionStatus');

    this.testCompleted = sessionStatus === 'Success';
    this.userId = localStorage.getItem('userId');
    this.userRole = localStorage.getItem('userRole');
    if (this.userRole.includes('admin')) {
      this.adminLevel = localStorage.getItem('adminLevel');
    }

    this.route.params
      .pipe(
        mergeMap(params => {
          window.scrollTo(0, 0);
          this.hrid = params['hrid'];
          this.dataFetched = false;
          return this.loadProfession();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.overlayService.hide());

    this.showSalary = AppSettingsService.settings.professionViewConfiguration.showSalary;
  }

  loadProfession(): Observable<any> {
    return this.professionService.getProfessionByHRID(this.hrid).pipe(
      mergeMap(job => {
        let currentObservable$: Observable<any>;
        if (job) {
          window.scrollTo(0, 0);
          this.profession = job;
          this.prepareMenu();
          this.professionService.setCurrentProfession(this.profession);
          currentObservable$ = this.getFavorite();
        } else {
          currentObservable$ = of(null);
        }
        return currentObservable$;
      }),
    );
  }

  getFavorite(): Observable<any> {
    return this.favoritesService.getUserFavorites(this.userId, FavoritesTypes.Profession).pipe(
      tap(r => {
        this.favorites = r;
        this.favoritesService.setFavoriteProfessions(r);
        // Проверка текущей профессии, добавлена ли она в избранное
        let favoritesProfessionsIds = this.favorites.map(el => el.productId);
        this.isGoal = favoritesProfessionsIds.indexOf(this.profession.id) > -1;
        // Получаем Ids избранных с текущей профессией (могут быть дубляжи)
        let filteredFavorites = this.favorites.filter(el => el.productId === this.profession.id);
        this.currentFavoriteId = filteredFavorites.map(el => el.id);
      }),
    );
  }

  addFavorite() {
    this.favoritesService
      .addToFavorites(this.profession.id, this.userId, FavoritesTypes.Profession)
      .pipe(
        take(1),
        switchMap(r => this.getFavorite()),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  removeFavorite() {
    if (this.currentFavoriteId) {
      this.favoritesService
        .deleteFavorite(this.currentFavoriteId[0])
        .pipe(
          take(1),
          switchMap(r => {
            return this.getFavorite();
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe(
          r => {
            //перенаправляем только после того, как удалились все дубликаты профессии (если они были)
            //if (index + 1 == this.currentFavoriteId.length) {
            // this.router.navigate(['/professions']);
            //}
          },
          err => {
            this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте снова', 'error');
          },
        );
    }
  }

  prepareMenu() {
    this.contentMenu = [];
    if (this.profession.videos.length > 0) {
      this.profession.videos.forEach(video => (video.url = video.url.replace('http:', 'https:').replace('watch?v=', 'embed/')));
      this.contentMenu.push('Видео');
      this.videoList = this.profession.videos.slice(0, 1);
    }
    if (this.profession.movies.length > 0) {
      this.contentMenu.push('Фильмы');
    }
    if (this.profession.persons.length > 0) {
      this.contentMenu.push('Профессия в лицах');
      this.personsList = this.profession.persons.slice(0, 2);
    }
    if (this.profession.articles.length > 0) {
      this.contentMenu.push('Книги и статьи');
    }
    this.currentContent = this.contentMenu[0];
    this.dataFetched = true;
  }

  prepareData() {
    if (this.profession.videos.length > 0) {
      this.profession.videos.forEach(video => (video.url = video.url.replace('http:', 'https:').replace('watch?v=', 'embed/')));
      this.videoList = this.profession.videos.slice(0, 1);
    }
    if (this.profession.persons.length > 0) {
      this.personsList = this.profession.persons.slice(0, 2);
    }
  }

  scroll() {
    this.infoMenu.nativeElement.scrollIntoView();
  }

  checkPopup(event, popup) {
    if (!popup.contains(event.target)) {
      popup.style.display = 'block';
    }
  }

  checkHeaderButton() {
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
        return true;
      default:
        return false;
    }
  }

  sanitizeUrl(url: string) {
    let trustedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    return trustedUrl;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
