<div class="footer-section">
  <div class="footer-row">
    <div class="footer-text">© {{ yearNumber }} Карта талантов Подмосковья</div>
    <!-- <div class="footer-text">8 495 445-01-09</div> -->
    <div class="footer-logo mo-logo">
      <a target="_blank" class="logo-link-footer w-inline-block">
        <img src="/assets/images/mo-landing/footer-mo-logo.svg" alt="MO logo" />
      </a>
    </div>
    <div class="footer-logo academy-logo">
      <a target="_blank" class="logo-link-footer w-inline-block">
        <img src="/assets/images/mo-landing/Academy.png" alt="Social Academy" />
      </a>
    </div>
    <div class="footer-logo profilum-logo">
      <a target="_blank" class="logo-link-footer w-inline-block">
        <img src="/assets/images/dashboard/profilum-logo.svg" alt="Profilum logo" />
      </a>
    </div>
    <div class="footer-emails">
      <div class="footer-text">
        <a href="mailto:help@profilum.ru" class="footer-link">help&#64;profilum.ru</a>
        <div class="phone-text">7 (495) 120-33-97</div>
      </div>
    </div>
  </div>
</div>
