<div class="select-user-status" *ngIf="!isFemale">
  <div class="select-user-status__item" [ngClass]="{ 'select-user-status__item--active': selected == 1 }" (click)="select(1)">
    <div class="select-user-status__pic">
      <img alt="" src="/assets/images/dashboard/man.png" class="" />
    </div>
    <div class="select-user-status__name">{{ 'SHARED.PARENT' | translate }}</div>
  </div>
  <div
    *ngIf="isEn()"
    class="select-user-status__item"
    [ngClass]="{ 'select-user-status__item--active': selected == 3 }"
    (click)="select(3)"
  >
    <div class="select-user-status__pic">
      <img alt="" src="/assets/images/dashboard/student.png" class="" />
    </div>
    <div class="select-user-status__name">
      {{ 'PLAYER.ANKETA.USER_STATUS.SPECIALIST' | translate }}
    </div>
  </div>
  <div class="select-user-status__item" [ngClass]="{ 'select-user-status__item--active': selected == 2 }" (click)="select(2)">
    <div class="select-user-status__pic">
      <img alt="" src="/assets/images/dashboard/schoolboy.png" class="" />
    </div>
    <div class="select-user-status__name">
      {{ 'PLAYER.ANKETA.USER_STATUS.STUDENT' | translate }}
    </div>
  </div>
</div>

<div class="select-user-status" *ngIf="isFemale">
  <div class="select-user-status__item" [ngClass]="{ 'select-user-status__item--active': selected == 1 }" (click)="select(1)">
    <div class="select-user-status__pic">
      <img alt="" src="/assets/images/dashboard/woman.png" class="" />
    </div>
    <div class="select-user-status__name">{{ 'SHARED.PARENT' | translate }}</div>
  </div>
  <div
    *ngIf="isEn()"
    class="select-user-status__item"
    [ngClass]="{ 'select-user-status__item--active': selected == 3 }"
    (click)="select(3)"
  >
    <div class="select-user-status__pic">
      <img alt="" src="/assets/images/dashboard/female-student.png" class="" />
    </div>
    <div class="select-user-status__name">
      {{ 'PLAYER.ANKETA.USER_STATUS.SPECIALIST' | translate }}
    </div>
  </div>
  <div class="select-user-status__item" [ngClass]="{ 'select-user-status__item--active': selected == 2 }" (click)="select(2)">
    <div class="select-user-status__pic">
      <img alt="" src="/assets/images/dashboard/schoolgirl.png" class="" />
    </div>
    <div class="select-user-status__name">
      {{ 'PLAYER.ANKETA.USER_STATUS.STUDENT' | translate }}
    </div>
  </div>
</div>
