import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResultsService } from 'app/pages/results/results.service';
import { SharedService } from 'app/shared/shared.service';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MenuService } from '../menus/menu/menu.service';

@Component({
  selector: 'prf-promo-action-page',
  templateUrl: './promo-action-page.component.html',
  styleUrls: ['./promo-action-page.component.scss'],
})
export class PromoActionPageComponent implements OnInit {
  public userId: string = '';
  public userRole: string = '';
  public regionId: string = '';
  public municipalityId: string = '';

  resultsRoutingUrl: string = '';

  isMobile: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private resultsService: ResultsService,
    private menuService: MenuService,
  ) {
    this.userRole = localStorage.getItem('userRole');
    this.userId = localStorage.getItem('userId');
    this.regionId = localStorage.getItem('regionId');
    this.municipalityId = localStorage.getItem('municipalityId');
    this.menuService.isMobileScreen$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit() {
    this.checkPromotestResults();
  }

  routingAllCourses() {
    return this.router.navigateByUrl('/partners-courses-all');
  }

  navigateNewTest() {
    return this.router.navigate(['/parent/mosrupromo']);
  }

  returnToResults() {
    this.resultsRoutingUrl && this.resultsRoutingUrl.length
      ? this.router.navigate([this.resultsRoutingUrl])
      : this.router.navigate(['/parent/mosrupromo']);
  }

  getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }

  checkPromotestResults() {
    this.resultsService
      .getSharedResults(localStorage.getItem('userId'))
      .pipe(
        tap(sharedResults => {
          let promotestResults: any[] = [];
          if (sharedResults.length > 0) {
            promotestResults = sharedResults.filter(r => r.screeningTestId === TESTS_VARIANTS_IDS.promoTestMosRu);

            this.resultsRoutingUrl = promotestResults.length > 0 ? '/promotest-results' : '/parent/mosrupromo';
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
