import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { HttpService } from 'app/core/services/http.service';
import { PlayerService } from 'app/pages/player/player.service';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HistoryService  {
  constructor(private playerService: PlayerService, private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  resolve(route: ActivatedRouteSnapshot) {
    const session = route.params.ssid;
    return this.playerService.getResults(session);
  }

  getSessionList(userId: string): Observable<any> {
    return this.http.get('/api/v1.0/player/sessions/list?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getRegionTestInfo(regionId): Observable<any> {
    return this.http.get('/api/v1.0/player/regiontestinfo?regionId=' + regionId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getTestResults(sessionList: any): Observable<any> {
    return this.http.get('/api/v1.0/results/page?sessionIds=' + sessionList).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  //tutor method only
  getTutorPupilInfoByCode(code: string) {
    return this.http.get('/b2c/v1.0/tutors/getuserinfobycode?registrationCode=' + code).pipe(
      map(r => r),
      catchError(err => this.handleError(err)),
    );
  }
}
