<div class="add-child-lb lb show" [style.opacity]="isShow ? 1 : 0" [style.display]="isShow ? 'block' : 'none'">
  <div class="lb-bg"></div>

  <div class="lb-block add-child-lb-block">
    <div class="invite-parent-row _2">
      <div class="add-child-column _2">
        <h3 class="h3-invite-parent">{{ 'SHARED.ADD_CHILD' | translate }}</h3>
        <p class="add-child-text">
          Для добавления ребенка потребуется ввести 5ти значный код. Взять код можно у классного руководителя вашего ребенка.
        </p>

        <div class="w-form">
          <form name="wf-form-Invite-Parent">
            <label class="invite-parent-label">Код приглашения</label>

            <div [class.not-found]="child === null && code.length === codeLength && loading === false" class="add-child-div">
              <div class="add-child-code-row">
                <input
                  #inp1
                  (keydown)="onKeyDown($event, inp1, null)"
                  (input)="onInsertData($event, inp1, inp2)"
                  [class.not-found]="child === null && code.length === codeLength && loading === false"
                  class="add-child-code-field w-input"
                />

                <input
                  #inp2
                  (keydown)="onKeyDown($event, inp2, inp1)"
                  (input)="onInsertData($event, inp2, inp3)"
                  [class.not-found]="child === null && code.length === codeLength && loading === false"
                  class="add-child-code-field w-input"
                />

                <input
                  #inp3
                  (keydown)="onKeyDown($event, inp3, inp2)"
                  (input)="onInsertData($event, inp3, inp4)"
                  [class.not-found]="child === null && code.length === codeLength && loading === false"
                  class="add-child-code-field w-input"
                />
                <input
                  #inp4
                  (keydown)="onKeyDown($event, inp4, inp3)"
                  (input)="onInsertData($event, inp4, inp5)"
                  [class.not-found]="child === null && code.length === codeLength && loading === false"
                  class="add-child-code-field w-input"
                />

                <input
                  #inp5
                  (keydown)="onKeyDown($event, inp5, inp4)"
                  (input)="onInsertData($event, inp5, inp6)"
                  [class.not-found]="child === null && code.length === codeLength && loading === false"
                  class="add-child-code-field w-input"
                />

                <input
                  #inp6
                  (keydown)="onKeyDown($event, inp6, inp5)"
                  (input)="onInsertData($event, inp6, null)"
                  [class.not-found]="child === null && code.length === codeLength && loading === false"
                  class="add-child-code-field w-input"
                />
              </div>

              <div *ngIf="child !== null && code.length === codeLength && loading === false" class="add-child-div-3">
                <img src="{{ child.imagePath ? child.imagePath : '/assets/images/dashboard/ava-0.png' }}" alt="" class="add-child-photo" />
                <div class="parent-name-profile">{{ child.firstName }}</div>
              </div>

              <div *ngIf="child === null && code.length === codeLength && loading === false" class="add-child-div-3 not-found">
                <div class="add-child-name">Ребенок не найден</div>
              </div>
            </div>

            <div class="button-group">
              <input
                (click)="addChild()"
                [class.not-active]="child === null"
                type="submit"
                value="{{ 'SHARED.ADD_CHILD' | translate }}"
                data-wait="Please wait..."
                class="button add-child-button-2 not-active w-button"
              />
              <ng-container *ngIf="activeLocation != 'mo'">
                <div class="child-or">или</div>

                <a routerLink="/child-registration" class="button add-child-button-2" (click)="showRegPage()">
                  Зарегистрировать самостоятельно
                </a>
              </ng-container>
            </div>
          </form>

          <div class="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>

          <div class="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>

      <img src="/assets/images/parent/Parent.svg" alt="" class="invite-parent-image add-child-img" />
    </div>

    <a (click)="closeModal()" class="close-lb w-inline-block">
      <img src="/assets/images/icons/close-lb.svg" alt="" />
    </a>
  </div>
</div>
