<div class="tab-pane-parent">
  <div class="child-profile-block">
    <div
      class="child-profile-photo"
      [ngStyle]="{
        'background-image': child.imagePath ? 'url(' + child.imagePath + ')' : 'url(/assets/images/dashboard/no-photo.svg)'
      }"
    ></div>

    <div class="child-profile-column">
      <div class="user-name-profile child-prof">
        {{ child.firstName }}
        {{ child.middleName && child.middleName.length > 0 ? ' ' + child.middleName : '' }}
        {{ child.lastName }}
      </div>

      <div class="child-profile-code-row">
        <div class="child-profile-code-text">{{ 'SHARED.REGISTRATION_CODE' | translate }} —</div>
        <div class="child-profile-code-column">
          <a class="child-profile-code">{{ child.registrationCode ? child.registrationCode : 'Не указан' }}</a>
          <div class="info-div child-prof">
            <div class="info-icon" (mouseover)="showInfoPopup = true" (mouseout)="showInfoPopup = false"></div>
            <div class="info-popup" *ngIf="showInfoPopup">
              <p>Передайте этот код ребёнку для регистрации.</p>
              <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
            </div>
          </div>
        </div>
      </div>

      <div class="child-profile-data">
        <div class="child-profile-data-row">
          <div class="child-profile-data-text">{{ 'SHARED.FORM.PHONE' | translate }}:</div>
          <div class="child-profile-contact">
            {{ child.phoneNumber ? child.phoneNumber : 'Не указан' }}
          </div>
        </div>

        <div class="child-profile-data-row">
          <div class="child-profile-data-text">Email:</div>
          <div class="child-profile-contact">{{ child.email ? child.email : 'Не указан' }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
