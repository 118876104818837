<ng-container [ngSwitch]="classViewSelected">
  <ng-container *ngSwitchCase="classPageViews.CLASS_VIEW">
    <div [ngClass]="showEditPupilsComponent ? 'hide' : '' || showEditClassComponent ? 'hide' : ''">
      <div class="return-button-container">
        <a (click)="routingBack()" class="no-link">{{ 'BUTTONS.PREVIOUS' | translate }}</a>
      </div>

      <!-- компонент класса для просмотра из-под админов -->
      <prf-admin-class
        (editClassEvent)="showEditClass($event)"
        (editPupilsEvent)="showEditPupils($event)"
        (teacherEvent)="setTeacher($event)"
        (schoolClassEvent)="setSchoolClass($event)"
        (pupilsEvent)="setPupils($event)"
      ></prf-admin-class>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="classPageViews.EDIT_CLASS_VIEW">
    <!-- редактирование класса из-под шк. админа -->
    <prf-schooladmin-edit-class (showEditClass)="hideEditClass($event)" [teacher]="teacher" [teacherClass]="schoolClass">
    </prf-schooladmin-edit-class>
  </ng-container>
  <ng-container *ngSwitchCase="classPageViews.EDIT_PUPILS_VIEW">
    <!-- добавление учеников из-под шк. админа -->
    <prf-schooladmin-add-students
      (showEditPupils)="hideEditPupils($event)"
      [pupils]="pupils"
      [teacherClass]="schoolClass"
      [schoolId]="userProfile.schoolId"
    >
    </prf-schooladmin-add-students>
  </ng-container>
</ng-container>
