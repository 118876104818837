import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { UserProfileService } from '../../../../pages/catalog/user-profile-page/user-profile.service';
import { COURSES_TYPES } from '../../courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import { FavoritesService } from '../../../../pages/catalog/favorites/favorites.service';

@Component({
  selector: 'prf-user-favorites',
  templateUrl: './user-favorites.component.html',
  styleUrls: ['./user-favorites.component.scss'],
})
export class UserFavoritesComponent implements OnInit {
  @Input() user: any;
  @Input() favoritesList: any;
  courses: any = [];
  events: any = [];
  classes: any = [];
  favoritesMenu = [
    { key: 'all', name: 'Все' },
    { key: 'events', name: 'Мероприятия' },
    { key: 'courses', name: 'Курсы' },
    { key: 'classes', name: 'Предметы' },
  ];

  types: any = COURSES_TYPES;
  currentFilter: any;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private favoritesService: FavoritesService) {}

  ngOnInit() {
    this.currentFilter = this.favoritesMenu[0];
    this.getFavoritesEvents();
  }

  setFilter(filter: any) {
    this.currentFilter = filter;
  }

  getFavoritesEvents() {
    let ids = this.favoritesList.filter(el => el.productType === 2).map(el => el.productId);

    this.favoritesService
      .getUserFavoritesCourses(ids)
      .pipe(
        tap(favorites => {
          favorites.forEach(favourite => {
            if (favourite.classesFormat === 'ShortCourse' || favourite.classesFormat === 'LongCourse') {
              this.courses.push(favourite);
            } else {
              this.events.push(favourite);
            }
          });
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();

    // let userFavourites$: Observable<any>[] = [];
    // ids.forEach(id => {
    //   userFavourites$.push(
    //     this.favoritesService.getUserFavorite(id).pipe(
    //       take(1),
    //       tap(favourite => {
    //         //??
    //         if (
    //           favourite.classesFormat === 'ShortCourse' ||
    //           favourite.classesFormat === 'LongCourse'
    //         ) {
    //           this.courses.push(favourite);
    //         } else {
    //           this.events.push(favourite);
    //         }
    //       })
    //     )
    //   );
    // });

    // forkJoin(userFavourites$).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
