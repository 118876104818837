<a class="event-card adm" *ngIf="!viewValue" [routerLink]="['/' + userRole + '/internships', internship.hrid]">
  <ng-container *ngIf="internshipCard">
    <div class="event-card-col">
      <a class="event-gradient-bg adm w-inline-block" [ngClass]="blue">
        <div class="event-header">
          <h3 class="event-heading">{{ internship.name }}</h3>
          <div class="event-info-row-2">
            <div class="event-cost">{{ 'COURCES.PAGE.FREE' | translate }}</div>
          </div>
        </div>
        <div class="event-image-bg" [ngStyle]="{ 'background-image': 'url(/assets/images/background/event-bg-2.png)' }"></div>
      </a>
      <div class="event-info-div adm">
        <div class="event-date" *ngIf="internship.isRegular">
          {{ 'SHARED.REGULARLY' | translate }}
        </div>
        <div class="event-date" *ngIf="internship.startDate">{{ startDate }}</div>
        <p class="event-description">{{ internship.shortDescription }}</p>
        <div class="event-address">{{ internship.address }}</div>
      </div>
    </div>
    <div class="event-status-block">
      <div class="event-status-text">{{ 'SHARED.ADDED' | translate }} {{ internship.publishedDate | date : 'dd MMM yyyy hh:mm' }}</div>
      <a
        *ngIf="accessAllow"
        [routerLink]="['/admin/edit-internship', internship.hrid]"
        class="event-status-edit-btn w-inline-block edit-event"
      ></a>
    </div>
  </ng-container>
</a>

<ng-template #isFavoriteCard>
  <a
    class="event-heart w-inline-block"
    [style.background-image]="'url(/assets/images/icons/heart-icon-black-active.png)'"
    (mouseover)="heartActive.style.backgroundImage = 'url(/assets/images/icons/heart.svg)'"
    (mouseleave)="heartActive.style.backgroundImage = 'url(/assets/images/icons/heart-icon-black-active.png)'"
    #heartActive
  >
  </a>
</ng-template>

<ng-template #internshipCard>
  <a class="event-gradient-bg full-height purple w-inline-block" [ngClass]="blue">
    <div class="event-header full-height">
      <h3 class="event-heading">{{ internship.name }}</h3>
      <div class="event-info-row-2">
        <div class="event-cost">{{ 'COURCES.PAGE.FREE' | translate }}</div>
      </div>
    </div>
    <div class="event-image-bg adm" [ngStyle]="{ 'background-image': 'url(/assets/images/background/event-bg-2.png)' }"></div>
  </a>
  <div class="event-status-block">
    <a
      *ngIf="accessAllow"
      [routerLink]="['/admin/edit-internship', internship.hrid]"
      class="event-status-edit-btn w-inline-block edit-event"
    ></a>
  </div>
</ng-template>

<div class="internships-list-item" *ngIf="viewValue">
  <a [routerLink]="['/internships', internship.hrid]" class="internships-list-item-link w-inline-block">
    <h3 class="internship-heading">{{ internship.name }}</h3>
  </a>
  <a
    *ngIf="accessAllow"
    [routerLink]="['/admin/edit-internship', internship.hrid]"
    class="event-status-edit-btn short w-inline-block edit-event"
  >
  </a>
  <a *ngIf="accessAllow" class="delete-attachment w-inline-block remove-event-btn edit-event" (click)="popUpConfirming = !popUpConfirming">
  </a>
</div>

<ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="/assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Удаление стажировки</h4>
          <div class="task-result-text">Вы уверены, что хотите удалить стажировку?</div>
          <a class="button attach-file-btn w-button" (click)="deleteInternship(internship.id)">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
