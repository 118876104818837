<div class="onboarding-page">
  <div class="onboarding-section">
    <ng-container [ngSwitch]="onboardingStep">
      <div *ngSwitchCase="1" class="onboarding-text">Ща всё будет 🌚</div>

      <div *ngSwitchCase="2" class="onboarding-text">37 вопросов о тебе — изи катка 👌</div>

      <div *ngSwitchCase="3" class="onboarding-text">Это не на время, проходи в своём темпе ❤️</div>

      <div *ngSwitchDefault class="onboarding-text"></div>

      <div class="step-bar-container">
        <div class="step-bar"></div>
      </div>
    </ng-container>
  </div>
</div>
