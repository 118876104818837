import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageHeaderService } from 'app/shared/dashboard/page-header/page-header.service';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'prf-schooladmin-menu',
  templateUrl: './schooladmin-menu.component.html',
  styleUrls: ['./schooladmin-menu.component.scss'],
})
export class SchooladminMenuComponent implements OnInit {
  @Input() innerRoute: boolean = false;
  @Input() professionActive: boolean = false;
  public href: string;
  public root: string = 'schooladmin';

  public isShowMobileMenu: boolean = false;
  public imagePath: string = '';
  public firstName: string = '';

  isMOStend: boolean = false;

  constructor(private router: Router, private menuService: MenuService, private headerService: PageHeaderService) {
    this.menuService.showMobileMenu$.subscribe(show => {
      this.isShowMobileMenu = show;
    });
    this.imagePath = localStorage.getItem('imagePath');
    this.firstName = localStorage.getItem('firstName');
    this.isMOStend = location.origin.includes('mosreg');
  }

  ngOnInit() {}

  get routeActive(): boolean {
    return this.router.isActive('/schooladmin', true);
  }

  public selectMain() {
    this.hideMobileMenu();
    return this.router.navigate(['/' + this.root + '/classes']);
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  @HostListener('window:resize', [])
  onResize(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  public hideMobileMenu() {
    this.menuService.showMobileMenu(false);
  }

  public hideProfile() {
    this.headerService.closeProfile();
  }

  public selectProfile() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/' + this.root + '/profile']);
  }
}
