<div class="add-new-director__form" *ngIf="editUserFlag">
  <div class="overlay">
    <div class="modal">
      <p (click)="toggleEditUserForm()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35">
          <g fill-rule="evenodd" stroke-linecap="square">
            <path d="M9.192 9.192l18.385 18.385M27.577 9.192L9.192 27.577"></path>
          </g>
        </svg>
      </p>
      <form novalidate [formGroup]="formUser" (submit)="onSubmit()">
        <p>{{ 'SHARED.EDITING' | translate }} пользователя</p>

        <mat-form-field>
          <input matInput type="text" class="w-input" name="lastName" placeholder="Фамилия" formControlName="lastName" />
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" class="w-input" name="firstName" placeholder="Имя" formControlName="firstName" />
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" class="w-input" name="middleName" placeholder="Отчество" formControlName="middleName" />
        </mat-form-field>
        <mat-form-field>
          <input matInput type="email" maxlength="70" class="w-input" name="email" placeholder="Email" formControlName="email" />
          <mat-error *ngIf="formUser.get('email').errors && formUser.get('email').errors.email">
            E-mail не соответствует шаблону: name&#64;domain.ru
          </mat-error>
        </mat-form-field>

        <button type="submit" class="button green-btn w-button">Сохранить</button>
      </form>
    </div>
  </div>
</div>
