import { ElementRef, ViewChild } from '@angular/core';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

import { Router } from '@angular/router';
import { PageHeaderService } from 'app/shared/dashboard/page-header/page-header.service';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'prf-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {
  @Input() home: string = 'Школы';
  @Input() homeURL: string = 'schools';
  @Input() itemsHide: Array<any> = [];
  @Input() hide: boolean = false;
  @Input() innerRoute: boolean = false;
  @Input() internshipActive: boolean = false;
  @Input() professionActive: boolean = false;
  @Input() adminLevel: string;
  public href: string;
  public root: string = '/';

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('menuTrigger2') menuTrigger2: MatMenuTrigger;

  public isShowMobileMenu: boolean = false;
  public imagePath: string = '';
  public firstName: string = '';
  public adminRole: string = '';

  isMOStend: boolean = false;
  // Переменная временно скрывает стажировки
  isInternshipsReady: boolean = false;

  constructor(public router: Router, private menuService: MenuService, private headerService: PageHeaderService) {
    this.menuService.showMobileMenu$.subscribe(show => {
      this.isShowMobileMenu = show;
    });
    this.imagePath = localStorage.getItem('imagePath');
    this.firstName = localStorage.getItem('firstName');
    this.adminRole = localStorage.getItem('userRole');
    this.isMOStend = location.origin.includes('mosreg');
  }

  ngOnInit() {
    this.href = this.router.url;
    this.root = localStorage.getItem('userRole');
  }

  get routeActive(): boolean {
    return this.router.isActive('/schooladmin', true);
  }

  menuOpened() {
    let toggledMenuOverlay = document.getElementsByClassName('cdk-overlay-connected-position-bounding-box')[0];
    if (window.innerWidth > 991) {
      toggledMenuOverlay.setAttribute('style', 'transform: translate(100px, -83px)');
    } else if (window.innerWidth > 767) {
      toggledMenuOverlay.setAttribute('style', 'transform: translate(100px, -55px)');
    } else {
      toggledMenuOverlay.setAttribute('style', 'transform: translate(55px, -10px)');
    }
  }

  public selectMain() {
    this.hideProfile();
    this.hideMobileMenu();

    return this.router.navigate(['/' + this.root]);
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isShowMobileMenu) {
      this.menuTrigger.closeMenu();
      this.menuTrigger2.closeMenu();
      this.isShowMobileMenu = false;
    }
  }

  @HostListener('window:resize', [])
  onResize(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  public hideMobileMenu() {
    this.menuService.showMobileMenu(false);
  }

  public hideProfile() {
    this.headerService.closeProfile();
  }

  public selectProfile() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/' + this.root + '/profile']);
  }

  /* Проверка есть ли в массиве элемент который нужно скрыть */
  public checkItemHide(element): boolean {
    const hideElement = this.itemsHide.filter(el => el === element);
    return hideElement[0] != element;
  }
}
