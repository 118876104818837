export enum FavoritesTypes {
  /// <summary>
  /// Консультация
  /// </summary>
  Consultation = 1,
  /// <summary>
  /// Запись на конкретную программу
  /// </summary>
  Class = 2,
  /// <summary>
  /// Профессия
  /// </summary>
  Profession = 3,
}
