<div class="tabs-menu-div parent-tabs">
  <div class="tabs-menu">
    <a
      *ngFor="let child of children; let first = first"
      [class.w--current]="child === selectedChild"
      (click)="onSelectChild(child)"
      class="tab-link-par parent-tabs w-inline-block w--current"
    >
      <div class="tab-link-parent">
        <img [attr.src]="child.imagePath ? child.imagePath : '/assets/images/dashboard/no-photo.svg'" alt="" class="tab-photo-child" />
        <div class="text-container">
          <div class="text-block">{{ child.firstName }}</div>
          <!-- <div class="tabs-child-school">
            <ng-container *ngIf="child.schoolClassNumber">{{
              child.schoolClassNumber
            }}</ng-container>
            <ng-container *ngIf="child.schoolClassLetter"
              >«{{ child.schoolClassLetter }}»,
            </ng-container>
            <ng-container *ngIf="child.schoolName">{{ child.schoolName }}</ng-container>
          </div> -->
        </div>
      </div>

      <img [class.active]="child === selectedChild" src="/assets/images/icons/angle.png" width="20" alt="" class="angle-parent-tabs" />
      <img
        [class.active]="child === selectedChild && !first"
        src="/assets/images/icons/angle2.png"
        width="20"
        alt=""
        class="angle-parent-tabs _2"
      />
    </a>

    <a
      *ngIf="childrenMore.length === 0 && addChildrenAllowed"
      (click)="clickAddChild()"
      (mouseenter)="buttonOpacity = 1"
      (mouseleave)="buttonOpacity = 0"
      class="tab-link add-tab parent-tabs w-inline-block w-tab-link"
    >
      <div class="profession-icon parent-tab-icon-div">
        <img src="/assets/images/icons/add-icon.svg" class="parent-tab-icon" />
        <img src="/assets/images/icons/add-prof.svg" class="icon-hover parent-tab-icon" [style.opacity]="buttonOpacity" />
      </div>
    </a>
  </div>

  <div *ngIf="childrenMore.length > 0" #tabDropdown class="tabs-dropdown w-dropdown">
    <div (click)="isTabDropdown = !isTabDropdown" class="tabs-dropdown-toggle w-dropdown-toggle">
      <img src="/assets/images/icons/Tabs-Dropdown-Icon.svg" alt="" class="tabs-dropdown-icon" />
    </div>

    <nav [class.w--open]="isTabDropdown" class="tabs-dropdown-list w-dropdown-list">
      <div class="tabs-dd-bg">
        <div class="tabs-dd-row">
          <div *ngFor="let child of childrenMore" class="tabs-dd-column parent-tabs">
            <a (click)="onSelectChild(child)" [class.w--current]="child === selectedChild" class="tabs-dd-link parent-tabs w-inline-block">
              <div class="tab-link-parent dd-add-child">
                <img
                  [attr.src]="child.imagePath ? child.imagePath : '/assets/images/dashboard/no-photo.svg'"
                  alt=""
                  class="tab-photo-child"
                />
                <div class="text-container">
                  <div class="text-block">{{ child.firstName }}</div>
                  <!-- <div class="tabs-child-school">
                    <ng-container *ngIf="child.schoolClassNumber">{{
                      child.schoolClassNumber
                    }}</ng-container>
                    <ng-container *ngIf="child.schoolClassLetter"
                      >«{{ child.schoolClassLetter }}»,
                    </ng-container>
                    <ng-container *ngIf="child.schoolName">{{ child.schoolName }}</ng-container>
                  </div> -->
                </div>
              </div>
            </a>
          </div>

          <div class="tabs-dd-column parent-tabs">
            <a
              (click)="clickAddChild()"
              (mouseenter)="buttonOpacity = 1"
              (mouseleave)="buttonOpacity = 0"
              class="tabs-dd-link add-tab parent-tabs w-inline-block"
            >
              <div class="tab-link-parent dd-add-child">
                <div class="profession-icon parent-tab-icon-div dd-add-child">
                  <img src="/assets/images/icons/add-icon.svg" alt="" class="parent-tab-icon" />
                  <img src="/assets/images/icons/add-prof.svg" alt="" class="icon-hover parent-tab-icon" [style.opacity]="buttonOpacity" />
                </div>
                <div>
                  <div class="text-block dd-ad-child">{{ 'SHARED.ADD_CHILD' | translate }}</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
