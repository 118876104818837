import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { InstitutionClass } from 'app/shared/classes/institution.class';
import { Stands } from 'app/shared/enums/stands.enums';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { Observable, throwError, TimeoutError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export enum PricesFilterTypes {
  BY_ASCENDING,
  BY_DESCENDING,
}
export interface IPriceFilterFormat {
  value: PricesFilterTypes;
  viewValue: string;
}
export const priceFiltersArray: IPriceFilterFormat[] = [
  {
    value: PricesFilterTypes.BY_ASCENDING,
    viewValue: 'По возрастанию',
  },
  {
    value: PricesFilterTypes.BY_DESCENDING,
    viewValue: 'По убыванию',
  },
];

export enum DateFiltersTypes {
  JANUARY = 1,
  FEBRUARE = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}
export interface IDateFilterFormat {
  value: DateFiltersTypes;
  viewValue: string;
}
export const datesFiltersArray: IDateFilterFormat[] = [
  {
    value: DateFiltersTypes.JANUARY,
    viewValue: 'Январь',
  },
  {
    value: DateFiltersTypes.FEBRUARE,
    viewValue: 'Февраль',
  },
  {
    value: DateFiltersTypes.MARCH,
    viewValue: 'Март',
  },
  {
    value: DateFiltersTypes.APRIL,
    viewValue: 'Апрель',
  },
  {
    value: DateFiltersTypes.MAY,
    viewValue: 'Май',
  },
  {
    value: DateFiltersTypes.JUNE,
    viewValue: 'Июнь',
  },
  {
    value: DateFiltersTypes.JULY,
    viewValue: 'Июль',
  },
  {
    value: DateFiltersTypes.AUGUST,
    viewValue: 'Август',
  },
  {
    value: DateFiltersTypes.SEPTEMBER,
    viewValue: 'Сентябрь',
  },
  {
    value: DateFiltersTypes.OCTOBER,
    viewValue: 'Октябрь',
  },
  {
    value: DateFiltersTypes.NOVEMBER,
    viewValue: 'Ноябрь',
  },
  {
    value: DateFiltersTypes.DECEMBER,
    viewValue: 'Декабрь',
  },
];

@Injectable({
  providedIn: 'root',
})
export class FiltersPromotestCoursesService {
  private coursesFilters = new BehaviorSubject<IFilterClasses>({});
  private priceFilters = new BehaviorSubject<IPriceFilterFormat>(null);
  private dateFilter = new BehaviorSubject<IDateFilterFormat>(null);

  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  getCoursesFilters(): Observable<IFilterClasses> {
    return this.coursesFilters.asObservable();
  }
  setCoursesFilter(filters: IFilterClasses, schoolID?: any) {
    this.coursesFilters.next(filters);
  }

  setPriceFilter(priceFilter: IPriceFilterFormat) {
    this.priceFilters.next(priceFilter);
  }
  getPriceFilter() {
    return this.priceFilters.asObservable();
  }

  setDateFilter(dateFilter: IDateFilterFormat) {
    this.dateFilter.next(dateFilter);
  }
  getDateFilter() {
    return this.dateFilter.asObservable();
  }

  public getTalents() {
    return this.http.get('/api/v1.0/catalog/search/talents').pipe(
      map(response => {
        const data = response.result;
        data.sort(sortList);
        return uniqSorted(data);
      }),
    );
  }

  getInstitutionsAll(): Observable<InstitutionClass[]> {
    return this.http.get('/api/v1.0/catalog/institutions/all').pipe(
      map(r => r.institutions),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getFilteredClassesMongo(filters: IFilterClasses) {
    const credentials = {
      filters: filters,
    };

    credentials.filters.stand = Stands.Talent;
    return this.http.post('/api/v1.0/catalog/classes/mongo/search/filters', credentials).pipe(map(r => r.classes));
  }

  public getTalentGroupCourse() {
    return this.http.get('/api/v1.0/catalog/search/talentgroupcourse').pipe(map(response => response.result));
  }

  public getCourseGroups() {
    return this.http.get('/api/v1.0/catalog/fielddos/all').pipe(
      map(response => {
        const data = response.fieldDOs;
        data.sort(sortList);
        return data;
      }),
    );
  }
}

function sortList(left, right) {
  if (left.name > right.name) {
    return 1;
  }
  if (left.name < right.name) {
    return -1;
  }
  return 0;
}
function uniqSorted(list) {
  const newList = [list[0]];

  list.forEach(({ name, line }) => {
    if (newList[newList.length - 1].name !== name) {
      newList.push({ name, line });
    }
  });

  return newList;
}
