<div class="results-shortened">
  <ng-container *ngTemplateOutlet="childInvite"></ng-container>
  <section class="test-result-section" #skills>
    <h2 class="test-result-h1 left-aligned">Навыки и способности</h2>
    <p class="test-result-text left-aligned">
      Эта схема демонстрирует сильные стороны вашего подростка и определяет круг задач, которые он, по вашему мнению, может успешно решать.
    </p>

    <div class="test-result-diagram">
      <prf-spider-chart-custom
        [inputSpiderChartData]="skillsAttainmentData"
        [theme]="SpiderChartThemeEnum.GREEN"
        [figureType]="SpiderChartFiguresEnum.HEXAGON"
        [isAdditionalResultsFirst]="true"
      >
      </prf-spider-chart-custom>
    </div>
  </section>

  <section class="test-result-section interests-section" #interests>
    <h2 class="test-result-h1 left-aligned">Интересы</h2>
    <p class="test-result-text left-aligned">Посмотрите рейтинг сфер по уровню интереса и желания заниматься ими.</p>

    <div class="interests-container" #interests>
      <prf-interests-progress-bar [data]="interestsData" [isParent]="false" [showText]="false"> </prf-interests-progress-bar>
    </div>
  </section>

  <div class="overlay-cap">
    <div class="overlay-cap-content">
      <ng-container *ngTemplateOutlet="childInvite"></ng-container>
    </div>
  </div>
</div>

<ng-template #childInvite>
  <prf-parent-child-invite
    [childPhoneNumber]="child.phoneNumber"
    [childInviteStep]="childInviteStep"
    (changePhoneNumber)="changePhoneNumber($event)"
    (openSnackBar)="openSnackBar($event)"
  ></prf-parent-child-invite>
</ng-template>
