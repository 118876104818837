<div *ngIf="dataLoaded" class="content-row">
  <div class="content-column main-column">
    <div class="tabs w-tabs">
      <div class="tabs-professions">
        <div class="tabs-menu-div">
          <div class="tabs-menu-prof">
            <a
              class="tab-link w-inline-block"
              *ngFor="let favorite of goalsProfessions | slice : 0 : 3; let i = index"
              [ngClass]="favorite.id === profession.id ? 'current' : ''"
              (click)="selectFavorite(favorite)"
            >
              <div class="profession-icon">
                <img
                  *ngIf="favorite.mainImagePath"
                  class="profession-image"
                  src="{{ '/assets/images/profession/' + favorite.mainImagePath.substring(42) }}"
                />
                <img *ngIf="!favorite.mainImagePath" class="profession-image" src="/assets/images/icons/no-prof-icon.png" />
              </div>
              <div class="profession-name">{{ favorite.name }}</div>
              <img *ngIf="i !== 2 && favorite.id === profession.id" src="/assets/images/icons/angle.png" width="20" class="angle" />
              <img *ngIf="i !== 0 && favorite.id === profession.id" src="/assets/images/icons/angle2.png" width="20" class="angle _2" />
            </a>
            <ng-container *ngIf="goalsProfessions.length < 3">
              <a
                (mouseover)="addIcon.style.opacity = '0'; addProf.style.opacity = '1'"
                (mouseleave)="addIcon.style.opacity = '1'; addProf.style.opacity = '0'"
                class="tab-link w-inline-block"
              >
                <div>
                  <a class="tabs-dd-link add-tab w-inline-block" routerLink="/professions">
                    <div class="profession-icon">
                      <img src="/assets/images/icons/add-icon.svg" class="icon-initial-state" #addIcon />
                      <img src="/assets/images/icons/add-prof.svg" class="icon-hover" #addProf />
                    </div>
                    <div>{{ 'SHARED.ADD' | translate }}</div>
                  </a>
                </div>
              </a>
            </ng-container>
          </div>

          <div class="tabs-dropdown w-dropdown" (click)="openProfessions()" *ngIf="goalsProfessions.length > 2" #dropProfession>
            <div class="tabs-dropdown-toggle w-dropdown-toggle">
              <img
                src="/assets/images/icons/Tabs-Dropdown-Icon.svg"
                [ngClass]="showProfessions ? 'rotateUp' : ''"
                class="tabs-dropdown-icon"
              />
            </div>
            <div class="tabs-dropdown-list w-dropdown-list" [ngClass]="showProfessions ? 'w--open' : ''">
              <div class="tabs-dd-bg">
                <div class="tabs-dd-row">
                  <div class="tabs-dd-column" *ngFor="let favorite of goalsProfessions | slice : 3">
                    <a
                      class="tabs-dd-link w-inline-block"
                      [ngClass]="favorite.id === profession.id ? 'current' : ''"
                      (click)="selectFavorite(favorite)"
                    >
                      <div class="profession-icon" *ngIf="favorite.mainImagePath">
                        <img class="profession-image" src="{{ '/assets/images/profession/' + favorite.mainImagePath.substring(42) }}" />
                      </div>
                      <div class="profession-name">{{ favorite.name }}</div>
                    </a>
                  </div>
                  <div
                    class="tabs-dd-column"
                    (mouseover)="addIcon.style.opacity = '0'; addProf.style.opacity = '1'"
                    (mouseleave)="addIcon.style.opacity = '1'; addProf.style.opacity = '0'"
                  >
                    <a class="tabs-dd-link add-tab w-inline-block" routerLink="/professions">
                      <div class="profession-icon">
                        <img src="/assets/images/icons/add-icon.svg" class="icon-initial-state" #addIcon />
                        <img src="/assets/images/icons/add-prof.svg" class="icon-hover" #addProf />
                      </div>
                      <div>{{ 'SHARED.ADD' | translate }}</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tabs-content w-tab-content">
        <div data-w-tab="Tab 1" class="w-tab-pane w--tab-active">
          <div class="tab-pane">
            <div class="tab-container">
              <div>
                <h2>
                  Моя цель — <a (click)="showProfession(profession)">{{ profession.name }}</a>
                </h2>
                <!--<prf-steps-trajectory
                  [profession]="profession"
                  [hideButton]="true"
                  [showMoreAbout]="true">
                </prf-steps-trajectory>-->
              </div>

              <div class="line"></div>
              <h2>Изучение профессии</h2>
              <div>
                <p class="test-result-text _w-680" *ngIf="planLevel === 1">
                  Мы подготовили для тебя интересные задания. Выполняя их, ты <br />будешь узнавать о выбранной профессии все больше.
                  <br />Эти знания помогут тебе выстроить маршрут к цели!
                </p>
                <p class="test-result-text _w-680" *ngIf="planLevel === 2">
                  Это — твой маршрут к цели. Добавь необходимое <br />
                  количество задний в план и приступай к их выполнению. <br />Твой прогресс по иучению профессии отображается здесь.
                </p>
              </div>
              <div class="switcher-trajectory">
                <a (click)="selectMenu(1)" [class.active]="planLevel === 1" class="switcher-trajectory-btn"> Задания </a>
                <a
                  (click)="selectMenu(2)"
                  [class.active]="planLevel === 2"
                  [class.no-active]="!isActive"
                  (mouseover)="isActive ? false : (popup.style.display = 'block')"
                  (mouseleave)="isActive ? false : (popup.style.display = 'none')"
                  class="switcher-trajectory-btn"
                >
                  Мой план
                  <div class="info-popup recommendation w-hidden-small w-hidden-tiny" #popup style="display: none">
                    <p>Еще нет заданий для выполнения</p>
                    <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                  </div>
                </a>
              </div>

              <prf-pupil-tasks (checkActive)="checkActive($event)" [profession]="profession" *ngIf="planLevel === 1"></prf-pupil-tasks>

              <prf-pupil-plan *ngIf="planLevel === 2" [profession]="profession"></prf-pupil-plan>

              <div class="w-center-content">
                <a
                  class="professions-menu-link w--current btn-task"
                  [class.no-active-btn]="!isActive"
                  *ngIf="planLevel == 1"
                  (click)="selectMenu(2)"
                >
                  В мой план
                </a>
              </div>

              <!--Времменно убрал-->
              <section *ngIf="false && profession.facts && profession.facts[0]">
                <div class="line-tasks"></div>
                <div class="quote-block">
                  <div>
                    <h4>Кстати, ты знаешь что..</h4>
                    <p>{{ profession.facts[0] }}</p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <prf-test-result [skillsAttainmentData]="skillsAttainmentData" [weAreParent]="weAreParent"></prf-test-result>
</div>
