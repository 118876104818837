<ng-container *ngIf="isLoaded && isPupilLoaded">
  <!-- заглушка, если не пройден тест -->
  <div *ngIf="testsResultsView.length === 0" class="test-parent-div">
    <div class="welcome-row">
      <div class="test-parent-column">
        <ng-container *ngIf="isPupilPage">
          <h3 class="test-parent-h3">{{ 'PROFESSIONS.LAUNCH_TEST' | translate }}</h3>
          <p class="test-parent-text">
            {{ 'SHARED.SYSTEM_DIAGNOSTIC_NARRATIVE' | translate }}
          </p>
        </ng-container>
        <ng-container *ngIf="!isPupilPage">
          <h3 class="test-parent-h3">{{ 'SHARED.TEST_360.COMPLETE_TEST' | translate }}</h3>
          <p class="test-parent-text">
            {{ 'SHARED.TEST_360.NARRATIVE' | translate }}
          </p>
        </ng-container>
        <div class="main-screen-buttons-row">
          <a routerLink="/test-na-professiyu" class="button test-parent-button w-button">{{ 'SHARED.START_TEST' | translate }}</a>
        </div>
      </div>
      <img *ngIf="!isPupilPage" src="/assets/images/parent/Parent360.svg" alt="родитель" width="353" class="test-parent-img" />
      <ng-container *ngIf="isPupilPage">
        <img
          src="{{ isMale(userGender) ? '/assets/images/dashboard/Pupil-m.svg' : '/assets/images/dashboard/Pupil-w.svg' }}"
          alt="gender"
          width="365"
          class="welcome-image-stud"
        />
      </ng-container>
    </div>
  </div>

  <div class="test-history-block" *ngFor="let test of testsResultsView; let i = index">
    <div class="test-data">
      <div class="test-number">
        <span>{{ i + 1 }}</span>
      </div>
      <div class="test-date">
        {{ sessionList[i].createdDate | date : 'dd.MM.yyyy' }}
      </div>
      <div *ngIf="showTestName(test) && test.sessionListView.screeningTestName" class="test-name">
        {{ test.sessionListView.screeningTestName }}
      </div>
    </div>
    <div class="test-tabs" *ngIf="checkShortResults(test) && test.testsResults">
      <div class="tab-item talents-tab">
        <div class="tab-item-shadow">
          <div class="tab-item-container">
            <div class="tab-header">{{ 'SHARED.YOUR_TALENTS' | translate }}</div>
            <div class="tab-list">
              <div class="tab-list-item image-row" *ngFor="let talent of test.testsResults.talents.slice(0, 3)">
                <img class="tab-item-pic" src="{{ talent.imageUrl }}" alt="talent pic" />
                <div class="tab-item-text">{{ talent.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-item interests-tab">
        <div class="tab-item-shadow">
          <div class="tab-item-container">
            <div class="tab-header">
              {{ 'SHARED.INTERESTS_AND_PREFERENCES' | translate }}
            </div>
            <div class="tab-list">
              <div class="tab-list-item number-row" *ngFor="let interest of test.testsResults.interests.slice(0, 3)">
                <span class="tab-item-text">{{ interest.name }}</span>
                <div class="tab-item-value">
                  {{ interest.results[0].transformedValue | number : '2.0-0' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-item skills-tab">
        <div class="tab-item-shadow">
          <div class="tab-item-container">
            <div class="tab-header">Навыки и способности</div>
            <div class="tab-list">
              <div class="tab-list-item number-row" *ngFor="let skillAtt of test.testsResults.skillsAttainment.slice(0, 3)">
                <span class="tab-item-text">{{ skillAtt.name }}</span>
                <div class="tab-item-value">
                  {{ skillAtt.results[0].transformedValue | number : '2.0-0' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a class="button-more-info" [class.opened]="test.resultsOpened" (click)="getResult(test)">Подробнее</a>
    <div class="test-results" *ngIf="test.resultsOpened">
      <div class="test-history-underline" style="margin-bottom: 30px"></div>
      <ng-container [ngSwitch]="test.sessionListView.screeningTestId">
        <div *ngSwitchCase="testVariantsIds.motivationtest">
          <prf-motivation-results [pupil]="pupilInfo" [sessionId]="test.sessionListView.sessionId"></prf-motivation-results>
        </div>
        <!-- дополнить, если будут другие варианты тестов -->
        <div *ngSwitchDefault>
          <prf-results
            [child]="pupilInfo"
            [sessionId]="test.sessionListView.sessionId"
            [showResultMenu]="false"
            [showSwitch]="false"
          ></prf-results>
        </div>
      </ng-container>
    </div>
    <div class="test-history-underline"></div>
  </div>
</ng-container>
