import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';

import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';
import { ProfessionsService } from '../../professions.service';
import { IAllRecommendedProfessions, IRecommendedProfession, IRecommendedProfessionArray } from './model';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-recommended-professions',
  templateUrl: './recommended-professions.component.html',
  styleUrls: ['./recommended-professions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendedProfessionsComponent extends UnsubscribeComponent implements OnInit {
  @Input() public sessionId: string;

  public userId: string = '';
  public userRole: string = '';
  public sessionStatus: string = '';
  public showSalary: boolean = false;
  public recommendedProfessions: IAllRecommendedProfessions = null;
  public recommendedProfessionsArray: IRecommendedProfessionArray[] = [];

  private disableShowProfession: boolean = false;
  private favoritesList: Array<any> = [];

  constructor(
    private router: Router,
    private favoritesService: FavoritesService,
    private professionsService: ProfessionsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.userId = localStorage.getItem('userId');
    this.userRole = localStorage.getItem('userRole');
    this.sessionStatus = localStorage.getItem('sessionStatus');
    this.showSalary = AppSettingsService.settings.professionViewConfiguration.showSalary;
  }

  public ngOnInit(): void {
    forkJoin([
      this.professionsService.getRecommendedProfessions(this.sessionId),
      this.favoritesService.getUserFavorites(this.userId, FavoritesTypes.Profession),
    ]).subscribe(([recommendedProfessionsResponse, favouriteProfessionsResponse]) => {
      this.recommendedProfessions = recommendedProfessionsResponse.calculateProfessions;
      this.favoritesList = favouriteProfessionsResponse;
      this.favoritesService.setFavoriteProfessions(favouriteProfessionsResponse);
      const favoritesProfessionsIds = this.favoritesList.map(favouriteProfession => favouriteProfession.productId);

      this.recommendedProfessionsArray = [];

      for (const industryName in this.recommendedProfessions) {
        this.recommendedProfessions[industryName].forEach((profession: IRecommendedProfession) => {
          profession.mainImagePath = './assets/images/profession/' + profession.mainImagePath.substring(42);
          profession.isGoal = favoritesProfessionsIds.indexOf(profession.id) > -1;
        });

        this.recommendedProfessionsArray.push({ industry: industryName, professions: this.recommendedProfessions[industryName] });
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  public navigateProfessions(profession: IRecommendedProfession): void {
    if (!this.disableShowProfession) {
      this.router.navigate(['/professions', profession.hrid]).then();
    }

    this.disableShowProfession = false;
  }

  public addToFavorite(profession: IRecommendedProfession): void {
    this.disableShowProfession = true;
    this.favoritesService
      .addToFavorites(profession.id, this.userId, FavoritesTypes.Profession)
      .pipe(
        switchMap(() => this.getFavorites()),
        takeUntil(this.unsubscribe),
      )
      .subscribe(() => {
        profession.isGoal = true;
        this.changeDetectorRef.markForCheck();
      });
  }

  public removeFavorite(profession: IRecommendedProfession): void {
    this.disableShowProfession = true;
    const favoriteIds = this.favoritesList.filter(fl => fl.productId === profession.id).map(fl => fl.id);

    if (!favoriteIds.length) return;

    this.favoritesService
      .deleteFavorite(favoriteIds[0])
      .pipe(
        switchMap(() => this.getFavorites()),
        takeUntil(this.unsubscribe),
      )
      .subscribe(() => {
        this.favoritesList = this.favoritesList.filter(listItem => listItem.id !== favoriteIds[0]);
        profession.isGoal = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  public removeTags(test: string): string {
    return test && test.length ? test.replace(/<p>(.*?)<\/p>/gi, '$1') : '';
  }

  public checkProfession(profession: IRecommendedProfession): boolean {
    return profession.regionId === localStorage.getItem('regionId') && profession.municipalityId === localStorage.getItem('municipalityId');
  }

  private getFavorites(): Observable<any> {
    return this.favoritesService.getUserFavorites(this.userId, FavoritesTypes.Profession).pipe(
      tap(response => {
        this.favoritesList = response;
        this.favoritesService.setFavoriteProfessions(response);
      }),
    );
  }

  public trackByFn(index: number, profession: IRecommendedProfession): string {
    return profession.id;
  }
}
