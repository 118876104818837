<div class="testing-buttons-div">
  <a
    class="button testing-button back white-button w-button"
    routerLink="/{{ userRole }}"
    [class.disabled]="userRole === 'parent'"
    *ngIf="disabled || !enableBack; else enabledBack"
  >
    {{ 'BUTTONS.PREVIOUS' | translate }}
  </a>
  <ng-template #enabledBack>
    <a class="button testing-button back white-button w-button" (click)="back()">
      {{ 'BUTTONS.PREVIOUS' | translate }}
    </a>
  </ng-template>

  <a class="button testing-button next w-button disabled" *ngIf="disabled || !enableForward; else enabledForward">
    {{ 'BUTTONS.NEXT' | translate }}
  </a>
  <ng-template #enabledForward>
    <a *ngIf="isFinish" class="button testing-button w-button" (click)="forward()">
      {{ 'BUTTONS.OK' | translate }}
    </a>
    <a *ngIf="!isFinish" class="button testing-button next w-button" (click)="forward()">
      {{ 'BUTTONS.NEXT' | translate }}
    </a>
  </ng-template>
</div>
