<div class="login-section">
  <img class="logo_cornered" src="/assets/images/dashboard/profilum-new-logo.svg" alt="Profilum logo" />
  <div class="login-container">
    <div class="login-content">
      <div class="login-data">
        <div class="h1-login-text" [innerHTML]="loginTitleText | translate"></div>
        <div class="login-form-container">
          <form [formGroup]="form" action="">
            <p class="login-text">{{ loginFormText | translate }}</p>
            <ng-container [ngSwitch]="true">
              <ng-container *ngSwitchCase="regFormVariant === regForms.PHONE_INPUT">
                <div class="login-form-block">
                  <div class="login-input-container">
                    <label [class.topped-out]="phoneLabel" for="phone" class="login-label">{{ 'REGISTRATION.PHONE' | translate }}</label>

                    <div class="input-wrapper">
                      <input
                        class="login-input w-input"
                        [class.error-field]="form.get('phone').errors && phoneChecked"
                        id="phone"
                        maxlength="25"
                        type="text"
                        formControlName="phone"
                        [textMask]="{ mask: queryParams ? phoneMaskWithoutCode : phoneMask }"
                        (focusin)="phoneLabel = true; phoneChecked = false"
                        (focusout)="form.get('phone').value ? null : (phoneLabel = false); checkPhone()"
                      />

                      <a *ngIf="form.get('phone').errors?.isUsed && !queryParams" (click)="clearInput('phone')" class="input-clear">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L9 9" stroke="#FF5722" stroke-width="2" stroke-linecap="round" />
                          <path d="M9.00003 1L1.00003 9" stroke="#FF5722" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </a>
                    </div>

                    <ng-container>
                      <div *ngIf="form.get('phone').errors?.isUsed" class="alert-message">
                        {{ 'REGISTRATION.NUMBER_IS_USED' | translate }}
                      </div>
                      <div *ngIf="form.get('phone').errors?.notCorrect" class="alert-message">
                        {{ 'REGISTRATION.PASS_LOGIN_NOT_SUITS' | translate }}
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="regFormVariant === regForms.CODE_INPUT">
                <div class="login-form-block">
                  <div class="login-input-container">
                    <label [class.topped-out]="codeLabel" for="codeInput" class="login-label">{{
                      'REGISTRATION.PASSWORD' | translate
                    }}</label>
                    <div class="input-wrapper">
                      <input
                        *ngIf="isMaskedPassword"
                        type="password"
                        class="login-input w-input"
                        [class.error-field]="form.get('password').errors"
                        id="codeInput"
                        maxlength="25"
                        formControlName="password"
                        (focusin)="codeLabel = true"
                        (focusout)="form.get('password').value ? null : (codeLabel = false)"
                      />
                      <input
                        *ngIf="!isMaskedPassword"
                        type="text"
                        class="login-input w-input"
                        [class.error-field]="form.get('password').errors"
                        id="codeInput"
                        maxlength="25"
                        formControlName="password"
                        (focusin)="codeLabel = true"
                        (focusout)="form.get('password').value ? null : (codeLabel = false)"
                      />
                      <div [ngClass]="{ eye: !isMaskedPassword, eye__closed: isMaskedPassword }" (click)="toggleMask()"></div>
                      <!-- <a
                        *ngIf="form.get('password').errors && !queryParams"
                        (click)="clearInput('password')"
                        class="input-clear"
                      >
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L9 9"
                            stroke="#FF5722"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                          <path
                            d="M9.00003 1L1.00003 9"
                            stroke="#FF5722"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                        </svg>
                      </a> -->
                    </div>
                    <ng-container *ngIf="form.get('password').errors">
                      <div *ngIf="form.get('password').errors?.isOutdated" class="alert-message">
                        {{ 'REGISTRATION.CODE_IS_OUTDATED' | translate }}
                      </div>
                      <div *ngIf="form.get('password').errors?.notCorrect" class="alert-message">
                        {{ 'REGISTRATION.PASS_LOGIN_NOT_SUITS' | translate }}
                      </div></ng-container
                    >
                  </div>
                </div>
              </ng-container>
              <button
                class="submit-button button-blue"
                (click)="submit()"
                [class.inactive]="!submitAllowed()"
                [class.loading]="isProcessing"
              >
                <ng-container *ngIf="isProcessing">
                  <prf-loader-button></prf-loader-button>
                </ng-container>
                <ng-container *ngIf="!isProcessing">{{ 'LOGIN.TO_CONFIRM' | translate }}</ng-container>
              </button>
            </ng-container>
          </form>
        </div>
      </div>
      <div class="login-picture">
        <img src="/assets/images/login_profilum.svg" alt="registration Profilum" />
      </div>
    </div>

    <!-- <div class="tabs login w-tabs">
      <div class="tab-pane login">
        <div class="user-data-form-block w-form">
          <div class="login-form-row reg-form" [class.w-center]="isCodeQueryParam">
            <div class="login-form-column" *ngIf="!isCodeQueryParam">
              <a routerLink="/registration" class="reg-form-back-button">Назад</a>
            </div>
            <div class="login-field-column reg-form" *ngIf="registrationPupil">
              <img
                src="/assets/images/dashboard/reg-student.svg"
                class="registration-link-image reg-form"
              />
              <div class="registration-link-text reg-form">Ученик</div>
            </div>
            <div class="login-field-column reg-form" *ngIf="registrationOther">
              <img
                src="/assets/images/dashboard/reg-other.svg"
                class="registration-link-image reg-form"
              />
              <div class="registration-link-text reg-form">Организация</div>
            </div>
            <div *ngIf="registrationPupil && !isCodeQueryParam">
              <a *ngIf="tglRegistration" (click)="toggleRegistration()">Регистрация</a>
              <a *ngIf="!tglRegistration" (click)="toggleRegistration()">У меня есть код</a>
            </div>
          </div>
          <form [formGroup]="form" *ngIf="accessFormRole" autocomplete="off">
            <div class="login-form-row">
              <label for="email" class="login-label">Твой номер телефона</label>
              <div class="login-field-column">
                <input
                  type="email"
                  autocomplete="off"
                  class="login-field w-input"
                  [class.error-field]="
                    (submitted && f.email.errors) || (submitted && duplicateUserName)
                  "
                  maxlength="70"
                  name="email"
                  data-name="email"
                  id="email"
                  formControlName="email"
                  (focusout)="checkFormatEmail($event)"
                />

                <ng-container *ngIf="form.get('phone').errors">
                  <div *ngIf="false" class="alert-message"></div
                ></ng-container>
                <ng-container>
                  <mat-error *ngIf="!checkEmail"> Проверьте правильность email </mat-error>
                  <mat-error *ngIf="submitted && duplicateUserName">
                    Пользователь с такой электронной почтой уже существует
                  </mat-error>
                </ng-container>
              </div>
            </div>
            <div class="login-form-row">
              <label for="password" class="login-label">Код доступа</label>
              <div class="login-field-column">
                <input
                  *ngIf="isMaskedPassword"
                  type="password"
                  autocomplete="new-password"
                  class="login-field w-input short-right"
                  [class.error-field]="(submitted && f.password.errors) || passFailed"
                  maxlength="25"
                  name="password"
                  data-name="password"
                  id="password"
                  formControlName="password"
                />
                <input
                  *ngIf="!isMaskedPassword"
                  type="text"
                  autocomplete="new-password"
                  class="login-field w-input short-right"
                  [class.error-field]="(submitted && f.password.errors) || passFailed || codeIsUsed"
                  maxlength="25"
                  name="password"
                  data-name="password"
                  id="passwordUnMask"
                  formControlName="password"
                />
                <ng-container *ngIf="!isCodeQueryParam">
                  <div class="under-field-text" *ngIf="registrationPupil">
                    Этот код даст тебе родитель или учитель
                  </div>
                  <div class="under-field-text" *ngIf="registrationOther">
                    Для получения кода обратитесь в администрацию сервиса
                  </div>
                </ng-container>
                <div
                  [ngClass]="{eye: !isMaskedPassword, eye__closed: isMaskedPassword}"
                  (click)="toggleMask()"
                ></div>
                <ng-container *ngIf="submitted && f.password.errors">
                  <mat-error *ngIf="f.password.errors.required"> Заполните поле </mat-error>
                </ng-container>
                <mat-error *ngIf="submitted && passFailed"> Проверьте правильность кода </mat-error>
                <mat-error *ngIf="submitted && codeIsUsed"> Данный код уже использован</mat-error>
              </div>
            </div>

            <div class="terms" *ngIf="!registrationPupil">
              <div
                class="checkbox-terms-grn"
                [class.checked-terms-grn]="personalTerms"
                (click)="personalTerms = !personalTerms"
              ></div>
              <span class="terms-text"
                >Принимаю условия
                <a href="/assets/docs/talent/Agreement.pdf" target="_blank"
                  >пользовательского соглашения</a
                >
                и даю согласие на обработку моих
                <a href="/assets/docs/talent/PrivacyPolicy.pdf" target="_blank"
                  >персональных данных</a
                >
              </span>
            </div>
            <div class="terms" *ngIf="!registrationPupil">
              <div
                class="checkbox-terms-grn"
                [class.checked-terms-grn]="advertisingTerms"
                (click)="advertisingTerms = !advertisingTerms"
              ></div>
              <span class="terms-text"
                >Я хочу получать
                <a href="/assets/docs/ReceivingAdvertising.pdf" target="_blank"
                  >уведомления и рекламные акции</a
                >
              </span>
            </div>
            <div class="w-center-content">
              <a
                (click)="animateLogin()"
                [ngClass]="{disabled: !isAccessAllowed}"
                [class.btn-waiting]="buttonWaiting"
                [class.btn-activated]="buttonActivated"
                [class.btn-activate]="!buttonActivate"
                class="button-animate btn-activate"
                id="btnActivation"
              >
                <span class="btn-icon"></span>
                <span class="btn-text" data-wait="Подождите" data-after="Зарегистрироваться"
                  >Зарегистрироваться</span
                >
              </a>
            </div>
          </form>

          <prf-registration-pupil-b2c
            *ngIf="registrationPupil && !tglRegistration"
            [tag]="tag"
          ></prf-registration-pupil-b2c>
        </div>
      </div>
    </div> -->
  </div>

  <prf-page-footer> </prf-page-footer>
</div>
