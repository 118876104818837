import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { of, Subject } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { AdminPanelService } from '../../admin-panel.service';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { RegionsService } from '../../regions/regions.service';
import { SystemService } from '../system.service';
import { saveAs } from 'file-saver';

interface IListedOption {
  name: string | number;
  data: any;
}

// список регионов с выбором языков
// Kazahstan regionId
const multipleLanguagesRegions: string[] = ['aeba8896-64c1-49c0-9a47-099b6b192a55'];

@Component({
  selector: 'prf-system-reports',
  templateUrl: './system-reports.component.html',
  styleUrls: ['./system-reports.component.scss'],
})
export class SystemReportsComponent implements OnInit {
  regions: IRegion[] = [];
  listedRegions: IListedOption[] = [];
  regionReportUpdating: boolean = false;
  regionReportPrepearing: boolean = false;

  regionPupilsUpdating: boolean = false;
  regionPupilsPrepearing: boolean = false;

  municipalitiesByRegion: IMunicipality[] = [];
  listedMunicipalities: IListedOption[] = [];
  municipalityReportUpdating: boolean = false;
  municipalityReportPrepearing: boolean = false;
  professionsUpdating: boolean = false;

  public form: UntypedFormGroup;

  isLoaded: boolean = true;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private meta: Meta,
    private adminService: AdminPanelService,
    private systemService: SystemService,
    private utilsService: UtilsService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private translateService: TranslateService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Отчеты' });
  }

  ngOnInit() {
    this.initForm();
    this.getRegions();

    this.form
      .get('municipalityReport')
      .get('region')
      .valueChanges.pipe(
        mergeMap(value => {
          if (value && value.data) {
            let selectedRegion = this.regions.find(region => region.hrid === value.data);
            this.form.get('municipalityReport').get('municipality').reset();

            return this.municipalitiesService.getMunicipalitiesByRegion(selectedRegion.id).pipe(
              tap(municipalities => {
                this.municipalitiesByRegion = municipalities;
                this.listedMunicipalities = [];
                municipalities.forEach(municipality =>
                  this.listedMunicipalities.push({ name: municipality.name, data: municipality.hrid }),
                );
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  initForm() {
    this.form = this.fb.group({
      region: new UntypedFormControl(null),
      pupilsRegion: new UntypedFormControl(null),
      municipalityReport: this.fb.group({
        region: new UntypedFormControl(null),
        municipality: new UntypedFormControl(null),
      }),
    });
  }

  getRegions() {
    this.regionsService
      .getAllRegions()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(res => {
        this.regions = res;
        this.regions.forEach(region =>
          this.listedRegions.push({
            name: region.name,
            data: region.hrid,
          }),
        );
      });
  }

  updateRegionReport() {
    let selectedRegion = this.regions.find(region => region.name === this.form.get('region').value.name);
    let language: string = null;
    language = multipleLanguagesRegions.includes(selectedRegion.id)
      ? (language = localStorage.getItem('selectedLanguage')
          ? localStorage.getItem('selectedLanguage')
          : this.translateService.getDefaultLang())
      : null;
    if (selectedRegion) {
      this.regionReportUpdating = true;
      this.form.get('region').disable();

      this.systemService
        .updateRegionStatistic(selectedRegion.id, language)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          response => {
            this.regionReportUpdating = false;
            this.form.get('region').enable();
            this.utilsService.openSnackBar('Данные по региону обновлены, можно загружать отчет', 'success');
          },
          err => {
            this.regionReportUpdating = false;
            this.form.get('region').enable();
            this.form.get('region').setValue(null);
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        );
    }
  }
  getRegionReport() {
    let selectedRegion = this.regions.find(region => region.name === this.form.get('region').value.name);
    let language: string = null;
    language = multipleLanguagesRegions.includes(selectedRegion.id)
      ? (language = localStorage.getItem('selectedLanguage')
          ? localStorage.getItem('selectedLanguage')
          : this.translateService.getDefaultLang())
      : null;
    if (selectedRegion) {
      this.regionReportPrepearing = true;
      this.form.get('region').disable();
      this.systemService
        .getRegionStatisticExcel(selectedRegion.id, language)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          response => {
            this.regionReportPrepearing = false;
            this.form.get('region').enable();
            this.form.get('region').setValue(null);
            this.utilsService.openSnackBar('Отчет получен', 'success');
            const blob = new Blob([response.body], { type: response.headers.get('content-type') });
            var fileName = this.parseFilenameFromContentDisposition(response.headers.get('content-disposition'));
            const file = new File([blob], fileName, { type: response.headers.get('content-type') });
            saveAs(file);
          },
          err => {
            this.regionReportPrepearing = false;
            this.form.get('region').enable();
            this.form.get('region').setValue(null);
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        );
    }
  }

  updateRegionPupilsReport() {
    let selectedRegion = this.regions.find(region => region.name === this.form.get('pupilsRegion').value.name);
    if (selectedRegion) {
      this.regionPupilsUpdating = true;
      this.form.get('pupilsRegion').disable();
      this.systemService
        .updateRegionPupilsStatistic(selectedRegion.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          response => {
            this.regionPupilsUpdating = false;
            this.form.get('pupilsRegion').enable();
            this.utilsService.openSnackBar('Данные учеников по региону обновлены, можно загружать отчет', 'success');
          },
          err => {
            this.regionPupilsUpdating = false;
            this.form.get('pupilsRegion').enable();
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        );
    }
  }
  getRegionPupilsReport() {
    let selectedRegion = this.regions.find(region => region.name === this.form.get('pupilsRegion').value.name);
    if (selectedRegion) {
      this.regionPupilsPrepearing = true;
      this.form.get('pupilsRegion').disable();
      this.systemService
        .getRegionPupilsStatisticExcel(selectedRegion.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          response => {
            this.regionPupilsPrepearing = false;
            this.form.get('pupilsRegion').enable();
            this.form.get('pupilsRegion').setValue(null);
            this.utilsService.openSnackBar('Отчет получен', 'success');
            const blob = new Blob([response.body], { type: response.headers.get('content-type') });
            var fileName = this.parseFilenameFromContentDisposition(response.headers.get('content-disposition'));
            const file = new File([blob], fileName, { type: response.headers.get('content-type') });
            saveAs(file);
          },
          err => {
            this.regionPupilsPrepearing = false;
            this.form.get('pupilsRegion').enable();
            this.form.get('pupilsRegion').setValue(null);
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        );
    }
  }

  updateMunicipalityReport() {
    let selectedRegion = this.regions.find(region => region.name === this.form.get('municipalityReport').get('region').value.name);
    let selectedMunicipality = this.municipalitiesByRegion.find(
      municipality => municipality.name === this.form.get('municipalityReport').get('municipality').value.name,
    );
    let language = localStorage.getItem('selectedLanguage')
      ? localStorage.getItem('selectedLanguage')
      : this.translateService.getDefaultLang();
    if (selectedRegion && selectedMunicipality && language) {
      this.municipalityReportUpdating = true;
      this.form.get('municipalityReport').get('municipality').disable();
      this.systemService
        .updateMunicipalityStatistic(selectedRegion.id, selectedMunicipality.id, language)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          response => {
            this.municipalityReportUpdating = false;
            this.form.get('municipalityReport').get('municipality').enable();
            this.utilsService.openSnackBar('Данные по муниципалитету обновлены, можно загружать отчет', 'success');
          },
          err => {
            this.municipalityReportUpdating = false;
            this.form.get('municipalityReport').get('municipality').enable();
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        );
    }
  }
  getMunicipalityReport() {
    let selectedRegion = this.regions.find(region => region.name === this.form.get('municipalityReport').get('region').value.name);
    let selectedMunicipality = this.municipalitiesByRegion.find(
      municipality => municipality.name === this.form.get('municipalityReport').get('municipality').value.name,
    );
    let language = localStorage.getItem('selectedLanguage')
      ? localStorage.getItem('selectedLanguage')
      : this.translateService.getDefaultLang();
    if (selectedRegion && selectedMunicipality && language) {
      this.municipalityReportPrepearing = true;
      this.form.get('municipalityReport').get('municipality').disable();
      this.systemService
        .getMunicipalityStatisticExcel(selectedRegion.id, selectedMunicipality.id, language)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          response => {
            this.municipalityReportPrepearing = false;
            this.form.get('municipalityReport').get('municipality').enable();
            this.form.get('municipalityReport').get('region').setValue(null);
            this.form.get('municipalityReport').get('municipality').setValue(null);
            this.utilsService.openSnackBar('Отчет получен', 'success');
            const blob = new Blob([response.body], { type: response.headers.get('content-type') });
            var fileName = this.parseFilenameFromContentDisposition(response.headers.get('content-disposition'));
            const file = new File([blob], fileName, { type: response.headers.get('content-type') });
            saveAs(file);
          },
          err => {
            this.municipalityReportPrepearing = false;
            this.form.get('municipalityReport').get('municipality').enable();
            this.form.get('municipalityReport').get('region').setValue(null);
            this.form.get('municipalityReport').get('municipality').setValue(null);
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        );
    }
  }

  updateProfessions() {
    this.professionsUpdating = true;
    this.systemService
      .updateProfessions()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        r => {
          this.professionsUpdating = false;
        },
        err => {
          this.professionsUpdating = false;
          this.utilsService.openSnackBar('Произошла ошибка', 'error');
        },
      );
  }

  private parseFilenameFromContentDisposition(contentDisposition) {
    if (!contentDisposition) return null;
    let matches = /filename="(.*?)"/g.exec(contentDisposition);
    return matches && matches.length > 1 ? matches[1] : 'untitled';
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
