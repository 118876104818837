import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { PupilPanelService } from '../../../../pages/control-panel/pupil/pupil-panel.service';
import { ProgrammsService } from '../../backend-services/programms.service';
import { IResult } from '../../../../pages/results/results.interface';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SharedService } from 'app/shared/shared.service';
import { ITestInfo } from 'app/shared/interfaces/itestinfo.interface';

@Component({
  selector: 'prf-recommended-courses',
  templateUrl: './recommended-courses.component.html',
  styleUrls: ['./recommended-courses.component.scss'],
})
export class RecommendedCoursesComponent implements OnInit, OnDestroy {
  @Input() isCourses: boolean;
  @ViewChild('swiperComponent') swiperComponent?: ElementRef;
  public swiper?: Swiper;

  menuList: any;
  testRecommends: any = [];
  parentsRecomends: any = [];
  teacherRecomends: any = [];
  currentRecommend: any;
  userId: string;
  lastTestResults: any;
  dataFetched: boolean = false;
  courseCategories: IResult[] = [];
  programsAmount: number = 3;
  currentIndexCarousel: number = 0;
  recommendedCourses: any = [];
  recommendedCoursesTest: Array<any> = [];
  recommendedCoursesParents: Array<any> = [];
  recommendedCoursesTeacher: Array<any> = [];
  titleName: string;
  public userRole: string = '';

  public swiperConfig: SwiperOptions  = {
    slidesPerView: 3,
    slidesPerGroup: 3,
    speed: 500,
  };
  public testSessions: any[] = [];

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private pupilPanelService: PupilPanelService,
    private programmsService: ProgrammsService,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private appSettingsService: AppSettingsService,
    private sharedService: SharedService,
  ) {
    this.userRole = localStorage.getItem('userRole');
    this.route.data.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(v => {
      this.isCourses = v.isCourses;
    });
  }

  ngOnInit() {
    this.titleName = this.isCourses ? 'курсов' : 'мероприятий';
    this.userId = localStorage.getItem('userId');

    forkJoin([this.getTestResults(), this.addRecommendedProgramsByParents(), this.addRecommendedProgramsByTeacher()])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.dataFetched = true;
        this.createMenu();
      });
  }

  public clickNext() {
    this.swiper.slideNext();
    this.currentIndexCarousel++;
  }

  public clickPrev() {
    this.swiper.slidePrev();
    this.currentIndexCarousel--;
  }

  public selectMenu(recommend: any) {
    if (recommend.isContent) {
      this.currentRecommend = recommend;

      this.getTranslation('SHARED.RESULT.BY_TEST')
        .pipe(take(1))
        .subscribe(translation => {
          let recommendedByTest = translation;

          if (recommend.name === recommendedByTest) {
            this.recommendedCourses = this.recommendedCoursesTest;
            this.currentIndexCarousel = 0;
          }

          if (recommend.name === 'От родителей') {
            this.recommendedCourses = this.recommendedCoursesParents;
            this.currentIndexCarousel = 0;
          }

          if (recommend.name === 'От учителя') {
            this.recommendedCourses = this.recommendedCoursesTeacher;
            this.currentIndexCarousel = 0;
          }
        });
    }
  }

  getTestResults(): Observable<any> {
    return this.pupilPanelService.getTestingSessions(this.userId).pipe(
      switchMap(sessions => {
        if (sessions && sessions.length > 0) {
          return this.sharedService.getRegionTestInfo(localStorage.getItem('regionId')).pipe(
            tap((testInfo: ITestInfo) => {
              this.testSessions = sessions.filter(el => el.completed === true && el.screeningTestId === testInfo.screeningTestId);

              // Ищем сессию с результатами теста
              let lastSessionCompleted = this.testSessions[this.testSessions.length - 1];
              if (lastSessionCompleted) {
                return this.pupilPanelService.getResults(lastSessionCompleted.sessionId).pipe(
                  switchMap(results => {
                    if (results.results.length > 0) {
                      this.lastTestResults = results.results;
                      this.courseCategories = this.lastTestResults.filter(d => d.objectType === 'Course' && d.results.length);
                      // запрашиваются результаты по sessionId, не совместные, поэтому берутся results[0]
                      this.testRecommends = this.courseCategories.sort((a, b) =>
                        a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1,
                      );
                      return this.getCourses();
                    } else {
                      return of(null);
                    }
                  }),
                );
              } else {
                return of(null);
              }
            }),
          );
        } else {
          return of(null);
        }
      }),
    );
  }

  async getCourses() {
    const maxCountCourses = 9;
    let coursesArrayCount = 0;
    let inc = 0;

    if (this.isCourses) {
      do {
        await this.programmsService
          .getCoursesByCourseObject(this.programsAmount, this.testRecommends[inc].name)
          .pipe(
            take(1),
            tap(courses => {
              if (courses.length) {
                coursesArrayCount += courses.length;
                this.recommendedCoursesTest.push({
                  name: this.testRecommends[inc].name,
                  image: this.testRecommends[inc].imageUrl,
                  programms: courses,
                });
              }
            }),
          )
          .toPromise();
        inc++;
      } while (coursesArrayCount < maxCountCourses);
    } else {
      do {
        await this.programmsService
          .getEventsByCourseObject(this.programsAmount, this.testRecommends[inc].name)
          .pipe(
            take(1),
            tap(courses => {
              if (courses.length) {
                coursesArrayCount += courses.length;
                this.recommendedCoursesTest.push({
                  name: this.testRecommends[inc].name,
                  image: this.testRecommends[inc].imageUrl,
                  programms: courses,
                });
              }
            }),
          )
          .toPromise();
        inc++;
      } while (coursesArrayCount < maxCountCourses);
    }
  }

  addRecommendedProgramsByParents(): Observable<any> {
    let courseFormats: Array<string> = ['ShortCourse', 'LongCourse'];
    return this.programmsService.getUserRecommendations('Class', 'Parent').pipe(
      take(1),
      switchMap(r => {
        this.parentsRecomends = r;

        if (this.parentsRecomends && this.parentsRecomends.length) {
          return this.programmsService.CoursesByIds(this.parentsRecomends).pipe(
            take(1),
            tap(recommendedCoursesParents => {
              let courses = [];

              if (this.isCourses) {
                courses = recommendedCoursesParents.filter(rcp => courseFormats.some(cf => cf === rcp.classesFormat));
              } else {
                courses = recommendedCoursesParents.filter(rcp => !courseFormats.some(cf => cf === rcp.classesFormat));
              }

              if (courses.length === 0) {
                this.parentsRecomends = [];
              }

              this.recommendedCoursesParents = this.addCourses(courses);
            }),
          );
        } else {
          return of(null);
        }
      }),
    );
  }

  addRecommendedProgramsByTeacher(): Observable<any> {
    let courseFormats: Array<string> = ['ShortCourse', 'LongCourse'];
    return this.programmsService.getUserRecommendations('Class', 'Teacher').pipe(
      take(1),
      switchMap(r => {
        this.teacherRecomends = r;

        if (this.teacherRecomends && this.teacherRecomends.length) {
          return this.programmsService.CoursesByIds(this.teacherRecomends).pipe(
            take(1),
            tap(recommendedCoursesParents => {
              let courses = [];

              if (this.isCourses) {
                courses = recommendedCoursesParents.filter(rcp => courseFormats.some(cf => cf === rcp.classesFormat));
              } else {
                courses = recommendedCoursesParents.filter(rcp => !courseFormats.some(cf => cf === rcp.classesFormat));
              }

              if (courses.length === 0) {
                this.teacherRecomends = [];
              }
              this.recommendedCoursesTeacher = this.addCourses(courses);
            }),
          );
        } else {
          return of(null);
        }
      }),
    );
  }

  createMenu() {
    this.getTranslation('SHARED.RESULT.BY_TEST')
      .pipe(take(1))
      .subscribe(translation => {
        let recommendedByTest = translation;

        this.menuList = [
          {
            name: recommendedByTest,
            isContent: this.testRecommends.length > 0,
          },
          { name: 'От родителей', isContent: this.parentsRecomends.length > 0 },
          { name: 'От учителя', isContent: this.teacherRecomends.length > 0 },
        ];

        let currentMenuTab = this.menuList.filter(item => item.isContent)[0] || this.menuList[0];
        this.selectMenu(currentMenuTab);
      });
  }

  private addCourses(courses): Array<any> {
    let recommendedCourses = [{ name: '', image: '', programms: [] }];

    courses.forEach(c => {
      let index = recommendedCourses.length - 1;

      if (recommendedCourses[index].programms.length < this.programsAmount) {
        recommendedCourses[index].programms.push(c);
      } else {
        recommendedCourses.push({ name: '', image: '', programms: [] });
        recommendedCourses[index + 1].programms.push(c);
      }
    });

    return recommendedCourses;
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
