<div class="card">
  <mat-form-field>
    <label for="title"></label>
    <input matInput id="title" [(ngModel)]="news.title" name="title" #newsTitle="ngModel" minlength="3" required />
    <mat-error *ngIf="newsTitle.errors?.required && newsTitle.touched">
      {{ 'SHARED.REQUIRED_CAPTION' | translate }}
    </mat-error>
    <mat-error *ngIf="newsTitle.errors?.minlength && newsTitle.touched">
      {{ 'REGISTRATION.FORM_ALERT.5_CHARACTERS' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <label for="description"></label>
    <input matInput id="description" [(ngModel)]="news.description" name="description" #newsDescription="ngModel" minlength="3" required />
    <mat-error *ngIf="newsDescription.errors?.required && newsDescription.touched"> Требуется описание </mat-error>
    <mat-error *ngIf="newsDescription.errors?.minlength && newsDescription.touched">
      {{ 'REGISTRATION.FORM_ALERT.3_CHARACTERS' | translate }}
    </mat-error>
  </mat-form-field>

  <div *ngIf="true">
    <h5>{{ 'SHARED.LOGO' | translate }}</h5>
    <div class="upload-file">
      <img [src]="news.imageURL" />
      <input type="file" (change)="selectFile($event)" />
      <button mat-raised-button color="accent" [disabled]="!_selectedFiles" (click)="upload()">
        {{ 'SHARED.DOWNLOAD.BASE' | translate }}
      </button>
    </div>
  </div>

  <mat-form-field>
    <mat-select placeholder="Получатель новости" (change)="changeSelected($event.value)" required>
      <mat-option *ngFor="let school of schools" [(value)]="school.value">
        {{ school.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button type="submit" (click)="onSubmit(true)" mat-raised-button color="primary">
    {{ 'SHARED.ADD' | translate }}
  </button>
</div>
<prf-page-toast *ngIf="error"></prf-page-toast>
