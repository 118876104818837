import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PupilPanelService } from 'app/pages/control-panel/pupil/pupil-panel.service';
import { ETrajectoryTypes } from 'app/shared/enums/trajectorytypes.enum';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { GoalsService } from '../goals/goals.service';

const MAX_FILE_SIZE_MB: number = 10; // [MB]

@Component({
  selector: 'prf-static-task-modal',
  templateUrl: './static-task-modal.component.html',
  styleUrls: ['./static-task-modal.component.scss'],
})
export class StaticTaskModalComponent implements OnInit, OnDestroy {
  public show: boolean = false;
  public staticTask: any = null;
  public fileList: Array<any> = [];
  public errorMes: string = '';
  userId: string;
  isAvailable: boolean = true;

  @ViewChild('fileInput') fileInput: ElementRef;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private goalsService: GoalsService, private pupilPanelService: PupilPanelService) {
    this.userId = localStorage.getItem('userId');
  }

  ngOnInit() {
    this.getStaticTasks();
  }

  private getStaticTasks() {
    this.goalsService
      .getStaticTaskModal()
      .pipe(
        switchMap(response => {
          if (!response) {
            this.show = false;
            return of(null);
          }
          this.staticTask = response.staticTask;
          this.isAvailable = response.available;
          this.show = true;

          // добавить поле files к staticTask
          if (this.staticTask) {
            this.staticTask = Object.assign(this.staticTask, { files: [] });
          }

          return this.downloadFileList();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  public close() {
    this.goalsService.closeStaticTaskModal();
    this.errorMes = '';
  }

  public selectFileButton() {
    const event = new MouseEvent('click', { bubbles: false });
    this.fileInput.nativeElement.dispatchEvent(event);
    this.errorMes = '';
  }

  public selectFile(event) {
    if (event.target && event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        this.errorMes = 'Размер не более: ' + MAX_FILE_SIZE_MB + ' MB';
        this.fileInput.nativeElement.value = '';
        return;
      }

      if (this.staticTask.files) {
        this.staticTask.files.push({
          id: '',
          path: '',
          name: event.target.files[0].name,
          progress: 1,
        });
      }

      this.uploadAnimation();

      this.goalsService
        .addFileToTrajectory(event.target.files[0], this.staticTask.id)
        .pipe(take(1))
        .subscribe(() => {
          this.updateFileList();
          this.fileInput.nativeElement.value = '';
        });
    }
  }

  public deleteFile(file: any) {
    const id: string = file.id;
    file.id = '';
    file.progress = null;
    this.goalsService
      .deleteFileFromTrajectory(id)
      .pipe(take(1))
      .subscribe(() => this.updateFileList());
  }

  public downloadFile(file: any) {
    window.open(file.path);
  }

  private uploadAnimation() {
    const iterator: number = 5; // percent
    const interval: number = 100; // sec
    let done: boolean = true;

    setTimeout(() => {
      this.staticTask.files.forEach(f => {
        if (f.progress < 100 - iterator) {
          f.progress = f.progress + iterator;
          done = false;
        } else {
          f.progress = 100;
        }
      });

      if (done === false) {
        this.uploadAnimation();
      }
    }, interval);
  }

  downloadFileList(): Observable<any> {
    const cutCount: number = 63;

    if (!this.isAvailable) {
      return of(null);
    }

    return this.goalsService.getFilesToTrajectory(this.staticTask.id).pipe(
      take(1),
      tap(response => {
        this.staticTask.files = response;
        this.staticTask.files = Object.keys(this.staticTask.files).map(id => {
          return {
            id: id,
            path: this.staticTask.files[id],
            name: this.staticTask.files[id].substring(cutCount),
          };
        });
      }),
    );
  }

  private updateFileList() {
    this.downloadFileList()
      .pipe(
        switchMap(_ => {
          if (this.staticTask.files && this.staticTask.files.length > 0) {
            return this.setTrajectoryClassStatus(true);
          } else if (this.staticTask.files && this.staticTask.files.length == 0) {
            return this.setTrajectoryClassStatus(false);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  setTrajectoryClassStatus(isDone: boolean): Observable<any> {
    let trajectoryType: any;
    if (this.staticTask.classesFormat) {
      trajectoryType = this.staticTask.classesFormat;
    } else {
      trajectoryType = ETrajectoryTypes.CATALOG_TASK.toString();
    }

    return this.pupilPanelService.getGoalProfession().pipe(
      switchMap(profession => {
        return this.goalsService
          .updateUserTrajectory(this.staticTask.id, this.userId, this.staticTask.productId, trajectoryType, profession.id, isDone)
          .pipe(
            tap(_ => {
              this.goalsService.toUpdateTrajectories(true);
            }),
          );
      }),
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
