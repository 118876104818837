import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from 'app/pages/catalog/user-profile-page/user-profile.service';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ParentPanelService } from '../parent-panel.service';
import { ClipboardService } from 'ngx-clipboard';
import { IResultsAccess } from 'app/shared/interfaces/iresults.access';
import { SharedService } from 'app/shared/shared.service';
import { ITestInfo } from 'app/shared/interfaces/itestinfo.interface';

@Component({
  selector: 'prf-parent-child-container',
  templateUrl: './parent-child-container.component.html',
  styleUrls: ['./parent-child-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ParentChildContainerComponent implements OnInit {
  @Input() favoriteProfessions: Array<any> = [];
  @Input() set sessionIDParent(val: string) {
    this._sessionIDParent = val;
  }
  @Output() pageTitle = new EventEmitter();
  @Input()
  set child(val: any) {
    if (val) {
      this._child = val;
      // localStorage.setItem('RefferalUserId', val.userId);
      // localStorage.setItem('RefferalUserGender', val.gender);
      this.childStatus = this._child.id === '00000000-0000-0000-0000-000000000000' ? 'notRegistered' : 'registered';
      this.resultsLoaded = false;

      // костыль с childChanged используется для обновления содержимого при переходе между детьми
      // this.childChanged = true;

      // setTimeout(() => {
      //   this.childChanged = false;
      // }, 10);
      // this.getChildInfoOfB2cParent();
      // this.getMotivationSession(this.child.userId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
      // this.getFavorites(this.child).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
    }
  }

  get child(): any {
    return this._child;
  }

  get sessionIDParent(): string {
    return this._sessionIDParent;
  }

  childNotRegistered: boolean = true;

  userTag: string;
  public selectedProfession: any = {};
  public pupilSessionComplete: boolean = false;
  public parentSessionComplete: boolean = false;
  public resultsLoaded: boolean = false;
  public childChanged: boolean = false;
  public favoritesClasses: any[];

  public showToolTip: boolean = false;

  public childStatus: string = 'notRegistered';
  childInviteTexts = {
    notRegistered: {
      title: 'Ребенок еще не зарегистрировался',
      text: 'Отправьте ребенку ссылку на регистрацию любым удобным способом',
    },
    registered: {
      title: 'Ребенок еще не прошел диагностику',
      text: 'Мы сравним ваши ответы с ответами ребенка: итоговые результаты будут доступны после того, как ребенок пройдет диагностику',
    },
    tested: {
      title: '',
      text: '',
    },
  };
  openSocials: boolean = false;
  loaded: boolean = false;

  showFullResults: boolean = false;

  private _sessionIDParent: string;
  private _child: any = null;

  public motivationLastSessionId: string;
  public childInfo: {
    userId: string;
    userRole: string;
    firstName: string;
    regionId: string;
    municipalityId: string;
  };

  public userRole: string;
  public subMenu: string = '';

  public isMobile: boolean = false;

  isProsvetIdUser: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private translateService: TranslateService,
    private parentPanelService: ParentPanelService,
    private userProfileService: UserProfileService,
    private favoritesService: FavoritesService,
    private clipboardService: ClipboardService,
    private appSettingsService: AppSettingsService,
    private sharedService: SharedService,
  ) {
    this.userRole = localStorage.getItem('userRole');
    this.isProsvetIdUser = localStorage.getItem('tag') === EUserTags[EUserTags.Prosv];
    this.userTag = localStorage.getItem('tag');
    this.isMobile = window.innerWidth <= 768;
  }

  ngOnInit() {
    this.checkResultsAccess();
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  private getMotivationSession(childId: string): Observable<any> {
    return this.parentPanelService.getTestingSessions(childId).pipe(
      tap(sessions => {
        let motivationSessions = sessions
          .filter(session => session.screeningTestId === TESTS_VARIANTS_IDS.motivationtest && session.completed)
          .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));

        this.motivationLastSessionId = motivationSessions && motivationSessions.length ? motivationSessions[0].sessionId : null;
      }),
    );
  }

  getFavorites(child): Observable<any> {
    return this.favoritesService.getUserFavorites(child.userId).pipe(
      tap(favorites => {
        if (favorites) {
          this.favoritesClasses = favorites.filter(el => el.productType === FavoritesTypes.Class);
        }
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  private getChildInfoOfB2cParent() {
    if (this.isB2cParent && this.child) {
      this.childInfo = {
        userId: this.child.userId,
        userRole: 'pupil',
        firstName: this.child.firstName,
        regionId: this.child.regionId,
        municipalityId: this.child.municipalityId,
      };
    }
  }

  copyRegLink() {
    let registrationLink = this.generateRegLink();
    this.clipboardService.copy(registrationLink);
    if (registrationLink) {
      setTimeout(() => {
        let popupHintEl = document.getElementsByClassName('info-popup')[0] as HTMLElement;
        popupHintEl?.classList.add('opened');
        setTimeout(() => {
          popupHintEl?.classList.remove('opened');
        }, 3000);
      }, 50);
    }
  }
  generateRegLink(): string {
    if (this.child?.registrationCode) {
      return this.userTag === EUserTags[EUserTags.Default]
        ? location.origin + '/code-registration?code=' + this.child.registrationCode
        : this.userTag === EUserTags[EUserTags.b2c] || this.userTag === EUserTags[EUserTags.Prosv]
        ? location.origin + '/code-registration?code=' + this.child.registrationCode
        : null;
    }
  }

  checkResultsAccess() {
    this.parentPanelService
      .getResultsAccess()
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap(r => {
          if (r.status === 'Success') {
            return this.sharedService.getRegionTestInfo(localStorage.getItem('regionId')).pipe(
              tap((testInfo: ITestInfo) => {
                let resultsAccessArray: IResultsAccess[] = r.screeningTestsAccesses;

                let resultsAccess = resultsAccessArray.find(el => el.screeningTestId === testInfo.screeningTestId);

                this.showFullResults = resultsAccess.isTestResult === true;
                let pageTitleString = this.showFullResults ? 'Результаты диагностики' : 'Предварительные результаты';
                this.pageTitle.emit(pageTitleString);

                this.loaded = true;
              }),
            );
          } else {
            return of(null);
          }
        }),
        catchError(err => {
          this.loaded = true;
          throw err;
        }),
      )
      .subscribe();
  }

  openSharing() {
    if (this.isMobile && navigator.share) {
      navigator
        .share({
          url: this.generateRegLink(),
        })
        .then();
    } else {
      this.openSocials = !this.openSocials;
    }
  }

  get isB2cParent() {
    let b2cRegionId = AppSettingsService.settings.b2c ? AppSettingsService.settings.b2c.regionId : null;
    let b2cMunicipalityId = AppSettingsService.settings.b2c ? AppSettingsService.settings.b2c.municipalityId : null;
    return (
      localStorage.getItem('userRole') === 'parent' &&
      localStorage.getItem('regionId') === b2cRegionId &&
      localStorage.getItem('municipalityId') === b2cMunicipalityId
    );
  }

  get childRegistered() {
    return this.child?.id && this.child?.id !== '00000000-0000-0000-0000-000000000000';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isMobile = event.target.innerWidth <= 768;
  }
}
