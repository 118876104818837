<section class="admin">
  <div class="content-section">
    <prf-page-header [title]="title"></prf-page-header>
    <div class="container">
      <ng-container>
        <router-outlet></router-outlet>
      </ng-container>

      <prf-page-footer></prf-page-footer>
    </div>
  </div>

  <prf-admin-menu [adminLevel]="adminLevel"></prf-admin-menu>
</section>
