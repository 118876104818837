<div class="add-goal-lb lb show" *ngIf="show">
  <div class="lb-bg"></div>
  <div class="lb-block goal">
    <div class="add-goal-row">
      <div class="add-goal-column">
        <h3 class="h3-add-goal">{{ 'SHARED.YOU_ADD_FIRST_PROFESSION_IN_TARGET' | translate }}</h3>
        <p class="add-goal-text">
          {{ 'SHARED.VIEW_PROFESSION_ON_MAIN' | translate }}
        </p>
        <div class="main-screen-buttons-row goal-lb">
          <a (click)="navigateTo()" class="button welcome-button add-goal-btn w-button">
            {{ 'SHARED.GO_TO_TRAJECTORY' | translate }}
          </a>
          <a *ngIf="enableClose" (click)="close()" class="button welcome-button white-button close-goal-lb w-button">
            {{ 'SHARED.CLOSE' | translate }}
          </a>
        </div>
      </div>
      <img *ngIf="isMale(userGender)" src="/assets/images/dashboard/new-profession-m.svg" alt="" class="add-goal-image" />
      <img *ngIf="!isMale(userGender)" src="/assets/images/dashboard/new-profession-w.svg" alt="" class="add-goal-image" />
    </div>

    <a *ngIf="enableClose" (click)="close()" class="close-lb w-inline-block">
      <img src="/assets/images/icons/close-lb.svg" alt="" />
    </a>
  </div>
</div>
