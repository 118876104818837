<div *ngIf="menuAllowed" class="navbar">
  <div class="logo-div">
    <a (click)="selectMain()" class="home-link w-inline-block" [class.mo-logo]="isMOStend" [class.no-link]="isVGuser"></a>
  </div>
  <!-- mosru пользователи -->
  <div *ngIf="menuAllowed" class="menu" style="overflow-y: auto">
    <a
      *ngIf="showMosruAbout && !isVGuser"
      (click)="selectPromoAction()"
      [class.w--current]="currentUrl === '/mosruabout'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/menu/redHat_grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/menu/redHat.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div>Узнайте <br />свои таланты</div>

      <div class="menu-separator"></div>
    </a>
    <!-- VG пользователи -->
    <a
      *ngIf="isVGuser"
      routerLink="/vorobievi-gori/mosrupromo"
      [class.w--current]="currentUrl === '/mosruabout'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/Test-Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/Test-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div>
        Тест<br />
        на таланты
      </div>
      <div class="menu-separator"></div>
    </a>
    <!--ученик, основная страница -->
    <!-- <a
      *ngIf="userRole === 'pupil' && false"
      (click)="selectMain()"
      [class.w--current]="currentUrl === '/' + root"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/Main-Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/Main-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Мои цели</div>
      <div class="menu-separator"></div>
    </a> -->
    <!-- родитель, основная страница  -->
    <!-- <a
      *ngIf="defaultB2cParents"
      (click)="selectMain()"
      [class.w--current]="currentUrl === '/' + root"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/Main-Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/Main-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Мои дети</div>
      <div class="menu-separator"></div>
    </a> -->

    <!-- ученик, defaultB2cParents результаты -->
    <a
      *ngIf="userRole === 'pupil' || defaultB2cParents"
      class="menu-link-block w-inline-block"
      [class.w--current]="
        router.url.includes('/parent') ||
        router.url.includes('/results') ||
        router.url.includes('/motivation-test-results') ||
        router.url.includes('/values-test-results')
      "
      [matMenuTriggerFor]="resultsMenu"
      (menuOpened)="setMenuProperties()"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/Test-Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/Test-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Результаты тестов</div>
      <div class="menu-separator"></div>
    </a>

    <mat-menu #resultsMenu="matMenu" xPosition="after">
      <ng-container *ngFor="let testItem of userTestsArray" class="menu-item-wrapper">
        <button mat-menu-item *ngIf="testItem.testAccess" (click)="selectResults(testItem.screeningTestId)">
          {{ testItem.testName }}
        </button>
      </ng-container>
      <!-- <button mat-menu-item (click)="selectResults('motivation-results')">Учебная мотивация</button>
      <button mat-menu-item (click)="selectResults('values-results')">Ценности</button> -->
    </mat-menu>

    <!-- ученик, defaultB2cParents рекомендации -->
    <a
      *ngIf="userRole === 'pupil' || defaultB2cParents"
      (click)="selectRecommendations()"
      [class.w--current]="currentUrl === '/recommendations'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/Test-Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/Test-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Рекомендации</div>
      <div class="menu-separator"></div>
    </a>

    <a
      *ngIf="userRole === 'pupil'"
      (click)="selectProfessions()"
      [class.w--current]="currentUrl === '/professions'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="./assets/images/dashboard/Professions-Grey.svg" alt="" class="menu-img" />
        <img src="./assets/images/dashboard/Professions-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">{{ 'HEADER.PROF' | translate }}</div>
      <div class="menu-separator"></div>
    </a>

    <!-- b2c ученики -->
    <!-- <a
      *ngIf="isB2cUser && userRole === 'pupil'"
      (click)="routingMotivationTest()"
      [class.w--current]="
        currentUrl.includes('/motivation-test') || currentUrl.includes('/motivation-test-results')
      "
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/menu/grey/Motiv_Test.svg" alt="Motivation test" class="menu-img" />
        <img
          src="/assets/images/menu/violet/Motiv_Test.svg"
          alt="Motivation test"
          class="menu-img active"
        />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Мотивация</div>
      <div class="menu-separator"></div>
    </a> -->
    <!-- b2c ученик, пока скрыто -->
    <!-- <a
      *ngIf="isB2cUser && userRole === 'pupil' && isTestReady"
      (click)="routingValuesTest()"
      [class.w--current]="
        currentUrl.includes('/values-test') || currentUrl.includes('/values-test-results')
      "
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/menu/grey/Motiv_Test.svg" alt="Values test" class="menu-img" />
        <img
          src="/assets/images/menu/violet/Motiv_Test.svg"
          alt="Values test"
          class="menu-img active"
        />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Тест на ценности</div>
      <div class="menu-separator"></div>
    </a> -->

    <!--стажировки, пока скрыто -->
    <a
      *ngIf="showInternships && isInternshipsReady"
      (click)="selectInternships()"
      [class.w--current]="currentUrl === '/internships'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/Course_Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/Course_VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Стажировки</div>
      <div class="menu-separator"></div>
    </a>
    <!-- ученик, портфолио, пока скрыто -->
    <!-- <a
      *ngIf="userRole === 'pupil' && false"
      (click)="selectPortfolio()"
      [class.w--current]="currentUrl === '/portfolio'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/Portfolio-Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/Portfolio-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Портфолио</div>
      <div class="menu-separator"></div>
    </a> -->
    <!-- скрыто для всех пользователей -->
    <!-- <a
      *ngIf="false"
      (click)="selectNews()"
      [class.w--current]="currentUrl === '/news'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/News-Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/News-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">Новости</div>
      <div class="menu-separator"></div>
    </a> -->
    <!-- ученик, история тестов, пока скрыто -->
    <!-- <a
      *ngIf="userRole === 'pupil' && false"
      (click)="selectHistory()"
      [class.w--current]="currentUrl === '/pupil-tests-history'"
      class="menu-link-block w-inline-block"
    >
      <div class="menu-separator"></div>
      <div class="menu-icon-div">
        <img src="/assets/images/dashboard/News-Grey.svg" alt="" class="menu-img" />
        <img src="/assets/images/dashboard/News-VIolet.svg" alt="" class="menu-img active" />
      </div>
      <div class="menu-separator _2"></div>
      <div class="menu-link-text">История тестов</div>
      <div class="menu-separator"></div>
    </a> -->
  </div>
  <div class="mobile-menu-button" (click)="showMobileMenu()">
    <div class="mobile-menu-icon-div">
      <div class="menu-line"></div>
      <div class="menu-line"></div>
      <div class="menu-line"></div>
    </div>
  </div>

  <div style="display: none" class="mobile-menu-button fixed">
    <div class="mobile-menu-icon-div">
      <div class="menu-line"></div>
      <div class="menu-line"></div>
      <div class="menu-line"></div>
    </div>
  </div>
</div>

<ng-container *ngIf="!menuAllowed && !isMobile">
  <ng-container *ngTemplateOutlet="logoProfilum"></ng-container>
</ng-container>

<!-- мобильное меню -->
<div class="mobile-menu" [style.display]="isShowMobileMenu ? 'block' : 'none'">
  <div
    [ngStyle]="{
      transform: 'translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);'
    }"
    class="mobile-menu-container"
    style="transform-style: preserve-3d"
  >
    <div class="mobile-menu-header">
      <div class="mobile-menu-button fixed" (click)="hideMobileMenu()">
        <div class="mobile-menu-icon-div">
          <div
            class="menu-line"
            style="
              transform: translate3d(0px, 4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
              transform-style: preserve-3d;
            "
          ></div>
          <div
            class="menu-line"
            style="
              transform: translate3d(0px, -4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
              transform-style: preserve-3d;
            "
          ></div>
        </div>
      </div>
      <div class="mobile-menu-logo-div">
        <a
          (click)="selectMain()"
          class="mobile-menu-logo-link w-inline-block w--current"
          [class.mo-logo]="isMOStend"
          [class.no-link]="isVGuser"
        >
          <img
            src="{{ isMOStend ? '/assets/images/dashboard/profilum-logo.svg' : '/assets/images/dashboard/profilum-new-logo.svg' }}"
            alt="Profilum logo"
          />
        </a>
      </div>
    </div>
    <div class="mobile-user-div">
      <a (click)="selectProfile()" class="user-dropdown mob w-inline-block">
        <div class="user-dropdown-toggle">
          <div class="user-photo mob" [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"></div>
          <div class="user-name mob">{{ firstName }}</div>
        </div>
      </a>
    </div>
    <div *ngIf="menuAllowed">
      <ng-container *ngIf="showHeaderButtons">
        <div class="header_buttons">
          <a
            class="header_button _button"
            [class.active]="url.includes(item.url)"
            *ngFor="let item of headerButtonsArray"
            (click)="hideMobileMenu()"
            routerLink="{{ item.url }}"
            >{{ item.name }}</a
          >
        </div>
      </ng-container>
      <!-- mosru пользователи -->
      <a
        *ngIf="showMosruAbout && !isVGuser"
        (click)="selectPromoAction()"
        [class.w--current]="currentUrl === '/mosruabout'"
        class="menu-link mosruabout w-inline-block"
      >
        <div>Узнайте <br />свои таланты</div>
      </a>
      <!-- VG пользователи -->
      <a
        *ngIf="isVGuser"
        routerLink="/vorobievi-gori/mosrupromo"
        [class.w--current]="currentUrl === '/mosruabout'"
        class="menu-link mosruabout w-inline-block"
      >
        <div>
          Тест<br />
          на таланты
        </div>
      </a>

      <!-- родитель, основная страница  -->
      <!-- <a
        *ngIf="defaultB2cParents"
        (click)="selectMain()"
        [class.w--current]="currentUrl === '/' + root"
        class="menu-link-block w-inline-block"
      >
        <div class="menu-link-text">Мои дети</div>
      </a> -->

      <!-- ученик,defaultB2cParents результаты -->
      <a
        *ngIf="userRole === 'pupil' || defaultB2cParents"
        class="menu-link-block test w-inline-block"
        [class.w--current]="
          router.url.includes('/parent') ||
          router.url.includes('/results') ||
          router.url.includes('/motivation-test-results') ||
          router.url.includes('/values-test-results')
        "
        [matMenuTriggerFor]="resultsMenu"
        (menuOpened)="setMenuProperties()"
      >
        <div class="menu-separator"></div>
        <div class="menu-separator _2"></div>
        <div class="menu-link-text">Результаты тестов</div>
        <div class="menu-separator"></div>
      </a>

      <mat-menu #resultsMenu="matMenu" xPosition="after">
        <ng-container *ngFor="let testItem of userTestsArray" class="menu-item-wrapper">
          <button mat-menu-item *ngIf="testItem.testAccess" (click)="selectResults(testItem.screeningTestId)">
            {{ testItem.testName }}
          </button>
        </ng-container>
      </mat-menu>

      <!-- ученик, defaultB2cParents рекомендации -->
      <a
        *ngIf="userRole === 'pupil' || defaultB2cParents"
        (click)="selectRecommendations()"
        [class.w--current]="currentUrl === '/recommendations'"
        class="menu-link w-inline-block"
      >
        <div class="menu-link-text">Рекомендации</div>
      </a>

      <a
        *ngIf="userRole === 'pupil'"
        (click)="selectProfessions()"
        [class.w--current]="currentUrl === '/professions'"
        class="menu-link professions w-inline-block"
      >
        <div>{{ 'HEADER.PROF' | translate }}</div>
      </a>

      <!-- b2c ученики -->
      <!-- <a
        *ngIf="isB2cUser && userRole === 'pupil'"
        (click)="routingMotivationTest()"
        [class.w--current]="
          currentUrl.includes('/motivation-test') || currentUrl.includes('/motivation-test-results')
        "
        class="menu-link test w-inline-block"
      >
        <div>Мотивация</div>
      </a> -->
      <!-- b2c ученик -->
      <!-- <a
        *ngIf="isB2cUser && userRole === 'pupil' && isTestReady"
        (click)="routingValuesTest()"
        [class.w--current]="
          currentUrl.includes('/values-test') || currentUrl.includes('/values-test-results')
        "
        class="menu-link test w-inline-block"
      >
        <div>Тест на ценности</div>
      </a> -->

      <!-- <a
        *ngIf="showInternships && isInternshipsReady"
        (click)="selectInternships()"
        [class.w--current]="currentUrl === '/internships'"
        class="menu-link internships w-inline-block"
      >
        <div>Стажировки</div>
      </a> -->
      <!-- <a
        *ngIf="userRole === 'pupil'"
        (click)="selectPortfolio()"
        [class.w--current]="currentUrl === '/portfolio'"
        class="menu-link portfolio w-inline-block"
      >
        <div>Портфолио</div>
      </a> -->
      <!-- <a
        *ngIf="false"
        (click)="selectNews()"
        [class.w--current]="currentUrl === '/news'"
        class="menu-link news w-inline-block"
      >
        <div>Новости</div>
      </a> -->
      <!-- <a
        *ngIf="userRole === 'pupil'"
        (click)="selectHistory()"
        [class.w--current]="currentUrl === '/pupil-tests-history'"
        class="menu-link news w-inline-block"
      >
        <div>История тестов</div>
      </a> -->
    </div>
  </div>
  <div class="mobile-menu-bg" (click)="hideMobileMenu()"></div>
</div>

<ng-template #logoProfilum>
  <div *ngIf="!isTabletScreen" class="logo-link w-inline-block">
    <a class="home-link w-inline-block" (click)="userRole === 'parent' ? backToParentsPage() : null">
      <img class="new-logo" src="/assets/images/dashboard/profilum-new-logo.svg" alt="" />
    </a>
  </div>
</ng-template>
