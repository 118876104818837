import { Component, Input, OnInit } from '@angular/core';
import { Gender } from '../../../../shared/enums/gender.enums';
import { ETestTypes } from '../../../../shared/enums/testtypes.enum';

@Component({
  selector: 'prf-welcome',
  templateUrl: './welcome.component.html',
})
export class WelcomeComponent implements OnInit {
  @Input() testResults: any;

  public isTestNew: boolean = true;
  public showResults: boolean = false;
  public testSessionId: any;
  public sessionStatus: string = '';
  public userGender: string = '';

  constructor() {
    this.userGender = localStorage.getItem('userGender');
    this.sessionStatus = localStorage.getItem('sessionStatus');
    const testSessionId = localStorage.getItem('testSessionId');
    this.testSessionId = testSessionId && testSessionId !== '00000000-0000-0000-0000-000000000000' ? testSessionId : '';
  }

  ngOnInit() {
    if (this.testResults && this.testResults.length > 0 && this.sessionStatus === 'Success') {
      this.showResults = true;
    } else {
      if (this.sessionStatus === 'Uncompleted test') {
        this.isTestNew = false;
      }
    }
  }

  public setTypeTest() {
    localStorage.setItem('testType', ETestTypes.DEFAULT_STANDART.toString());
  }

  public isMale(gender: string) {
    return gender === Gender.Male;
  }
}
