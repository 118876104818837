import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ISchoolPortalUserData } from 'app/shared/interfaces/ischoolportal.userdata.interface';
import { FilterByNamePipe } from 'app/shared/pipes/filter-by-name.pipe';
import { FilterSchoolsPipe } from 'app/shared/pipes/filter-schools.pipe';
import * as moment from 'moment';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppSettingsService } from '../../../../shared/services/appsettings.service';
import { AdminPanelService } from '../../../../pages/control-panel/admin/admin-panel.service';
import { ChildrenAddService } from '../../../../pages/control-panel/parent/children-add/children-add.service';
import { RegionsService } from '../../../../pages/control-panel/admin/regions/regions.service';
import { SharedService } from 'app/shared/shared.service';
import { RegistrationProsvetService } from '../../registration-prosvet/registration-prosvet.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IProsvetUserData } from 'app/shared/interfaces/iprosvet.userdata.interface';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { ICity } from 'app/shared/interfaces/icity';
import { SchoolClassesClass } from 'app/shared/classes/school-classes.class';
import { Observable, Subject } from 'rxjs';
import { SCHOOL_NUMBERS } from 'app/shared/global-constants/school-numbers';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';

@Component({
  selector: 'prf-update-pupil-prosvet',
  templateUrl: './update-pupil-prosvet.component.html',
  styleUrls: ['./update-pupil-prosvet.component.scss'],
})
export class UpdatePupilProsvetComponent implements OnInit {
  @Input() prosvetUserData: IProsvetUserData;
  @Input() tag: string;

  public form: UntypedFormGroup;
  public submitted: boolean;
  public readonly dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public readonly emailRegExp: RegExp = REG_EXP.emailRegExp;
  public readonly dateRegExp: RegExp = REG_EXP.dateRegExp;
  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  public readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  public readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  public readonly phoneRegExp: RegExp = REG_EXP.phoneRegExp;

  public isCharactersError: boolean = true;
  public isLetterError: boolean = true;
  public isNumberError: boolean = true;
  public isRusLettersError: boolean = false;
  public isWhiteSpaceError: boolean = false;
  public isNotValidPassword: boolean = false;
  public focusOutPasswordErrors: boolean = false;

  public addition: boolean = false;
  public regions = [];
  public cities: ICity[] = [];
  private _citiesView;
  public classes: SchoolClassesClass[] = [];
  public classesFetched: boolean = false;
  public schools: any[] = [];
  private _schoolView: any[] = [];
  public numbersClass: any = [];
  public lettersClass: any = [];

  public registrationFailed: boolean = false;
  public passFailed: boolean = false;
  public loginFailed: boolean = false;

  public moment: any = moment;
  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;
  public isMaskedPassword: boolean = true;
  public personalTerms: boolean = true;
  public duplicateUserName: boolean = false;
  public codeIsUsed: boolean = false;
  public checkEmail: boolean = true;
  public checkBirthday: boolean = false;
  public isClasses: boolean = false;

  public isNotEnoughYears: boolean = false; // признак для определения разрешенного возраста
  private minAge: number = 14; // минимальный разрешенный возраст для регистрации ученика
  public SCHOOL_LETTERS: { value: string }[] = [];
  public defaultRegion: string = null;

  public role = 'pupil';

  private ngUnsubscribe$: Subject<any> = new Subject<any>();
  constructor(
    private registrationService: RegistrationProsvetService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private childrenAddService: ChildrenAddService,
    private filterSchoolsPipe: FilterSchoolsPipe,
    private filterByNamePipe: FilterByNamePipe,
    private translateService: TranslateService,
    private adminPanelService: AdminPanelService,
    private appSettingsService: AppSettingsService,
    private sharedService: SharedService,
    private regionsService: RegionsService,
    private utilsService: UtilsService,
  ) {
    this.moment = moment;
    this.moment.locale('ru');
  }

  async ngOnInit() {
    this.defaultRegion = AppSettingsService.settings.regionId;
    this.SCHOOL_LETTERS = this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS');
    this.initFormGroup();

    this.getData()
      .pipe(
        tap(_ => {
          this.setUserDataValues();
        }),
      )
      .subscribe();
  }

  getData(): Observable<any> {
    return this.getRegions().pipe(switchMap(_ => this.getCities().pipe(switchMap(_ => this.getSchools()))));
  }

  public updateSchoolClass() {
    this.submitted = true;
    if (this.form.valid) {
      let school = this.schools.find(school => school.id === this.prosvetUserData.schoolId);
      let city = this.cities.find(city => city.name === this.prosvetUserData.city);

      let classLetter, classNumber;

      if (this.f.schoolClass.value == null) {
        classNumber = this.f.schoolClassNumber.value.name;
        classLetter = this.f.schoolClassLetter.value.name;
      } else {
        let classLetterIndex = this.f.schoolClass.value && this.f.schoolClass.value.name.match(/[a-zA-Zа-яА-Я]/).index;
        classLetter = classLetterIndex && this.f.schoolClass.value.name[classLetterIndex];
        classNumber = this.f.schoolClass.value && parseInt(this.f.schoolClass.value.name);
      }

      this.registrationService
        .softSchoolClassChange(this.prosvetUserData.userId, school.id, classNumber, classLetter)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(response => {
          if (response.status == 'Success') {
            this.utilsService.openSnackBar('👌 Изменения успешно сохранены', 'success');
            return this.router.navigate(['/pupil']);
          } else {
            this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
            this.failWaiting();
          }
        });
    } else {
      this.failWaiting();
    }
  }

  animateLogin() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.updateSchoolClass();
  }

  // данные пользователя с "Просвещения"
  private setUserDataValues(): void {
    const isValidDate = this.prosvetUserData.birthday && this.prosvetUserData.birthday !== '0001-01-01T00:00:00'; // в таком формте приходит отсутствие даты
    const birthday = isValidDate ? moment(this.prosvetUserData.birthday).format('DD/MM/YYYY') : null;

    let school = this.schools.find(school => school.id === this.prosvetUserData.schoolId);
    let city = this.cities.find(city => city.name === this.prosvetUserData.city);

    // запрос классов по школе
    if (school && school.id) {
      this.getSchoolClassBySchool(school.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassLetter.reset();
        });
    }

    this.form = this.fb.group({
      region: new UntypedFormControl(this.regionsView.find(r => r.data.id === this.defaultRegion)),
      lastName: new UntypedFormControl({ value: this.prosvetUserData.lastName, disabled: true }, [Validators.required]),
      firstName: new UntypedFormControl({ value: this.prosvetUserData.firstName, disabled: true }, [Validators.required]),
      middleName: new UntypedFormControl({ value: this.prosvetUserData.middleName, disabled: true }),
      date: new UntypedFormControl({ value: isValidDate ? birthday : null, disabled: isValidDate }, [
        Validators.required,
        Validators.pattern(this.dateRegExp),
      ]),
      email: new UntypedFormControl({ value: this.prosvetUserData.email, disabled: this.prosvetUserData.email ? true : false }, [
        Validators.required,
        Validators.pattern(this.emailRegExp),
      ]),
      role: new UntypedFormControl('pupil', [Validators.required]),
      city: new UntypedFormControl({
        value: city ? { name: city.name, data: city } : null,
        disabled: city ? true : false,
      }),
      school: new UntypedFormControl(
        {
          value: school ? { name: school.viewValue, data: school } : null,
          disabled: school ? true : false,
        },
        [Validators.required],
      ),
      schoolClass: new UntypedFormControl(null),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      gender: new UntypedFormControl(
        {
          value: this.prosvetUserData.gender && ['M', 'F'].indexOf(this.prosvetUserData.gender) > -1 ? this.prosvetUserData.gender : null,
          disabled: this.prosvetUserData.gender && ['M', 'F'].indexOf(this.prosvetUserData.gender) > -1 ? true : false,
        },
        [Validators.required],
      ),
    });

    // нужно, чтобы подтянулись школы по городу
    this.f.city.value && !this.f.school.value ? this.onCityChange() : null;

    // вызываем проверку возраста
    this.checkFormatDate(this.f.date.value);
  }

  get isAccessAllowed() {
    return this.form.valid && this.isClassSelected;
  }

  getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathRegion;
  }

  private initFormGroup() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      middleName: new UntypedFormControl(null),
      date: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.dateRegExp)]),
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegExp)]),
      password: new UntypedFormControl(null, [
        Validators.required,
        // Validators.pattern(this.passwordRegExp),
      ]),
      role: new UntypedFormControl(this.role, [Validators.required]),
      region: new UntypedFormControl(null, [Validators.required]),
      city: new UntypedFormControl(null, [Validators.required]),
      school: new UntypedFormControl(null, [Validators.required]),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      gender: new UntypedFormControl('M', [Validators.required]),
      personalTerms: new UntypedFormControl(false, [Validators.requiredTrue]),
      schoolClass: new UntypedFormControl(null, []),
    });
  }

  get f() {
    return this.form.controls;
  }

  getRegions(): Observable<any> {
    return this.regionsService.getAllRegions().pipe(
      tap(regions => {
        this.regions = regions.filter(region => region.hrid != 'defaultRegion');
      }),
    );
  }

  getCities(): Observable<any> {
    return this.adminPanelService.getAllCities().pipe(tap(cities => (this.cities = cities)));
  }

  getSchools(): Observable<any> {
    return this.childrenAddService.getCatalogSchools().pipe(
      tap((schools: any[]) => {
        this.schools = [...schools].map(school => ({
          city: school.city,
          value: school.number,
          viewValue: school.number,
          id: school.id,
        }));
      }),
    );
  }

  getSchoolClassBySchool(id): Observable<any> {
    return this.registrationService.getSchoolClassesBySchool(id).pipe(
      tap((classes: SchoolClassesClass[]) => {
        this.classes = classes;
        this.classes && this.classes.length ? (this.isClasses = true) : null;
        this.classesFetched = true;
      }),
    );
  }

  onRegionChange() {
    this.f.city.reset();
    if (this.f.region.value && this.f.region.value.data.id) {
      this.citiesView = this.f.region.value.data.id;
    }
  }
  onCityChange() {
    this.f.school.reset();
    if (this.f.city.value && this.f.city.value.data.name) {
      this.schoolView = this.f.city.value.data.name;
    }
  }
  onSchoolChange() {
    if (this.f.school.value && this.f.school.value.data.id) {
      this.getSchoolClassBySchool(this.f.school.value.data.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassNumber.reset();
        });
    }
  }

  get regionsView() {
    return [...this.regions].map(region => ({ name: region.name, data: region }));
  }

  set citiesView(regionId) {
    this._citiesView = regionId
      ? [...this.cities].filter(city => city.regionId === regionId).map(city => ({ name: city.name, data: city }))
      : [];
  }
  get citiesView() {
    return this._citiesView;
  }

  set schoolView(cityName) {
    this._schoolView = cityName
      ? [...this.schools].filter(school => school.city === cityName).map(school => ({ name: school.viewValue, data: school }))
      : [];
  }
  get schoolView() {
    return this._schoolView;
  }

  get schoolClassView() {
    return [...this.classes].map(schoolClass => ({
      name: schoolClass.number + schoolClass.letter,
      data: schoolClass,
    }));
  }
  get schoolClassNumber() {
    return [...SCHOOL_NUMBERS].map(number => ({ name: number.value, data: number }));
  }
  get schoolClassLetter() {
    return [...this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS')].map(letter => ({
      name: letter.value,
      data: letter,
    }));
  }
  get isClassSelected(): boolean {
    return (this.f.schoolClassLetter.value && this.f.schoolClassNumber.value) || this.f.schoolClass.value;
  }
  get currentClassLetter() {
    return this.f.schoolClassLetter.value ? this.f.schoolClassLetter.value.name : this.f.schoolClass.value.data.letter;
  }
  get currentClassNumber() {
    return this.f.schoolClassNumber.value ? this.f.schoolClassNumber.value.name : this.f.schoolClass.value.data.number;
  }

  private setDefaultErrorFlagValues(): void {
    this.isWhiteSpaceError = false;
    this.isCharactersError = true;
    this.isLetterError = true;
    this.isNumberError = true;
    this.isRusLettersError = false;
  }
  private updateErrorFlagValues(password: string): void {
    this.isWhiteSpaceError = !this.testWhiteSpace.test(password);
    this.isCharactersError = !this.testSixCharter.test(password);
    this.isLetterError = !this.testOneLetter.test(password);
    this.isNumberError = !this.testOneDigit.test(password);
    this.isRusLettersError = this.testRusLetters.test(password);
  }
  private isAnyPasswordError(): boolean {
    return this.isCharactersError || this.isLetterError || this.isNumberError || this.isRusLettersError || this.isWhiteSpaceError;
  }

  public toggleMask() {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  setUserInfoInLS(ui: UserInfoClass) {
    const defaultTerritoryId: string = '00000000-0000-0000-0000-000000000000';

    const setAdminLevelLS = (ui: UserInfoClass) => {
      var userRegionId = ui.regionId;
      var userMunicipalityId = ui.municipalityId;
      if (userRegionId != null && userMunicipalityId != null) {
        if (userRegionId == defaultTerritoryId && userMunicipalityId == defaultTerritoryId) {
          localStorage.setItem('adminLevel', AdminAccessLevel.GLOBAL);
        }
        if (userRegionId != defaultTerritoryId && userMunicipalityId == defaultTerritoryId) {
          localStorage.setItem('adminLevel', AdminAccessLevel.REGION);
        }
        if (userRegionId != defaultTerritoryId && userMunicipalityId != defaultTerritoryId) {
          localStorage.setItem('adminLevel', AdminAccessLevel.MUNICIPALITY);
        }
      }
    };

    if (!ui) {
      throw 'User info is not defined';
    }

    localStorage.setItem('imagePath', ui.imagePath);
    localStorage.setItem('firstName', ui.firstName);
    localStorage.setItem('lastName', ui.lastName);
    localStorage.setItem('schoolId', ui.schoolId);
    localStorage.setItem('userGender', ui.gender);
    localStorage.setItem('companyId', ui.companyId);
    localStorage.setItem('position', ui.position);
    localStorage.setItem('regionId', ui.regionId);
    localStorage.setItem('municipalityId', ui.municipalityId);
    ui.parents[0]?.userId ? localStorage.setItem('parentUserId', ui.parents[0]?.userId) : null;

    if (localStorage.getItem('userRole') == 'admin') {
      setAdminLevelLS(ui);
    }
  }

  public checkBirthdayDate(isCorrectDate: boolean) {
    let date = moment();
    let userDate = moment(this.f.date.value, 'DD/MM/YYYY', true); // порядок определен форматом даты на фронте
    let diff = moment.duration(date.diff(userDate)).asYears();
    this.isNotEnoughYears = diff < this.minAge;
  }
  public removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }
  public failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }
  public isValidPassword(password: string): boolean {
    if (password && password.length > 0) {
      this.updateErrorFlagValues(password);
      if (this.isAnyPasswordError()) {
        this.isNotValidPassword = true; // isWhiteSpaceError был без этой записи
        return false;
      } else {
        this.isNotValidPassword = false;
        return true;
      }
    } else {
      this.setDefaultErrorFlagValues();
      return false;
    }
  }
  public focusOutErrorChecking() {
    this.focusOutPasswordErrors = this.isAnyPasswordError();
  }
  public additionEnable() {
    this.addition = !this.addition;
  }
  public checkFormatEmail(event): void {
    if (event) {
      this.checkEmail = this.emailRegExp.test(this.f.email.value);
    }
  }
  public checkFormatDate(event): void {
    if (event) {
      this.checkBirthdayDate(true);
      this.checkBirthday = true;
    }
  }
  public tooggleSelectClassType() {
    this.isClasses = !this.isClasses;
    this.f.schoolClass.reset();
    this.f.schoolClassNumber.reset();
    this.f.schoolClassLetter.reset();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
