export enum SlideType {
  CHECKBOXGROUP,
  SWIPECARDS,
  RANGE,
}

export enum SlideStatus {
  EMPTY,
  PENDING,
  SENT,
}

export enum PlayerStatus {
  EMPTY,
  INPROGRESS,
  COMPLETE,
}
