import { Component, OnDestroy, OnInit } from '@angular/core';
import { IHistory } from 'app/shared/interfaces/ihistory.interface';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PupilPanelService } from '../pupil-panel.service';

@Component({
  selector: 'prf-pupil-tests-history-page',
  templateUrl: './pupil-tests-history-page.component.html',
  styleUrls: ['./pupil-tests-history-page.component.scss'],
})
export class PupilTestsHistoryPageComponent implements OnInit, OnDestroy {
  public user: {
    userId: string;
    regionId: string;
    municipalityId: string;
  };
  testsResultsView: any[] = [];
  public sessionList: IHistory[];
  isCompletedTest: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private pupilPanelService: PupilPanelService) {
    this.user = {
      userId: localStorage.getItem('userId'),
      regionId: localStorage.getItem('regionId'),
      municipalityId: localStorage.getItem('municipalityId'),
    };
  }

  ngOnInit() {
    this.loadHistoryTests().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  private loadHistoryTests(): Observable<any> {
    return this.pupilPanelService.getTestingSessions(this.user.userId).pipe(
      tap(r => {
        this.sessionList = r;
      }),
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  getCompletedTest(results: any[]) {
    return results.find(el => {
      if (el.sessionListView.completed) {
        return (this.isCompletedTest = true);
      }
    });
  }
}
