import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorPanelService } from './tutor.service';
import { TutorStudentPageComponent } from './tutor-student-page/tutor-student-page.component';
import { TutorStudentTestResultsComponent } from './tutor-student-page/tutor-student-test-results/tutor-student-test-results.component';
import { SharedModule } from 'app/shared/shared.module';
import { DashboardModule } from 'app/shared/dashboard/dashboard.module';

@NgModule({
  declarations: [TutorStudentPageComponent, TutorStudentTestResultsComponent],
  providers: [TutorPanelService],
  imports: [SharedModule, DashboardModule],
  exports: [TutorStudentPageComponent, TutorStudentTestResultsComponent],
})
export class TutorSharedModule {}
