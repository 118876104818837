import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class PortfolioService {
  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  public getPortfolio(userId: string): Observable<any> {
    const url = '/api/v1.0/profiles/portfolio/getportfolio';
    const params = `?userId=${userId}`;
    return this.http.get(url + params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public addPortfolioFile(portfolioId: string, name: string, fileType: string, file: File): Observable<any> {
    const url = '/api/v1.0/profiles/portfolio/addportfoliofile';
    const params = `?portfolioId=${portfolioId}&name=${name}&fileType=${fileType}`;

    let formData: FormData = new FormData();
    formData.append(file.name, file);

    return this.http.postImage(url + params, formData).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public deletePortfolioFile(portfolioFileId: string): Observable<any> {
    const url = '/api/v1.0/profiles/portfolio/deleteportfoliofile';
    const params = `?portfolioFileId=${portfolioFileId}`;
    return this.http.delete(url + params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public addExistingPortfolioFile(portfolioFile): Observable<any> {
    return this.http.post('/api/v1.0/profiles/portfolio/addexistingportfoliofile', portfolioFile);
  }
}
