<div>
  <div class="content-row">
    <div class="content-tutor">
      <div class="class-div" *ngIf="pupils.length > 0">
        <div class="students-edit">
          <h1>Ученики</h1>
          <!-- <a *ngIf="userRole == userRoles.schooladmin" class="school-edit-link" (click)="showEditPupils()">
      {{ 'SHARED.EDIT_BUTTON' | translate }}
    </a> -->
        </div>
        <div class="class-header _2" [class.fixed]="isFixed">
          <div class="class-row class-header-row-3">
            <div class="class-column _9"></div>
            <div class="class-column _4">
              <div>{{ pupils.length }} учеников</div>
            </div>
            <div class="class-column _6">
              <div>Тест прошел</div>
              <div class="class-test-row">
                <div class="class-test-column">
                  <div>Родитель</div>
                </div>
                <div class="class-test-column">
                  <div>Ученик</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="schooladmin-list-block _2">
          <div class="students-list">
            <!--Pupil-->
            <div
              class="class-list-item-2"
              *ngFor="let pupil of pupils; let i = index"
              [ngClass]="pupils.length === i ? 'no-underline' : ''"
            >
              <div [class.last-selected]="pupil.userId === selectedPupilId">
                <div class="class-row-2">
                  <!--Number-->
                  <div class="class-column _9">
                    <div class="class-header-number _2">{{ i + 1 }}</div>
                  </div>

                  <!--Check registration-->
                  <!--if registrated-->
                  <div class="class-row-div" *ngIf="pupil.isActivated; else notRegistered">
                    <div class="class-column _7">
                      <div class="class-column _2 _10">
                        <!--Avatar-->
                        <div class="class-photo-column">
                          <a
                            (click)="showSelectedPupil(pupil)"
                            class="class-photo w-inline-block"
                            [ngStyle]="{ 'background-image': 'url(' + getImageUrl(pupil) + ')' }"
                          ></a>
                          <!-- <a class="class-photo w-inline-block"></a> -->
                        </div>
                      </div>
                      <!--details-->
                      <div class="class-column _4">
                        <div class="student-name">
                          <a (click)="showSelectedPupil(pupil)" class="student-name-link"> {{ pupil.firstName }} {{ pupil.lastName }} </a>
                        </div>
                        <!--<div class="student-online-status">Была только что???</div>-->
                      </div>
                    </div>
                    <!--Test-->
                    <div class="class-column _6">
                      <div class="class-test-row">
                        <!--last-test-date-->
                        <div class="class-test-column test mobile-hidden">
                          <a class="last-test-date">{{ pupil.lastTestDate | date : 'dd/MM/yyyy' }}</a>
                        </div>
                        <!--Parent-test-->
                        <div class="class-test-column test">
                          <div class="test-check" [class.complete]="pupil.lastParentSessionCompleted"></div>
                        </div>
                        <!--Pupil-Test-->
                        <div class="class-test-column test">
                          <div class="test-check" [class.complete]="pupil.isTested"></div>
                        </div>
                      </div>
                      <!-- <div class="class-test-row">

                  <div class="test-check" [class.complete]="pupil.lastParentSessionCompleted"></div>
                  <div class="class-test-column test" *ngIf="pupil.lastParentSessionCompleted === true; else testNotCompleted">
                    <div class="test-check complete"></div>
                  </div>

                  <div class="test-check" [class.complete]="pupil.lastSessionCompleted"></div>
                  <div class="class-test-column test" *ngIf="pupil.lastSessionCompleted === true; else testNotCompleted">
                    <div class="test-check complete"></div>
                  </div>
                </div> -->
                    </div>

                    <ng-template #testNotCompleted>
                      <div class="test-check"></div>
                    </ng-template>

                    <!--if last class class-line no-underline-->
                    <div class="class-line" [ngClass]="pupils.length === i ? 'no-underline' : ''"></div>
                  </div>

                  <!--if NOT registrated-->
                  <ng-template #notRegistered>
                    <div class="class-row-div">
                      <div class="class-column _7">
                        <div class="class-column _2 _10">
                          <div class="class-photo-column">
                            <a class="class-photo no-photo w-inline-block"></a>
                          </div>
                        </div>
                        <div class="class-column _4">
                          <div class="student-name">
                            <a class="student-name-link no-link">{{ pupil.firstName }} {{ pupil.lastName }}</a>
                          </div>
                          <div class="student-online-status">Не зарегистрирован</div>
                        </div>
                      </div>
                      <div class="class-column _10">
                        <div>
                          Код для входа:
                          <span class="student-code">{{ pupil.registrationCode }}</span>
                        </div>
                        <div class="info-div-class">
                          <div class="info-icon" (mouseenter)="showElement($event)" (mouseleave)="hideElement($event)"></div>
                          <div class="info-popup stud-code">
                            <p>Передайте этот код ученику для регистрации.</p>
                            <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle stud-code" />
                          </div>
                        </div>
                      </div>
                      <div class="class-line"></div>
                    </div>
                  </ng-template>

                  <!--ContexMenu-->
                  <!--<div class="class-dropdown w-dropdown">
              <div class="tabs-dropdown-toggle w-dropdown-toggle" (click)="nav.classList.toggle('w&#45;&#45;open')">
                <img src="/assets/images/icons/dots-dd.svg" class="tabs-dropdown-icon">
              </div>
              <nav class="class-dropdown-list w-dropdown-list" #nav>
                <div class="class-dd-bg">
                  <a class="class-dd-option" (click)="nav.classList.toggle('w&#45;&#45;open')">Опция 1</a>
                  <a class="class-dd-option" (click)="nav.classList.toggle('w&#45;&#45;open')">Опция 2</a>
                </div>
              </nav>
            </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--if NOT HAVE pupils -->
      <div class="class-div" *ngIf="pupils.length == 0">
        <div class="empty-class">
          <div class="empty-class-item">
            <img src="/assets/images/control-panel/logo-empty.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notRegistered>
  <small>Не зарегистрирован</small>
</ng-template>

<!-- <ng-template #pupilPage>
  <div class="return-button-container">
    <a (click)="routingBack()" class="no-link">{{ 'BUTTONS.PREVIOUS' | translate }}</a>
  </div>
  <prf-tutor-student-page [pupil]="selectedPupil"></prf-tutor-student-page>
</ng-template> -->
