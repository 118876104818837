import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { SchoolAdminRoutingModule } from './school-admin-routing.module';
import { SchooladminAddClassComponent } from './schooladmin-add-class/schooladmin-add-class.component';
import { SchooladminAddStudentsComponent } from './schooladmin-add-students/schooladmin-add-students.component';
import { SchooladminClassTemplateComponent } from './schooladmin-class-template/schooladmin-class-template.component';
import { SchooladminEditClassComponent } from './schooladmin-edit-class/schooladmin-edit-class.component';
import { SchoolAdminPanelComponent } from './schooladmin-panel.component';
import { SchoolAdminPanelService } from './schooladmin-panel.service';

@NgModule({
  declarations: [
    SchoolAdminPanelComponent,
    SchooladminAddStudentsComponent,
    SchooladminClassTemplateComponent,
    SchooladminEditClassComponent,
    SchooladminAddClassComponent,
  ],
  providers: [SchoolAdminPanelService],
  imports: [CommonModule, SchoolAdminRoutingModule, SharedModule],
})
export class SchoolAdminModule {}
