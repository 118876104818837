import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Observable, of, Subject, timer } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IResult, IResultsData } from '../results.interface';
import { ResultsService } from '../results.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { Stands } from 'app/shared/enums/stands.enums';
import { EPromoTestTypes, promoTestData } from 'app/pages/control-panel/parent/promo-test/promo-test.component';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { PlayerService } from 'app/pages/player/player.service';
import { SlideService } from 'app/shared/services/slide.service';
import { ETestTypes } from 'app/shared/enums/testtypes.enum';
import { consultsPromoData } from 'app/shared/global-constants/consults-data';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { ClassesFormatTypes } from 'app/shared/enums/courses-types.enum';
import { PromotestResultsCanDeactivate } from 'app/core/guards/promoResultsDeactivate.guard';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { MenuService } from 'app/shared/dashboard/menus/menu/menu.service';
import { MatIconRegistry } from '@angular/material/icon';

export const ProfilumCoursesHrid = ['profilum_proforientacia', 'profilum_proforientacia_consult', 'profilum_proforientacia_20'];
@Component({
  selector: 'prf-promotest-results',
  templateUrl: './promotest-results.component.html',
  styleUrls: ['./promotest-results.component.scss'],
})
export class PromotestResultsComponent implements OnInit, PromotestResultsCanDeactivate {
  public userRole: string = '';
  public urlSessionId: string = '';
  public userId: string = '';
  public regionId: string = '';
  public municipalityId: string = '';

  public userProfile: any = {};
  public children: any[] = [];
  public childrenTested: any[] = [];
  private selectedChild: any = {};
  private isPupil: boolean = false;
  private talentDescriptionByRole: Object = {};

  public results: any;
  public promotestResults: any[] = [];

  public talents: IResult[] = [];
  public talentsData = [];
  public testRecommendsTalents: any = [];
  public recommendedCoursesTest: Array<any> = [];
  public profilumCourses: any[] = [];

  public course: IResult[] = [];
  public additionalText: Array<string> = [];
  public additionalTextName: string = null;
  public additionalTextShortDescription: string = null;
  public chartSize: number = 560; // [px]

  public parentSessionId: string = '';
  public pupilSessionId: string = '';

  public promoTestTypes = EPromoTestTypes;
  public createSessionObject = { userId: '', screeningTestId: '', data: {} };
  testDataArray = promoTestData;
  public credentials: any;

  dataLoaded: boolean = false;
  public nullResults: boolean = false;
  popUpConfirming: boolean = false;
  consultsPromoData = consultsPromoData;
  isMobileConsult: boolean = false;

  popupReminderShow: boolean = false;
  isReminded: boolean = false;
  reminderTitle: string = '';
  popupReminderLink = 'https://career.profilum.ru/mosru/?utm_source=mosru&utm_medium=banner_mosru&utm_campaign=promo_mosru';
  consultsUrlVG = 'https://career.profilum.ru/?utm_source=vorobyevy_gory&utm_medium=cpc&utm_campaign=vg_promo';

  filters: IFilterClasses = {};
  fromTo: any = { currentPage: 0, pageSize: null };

  coursesOnPage: number = 12;
  //сколько бесплатных курсов добавляются в начало списка при сортировке
  coursesFreeFirstConst: number = 3;
  // макс. кол-во детей в шапке страницы
  public maxChildrenInRaw = 5;

  public buttonOpacity: number = 0;
  public isTabDropdown: boolean = false;
  @ViewChild('tabDropdown') tabDropdown: ElementRef;
  @ViewChild('PopupReminder') PopupReminder: ElementRef;
  //view elements properties
  crossingLineAngle: number;
  crossingLineLength: number;

  // проверка языка для регионов
  public selectedLanguage: string =
    AppSettingsService.settings.regionLanguages && AppSettingsService.settings.regionLanguages.isAccessible
      ? AppSettingsService.settings.regionLanguages.defaultLanguage
      : null;
  public regionLanguages = {
    default: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.defaultLanguage : null,
    native: AppSettingsService.settings.regionLanguages ? AppSettingsService.settings.regionLanguages.regionLanguage : null,
  };

  isMobile: boolean = false;
  isVGuser: boolean = false;

  public showBanner: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(
    private activatedRoute: ActivatedRoute,
    public element: ElementRef,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private resultsService: ResultsService,
    private location: Location,
    private translate: TranslateService,
    private coursesFiltersService: CoursesFiltersService,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private playerService: PlayerService,
    private slideService: SlideService,
    private cd: ChangeDetectorRef,
    private menuService: MenuService,
  ) {
    this.userRole = localStorage.getItem('userRole');
    this.userId = localStorage.getItem('userId');
    this.regionId = localStorage.getItem('regionId');
    this.municipalityId = localStorage.getItem('municipalityId');
    localStorage.removeItem('testType');
    this.isVGuser = localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString();

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params && params['ssid']) {
        this.urlSessionId = params['ssid'];
      }
    });

    this.menuService.isMobileScreen$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit() {
    this.loadData().subscribe(() => {
      this.overlayService.hide();
      this.dataLoaded = true;
      this.cd.markForCheck();
      setTimeout(() => this.checkViewProperties(), 10);
    });

    setTimeout(() => {
      if (!this.isVGuser) {
        this.reminderTitle = 'Хотите узнать больше?';
        this.popupReminderShow = true;
        this.isReminded = true;
      }
    }, 20000);
    this.isMobileConsult = window.innerWidth < 900 ? true : false;

    if (this.isVGuser) {
      this.consultsPromoData.consultsUrl = this.consultsUrlVG;
    }

    this.checkTag();
  }

  public loadData(): Observable<any> {
    this.dataLoaded = false;
    this.overlayService.show();
    return this.sharedService.getUserInfoData().pipe(
      switchMap(data => {
        this.userProfile = data;

        // все дети родителя
        this.children = this.userProfile.children;
        return this.resultsService.getSharedResults(this.userProfile.userId).pipe(
          switchMap(sharedResults => {
            if (sharedResults.length > 0) {
              this.promotestResults = sharedResults.filter(r => r.screeningTestId === TESTS_VARIANTS_IDS.promoTestMosRu);
              let sessionIds: any[] = [];

              //ф-ия заполнения childrenTested
              let setChildrenTested = () => {
                this.childrenTested = [];
                this.children.forEach(child => {
                  if (
                    this.promotestResults.filter(oneResult => {
                      return oneResult.refferalUserId == child.userId;
                    }).length > 0
                  ) {
                    this.childrenTested.push(child);
                  }
                });
              };

              if (this.urlSessionId) {
                // если зашли с sessionId в url
                let currentSessionId = sharedResults.find(ss => ss.sessionId === this.urlSessionId)
                  ? sharedResults.find(ss => ss.sessionId === this.urlSessionId).sessionId
                  : null;
                if (currentSessionId) {
                  //основной кейс
                  sessionIds = [currentSessionId];
                  setChildrenTested();
                  this.selectedChild = this.childrenTested.find(
                    cht => cht.userId === sharedResults.find(shr => shr.sessionId === currentSessionId).refferalUserId,
                  );
                } else {
                  // если id сессии не найден
                  setChildrenTested();
                  //берем первого ребенка из тех, за кого пройден тест
                  this.selectedChild = this.childrenTested[0];
                  sessionIds.push(this.promotestResults.find(pr => pr.refferalUserId === this.selectedChild.userId).sessionId);
                }
              } else {
                // если sessionId нет в url
                setChildrenTested();
                //берем первого ребенка из тех, за кого пройден тест
                this.selectedChild = this.childrenTested[0];
                sessionIds.push(this.promotestResults.find(pr => pr.refferalUserId === this.selectedChild.userId).sessionId);
              }
              // задержка 1с нужна для обновления данных в эластике поле сохранения
              return timer(1000).pipe(
                switchMap(() => {
                  return this.resultsService.getSessionData(sessionIds[0]).pipe(
                    switchMap(sessionData => {
                      if (sessionData) {
                        if (sessionData.completed !== true) {
                          this.results = {};
                          Object.assign(this.results, { sessionIncompleted: true });
                          return of(null);
                        }
                        let getResultsObject = {
                          regionId: this.userProfile.regionId,
                          municipalityId: this.userProfile.municipalityId,
                          sessionIds: sessionIds,
                          role: 'parent',
                        };

                        return this.resultsService.getResultPages(getResultsObject).pipe(
                          switchMap(results => {
                            if (results) {
                              this.results = results;
                              if (!results.results || results.results.length === 0) {
                                // если результаты нулевые, то зануляем таланты и курсы
                                this.talentsData = [];
                                this.recommendedCoursesTest = [];
                                Object.assign(this.results, { nullResults: true });
                                return of(null);
                              }
                              return this.parseResults(this.results);
                            } else {
                              return of(null);
                            }
                          }),
                        );
                      } else {
                        return of(null);
                      }
                    }),
                  );
                }),
              );
            } else {
              return of(null);
            }
          }),
        );
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  private parseResults(results: IResultsData): Observable<any> {
    let resultsData;
    resultsData = JSON.parse(JSON.stringify(results.results));
    if (resultsData[0].results.length === 0) {
      this.talentsData = [];
      this.recommendedCoursesTest = [];
      return of(null);
    }

    this.getAdditionalText();

    this.talents = resultsData.filter(d => d.objectType === 'Talent' && d.results.length);

    // запрашиваются результаты по sessionId, не совместные, поэтому берутся results[0]
    this.testRecommendsTalents = this.talents.sort((a, b) =>
      a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1,
    );

    let regularCoursesArrayRequest$: Observable<any>[] = [];
    ProfilumCoursesHrid.forEach(hrid => {
      regularCoursesArrayRequest$.push(
        this.coursesFiltersService.getClassMongoByHrid(hrid).pipe(
          map(course => {
            if (course) {
              this.profilumCourses.push(course);
            }
          }),
        ),
      );
    });
    return forkJoin(regularCoursesArrayRequest$).pipe(
      switchMap(() => {
        return this.addRecommendedProgramsByTalent(this.testRecommendsTalents).pipe(
          switchMap(r => {
            // получаем урл картинок для талантов
            return this.resultsService.getTalentsUrls().pipe(
              take(1),
              tap(data => {
                // преобразовываем массив с урлами талантов объект
                data.forEach(item => {
                  if (!this.isPupil) {
                    switch (this.userRole) {
                      case 'parent':
                        this.talentDescriptionByRole[item.name] = item.descriptionForParent;
                        break;
                      default:
                        this.talentDescriptionByRole[item.name] = item.descriptionForTutor;
                    }
                  }
                  // синхронная функция
                  this.talentsData = this.preTransformTalents(this.talents);
                  this.overlayService.hide();
                });
              }),
            );
          }),
        );
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  addRecommendedProgramsByTalent(testRecommends): Observable<any> {
    // courses filters
    Object.assign(this.filters, {
      classesFormat: [ClassesFormatTypes.ShortCourse, ClassesFormatTypes.LongCourse],
      talents: [],
      stand: Stands.Talent,
      regionId: this.userProfile.regionId,
      municipalityId: this.userProfile.municipalityId,
    });
    testRecommends.forEach(recom => {
      this.filters.talents.push(recom.name);
    });
    return this.coursesFiltersService.getFilteredClassesMongo(this.filters, this.fromTo).pipe(
      tap(courses => {
        if (courses.length) {
          this.recommendedCoursesTest = this.createRecommendedCoursesArray(courses, testRecommends);
        }
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  createRecommendedCoursesArray(coursesByTest: any[], talents: any[]): any[] {
    let coursesArray = [];
    let coursesThreeCoincidence = [];
    let coursesTwoCoincidence = [];
    let coursesOneCoincidence = [];
    let coursesFreePayment = [];

    if (coursesByTest && talents) {
      //courses with 3 talents coincidence
      coursesThreeCoincidence = coursesByTest.filter(course => {
        return talents.every(talent => course.talents.includes(talent.name));
      });

      coursesArray = coursesArray.concat(coursesThreeCoincidence);
      //courses with 2 talents coincidence
      coursesTwoCoincidence = coursesByTest.filter(course => {
        if (course.talents.filter(c => talents.some(t => t.name === c)).length === 2) {
          return true;
        } else {
          return false;
        }
      });

      coursesArray = coursesArray.concat(coursesTwoCoincidence);
      //courses with 1 talents coincidence
      coursesOneCoincidence = coursesByTest.filter(course => {
        if (course.talents.filter(c => talents.some(t => t.name === c)).length === 1) {
          return true;
        } else {
          return false;
        }
      });
      coursesArray = coursesArray.concat(coursesOneCoincidence);
      // массив бесплатных курсов
      coursesArray.forEach(course => (course.unitPrice <= 0 ? coursesFreePayment.push(course) : null));
      let coursesFreeArrayFirst = coursesFreePayment.slice(0, this.coursesFreeFirstConst);
      // все остальные курсы
      let coursesAllOther = [];
      coursesArray.forEach(course => {
        coursesFreeArrayFirst.every(courseFree => courseFree.name != course.name) ? coursesAllOther.push(course) : null;
      });
      let resultCourses = coursesFreeArrayFirst
        .concat(this.profilumCourses)
        .concat(coursesAllOther.sort((a, b) => (this.calculateRealPrice(a) > this.calculateRealPrice(b) ? 1 : -1)));

      //берем первые 12, сортируем их по возрастанию цены
      resultCourses = resultCourses.slice(0, this.coursesOnPage);

      return resultCourses;
    } else {
      return [];
    }
  }

  repeatTest() {
    let variantString;
    let childbirthDay = new Date(this.selectedChild.birthday);
    let childAge = new Date().getFullYear() - childbirthDay.getFullYear();

    let testType = this.checkInterval(childAge, 7, 10)
      ? this.promoTestTypes.TYPE_1
      : this.checkInterval(childAge, 11, 13)
      ? this.promoTestTypes.TYPE_2
      : this.checkInterval(childAge, 14, 18)
      ? this.promoTestTypes.TYPE_3
      : null;

    switch (testType) {
      case this.promoTestTypes.TYPE_1:
        //screeningTestId
        this.createSessionObject.screeningTestId = this.testDataArray.find(
          test => test.testType === this.promoTestTypes.TYPE_1,
        ).screeningTestId;
        //variant
        variantString = this.testDataArray.find(test => test.testType === this.promoTestTypes.TYPE_1).variantString;
        variantString ? Object.assign(this.createSessionObject, { data: { ScreeningTestType: variantString } }) : null;
        break;
      case this.promoTestTypes.TYPE_2:
        //screeningTestId
        this.createSessionObject.screeningTestId = this.testDataArray.find(
          test => test.testType === this.promoTestTypes.TYPE_2,
        ).screeningTestId;
        //variant
        variantString = this.testDataArray.find(test => test.testType === this.promoTestTypes.TYPE_2).variantString;
        variantString ? Object.assign(this.createSessionObject, { data: { ScreeningTestType: variantString } }) : null;
        break;
      case this.promoTestTypes.TYPE_3:
        //screeningTestId
        this.createSessionObject.screeningTestId = this.testDataArray.find(
          test => test.testType === this.promoTestTypes.TYPE_3,
        ).screeningTestId;
        //variant
        variantString = this.testDataArray.find(test => test.testType === this.promoTestTypes.TYPE_3).variantString;
        variantString ? Object.assign(this.createSessionObject, { data: { ScreeningTestType: variantString } }) : null;
        break;
      default:
        break;
    }

    if (AppSettingsService.settings.promotestSchool) {
      //selectedLanguage
      if (this.selectedLanguage) {
        Object.assign(this.createSessionObject, { language: this.selectedLanguage });
      }
      //userId
      this.createSessionObject.userId = this.userId ? this.userId : this.userProfile.userId;

      //selected child Id
      Object.assign(this.createSessionObject, {
        ReferralUserId: this.selectedChild.userId,
      });
      //create session
      this.playerService
        .createPormoTestSession(this.createSessionObject)
        .pipe(
          switchMap(sessionResult => {
            if (!sessionResult || !sessionResult.status || sessionResult.status === 'Failed to create testing session') {
              this.handleSessionError();
              return of(null);
            } else {
              return timer(1000).pipe(
                switchMap(r => {
                  return this.playerService.getNextSlide(sessionResult.sessionId).pipe(
                    tap(value => {
                      this.slideService.setCurrentSlide(sessionResult.sessionId, value);
                      //set testType lo LS
                      localStorage.setItem('testType', ETestTypes.PROMO_TEST.toString());
                      this.router.navigate(['/mosrupromo-test', sessionResult.sessionId]).then(() => {
                        this.overlayService.hide();
                      });
                    }),
                  );
                }),
              );
            }
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe();
    } else {
    }
  }

  navigateNewTest() {
    return this.router.navigate(['/parent/mosrupromo']);
  }

  private preTransformTalents(talents) {
    const shortDescriptionLen: number = 40;

    return talents
      .map(t => {
        return {
          name: t.name,
          description: this.isPupil ? t.description : this.talentDescriptionByRole[t.name],
          shortDescription:
            t.description.length < shortDescriptionLen ? t.description : t.description.slice(0, shortDescriptionLen - 2) + '..',
          value: t.results.length ? t.results[0].transformedValue : 0,
          imageUrl: document.location.origin + this.location.prepareExternalUrl(t.imageUrl),
          title: this.isEn() ? t.name : t.data.DativeCase,
        };
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, this.resultsService.TALENTS_TO_CHOOSE);
  }

  private getAdditionalText() {
    if (this.results && this.results.additionalText) {
      this.additionalTextName = this.results.additionalText.name;
      this.additionalTextShortDescription = this.results.additionalText.shortDescription;
      this.additionalText = this.prepareAdditionalText(this.results.additionalText.text);
    }
  }

  private prepareAdditionalText(text: string): Array<string> {
    let result: Array<string> = [];
    if (text && text.length) {
      let textList = text.replace(/[ ]?<p>[ ]?/gi, '').split('</p>');

      textList.forEach((t, i) => {
        if (i < 4) {
          result.push(t);
        }
      });
    }
    return result;
  }

  private isEn = () => this.translate.currentLang === 'en';

  public onSelectChild(child: any) {
    this.selectedChild = child;
    this.isTabDropdown = false;

    let sessionIds: any[] = [];
    sessionIds.push(this.promotestResults.find(pr => pr.refferalUserId === this.selectedChild.userId).sessionId);

    return this.resultsService
      .getSessionData(sessionIds[0])
      .pipe(
        switchMap(sessionData => {
          if (sessionData) {
            if (sessionData.completed !== true) {
              this.results = {};
              Object.assign(this.results, { sessionIncompleted: true });
              return of(null);
            }

            let getResultsObject = {
              regionId: this.userProfile.regionId,
              municipalityId: this.userProfile.municipalityId,
              sessionIds: sessionIds,
              role: 'parent',
            };
            this.overlayService.show();
            this.dataLoaded = false;
            return this.resultsService.getResultPages(getResultsObject).pipe(
              switchMap(results => {
                if (results) {
                  this.results = results;
                  if (!results.results || results.results.length === 0) {
                    // если результаты нулевые, то зануляем таланты и курсы
                    this.talentsData = [];
                    this.recommendedCoursesTest = [];
                    Object.assign(this.results, { nullResults: true });
                    return of(null);
                  }
                  return this.parseResults(this.results);
                } else {
                  return of(null);
                }
              }),
              takeUntil(this.ngUnsubscribe$),
            );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(_ => {
        this.dataLoaded = true;
        this.overlayService.hide();
      });
  }

  routingAllCourses() {
    this.router.navigateByUrl('/partners-courses-all');
  }

  checkInterval(value, min, max) {
    return value >= min && value <= max;
  }

  get childrenTestedShow(): any {
    return this.childrenTested.slice(0, this.maxChildrenInRaw);
  }

  get childrenMore(): any {
    return this.childrenTested.slice(this.maxChildrenInRaw);
  }

  handleSessionError() {
    this.utilsService.openSnackBar('Неполадки на сервере', 'error');
    setTimeout(() => {
      this.overlayService.hide();
      this.utilsService.closeSnackBar();
      return this.router.navigate(['/']);
    }, 2000);
  }

  private checkViewProperties() {
    if (document.getElementById('id_price_undiscounted')) {
      let elementWidth = document.getElementById('id_price_undiscounted').offsetWidth;
      let elementHight = document.getElementById('id_price_undiscounted').offsetHeight;
      this.crossingLineAngle = (Math.atan(elementHight / elementWidth) * 180) / Math.PI;

      let angle = Math.atan(elementHight / elementWidth);
      this.crossingLineLength = elementWidth / Math.cos(angle);
    }
  }

  calculateRealPrice(course: any) {
    let discount = +course.unitDiscount;
    let price = +course.unitPrice;
    if (course.isPercent) {
      return discount ? price - price * discount * 0.01 : price;
    } else {
      return discount ? price - discount : price;
    }
  }

  showMobileMenu() {
    this.menuService.showMobileMenu(true);
  }

  private checkTag() {
    const tag = localStorage.getItem('tag');
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = '';
    }
  }
  canDeactivate(): boolean | Observable<boolean> {
    if (this.isReminded || this.isVGuser) {
      return true;
    }
    setTimeout(() => {
      this.reminderTitle = 'Уже уходите?';
      this.popupReminderShow = true;
      this.isReminded = true;
    }, 50);
    return false;
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.tabDropdown) {
      if (!this.tabDropdown.nativeElement.contains(target)) {
        this.isTabDropdown = false;
      }
    }
    if (this.popupReminderShow) {
      if (!this.PopupReminder.nativeElement.contains(target)) {
        this.popupReminderShow = false;
        this.isReminded = true;
      }
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileConsult = window.innerWidth < 900 ? true : null;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
