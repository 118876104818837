import { Component, Input, OnInit } from '@angular/core';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'prf-children-favorites',
  templateUrl: './children-favorites.component.html',
  styleUrls: ['./children-favorites.component.scss'],
})
export class ChildrenFavoritesComponent implements OnInit {
  @Input() childrenUserId: string = '';
  @Input() selectedSubMenu: string = '';
  public loaded: boolean = false;
  public professionsFavorites: any[] = [];

  isLoading: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private favoritesService: FavoritesService) {}

  ngOnInit() {
    this.getFavoritesAll()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => setTimeout(() => (this.loaded = true), 200));
  }

  public getFavoritesAll(): Observable<any> {
    if (!this.isLoading) {
      this.isLoading = true;
      return this.favoritesService.getUserFavorites(this.childrenUserId).pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap(userFavorites => {
          let favouriteCourses = userFavorites.filter(el => el.productType === FavoritesTypes.Class);
          this.favoritesService.setFavoriteCourses(favouriteCourses);

          let favoriteProfessions = userFavorites.filter(el => el.productType === FavoritesTypes.Profession);
          this.favoritesService.setFavoriteProfessions(favoriteProfessions);

          let professionsIds = userFavorites.filter(el => el.productType === FavoritesTypes.Profession).map(el => el.productId) || [];

          let coursesFavorites$: Observable<any> = of([]);

          return coursesFavorites$.pipe(
            switchMap(courses => {
              let professionsFavorites$: Observable<any> = of([]);
              if (professionsIds.length) {
                professionsFavorites$ = this.favoritesService.getUserFavoritesProfessions(professionsIds);
              }
              return professionsFavorites$.pipe(
                tap(professions => {
                  this.professionsFavorites = professions;
                  this.isLoading = false;
                }),
              );
            }),
          );
        }),
      );
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
