<div *ngIf="loaded" class="tasks-tabs">
  <div class="tasks-tabs-menu">
    <div class="tasks-tabs-menu-row">
      <a (click)="changeTaskLevel(1)" [class.w--current]="tasksLevel === 1" class="tasks-tab-link w-inline-block">
        <div [class.current]="tasksLevel === 1" class="task-tab-num">1</div>
        <div class="tasks-tab-link-text">{{ 'SHARED.INTRODUCTORY_LEVEL' | translate }}</div>
      </a>
      <a (click)="changeTaskLevel(2)" [class.w--current]="tasksLevel === 2" class="tasks-tab-link w-inline-block">
        <div [class.current]="tasksLevel === 2" class="task-tab-num">2</div>
        <div class="tasks-tab-link-text">Углубленный уровень</div>
      </a>
      <a class="tasks-tab-link locked w-inline-block">
        <div class="task-tab-num"><img src="/assets/images/icons/locked-padlock.svg" /></div>
        <div class="tasks-tab-link-text">Профессиональный уровень</div>
      </a>
    </div>
  </div>

  <div class="task-tab-pane">
    <div class="task-row">
      <ng-container *ngIf="tasksLevel === 1">
        <div class="starter-text">
          <img src="/assets/images/tasks/starter-icon.svg" alt="" />
          <article>
            <p>Задания этого уровня подойдут тебе для базового ознакомления с профессией. Скорее приступай!</p>
          </article>
        </div>
        <div class="task-column task-row-full">
          <prf-goals-task-card
            *ngIf="showStatic"
            [isStatic]="true"
            (eventMenuAccess)="updMenuAccess($event)"
            [staticTasks]="userStaticTaskBeginner"
            [text]="soloText"
            [professionID]="_profession.id"
            [tasks]="first_block"
          >
          </prf-goals-task-card>
        </div>
        <div class="task-column task-row-full">
          <prf-goals-task-card
            *ngIf="first_block.length > 0"
            [text]="shortText"
            [coursesFormats]="['ShortCourse', 'LongCourse']"
            (eventMenuAccess)="updMenuAccess($event)"
            [bgColor]="'orange'"
            [professionID]="_profession.id"
            [tasks]="first_block"
          >
          </prf-goals-task-card>
        </div>
        <div class="task-column task-row-full">
          <prf-goals-task-card
            *ngIf="second_block.length > 0"
            [coursesFormats]="['MasterClass', 'Profproba']"
            [text]="masterText"
            (eventMenuAccess)="updMenuAccess($event)"
            [bgColor]="'green'"
            [professionID]="_profession.id"
            [tasks]="second_block"
          >
          </prf-goals-task-card>
        </div>
        <div class="task-column task-row-full">
          <prf-goals-task-card
            *ngIf="third_block.length > 0"
            [coursesFormats]="['Excursion', 'Meeting']"
            [text]="excursionText"
            (eventMenuAccess)="updMenuAccess($event)"
            [bgColor]="'yellow'"
            [professionID]="_profession.id"
            [tasks]="third_block"
          >
          </prf-goals-task-card>
        </div>
        <div class="task-column task-row-full">
          <prf-goals-task-card
            *ngIf="fourth_block.length > 0"
            [coursesFormats]="['Action', 'Festival']"
            [text]="actionText"
            (eventMenuAccess)="updMenuAccess($event)"
            [bgColor]="'blue'"
            [professionID]="_profession.id"
            [tasks]="fourth_block"
          >
          </prf-goals-task-card>
        </div>
      </ng-container>

      <ng-container *ngIf="tasksLevel === 2">
        <div class="starter-text">
          <img src="/assets/images/tasks/stager-icon.svg" alt="" />
          <article>
            <p>
              Эти задания могут потребовать некоторых знаний и навыков. Если ты еще не знаком с профессией, лучше начать с ознакомительного
              уровня.
            </p>
          </article>
        </div>
        <div class="task-column task-row-full">
          <prf-goals-task-card
            *ngIf="showStatic"
            [isStatic]="true"
            [staticTasks]="userStaticTaskIntermediate"
            (eventMenuAccess)="updMenuAccess($event)"
            [text]="soloText"
            [professionID]="_profession.id"
            [tasks]="first_block"
          >
          </prf-goals-task-card>
        </div>
        <div class="task-column task-row-full">
          <prf-goals-task-card
            *ngIf="first_block.length > 0"
            [text]="shortText"
            [bgColor]="'orange'"
            (eventMenuAccess)="updMenuAccess($event)"
            [professionID]="_profession.id"
            [tasks]="first_block"
          >
          </prf-goals-task-card>
        </div>
        <div class="task-column task-row-full">
          <prf-goals-task-card
            *ngIf="second_block.length > 0"
            [text]="masterText"
            [bgColor]="'green'"
            (eventMenuAccess)="updMenuAccess($event)"
            [professionID]="_profession.id"
            [tasks]="second_block"
          >
          </prf-goals-task-card>
        </div>
        <div class="task-column task-row-full">
          <prf-goals-task-card
            *ngIf="third_block.length > 0"
            [text]="excursionText"
            [bgColor]="'yellow'"
            (eventMenuAccess)="updMenuAccess($event)"
            [professionID]="_profession.id"
            [tasks]="third_block"
          >
          </prf-goals-task-card>
        </div>
        <div class="task-column task-row-full">
          <prf-goals-task-card
            *ngIf="fourth_block.length > 0"
            [text]="actionText"
            [bgColor]="'blue'"
            (eventMenuAccess)="updMenuAccess($event)"
            [professionID]="_profession.id"
            [tasks]="fourth_block"
          >
          </prf-goals-task-card>
        </div>
      </ng-container>
    </div>
  </div>
</div>
