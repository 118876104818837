import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, throwError, Subject, Observable, TimeoutError } from 'rxjs';
import { catchError, mergeMap, switchMap, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { CoursesFiltersService } from './courses-filter/courses-filters.service';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { CoursesService } from 'app/pages/catalog/courses-page/courses.service';
import { ClassesFormatTypes } from 'app/shared/enums/courses-types.enum';
import { ChangeDetectorRef } from '@angular/core';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';

const PAGE_SIZE: number = 6;
const TRIGGER_SCROLL_BOTTOM: number = 350;

// используется учеником, учителем
@Component({
  selector: 'prf-courses-catalog',
  templateUrl: './courses-catalog.component.html',
  styleUrls: ['./courses-catalog.component.scss'],
})
export class CoursesCatalogComponent implements OnInit, OnDestroy {
  @Input() isCourses: boolean;
  fromTo: any = { currentPage: 0, pageSize: 1000 };
  filters: IFilterClasses;
  courses: any;
  titleName: string;
  currentCourses: any = [];
  schoolId: string;
  cardsToShow: number = PAGE_SIZE;
  courseTitles: string[] = [];

  requestError: boolean = false;
  dataFetched: boolean = false;
  isLoading: boolean = false;

  public searches: any = [];
  public classesCount: string;
  public filteredCourses: any = [];
  public isSearchList: boolean = false;

  translations: any[] = [];

  private ngUnsubscribe$: Subject<any> = new Subject();

  public defaultRegion: string = '00000000-0000-0000-0000-000000000000';
  public defaultMunicipality: string = '00000000-0000-0000-0000-000000000000';

  constructor(
    private meta: Meta,
    private filtersService: CoursesFiltersService,
    private router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private coursesService: CoursesService,
    private cd: ChangeDetectorRef,
  ) {
    this.getTranslations(['COURCES.CATALOG.CATALOG_CATEGORY_TEXT'])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.meta.updateTag({
          name: 'og:title',
          content: translations['COURCES.CATALOG.CATALOG_CATEGORY_TEXT'],
        });
      });
    this.getTranslations(['SHARED.COURSE', 'SHARED.COURSE_A', 'SHARED.COURSES_OV'])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.courseTitles = [
          translations['SHARED.COURSE'].toLowerCase(),
          translations['SHARED.COURSE_A'].toLowerCase(),
          translations['SHARED.COURSES_OV'].toLowerCase(),
        ];
      });

    this.route.data.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(v => {
      this.isCourses = v.isCourses;
    });
  }

  ngOnInit() {
    this.getTranslations(['SHARED.ACTIVITIES', 'SHARED.COURSES_OV', 'SHARED.FOR_YOU_REQUEST_NOT_TITLE_NAME'])
      .pipe(
        switchMap(translations => {
          this.translations = translations;

          return this.filtersService.getCoursesFilters().pipe(
            mergeMap(filters => {
              // check and reject null/undefined values
              let filtersVerified: IFilterClasses = {};
              Object.keys(filters).forEach(key => {
                filters[key] != null && filters[key] != undefined ? (filtersVerified[key] = filters[key]) : null;
              });
              this.filters = filtersVerified;
              this.schoolId = localStorage.getItem('schoolId');
              if (localStorage.getItem('course')) {
                this.filters.courses = [localStorage.getItem('course')];
                localStorage.removeItem('course');
              }
              return this.loadCourses();
            }),
          );
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(_ => (this.dataFetched = true));
  }

  loadCourses(): Observable<any> {
    let path = this.router.routerState.snapshot.url;
    let filters = Object.assign({}, this.filters);
    if (this.filters && (!this.filters.classesFormat || this.filters.classesFormat.length === 0)) {
      if (path === '/events') {
        this.titleName = this.translations['SHARED.ACTIVITIES'].toLowerCase();
        filters.classesFormat = [
          ClassesFormatTypes.MasterClass,
          ClassesFormatTypes.Excursion,
          ClassesFormatTypes.Action,
          ClassesFormatTypes.Festival,
          ClassesFormatTypes.Meeting,
          ClassesFormatTypes.Competition,
          ClassesFormatTypes.Profproba,
          ClassesFormatTypes.OpenDay,
        ];
      }
      // если идет запрос мероприятий школы, то classesFormat не выставляется
      if (path === '/courses' && !filters.institutionId) {
        this.titleName = this.translations['SHARED.COURSES_OV'].toLowerCase();
        filters.classesFormat = [ClassesFormatTypes.ShortCourse, ClassesFormatTypes.LongCourse];
      }
    }
    this.filtersService.setRegionMunicipalityFilters(filters);

    let defaultFilters = Object.assign({}, filters);
    defaultFilters.regionId = this.defaultRegion;
    defaultFilters.municipalityId = this.defaultMunicipality;

    if (!this.isLoading) {
      this.requestError = false;
      this.isLoading = true;
      return observableForkJoin([
        this.filtersService.getFilteredClasses(defaultFilters, this.fromTo),
        this.filtersService.getFilteredClasses(filters, this.fromTo),
      ]).pipe(
        take(1),
        timeout(10000),
        tap(([defaultClasses, classes]) => {
          this.courses = classes.concat(defaultClasses);
          if (this.courses) {
            this.currentCourses = this.courses.slice(0, this.cardsToShow);
            this.filteredCourses = this.currentCourses;
          }
          this.cd.markForCheck();
          this.requestError = false;
          this.isLoading = false;
        }),
        catchError(err => {
          this.requestError = true;
          this.isLoading = false;
          return of(null);
        }),
      );
    }
  }

  public countCourses(coursesLength: number, searchLength: number = 0) {
    let complexLength = coursesLength + searchLength;
    let title = this.filtersService.getTitleByNumber(complexLength, this.courseTitles);
    return (this.classesCount = complexLength + ' ' + title);
  }

  checkFavoriteAccess(): boolean {
    switch (localStorage.getItem('userRole')) {
      case 'pupil':
      case 'parent':
        return true;
      default:
        return false;
    }
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - TRIGGER_SCROLL_BOTTOM && !this.isSearchList) {
      this.cardsToShow += PAGE_SIZE;
      if (this.courses) {
        this.currentCourses = this.courses.slice(0, this.cardsToShow);
        this.filteredCourses = this.currentCourses;
      }
    }
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
    this.filtersService.setCoursesFilter({}, 0);
  }
}
