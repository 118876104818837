import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PlayerService } from 'app/pages/player/player.service';
import { ISlideInterface } from 'app/pages/player/slide/slide.interface';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SlideService  {
  private sessions = {};
  private currentSlideNumber = new BehaviorSubject(null);

  constructor(public route: ActivatedRoute, private playerService: PlayerService) {}

  setCurrentSlide(session, slideData) {
    this.sessions[session] = slideData;
  }

  getCurrentSlide(session): ISlideInterface {
    return this.sessions[session];
  }

  getCurrenSlideNumber(sectionId, sessionId) {
    let slide = this.getCurrentSlide(sessionId);
    if (slide.slide.slideView.bubbleView == null) {
      return slide.slide.orderNumber;
    }

    let sectionNumber = slide.slide.slideView.bubbleView.sections.findIndex(s => s.sectionId == sectionId);
    return sectionNumber + slide.slide.orderNumber;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const resolver = new Subject();
    const session = route.params.ssid;
    if (this.sessions[session] === undefined) {
      this.playerService.getCurrentSlide(session).subscribe(slideData => {
        this.sessions[session] = slideData;
        resolver.next(slideData);
        resolver.complete();
      });
    } else {
      setTimeout(() => {
        resolver.next(this.sessions[session]);
        resolver.complete();
      });
    }
    return resolver;
  }

  changeSlideNumber(n: number) {
    this.currentSlideNumber.next(n);
  }

  getSlideNumber(): Observable<any> {
    return this.currentSlideNumber.asObservable();
  }
}
