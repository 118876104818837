<prf-results
  [showSwitch]="true"
  [showResultMenu]="true"
  [child]="pupil"
  (loaded)="resultLoaded = $event"
  (pupilSessionComplete)="pupilSessionComplete = $event"
>
</prf-results>

<prf-not-passed-test *ngIf="resultLoaded && pupilSessionComplete === false" [user]="pupil"> </prf-not-passed-test>
