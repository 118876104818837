<div>
  <ng-container *ngIf="!loaded">
    <prf-content-loader></prf-content-loader>
  </ng-container>

  <div *ngIf="selectedSubMenu === 'profession' && loaded" class="children-favorites-section _professions">
    <h3 class="children-favorites-title">Ваш ребенок выбрал профессии!</h3>
    <ng-container *ngIf="professionsFavorites && professionsFavorites.length > 0; else emptyContent">
      <div class="catalog-events-row">
        <prf-profession-card-new *ngFor="let profession of professionsFavorites" [profession]="profession"> </prf-profession-card-new>
      </div>
    </ng-container>
  </div>

  <!-- <div *ngIf="selectedTab === 'courses'" class="favourites-section _courses">
          <ng-container *ngIf="coursesFavorites && coursesFavorites.length > 0; else emptyContent">
            <div class="catalog-events-row">
              <prf-course-card
                *ngFor="let course of coursesFavorites"
                [course]="course"
              ></prf-course-card>
            </div>
          </ng-container>
  </div> -->

  <!-- <div *ngIf="selectedTab === 'events'" class="favorites-section _events">
    <ng-container *ngIf="favoriteEvents && favoriteEvents.length > 0; else emptyContent">
      <div class="catalog-events-row">
        <prf-course-card
          *ngFor="let event of favoriteEvents"
          [course]="course"
        ></prf-course-card>
      </div>
    </ng-container>
  </div> -->

  <ng-template #emptyContent>
    <div class="empty-block">
      <div>
        <img src="/assets/images/dashboard/favourites.svg" width="80" class="no-profession-icon" />
      </div>
      <div class="empty-block-text">Вы ещё ничего не добавили в избранное</div>
    </div>
  </ng-template>
</div>
