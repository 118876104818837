<div
  class="portfolio-card"
  [class.achievement-card]="!fixSize"
  [class.achievement-card-lb]="fixSize"
  [style.background-color]="typeParams.bgColor"
  (click)="!isMouseOnEdit ? downloadFile() : false"
  (mouseover)="editButton.style.display = editable ? 'block' : 'none'"
  (mouseleave)="editButton.style.display = 'none'"
>
  <div class="achievement-frame">
    <div class="achievement-frame-row">
      <div class="achievement-frame-oval"></div>
      <div class="achievement-frame-line"></div>
      <div class="achievement-frame-oval _2"></div>
    </div>
    <div class="achievement-frame-line-2"></div>
    <div class="achievement-frame-row">
      <div class="achievement-frame-oval _3"></div>
      <div class="achievement-frame-line _2"></div>
      <div class="achievement-frame-oval _4"></div>
    </div>
  </div>

  <div class="achievement-card-content">
    <div class="achievement-icon-div">
      <!-- проверить картинки после удаления!! вероятно, что-о не подгрузится  -->
      <img [attr.src]="'/assets/images/' + typeParams.image" class="achievement-icon" />
    </div>
    <h4 class="achievement-card-heading">{{ typeParams.name }}</h4>
    <p *ngIf="file.name" class="achievement-card-text">{{ file.name }}</p>
  </div>

  <a
    (click)="clickEdit()"
    (mousemove)="isMouseOnEdit = true"
    (mouseleave)="isMouseOnEdit = false"
    class="achievement-edit-button w-inline-block"
    style="display: none"
    #editButton
  ></a>
</div>
