import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HttpService } from 'app/core/services/http.service';
import { Subject, throwError, TimeoutError, BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RequestCache } from '../../../core/services/request-cache.service';

@Injectable()
export class PageHeaderService {
  private showProfile = new BehaviorSubject({ showProfile: false });
  private showMobileMenu = new BehaviorSubject({ showMobileMenu: false });

  public avatarUpdated$ = new BehaviorSubject(null);

  previousUrl: string;
  currentUrl: string;

  parentMessage$: Subject<any> = new Subject();

  constructor(private http: HttpService, private router: Router, private cache: RequestCache) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  uploadImage(image: File, id: string): Observable<any> {
    let formData: FormData = new FormData();
    formData.append(image[0].name, image[0]);
    return this.http.postImage('/b2c/v1.0/utils/upload/' + id, formData).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getNotifications(): Observable<any> {
    return this.http.get('/b2c/v1.0/saas/getnotifications').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  markNotification(notifications: any): Observable<any> {
    return this.http.post('/b2c/v1.0/saas/marknotification', notifications).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  openProfile() {
    this.showProfile.next({ showProfile: true });
  }

  closeProfile() {
    this.showProfile.next({ showProfile: false });
  }

  getProfileState(): Observable<any> {
    return this.showProfile.asObservable();
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  toggleMobileMenu(bool: boolean) {
    this.showMobileMenu.next({ showMobileMenu: bool });
  }

  getMobileMenuState(): Observable<any> {
    return this.showMobileMenu.asObservable();
  }

  getSchoolByID(schoolID): Observable<any> {
    return this.http.get('/api/v1.0/catalog/schools/onebyid?schoolId=' + schoolID).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getCity(cityId): Observable<any> {
    return this.http.get('/api/v1.0/catalog/cities/one?id=' + cityId).pipe(
      map(r => r.city),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  sendParentMessage(value) {
    this.parentMessage$.next(value);
  }
}
