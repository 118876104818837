import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { FiltersPromotestCoursesService, IPriceFilterFormat, priceFiltersArray } from '../filters-promotest-courses.service';

@Component({
  selector: 'prf-filters-price',
  templateUrl: './filters-price.component.html',
  styleUrls: ['./filters-price.component.scss'],
})
export class FiltersPriceComponent implements OnInit {
  @ViewChild('priceFilter') priceFilter: ElementRef;

  filterOpen: boolean = false;
  pricesFilters: IPriceFilterFormat[] = priceFiltersArray;

  selectedFilter: IPriceFilterFormat;

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(private filtersService: FiltersPromotestCoursesService) {}

  ngOnInit() {
    this.filtersService
      .getPriceFilter()
      .pipe(
        tap((priceFilter: IPriceFilterFormat) => {
          this.selectedFilter = priceFilter;
        }),
      )
      .subscribe();
  }

  setPriceFilter(filter: IPriceFilterFormat) {
    this.selectedFilter = filter;
    this.filtersService.setPriceFilter(this.selectedFilter);
    this.filterOpen = !this.filterOpen;
  }

  checkFilterHeader(): string {
    if (this.selectedFilter) {
      return this.selectedFilter.viewValue;
    } else {
      return 'Цена';
    }
  }

  clearPrice() {
    this.selectedFilter = null;
    this.filtersService.setPriceFilter(this.selectedFilter);
    this.filterOpen = !this.filterOpen;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.priceFilter && !this.priceFilter?.nativeElement.contains(target)) {
      this.filterOpen = false;
    }
  }
}
