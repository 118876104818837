import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

import { IRegion } from 'app/shared/interfaces/iregion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RegionsService } from '../regions.service';

@Component({
  selector: 'prf-edit-region',
  templateUrl: './edit-region.component.html',
  styleUrls: ['./edit-region.component.scss'],
})
export class EditRegionComponent implements OnInit {
  @Input() currentRegion: IRegion;
  @Output() regionChanged = new EventEmitter<any>();

  public regionInfo: IRegion;

  dialogOpen: boolean = false;
  loaded: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private regionsService: RegionsService, private utilsService: UtilsService) {}

  ngOnInit() {}

  edit() {
    this.dialogOpen = !this.dialogOpen;
    this.regionsService
      .getRegion(this.currentRegion.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(region => {
        this.regionInfo = region;
        this.loaded = true;
        return this.regionChanged.emit(this.regionInfo.id);
      });
  }

  onSubmit(valid: boolean) {
    if (valid) {
      this.regionsService
        .updateRegion(this.regionInfo)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          response => {
            this.utilsService.openSnackBar('👌 Регион обновлен', 'success');
            return setTimeout(_ => {
              this.edit();
            }, 300);
          },
          err => {
            return this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте позже', 'error');
          },
        );
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
