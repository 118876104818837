<!-- SEARCH -->
<!-- <prf-search-internships (searchesData)="dataSearch($event)"></prf-search-internships> -->

<!-- FILTERS -->
<prf-filters-internships></prf-filters-internships>

<!--VIEW SETTINGS-->
<div class="internships-row-1">
  <div class="internshis-number"></div>
  <div class="internships-column-1">
    <div class="gender-choice-row">
      <a (click)="viewValue = false" [class.active]="viewValue === false" #grid class="gender-button w-inline-block">
        <img *ngIf="!viewValue" src="/assets/images/courses/list-view-1-white.svg" />
        <img *ngIf="viewValue" src="/assets/images/courses/list-view-1-gray.svg" />
      </a>
      <a (click)="viewValue = true" [class.active]="viewValue === true" #list class="gender-button right w-inline-block">
        <img *ngIf="viewValue" src="/assets/images/courses/list-view-2-white.svg" />
        <img *ngIf="!viewValue" src="/assets/images/courses/list-view-2-gray.svg" />
      </a>
    </div>
  </div>
</div>

<!-- INTERNSHIPS -->
<prf-internships-list [searches]="searches" [viewValue]="viewValue" (countInternships)="countInternships($event)"></prf-internships-list>
