import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoalsService } from 'app/shared/dashboard/goals/goals.service';
import { UserProfileService } from 'app/pages/catalog/user-profile-page/user-profile.service';
import { PortfolioService } from './portfolio-page/portfolio.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HistoryService } from '../backend-services/history.service';
import { IHistory } from 'app/shared/interfaces/ihistory.interface';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';

@Component({
  selector: 'prf-teacher-student-page',
  templateUrl: './teacher-student-page.component.html',
  styleUrls: ['./teacher-student-page.component.scss'],
})
export class TeacherStudentPageComponent implements OnInit {
  @Input() pupil: any;
  favorites: any;
  portfolios: any;
  favoriteProfessions: any = [];
  favoritesClasses: any = [];
  activeProfession: any;
  detailsMenu = [
    { key: 'results', name: 'Таланты' },
    { key: 'motivation', name: 'Учебная мотивация' },
    { key: 'values', name: 'Ценности' },
    { key: 'favoritesProfessions', name: 'Избранное' },
    { key: 'profile', name: 'Профиль' },
  ];
  selectedMenuItem: any = this.detailsMenu[0];
  userRole: string;

  public sessionList: IHistory[];
  public motivationLastSessionId: string;
  public valuesLastSessionId: string;

  public isLoaded: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private goalsService: GoalsService,
    private userProfileService: UserProfileService,
    private portfolioService: PortfolioService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private historyService: HistoryService,
    private favoritesService: FavoritesService,
  ) {
    this.userRole = localStorage.getItem('userRole');
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(
        switchMap(r => {
          return this.loadHistoryTests();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        this.isLoaded = true;
      });
    this.favoritesService
      .getUserFavorites(this.pupil.userId)
      .pipe(
        switchMap(favorites => {
          this.favorites = favorites;

          let currentObservable$: Observable<any> = of(null);

          let favoritesProfessionsIds = this.favorites.filter(el => el.productType === FavoritesTypes.Profession).map(el => el.productId);

          if (favoritesProfessionsIds.length) {
            currentObservable$ = this.favoritesService.getUserFavoritesProfessions(favoritesProfessionsIds).pipe(
              tap(r => {
                this.favoriteProfessions = r;
              }),
            );
          }

          return currentObservable$.pipe(
            switchMap(() => {
              this.activeProfession = this.favoriteProfessions[0];
              this.favoritesClasses = this.favorites.filter(el => el.productType !== FavoritesTypes.Profession);

              return this.portfolioService.getPortfolio(this.pupil.userId).pipe(tap(r => (this.portfolios = r)));
            }),
          );
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  private loadHistoryTests(): Observable<any> {
    return this.historyService.getSessionList(this.pupil.userId).pipe(
      tap(r => {
        this.sessionList = r;
        let motivationSessions = this.sessionList
          .filter(session => session.screeningTestId === TESTS_VARIANTS_IDS.motivationtest && session.completed)
          .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));

        let valuesSessions = this.sessionList
          .filter(session => session.screeningTestId === TESTS_VARIANTS_IDS.valuesTest && session.completed)
          .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));

        this.motivationLastSessionId = motivationSessions && motivationSessions.length ? motivationSessions[0]!.sessionId : null;

        this.valuesLastSessionId = valuesSessions && valuesSessions.length ? valuesSessions[0]!.sessionId : null;
      }),
    );
  }

  setActiveProfession(profession: any) {
    this.activeProfession = profession;
  }

  selectMenuItem(menuItem: any) {
    return (this.selectedMenuItem = menuItem);
  }

  portfoliosChange(portfolios: any) {
    return (this.portfolios = portfolios);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
