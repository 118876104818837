<div class="header-testing">
  <a routerLink="/{{ userRole }}" class="testing-logo-link w-inline-block">
    <img src="{{ profilumLogoUrl }}" />
    <!-- <img src="/assets/images/logo_menu.svg"> -->
  </a>
</div>
<div class="content-section testing-content">
  <div class="container-prestige container container-845">
    <h1>{{ 'SHARED.THANKS' | translate }}!</h1>
    <p>
      {{ 'TEST_AND_RESULTS.REVIEW_GRATITUDE' | translate }}
    </p>
    <a [routerLink]="['/pupil']" class="button testing-button w-button">{{ 'TEST_AND_RESULTS.EXIT' | translate }}</a>
  </div>
</div>
