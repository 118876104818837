import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { COURSES_FORMAT_DATA } from 'app/shared/global-constants/constants';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FiltersPromotestCoursesService } from '../filters-promotest-courses.service';

// в запросе по фильтрам фомировать объект, чтобы исключать поля null undefined
@Component({
  selector: 'prf-filters-format',
  templateUrl: './filters-format.component.html',
  styleUrls: ['./filters-format.component.scss'],
})
export class FiltersFormatComponent implements OnInit {
  filters: IFilterClasses;
  formats = COURSES_FORMAT_DATA;
  classes: any = [];
  formatOpen: boolean = false;
  selectFormat: string;
  @ViewChild('formatFilter') formatFilter: ElementRef;

  public tag: string;

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(private filtersService: FiltersPromotestCoursesService) {
    this.tag = localStorage.getItem('tag');
  }

  ngOnInit() {
    this.filtersService
      .getCoursesFilters()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        this.filters = data;
      });
  }

  setFormatsFilter(format: any) {
    this.filters.format = format.value;
    this.filtersService.setCoursesFilter(this.filters);
    this.formatOpen = false;
  }

  deselectFormat() {
    this.filters.format = null;
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkFilterHeader() {
    if (this.filters.format) {
      let format = this.formats.find(el => el.value === this.filters.format);
      return format.viewValue;
    } else {
      return 'Формат';
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.formatFilter && !this.formatFilter?.nativeElement.contains(target)) {
      this.formatOpen = false;
    }
  }
}
