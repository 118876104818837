import { Component, Input } from '@angular/core';

@Component({
  selector: 'prf-recommended-classes',
  templateUrl: './recommended-classes.component.html',
  styleUrls: ['./recommended-classes.component.scss'],
})
export class RecommendedClassesComponent {
  @Input() set testResults(val: any) {
    this._testResults = val;
    this.setByResult();
  }

  public recommendedClasses: Array<any>;
  public colorList: any = ['green', 'purple', 'blue', 'turquoise', 'yellow', 'orange', 'brown', ''];

  private _testResults: any;

  public setByResult() {
    if (this._testResults && this._testResults.length > 0) {
      let classes = this._testResults.filter(d => d.objectType === 'ProfilClass' && d.results.length);
      this.recommendedClasses = classes.sort((a, b) => (a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1));
      this.recommendedClasses = this.recommendedClasses.slice(0, 3);
    }
  }
}
