import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class GoalRecommendationService {
  constructor(private http: HttpService) {}

  getRecommendations(recommendationType: string): Observable<any> {
    return this.http.get('/b2c/v1.0/saas/getuserrecommendations?recommendationType=' + recommendationType).pipe(map(r => r));
  }
}
