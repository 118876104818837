<ng-container>
  <div class="loader-wrapper">
    <div class="loadingio-spinner-spin-7r4u7e5tvu">
      <div class="ldio-vvw3hsbhf9p">
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
      </div>
    </div>
  </div>
</ng-container>
