<div class="reports-page" [formGroup]="form">
  <section>
    <div class="section-title">Отчет по региону</div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="region"
        [label]="'SHARED.REGIONS' | translate"
        [options]="listedRegions"
      ></prf-dropdown-with-search>
    </div>
    <div class="section-row">
      <a
        class="button-animate btn-activate"
        [class.btn-waiting]="regionReportUpdating"
        [class.disabled]="!form.get('region').value || regionReportPrepearing"
        (click)="updateRegionReport()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Получен">Обновить данные</span></a
      >
      <a
        class="button-animate btn-activate"
        [class.btn-waiting]="regionReportPrepearing"
        [class.disabled]="!form.get('region').value || regionReportUpdating"
        (click)="getRegionReport()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Получен">Получить</span></a
      >
    </div>
  </section>
  <section>
    <div class="section-title">Ученики по региону</div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="pupilsRegion"
        [label]="'SHARED.REGIONS' | translate"
        [options]="listedRegions"
      ></prf-dropdown-with-search>
    </div>
    <div class="section-row">
      <a
        class="btn-prf-outline"
        class="button-animate btn-activate"
        [class.btn-waiting]="regionPupilsUpdating"
        [class.disabled]="!form.get('pupilsRegion').value || regionPupilsPrepearing"
        (click)="updateRegionPupilsReport()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Получен">Обновить данные</span></a
      >
      <a
        class="btn-prf-outline"
        class="button-animate btn-activate"
        [class.btn-waiting]="regionPupilsPrepearing"
        [class.disabled]="!form.get('pupilsRegion').value || regionPupilsUpdating"
        (click)="getRegionPupilsReport()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Получен">Получить</span></a
      >
    </div>
  </section>

  <section formGroupName="municipalityReport">
    <div class="section-title">Отчет по муниципалитету</div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="region"
        [label]="'SHARED.REGIONS' | translate"
        [options]="listedRegions"
      ></prf-dropdown-with-search>
    </div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="municipality"
        [label]="'SHARED.MUNICIPALITIES' | translate"
        [options]="listedMunicipalities"
      ></prf-dropdown-with-search>
    </div>
    <div class="section-row">
      <a
        class="btn-prf-outline"
        class="button-animate btn-activate"
        [class.btn-waiting]="municipalityReportUpdating"
        [class.disabled]="!form.get('municipalityReport').get('municipality').value || municipalityReportPrepearing"
        (click)="updateMunicipalityReport()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Получен">Обновить данные</span></a
      >
      <a
        class="btn-prf-outline"
        class="button-animate btn-activate"
        [class.btn-waiting]="municipalityReportPrepearing"
        [class.disabled]="!form.get('municipalityReport').get('municipality').value || municipalityReportUpdating"
        (click)="getMunicipalityReport()"
      >
        <span class="btn-icon"></span>
        <span class="btn-text" data-wait="Подождите" data-after="Получен">Получить</span></a
      >
    </div>
  </section>

  <section>
    <div class="section-title">Обновить данные по профессиям</div>
    <a class="btn-prf-outline" class="button-animate btn-activate" [class.btn-waiting]="professionsUpdating" (click)="updateProfessions()">
      <span class="btn-icon"></span>
      <span class="btn-text" data-wait="Подождите" data-after="Получен">Обновить данные</span></a
    >
  </section>
</div>
