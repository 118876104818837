<!-- SEARCH -->
<!-- <prf-search-internships (searchesData)="dataSearch($event)"></prf-search-internships> -->

<div class="events-filters"></div>

<!--VIEW SETTINGS-->
<div class="internships-row-1">
  <div class="internships-number"></div>
  <div class="internships-column-1">
    <div class="gender-choice-row">
      <a (click)="viewValue = false" [class.active]="viewValue === false" #grid class="gender-button w-inline-block">
        <img src="/assets/images/courses/list-view-1-white.svg" />
      </a>
      <a (click)="viewValue = true" [class.active]="viewValue === true" #list class="gender-button right w-inline-block">
        <img src="/assets/images/courses/list-view-2-white.svg" />
      </a>
    </div>
  </div>
</div>
