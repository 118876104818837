<prf-add-school class="prf-add-school-correct" [inputRegions]="allRegions" (updateValue)="schoolAdded($event)"> </prf-add-school>

<div class="schools-list" [formGroup]="form">
  <h2>{{ 'SHARED.LIST_OF_SCHOOLS' | translate }}</h2>

  <div class="utils-bar">
    <div class="terr-object-selection">
      <div class="selectRegion">
        <div>
          <label for="region" class="login-label">{{ 'SHARED.REGION' | translate }}</label>
          <div class="div-space"></div>
        </div>
        <div class="dropdown-inline cat" #regionFilter>
          <div class="dropdown-inline-container w-dropdown" [ngClass]="{ disabled: adminLevel != adminAccessLevel.GLOBAL }">
            <div
              class="dd-input-field"
              #regionField
              (click)="regionDrop.classList.toggle('w--open'); regionField.classList.toggle('opened')"
            >
              <div class="login-field w-input" [class.active]="f.region.value">
                <span *ngIf="f.region.value">{{ f.region.value.name }}</span>
              </div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #regionDrop>
              <div class="dd-prof-filter-div">
                <div class="filter-list-row">
                  <div class="filter-list-heading">{{ 'SHARED.REGION' | translate }}</div>
                </div>
                <div class="prof-categories-links">
                  <a
                    class="prof-categories-link w-inline-block"
                    *ngFor="let region of allRegions"
                    (click)="selectRegion(region); regionDrop.classList.remove('w--open'); regionField.classList.remove('opened')"
                  >
                    <div class="category-text-link">{{ region.name }}</div>
                  </a>
                </div>
                <div class="filter-list-reset" (click)="resetData(); regionDrop.classList.remove('w--open')">
                  {{ 'SHARED.ALL_REGIONS' | translate }}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div class="selectMunicipality" *ngIf="f.region.value">
        <div>
          <label for="municipality" class="login-label">Муниципалитет</label>
          <div class="div-space"></div>
        </div>
        <div class="dropdown-inline cat" #municipalityFilter>
          <div class="dropdown-inline-container w-dropdown" [ngClass]="{ disabled: adminLevel == adminAccessLevel.MUNICIPALITY }">
            <div
              class="dd-input-field"
              #municipalityField
              (click)="municipalityDrop.classList.toggle('w--open'); municipalityField.classList.toggle('opened')"
            >
              <div class="login-field w-input" [class.active]="f.municipality.value">
                <span *ngIf="f.municipality.value">{{ f.municipality.value.name }}</span>
              </div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #municipalityDrop>
              <div class="dd-prof-filter-div">
                <div class="filter-list-row">
                  <div class="filter-list-heading">Муниципалитет</div>
                </div>
                <div class="prof-categories-links">
                  <a class="clear-categories" [class.show]="f.municipality.value" (click)="deselectMunicipality()">{{
                    'SHARED.CLEAR_BUTTON' | translate
                  }}</a>
                  <a
                    class="prof-categories-link w-inline-block"
                    *ngFor="let municipality of municipalitiesByRegion"
                    (click)="
                      selectMunicipality(municipality);
                      municipalityDrop.classList.remove('w--open');
                      municipalityField.classList.remove('opened')
                    "
                  >
                    <div class="category-text-link">{{ municipality.name }}</div>
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div class="selectCity" *ngIf="f.municipality.value">
        <div>
          <label for="city" class="login-label">Город</label>
          <div class="div-space"></div>
        </div>
        <div class="dropdown-inline cat" #cityFilter>
          <div class="dropdown-inline-container w-dropdown">
            <div class="dd-input-field" #cityField (click)="cityDrop.classList.toggle('w--open'); cityField.classList.toggle('opened')">
              <div class="login-field w-input" [class.active]="f.city.value">
                <span *ngIf="f.city.value">{{ f.city.value.name }}</span>
              </div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #cityDrop>
              <div class="dd-prof-filter-div">
                <div class="filter-list-row">
                  <div class="filter-list-heading">Город</div>
                </div>
                <div class="prof-categories-links">
                  <a class="clear-categories" [class.show]="f.city.value" (click)="deselectCity()">{{
                    'SHARED.CLEAR_BUTTON' | translate
                  }}</a>
                  <a
                    class="prof-categories-link w-inline-block"
                    *ngFor="let city of citiesByMunicipality"
                    (click)="selectCity(city); cityDrop.classList.remove('w--open'); cityField.classList.remove('opened')"
                  >
                    <div class="category-text-link">{{ city.name }}</div>
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div class="selectYear">
        <div>
          <label for="year" class="login-label">{{ 'SHARED.YEAR' | translate }}</label>
          <div class="div-space"></div>
        </div>
        <div class="dropdown-inline cat" #yearFilter>
          <div class="dropdown-inline-container w-dropdown" [ngClass]="{ disabled: adminLevel != adminAccessLevel.GLOBAL }">
            <div class="dd-input-field" #yearField (click)="yearDrop.classList.toggle('w--open'); yearField.classList.toggle('opened')">
              <div class="login-field w-input" [class.active]="f.year.value">
                <span *ngIf="f.year.value">{{ f.year.value.name }}</span>
              </div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #yearDrop>
              <div class="dd-prof-filter-div">
                <div class="filter-list-row">
                  <div class="filter-list-heading">{{ 'SHARED.YEAR' | translate }}</div>
                </div>
                <div class="prof-categories-links">
                  <a
                    class="prof-categories-link w-inline-block"
                    *ngFor="let year of reportYears"
                    (click)="setYear(year); yearDrop.classList.remove('w--open'); yearField.classList.remove('opened')"
                  >
                    <div class="category-text-link">{{ year.name }}</div>
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <!-- <div class="reportDate">
        <div>
          <label for="reportDate" class="login-label">Дата</label>
          <div class="div-space"></div>
        </div>
        <input
          type="date"
          class="create-event-field reportDate w-input"
          maxlength="256"
          name="reportDate"
          data-name="reportDate"
          id="reportDate"
          #myDate
          [value]="reportDate | date: 'yyyy-MM-dd'"
          (input)="reportDate = $event.target.value"
          (change)="setDate()"
        />
      </div> -->
    </div>
  </div>

  <table *ngIf="schoolsStatistic && schoolsStatisticCounters">
    <tr>
      <td>
        <b>Всего школ: {{ schoolsStatisticCounters.schoolsCount }}</b>
      </td>
      <td><b></b></td>
      <td>
        <b>{{ schoolsStatisticCounters.pupilsCounter }}</b>
      </td>
      <td>
        <b>{{ schoolsStatisticCounters.regPupilsCounter }}</b>
      </td>
      <td *ngIf="regionLanguages.native">
        <b>{{ schoolsStatisticCounters.testedPupilsRussianCounter }}</b>
      </td>
      <td *ngIf="regionLanguages.native">
        <b>{{ schoolsStatisticCounters.testedPupilsForeignCounter }}</b>
      </td>
      <td *ngIf="!regionLanguages.native">
        <b>{{ schoolsStatisticCounters.testedPupilsCounter }}</b>
      </td>
      <td>
        <!-- Пока не приходит общая статистика по родителям -->
        <b>0</b>
      </td>
      <td>
        <b>{{ schoolsStatisticCounters.classesCounter }}</b>
      </td>
      <td>
        <b>{{ schoolsStatisticCounters.teachersCounter }}</b>
      </td>
      <td><b></b></td>
    </tr>
    <tr>
      <td
        class="school-list_table-header"
        [ngClass]="{ 'active-sorting': selectedIndex == 1, switched: sortSelectedSchoolName == true }"
        (click)="sortByParamName('school')"
      >
        <b>Название</b>
      </td>
      <td><b>Город</b></td>
      <td
        class="school-list_table-header"
        [ngClass]="{
          'active-sorting': selectedIndex == 2,
          switched: sortSelectedTotalPupils == true
        }"
        (click)="sortByParamName('pupils')"
      >
        <b>Учеников</b>
      </td>
      <td
        class="school-list_table-header"
        [ngClass]="{
          'active-sorting': selectedIndex == 3,
          switched: sortSelectedTotalRegPupils == true
        }"
        (click)="sortByParamName('regPupils')"
      >
        <b>Зарегистрировано</b>
      </td>

      <td
        *ngIf="regionLanguages.native"
        class="school-list_table-header"
        [ngClass]="{
          'active-sorting': selectedIndex == 4,
          switched: sortSelectedTestCompletedRussian == true
        }"
        (click)="sortByParamName('testCompletedRussian')"
      >
        <b class="block">Прошло тест на русском языке</b>
      </td>

      <td
        *ngIf="regionLanguages.native"
        class="school-list_table-header"
        [ngClass]="{
          'active-sorting': selectedIndex == 7,
          switched: sortSelectedTestCompletedForeign == true
        }"
        (click)="sortByParamName('testCompletedForeign')"
      >
        <b class="block">Прошло тест на иностранном языке </b>
      </td>

      <td
        *ngIf="!regionLanguages.native"
        class="school-list_table-header"
        [ngClass]="{
          'active-sorting': selectedIndex == 4,
          switched: sortSelectedTestCompleted == true
        }"
        (click)="sortByParamName('testCompleted')"
      >
        <b class="block">Прошло тест</b>
      </td>

      <td
        class="school-list_table-header"
        [ngClass]="{
          'active-sorting': selectedIndex == 8,
          switched: sortSelectedTotalRegParents == true
        }"
        (click)="sortByParamName('parents')"
      >
        <b class="block">Родителей</b>
      </td>

      <td
        class="school-list_table-header"
        [ngClass]="{
          'active-sorting': selectedIndex == 5,
          switched: sortSelectedTotalClasses == true
        }"
        (click)="sortByParamName('classes')"
      >
        <b>Классов</b>
      </td>
      <td
        class="school-list_table-header"
        [ngClass]="{
          'active-sorting': selectedIndex == 6,
          switched: sortSelectedTotalTeachers == true
        }"
        (click)="sortByParamName('teachers')"
      >
        <b>Учителей</b>
      </td>
      <td><b>Редактирование школ</b></td>
    </tr>
    <tr *ngFor="let school of schoolsStatistic">
      <td>
        <a [routerLink]="['./' + school.schoolId]">{{ school.schoolName }}</a>
      </td>
      <td>{{ school.schoolCity }}</td>
      <td>{{ school.totalPupilsCount }}</td>
      <td>{{ school.registeredPupilsCount }}</td>
      <td *ngIf="regionLanguages.native">{{ school.completedRussianTestsCount }}</td>
      <td *ngIf="regionLanguages.native">{{ school.completedForeignTestsCount }}</td>
      <td *ngIf="!regionLanguages.native">{{ school.completedTestsCount }}</td>
      <td>{{ school.parentsCount }}</td>
      <td>{{ school.classesCount }}</td>
      <td>{{ school.teachersCount }}</td>
      <td>
        <button (click)="edit(school.schoolId)">{{ 'SHARED.EDIT_BUTTON' | translate }}</button>
        <button (click)="deleteConfirming = !deleteConfirming; schoolToDeleteId = school.schoolId">
          {{ 'BUTTONS.DELETE' | translate }}
        </button>
      </td>
    </tr>
  </table>
</div>

<ng-container *ngIf="editing">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="editing = !editing">
        <img src="/assets/images/icons/close-lb.svg" alt="{{ 'SHARED.CLOSE_WINDOW' | translate }}" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column">
          <h3 class="task-lb-heading">Изменение школы «{{ currentSchool.schoolName }}»</h3>
          <prf-edit-school [setCurrentSchool]="currentSchool" (closeEditing)="closeEditing($event)"> </prf-edit-school>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="deleteConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="deleteConfirming = !deleteConfirming">
        <img src="/assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Удаление школы</h4>
          <div class="task-result-text">Вы уверены, что хотите удалить школу?</div>
          <a class="button attach-file-btn w-button" (click)="deleteSchool()">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
