<ng-container *ngIf="dataFetched">
  <prf-add-user
    (updateValue)="processUserAdd($event)"
    [inputInstitutions]="institutions"
    [inputRegions]="allRegions"
    [inputCities]="allCities"
    [inputSchools]="allSchools"
  >
  </prf-add-user>
  <prf-edit-user
    *ngIf="editUserFlag"
    (updateValue)="processUserEdit($event)"
    (editUserFlag)="editFlag($event)"
    [inputOldUserData]="selectedUser"
    [inputUserRole]="selectedRole"
  >
  </prf-edit-user>

  <div class="userSearch" [formGroup]="form">
    <div class="personalInfo filtersColumn">
      <mat-form-field>
        <mat-select placeholder="Роль" formControlName="role" name="role" [class.error-field]="submitted && f.role.errors" required>
          <mat-option *ngFor="let role of userRoles" [value]="role">
            {{ role.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.role.errors?.required && submitted"> Обязательное поле </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text" class="w-input" name="lastName" placeholder="Фамилия" formControlName="lastName" />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          type="text"
          class="w-input"
          name="firstName"
          placeholder="Имя "
          formControlName="firstName"
          (focusin)="showInfo = true"
        />
        <ng-container *ngIf="showInfo">
          <span class="info-row">Поиск по имени доступен при указании фамилии</span>
        </ng-container>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text" class="w-input" name="email" placeholder="Email" formControlName="email" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" class="w-input" name="code" placeholder="Регистрационный код" formControlName="code" />
      </mat-form-field>
    </div>

    <div class="territoryInfo filtersColumn">
      <mat-form-field>
        <mat-select placeholder="Регион" formControlName="region" name="allRegions">
          <mat-option *ngFor="let region of allRegions" [value]="region" (click)="selectRegion(region)">
            {{ region.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Муниципалитет" formControlName="municipality" [disabled]="!f.region.value" name="municipality">
          <mat-option *ngFor="let municipality of municipalitiesByRegion" [value]="municipality" (click)="selectMunicipality(municipality)">
            {{ municipality.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Город" formControlName="city" [disabled]="!f.municipality.value" name="cities">
          <mat-option *ngFor="let city of citiesByMunicipality" [value]="city" (click)="setCity(city)">
            {{ city.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Школа" formControlName="school" [disabled]="!f.city.value" name="schools">
          <mat-option *ngFor="let school of schoolsFiltered" [value]="school">
            {{ school.number }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <a class="admin-add-event w-button" (click)="findUsers()"> Найти </a>
  <a class="admin-add-event w-button reset-button" (click)="resetFilters()"> Сбросить фильтры </a>

  <section class="users-count" *ngIf="users">Количество найденных пользователей: {{ users.length }}</section>
  <section class="users-list" *ngIf="users && users.length">
    <h2>{{ 'SHARED.USERS' | translate }}</h2>
    <div class="example-action-buttons">
      <button mat-button (click)="accordion.openAll()">Раскрыть все</button>
      <button mat-button (click)="accordion.closeAll()">Свернуть все</button>
    </div>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table">
          <div class="row table-header">
            <div [style.width.px]="40" class="cell">№</div>
            <div [style.width.px]="220" class="cell">EMAIL</div>
            <div [style.width.px]="130" class="cell">{{ 'SHARED.FORM.FAMILY' | translate }}</div>
            <div [style.width.px]="100" class="cell">{{ 'SHARED.FORM.NAME' | translate }}</div>
            <div [style.width.px]="150" class="cell">
              {{ 'SHARED.FORM.MIDDLENAME' | translate }}
            </div>
            <div [style.width.px]="80" class="cell">Рег.код</div>
            <!--          <div class="cell">Активирован?</div>-->
            <!--          <div class="cell">Тест<br />пройден?</div>-->
            <!--          <div class="cell">Школа</div>-->
            <!--          <div class="cell">Класс</div>-->
            <div [style.width.px]="130" class="cell" *ngIf="isAdminGlobal()">Редактировать</div>
            <div [style.width.px]="100" class="cell" *ngIf="isAdminGlobal()">Сброс пароля</div>
            <div [style.width.px]="100" class="cell">Тег</div>
          </div>

          <!--<div class="row" *ngFor="let user of users; let count = index">
          <div class="cell" data-title="count">{{ count + 1 }}</div>
          <div class="cell" data-title="email">{{ user.email }}</div>
          <div class="cell" data-title="lastName">{{ user.lastName }}</div>
          <div class="cell" data-title="firstName">{{ user.firstName }}</div>
          <div class="cell" data-title="middleName">{{ user.middleName }}</div>
          <div class="cell" data-title="registrationCode">{{ user.registrationCode }}</div>
          <div class="cell" data-title="isActivated">
            <span *ngIf="user.isActivated === true" class="done-icon"></span>
            <span *ngIf="user.isActivated === false" class="not-icon"></span>
          </div>
          <div class="cell" data-title="isTested">
            <span *ngIf="user.isTested === true" class="done-icon"></span>
            <span *ngIf="user.isTested === false" class="not-icon"></span>
          </div>
          <div class="cell" data-title="schoolName">{{ schoolsByIds.get(user.schoolId) }}</div>
          <div class="cell" data-title="className">{{ user.className }}</div>
          <div class="cell" data-title="SETTINGS" *ngIf="isAdminGlobal()">
            <div class="settings-wrapper">
              <a
                (click)="showEditUser(user, f.role.value)"
                class="user-edit-btn w-inline-block"
              ></a>
              <a
                class="delete-user w-inline-block"
                (click)="selectDeletedUser(user, f.role.value)"
              ></a>
            </div>
          </div>
          <div class="cell" data-title="ResetPassword" *ngIf="isAdminGlobal()">
            <button
              *ngIf="user.registrationCode && user.isActivated"
              mat-stroked-button
              (click)="openResetPopUp(user.email)"
            >
              Сброс
            </button>
          </div>
          <div class="cell" data-title="Tag">{{ user.tag }}</div>
        </div>-->

          <mat-accordion multi>
            <mat-expansion-panel *ngFor="let user of users; let count = index" hideToggle>
              <mat-expansion-panel-header class="row">
                <div [style.width.px]="40" class="cell" data-title="count">{{ count + 1 }}</div>
                <div [style.width.px]="220" class="cell" data-title="email">{{ user.email }}</div>
                <div [style.width.px]="130" class="cell" data-title="lastName">
                  {{ user.lastName }}
                </div>
                <div [style.width.px]="100" class="cell" data-title="firstName">
                  {{ user.firstName }}
                </div>
                <div [style.width.px]="150" class="cell" data-title="middleName">
                  {{ user.middleName }}
                </div>
                <div [style.width.px]="80" class="cell" data-title="registrationCode">
                  {{ user.registrationCode }}
                </div>
                <!--              <div class="cell" data-title="isActivated">-->
                <!--                <span *ngIf="user.isActivated === true" class="done-icon"></span>-->
                <!--                <span *ngIf="user.isActivated === false" class="not-icon"></span>-->
                <!--              </div>-->
                <!--              <div class="cell" data-title="isTested">-->
                <!--                <span *ngIf="user.isTested === true" class="done-icon"></span>-->
                <!--                <span *ngIf="user.isTested === false" class="not-icon"></span>-->
                <!--              </div>-->
                <!--              <div class="cell" data-title="schoolName">{{ schoolsByIds.get(user.schoolId) }}</div>-->
                <!--              <div class="cell" data-title="className">{{ user.className }}</div>-->
                <div [style.width.px]="130" class="cell" data-title="SETTINGS" *ngIf="isAdminGlobal()">
                  <div class="settings-wrapper">
                    <a (click)="showEditUser(user, f.role.value)" class="user-edit-btn w-inline-block"></a>
                    <a class="delete-user w-inline-block" (click)="selectDeletedUser(user, f.role.value)"></a>
                  </div>
                </div>
                <div [style.width.px]="100" class="cell" data-title="ResetPassword" *ngIf="isAdminGlobal()">
                  <button
                    *ngIf="user.registrationCode && user.isActivated"
                    mat-stroked-button
                    (click)="openResetPopUp(user.email)"
                    class="reset-password"
                  >
                    Сброс
                  </button>
                </div>
                <div [style.width.px]="100" class="cell" data-title="Tag">{{ user.tag }}</div>
              </mat-expansion-panel-header>
              <div class="desc-block">
                <h3 class="desc-block__title">Дополнительная информация</h3>
                <div class="desc-block__row">
                  <div class="desc-block__label">Регистрационный код:</div>
                  <div class="desc-block__state" data-title="registrationCode">
                    {{ user.registrationCode ? user.registrationCode : '-' }}
                  </div>
                </div>
                <div class="desc-block__row">
                  <div class="desc-block__label">Номер телефона:</div>
                  <div class="desc-block__state" data-title="registrationCode">
                    {{ user.phoneNumber ? user.phoneNumber : '-' }}
                  </div>
                </div>
                <div class="desc-block__row">
                  <div class="desc-block__label">Активирован:</div>
                  <div class="desc-block__state" data-title="isActivated">
                    <span *ngIf="user.isActivated === true" class="done-icon"></span>
                    <span *ngIf="user.isActivated === false" class="not-icon"></span>
                  </div>
                </div>
                <div class="desc-block__row">
                  <div class="desc-block__label">Тестирован:</div>
                  <div class="desc-block__state" data-title="isTested">
                    <span *ngIf="user.isTested === true" class="done-icon"></span>
                    <span *ngIf="user.isTested === false" class="not-icon"></span>
                  </div>
                </div>
                <div class="desc-block__row">
                  <div class="desc-block__label">Школа:</div>
                  <div class="desc-block__state" data-title="schoolName">
                    {{ user.schoolId ? schoolsByIds.get(user.schoolId) : 'Не определенна' }}
                  </div>
                </div>
                <div class="desc-block__row">
                  <div class="desc-block__label">Класс:</div>
                  <div class="desc-block__state" data-title="className">
                    {{ user.className ? user.className : 'Не определен' }}
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </section>

  <ng-container *ngIf="showDeletePopUp">
    <div class="popup-overlay overlay">
      <div class="popup-container">
        <div class="popup-title">Удаление пользователя:</div>
        <div class="popup-content">
          <div class="content-text">
            <div class="popup-text _1">
              {{ deletedUser.lastName }} {{ deletedUser.firstName }} {{ deletedUser.middleName }}
              {{ deletedUser.registrationCode }}
            </div>
            <div class="popup-text _1">
              Вы уверены, что хотите удалить данного пользователя? После удаления доступ к данным будет потерян.
            </div>

            <div class="buttons">
              <a class="button w-button popup-button _red" (click)="totalDeleteUser(deletedUser.userId)"> Удалить </a>
              <a class="button w-button popup-button _white" (click)="closeDeletePopUp()"> Отмена </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showResetPasswordPopUp">
    <div class="popup-overlay overlay">
      <div class="popup-container">
        <div class="popup-title">Сброс пароля:</div>
        <div class="popup-content">
          <div class="content-text">
            <div class="popup-text _1">Вы уверены, что хотите сбросить пароль данного пользователя?</div>
            <div class="buttons">
              <a class="button w-button popup-button _red" (click)="resetPasswordToCode()"> Сбросить </a>
              <a class="button w-button popup-button _white" (click)="closeResetPasswordPopUp()"> Отмена </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- <section class="toggle-control">
    <mat-button-toggle-group #group="matButtonToggleGroup" value="schooladmins">
      <mat-button-toggle value="schooladmins" (click)="selectRole('schoolAdmin')">
        <i class="fas fa-user-secret"></i>{{ 'SHARED.SCHOOL_ADMINISTRATORS' | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="directors" (click)="selectRole('director')">
        <i class="fas fa-user-graduate"></i>{{ 'SHARED.DIRECTORS' | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="admindos" (click)="selectRole('adminDO')">
        <i class="fas fa-user-graduate"></i>{{ 'SHARED.DO_ADMINISTRATORS' | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="admins" (click)="selectRole('admin')">
        <i class="fas fa-user-graduate"></i>{{ 'SHARED.REGION_ADMINISTRATORS' | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="employers" (click)="selectRole('employers')">
        <i class="fas fa-user-graduate"></i>Работодатели
      </mat-button-toggle>
      <mat-button-toggle value="tutors" (click)="selectRole('tutors')">
        <i class="fas fa-user-graduate"></i>Тьюторы
      </mat-button-toggle>
    </mat-button-toggle-group>
  </section> -->

  <!-- <section class="users-list" *ngIf="group.value == 'schooladmins'">
    <h2>{{ 'SHARED.SCHOOL_ADMINISTRATORS' | translate }}</h2>
    <div class="wrapper">
      <div class="table">
        <div class="row table-header">
          <div class="cell">№</div>
          <div class="cell">EMAIL</div>
          <div class="cell">{{ 'SHARED.FORM.FAMILY' | translate }}</div>
          <div class="cell">{{ 'SHARED.FORM.NAME' | translate }}</div>
          <div class="cell">{{ 'SHARED.FORM.MIDDLENAME' | translate }}</div>
          <div class="cell">Регистрационный Код</div>
          <div class="cell">Активирован?</div>
          <div class="cell">Школа</div>
          <div class="cell" *ngIf="isAdminGlobal()">Редактировать</div>
        </div>
        <div class="row" *ngFor="let schoolAdmin of schoolAdminsView; let count = index">
          <div class="cell" data-title="count">{{ count + 1 }}</div>
          <div class="cell" data-title="email">{{ schoolAdmin.email }}</div>
          <div class="cell" data-title="lastName">{{ schoolAdmin.lastName }}</div>
          <div class="cell" data-title="firstName">{{ schoolAdmin.firstName }}</div>
          <div class="cell" data-title="middleName">{{ schoolAdmin.middleName }}</div>
          <div class="cell" data-title="registrationCode">{{ schoolAdmin.registrationCode }}</div>
          <div class="cell" data-title="isActivated">{{ schoolAdmin.isActivated }}</div>
          <div class="cell" data-title="schoolName">{{ schoolAdmin.schoolName }}</div>
          <div class="cell" data-title="SETTINGS" *ngIf="isAdminGlobal()">
            <a
              (click)="showEditUser(schoolAdmin, 'schoolAdmin')"
              class="user-edit-btn w-inline-block"
            ></a>
            <a
              class="delete-user w-inline-block"
              (click)="selectDeletedUser(schoolAdmin, 'schoolAdmin')"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="users-list" *ngIf="group.value == 'directors'">
    <h2>{{ 'SHARED.DIRECTORS' | translate }}</h2>
    <div class="wrapper">
      <div class="table">
        <div class="row table-header">
          <div class="cell">№</div>
          <div class="cell">EMAIL</div>
          <div class="cell">{{ 'SHARED.FORM.FAMILY' | translate }}</div>
          <div class="cell">{{ 'SHARED.FORM.NAME' | translate }}</div>
          <div class="cell">{{ 'SHARED.FORM.MIDDLENAME' | translate }}</div>
          <div class="cell">Регистрационный Код</div>
          <div class="cell">Активирован?</div>
          <div class="cell">Школа</div>
          <div *ngIf="isAdminGlobal()" class="cell">Редактировать</div>
        </div>
        <div class="row" *ngFor="let director of directorsView; let count = index">
          <div class="cell" data-title="count">{{ count + 1 }}</div>
          <div class="cell" data-title="email">{{ director.email }}</div>
          <div class="cell" data-title="lastName">{{ director.lastName }}</div>
          <div class="cell" data-title="firstName">{{ director.firstName }}</div>
          <div class="cell" data-title="middleName">{{ director.middleName }}</div>
          <div class="cell" data-title="registrationCode">{{ director.registrationCode }}</div>
          <div class="cell" data-title="isActivated">{{ director.isActivated }}</div>
          <div class="cell" data-title="schoolName">{{ director.schoolName }}</div>
          <div class="cell" data-title="SETTINGS" *ngIf="isAdminGlobal()">
            <a
              (click)="showEditUser(director, 'director')"
              class="user-edit-btn w-inline-block"
            ></a>
            <a
              class="delete-user w-inline-block"
              (click)="selectDeletedUser(director, 'director')"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="users-list" *ngIf="group.value == 'admindos'">
    <h2>{{ 'SHARED.DO_ADMINISTRATORS' | translate }}</h2>
    <div class="wrapper">
      <div class="table">
        <div class="row table-header">
          <div class="cell">№</div>
          <div class="cell">EMAIL</div>
          <div class="cell">{{ 'SHARED.FORM.FAMILY' | translate }}</div>
          <div class="cell">{{ 'SHARED.FORM.NAME' | translate }}</div>
          <div class="cell">{{ 'SHARED.FORM.MIDDLENAME' | translate }}</div>
          <div class="cell">Регистрационный Код</div>
          <div class="cell">Активирован?</div>
          <div class="cell">Центр ДО</div>
        </div>
        <div class="row" *ngFor="let adminDO of adminDOUsersView; let count = index">
          <div class="cell" data-title="count">{{ count + 1 }}</div>
          <div class="cell" data-title="email">{{ adminDO.email }}</div>
          <div class="cell" data-title="lastName">{{ adminDO.lastName }}</div>
          <div class="cell" data-title="firstName">{{ adminDO.firstName }}</div>
          <div class="cell" data-title="middleName">{{ adminDO.middleName }}</div>
          <div class="cell" data-title="registrationCode">{{ adminDO.registrationCode }}</div>
          <div class="cell" data-title="isActivated">{{ adminDO.isActivated }}</div>
          <div class="cell" data-title="institutionName">{{ adminDO.institutionName }}</div>
        </div>
      </div>
    </div>
  </section>

  <section class="users-list" *ngIf="group.value == 'admins'">
    <h2>{{ 'SHARED.REGION_ADMINISTRATORS' | translate }}</h2>
    <div class="wrapper">
      <div class="table">
        <div class="row table-header">
          <div class="cell">№</div>
          <div class="cell">EMAIL</div>
          <div class="cell">{{ 'SHARED.FORM.FAMILY' | translate }}</div>
          <div class="cell">{{ 'SHARED.FORM.NAME' | translate }}</div>
          <div class="cell">{{ 'SHARED.FORM.MIDDLENAME' | translate }}</div>
          <div class="cell">Регистрационный Код</div>
          <div class="cell">Активирован?</div>
          <div class="cell">{{ 'SHARED.REGION' | translate }}</div>
          <div class="cell">Муниципалитет</div>
        </div>
        <div class="row" *ngFor="let admin of adminsView; let count = index">
          <div class="cell" data-title="count">{{ count + 1 }}</div>
          <div class="cell" data-title="email">{{ admin.email }}</div>
          <div class="cell" data-title="lastName">{{ admin.lastName }}</div>
          <div class="cell" data-title="firstName">{{ admin.firstName }}</div>
          <div class="cell" data-title="middleName">{{ admin.middleName }}</div>
          <div class="cell" data-title="registrationCode">{{ admin.registrationCode }}</div>
          <div class="cell" data-title="isActivated">{{ admin.isActivated }}</div>
          <div class="cell" data-title="regionName">{{ admin.regionName }}</div>
          <div class="cell" data-title="municipalityName">{{ admin.municipalityName }}</div>
        </div>
      </div>
    </div>
  </section>

  <section class="users-list" *ngIf="group.value == 'employers'">
    <h2>Работодатели</h2>
    <div class="wrapper">
      <div class="table" *ngIf="employersView.length">
        <div class="row table-header">
          <div class="cell">№</div>
          <div class="cell">EMAIL</div>
          <div class="cell">Фамилия</div>
          <div class="cell">Имя</div>
          <div class="cell">Отчество</div>
          <div class="cell">Компания</div>
          <div class="cell">Активирован?</div>
        </div>
        <div class="row" *ngFor="let employer of employersView; let count = index">
          <div class="cell" data-title="count">{{ count + 1 }}</div>
          <div class="cell" data-title="email">{{ employer.email }}</div>
          <div class="cell" data-title="lastName">{{ employer.lastName }}</div>
          <div class="cell" data-title="firstName">{{ employer.firstName }}</div>
          <div class="cell" data-title="middleName">{{ employer.middleName }}</div>
          <div class="cell" data-title="companyName">{{ employer.companyName }}</div>
          <div class="cell" data-title="isActivated">{{ employer.isActivated }}</div>
        </div>
      </div>
    </div>
  </section>

  <section class="users-list" *ngIf="group.value == 'tutors'">
    <h2>Тьюторы</h2>
    <div class="wrapper">
      <div class="table" *ngIf="tutorsView.length">
        <div class="row table-header">
          <div class="cell">№</div>
          <div class="cell">EMAIL</div>
          <div class="cell">{{ 'SHARED.FORM.FAMILY' | translate }}</div>
          <div class="cell">{{ 'SHARED.FORM.NAME' | translate }}</div>
          <div class="cell">{{ 'SHARED.FORM.MIDDLENAME' | translate }}</div>
          <div class="cell">Регистрационный Код</div>
        </div>
        <div class="row" *ngFor="let tutor of tutorsView; let count = index">
          <div class="cell" data-title="count">{{ count + 1 }}</div>
          <div class="cell" data-title="email">{{ tutor.email }}</div>
          <div class="cell" data-title="lastName">{{ tutor.lastName }}</div>
          <div class="cell" data-title="firstName">{{ tutor.firstName }}</div>
          <div class="cell" data-title="middleName">{{ tutor.middleName }}</div>
          <div class="cell" data-title="registrationCode">{{ tutor.registrationCode }}</div>
        </div>
      </div>
    </div>
  </section> -->
</ng-container>
