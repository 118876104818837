<prf-menu [menuAllowed]="menuAllowed" [setUserProfile]="userProfile" [root]="'parent'"></prf-menu>

<div class="content-section">
  <prf-page-header [title]="' '"></prf-page-header>
  <div class="container">
    <div class="registrations-steps-wrapper">
      <div class="registrations-steps">
        <div class="step-item" *ngFor="let step of registrationSteps; let i = index">
          <div class="step-number-container">
            <div class="step-number" [class.active]="step.status === 'active' || step.status === 'done'">
              {{ i + 1 }}
            </div>
          </div>
          <div class="step-name" [class.active]="step.status === 'active'" [class.done]="step.status === 'done'">
            {{ step.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-content">
      <div class="page-tab">
        <ng-container *ngIf="child; else registrChild">
          <div class="invite-child-container">
            <h1 class="invite-child-h1">Поделитесь ссылкой</h1>
            <div class="invite-child-text">Отправьте ссылку ребенку для входа <br />любым удобным способом...</div>
            <div class="invite-child-buttons">
              <div class="button-copy-container">
                <button
                  class="button-copy button-new"
                  [class.default-cursor]="child.registrationCode"
                  (click)="copyRegLink()"
                  (mouseover)="showToolTip = true"
                  (mouseout)="showToolTip = false"
                >
                  Скопировать ссылку
                </button>
                <div class="info-popup" #infoPopup *ngIf="showToolTip || linkIsCopied">
                  <p *ngIf="!linkIsCopied">Сгенерировать ссылку для регистрации ребенка</p>
                  <p *ngIf="linkIsCopied">Ссылка скопирована</p>

                  <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                </div>
              </div>
              <div class="button-share-container">
                <button class="button-share" (click)="openSharing()">Поделиться через...</button>
                <a class="share-link share-link_wp" href="https://wa.me/send?text={{ registrationPupilLink }}" target="_blank"
                  ><span class="share-link__text_hidden">WhatsApp</span></a
                >
                <a class="share-link share-link_tg" href="https://t.me/share/url?url={{ registrationPupilLink }}" target="_blank"
                  ><span class="share-link__text_hidden">Telegram</span></a
                >
                <a class="share-link share-link_email" href="mailto:?body={{ registrationPupilLink }}" target="_blank"
                  ><span class="share-link__text_hidden">Email</span></a
                >
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #registrChild> <prf-child-add-form (addedChild)="updateChild($event)"></prf-child-add-form></ng-template>
      </div>
    </div>

    <prf-page-footer> </prf-page-footer>
  </div>
</div>

<!-- <div class="invite-parent-container">
  <div class="invite-parent-heading">
    <h3 class="h3-invite-parent">Вы великолепны!</h3>
    <p class="invite-parent-text">Регистрация почти завершена, осталось два шага</p>
  </div>
  <div class="invite-parent-content">
    <div class="invite-parent-row">
      <div class="add-child-column">
        <div class="add-child-section _invite">
          <h1 class="h1-invite-parent">Пригласите ребёнка</h1>
          <p class="invite-parent-text">
            Отправьте ссылку ребёнку для входа любым удобным способом...
          </p>
          <div class="link-container">
            {{ registrationPupilLink }}
          </div>
          <div class="share-button">
            <a class="button" (click)="openSharing()">Отправить</a>
            <a
              class="share-link share-link_wp"
              href="https://wa.me/send?text={{ registrationPupilLink }}"
              target="_blank"
              ><span class="share-link__text_hidden">WhatsApp</span></a
            >
            <a
              class="share-link share-link_tg"
              href="https://t.me/share/url?url={{ registrationPupilLink }}"
              target="_blank"
              ><span class="share-link__text_hidden">Telegram</span></a
            >
            <a
              class="share-link share-link_email"
              href="mailto:?body={{ registrationPupilLink }}"
              target="_blank"
              ><span class="share-link__text_hidden">Email</span></a
            >
          </div>
          <p class="invite-parent-subtext">
            или позовите ребенка, и он<br />
            поможет вам поделиться ссылкой :)
          </p>
        </div>
        <div class="add-child-section _child-testing">
          <h1 class="h1-invite-parent">Попросите ребенка пройти тестирование</h1>
          <p class="invite-parent-text">
            Не закрывайте эту страницу: для вас диагностика станет доступной после того, как ребенок
            пройдет регистрацию и тестирование.
          </p>
        </div>
        <div class="add-child-section _parent-testing">
          <h1 class="h1-invite-parent">Пройдите тестирование для родителя</h1>
          <p class="invite-parent-text">
            Это интересно! Познакомьтесь с результатами или запишитесь на консультацию к
            специалисту, чтобы узнать больше.
          </p>
        </div>
      </div>
      <img src="/assets/images/parent/Parent.svg" alt="" class="invite-parent-image add-child-img" />
    </div>
  </div>
</div> -->

<!-- <div class="lb-block-invite-welcome">
  <div class="invite-parent-row">
    <div class="add-child-column">
      <h3 class="h3-invite-parent">{{ 'SHARED.SMALL_BUSINESS' | translate }}</h3>
      <p class="invite-parent-text">Для регистрации скопируйте этот код и передайте ребёнку</p>
      <div class="child-codes">
        <div class="info-div">
          <button
            class="info-icon"
            [class.default-cursor]="child.registrationCode"
            (click)="generateRegLink()"
            (mouseover)="showToolTip = true"
            (mouseout)="showToolTip = false"
          ></button>
          <div class="info-popup" *ngIf="showToolTip">
            <p *ngIf="!child.registrationCode">Этот код является паролем при регистрации.</p>
            <ng-container *ngIf="child.registrationCode">
              <p *ngIf="!linkIsCopied">Сгенерировать ссылку для регистрации ребенка</p>
              <p *ngIf="linkIsGenerated">Ссылка скопирована</p>
            </ng-container>

            <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
          </div>
        </div>
        <div class="profile-code">
          <div>Код ребенка</div>
          <div class="code-text">{{ child.registrationCode }}</div>
        </div>
      </div>
    </div>

    <img src="/assets/images/parent/Parent.svg" alt="" class="invite-parent-image add-child-img" />
  </div>
</div> -->
