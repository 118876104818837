<div *ngIf="loaded" class="tasks-tabs">
  <div class="task-bubbles-container">
    <div class="task-title-bubbles">{{ 'SHARED.ADDED' | translate }}</div>
    <svg width="345" height="30" viewBox="0 0 345 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#CDCDCD" />
      <path d="M13.88 19V14.44L12.69 15.26L11.57 13.52L14.08 11.92H16.28V19H13.88Z" fill="#353535" />
      <circle *ngIf="userTrajectories[0]" cx="15" cy="15" r="15" fill="#8A6FE7" />
      <path d="M13.88 19V14.44L12.69 15.26L11.57 13.52L14.08 11.92H16.28V19H13.88Z" fill="white" />
      <circle cx="60" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M56.66 17.15L59.46 14.91C59.72 14.71 59.85 14.5033 59.85 14.29C59.85 14.15 59.8033 14.0333 59.71 13.94C59.6233 13.84 59.4967 13.79 59.33 13.79C59.1367 13.79 58.9767 13.8667 58.85 14.02C58.7233 14.1733 58.6533 14.38 58.64 14.64L56.37 14.38C56.4567 13.5133 56.8 12.8533 57.4 12.4C58 11.9467 58.71 11.72 59.53 11.72C59.8967 11.72 60.2433 11.7633 60.57 11.85C60.8967 11.9367 61.1967 12.07 61.47 12.25C61.75 12.4233 61.97 12.6633 62.13 12.97C62.2967 13.2767 62.38 13.63 62.38 14.03C62.38 14.3967 62.3067 14.73 62.16 15.03C62.02 15.3233 61.8633 15.5533 61.69 15.72C61.5167 15.8867 61.2867 16.07 61 16.27L59.96 17.01H62.4V19H56.66V17.15Z"
        fill="#353535"
      />
      <rect x="33" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="userTrajectories[1]" cx="60" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M56.66 17.15L59.46 14.91C59.72 14.71 59.85 14.5033 59.85 14.29C59.85 14.15 59.8033 14.0333 59.71 13.94C59.6233 13.84 59.4967 13.79 59.33 13.79C59.1367 13.79 58.9767 13.8667 58.85 14.02C58.7233 14.1733 58.6533 14.38 58.64 14.64L56.37 14.38C56.4567 13.5133 56.8 12.8533 57.4 12.4C58 11.9467 58.71 11.72 59.53 11.72C59.8967 11.72 60.2433 11.7633 60.57 11.85C60.8967 11.9367 61.1967 12.07 61.47 12.25C61.75 12.4233 61.97 12.6633 62.13 12.97C62.2967 13.2767 62.38 13.63 62.38 14.03C62.38 14.3967 62.3067 14.73 62.16 15.03C62.02 15.3233 61.8633 15.5533 61.69 15.72C61.5167 15.8867 61.2867 16.07 61 16.27L59.96 17.01H62.4V19H56.66V17.15Z"
        fill="white"
      />
      <rect *ngIf="userTrajectories[1]" x="33" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="105" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M106.33 15.25V15.29C106.69 15.4033 106.98 15.61 107.2 15.91C107.427 16.2033 107.54 16.52 107.54 16.86C107.54 17.3867 107.39 17.8333 107.09 18.2C106.79 18.56 106.423 18.8167 105.99 18.97C105.557 19.1233 105.087 19.2 104.58 19.2C103.8 19.2 103.107 19.0167 102.5 18.65C101.9 18.2833 101.51 17.74 101.33 17.02L103.55 16.5C103.59 16.6867 103.69 16.85 103.85 16.99C104.017 17.13 104.217 17.2 104.45 17.2C104.61 17.2 104.753 17.1633 104.88 17.09C105.007 17.01 105.07 16.8867 105.07 16.72C105.07 16.52 104.983 16.3833 104.81 16.31C104.637 16.2367 104.38 16.2 104.04 16.2H103.36V14.56H104.12C104.68 14.56 104.96 14.39 104.96 14.05C104.96 13.91 104.903 13.8067 104.79 13.74C104.683 13.6733 104.553 13.64 104.4 13.64C104.227 13.64 104.07 13.6933 103.93 13.8C103.797 13.9067 103.713 14.0567 103.68 14.25L101.47 13.78C101.69 13.0667 102.087 12.5467 102.66 12.22C103.24 11.8867 103.887 11.72 104.6 11.72C104.933 11.72 105.257 11.76 105.57 11.84C105.89 11.92 106.19 12.04 106.47 12.2C106.75 12.36 106.977 12.58 107.15 12.86C107.323 13.14 107.41 13.4567 107.41 13.81C107.41 14.19 107.31 14.5033 107.11 14.75C106.91 14.99 106.65 15.1567 106.33 15.25Z"
        fill="#353535"
      />
      <rect x="78" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="userTrajectories[2]" cx="105" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M106.33 15.25V15.29C106.69 15.4033 106.98 15.61 107.2 15.91C107.427 16.2033 107.54 16.52 107.54 16.86C107.54 17.3867 107.39 17.8333 107.09 18.2C106.79 18.56 106.423 18.8167 105.99 18.97C105.557 19.1233 105.087 19.2 104.58 19.2C103.8 19.2 103.107 19.0167 102.5 18.65C101.9 18.2833 101.51 17.74 101.33 17.02L103.55 16.5C103.59 16.6867 103.69 16.85 103.85 16.99C104.017 17.13 104.217 17.2 104.45 17.2C104.61 17.2 104.753 17.1633 104.88 17.09C105.007 17.01 105.07 16.8867 105.07 16.72C105.07 16.52 104.983 16.3833 104.81 16.31C104.637 16.2367 104.38 16.2 104.04 16.2H103.36V14.56H104.12C104.68 14.56 104.96 14.39 104.96 14.05C104.96 13.91 104.903 13.8067 104.79 13.74C104.683 13.6733 104.553 13.64 104.4 13.64C104.227 13.64 104.07 13.6933 103.93 13.8C103.797 13.9067 103.713 14.0567 103.68 14.25L101.47 13.78C101.69 13.0667 102.087 12.5467 102.66 12.22C103.24 11.8867 103.887 11.72 104.6 11.72C104.933 11.72 105.257 11.76 105.57 11.84C105.89 11.92 106.19 12.04 106.47 12.2C106.75 12.36 106.977 12.58 107.15 12.86C107.323 13.14 107.41 13.4567 107.41 13.81C107.41 14.19 107.31 14.5033 107.11 14.75C106.91 14.99 106.65 15.1567 106.33 15.25Z"
        fill="white"
      />
      <rect *ngIf="userTrajectories[2]" x="78" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="150" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M151.88 17.75V19H149.72V17.75H146.31V15.81L148.99 11.92H151.87V15.89H152.83V17.75H151.88ZM149.75 14.1H149.71L148.53 15.89H149.75V14.1Z"
        fill="#353535"
      />
      <rect x="123" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="userTrajectories[3]" cx="150" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M151.88 17.75V19H149.72V17.75H146.31V15.81L148.99 11.92H151.87V15.89H152.83V17.75H151.88ZM149.75 14.1H149.71L148.53 15.89H149.75V14.1Z"
        fill="white"
      />
      <rect *ngIf="userTrajectories[3]" x="123" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="195" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M197.5 16.62C197.5 17.0867 197.41 17.4967 197.23 17.85C197.057 18.1967 196.823 18.4633 196.53 18.65C196.237 18.8367 195.923 18.9733 195.59 19.06C195.263 19.1533 194.917 19.2 194.55 19.2C193.797 19.2 193.117 19.0033 192.51 18.61C191.903 18.2167 191.537 17.6867 191.41 17.02L193.61 16.46C193.65 16.66 193.75 16.83 193.91 16.97C194.07 17.11 194.257 17.18 194.47 17.18C194.91 17.18 195.13 17 195.13 16.64C195.13 16.24 194.71 16.04 193.87 16.04C193.35 16.04 192.733 16.11 192.02 16.25L192.27 11.92H197.08V13.9H194.28L194.24 14.41C194.453 14.3767 194.63 14.36 194.77 14.36C195.557 14.36 196.207 14.55 196.72 14.93C197.24 15.31 197.5 15.8733 197.5 16.62Z"
        fill="#353535"
      />
      <rect x="168" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="userTrajectories[4]" cx="195" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M197.5 16.62C197.5 17.0867 197.41 17.4967 197.23 17.85C197.057 18.1967 196.823 18.4633 196.53 18.65C196.237 18.8367 195.923 18.9733 195.59 19.06C195.263 19.1533 194.917 19.2 194.55 19.2C193.797 19.2 193.117 19.0033 192.51 18.61C191.903 18.2167 191.537 17.6867 191.41 17.02L193.61 16.46C193.65 16.66 193.75 16.83 193.91 16.97C194.07 17.11 194.257 17.18 194.47 17.18C194.91 17.18 195.13 17 195.13 16.64C195.13 16.24 194.71 16.04 193.87 16.04C193.35 16.04 192.733 16.11 192.02 16.25L192.27 11.92H197.08V13.9H194.28L194.24 14.41C194.453 14.3767 194.63 14.36 194.77 14.36C195.557 14.36 196.207 14.55 196.72 14.93C197.24 15.31 197.5 15.8733 197.5 16.62Z"
        fill="white"
      />
      <rect *ngIf="userTrajectories[4]" x="168" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="240" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M240.07 14.14H240.18C240.833 14.14 241.4 14.3533 241.88 14.78C242.36 15.2067 242.6 15.78 242.6 16.5C242.6 17.3533 242.3 18.0167 241.7 18.49C241.107 18.9633 240.373 19.2 239.5 19.2C238.62 19.2 237.883 18.97 237.29 18.51C236.697 18.05 236.4 17.3867 236.4 16.52C236.4 15.78 236.693 15.0033 237.28 14.19L238.9 11.92H241.79L240.16 14.03L240.07 14.14ZM239.5 15.8C239.287 15.8 239.11 15.87 238.97 16.01C238.837 16.1433 238.77 16.31 238.77 16.51C238.77 16.7167 238.837 16.89 238.97 17.03C239.11 17.17 239.287 17.24 239.5 17.24C239.72 17.24 239.9 17.17 240.04 17.03C240.18 16.89 240.25 16.7167 240.25 16.51C240.25 16.31 240.18 16.1433 240.04 16.01C239.9 15.87 239.72 15.8 239.5 15.8Z"
        fill="#353535"
      />
      <rect x="213" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="userTrajectories[5]" cx="240" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M240.07 14.14H240.18C240.833 14.14 241.4 14.3533 241.88 14.78C242.36 15.2067 242.6 15.78 242.6 16.5C242.6 17.3533 242.3 18.0167 241.7 18.49C241.107 18.9633 240.373 19.2 239.5 19.2C238.62 19.2 237.883 18.97 237.29 18.51C236.697 18.05 236.4 17.3867 236.4 16.52C236.4 15.78 236.693 15.0033 237.28 14.19L238.9 11.92H241.79L240.16 14.03L240.07 14.14ZM239.5 15.8C239.287 15.8 239.11 15.87 238.97 16.01C238.837 16.1433 238.77 16.31 238.77 16.51C238.77 16.7167 238.837 16.89 238.97 17.03C239.11 17.17 239.287 17.24 239.5 17.24C239.72 17.24 239.9 17.17 240.04 17.03C240.18 16.89 240.25 16.7167 240.25 16.51C240.25 16.31 240.18 16.1433 240.04 16.01C239.9 15.87 239.72 15.8 239.5 15.8Z"
        fill="white"
      />
      <rect *ngIf="userTrajectories[5]" x="213" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="285" cy="15" r="15" fill="#CDCDCD" />
      <path d="M284.92 19H282.16L284.85 13.9H281.81V11.92H287.45V13.61L284.92 19Z" fill="#353535" />
      <rect x="258" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="userTrajectories[6]" cx="285" cy="15" r="15" fill="#8A6FE7" />
      <path d="M284.92 19H282.16L284.85 13.9H281.81V11.92H287.45V13.61L284.92 19Z" fill="white" />
      <rect *ngIf="userTrajectories[6]" x="258" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="330" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M329.92 13.65C329.813 13.5433 329.673 13.49 329.5 13.49C329.327 13.49 329.187 13.5433 329.08 13.65C328.973 13.7567 328.92 13.8767 328.92 14.01C328.92 14.15 328.973 14.2767 329.08 14.39C329.187 14.4967 329.327 14.55 329.5 14.55C329.673 14.55 329.813 14.4967 329.92 14.39C330.027 14.2767 330.08 14.15 330.08 14.01C330.08 13.8767 330.027 13.7567 329.92 13.65ZM329.95 16.4C329.843 16.2867 329.693 16.23 329.5 16.23C329.307 16.23 329.153 16.2867 329.04 16.4C328.933 16.5133 328.88 16.6533 328.88 16.82C328.88 16.9867 328.933 17.1267 329.04 17.24C329.153 17.3467 329.307 17.4 329.5 17.4C329.693 17.4 329.843 17.3467 329.95 17.24C330.063 17.1267 330.12 16.9867 330.12 16.82C330.12 16.6533 330.063 16.5133 329.95 16.4ZM331.26 15.29V15.32C331.607 15.4467 331.89 15.6567 332.11 15.95C332.33 16.2433 332.44 16.58 332.44 16.96C332.44 17.7067 332.153 18.2667 331.58 18.64C331.007 19.0133 330.313 19.2 329.5 19.2C328.98 19.2 328.507 19.13 328.08 18.99C327.653 18.85 327.293 18.6067 327 18.26C326.707 17.9133 326.56 17.4833 326.56 16.97C326.56 16.5967 326.667 16.2667 326.88 15.98C327.093 15.6933 327.38 15.4767 327.74 15.33V15.3C327.433 15.1867 327.18 14.9967 326.98 14.73C326.78 14.4633 326.68 14.1567 326.68 13.81C326.68 13.4567 326.76 13.1433 326.92 12.87C327.08 12.59 327.293 12.37 327.56 12.21C327.833 12.0433 328.133 11.92 328.46 11.84C328.787 11.76 329.133 11.72 329.5 11.72C329.86 11.72 330.203 11.76 330.53 11.84C330.857 11.92 331.157 12.0433 331.43 12.21C331.703 12.37 331.92 12.59 332.08 12.87C332.24 13.1433 332.32 13.4567 332.32 13.81C332.32 14.17 332.223 14.48 332.03 14.74C331.837 15 331.58 15.1833 331.26 15.29Z"
        fill="#353535"
      />
      <rect x="303" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="userTrajectories[7]" cx="330" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M329.92 13.65C329.813 13.5433 329.673 13.49 329.5 13.49C329.327 13.49 329.187 13.5433 329.08 13.65C328.973 13.7567 328.92 13.8767 328.92 14.01C328.92 14.15 328.973 14.2767 329.08 14.39C329.187 14.4967 329.327 14.55 329.5 14.55C329.673 14.55 329.813 14.4967 329.92 14.39C330.027 14.2767 330.08 14.15 330.08 14.01C330.08 13.8767 330.027 13.7567 329.92 13.65ZM329.95 16.4C329.843 16.2867 329.693 16.23 329.5 16.23C329.307 16.23 329.153 16.2867 329.04 16.4C328.933 16.5133 328.88 16.6533 328.88 16.82C328.88 16.9867 328.933 17.1267 329.04 17.24C329.153 17.3467 329.307 17.4 329.5 17.4C329.693 17.4 329.843 17.3467 329.95 17.24C330.063 17.1267 330.12 16.9867 330.12 16.82C330.12 16.6533 330.063 16.5133 329.95 16.4ZM331.26 15.29V15.32C331.607 15.4467 331.89 15.6567 332.11 15.95C332.33 16.2433 332.44 16.58 332.44 16.96C332.44 17.7067 332.153 18.2667 331.58 18.64C331.007 19.0133 330.313 19.2 329.5 19.2C328.98 19.2 328.507 19.13 328.08 18.99C327.653 18.85 327.293 18.6067 327 18.26C326.707 17.9133 326.56 17.4833 326.56 16.97C326.56 16.5967 326.667 16.2667 326.88 15.98C327.093 15.6933 327.38 15.4767 327.74 15.33V15.3C327.433 15.1867 327.18 14.9967 326.98 14.73C326.78 14.4633 326.68 14.1567 326.68 13.81C326.68 13.4567 326.76 13.1433 326.92 12.87C327.08 12.59 327.293 12.37 327.56 12.21C327.833 12.0433 328.133 11.92 328.46 11.84C328.787 11.76 329.133 11.72 329.5 11.72C329.86 11.72 330.203 11.76 330.53 11.84C330.857 11.92 331.157 12.0433 331.43 12.21C331.703 12.37 331.92 12.59 332.08 12.87C332.24 13.1433 332.32 13.4567 332.32 13.81C332.32 14.17 332.223 14.48 332.03 14.74C331.837 15 331.58 15.1833 331.26 15.29Z"
        fill="white"
      />
      <rect *ngIf="userTrajectories[7]" x="303" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
    </svg>
  </div>
  <div class="task-bubbles-container">
    <div class="task-title-bubbles">Выполнено</div>
    <svg width="345" height="30" viewBox="0 0 345 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#CDCDCD" />
      <path d="M13.88 19V14.44L12.69 15.26L11.57 13.52L14.08 11.92H16.28V19H13.88Z" fill="#353535" />
      <circle *ngIf="isDoneData[0]" cx="15" cy="15" r="15" fill="#8A6FE7" />
      <path d="M13.88 19V14.44L12.69 15.26L11.57 13.52L14.08 11.92H16.28V19H13.88Z" fill="white" />
      <circle cx="60" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M56.66 17.15L59.46 14.91C59.72 14.71 59.85 14.5033 59.85 14.29C59.85 14.15 59.8033 14.0333 59.71 13.94C59.6233 13.84 59.4967 13.79 59.33 13.79C59.1367 13.79 58.9767 13.8667 58.85 14.02C58.7233 14.1733 58.6533 14.38 58.64 14.64L56.37 14.38C56.4567 13.5133 56.8 12.8533 57.4 12.4C58 11.9467 58.71 11.72 59.53 11.72C59.8967 11.72 60.2433 11.7633 60.57 11.85C60.8967 11.9367 61.1967 12.07 61.47 12.25C61.75 12.4233 61.97 12.6633 62.13 12.97C62.2967 13.2767 62.38 13.63 62.38 14.03C62.38 14.3967 62.3067 14.73 62.16 15.03C62.02 15.3233 61.8633 15.5533 61.69 15.72C61.5167 15.8867 61.2867 16.07 61 16.27L59.96 17.01H62.4V19H56.66V17.15Z"
        fill="#353535"
      />
      <rect x="33" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="isDoneData[1]" cx="60" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M56.66 17.15L59.46 14.91C59.72 14.71 59.85 14.5033 59.85 14.29C59.85 14.15 59.8033 14.0333 59.71 13.94C59.6233 13.84 59.4967 13.79 59.33 13.79C59.1367 13.79 58.9767 13.8667 58.85 14.02C58.7233 14.1733 58.6533 14.38 58.64 14.64L56.37 14.38C56.4567 13.5133 56.8 12.8533 57.4 12.4C58 11.9467 58.71 11.72 59.53 11.72C59.8967 11.72 60.2433 11.7633 60.57 11.85C60.8967 11.9367 61.1967 12.07 61.47 12.25C61.75 12.4233 61.97 12.6633 62.13 12.97C62.2967 13.2767 62.38 13.63 62.38 14.03C62.38 14.3967 62.3067 14.73 62.16 15.03C62.02 15.3233 61.8633 15.5533 61.69 15.72C61.5167 15.8867 61.2867 16.07 61 16.27L59.96 17.01H62.4V19H56.66V17.15Z"
        fill="white"
      />
      <rect *ngIf="isDoneData[1]" x="33" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="105" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M106.33 15.25V15.29C106.69 15.4033 106.98 15.61 107.2 15.91C107.427 16.2033 107.54 16.52 107.54 16.86C107.54 17.3867 107.39 17.8333 107.09 18.2C106.79 18.56 106.423 18.8167 105.99 18.97C105.557 19.1233 105.087 19.2 104.58 19.2C103.8 19.2 103.107 19.0167 102.5 18.65C101.9 18.2833 101.51 17.74 101.33 17.02L103.55 16.5C103.59 16.6867 103.69 16.85 103.85 16.99C104.017 17.13 104.217 17.2 104.45 17.2C104.61 17.2 104.753 17.1633 104.88 17.09C105.007 17.01 105.07 16.8867 105.07 16.72C105.07 16.52 104.983 16.3833 104.81 16.31C104.637 16.2367 104.38 16.2 104.04 16.2H103.36V14.56H104.12C104.68 14.56 104.96 14.39 104.96 14.05C104.96 13.91 104.903 13.8067 104.79 13.74C104.683 13.6733 104.553 13.64 104.4 13.64C104.227 13.64 104.07 13.6933 103.93 13.8C103.797 13.9067 103.713 14.0567 103.68 14.25L101.47 13.78C101.69 13.0667 102.087 12.5467 102.66 12.22C103.24 11.8867 103.887 11.72 104.6 11.72C104.933 11.72 105.257 11.76 105.57 11.84C105.89 11.92 106.19 12.04 106.47 12.2C106.75 12.36 106.977 12.58 107.15 12.86C107.323 13.14 107.41 13.4567 107.41 13.81C107.41 14.19 107.31 14.5033 107.11 14.75C106.91 14.99 106.65 15.1567 106.33 15.25Z"
        fill="#353535"
      />
      <rect x="78" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="isDoneData[2]" cx="105" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M106.33 15.25V15.29C106.69 15.4033 106.98 15.61 107.2 15.91C107.427 16.2033 107.54 16.52 107.54 16.86C107.54 17.3867 107.39 17.8333 107.09 18.2C106.79 18.56 106.423 18.8167 105.99 18.97C105.557 19.1233 105.087 19.2 104.58 19.2C103.8 19.2 103.107 19.0167 102.5 18.65C101.9 18.2833 101.51 17.74 101.33 17.02L103.55 16.5C103.59 16.6867 103.69 16.85 103.85 16.99C104.017 17.13 104.217 17.2 104.45 17.2C104.61 17.2 104.753 17.1633 104.88 17.09C105.007 17.01 105.07 16.8867 105.07 16.72C105.07 16.52 104.983 16.3833 104.81 16.31C104.637 16.2367 104.38 16.2 104.04 16.2H103.36V14.56H104.12C104.68 14.56 104.96 14.39 104.96 14.05C104.96 13.91 104.903 13.8067 104.79 13.74C104.683 13.6733 104.553 13.64 104.4 13.64C104.227 13.64 104.07 13.6933 103.93 13.8C103.797 13.9067 103.713 14.0567 103.68 14.25L101.47 13.78C101.69 13.0667 102.087 12.5467 102.66 12.22C103.24 11.8867 103.887 11.72 104.6 11.72C104.933 11.72 105.257 11.76 105.57 11.84C105.89 11.92 106.19 12.04 106.47 12.2C106.75 12.36 106.977 12.58 107.15 12.86C107.323 13.14 107.41 13.4567 107.41 13.81C107.41 14.19 107.31 14.5033 107.11 14.75C106.91 14.99 106.65 15.1567 106.33 15.25Z"
        fill="white"
      />
      <rect *ngIf="isDoneData[2]" x="78" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="150" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M151.88 17.75V19H149.72V17.75H146.31V15.81L148.99 11.92H151.87V15.89H152.83V17.75H151.88ZM149.75 14.1H149.71L148.53 15.89H149.75V14.1Z"
        fill="#353535"
      />
      <rect x="123" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="isDoneData[3]" cx="150" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M151.88 17.75V19H149.72V17.75H146.31V15.81L148.99 11.92H151.87V15.89H152.83V17.75H151.88ZM149.75 14.1H149.71L148.53 15.89H149.75V14.1Z"
        fill="white"
      />
      <rect *ngIf="isDoneData[3]" x="123" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="195" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M197.5 16.62C197.5 17.0867 197.41 17.4967 197.23 17.85C197.057 18.1967 196.823 18.4633 196.53 18.65C196.237 18.8367 195.923 18.9733 195.59 19.06C195.263 19.1533 194.917 19.2 194.55 19.2C193.797 19.2 193.117 19.0033 192.51 18.61C191.903 18.2167 191.537 17.6867 191.41 17.02L193.61 16.46C193.65 16.66 193.75 16.83 193.91 16.97C194.07 17.11 194.257 17.18 194.47 17.18C194.91 17.18 195.13 17 195.13 16.64C195.13 16.24 194.71 16.04 193.87 16.04C193.35 16.04 192.733 16.11 192.02 16.25L192.27 11.92H197.08V13.9H194.28L194.24 14.41C194.453 14.3767 194.63 14.36 194.77 14.36C195.557 14.36 196.207 14.55 196.72 14.93C197.24 15.31 197.5 15.8733 197.5 16.62Z"
        fill="#353535"
      />
      <rect x="168" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="isDoneData[4]" cx="195" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M197.5 16.62C197.5 17.0867 197.41 17.4967 197.23 17.85C197.057 18.1967 196.823 18.4633 196.53 18.65C196.237 18.8367 195.923 18.9733 195.59 19.06C195.263 19.1533 194.917 19.2 194.55 19.2C193.797 19.2 193.117 19.0033 192.51 18.61C191.903 18.2167 191.537 17.6867 191.41 17.02L193.61 16.46C193.65 16.66 193.75 16.83 193.91 16.97C194.07 17.11 194.257 17.18 194.47 17.18C194.91 17.18 195.13 17 195.13 16.64C195.13 16.24 194.71 16.04 193.87 16.04C193.35 16.04 192.733 16.11 192.02 16.25L192.27 11.92H197.08V13.9H194.28L194.24 14.41C194.453 14.3767 194.63 14.36 194.77 14.36C195.557 14.36 196.207 14.55 196.72 14.93C197.24 15.31 197.5 15.8733 197.5 16.62Z"
        fill="white"
      />
      <rect *ngIf="isDoneData[4]" x="168" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="240" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M240.07 14.14H240.18C240.833 14.14 241.4 14.3533 241.88 14.78C242.36 15.2067 242.6 15.78 242.6 16.5C242.6 17.3533 242.3 18.0167 241.7 18.49C241.107 18.9633 240.373 19.2 239.5 19.2C238.62 19.2 237.883 18.97 237.29 18.51C236.697 18.05 236.4 17.3867 236.4 16.52C236.4 15.78 236.693 15.0033 237.28 14.19L238.9 11.92H241.79L240.16 14.03L240.07 14.14ZM239.5 15.8C239.287 15.8 239.11 15.87 238.97 16.01C238.837 16.1433 238.77 16.31 238.77 16.51C238.77 16.7167 238.837 16.89 238.97 17.03C239.11 17.17 239.287 17.24 239.5 17.24C239.72 17.24 239.9 17.17 240.04 17.03C240.18 16.89 240.25 16.7167 240.25 16.51C240.25 16.31 240.18 16.1433 240.04 16.01C239.9 15.87 239.72 15.8 239.5 15.8Z"
        fill="#353535"
      />
      <rect x="213" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="isDoneData[5]" cx="240" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M240.07 14.14H240.18C240.833 14.14 241.4 14.3533 241.88 14.78C242.36 15.2067 242.6 15.78 242.6 16.5C242.6 17.3533 242.3 18.0167 241.7 18.49C241.107 18.9633 240.373 19.2 239.5 19.2C238.62 19.2 237.883 18.97 237.29 18.51C236.697 18.05 236.4 17.3867 236.4 16.52C236.4 15.78 236.693 15.0033 237.28 14.19L238.9 11.92H241.79L240.16 14.03L240.07 14.14ZM239.5 15.8C239.287 15.8 239.11 15.87 238.97 16.01C238.837 16.1433 238.77 16.31 238.77 16.51C238.77 16.7167 238.837 16.89 238.97 17.03C239.11 17.17 239.287 17.24 239.5 17.24C239.72 17.24 239.9 17.17 240.04 17.03C240.18 16.89 240.25 16.7167 240.25 16.51C240.25 16.31 240.18 16.1433 240.04 16.01C239.9 15.87 239.72 15.8 239.5 15.8Z"
        fill="white"
      />
      <rect *ngIf="isDoneData[5]" x="213" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="285" cy="15" r="15" fill="#CDCDCD" />
      <path d="M284.92 19H282.16L284.85 13.9H281.81V11.92H287.45V13.61L284.92 19Z" fill="#353535" />
      <rect x="258" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="isDoneData[6]" cx="285" cy="15" r="15" fill="#8A6FE7" />
      <path d="M284.92 19H282.16L284.85 13.9H281.81V11.92H287.45V13.61L284.92 19Z" fill="white" />
      <rect *ngIf="isDoneData[6]" x="258" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
      <circle cx="330" cy="15" r="15" fill="#CDCDCD" />
      <path
        d="M329.92 13.65C329.813 13.5433 329.673 13.49 329.5 13.49C329.327 13.49 329.187 13.5433 329.08 13.65C328.973 13.7567 328.92 13.8767 328.92 14.01C328.92 14.15 328.973 14.2767 329.08 14.39C329.187 14.4967 329.327 14.55 329.5 14.55C329.673 14.55 329.813 14.4967 329.92 14.39C330.027 14.2767 330.08 14.15 330.08 14.01C330.08 13.8767 330.027 13.7567 329.92 13.65ZM329.95 16.4C329.843 16.2867 329.693 16.23 329.5 16.23C329.307 16.23 329.153 16.2867 329.04 16.4C328.933 16.5133 328.88 16.6533 328.88 16.82C328.88 16.9867 328.933 17.1267 329.04 17.24C329.153 17.3467 329.307 17.4 329.5 17.4C329.693 17.4 329.843 17.3467 329.95 17.24C330.063 17.1267 330.12 16.9867 330.12 16.82C330.12 16.6533 330.063 16.5133 329.95 16.4ZM331.26 15.29V15.32C331.607 15.4467 331.89 15.6567 332.11 15.95C332.33 16.2433 332.44 16.58 332.44 16.96C332.44 17.7067 332.153 18.2667 331.58 18.64C331.007 19.0133 330.313 19.2 329.5 19.2C328.98 19.2 328.507 19.13 328.08 18.99C327.653 18.85 327.293 18.6067 327 18.26C326.707 17.9133 326.56 17.4833 326.56 16.97C326.56 16.5967 326.667 16.2667 326.88 15.98C327.093 15.6933 327.38 15.4767 327.74 15.33V15.3C327.433 15.1867 327.18 14.9967 326.98 14.73C326.78 14.4633 326.68 14.1567 326.68 13.81C326.68 13.4567 326.76 13.1433 326.92 12.87C327.08 12.59 327.293 12.37 327.56 12.21C327.833 12.0433 328.133 11.92 328.46 11.84C328.787 11.76 329.133 11.72 329.5 11.72C329.86 11.72 330.203 11.76 330.53 11.84C330.857 11.92 331.157 12.0433 331.43 12.21C331.703 12.37 331.92 12.59 332.08 12.87C332.24 13.1433 332.32 13.4567 332.32 13.81C332.32 14.17 332.223 14.48 332.03 14.74C331.837 15 331.58 15.1833 331.26 15.29Z"
        fill="#353535"
      />
      <rect x="303" y="13" width="9" height="3" rx="1.5" fill="#939393" />
      <circle *ngIf="isDoneData[7]" cx="330" cy="15" r="15" fill="#8A6FE7" />
      <path
        d="M329.92 13.65C329.813 13.5433 329.673 13.49 329.5 13.49C329.327 13.49 329.187 13.5433 329.08 13.65C328.973 13.7567 328.92 13.8767 328.92 14.01C328.92 14.15 328.973 14.2767 329.08 14.39C329.187 14.4967 329.327 14.55 329.5 14.55C329.673 14.55 329.813 14.4967 329.92 14.39C330.027 14.2767 330.08 14.15 330.08 14.01C330.08 13.8767 330.027 13.7567 329.92 13.65ZM329.95 16.4C329.843 16.2867 329.693 16.23 329.5 16.23C329.307 16.23 329.153 16.2867 329.04 16.4C328.933 16.5133 328.88 16.6533 328.88 16.82C328.88 16.9867 328.933 17.1267 329.04 17.24C329.153 17.3467 329.307 17.4 329.5 17.4C329.693 17.4 329.843 17.3467 329.95 17.24C330.063 17.1267 330.12 16.9867 330.12 16.82C330.12 16.6533 330.063 16.5133 329.95 16.4ZM331.26 15.29V15.32C331.607 15.4467 331.89 15.6567 332.11 15.95C332.33 16.2433 332.44 16.58 332.44 16.96C332.44 17.7067 332.153 18.2667 331.58 18.64C331.007 19.0133 330.313 19.2 329.5 19.2C328.98 19.2 328.507 19.13 328.08 18.99C327.653 18.85 327.293 18.6067 327 18.26C326.707 17.9133 326.56 17.4833 326.56 16.97C326.56 16.5967 326.667 16.2667 326.88 15.98C327.093 15.6933 327.38 15.4767 327.74 15.33V15.3C327.433 15.1867 327.18 14.9967 326.98 14.73C326.78 14.4633 326.68 14.1567 326.68 13.81C326.68 13.4567 326.76 13.1433 326.92 12.87C327.08 12.59 327.293 12.37 327.56 12.21C327.833 12.0433 328.133 11.92 328.46 11.84C328.787 11.76 329.133 11.72 329.5 11.72C329.86 11.72 330.203 11.76 330.53 11.84C330.857 11.92 331.157 12.0433 331.43 12.21C331.703 12.37 331.92 12.59 332.08 12.87C332.24 13.1433 332.32 13.4567 332.32 13.81C332.32 14.17 332.223 14.48 332.03 14.74C331.837 15 331.58 15.1833 331.26 15.29Z"
        fill="white"
      />
      <rect *ngIf="isDoneData[7]" x="303" y="13" width="9" height="3" rx="1.5" fill="#FF7AED" />
    </svg>
  </div>

  <div class="tasks-tabs-menu">
    <div class="tasks-tabs-menu-row">
      <a (click)="changeTaskLevel(1)" [class.w--current]="tasksLevel === 1" class="tasks-tab-link w-inline-block">
        <div [class.current]="tasksLevel === 1" class="task-tab-num">1</div>
        <div class="tasks-tab-link-text">{{ 'SHARED.INTRODUCTORY_LEVEL' | translate }}</div>
      </a>
      <a (click)="changeTaskLevel(2)" [class.w--current]="tasksLevel === 2" class="tasks-tab-link w-inline-block">
        <div [class.current]="tasksLevel === 2" class="task-tab-num">2</div>
        <div class="tasks-tab-link-text">Углубленный уровень</div>
      </a>
      <a class="tasks-tab-link locked w-inline-block">
        <div class="task-tab-num"><img src="/assets/images/icons/locked-padlock.svg" /></div>
        <div class="tasks-tab-link-text">Профессиональный уровень</div>
      </a>
    </div>
  </div>

  <ng-container *ngIf="tasksLevel === 1">
    <!--STATIC BLOCK TASKS-->
    <div class="task-container" *ngIf="staticTasksInTrajectory.length > 0">
      <h2 class="tasks-h2">У меня в самостоятельном изучении</h2>
      <div class="container-swiper">
        <swiper-container #swiperComponentStatic prfSwiperElement [config]="swiperConfig" init="false">
          <ng-container *ngFor="let tasksGroup of staticTasksInTrajectory">
            <swiper-slide class="events-column swiper-slide" *ngFor="let task of tasksGroup.programms; let i = index">
              <prf-plan-card [course]="task" (click)="StaticTaskModal(task)" [staticTask]="true" [index]="i"></prf-plan-card>
            </swiper-slide>
          </ng-container>
        </swiper-container>
        <a class="arrow-link prev w-inline-block" *ngIf="currentIndexCarouselStatic > 0" (click)="clickPrevStatic()">
          <img src="/assets/images/icons/back_1.svg" />
        </a>
        <a
          class="arrow-link next w-inline-block"
          *ngIf="currentIndexCarouselStatic < staticTasksInTrajectory.length - 1"
          (click)="clickNextStatic()"
        >
          <img src="/assets/images/icons/next_1.svg" />
        </a>
      </div>
      <div class="task-line"></div>
    </div>
    <!--FIRST BLOCK TASKS-->
    <div class="task-container" *ngIf="first_block[0] && first_block[0].programms.length > 0">
      <h2 class="tasks-h2">У меня в курсах</h2>
      <div class="container-swiper">
        <swiper-container #swiperComponentFirst prfSwiperElement [config]="swiperConfig" init="false">
          <ng-container *ngFor="let task of first_block">
            <swiper-slide class="events-column swiper-slide" *ngFor="let program of task.programms; let i = index">
              <prf-plan-card [course]="program"></prf-plan-card>
            </swiper-slide>
          </ng-container>
        </swiper-container>
        <a class="arrow-link prev w-inline-block" *ngIf="currentIndexCarouselFirst > 0" (click)="clickPrevFirst()">
          <img src="/assets/images/icons/back_1.svg" />
        </a>
        <a
          class="arrow-link next w-inline-block"
          *ngIf="currentIndexCarouselFirst < first_block[0].programms.length - 1"
          (click)="clickNextFirst()"
        >
          <img src="/assets/images/icons/next_1.svg" />
        </a>
      </div>
      <div class="task-line"></div>
    </div>
    <!--SECOND BLOCK TASKS-->
    <div class="task-container" *ngIf="second_block[0] && second_block[0].programms.length > 0">
      <h2 class="tasks-h2">У меня в мастер-классах и профпробах</h2>
      <div class="container-swiper">
        <swiper-container #swiperComponentSecond prfSwiperElement [config]="swiperConfig" init="false">
          <ng-container *ngFor="let task of second_block">
            <swiper-slide class="events-column swiper-slide" *ngFor="let program of task.programms">
              <prf-plan-card [course]="program"></prf-plan-card>
            </swiper-slide>
          </ng-container>
        </swiper-container>
        <a class="arrow-link prev w-inline-block" *ngIf="currentIndexCarouselSecond > 0" (click)="clickPrevSecond()">
          <img src="/assets/images/icons/back_1.svg" />
        </a>
        <a
          class="arrow-link next w-inline-block"
          *ngIf="currentIndexCarouselSecond < second_block[0].programms.length - 1"
          (click)="clickNextSecond()"
        >
          <img src="/assets/images/icons/next_1.svg" />
        </a>
      </div>
      <div class="task-line"></div>
    </div>
    <!--THIRD BLOCK TASKS-->
    <div class="task-container" *ngIf="third_block[0] && third_block[0].programms.length > 0">
      <h2 class="tasks-h2">У меня в встречах и экскурсиях</h2>
      <div class="container-swiper">
        <swiper-container
          #swiperComponentThirdswiperComponentThird
          prfSwiperElement
          [config]="swiperConfig"
          init="false"
          class="swiper swiper-container"
        >
          <ng-container *ngFor="let task of third_block">
            <swiper-slide class="events-column swiper-slide" *ngFor="let program of task.programms">
              <prf-plan-card [course]="program"></prf-plan-card>
            </swiper-slide>
          </ng-container>
        </swiper-container>
        <a class="arrow-link prev w-inline-block" *ngIf="currentIndexCarouselThird > 0" (click)="clickPrevThird()">
          <img src="/assets/images/icons/back_1.svg" />
        </a>
        <a
          class="arrow-link next w-inline-block"
          *ngIf="currentIndexCarouselThird < third_block[0].programms.length - 1"
          (click)="clickNextThird()"
        >
          <img src="/assets/images/icons/next_1.svg" />
        </a>
      </div>
      <div class="task-line"></div>
    </div>
    <!--FOURTH BLOCK TASKS-->
    <div class="task-container" *ngIf="fourth_block[0] && fourth_block[0].programms.length > 0">
      <h2 class="tasks-h2">У меня в фестивалях</h2>
      <div class="container-swiper">
        <swiper-container #swiperComponentFourth prfSwiperElement [config]="swiperConfig" init="false">
          <ng-container *ngFor="let task of fourth_block">
            <swiper-slide class="events-column swiper-slide" *ngFor="let program of task.programms">
              <prf-plan-card [course]="program"></prf-plan-card>
            </swiper-slide>
          </ng-container>
        </swiper-container>
        <a class="arrow-link prev w-inline-block" *ngIf="currentIndexCarouselFourth > 0" (click)="clickPrevFourth()">
          <img src="/assets/images/icons/back_1.svg" />
        </a>
        <a
          class="arrow-link next w-inline-block"
          *ngIf="currentIndexCarouselFourth < fourth_block[0].programms.length - 1"
          (click)="clickNextFourth()"
        >
          <img src="/assets/images/icons/next_1.svg" />
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="tasksLevel === 2">
    <!--STATIC BLOCK TASKS-->
    <div class="task-container" *ngIf="staticTasksInTrajectory.length > 0">
      <h2 class="tasks-h2">У меня в самостоятельном изучении</h2>
      <div class="container-swiper">
        <swiper-container #swiperComponentStatic prfSwiperElement [config]="swiperConfig" init="false">
          <ng-container *ngFor="let task of staticTasksInTrajectory">
            <swiper-slide class="events-column swiper-slide" *ngFor="let program of task.programms">
              <prf-plan-card [course]="program" [staticTask]="true"></prf-plan-card>
            </swiper-slide>
          </ng-container>
        </swiper-container>
        <a class="arrow-link prev w-inline-block" *ngIf="currentIndexCarouselStatic > 0" (click)="clickPrevStatic()">
          <img src="/assets/images/icons/back_1.svg" />
        </a>
        <a
          class="arrow-link next w-inline-block"
          *ngIf="currentIndexCarouselStatic < staticTasksInTrajectory[0].programms.length - 1"
          (click)="clickNextStatic()"
        >
          <img src="/assets/images/icons/next_1.svg" />
        </a>
      </div>
      <div class="task-line"></div>
    </div>
    <!--FIRST BLOCK TASKS-->
    <div class="task-container" *ngIf="first_block[0].programms.length > 0">
      <h2 class="tasks-h2">У меня в курсах</h2>
      <div class="container-swiper">
        <swiper-container #swiperComponentFirst prfSwiperElement [config]="swiperConfig" init="false">
          <ng-container *ngFor="let task of first_block">
            <swiper-slide class="events-column swiper-slide" *ngFor="let program of task.programms">
              <prf-plan-card [course]="program"></prf-plan-card>
            </swiper-slide>
          </ng-container>
        </swiper-container>
        <a class="arrow-link prev w-inline-block" *ngIf="currentIndexCarouselFirst > 0" (click)="clickPrevFirst()">
          <img src="/assets/images/icons/back_1.svg" />
        </a>
        <a
          class="arrow-link next w-inline-block"
          *ngIf="currentIndexCarouselFirst < first_block[0].programms.length - 1"
          (click)="clickNextFirst()"
        >
          <img src="/assets/images/icons/next_1.svg" />
        </a>
      </div>
      <div class="task-line"></div>
    </div>
    <!--SECOND BLOCK TASKS-->
    <div class="task-container" *ngIf="second_block[0].programms.length > 0">
      <h2 class="tasks-h2">У меня в мастер-классах и профпробах</h2>
      <div class="container-swiper">
        <swiper-container #swiperComponentSecond prfSwiperElement [config]="swiperConfig" init="false">
          <ng-container *ngFor="let task of second_block">
            <swiper-slide class="events-column swiper-slide" *ngFor="let program of task.programms">
              <prf-plan-card [course]="program"></prf-plan-card>
            </swiper-slide>
          </ng-container>
        </swiper-container>
        <a class="arrow-link prev w-inline-block" *ngIf="currentIndexCarouselSecond > 0" (click)="clickPrevSecond()">
          <img src="/assets/images/icons/back_1.svg" />
        </a>
        <a
          class="arrow-link next w-inline-block"
          *ngIf="currentIndexCarouselSecond < second_block[0].programms.length - 1"
          (click)="clickNextSecond()"
        >
          <img src="/assets/images/icons/next_1.svg" />
        </a>
      </div>
      <div class="task-line"></div>
    </div>
    <!--THIRD BLOCK TASKS-->
    <div class="task-container" *ngIf="third_block[0].programms.length > 0">
      <h2 class="tasks-h2">У меня в встречах и экскурсиях</h2>
      <div class="container-swiper">
        <swiper-container #swiperComponentThird prfSwiperElement [config]="swiperConfig" init="false">
          <ng-container *ngFor="let task of third_block">
            <swiper-slide class="events-column swiper-slide" *ngFor="let program of task.programms">
              <prf-plan-card [course]="program"></prf-plan-card>
            </swiper-slide>
          </ng-container>
        </swiper-container>
        <a class="arrow-link prev w-inline-block" *ngIf="currentIndexCarouselThird > 0" (click)="clickPrevThird()">
          <img src="/assets/images/icons/back_1.svg" />
        </a>
        <a
          class="arrow-link next w-inline-block"
          *ngIf="currentIndexCarouselThird < third_block[0].programms.length - 1"
          (click)="clickNextThird()"
        >
          <img src="/assets/images/icons/next_1.svg" />
        </a>
      </div>
      <div class="task-line"></div>
    </div>
    <!--FOURTH BLOCK TASKS-->
    <div class="task-container" *ngIf="fourth_block[0].programms.length > 0">
      <h2 class="tasks-h2">У меня в фестивалях</h2>
      <div class="container-swiper">
        <swiper-container #swiperComponentFourth prfSwiperElement [config]="swiperConfig" init="false">
          <ng-container *ngFor="let task of fourth_block">
            <swiper-slide class="events-column swiper-slide" *ngFor="let program of task.programms">
              <prf-plan-card [course]="program"></prf-plan-card>
            </swiper-slide>
          </ng-container>
        </swiper-container>
        <a class="arrow-link prev w-inline-block" *ngIf="currentIndexCarouselFourth > 0" (click)="clickPrevFourth()">
          <img src="/assets/images/icons/back_1.svg" />
        </a>
        <a
          class="arrow-link next w-inline-block"
          *ngIf="currentIndexCarouselFourth < fourth_block[0].programms.length - 1"
          (click)="clickNextFourth()"
        >
          <img src="/assets/images/icons/next_1.svg" />
        </a>
      </div>
    </div>
  </ng-container>
</div>
