<ng-container *ngIf="(userRole === 'parent' || userRole === 'pupil') && !isVGuser">
  <prf-menu [root]="userRole"></prf-menu>

  <div class="content-section">
    <prf-page-header [title]="' '"></prf-page-header>
    <div class="container container-partner-courses">
      <ng-container *ngIf="dataLoaded">
        <div class="header_buttons" *ngIf="!isMobile">
          <a class="header_button prt_button" (click)="returnToResults()">Результаты теста</a>
          <a class="header_button prt_button" (click)="navigateNewTest()">Пройти тест</a>
          <a class="header_button prt_button" routerLink="/mosruabout">Об акции</a>
        </div>

        <h3 class="results-title-h3">Развивайтесь вместе с акцией "Узнайте свои таланты"</h3>

        <div class="events-filters">
          <prf-filters-talents></prf-filters-talents>
          <prf-filters-price></prf-filters-price>
          <prf-filters-format></prf-filters-format>
          <prf-filters-group></prf-filters-group>
          <prf-filters-age></prf-filters-age>
          <prf-filters-date></prf-filters-date>
          <!-- <prf-filters-institutions></prf-filters-institutions> -->
        </div>
        <div class="partner-courses">
          <ng-container *ngTemplateOutlet="partnerCourses"></ng-container>
        </div>

        <prf-page-footer></prf-page-footer>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isVGuser">
  <prf-menu [root]="userRole"></prf-menu>

  <div class="content-section VGpage">
    <div class="container container-partner-courses">
      <ng-container *ngIf="dataLoaded">
        <ng-container *ngIf="dataLoaded && !isMobile">
          <div class="header_buttons">
            <a class="header_button prt_button" routerLink="/vorobievi-gori/promotest-results">Результаты теста</a>
            <a class="header_button _button" routerLink="/vorobievi-gori/mosrupromo">Пройти тест</a>
          </div>
          <h3 class="results-title-h3">Развивайтесь вместе с акцией "Узнайте свои таланты"</h3>
        </ng-container>

        <ng-container *ngIf="dataLoaded && isMobile">
          <div class="mobile-menu-button" (click)="showMobileMenu()">
            <div class="mobile-menu-icon-div">
              <div class="menu-line"></div>
              <div class="menu-line"></div>
            </div>
          </div>
          <h3 class="results-title-h3">Развивайтесь вместе с акцией "Узнайте свои таланты"</h3>
          <div class="clear_float"></div>
        </ng-container>
        <div class="events-filters">
          <prf-filters-talents></prf-filters-talents>
          <prf-filters-price></prf-filters-price>
          <prf-filters-format></prf-filters-format>
          <prf-filters-group></prf-filters-group>
          <prf-filters-age></prf-filters-age>
          <prf-filters-date></prf-filters-date>
        </div>
        <div class="partner-courses">
          <ng-container *ngTemplateOutlet="partnerCourses"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #partnerCourses>
  <div class="container-1050">
    <div class="courses-container">
      <ng-container *ngIf="allPartnersCoursesView && allPartnersCoursesView.length > 0; else emptyCourses">
        <prf-promotest-course-card *ngFor="let course of allPartnersCoursesView" [course]="course"></prf-promotest-course-card>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #emptyCourses>
  <div class="event-empty-block">
    <div>
      <img src="/assets/images/menu/grey/Courses.svg" width="80" class="no-profession-icon" *ngIf="isCourses" />
      <img src="/assets/images/menu/grey/Events.svg" width="80" class="no-profession-icon" *ngIf="!isCourses" />
    </div>
    <div class="event-empty-block-text">{{ 'SHARED.FOR_YOU_REQUEST_NOT_COURSES' | translate }}</div>
  </div>
</ng-template>
