import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { COURSES_TYPES } from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import * as moment from 'moment';
import { IClass } from '../../../../interfaces/iclass.interface';

import { CourseDetailsService } from '../../../../../pages/catalog/courses-page-without-filters/course-details/course-details.service';
import { MetroColors } from 'app/pages/catalog/courses-page/all-courses/active-courses/add-course/select-metro/metro-colors';
import { SharedService } from 'app/shared/shared.service';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';

@Component({
  selector: 'prf-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit, OnDestroy {
  @Input() course: IClass;
  @Input() favouritesAccess: boolean = true;
  types = COURSES_TYPES;
  currentType: any = { color: '' };
  isFavorite: boolean = false;
  isCourse: boolean;
  subscription: any;
  favoritesCourses: any;
  userId: string;
  metroColors: MetroColors = new MetroColors();
  moment: any = moment;
  isToggleFavorite: boolean = false;
  private ngUnsubscribe$: Subject<any> = new Subject();
  public userRole: string = '';

  @HostBinding('class.events-column') hostClass: boolean = true;
  @ViewChild('heart') heart: ElementRef;
  @ViewChild('heartActive') heartActive: ElementRef;

  constructor(
    private courseDetailsService: CourseDetailsService,
    private router: Router,
    private sharedService: SharedService,
    private favoritesService: FavoritesService,
  ) {
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.userRole = localStorage.getItem('userRole');
    this.isCourse = this.course?.classesFormat === 'ShortCourse' || this.course?.classesFormat === 'LongCourse';
    this.currentType = this.getTypeCourse(this.course.classesFormat);
    this.userId = localStorage.getItem('userId');
    this.subscription = this.favoritesService.getFavoriteCourses().subscribe(data => {
      this.favoritesCourses = data;
      if (this.favoritesCourses) {
        let productIds = this.favoritesCourses.filter(el => el.productId === this.course.id);
        this.isFavorite = productIds.length > 0;
      }
    });
  }

  calculateRealPrice() {
    return this.courseDetailsService.calculateRealPrice(this.course);
  }

  public toggleFavorite() {
    this.isToggleFavorite = true;

    if (this.isFavorite) {
      this.removeFavorite();
      this.isFavorite = false;
    } else {
      this.addToFavorite();
      this.isFavorite = true;
    }
  }

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen - 2) + '..';
    }
    return text;
  }

  private getTypeCourse(value: string) {
    let type = this.types.filter(el => el.value === value);
    return type[0];
  }

  loadFavorites(): Observable<any> {
    return this.favoritesService.getUserFavorites(this.userId, FavoritesTypes.Class).pipe(
      take(1),
      tap(r => {
        this.favoritesCourses = r;
        this.favoritesService.setFavoriteCourses(r);
      }),
    );
  }
  addToFavorite() {
    this.favoritesService
      .addToFavorites(this.course.id, this.userId, FavoritesTypes.Class)
      .pipe(
        take(1),
        switchMap(r => {
          return this.loadFavorites();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  removeFavorite() {
    if (this.favoritesCourses) {
      let filteredFavorite = this.favoritesCourses.filter(el => el.productId === this.course.id);
      let courseFavoriteId = filteredFavorite.map(el => el.id)[0];
      if (courseFavoriteId) {
        this.favoritesService
          .deleteFavorite(courseFavoriteId)
          .pipe(
            switchMap(r => this.loadFavorites()),
            takeUntil(this.ngUnsubscribe$),
          )
          .subscribe();

        this.isFavorite = false;
      }
    }
  }

  checkAction(event: any) {
    if (!this.isToggleFavorite) {
      switch (true) {
        case this.heart && this.heart.nativeElement.contains(event.target):
          this.addToFavorite();
          break;
        case this.heartActive && this.heartActive.nativeElement.contains(event.target):
          this.removeFavorite();
          break;
        default:
          this.isCourse ? this.router.navigate(['/courses', this.course.hrid]) : this.router.navigate(['/events', this.course.hrid]);
          break;
      }
    } else {
      this.favoritesCourses = false;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
