<prf-add-region (regionAdded)="regionAdded($event)">{{ 'SHARED.ADD_REGION' | translate }}</prf-add-region>
<div>
  <h2>Список регионов</h2>
  <table *ngIf="allRegions">
    <tr>
      <td>
        <b>Всего регионов: {{ allRegions.length }}</b>
      </td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td><b>Название</b></td>
      <td>Hrid</td>
      <td></td>
    </tr>
    <tr *ngFor="let region of allRegions">
      <td>{{ region.name }}</td>
      <td>{{ region.hrid }}</td>
      <td>
        <prf-edit-region [currentRegion]="region" (regionChanged)="regionUpdate($event)"></prf-edit-region>
        <prf-delete-region [regionId]="region.id" (regionDeleted)="regionDeleted($event)"></prf-delete-region>
      </td>
    </tr>
  </table>
</div>
